import authService from '@services/brandywine/authService';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import secureStorage from './secureStorage';
import { AuthStore } from './storeTypes';

const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      authToken: undefined,
      username: undefined,
      setAuthToken: (username, authToken): void => {
        set((state) => ({ ...state, username, authToken }), true);
      },
      refreshAuthToken: async (): Promise<string> => {
        const current = get().authToken;
        if (current && current.jwtToken && current.refreshToken) {
          const updatedToken = await authService.refreshAuthToken(current.email, current.jwtToken, current.refreshToken);
          if (updatedToken) {
            set((state) => ({ ...state, authToken: updatedToken }));
            return updatedToken.jwtToken;
          } else {
            set((state) => ({ ...state, authToken: undefined }));
          }
        }
        throw new Error('authToken is undefined');
      },
      isAuthenticated: () => {
        const authToken = get().authToken;
        var result = false;
        if (authToken && authToken.jwtToken) {
          var token = jwtDecode<any>(authToken.jwtToken);
          const now = dayjs();
          const exp = dayjs.unix(token.exp);
          result = exp.diff(now.add(5, 'minutes'), 'minutes') > 0;
        }
        return result;
      },
    }),
    {
      name: 'uc-auth',
      storage: createJSONStorage<AuthStore>(() => secureStorage),
    },
  ),
);

export default useAuthStore;

import { HeaderTitle, HelpLink, Icon, LoadingScreen, NavigationHeader, Screen } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import Text from '@components/Text';
import { ListItem, ListItemSeparator } from '@components/lists';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { Order as CommercetoolsOrder, Money } from '@fieldera-raleys/client-commercetools/schema';
import { Order, PagedArray, PurchaseType, TimeSlot } from '@fieldera-raleys/client-common/types';
import { useRefetchOnFocus } from '@hooks';
import { AppStackRoutes, OrderHistoryStackRoutes, OrderHistoryStackScreenProps, RootTabRoutes } from '@navigation/routes';
import { orderService } from '@services/brandywine';
import { Me } from '@services/commerceTools';
import { useCartStore, usePurchaseHistoryFilterStore } from '@store';
import appStyles from '@styles/appStyles';
import { containerWidth, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import { getOrderTypes, getShippingMethods } from '@utils/helpers';
import { getCustomField, getOriginalOrderNumber, moneyValue } from '@utils/orderHelpers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, FlatList, ListRenderItemInfo, Platform, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';

type OrderModel = {
  orderNumber: string;
  purchaseDate: Dayjs;
  deliveryDate: Dayjs;
  shippingMethod?: string;
  orderStatus?: 'Pending' | string;
  orderDeliverySite?: '' | string;
  orderTotal: Money;
};

type OrderHistoryScreenProps = OrderHistoryStackScreenProps<OrderHistoryStackRoutes.RecentOrders>;
const OrderHistoryScreen = ({ navigation }: OrderHistoryScreenProps) => {
  const TABS = getOrderTypes();
  const { dateRange, purchaseType, fulfillmentType, setPurchaseType, clearPurchasesStore } = usePurchaseHistoryFilterStore();
  const shippingMethod = useMemo(() => (fulfillmentType === 'All' ? getShippingMethods() : [fulfillmentType]), [fulfillmentType]);
  const [state, setState] = useState<{ isLoading: boolean; orders: OrderModel[] }>({ isLoading: true, orders: [] });
  const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isRefetching } = orderService.useSearchOrders([purchaseType], shippingMethod, dateRange);
  const { cart } = useCartStore();

  useEffect(() => {
    if (!isLoading && data) {
      Me.orderService
        .getOrders(
          { shippingType: shippingMethod, minDate: dateRange.startDate, maxDate: dateRange.endDate, orderSource: purchaseType },
          appConstants.PAGE_SIZE,
          0,
        )
        .then((orders) => {
          var bwOrderNumbers = data.pages.flatMap((page) => page.data).map((bwo) => bwo.extOrderId);
          const orderList: OrderModel[] = [];
          orders.data.forEach((o: CommercetoolsOrder) => {
            if (bwOrderNumbers.findIndex((x) => x === o.orderNumber) < 0) {
              const timeSlotValue = getCustomField(o, 'timeSlot');
              const timeSlot = JSON.parse(timeSlotValue.timeSlotDate ? timeSlotValue : '""') as TimeSlot;

              orderList.push({
                orderNumber: o.orderNumber as string,
                purchaseDate: dayjs(o.createdAt),
                deliveryDate: dayjs(timeSlot?.timeSlotDate),
                shippingMethod: o.shippingInfo?.shippingMethodName,
                orderStatus: 'Pending',
                orderDeliverySite: '',
                orderTotal: o.taxedPrice?.totalGross ?? o.totalPrice,
              });
            }
          });

          data.pages
            .flatMap((page) => page.data)
            .forEach((bwo) => {
              if (!orderList.length || !orderList.some((o) => o.orderNumber === bwo.extOrderId)) {
                orderList.push({
                  purchaseDate: dayjs(bwo.createdDate),
                  deliveryDate: dayjs(bwo.estimatedDeliveryDates[0]),
                  orderNumber: bwo.extOrderId,
                  orderStatus: bwo.orderStatus.value,
                  orderDeliverySite: bwo.deliverySite?.displayName,
                  orderTotal: getTotalGross(bwo),
                  shippingMethod: bwo.shippingMethod,
                });
              }
            });

          if (purchaseType === 'Online') {
            setState((prev) => ({ ...prev, isLoading: false, orders: orderList.sort((a, b) => b.deliveryDate.diff(a.deliveryDate)) }));
          } else {
            setState((prev) => ({ ...prev, isLoading: false, orders: orderList.sort((a, b) => b.purchaseDate.diff(a.purchaseDate)) }));
          }
        });
    }
    return () => {
      setState((prev) => ({ ...prev, isLoading: true, orders: [] }));
    };
  }, [dateRange.endDate, dateRange.startDate, isLoading, data, purchaseType, shippingMethod]);

  const [oONumber, setOoNumber] = useState<undefined | null | string>();
  useEffect(() => {
    setOoNumber(getOriginalOrderNumber(cart));
  }, [cart, setOoNumber]);

  useRefetchOnFocus(refetch);

  const renderListHeaderComponent = useCallback(() => {
    return (
      <>
        <View style={styles.flatListHeader} testID="listItemHeader">
          <View style={styles.flex1}>
            <Text style={appStyles.bodyLeftBold} testID="dateText">
              Date
            </Text>
          </View>
          <View style={styles.flex1}>
            <Text style={[appStyles.bodyLeftBold, styles.pl2]} testID="totalText">
              Total
            </Text>
          </View>
          {purchaseType === 'Online' && (
            <View style={styles.flex1}>
              <Text style={[appStyles.bodyLeftBold, styles.pl2]} testID="methodText">
                Method
              </Text>
            </View>
          )}
          <View style={purchaseType === 'Online' ? styles.flex1 : styles.flex2}>
            <Text style={[appStyles.bodyLeftBold, styles.pl2]} testID="purchaseType">
              {purchaseType === 'Online' ? 'Status' : 'Location'}
            </Text>
          </View>
          <TouchableOpacity style={styles.pickerVal} onPress={() => navigation.navigate(OrderHistoryStackRoutes.PurchaseFilter)} testID="filterClick">
            {!(fulfillmentType === 'All' && dateRange.default) ? <View style={styles.badgeStyle} /> : null}
            <Icon style={[appStyles.smallIcon, { bottom: 3 }]} name="sort-icon" size={28} testID="filterIcon" />
          </TouchableOpacity>
        </View>
        <ListItemSeparator />
      </>
    );
  }, [dateRange.default, fulfillmentType, navigation, purchaseType]);

  const renderEmptyListComponent = useCallback(
    () => (
      <View style={styles.noItemFoundView} testID="emptyListView">
        <View style={styles.emptyListContainer}>
          <View style={styles.emptyListIcon}>
            <Icon name={'pickup-icon'} fill={colors.white} size={68} stroke={colors.darkCream} testID="pickupicon" />
          </View>
          <Text style={styles.emptyListText} testID="emptyMsg">
            No records found for {purchaseType} purchases
          </Text>
        </View>
        <View style={[styles.noItemFoundView, styles.filterText]}>
          <Text style={[appStyles.fontMobileListSmallCenterRegular]} testID="emptyInst">
            Adjusting the filter setting may display older purchases.
          </Text>
        </View>
      </View>
    ),
    [purchaseType],
  );

  const getTotalGross = (order: Order) => {
    const currencyCode = 'USD';

    let money: Money = {
      type: 'centPrecision',
      currencyCode: currencyCode,
      centAmount: order.totalPrice,
      fractionDigits: 0,
    };

    return money;
  };

  const renderItemSeparator = () => <View style={styles.itemSeparator} />;
  const renderItem = ({ item }: ListRenderItemInfo<OrderModel>) => {
    let lineStyle: null | TextStyle = null;
    if (oONumber && oONumber !== item.orderNumber) {
      lineStyle = {
        color: colors.darkCream,
      };
    }
    return (
      <ListItem
        style={{ height: 56 }}
        testID={'orderItem'}
        title=""
        onPress={() => {
          lineStyle === null && item.orderStatus !== 'Pending' && navigation.navigate(OrderHistoryStackRoutes.OrderDetails, { orderId: item.orderNumber });
        }}>
        <View style={styles.itemContainer}>
          <View style={styles.flex1}>
            <Text style={[appStyles.bodySmallLeftRegular, lineStyle]} numberOfLines={1} testID="date">
              {purchaseType === 'Online' ? item.deliveryDate.format('MMM DD') : item.purchaseDate.format('MMM DD')}
            </Text>
          </View>
          <View style={styles.flex1}>
            <Text style={[appStyles.bodySmallLeftRegular, lineStyle]} numberOfLines={1} testID="moneyValue">
              {moneyValue(item.orderTotal)}
            </Text>
          </View>
          {purchaseType === 'Online' && (
            <View style={styles.flex1}>
              <Text style={[appStyles.bodySmallLeftRegular, lineStyle]} numberOfLines={1} testID="shippingMethod">
                {item.shippingMethod}
              </Text>
            </View>
          )}
          {/* TODO: Change dependency when in store data is available */}
          {purchaseType === 'Online' ? (
            <View style={styles.flex1}>
              <Text style={[appStyles.bodySmallLeftRegular, lineStyle]} numberOfLines={2} testID="orderStatus">
                {item.orderStatus}
              </Text>
            </View>
          ) : (
            <View style={styles.flex2}>
              <Text style={[appStyles.bodySmallLeftRegular, lineStyle]} numberOfLines={2} testID="orderDeliverySite">
                {item.orderDeliverySite}
              </Text>
            </View>
          )}
          <Icon
            testID="arrow-right"
            style={[appStyles.smallIcon]}
            name="arrow-right"
            size={15}
            stroke={lineStyle || item.orderStatus === 'Pending' ? 'transparent' : undefined}
          />
        </View>
      </ListItem>
    );
  };

  const handleEndReached = async () => !isFetchingNextPage && fetchNextPage();

  const handleBack = () => {
    clearPurchasesStore();
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
    }
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader
        onPress={handleBack}
        next={<HelpLink testID="helpClick" />}
        subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]}
        backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID="purchaseHistoryScreenTitle">Purchase History</HeaderTitle>
      </NavigationHeader>
      <View style={appStyles.container}>
        <View style={styles.tabWrapper}>
          {TABS.map((tab, index) => {
            return (
              <View key={index} style={styles.tabContainer} testID="tabView">
                <TouchableOpacity
                  testID={`tab-${tab}`}
                  onPress={() => {
                    setPurchaseType(tab as PurchaseType);
                  }}
                  style={styles.tab}>
                  <Text
                    testID="tabName"
                    style={{
                      ...styles.tabText,
                      color: purchaseType === tab ? colors.red : colors.black,
                      opacity: purchaseType === tab ? 1 : 0.5,
                      fontWeight: '700',
                    }}>
                    {tab}
                  </Text>
                  {purchaseType === tab && <View style={styles.selectedTab} />}
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
        {state.isLoading ? (
          <View style={{ height: screenHeight * 0.7 }}>
            <LoadingScreen />
          </View>
        ) : (
          <>
            <CartInfoBanner />
            <FlatList
              data={state.orders}
              keyExtractor={(_, idx) => String(idx)}
              ItemSeparatorComponent={renderItemSeparator}
              ListHeaderComponent={isLoading ? null : renderListHeaderComponent}
              ListEmptyComponent={isLoading ? null : renderEmptyListComponent}
              stickyHeaderIndices={[0]}
              bounces={true}
              contentContainerStyle={styles.cardContainer}
              style={{ width: screenWidth, transform: [{ translateX: -5 }] }}
              renderItem={renderItem}
              onEndReached={handleEndReached}
              onEndReachedThreshold={0.3}
              refreshing={isRefetching}
              onRefresh={() => refetch<PagedArray<CommercetoolsOrder>>({ refetchPage: (page) => page.offset === 0 })}
              ListFooterComponent={<>{isFetchingNextPage && <ActivityIndicator />}</>}
            />
          </>
        )}
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  header: { paddingHorizontal: 15 },
  badgeStyle: {
    backgroundColor: colors.red,
    position: 'absolute',
    zIndex: 99,
    left: 15.5,
    top: -2,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  overlay: {
    width: screenWidth + 50,
    height: screenHeight,
    position: 'absolute',
    bottom: -40,
    left: -50,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 9,
  },
  cardContainer: {
    overflow: 'hidden',
    backgroundColor: colors.white,
    paddingBottom: Platform.select({
      ios: screenHeight * 0.2,
      android: screenHeight * 0.3,
    }),
  },
  tabContainer: {
    flex: 1,
  },
  tabWrapper: {
    flexDirection: 'row',
    backgroundColor: colors.cream,
    paddingHorizontal: 20,
    marginTop: screenHeight * 0.03,
    marginBottom: 2,
    paddingBottom: 0,
    // shadowColor: colors.black,
    // shadowOffset: {
    //   width: 0,
    //   height: 10,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 3.84,
    // elevation: 5,
    borderBottomWidth: 1,
    borderColor: colors.borderGray,
    width: screenWidth,
    transform: [{ translateX: -5 }],
  },
  tab: {
    paddingTop: 10,
    alignItems: 'center',
  },
  tabText: {
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(15),
    lineHeight: 18,
    textAlign: 'center',
  },
  selectedTab: {
    width: '80%',
    height: 3,
    backgroundColor: '#af3c3f',
    marginTop: 5,
  },
  activityIndicator: {
    flex: 1,
    position: 'absolute',
    height: screenHeight,
    width: screenWidth,
  },
  pickerVal: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flatListHeader: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.sectionHeader,
    justifyContent: 'space-around',
    paddingVertical: 10,
    paddingLeft: 20,
    paddingRight: 38,
  },
  flatListHeaderText: {
    fontSize: 16,
    fontWeight: '600',
  },
  itemContainer: {
    flexDirection: 'row',
    width: screenWidth,
    paddingRight: 35,
    paddingVertical: 8,
    height: screenHeight * 0.2,
    alignItems: 'center',
    flex: 1,
  },
  titleContainer: {
    borderBottomWidth: 1,
  },
  titleRow: {
    flexDirection: 'row',
    width: containerWidth,
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  headSeparator: {
    height: 1,
    backgroundColor: colors.dark,
    marginTop: 10,
  },
  item: {
    flex: 1,
  },
  pickerContainer: {
    width: '100%',
    zIndex: 10,
    bottom: 0,
    position: 'absolute',
    backgroundColor: '#fcf7f3',
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListIcon: {
    width: 100,
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 14,
    paddingLeft: 22,
  },
  emptyListText: {
    fontFamily: 'Larsseit',
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 26,
    width: '75%',
    // marginLeft: 10,
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  itemSeparator: {
    width: '95%',
    alignSelf: 'center',
    height: 1.5,
    backgroundColor: colors.darkCream,
  },
  pl2: {
    paddingLeft: 2,
  },
  noItemFoundView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterText: {
    marginTop: 10,
    width: '70%',
  },
});

export default OrderHistoryScreen;

import Text from '@components/Text';
import { useTheme } from '@react-navigation/native';
import { appStyles } from '@styles';
import React from 'react';
import { StyleProp, TextProps, TextStyle, View, ViewStyle } from 'react-native';

interface FormLabelProps extends TextProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}
const FormLabel: React.FC<FormLabelProps> = ({ children, style, textStyle, ...rest }) => {
  const { colors } = useTheme();

  return (
    <View style={[{ flex: 1 }, style]}>
      <Text style={[appStyles.formLabels, { flex: 1, color: colors.text }, textStyle]} {...rest}>
        {children}
      </Text>
    </View>
  );
};

export default FormLabel;

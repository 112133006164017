import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Button, Image, LoadingScreen, Screen, Text } from '@components';
import { mapping } from '@components/bloomreach';
import colors from '@config/colors';
import { useBrxmPage } from '@hooks';
import { AppStackRoutes, AppStackScreenProps, RootTabRoutes } from '@navigation/routes';
import { appStyles } from '@styles';
import { lineHeight, screenWidth } from '@styles/constants';
import React, { useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';

type ShelfGuideDefinitionsScreenProps = AppStackScreenProps<AppStackRoutes.ShelfGuideDefinitions>;

const ShelfGuideDefinitionsScreen = ({ navigation, route }: ShelfGuideDefinitionsScreenProps) => {
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [refreshing, setRefreshing] = useState(false);
  const baseImageUrl = Config.BRXM_IMAGE_URL;
  const { shelfGuideName = '', shelfGuide = '' } = route?.params;

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  const handleOnPress = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
  };

  return (
    <>
      {!brxmPage || !brxmConfiguration ? (
        <LoadingScreen />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          <Screen headerVisible={true}>
            <View style={[appStyles.container, styles.mainContainer]}>
              <View style={styles.headerContainer}>
                <Image
                  testID="shelfGuideImage"
                  style={styles.sgImage}
                  source={{
                    uri: `${baseImageUrl}/consumer-app/shelf-guide/icons/${shelfGuide}.png`,
                  }}
                  resizeMode={'contain'}
                />
                <Text testID="shelfguideName" style={[appStyles.h3B, styles.title]}>
                  {shelfGuideName}
                </Text>
              </View>
              <ScrollView
                style={[appStyles.scrollView, styles.scrollView]}
                nestedScrollEnabled={true}
                bounces={false}
                showsVerticalScrollIndicator={true}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
                <BrComponent path="hero" />
                <BrComponent path="main" />
                <BrComponent path="body" />
                <BrComponent path="bottom" />
              </ScrollView>
              <View style={[styles.footerContainer]}>
                <Button
                  type="secondary"
                  size="small"
                  title={'Close'}
                  onPress={handleOnPress}
                  textStyle={styles.linkTextStyle}
                  buttonStyle={styles.buttonStyle}
                />
              </View>
            </View>
          </Screen>
        </BrPage>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    width: screenWidth,
    flex: 1,
    justifyContent: 'center',
    paddingTop: 0,
    paddingHorizontal: 0,
  },
  title: {
    textAlign: 'center',
    fontSize: 28,
    lineHeight: 32,
    marginBottom: 28,
  },
  linkTextStyle: {
    lineHeight: lineHeight(14),
    textTransform: 'capitalize',
  },
  sgImage: {
    width: 80,
    height: 80,
    alignSelf: 'center',
    marginTop: 48,
    marginBottom: 24,
  },
  buttonStyle: {
    width: 200,
    marginTop: 20,
    marginBottom: 40,
    maxWidth: 400,
  },
  scrollView: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 0,
    marginBottom: 0,
  },
  headerContainer: {
    marginRight: 5,
    marginLeft: 5,
  },
  footerContainer: {
    backgroundColor: colors.white,
    marginLeft: 5,
    marginRight: 5,
  },
});

export default ShelfGuideDefinitionsScreen;

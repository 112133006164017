import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address } from '@fieldera-raleys/client-common';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useRefetchOnFocus } from '@hooks';
import { userService } from '@services/brandywine';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import cardValidator from 'card-validator';
import { getTypeInfo } from 'credit-card-type';
import { CreditCardType } from 'credit-card-type/dist/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { NativeSyntheticEvent, ScrollView, StyleSheet, TextInput, TextInputChangeEventData, View } from 'react-native';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import helpers, { formatCardExpiryDate, formatCardNumber } from '../../utils/helpers';
import { queryClient } from '../../utils/reactQuery';
import KeyboardAvoidingScreen from '../KeyboardAvoidingScreen';
import LinkButton from '../LinkButton';
import LoadingScreen from '../LoadingScreen';
import Modal from '../Modal';
import { PickerComponent, PickerItem } from '../Picker2';
import ErrorMessage from './ErrorMessage';
import Form from './Form';
import FormField from './FormField';
import FormPicker from './FormPicker';
import FormSwitch from './FormSwitch';
import SubmitButton from './SubmitButton';

type PaymentFormProps = {
  paymentMethodId?: number;
  addressId?: string | number;
  onAddAddress?: () => void;
  onAfterSubmit?: (paymentMethodId?: number) => void;
  onEditAddress?: (addressId: string | number) => void;
};

type PaymentFormType = {
  CustomerPaymentMethodId?: number;
  CardNumber?: string;
  ExpirationDate?: string;
  CVV?: number;
  BillingAddressId?: string | number;
  BillingAddress?: PickerItem<Address>;
  IsDefault?: boolean;
};
const HEADER_HEIGHT = scale(56);

const CardPaymentForm = ({ paymentMethodId, addressId, onAddAddress, onAfterSubmit, onEditAddress }: PaymentFormProps): JSX.Element => {
  const formValues = useRef<PaymentFormType>({
    CustomerPaymentMethodId: 0,
    CardNumber: '',
    ExpirationDate: '',
    CVV: undefined,
    BillingAddressId: '',
    BillingAddress: undefined,
    IsDefault: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isbuttonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [creditcardSavedSuccessful, setCreditCardSavedSuccessful] = useState<{
    saved: boolean;
    paymentMethodId?: number;
  }>({
    saved: false,
  });
  const [creditcardInfo, setCreditCardInfo] = useState({
    CardNumber: '',
    ExpirationDate: '',
    CVV: '',
  });

  const {
    data: addressBook,
    isLoading: isAddressBookLoading,
    refetch: refetchAddressBook,
  } = useQuery('address-book', async () => userService.getAddressList());
  const handleError = useErrorHandler();
  const errorMessageRef = useRef<string>();
  const cardTypeRef = useRef<CreditCardType>();
  const cardNumberInputRef = createRef<TextInput>();
  const expirationInputRef = createRef<TextInput>();
  const cvvInputRef = createRef<TextInput>();
  const addressInputRef = createRef<PickerComponent<Address>>();
  const isCardExpiredRef = useRef<boolean>(false);

  useRefetchOnFocus(refetchAddressBook);

  useEffect(() => {
    setIsLoading(isLoading || isAddressBookLoading);
  }, [isAddressBookLoading, isLoading]);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (paymentMethodId) {
          const paymentProfile = await queryClient.fetchQuery(['payment-method', paymentMethodId], async () =>
            userService.getPaymentProfileByPaymentMethodId(paymentMethodId),
          );
          const address = addressId
            ? addressBook?.find((x) => x.id === addressId)
            : addressBook?.find(
                (a) =>
                  a.address1 === paymentProfile.BillingAddress?.Address1 &&
                  a.city === paymentProfile.BillingAddress.City &&
                  a.postalCode === paymentProfile.BillingAddress.PostalCode,
              );

          let expirationDate = `${String(paymentProfile.ExpirationMonth)?.padStart(2, '0')}/${paymentProfile.ExpirationYear}`;
          dayjs.extend(customParseFormat);
          expirationDate = dayjs(expirationDate, 'MM/YYYY').format('MM/YY');
          isCardExpiredRef.current = dayjs(paymentProfile.CardExpirationDate).add(1, 'month').isBefore(dayjs(), 'day');

          formValues.current = {
            CustomerPaymentMethodId: paymentProfile.CustomerPaymentMethodId,
            BillingAddressId: address?.id,
            BillingAddress: address ? ({ id: address.id, value: address, text: address?.address1 } as PickerItem<Address>) : undefined,
            CVV: paymentProfile.CVV || Number(creditcardInfo.CVV),
            ExpirationDate: expirationDate,
            CardNumber: paymentProfile.CardNumber,
            IsDefault: paymentProfile.IsDefault,
          };
          cardTypeRef.current = paymentProfile.CardType ? getTypeInfo(camelTolowerCase(paymentProfile.CardType)) : undefined;
          setIsLoading(false);
        } else {
          const address = addressId ? addressBook?.find((x) => x.id === addressId) : addressBook?.find((x) => x.isDefaultBilling);
          formValues.current = {
            CustomerPaymentMethodId: 0,
            CardNumber: creditcardInfo.CardNumber,
            ExpirationDate: creditcardInfo.ExpirationDate,
            CVV: Number(creditcardInfo.CVV),
            BillingAddressId: address?.id,
            BillingAddress: address ? ({ id: address.id, value: address, text: address?.address1 } as PickerItem<Address>) : undefined,
            IsDefault: false,
          };
          setTimeout(() => {
            setIsLoading(false);
          }, 100);
        }
      } catch (ex) {
        handleError(ex);
      }
    };

    setIsLoading(true);
    errorMessageRef.current = undefined;
    if (!isAddressBookLoading) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodId, addressBook, isAddressBookLoading, handleError, addressId]);

  const camelTolowerCase = (str: string) => {
    var returnValue = str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
    return returnValue.indexOf('-') === 0 ? returnValue.substring(1) : returnValue;
  };

  const getCardTypeId = (type: string | undefined) => {
    switch (type) {
      case 'discover':
        return 4;
      case 'american-express':
        return 3;
      case 'mastercard':
        return 2;
      case 'visa':
        return 1;
      default:
        return 0;
    }
  };

  const handleChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    cardTypeRef.current = (cardValidator.number(e.nativeEvent.text).card as CreditCardType) ?? undefined;
  };

  const onSubmit = async (formData: PaymentFormType) => {
    setIsButtonLoading(true);

    let expirationDate = formData.ExpirationDate;
    dayjs.extend(customParseFormat);
    expirationDate = `${expirationDate}`;
    expirationDate = dayjs(expirationDate, 'MM/YY').format('MM/YYYY');

    var cardType = cardValidator.number(formData.CardNumber).card?.type;
    var expiry = cardValidator.expirationDate(expirationDate);
    const billingAddress = addressBook?.find((x) => x.id?.toString() === formData.BillingAddressId?.toString());
    var region = appConstants.STATES_LIST.find((x) => x.abbreviation === billingAddress?.state);
    const paymentInfo = {
      CreditCardTypeId: getCardTypeId(cardType),
      CardNumber: formData.CardNumber!.replace(/[^0-9]+/g, ''),
      CVV: formData.CVV,
      ExpirationMonth: expiry.month,
      ExpirationYear: expiry.year,
      CardExpirationDate: expirationDate,
      CustomerPaymentMethodId: formData.CustomerPaymentMethodId,
      BillingAddress: {
        FirstName: billingAddress?.firstName,
        LastName: billingAddress?.lastName,
        Address1: billingAddress?.address1,
        City: billingAddress?.city,
        Region: { Id: region?.id, Value: region?.name },
        PostalCode: billingAddress?.postalCode,
        PhoneNumber: billingAddress?.phone,
        Country: billingAddress?.country,
      },
      IsDefault: formData.IsDefault,
    } as PaymentProfile;

    queryClient.executeMutation({
      mutationKey: ['payment-method', paymentMethodId],
      mutationFn: async () => (paymentMethodId ? userService.updatePaymentProfile(paymentInfo) : userService.createPaymentProfile(paymentInfo)),
      onError: (ex: any) => {
        if ((ex as Error).message.indexOf('already exists') >= 0) {
          errorMessageRef.current = (ex as Error).message;
          setShowError(true);
        } else if ((ex as Error).message.indexOf('Invalid Credit Card type') >= 0) {
          errorMessageRef.current = (ex as Error).message;
        } else if ((ex as Error).message.indexOf('Invalid Credit Card verification number') >= 0) {
          errorMessageRef.current = (ex as Error).message;
        } else if ((ex as Error).message.indexOf('Invalid Region') >= 0) {
          errorMessageRef.current = 'The address is invalid';
          setShowError(true);
        } else {
          handleError(ex);
        }
      },
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['payment-method', paymentMethodId]);
        await queryClient.invalidateQueries('payment-methods');

        setCreditCardSavedSuccessful({ saved: true, paymentMethodId: data });
      },
      onSettled: () => {
        setIsButtonLoading(false);
        setCreditCardInfo({ CardNumber: '', ExpirationDate: '', CVV: '' });
      },
    });
  };

  const showCreditCardSavedSuccessModal = (data: number) => {
    let message = '';
    if (paymentMethodId) {
      message = 'Your payment method has been successfully updated.';
    } else {
      message = 'Your payment method has been successfully added.';
    }

    return (
      <Modal
        visible={creditcardSavedSuccessful.saved}
        title="Success!"
        location="top"
        cancelButtonText="OK"
        cancelButtonOnPress={() => {
          onAfterSubmit && onAfterSubmit(data);
          setCreditCardSavedSuccessful({ saved: false });
        }}>
        <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="successmessage">
          {message}
        </Text>
      </Modal>
    );
  };

  const showErrorModal = (message: string | undefined) => {
    return (
      <Modal
        visible={showError}
        title="Error !"
        location="top"
        cancelButtonText="OK"
        cancelButtonOnPress={() => {
          onAfterSubmit && onAfterSubmit();
          setShowError(false);
        }}>
        <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="errormessage">
          {message}
        </Text>
      </Modal>
    );
  };

  const validationSchema = Yup.object().shape({
    CardNumber: Yup.string()
      .required()
      .label('Card Number')
      .test('CardNumber', (value) => {
        return paymentMethodId ? true : value ? cardValidator.number(value).isValid : false;
      }),
    ExpirationDate: Yup.string()
      .required()
      .label('Exp. Date')
      .length(5, 'Not a valid expiration date. Example: MM/YY')
      .matches(helpers.EXPIRATION_DATE_REGEX, 'Not a valid expiration date. Example: MM/YY')
      .test('ExpirationDate', 'Exp. Date Invalid', (value) => {
        return value ? cardValidator.expirationDate(value).isValid : false;
      }),
    CVV: Yup.string()
      .required()
      .label('CVV')
      .matches(helpers.CVV_REGEX, 'Not a valid CVV')
      .when('CardNumber', (CardNumber, schema) => {
        return CardNumber && cardTypeRef.current ? schema.length(cardTypeRef.current.code.size) : schema.length(3);
      }),
    BillingAddressId: Yup.string().required().label('Billing Address'),
  });

  return isLoading || isAddressBookLoading ? (
    <LoadingScreen />
  ) : (
    <Form initialValues={formValues.current} onSubmit={onSubmit} validationSchema={validationSchema}>
      <KeyboardAvoidingScreen behavior="padding" keyboardVerticalOffset={0}>
        <ScrollView bounces={false} contentContainerStyle={[appStyles.container, styles.formContainer]}>
          <ErrorMessage
            error={errorMessageRef.current}
            visible={!!errorMessageRef.current}
            style={{ ...utilityStyles.p3, textAlign: 'center' }}
            testID="cardPaymentError"
          />
          <FormField
            testID="cardNumber"
            ref={cardNumberInputRef}
            label="Credit Card Number"
            autoCapitalize="none"
            textContentType="none"
            autoCorrect={false}
            maxLength={19}
            keyboardType="number-pad"
            name="CardNumber"
            returnKeyType="done"
            editable={paymentMethodId ? false : true}
            onChange={handleChange}
            formatter={formatCardNumber}
            blurOnSubmit={false}
            onEndEditing={(e) => {
              setCreditCardInfo({ ...creditcardInfo, CardNumber: e.nativeEvent.text });
            }}
            onSubmitEditing={() => {
              expirationInputRef.current?.focus();
            }}
            containerStyle={styles.formContainerStyle}
          />
          <View style={styles.row}>
            <View style={{ flex: 0.45 }}>
              <FormField
                testID="expirationDate"
                ref={expirationInputRef}
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numbers-and-punctuation"
                returnKeyType="next"
                label="Exp. Date"
                name="ExpirationDate"
                placeholder={'MM/YY'}
                formatter={formatCardExpiryDate}
                maxLength={5}
                blurOnSubmit={false}
                onEndEditing={(e) => {
                  setCreditCardInfo({ ...creditcardInfo, ExpirationDate: e.nativeEvent.text });
                }}
                onSubmitEditing={() => {
                  cvvInputRef.current?.focus();
                }}
              />
            </View>
            <View style={{ flex: 0.45 }}>
              <FormField
                testID="cvvNumber"
                ref={cvvInputRef}
                label="CVV"
                autoCapitalize="none"
                keyboardType="number-pad"
                textContentType="none"
                autoCorrect={false}
                name="CVV"
                returnKeyType="done"
                maxLength={4}
                blurOnSubmit={false}
                onEndEditing={(e) => {
                  setCreditCardInfo({ ...creditcardInfo, CVV: e.nativeEvent.text });
                }}
                onSubmitEditing={() => {
                  addressInputRef.current?.focus();
                }}
              />
            </View>
          </View>
          <FormPicker
            testID="BillingAddressId"
            ref={addressInputRef}
            label="Billing Address"
            name="BillingAddressId"
            topRight={
              <View style={styles.optionWrapper}>
                {(addressBook?.length ?? 0) > 0 && (
                  <LinkButton
                    style={[styles.editLink, appStyles.smallLink]}
                    onPress={() => onEditAddress && onEditAddress(formValues.current.BillingAddressId ?? '')}
                    testID="editLink">
                    Edit
                  </LinkButton>
                )}
                <LinkButton onPress={onAddAddress} style={[styles.addLink, appStyles.smallLink]} testID="addLink">
                  Add
                </LinkButton>
              </View>
            }
            initialValue={formValues.current.BillingAddress}
            onSelect={(item) => {
              formValues.current.BillingAddress = item;
            }}
            options={addressBook?.map((a) => a.id && { id: a!.id, value: a, text: a!.address1 }) as PickerItem<Address>[]}
            enabled={(addressBook?.length ?? 0) > 0}
            textStyle={{ fontSize: scale(18) }}
            headerTitle={'Billing Address'}
            placeholder={(addressBook?.length ?? 0) === 0 ? 'No Saved Addresses ' : 'Select Existing Address '}
            containerStyle={styles.formContainerStyle}
          />
          <FormSwitch
            style={{ flex: 0, transform: [{ scaleX: 0.75 }, { scaleY: 0.75 }] }}
            name={'IsDefault'}
            label={'Set as default Payment Method'}
            testID="isDefault"
            disabled={isCardExpiredRef.current}
          />
        </ScrollView>
      </KeyboardAvoidingScreen>
      <View style={styles.saveBar}>
        <SubmitButton
          title={paymentMethodId ? 'Update' : 'Add'}
          buttonStyle={styles.saveButton}
          isButtonLoading={isbuttonLoading}
          testID={paymentMethodId ? 'updateBtn' : 'addBtn'}
        />
      </View>
      {creditcardSavedSuccessful.paymentMethodId && showCreditCardSavedSuccessModal(creditcardSavedSuccessful.paymentMethodId)}
      {showError && showErrorModal(errorMessageRef.current)}
    </Form>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: scale(9),
  },
  scrollViewContent: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: colors.cream,
  },
  saveBar: {
    width: screenWidth,
    bottom: 0,
    alignSelf: 'center',
    height: HEADER_HEIGHT,
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderColor: '#707070',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveButton: {
    width: screenWidth * 0.4,
    height: '70%',
    backgroundColor: colors.darkCream,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    padding: 0,
  },
  addLink: {
    fontSize: scale(15),
    lineHeight: lineHeight(15),
    color: colors.darkText,
    fontFamily: FontFamily.Aleo,
    ...utilityStyles.ml3,
  },
  formLabelStyle: { paddingLeft: 0 },
  formContainerStyle: { marginHorizontal: scale(9) },
  formContainer: { paddingHorizontal: '2%' },
  optionWrapper: {
    ...utilityStyles.pt1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  editLink: {
    fontSize: scale(15),
    lineHeight: lineHeight(15),
    color: colors.darkText,
    fontFamily: FontFamily.Aleo,
    ...utilityStyles.ml3,
  },
});

export default CardPaymentForm;

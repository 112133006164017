import Text from '@components/Text';
import colors from '@config/colors';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import logger from '../../utils/logger';
import { getProductAvailablity } from '../../utils/productHelper';
import CheckBox from '../Checkbox';

type ListItemProps = {
  //any temporary for testing
  item: Product | any;
  toggleChecked: () => void;
  value: boolean;
  productInfo?: Product | undefined;
  isTextLineItem?: boolean;
  toCart?: boolean;
  showUnderline?: boolean;
};

const ListItem: FC<ListItemProps> = ({ item, toggleChecked, value = false, productInfo, isTextLineItem = false, toCart = false, showUnderline = true }) => {
  const { showModal, hideModal } = useCustomModalContext();
  const available = getProductAvailablity(productInfo?.masterData);
  const isDisabled =
    (isTextLineItem && toCart) || (available.availability !== 'Available' && available.availability !== 'LowStock' && toCart && item.name !== 'Select All');

  const displayWarning = (warning: string) => {
    if (warning === 'Unavailable') {
      showModal({
        title: 'Item Not Available',
        children: <Text style={appStyles.bodyLargeCenter}>This item is not available at the selected store location.</Text>,
        cancelButtonOnPress: hideModal,
        cancelButtonText: 'OK',
        // okButtonOnPress: hideModal,
        // okButtonText: 'OK',
        location: 'top',
        showCloseIcon: false,
      });
    } else if (warning === 'Custom') {
      logger.log('customized item warning');
    }
  };

  return (
    <TouchableOpacity
      testID="listItemClick"
      activeOpacity={1}
      onPress={isDisabled ? () => displayWarning('Unavailable') : undefined}
      style={[styles.container, isDisabled ? styles.disabled : null, { borderBottomWidth: showUnderline ? 1.5 : 0 }]}>
      <View style={styles.shiftDown}>
        <CheckBox testID="checkbox" disabled={isDisabled} value={value} onValueChange={isDisabled ? () => displayWarning('Unavailable') : toggleChecked} />
      </View>
      <Text numberOfLines={1} style={[appStyles.bodySmallLeft, styles.itemText]} testID={item.name}>
        {item.name}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: screenWidth - 32,
    alignSelf: 'center',
    backgroundColor: colors.cream,
    // paddingHorizontal: 16,
    // borderBottomWidth: 1.5,
    borderColor: colors.sectionBorder,
    height: 58,
  },
  disabled: {
    opacity: 0.5,
  },
  shiftDown: {
    transform: [{ translateY: 4 }],
  },
  itemText: {
    paddingTop: Platform.OS === 'ios' ? 5 : 10,
    width: '90%',
  },
});

export default ListItem;

import colors from '@config/colors';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import logger from '@utils/logger';
import _ from 'lodash';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
import { useCustomModalContext } from '../contexts';
import CloseButton from './CloseButton';
import StepNavigator from './StepNavigator';
import Text from './Text';

interface CustomizeHeaderProps {
  forwardNavEnabled: boolean;
}

const CustomizeHeader: FC<CustomizeHeaderProps> = ({ forwardNavEnabled }): JSX.Element => {
  const navigation = useNavigation();
  const {
    prevPageData,
    itemId,
    steps,
    currStep,
    inCart,
    setSteps,
    setCurrStep,
    clearCustomize,
    saveCustomizedItem,
    removeCustomizedItem,
    prevProductSkus,
    selectedIngredients,
    selectedSize,
    cakeMessage,
    selectedContainer,
    savedCustomizedItems,
    setSavedCustomizedItems,
    setEditCartId,
    setEditSavedForLaterId,
    productLeadTime,
  } = useCustomizeStore();
  const { showModal } = useCustomModalContext();
  const closeAlert = () => {
    var productSkus = [];
    productSkus.push(selectedSize?.sku);
    selectedIngredients?.forEach((ing) => {
      ing.selections.forEach((sel: any) => productSkus.push(sel?.sku));
    });
    cakeMessage?.sku && productSkus.push(cakeMessage?.sku);
    selectedContainer?.sku && productSkus.push(selectedContainer?.sku);

    if (
      inCart ||
      !selectedSize?.sku ||
      (!steps.some((s) => s.pageItem === 'Size') && !selectedIngredients[0]?.selections?.length) ||
      _.isEqual(productSkus, prevProductSkus)
    ) {
      handleClose();
      return;
    }
    showModal({
      okButtonOnPress: handleSave,
      okButtonText: 'Save',
      title: 'Unsaved Changes',
      children: <Text style={appStyles.bodyLargeCenter}>Would you like to save your current selections?</Text>,
      cancelButtonOnPress: handleClose,
      cancelButtonText: 'No Thanks',
      location: 'top',
      showCloseIcon: false,
    });
  };

  const handleSave = () => {
    if (savedCustomizedItems.some((si) => si.selectedSize?.sku === selectedSize?.sku)) {
      const filtered = savedCustomizedItems.filter((i) => i.selectedSize?.sku !== selectedSize?.sku);
      setSavedCustomizedItems(filtered);
    }
    setEditCartId('');
    setEditSavedForLaterId('');
    saveCustomizedItem();
    setCurrStep(1);
    setSteps([]);
    navBack();
  };

  const handleClose = () => {
    removeCustomizedItem();
    clearCustomize();
    navBack();
  };

  // useEffect(() => {
  //   console.log('Nav crumbs: ', navigation.getState().routes);
  // }, []);

  const navBack = () => {
    if (prevPageData.parentId) {
      try {
        if (prevPageData.parentId === 'favoriteList') {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Account,
            params: { screen: AccountStackRoutes.CustomizeNeeded, params: prevPageData.params },
          });
          return;
        }
        if (prevPageData.parentId === 'searchLanding') {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Shop,
            params: {
              screen: ShopStackRoutes.SearchLanding,
              params: { query: prevPageData.query ?? '', prevRoute: RootTabRoutes.Home },
            },
          });
          return;
        }
        if (prevPageData.parentId === 'cart') {
          navigation.navigate(AppStackRoutes.CartAndCheckout, {
            screen: CheckoutStackRoutes.CartLanding,
            params: { storeNumber: undefined },
          });
          return;
        }
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: { screen: ShopStackRoutes.ProductList, params: { rootCategoryId: prevPageData.parentId, categoryId: prevPageData.id } },
        });
        return;
      } catch (err) {
        logger.error('Customize close error');
        navigation.dispatch(StackActions.replace(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home, params: {} }));
        return;
      }
    }

    if (!itemId) {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home, params: {} });
      return;
    }

    navigation.navigate(AppStackRoutes.ProductDetails, { productKey: itemId });
  };

  return (
    <>
      <View style={[styles.container, { height: currStep === 1 && +productLeadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ? 145 : 100 }]}>
        <View style={styles.topHeader}>
          <Text style={[styles.customizeText, appStyles.h3B]}>Customize</Text>
          <CloseButton onPress={closeAlert} />
        </View>
        <StepNavigator steps={steps} currStep={currStep} forwardNavEnabled={forwardNavEnabled} />
        {/* <ProgressNodes steps={steps} currStep={currStep} /> */}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    backgroundColor: colors.cream,
    // paddingTop: Platform.OS === 'ios' ? (height > 640 ? 50 : 20) : 5,
    marginVertical: 20,
    alignSelf: 'center',
    height: 100,
  },
  topHeader: {
    width: '95%',
    alignSelf: 'center',
    marginBottom: 20,
    paddingRight: 5,
    paddingLeft: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cartIconWrapper: {
    transform: [{ translateY: 4.5 }],
  },
  customizeText: {
    marginLeft: 32,
    marginTop: 5,
  },
});

export default CustomizeHeader;

import colors from '@config/colors';
import { Theme } from '@react-navigation/native';

const DefaultTheme: Theme = {
  dark: false,
  colors: {
    primary: colors.primary,
    background: colors.cream,
    card: colors.white,
    text: colors.text,
    border: colors.black,
    notification: colors.notification,
  },
};

export default DefaultTheme;

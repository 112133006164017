import { Button, Image, Screen } from '@components';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import logger from '@utils/logger';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, Text, View } from 'react-native';
import Config from 'react-native-config';

const AppUpgradeScreen = () => {
  const { t } = useTranslation('maintenanceScreen');
  const appStoreLink = 'itms-apps://apps.apple.com/id/app/raleys/id582172238?l=id';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.raleys.app.android';

  const handleUpgradeClick = () => {
    const appLink = Platform.select({
      ios: Config.APP_STORE_APP_LINK ?? appStoreLink,
      android: Config.PLAY_STORE_APP_LINK ?? playStoreLink,
    });
    if (appLink) {
      Linking.canOpenURL(appLink).then(
        (supported) => {
          supported && Linking.openURL(appLink);
        },
        (err) => logger.log(err),
      );
    }
  };

  return (
    <Screen>
      <View style={styles.container}>
        <Text style={styles.messageHeader}>{t('updateRequireTitle')}</Text>
        <View style={[styles.messageBodyView, { width: screenWidth * 0.56 }]}>
          <Text style={styles.messageBody}>{t('updateRequireMessage')}</Text>
          <Button
            testID="applyBtn"
            size={'small'}
            buttonStyle={[styles.primaryButtonSmall, { marginTop: scale(40) }]}
            textStyle={styles.updateBtnText}
            onPress={handleUpgradeClick}
            title={t('updateBtnText')}
          />
        </View>
        <Image resizeMode={'contain'} source={require('../../assets/images/quad-logo.png')} style={[styles.image]} />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageHeader: {
    color: colors.primary,
    fontSize: scale(30),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(34),
    textAlign: 'center',
    width: screenWidth * 0.8,
  },
  messageBody: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'center',
  },
  errorDesc: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    textAlign: 'center',
    marginTop: scale(24),
  },
  primaryButtonSmall: {
    minWidth: 130,
    maxWidth: 200,
    height: 40,
    paddingTop: 10,
    paddingBottom: 10,
    alignSelf: 'center',
    color: colors.primary,
    backgroundColor: colors.darkCream,
    borderWidth: 0,
    borderColor: colors.black,
    borderStyle: 'solid',
    borderRadius: 28,
    justifyContent: 'center',
  },
  messageBodyView: {
    marginTop: scale(24),
    justifyContent: 'center',
    alignItems: 'center',
  },
  updateBtnText: {
    textAlign: 'center',
    color: colors.primary,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(18),
  },
  skipText: {
    marginTop: scale(20),
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitLight,
  },
  helpModalButton: {
    width: 150,
  },
  image: {
    marginTop: scale(40),
    width: screenWidth * 0.9,
    height: scale(25),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default AppUpgradeScreen;

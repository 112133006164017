import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { ShoppingList } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon, { IconName } from '../Icon';
// import * as MediaLibrary from "expo-media-library";
import ListOptions from './ListOptions';

type ListCardProps = {
  iconName?: IconName | undefined;
  showValue?: boolean;
  list: ShoppingList;
};

const ListCard: FC<ListCardProps> = ({ iconName, showValue, list }) => {
  const navigation = useNavigation();
  const { showModal, hideModal } = useCustomModalContext();
  var value = 0;
  var numItems = 0;
  list.lineItems.forEach((li) => {
    value += ((li?.variant?.price?.value.centAmount ?? 0) / 10 ** (li?.variant?.price?.value?.fractionDigits ?? 2)) * li.quantity;
    numItems += li.quantity;
  });
  list.textLineItems.forEach((ti) => {
    numItems += ti.quantity;
  });

  const handleNav = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: { screen: AccountStackRoutes.ViewList, params: { title: list.name ?? '', listName: list.name ?? '', listId: list.id } },
    });
  };

  const customHandle = () => (
    <View style={styles.handle}>
      <TouchableOpacity
        testID="closeIconClick"
        style={styles.closeButton}
        onPress={() => {
          hideModal();
        }}>
        <Icon name={'x-close'} size={20} testID="closeIcon" />
      </TouchableOpacity>
      <Text style={appStyles.h6} testID="listOptions">
        List Options
      </Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]} testID="name">
        {list.name}
      </Text>
    </View>
  );

  const openList = () => {
    showModal({
      style: { borderTopLeftRadius: 25, borderTopRightRadius: 25, backgroundColor: colors.cream },
      header: customHandle(),
      children: (
        <>
          <ListOptions list={list} navigation={navigation} />
        </>
      ),
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3, paddingBottom: 16, marginTop: -16 },
    });
  };

  return (
    <>
      <TouchableOpacity onPress={handleNav} style={styles.container} testID="listCardItemClick">
        <View style={styles.topCardContainer} testID="topCardContainer">
          <View style={styles.titleContainer} testID="titleContainer">
            {iconName && (
              <Icon name={iconName} fill={colors.darkText} stroke={colors.darkText} size={15} style={{ marginRight: 4, marginTop: 2 }} testID="icon" />
            )}
            <Text numberOfLines={3} style={appStyles.bodySmallLeft} testID="listName">
              {list.name}
            </Text>
          </View>
          <TouchableOpacity style={styles.ellipsisContainer} onPress={openList} testID="ellipsisiconClick">
            <Icon name={'ellipsis-icon'} fill={colors.darkText} stroke={'none'} testID="ellipsisIcon" />
          </TouchableOpacity>
        </View>
        <View testID="numOfItemsview">
          <Text style={appStyles.bodySmallLeftRegular} testID="numOfItems">
            {numItems} items {showValue && `| Est. $${value.toFixed(2)}`}
          </Text>
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: screenWidth * 0.9,
    height: 100,
    marginVertical: 16,
    alignSelf: 'center',
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderColor: colors.greyBorder,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'space-between',
  },
  linkContainer: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    justifyContent: 'center',
  },
  topCardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: -2,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    // marginLeft: 8,
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    width: '70%',
  },
  subText: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
  },
  ellipsisContainer: {
    // backgroundColor: 'red',
    width: 50,
    height: 30,
    alignItems: 'flex-end',
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    // height: 50,
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  link: {
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
  modalText: {
    paddingTop: scale(5),
  },
});

export default ListCard;

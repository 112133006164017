import { createStackNavigator } from '@react-navigation/stack';
import { StoreFinderDeailScreen, StoreFinderFilterScreen, StoreFinderScreen } from '@screens/account';
import React from 'react';
import { StoreFinderStackParamList, StoreFinderStackRoutes } from './routes';

const StoreFinderNavigator = () => {
  const StoreFinderStack = createStackNavigator<StoreFinderStackParamList>();

  const verticalTransition = {
    cardStyleInterpolator: ({ current, layouts }) => {
      return {
        cardStyle: {
          transform: [
            {
              translateY: current.progress.interpolate({
                inputRange: [0, 1],
                outputRange: [-layouts.screen.height, 0],
              }),
            },
          ],
        },
      };
    },
  };

  return (
    <StoreFinderStack.Navigator
      initialRouteName={StoreFinderStackRoutes.StoreFinderScreen}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <StoreFinderStack.Screen name={StoreFinderStackRoutes.StoreFinderScreen} component={StoreFinderScreen} />
      <StoreFinderStack.Screen name={StoreFinderStackRoutes.StoreFinderDetail} component={StoreFinderDeailScreen} options={verticalTransition} />
      <StoreFinderStack.Screen name={StoreFinderStackRoutes.StoreFinderFilter} component={StoreFinderFilterScreen} options={verticalTransition} />
    </StoreFinderStack.Navigator>
  );
};

export default StoreFinderNavigator;

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { ScreenName, TutorialStore } from './storeTypes';

const useTutorialStore = create<TutorialStore>()(
  persist(
    (set, get) => ({
      visitCount: {
        Home: 1,
        Rewards: 0,
        Account: 0,
        PDP: 0,
      },

      setVisitCount: (screen: ScreenName) => {
        set((state) => ({ ...state, visitCount: { ...state.visitCount, [screen]: get().visitCount[screen] + 1 } }));
      },
      getVisitCount: (screen: ScreenName) => {
        return get().visitCount[screen];
      },
      clearAll: () => {
        set((state) => ({ ...state, visitCount: { Home: 1, Rewards: 0, Account: 0, PDP: 0 } }), true);
      },
    }),
    {
      name: 'uc-tutorial',
      storage: createJSONStorage<TutorialStore>(() => asyncStorage),
    },
  ),
);

export default useTutorialStore;

import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document } from '@bloomreach/spa-sdk';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import CloseButton from '../CloseButton';
import HtmlView from '../HtmlView';
import LinkButton from '../LinkButton';
import { brxmNavigation } from './utils';

const PromoCardComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const [closed, setClosed] = useState(false);
  const linkto = useLinkTo();
  const navigation = useNavigation();
  const { showAlertModal } = useCustomModalContext();

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const handleClose = () => {
    setClosed(true);
  };

  const { title, promoType, linkText, url, navigationType, body } = document.getData();

  const source = { html: body?.value };

  const handleNavigation = (): void => {
    brxmNavigation(url, navigationType, navigation, linkto, showAlertModal);
  };

  return closed ? (
    <></>
  ) : (
    <>
      {title || body?.value || linkText ? (
        <View
          style={[
            styles.separatorStyle,
            utilityStyles.px3,
            utilityStyles.pt2,
            { backgroundColor: promoType === 'SystemAlert' ? colors.notification : colors.promoCream },
          ]}>
          <View style={[styles.topBox]}>
            {title && <Text style={styles.headLine}>{title}</Text>}
            <CloseButton onPress={handleClose} style={styles.buttonStyle} size={18} />
          </View>
          <>
            <View style={[{ paddingRight: 20, top: !title ? -15 : 0, marginBottom: !title ? -18 : 0 }]}>
              <HtmlView
                text={source.html ?? ''}
                fontFamily={FontFamily.LarsseitLight}
                textAlign={'left'}
                lineHeight={25}
                textSize={scale(14)}
                textColor={colors.text}
              />
              {linkText && (
                <LinkButton style={[appStyles.bodySmallLight, styles.buttonText, utilityStyles.pb3]} onPress={() => handleNavigation()}>
                  &nbsp;{linkText}
                </LinkButton>
              )}
            </View>
          </>
        </View>
      ) : null}
    </>
  );
};
const styles = StyleSheet.create({
  separatorStyle: {
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  topBox: {
    flexDirection: 'row',
    flex: 1,
  },
  buttonText: {
    textDecorationLine: 'underline',
  },
  headLine: {
    color: colors.text,
    fontSize: 18,
    fontFamily: FontFamily.LarsseitBold,
    paddingRight: 25,
    lineHeight: 25,
  },
  buttonStyle: {
    left: '95%',
  },
});

export default PromoCardComponent;

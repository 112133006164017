import { Button, Icon, LoadingScreen, NavigationHeader, ProductCard, Screen, Text } from '@components';
import CartButton from '@components/CartButton';
import HeaderTitle from '@components/HeaderTitle';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { useEffectOnce } from '@hooks';
import { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useCustomizeStore } from '@store';
import useFavoritesStore from '@store/favoritesStore';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React, { useCallback, useState } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native';
import { hapticNotify } from '../../utils/helpers';
import { getProductsfromCommerceTools } from '../../utils/productHelper';

type ShoppingListScreenProps = AccountStackScreenProps<AccountStackRoutes.ManageShoppingList>;

const ShoppingListScreen = ({ navigation }: ShoppingListScreenProps) => {
  const [listData, setListData] = useState<Product[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const { isFavorite, favorites, toggleFavorite } = useFavoritesStore();

  const customizeStore = useCustomizeStore();

  useEffectOnce(() => {
    if (favorites) {
      getProductsfromCommerceTools(favorites.lineItems.map((x) => x.variant?.sku as string))
        .then((productList) => {
          setListData(productList);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const handlePress = () => {
    navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } });
  };

  const handleFavIconPress = useCallback(
    (productKey: string) => {
      toggleFavorite(productKey).then(() => hapticNotify('notificationSuccess'));
    },
    [toggleFavorite],
  );

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<Product>) => {
      const productKey = item.key as string;
      if (isFavorite(productKey)) {
        return (
          <ProductCard
            product={item}
            productCategory={{ parentId: 'favoriteList', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
            index={index}
          />
        );
      } else {
        return (
          <TouchableOpacity
            onPress={() => {
              handleFavIconPress(productKey);
            }}
            style={styles.overlay}>
            <View>
              <View style={styles.cardWrapper} />
              {/* <Text style={styles.removedText}>Item Removed</Text> */}
              <ProductCard
                product={item}
                productCategory={{ parentId: 'favoriteList', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
                index={index}
              />
            </View>
          </TouchableOpacity>
        );
      }
    },
    [handleFavIconPress, isFavorite],
  );
  const itemSeperator = useCallback(() => <View style={styles.separator} />, []);

  return (
    <Screen testID="ListScreen">
      {loading || customizeStore.customizeLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <NavigationHeader next={<CartButton />}>
            <View style={[{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }, utilityStyles.mt2]}>
              <HeaderTitle>Favorite Items</HeaderTitle>
              {favorites?.lineItems.length && favorites?.lineItems.length > 1 ? (
                <Text>{favorites?.lineItems.length + ' items'}</Text>
              ) : (
                <Text> {favorites?.lineItems.length + ' item'} </Text>
              )}
            </View>
          </NavigationHeader>
          {listData && listData.length > 0 ? (
            <FlatList
              data={listData}
              keyExtractor={(_: Product, idx: number) => idx.toString()}
              renderItem={renderItem}
              numColumns={2}
              columnWrapperStyle={{ paddingBottom: 35 }}
              ItemSeparatorComponent={() => itemSeperator()}
              onEndReachedThreshold={0.5}
            />
          ) : (
            <View style={[{ alignContent: 'space-between', alignItems: 'center' }, utilityStyles.pt5]}>
              <Icon testID="heartIcon" name="heart-icon" stroke={colors.darkCream} size={75} />
              <Text testID="emptyListMessage" style={[utilityStyles.p5, appStyles.fontMobileBodyMediumRegular, { textAlign: 'center' }]}>
                Items you favorite will show up here so you can quicky find them
              </Text>
              <Button type="secondary" size="regular" title={'Find Favorites'} onPress={handlePress} />
            </View>
          )}
        </>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  cartButton: {
    position: 'absolute',
    right: 0,
    top: -36,
  },
  overlay: {
    backgroundColor: colors.tableLightRow,
    opacity: 0.4,
  },
  cardWrapper: {
    opacity: 0.75,
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 999,
  },
  removedText: {
    position: 'absolute',
    top: 65,
    left: 42,
    zIndex: 1,
    color: colors.cream,
    backgroundColor: colors.primary,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: 12,
    lineHeight: 30,
    textAlign: 'center',
    marginTop: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    paddingTop: scale(12),
    paddingRight: scale(12),
    paddingBottom: scale(12),
    paddingLeft: scale(12),
  },
  separator: {
    backgroundColor: colors.darkCream,
    height: 1.5,
    width: screenWidth * 0.92,
    transform: [{ translateX: (screenWidth * 0.08) / 2 }],
  },
});

export default ShoppingListScreen;

import { useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

export interface AppStateHookSettings {
  onChange?: (status: AppStateStatus) => void;
  onForeground?: () => void;
  onBackground?: () => void;
}

export interface AppStateHookResult {
  appState: AppStateStatus;
}

export default function useAppState(settings?: AppStateHookSettings): AppStateHookResult {
  const { onChange, onForeground, onBackground } = settings || {};
  const [appState, setAppState] = useState(AppState.currentState);

  useEffect(() => {
    function handleAppStateChange(nextAppState: AppStateStatus) {
      if (nextAppState === 'active' && appState !== 'active') {
        onForeground && typeof onForeground === 'function' && onForeground();
      } else if (appState === 'active' && nextAppState.match(/inactive|background/)) {
        onBackground && typeof onBackground === 'function' && onBackground();
      }
      setAppState(nextAppState);
      onChange && typeof onChange === 'function' && onChange(nextAppState);
    }
    const unsub = AppState.addEventListener('change', handleAppStateChange);

    return () => unsub.remove();
  }, [onChange, onForeground, onBackground, appState]);

  return { appState };
}

import { useLinkTo } from '@react-navigation/native';
import { appStyles } from '@styles';
import { linktoNavigation } from '@utils/helpers';
import React from 'react';
import { GestureResponderEvent, TextProps } from 'react-native';
import Text from './Text';

interface LinkProps extends TextProps {
  route?: string;
}

const LinkButton = ({ route, children, style, onPress: onClick, ...rest }: LinkProps) => {
  const linkto = useLinkTo();
  const handleOnPress = (event: GestureResponderEvent) => {
    if (onClick) {
      onClick(event);
    } else if (route) {
      linktoNavigation(route, linkto);
    }
  };

  return (
    <Text testID="linkButton" style={[appStyles.link, style]} onPress={handleOnPress} {...rest}>
      {children}
    </Text>
  );
};

export default LinkButton;

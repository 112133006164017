import secureStore from '@utils/secureStorage';
import { StateStorage } from 'zustand/middleware';

const secureStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await secureStore.getItem(name)) ?? null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await secureStore.setItem(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await secureStore.removeItem(name);
  },
};

export default secureStorage;

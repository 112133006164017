import Config from 'react-native-config';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { CakeMessage, ChosenItem, Step } from '../models/customize';
import asyncStorage from './asyncStorage';
import { CustomizeStoreType } from './storeTypes';

const useCustomizeStore = create<CustomizeStoreType>()(
  persist(
    (set, get) => ({
      item: '',
      steps: [],
      currStep: 1,
      subtotal: 0,
      selectedSize: { itemName: '', cost: '' },
      selectedIngredients: [],
      selectedContainer: { itemName: '', cost: '' },
      notes: '',
      cakeMessage: { message: '', color: '', id: '', cost: '', sku: '', sortOrderHint: '' },
      inCart: false,
      itemId: '',
      prevPageData: { id: '', name: '', children: [] },
      savedCustomizedItems: [],
      editCartId: '',
      editSavedForLaterId: '',
      customizeLoading: false,
      initialPage: '',
      prevProductSkus: [],
      configType: '',
      productLeadTime: +(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ?? 60 * 24),
      setPrevProductSkus: (skus: string[] | number[]) => {
        set((state) => ({ ...state, prevProductSkus: skus }), true);
      },
      setInitialPage: (page: string) => {
        set((state) => ({ ...state, initialPage: page }), true);
      },
      setCustomizeLoading: (loading: boolean) => {
        set((state) => ({ ...state, customizeLoading: loading }), true);
      },
      setEditCartId: (editId: string) => {
        set((state) => ({ ...state, editCartId: editId }), true);
      },
      setEditSavedForLaterId: (editId: string) => {
        set((state) => ({ ...state, editSavedForLaterId: editId }), true);
      },
      setPrevPageData: (data: Object) => {
        set((state) => ({ ...state, prevPageData: data }), true);
      },
      setItemId: (id: string) => {
        set((state) => ({ ...state, itemId: id }), true);
      },
      setInCart: (inCart: boolean) => {
        set((state) => ({ ...state, inCart: inCart }), true);
      },
      setItem: (item: string) => {
        set((state) => ({ ...state, item: item }), true);
      },
      setSteps: (steps: Step[]) => {
        set((state) => ({ ...state, steps: steps }), true);
      },
      setCurrStep: (stepNumber: number) => {
        set((state) => ({ ...state, currStep: stepNumber }), true);
      },
      setSubtotal: (subtotal: number) => {
        set((state) => ({ ...state, subtotal: subtotal }), true);
      },
      setSelectedSize: (size: ChosenItem | undefined) => {
        set((state) => ({ ...state, selectedSize: size }), true);
      },
      setSelectedIngredients: (ingredients: any[] | undefined) => {
        set((state) => ({ ...state, selectedIngredients: ingredients }), true);
      },
      setSelectedContainer: (container: ChosenItem | undefined) => {
        set((state) => ({ ...state, selectedContainer: container }), true);
      },
      setNotes: (note: string | undefined) => {
        set((state) => ({ ...state, notes: note }), true);
      },
      setCakeMessage: (message: CakeMessage | undefined) => {
        set((state) => ({ ...state, cakeMessage: message }), true);
      },
      setSavedCustomizedItems: (customizedItems: any[]) => {
        set((state) => ({ ...state, savedCustomizedItems: customizedItems }), true);
      },
      setConfigType: (type: string) => {
        set((state) => ({ ...state, configType: type }), true);
      },
      setProductLeadTime: (leadTime: number) => {
        set((state) => ({ ...state, productLeadTime: leadTime }), true);
      },
      calculateSubtotal: () => {
        var finalAmount = 0;
        var selectedSize = get().selectedSize;
        var selectedContainer = get().selectedContainer;
        var selectedIngredients = get().selectedIngredients;

        var sizeCost: number = +parseFloat(selectedSize?.cost?.toString() ?? '0').toFixed(2);
        var containerCost: number = 0;
        if (selectedContainer?.cost && selectedContainer?.cost !== 'included') {
          containerCost = +parseFloat(selectedContainer?.cost.toString()).toFixed(2);
        }
        var ingredientCost: number = 0;
        selectedIngredients?.forEach((ingr) => {
          var items = ingr.selections;
          items.forEach((item: ChosenItem) => {
            if (isNaN(+item?.cost)) {
              return;
            }
            if (item.description && item.cost !== 'included') {
              var itemCost = +(parseFloat(item?.cost?.toString()) * parseFloat(item?.description?.toString())).toFixed(2);
              ingredientCost += itemCost;
            } else if (item.cost && item.cost !== 'included') {
              ingredientCost += +parseFloat(item.cost?.toString()).toFixed(2);
            }
          });
        });
        finalAmount = +(sizeCost + containerCost + ingredientCost).toFixed(2);
        if (finalAmount) {
          set((state) => ({ ...state, subtotal: finalAmount }), true);
        }
      },
      clearCustomize: () => {
        set(
          (state) => ({
            ...state,
            item: '',
            steps: [],
            currStep: 1,
            subtotal: 0,
            selectedSize: { itemName: '', cost: '' },
            selectedIngredients: [],
            selectedContainer: { itemName: '', cost: '' },
            notes: '',
            cakeMessage: { message: '', color: '', id: '', cost: '', sku: '', sortOrderHint: '' },
            inCart: false,
            itemId: '',
            editCartId: '',
            editSavedForLaterId: '',
            initialPage: '',
            prevProductSkus: [],
            configType: '',
            productLeadTime: +(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ?? 24 * 60),
          }),
          true,
        );
      },
      saveCustomizedItem: () => {
        var curr = get().savedCustomizedItems;
        var selectedSize = get().selectedSize;
        var selectedContainer = get().selectedContainer;
        var selectedIngredients = get().selectedIngredients;
        var notes = get().notes;
        var cakeMessage = get().cakeMessage;
        var itemId = get().itemId;
        set(
          (state) => ({
            ...state,
            savedCustomizedItems: [
              ...curr,
              {
                selectedSize,
                selectedIngredients,
                selectedContainer,
                notes,
                cakeMessage,
                itemId,
              },
            ],
          }),
          true,
        );
      },
      removeCustomizedItem: () => {
        var curr = get().savedCustomizedItems;
        var id = get().itemId;
        set(
          (state) => ({
            ...state,
            savedCustomizedItems: curr.filter((item) => item.itemId !== id),
          }),
          true,
        );
      },
      clearSavedItems: () => {
        set(
          (state) => ({
            ...state,
            savedCustomizedItems: [],
          }),
          true,
        );
      },
    }),
    {
      name: 'uc-customize', // unique name
      storage: createJSONStorage<CustomizeStoreType>(() => asyncStorage), // (optional) by default the 'localStorage' is used
    },
  ),
);

export default useCustomizeStore;

import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Animated, LayoutChangeEvent, StyleProp, StyleSheet, TextProps, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import CheckBox from './Checkbox';
import DropShadow from './DropShadow';
import Icon from './Icon';

type AccordionProps = PropsWithChildren<{
  title: string;
  icon?: JSX.Element;
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  isOpen: boolean;
  textStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  setNextState?: (isOpen: boolean) => void;
  testID?: string;
  shadowEnabled?: boolean;
  showCheckBox?: boolean;
  count?: number | string;
  customMsg?: string;
  customMsgStyle?: StyleProp<TextStyle>;
  aisleBay?: string;
  showCount?: boolean;
  showAisleBay?: boolean;
  showWarning?: boolean;
  warning?: string;
  showLink?: boolean;
  linkText?: string;
  linkAction?: () => void;
  arrowType?: string;
  textProps?: TextProps;
  checked?: boolean;
  hasNote?: boolean;
  togglerValue?: (item: any) => void;
  subTitle?: string;
}>;

const Accordion: React.FC<AccordionProps> = ({
  title,
  isOpen,
  style,
  textStyle,
  icon,
  iconStyle,
  setNextState,
  children,
  testID,
  headerStyle,
  count,
  customMsg,
  customMsgStyle,
  aisleBay,
  shadowEnabled = true,
  showCheckBox = false,
  showCount = false,
  showAisleBay = false,
  showWarning = false,
  warning,
  arrowType = 'primary',
  textProps,
  checked,
  hasNote = false,
  togglerValue,
  showLink = false,
  linkText,
  linkAction,
  subTitle,
}) => {
  const [isO, setIsO] = useState<{ isOpen: boolean; isOpenning: boolean }>({ isOpen: isOpen, isOpenning: false });
  const [layout, setLayout] = useState<{ x: number; y: number; w: number; h: number }>({ x: 0, y: 0, w: 0, h: 0 });
  const [aniState, setAniState] = useState<{ top: Animated.Value }>({
    top: useRef(new Animated.Value(isOpen ? layout.h : 0)).current,
  });

  useEffect(() => {
    setAniState(aniState);
  }, [aniState]);

  useEffect(() => {
    if (isO.isOpen !== isOpen && !isO.isOpenning) {
      // only update when not already set correctly
      setIsO({ isOpen: isO.isOpen, isOpenning: true });
      aniState.top.setValue(!isO.isOpen ? -layout.h : 0);
      Animated.timing(aniState.top, {
        toValue: !isO.isOpen ? 0 : -layout.h,
        duration: 350,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
      }).start((res) => {
        if (res.finished) {
          setIsO({ isOpen: isOpen, isOpenning: false });
        }
      });
    }
  }, [aniState, isO, isOpen, layout.h]);

  const onLayout = (e: LayoutChangeEvent) => {
    const { x, y, width, height } = e.nativeEvent.layout;
    setLayout({ x: x, y: y, w: width, h: height });
  };

  const openingStyle = [styles.contentOpening, { transform: [{ translateY: aniState.top }] }];

  const content =
    isOpen || isO.isOpen || isO.isOpenning ? (
      <Animated.View onLayout={(e) => onLayout(e)} style={[styles.itemsContainer, isO.isOpenning ? openingStyle : {}]}>
        {children}
      </Animated.View>
    ) : (
      <></>
    );

  return (
    <View style={[styles.container, { paddingBottom: !(isOpen || isO.isOpen) ? 3 : 0 }, style]}>
      {shadowEnabled ? (
        <DropShadow style={shadowEnabled ? null : styles.noShadow}>
          <View style={[styles.header, headerStyle]}>
            {showCheckBox && <CheckBox onValueChange={togglerValue} value={checked} />}
            <TouchableOpacity onPress={() => setNextState && setNextState(!isOpen)} style={[styles.iconWrapper]}>
              {icon || (
                <Icon
                  testID="arrowicon"
                  style={iconStyle}
                  size={arrowType === 'primary' ? 35 : 18}
                  name={isOpen ? (arrowType === 'primary' ? 'arrow-circle-up' : 'arrow-up') : arrowType === 'primary' ? 'arrow-circle-down' : 'arrow-down'}
                  fill={'none'}
                  fillSecondary={colors.red}
                  stroke={arrowType === 'primary' ? colors.sectionBorder : colors.text}
                />
              )}
            </TouchableOpacity>
            <TouchableOpacity testID={testID} onPress={() => setNextState && setNextState(!isOpen)}>
              <View style={[styles.titleTexts]}>
                <Text style={[appStyles.h6, utilityStyles.mr5, textStyle]} {...textProps}>
                  <Text style={[appStyles.h6, utilityStyles.mr5, textStyle]} testID="title">
                    {title}
                  </Text>
                </Text>
                {customMsg && (
                  <Text style={[appStyles.bodySmallRegular, styles.customMsg, customMsgStyle]} testID="customMsg">
                    {customMsg}
                  </Text>
                )}
              </View>
              {subTitle && (
                <Text style={styles.subTitleText} testID="subTitle">
                  {subTitle}
                </Text>
              )}
              {showCount || showAisleBay || showLink ? (
                <View>
                  <View style={styles.subView}>
                    {showCount && (
                      <Text style={appStyles.bodySmallLight} testID="count">
                        Qty {count}
                      </Text>
                    )}
                    {showAisleBay && (
                      <>
                        <View style={styles.aisleBay}>
                          <Icon
                            testID="location-pin"
                            name={'location-pin'}
                            stroke={'none'}
                            fill={colors.text}
                            size={13}
                            style={{ transform: [{ translateY: 3 }], marginRight: -4, marginLeft: 8 }}
                          />
                          <Text style={appStyles.bodySmallLight} testID="aisleBayText">
                            {aisleBay}
                          </Text>
                        </View>
                      </>
                    )}
                    {showWarning && (
                      <>
                        <View style={styles.aisleBay}>
                          <Icon
                            testID="flagredicon"
                            name={'flag-red-icon'}
                            stroke={'none'}
                            size={11}
                            style={{ transform: [{ translateY: 4 }], marginRight: -4, marginLeft: 9 }}
                          />
                          <Text testID="warning" style={[appStyles.bodySmallLight, { color: colors.red }]}>
                            {warning}
                          </Text>
                        </View>
                      </>
                    )}
                    {showLink && (
                      <TouchableOpacity onPress={linkAction}>
                        <Text testID="linkText" style={[appStyles.bodyXsRedLinkLeftLight, { marginLeft: 16 }]}>
                          {linkText}
                        </Text>
                      </TouchableOpacity>
                    )}
                    {/* {hasNote && (
                    <View style={styles.noteIndicator}>
                      <Text style={appStyles.bodySmallLight}>Note</Text>
                      <Icon style={{ marginLeft: 2, marginTop: 1 }} name="circle-check" size={18} />
                    </View>
                  )} */}
                  </View>
                  <View>
                    {hasNote && (
                      <View style={styles.noteIndicator}>
                        <Text style={appStyles.bodySmallLight} testID="editNote">
                          Edit Note
                        </Text>
                        <Icon style={{ marginLeft: 2, marginTop: 1 }} name="circle-check" size={18} testID="circle-check" />
                      </View>
                    )}
                  </View>
                </View>
              ) : null}
            </TouchableOpacity>
          </View>
        </DropShadow>
      ) : (
        <View style={[styles.header, headerStyle, { height: hasNote ? scale(83) : scale(73) }]}>
          {showCheckBox && <CheckBox onValueChange={togglerValue} value={checked} />}
          <TouchableOpacity onPress={() => setNextState && setNextState(!isOpen)} style={[styles.iconWrapper]}>
            {icon || (
              <Icon
                testID="arrowUpIcon"
                style={iconStyle}
                size={arrowType === 'primary' ? 35 : 18}
                name={isOpen ? (arrowType === 'primary' ? 'arrow-circle-up' : 'arrow-up') : arrowType === 'primary' ? 'arrow-circle-down' : 'arrow-down'}
                fill={'none'}
                fillSecondary={colors.red}
                stroke={arrowType === 'primary' ? colors.sectionBorder : colors.text}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity testID={testID} onPress={() => setNextState && setNextState(!isOpen)}>
            <View style={[styles.titleTexts]}>
              <Text style={[appStyles.h6, utilityStyles.mr5, textStyle]} {...textProps}>
                <Text style={[appStyles.h6, utilityStyles.mr5, textStyle]} testID="titleTexts">
                  {title}
                </Text>
              </Text>
              {customMsg && (
                <Text style={[appStyles.bodySmallRegular, styles.customMsg, customMsgStyle]} testID="customMsgText">
                  {customMsg}
                </Text>
              )}
            </View>
            {subTitle && (
              <Text style={styles.subTitleText} testID="subTitleText">
                {subTitle}
              </Text>
            )}
            {showCount || showAisleBay || showLink ? (
              <View>
                <View style={styles.subView}>
                  {showCount && (
                    <Text style={appStyles.bodySmallLight} testID="qtyText">
                      Qty {count}
                    </Text>
                  )}
                  {showAisleBay && (
                    <>
                      <View style={styles.aisleBay}>
                        <Icon
                          testID="locationPinIcon"
                          name={'location-pin'}
                          stroke={'none'}
                          fill={colors.text}
                          size={13}
                          style={{ transform: [{ translateY: 3 }], marginRight: -4, marginLeft: 8 }}
                        />
                        <Text style={appStyles.bodySmallLight} testID="aisleBayTxt">
                          {aisleBay}
                        </Text>
                      </View>
                    </>
                  )}
                  {showWarning && (
                    <>
                      <View style={styles.aisleBay}>
                        <Icon
                          testID="flagredicon"
                          name={'flag-red-icon'}
                          stroke={'none'}
                          size={11}
                          style={{ transform: [{ translateY: 4 }], marginRight: -4, marginLeft: 9 }}
                        />
                        <Text style={[appStyles.bodySmallLight, { color: colors.red }]} testID="warning">
                          {warning}
                        </Text>
                      </View>
                    </>
                  )}
                  {showLink && (
                    <TouchableOpacity onPress={linkAction} testID="linkClick">
                      <Text style={[appStyles.bodyXsRedLinkLeftLight, { marginLeft: 16 }]} testID="linkText">
                        {linkText}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View>
                  {hasNote && (
                    <View style={styles.noteIndicator}>
                      <Text style={appStyles.bodySmallLight} testID="editNoteText">
                        Edit Note
                      </Text>
                      <Icon style={{ marginLeft: 2, marginTop: 0 }} name="circle-check" size={18} testID="circle-check" />
                    </View>
                  )}
                </View>
              </View>
            ) : null}
          </TouchableOpacity>
        </View>
      )}
      {content}
    </View>
  );
};

const HEADER_HEIGHT = scale(73);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: screenWidth,
    backgroundColor: colors.cream,

    overflow: 'hidden',
  },
  noteIndicator: {
    flexDirection: 'row',
    marginBottom: -8,
    // position: 'absolute',
    // left: 0,
  },
  aisleBay: {
    flexDirection: 'row',
  },
  contentOpening: {
    zIndex: -1,
  },
  subView: {
    flexDirection: 'row',
  },
  header: {
    alignSelf: 'center',
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
    height: HEADER_HEIGHT,
    flexDirection: 'row',
    paddingHorizontal: (screenWidth - containerWidth) / 2,
  },
  iconWrapper: {
    position: 'absolute',
    right: 8,
    marginRight: (screenWidth - containerWidth) / 4,
  },
  itemsContainer: {
    backgroundColor: colors.cream,
    flexDirection: 'column',
    alignSelf: 'center',
    width: screenWidth,
  },
  items: {
    backgroundColor: colors.cream,
    flexDirection: 'column',
    alignSelf: 'center',
    width: screenWidth,
  },
  noShadow: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    borderWidth: 0,
  },
  subTitleText: {
    fontSize: scale(13),
    color: colors.darkText,
    lineHeight: lineHeight(14),
  },
  titleTexts: {
    flexDirection: 'row',
  },
  customMsg: {
    fontSize: scale(18),
    alignSelf: 'flex-end',
    paddingBottom: 3,
    marginLeft: -7,
  },
});

export default Accordion;

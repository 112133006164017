/* eslint-disable react/no-unstable-nested-components */

import { Icon, LoadingScreen, Modal } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Attribute, Product, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { customizeService } from '@services/commerceTools';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { filterUnavailableProducts } from '@utils/helpers';
import logger from '@utils/logger';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { ChosenItem } from '../../../models/customize';
const COLOR_SIZE = 30;

interface BasicScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.BasicSelection> {
  sizes?: ChosenItem[];
}

interface BasicSelectionProps {
  item: Product;
  pageItem: string;
  limit: number;
  index?: number | string;
  selectedItems: ChosenItem[];
  itemCount: number;
  setSelectedItems: Dispatch<SetStateAction<ChosenItem[]>>;
  setItemCount: Dispatch<SetStateAction<number>>;
}

const BasicSelection: FC<BasicSelectionProps> = ({ index, pageItem, selectedItems, item, itemCount, setItemCount, setSelectedItems }): JSX.Element => {
  const [colorPrice, setColorPrice] = useState(0);
  const variantArr = item?.masterData?.current?.variants;
  const variants = filterUnavailableProducts(variantArr);
  const [color, setColor] = useState('White');
  const colorOptions = [...Array(variants.length)].map((x, i) => {
    return variants[i].attributesRaw.find((attr: Attribute) => attr.name === 'name').value;
  });
  const attr = item?.masterData?.current?.masterVariant.attributesRaw;
  colorOptions.unshift(attr.find((a) => a.name === 'name').value);

  const setColorData = (col: string, idx: number) => {
    setColor(col);
    var colorItem: ProductVariant | Product | undefined;
    if (idx) {
      colorItem = variants[idx - 1];
    } else {
      colorItem = item?.masterData?.current?.masterVariant;
    }
    // console.log(JSON.stringify(colorItem, null, 3));
    var price: number | null = 0;
    var priceDigits: number | null = 0;
    if (colorItem?.price?.value?.centAmount) {
      price = colorItem?.price.value.centAmount;
      priceDigits = colorItem?.price.value.fractionDigits;
    }
    setColorPrice((price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0);
    // console.log(JSON.stringify(item.masterData.current.masterVariant, null, 3));
    // console.log('SKU', colorItem?.sku);
    setSelectedItems([
      {
        id: (colorItem?.id as string) ?? '',
        sku: colorItem?.sku,
        itemName: `${item?.masterData?.current?.name}`,
        cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
        color: col,
      },
    ]);
  };

  useEffect(() => {
    selectedItems[0] && setColor(selectedItems[0].color ?? 'White');
  }, [selectedItems]);

  const ColorPicker = () => {
    return (
      <View style={styles.colorPickerContainer}>
        <Text style={[styles.selectText, { fontFamily: FontFamily.LarsseitBold }]}>
          {color.replace(color[0], color[0].toUpperCase())} {colorPrice ? `(+$${colorPrice})` : null}
        </Text>
        <View style={styles.colorOptionsWrapper}>
          {colorOptions.map((c, indx) => {
            return (
              <View
                key={indx.toString()}
                style={[styles.colorHighlight, selectedItems?.some((sel) => sel?.color === c) ? { borderWidth: 1.5 } : { borderWidth: 0 }]}>
                <TouchableOpacity
                  onPress={() => setColorData(c, indx)}
                  style={[styles.colorOption, { backgroundColor: appConstants.PICKER_COLORS.find((col) => col.name === c)?.color ?? colors.white }]}
                />
              </View>
            );
          })}
          <Text style={[appStyles.bodySmallLight, { alignSelf: 'center', textAlign: 'center', width: '100%' }]}>
            Color may vary from what is shown on screen
          </Text>
        </View>
      </View>
    );
  };

  const setSelection = () => {
    var price: number | null = null;
    var priceDigits: number | null = null;
    if (item?.masterData?.current?.masterVariant?.price) {
      price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
      priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
    }
    if (selectedItems?.some((selection) => selection.itemName.includes(item?.masterData?.current?.name?.trim() ?? '&nbsp'))) {
      setSelectedItems(selectedItems.filter((selection) => !selection.itemName.includes(item?.masterData?.current?.name?.trim() ?? '&nbsp')));
      setItemCount(itemCount - 1);
    } else {
      setSelectedItems([
        {
          id: item?.id,
          sku: item?.masterData?.current?.masterVariant?.sku ?? '',
          itemName: item?.masterData?.current?.name?.trim() ?? '',
          cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
          color: item?.masterData?.current?.masterVariant?.attributesRaw.find((at) => at.name === 'name')?.value,
        },
      ]);
      setItemCount(itemCount + 1);
    }
  };

  return (
    <View testID={`basicSelection-${index}`} style={styles.sizeItemContainer}>
      <TouchableOpacity testID={`basicSelection-${index}-btn`} activeOpacity={0.7} style={styles.checkButton} onPress={setSelection}>
        {/* {selectedItems?.some((selection) => selection.itemName.includes(item?.masterData?.current?.name)) ? ( */}
        {selectedItems?.some((selection) => selection.itemName.trim() === item?.masterData?.current?.name?.trim()) ? (
          <Icon
            name="checked-circle"
            fill={colors.secondary}
            stroke={'none'}
            strokeSecondary={colors.white}
            fillSecondary={'none'}
            style={appStyles.mediumIcon}
            size={25}
          />
        ) : (
          <Icon name="unchecked-circle" fill={colors.white} stroke={colors.darkText} style={appStyles.mediumIcon} size={25} />
        )}
      </TouchableOpacity>
      <View style={styles.sizeInfoTextWrapper}>
        <TouchableOpacity activeOpacity={0.7} onPress={setSelection}>
          <Text
            style={
              selectedItems?.some((selection) => selection.itemName.split('-')[0].trim() === item?.masterData?.current?.name)
                ? appStyles.customizeLabelSmBold
                : appStyles.customizeLabelSm
            }>
            {pageItem.toLowerCase().includes('border') ? <Text>{item?.masterData?.current?.variants.length ? 'Yes - ' : 'No - '}</Text> : null}
            {item?.masterData?.current?.name}
          </Text>
        </TouchableOpacity>
        {item?.masterData?.current?.variants.length && (pageItem === 'Icing' || pageItem.toLowerCase().includes('border')) ? (
          <Text
            style={
              selectedItems?.some((selection) => selection.itemName.split('-')[0].trim() === item?.masterData?.current?.name)
                ? appStyles.customizeLabelSmBold
                : appStyles.customizeLabelSm
            }>
            (Color options available)
          </Text>
        ) : null}
        {item?.masterData?.current?.name === 'Whipped Cream' && (
          <Text style={[appStyles.customizeLabelSm, styles.whippedCreamNote]}>
            (Note: Dark-colored decorations or messages may run or fade on whipped cream.)
          </Text>
        )}
        {item?.masterData?.current?.variants.length &&
        (pageItem === 'Icing' || pageItem.toLowerCase().includes('border')) &&
        selectedItems?.some((selection) => selection.itemName.includes(item?.masterData?.current?.name ?? '&nbsp')) ? (
          <View style={styles.colorPickerWrapper}>
            <ColorPicker />
          </View>
        ) : null}
      </View>
    </View>
  );
};

const BasicSelectionScreen: FC<BasicScreenProps> = ({ route }): JSX.Element => {
  const navigation = useNavigation();
  const { pageItem = '', limit, catIds } = route.params;
  const { selectedIngredients, setSelectedIngredients, calculateSubtotal, currStep, selectedSize } = useCustomizeStore();
  const [selectedItems, setSelectedItems] = useState<ChosenItem[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [showInfo, setShowInfo] = useState(false);
  // const [choices, setChoices] = useState<Product[]>([]);
  const {
    data: choices,
    status,
    refetch,
  } = useQuery(`${pageItem.toLowerCase().replace(/\s/g, '')}-selections-list`, () => customizeService.getNonsellableProducts({ catId: catIds }), {
    staleTime: 0,
    cacheTime: 0,
    initialData: undefined,
    select: (d) => {
      d?.sort(
        (a, b) =>
          +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds[0])?.orderHint || 0.8) -
          +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds[0])?.orderHint || 0.8),
      );
      return filterUnavailableProducts(d);
    },
  });
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [scrollIndicatorVisible, setScrollIndicatorVisible] = useState(false);

  useEffect(() => {
    if (status === 'success') {
      if ((choices?.length ?? 0) > 4) {
        setScrollIndicatorVisible(true);
      }
      setIsLoadingProducts(false);
    } else {
      logger.log(status);
      setIsLoadingProducts(true);
    }
  }, [choices, status]);

  const ChoiceDescriptionLink = () => {
    return (
      <TouchableOpacity onPress={() => setShowInfo(true)}>
        <Text style={[appStyles.fontShowMoreLink, styles.choiceDescriptionLink]}>{pageItem} Descriptions</Text>
      </TouchableOpacity>
    );
  };

  //Function updates the context state as local selectedItems change
  useEffect(() => {
    if (!selectedItems.length) {
      return;
    }
    if (selectedIngredients?.some((ing) => ing.ingredientName === pageItem)) {
      var tempArr = selectedIngredients;
      var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

      tempArr[ingredientIndex] = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients(tempArr);
      // console.log('set tsx.239 selectionscreen: ', JSON.stringify(selectedIngredients));
    } else if (selectedItems.length) {
      var newIngredient = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients([...selectedIngredients, newIngredient]);
    } else if (selectedItems.length === 0) {
      var tempArr = selectedIngredients;
      var filteredArr = tempArr.filter((ingr) => ingr.ingredientName !== pageItem);

      setSelectedIngredients(filteredArr);
    }
    calculateSubtotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    var forwardEnabled = !selectedItems[0] || itemCount < limit ? false : true;
    navigation.setParams({ forwardNavEnabled: forwardEnabled });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCount, pageItem, currStep]);

  //Sets the local selectedItems
  const setSelected = () => {
    if ((selectedIngredients?.length ?? 0) > 0) {
      if (selectedIngredients?.some((ingr) => ingr.ingredientName === pageItem)) {
        var tempArr = selectedIngredients;
        var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);
        setSelectedItems(tempArr[ingredientIndex].selections);

        setItemCount(tempArr[ingredientIndex].selections.length);
      }
    }
  };

  useEffect(() => {
    refetch();
    setSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItem]);

  return isLoadingProducts ? (
    <LoadingScreen />
  ) : (
    <>
      {pageItem === 'Icing' || pageItem === 'Donut Type' ? (
        <Modal
          style={{ minHeight: screenHeight * 0.4, maxHeight: screenHeight * 0.8, padding: 0 }}
          contentStyle={styles.modalContent}
          title={`${pageItem} Descriptions`}
          titleStyle={{ lineHeight: lineHeight(24), paddingTop: 10 }}
          headerStyle={{ minHeight: 30, alignItems: 'center', justifyContent: 'center' }}
          location={'top'}
          visible={showInfo}
          okButtonStyle={[appStyles.secondaryButtonSmall, { width: 130, bottom: -2 }]}
          buttonContainerStyle={{ alignItems: 'center', marginVertical: 0, height: 80 }}
          cancelButtonType={'secondary'}
          cancelButtonOnPress={() => setShowInfo(false)}
          cancelButtonText={'Close'}
          showScrollHint={(choices?.length ?? 0) > 4 ? true : false}>
          <View style={{ width: screenWidth, paddingHorizontal: 8 }}>
            <View style={styles.modalScrollView}>
              {choices?.map((flavor, index) => {
                return (
                  <View style={[styles.modalText, index === 0 ? { marginTop: 0 } : null]} key={index.toString()}>
                    <Text style={[appStyles.bodyLeftBold]}>{flavor?.masterData?.current?.name}</Text>
                    <Text style={appStyles.bodyLargeLeft}>
                      {flavor?.masterData?.current?.masterVariant.attributesRaw.find((a) => a.name === 'longDescription')?.value || ''}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        </Modal>
      ) : null}
      <View style={styles.container}>
        <ScrollView bounces={false} style={styles.body}>
          <View style={styles.bodySection}>
            <Text style={styles.chooseYourSizeText}>Select {pageItem}</Text>
            {pageItem.toLowerCase().includes('border color') ? (
              <>
                <Text style={[appStyles.customizeLabelSm, { fontSize: scale(18), marginBottom: 16 }]}>Would you like to customize your border color?</Text>
                <Text style={[appStyles.bodySmallLeftRedLight, { lineHeight: lineHeight(18), marginBottom: 16, color: colors.red }]}>
                  Note: Cream Cheese and Whipped Cream icing cannot have customized borders.
                </Text>
              </>
            ) : null}
            {(pageItem === 'Icing' && !selectedSize?.itemName?.toLowerCase().includes('donut')) || pageItem === 'Donut Type' ? <ChoiceDescriptionLink /> : null}
            <View style={styles.listContainer}>
              {choices?.map((choice, index) => {
                return (
                  <BasicSelection
                    key={index.toString()}
                    pageItem={pageItem}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    limit={limit ? +limit : 0}
                    itemCount={itemCount}
                    setItemCount={setItemCount}
                    item={choice}
                    index={index}
                  />
                );
              })}
            </View>
          </View>
        </ScrollView>
        <View style={styles.footerWrapper}>
          <CustomizeFooter disabled={!selectedItems[0] || itemCount < (limit ?? 1) ? true : false} />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    paddingVertical: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
  },
  scrollIndicator: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  subText: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: 'Larsseit',
    color: colors.darkText,
  },
  colorPickerContainer: {
    width: '88%',
    minHeight: 200,
    flexGrow: 1,
    alignItems: 'center',
    // backgroundColor: 'gold',
  },
  colorPickerWrapper: {
    alignSelf: 'center',
    width: screenWidth,
    flexGrow: 1,
    marginVertical: 32,
    // backgroundColor: 'red',
  },
  colorOptionsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  colorOption: {
    width: COLOR_SIZE,
    height: COLOR_SIZE,
    borderRadius: COLOR_SIZE / 2,
    marginHorizontal: 16,
    marginBottom: 32,
    borderWidth: 0.5,
    borderColor: colors.greyBorder,
  },
  colorHighlight: {
    width: COLOR_SIZE * 1.35,
    height: COLOR_SIZE * 1.35,
    borderRadius: (COLOR_SIZE * 1.35) / 2,
    marginHorizontal: 12,
    marginBottom: 32,
    // borderWidth: 1.5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 3.5,
    paddingRight: 0,
  },
  choiceDescriptionLink: {
    alignSelf: 'center',
    marginBottom: 15,
    marginTop: -5,
  },
  selectText: {
    fontSize: scale(20.5),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 20,
  },
  modalContent: {
    height: '100%',
    margin: 0,
    marginTop: -50,
    paddingHorizontal: 0,
    paddingTop: 5,
    alignItems: 'flex-start',
    width: screenWidth,
    paddingBottom: 0,
    // paddingBottom: screenHeight < 740 ? 45 : 25,
    // backgroundColor: 'gold',
  },
  modalScrollView: {
    // minHeight: screenHeight * 0.5,
    flexGrow: 1,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 25,
    marginBottom: 8,
  },
  modalText: {
    marginVertical: 10,
  },
  body: {
    flex: 0.65,
    width: '100%',
    paddingHorizontal: 16,
    paddingTop:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.01 : screenHeight * 0.01) : screenHeight > 640 ? screenHeight * 0.01 : screenHeight * 0.01,
  },
  bodySection: {
    backgroundColor: colors.white,
    width: '95%',
    alignSelf: 'center',
    borderRadius: 20,
    paddingHorizontal: 25,
    paddingTop: 25,
    marginBottom: 50,
    paddingBottom: 25,
    // overflow: 'scroll',
  },
  sizeText: {
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 12,
  },
  chooseYourSizeText: {
    fontSize: scale(20),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 30,
  },
  listContainer: {
    width: '100%',
    paddingBottom: 0,
  },
  contentContainer: {
    justifyContent: 'space-between',
  },
  sizeItemContainer: {
    width: '100%',
    minHeight: screenHeight * 0.02,
    flexGrow: 1,
    flexDirection: 'row',
    marginVertical: 0,
    // backgroundColor: 'red',
  },
  sizeInfoTextWrapper: {
    marginLeft: 5,
    marginTop: screenHeight > 740 ? 25 : 18,
    width: '80%',
    // minHeight: 50,
    // flexGrow: 1,
    justifyContent: 'center',
    // backgroundColor: 'green',
  },
  itemSizeText: {
    fontSize: scale(18),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    lineHeight: lineHeight(18),
  },
  servesText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit',
    color: colors.darkText,
  },
  checkButton: {
    marginRight: 16,
    // justifyContent: 'center',
    // marginBottom: 12,
    marginTop: screenHeight > 740 ? 22 : 16,
  },
  footerWrapper: {
    paddingTop: 0,
    alignItems: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -100,
  },
  whippedCreamNote: {
    width: '100%',
    alignSelf: 'flex-end',
    marginTop: 5,
  },
});

export default BasicSelectionScreen;

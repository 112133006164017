import { CloseButton, DropShadow, Text } from '@components';
import colors from '@config/colors';
import { AppStackRoutes, AppStackScreenProps, AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { appStyles } from '@styles';
import { scale } from '@styles/constants';
import logger from '@utils/logger';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import WebView from 'react-native-webview';

type WebBrowserScreenProp = AppStackScreenProps<AppStackRoutes.WebBrowser> | AuthStackScreenProps<AuthStackRoutes.WebBrowser>;

const WebBrowserScreen = ({ route }: WebBrowserScreenProp) => {
  const { url, title } = route.params;
  const [state, setState] = useState<{ loading: boolean }>({ loading: true });
  const webViewRef = useRef<WebView>();
  const runBeforeFirst = `window.onerror = function(message, sourcefile, lineno, colno, error) {
    window.ReactNativeWebView.postMessage("Message: " + message + " - Source: " + sourcefile + " Line: " + lineno + ":" + colno);
    return true;
  };
  window.isNativeApp = true;
  window.name = "webLink";
  true; // note: this is required, or you'll sometimes get silent failures
`;
  const jsCode = `
    function handlePageLoad() {
      var css = '#header, #footer, #breadcrumb, #navigation { display:none !important; }; #main { margin-top: 0 !important; };'
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style'); 
      style.setAttribute('type', 'text/css');
      if (style.styleSheet){
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    } 

    if (window.addEventListener) {
      window.addEventListener('load', handlePageLoad, false);
    } else { 
      window.attachEvent('onload', handlePageLoad, false);
    }
  `;
  useEffect(() => {
    return () => {
      setState((prev) => ({ ...prev, loading: true }));
    };
  }, []);
  return (
    <View style={styles.container}>
      <DropShadow style={styles.dropshadowContainer}>
        <View style={styles.header}>
          <CloseButton style={styles.closeButton} />
          <Text style={styles.h3BoldCenter} testID="title">
            {title}
          </Text>
        </View>
      </DropShadow>
      <View style={styles.browserContainer}>
        <WebView
          ref={(ref) => (webViewRef.current = ref ?? undefined)}
          style={state.loading && { display: 'none' }}
          sharedCookiesEnabled={true}
          source={{
            uri: url,
            headers: {
              'X-Native-Webview': 'true',
            },
          }}
          startInLoadingState={true}
          setSupportMultipleWindows={false}
          scalesPageToFit={false}
          contentMode="mobile"
          injectedJavaScript={jsCode}
          injectedJavaScriptBeforeContentLoaded={runBeforeFirst}
          onLoadEnd={() => {
            setState((prev) => ({ ...prev, loading: false }));
          }}
          onMessage={(event) => {
            logger.log(event.nativeEvent.data);
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    left: scale(7),
    top: 16,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    paddingVertical: 10,
    textAlign: 'center',
    marginLeft: scale(28),
    display: 'none',
  },
  dropshadowContainer: {
    height: 50,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    alignContent: 'center',
  },
  container: {
    flex: 1,
  },
  browserContainer: {
    flex: 1,
  },
  header: {
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default WebBrowserScreen;

import { BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Image } from '../image';

const ProductListingCustomContent = ({ page, component }: BrProps) => {
  const { ProductListingCustomContent: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  if (!document) {
    return null;
  }
  // Get the document data
  const { image: imageRef } = document.getData();
  const image = imageRef && page.getContent<ImageSet>(imageRef);
  let categoryData = document.getData().categoryCard;

  if (categoryData) {
    for (let i = 0; i < categoryData.length; i++) {
      categoryData[i].Image = page.getContent<ImageSet>(categoryData[i].categoryImage);
      categoryData[i].ImageUrl = categoryData[i].Image?.getOriginal()?.getUrl();
    }
  }
  return (
    <>
      <View style={styles.container}>
        {image && <Image style={styles.image} source={{ cache: 'web', uri: image?.getOriginal()?.getUrl() }} resizeMode="contain" />}
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    height: 'auto',
    paddingHorizontal: 0,
    flex: 1,
  },
  image: {
    width: '100%',
    height: 300,
  },
});

export default ProductListingCustomContent;

import { CloseButton, KeyboardAvoidingScreen, KeyboardAvoidingScrollView, LinkButton, Text } from '@components';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { authService } from '@services/brandywine';
import { useAuthStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, ScrollView, StyleSheet, View } from 'react-native';
import * as Yup from 'yup';

type ForgotPasswordScreenProp = AuthStackScreenProps<AuthStackRoutes.ForgotPassword>;

const ForgotPasswordScreen = ({ navigation }: ForgotPasswordScreenProp) => {
  const { t } = useTranslation('forgotPassword');
  const { current } = useCardAnimation();
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const { username } = useAuthStore();

  const emailRef = React.createRef<TextInput>();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(t('emailValidationMsg')).label('Email'),
  });

  const onSubmit = async ({ email }: any) => {
    setIsButtonLoading(true);
    setEmailNotFound(false);
    try {
      const result = await authService.forgotPassword(email);
      if (result === false) {
        emailRef.current?.clear();
      }
    } catch (error) {
    } finally {
      emailRef.current?.clear();
      navigateToVerifyOTC(email);
      setIsButtonLoading(false);
    }
  };

  const navigateToVerifyOTC = (email: string | undefined) => {
    navigation.replace(AuthStackRoutes.VerifyOTC, { email: email });
  };

  return (
    <KeyboardAvoidingScreen keyboardVerticalOffset={-100} style={{ backgroundColor: 'transparent' }}>
      <Animated.View
        testID={'forgotPasswordView'}
        style={[
          styles.container,
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.65, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal} testID={'FPMainView'}>
          <View style={styles.header} testID={'closeBtnView'}>
            <CloseButton style={styles.closeButton} />
          </View>
          <ScrollView style={styles.form} testID={'FPScrollView'}>
            <KeyboardAvoidingScrollView
              testID="FPKeyboardScrollView"
              scrollEnabled={true}
              extraKeyboardOffset={240}
              contentContainerStyle={appStyles.container}
              bounces={false}>
              <View style={styles.textWrapper} testID={'titleView'}>
                <Text style={styles.h3BoldCenter} testID="forgotPasswordTitle">
                  {t('forgotPasswordTitle')}
                </Text>
                <Text style={styles.bodyLargeCenter} testID="passwordInstruction">
                  {t('passwordInstruction')}
                </Text>
                <LinkButton testID="haveOTCLink" onPress={() => navigateToVerifyOTC(username)} style={styles.boldLinkLargeCenter}>
                  {t('haveOTC')}
                </LinkButton>
              </View>
              <View style={styles.form}>
                <Form initialValues={{ email: username }} onSubmit={onSubmit} validationSchema={validationSchema}>
                  <ErrorMessage error={t('errorMsg')} visible={emailNotFound} style={{ textAlign: 'center' }} testID="fpErrorMsgs" />
                  <FormField
                    testID="email"
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="email-address"
                    name="email"
                    textContentType="emailAddress"
                    ref={emailRef}
                  />
                  <View style={styles.buttonsContainer}>
                    <SubmitButton testID="resetBtn" title={t('resetBtnTitle')} isButtonLoading={isButtonLoading} />
                    <LinkButton
                      testID="createAccountButton"
                      onPress={() => navigation.navigate(AuthStackRoutes.CreateAccountInitial, {})}
                      style={styles.boldLinkLargeCenter}>
                      {t('createAccountButton')}
                    </LinkButton>
                  </View>
                </Form>
              </View>
            </KeyboardAvoidingScrollView>
          </ScrollView>
        </View>
      </Animated.View>
    </KeyboardAvoidingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: screenHeight * 0.85,
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  textWrapper: {
    paddingHorizontal: 20,
    paddingBottom: 25,
  },
  bodyLargeCenter: {
    ...appStyles.bodyLargeCenter,
    marginTop: 15,
  },
  form: {
    width: '100%',
    paddingHorizontal: 20,
    alignSelf: 'center',
  },
  errorWrapper: {
    width: 330,
    height: 25,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: scale(16),
    fontFamily: 'Larsseit-Light',
  },
  errorBorder: {
    borderColor: colors.red,
  },
  buttonsContainer: {
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 25,
  },
  textInfo: {
    fontSize: scale(18),
    textAlign: 'center',
    lineHeight: lineHeight(18),
    marginBottom: 45,
    fontFamily: FontFamily.LarsseitLight,
    width: screenWidth * 0.8,
    alignSelf: 'center',
  },
  addLink: {
    padding: 5,
    right: 0,
    position: 'absolute',
    top: 40,
    height: 30,
  },
});

export default ForgotPasswordScreen;

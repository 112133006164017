import { locale } from '@styles/constants';
import logger from '@utils/logger';
import { create } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import Config from 'react-native-config';
import { Step } from '../models/customize';
import { getJwtToken } from './authHelper';
const apiClient = create({ baseURL: Config.CT_API_URL });

// if (__DEV__) {
//   var axiosLogger = require('debug')('axios');
//   require('axios-debug-log').addLogger(apiClient.axiosInstance, axiosLogger);
// }

apiClient.addAsyncRequestTransform(async (request: AxiosRequestConfig) => {
  const jwtToken = await getJwtToken();
  request.headers = { ...request.headers, Authorization: `Bearer ${jwtToken}` };
});

const getCustomizeInfo = async (productData: any) => {
  const configOptionsId = productData.masterData.current.masterVariant.attributesRaw.find((val: any) => val.name === 'configurationOptions').value.id;
  const configOptions = await getCategoryInfo(configOptionsId);
  const selectionStepsQuery = await getSubcategoryByParentId(configOptions.id);
  const selectionStepsData = selectionStepsQuery?.results?.sort((a, b) => a.orderHint - b.orderHint);
  if (!selectionStepsData) {
    return undefined;
  }

  var name = productData?.masterData?.current?.name[`${locale}`] || productData.masterData.current.name;
  var stepInfo: Step;

  //Build and Push Size step
  const customizeInfo = {
    itemName: name,
    id: productData.id,
    productType: productData.productType.name,
    steps: Array<typeof stepInfo>(0),
  };
  var tempSizeName = productData.masterData.current.masterVariant.attributesRaw.find((attr: any) => attr.name === 'name').value;
  if (productData.masterData.current.variants.length) {
    // logger.log(JSON.stringify(productData, null, 2));
    stepInfo = {
      pageItem: 'Size',
      selectionOption: '',
      choices: [
        {
          id: productData.masterData.current.masterVariant.id,
          sku: productData.masterData.current.masterVariant.sku,
          itemName: tempSizeName,
          // itemName: tempSizeName.split('-')[1]?.trim(),
          cost:
            productData.masterData.current.masterVariant.price.value.centAmount / 10 ** productData.masterData.current.masterVariant.price.value.fractionDigits,
          description: productData.masterData.current.masterVariant.attributesRaw
            .find((val: any) => val.name === 'feeds')
            ?.value?.replace('to', '-')
            ?.replace(/\s/g, ''),
        },
      ],
      limit: 1,
      min: 1,
      orderHint: 0,
      catIds: [],
    };

    for (let j = 0; j < productData.masterData.current.variants.length; j++) {
      tempSizeName = productData.masterData.current.variants[j].attributesRaw.find((attr: any) => attr.name === 'name').value;
      stepInfo?.choices?.push({
        id: productData.masterData.current.variants[j].id,
        sku: productData.masterData.current.variants[j].sku,
        itemName: tempSizeName,
        // itemName: tempSizeName.split('-')[1]?.trim(),
        cost: productData.masterData.current.variants[j].price.value.centAmount / 10 ** productData.masterData.current.variants[j].price.value.fractionDigits,
        description: productData.masterData.current.variants[j].attributesRaw
          ?.find((val: any) => val.name === 'feeds')
          ?.value?.replace('to', '-')
          ?.replace(/\s/g, ''),
      });
    }
    if ((stepInfo?.choices?.length ?? 0) < 1) {
      logger.error('Failed to fetch customize products');
      return undefined;
    }
    customizeInfo.steps.push(stepInfo);
  }

  //Build and push selection steps
  for (let i = 0; i < selectionStepsData.length; i++) {
    stepInfo = {
      pageItem: selectionStepsData[i].name[`${locale}`],
      selectionOption:
        selectionStepsData[i].name[`${locale}`] === 'Message' ? '' : selectionStepsData[i]?.custom?.fields?.allowMultiQty ? 'quantity' : 'select',
      // selectionStepsData[i].name[`${locale}`] === 'Message' ? '' : selectionStepsData[i]?.custom?.fields?.allowMultiQty ? 'quantity' : 'select',
      // choices: selectionProducts,
      catIds: [selectionStepsData[i].id],
      limit: selectionStepsData[i]?.custom?.fields?.max,
      min: selectionStepsData[i]?.custom?.fields?.min,
      orderHint: selectionStepsData[i]?.orderHint,
      slug: selectionStepsData[i].slug[`${locale}`],
    };
    // logger.log('STEP INFO', JSON.stringify(selectionStepsData[i], null, 2));
    customizeInfo.steps.push(stepInfo);
  }

  //Push Notes and Review Steps
  customizeInfo.steps.push({
    pageItem: 'Notes',
    selectionOption: '',
    choices: [],
    limit: 1,
  });
  customizeInfo.steps.push({
    pageItem: 'Review',
    selectionOption: '',
    choices: [],
    limit: 1,
  });

  return customizeInfo;
};

const getCakeBuilderInfo = async (productData: any) => {
  const configOptionsId = productData.masterData.current.masterVariant.attributesRaw.find((val: any) => val.name === 'configurationOptions').value.id;
  const configOptions = await getCategoryInfo(configOptionsId);
  const selectionStepsQuery = await getSubcategoryByParentId(configOptions.id);
  const selectionStepsData = selectionStepsQuery?.results?.sort((a, b) => a.orderHint - b.orderHint);
  if (!selectionStepsData) {
    return undefined;
  }
  var name = productData?.masterData?.current?.name[`${locale}`] || productData.masterData.current.name;
  var stepInfo: Step;

  //Build and Push Size step
  const customizeInfo = {
    itemName: name,
    id: productData.id,
    productType: productData.productType.name,
    steps: Array<typeof stepInfo>(0),
  };
  var tempSizeName = productData.masterData.current.masterVariant.attributesRaw.find((attr: any) => attr.name === 'name').value;
  if (productData.masterData.current.variants.length) {
    //if (tempSizeName !== 'Custom Bundt Cake' && !tempSizeName.includes('Donut')) {
    stepInfo = {
      pageItem: 'Size',
      selectionOption: '',
      choices: [
        {
          id: productData.masterData.current.masterVariant.id,
          sku: productData.masterData.current.masterVariant.sku,
          itemName: tempSizeName.split('-')[1]?.trim(),
          cost:
            productData.masterData.current.masterVariant.price.value.centAmount / 10 ** productData.masterData.current.masterVariant.price.value.fractionDigits,
          description: productData.masterData.current.masterVariant.attributesRaw
            ?.find((val: any) => val.name === 'feeds')
            ?.value?.replace('to', '-')
            ?.replace(/\s/g, ''),
        },
      ],
      limit: 1,
      min: 1,
      orderHint: 0,
      catIds: [],
    };

    for (let j = 0; j < productData.masterData.current.variants.length; j++) {
      tempSizeName = productData.masterData.current.variants[j].attributesRaw.find((attr: any) => attr.name === 'name').value;
      stepInfo?.choices?.push({
        id: productData.masterData.current.variants[j].id,
        sku: productData.masterData.current.variants[j].sku,
        itemName: tempSizeName.split('-')[1]?.trim(),
        cost: productData.masterData.current.variants[j].price.value.centAmount / 10 ** productData.masterData.current.variants[j].price.value.fractionDigits,
        description: productData.masterData.current.variants[j].attributesRaw
          ?.find((val: any) => val.name === 'feeds')
          ?.value?.replace('to', '-')
          ?.replace(/\s/g, ''),
      });
    }
    if ((stepInfo?.choices?.length ?? 0) < 1) {
      logger.error('Failed to fetch customize products');
      return undefined;
    }
    if (stepInfo?.choices && stepInfo?.choices[0]?.itemName?.includes('Quarter')) {
      var ordering = {};
      var sortOrder = ['Quarter', 'Half', 'Full'];
      for (var z = 0; z < sortOrder.length; z++) {
        ordering[sortOrder[z]] = z;
      }
      stepInfo?.choices?.sort((a, b) => {
        return ordering[a.itemName] - ordering[b.itemName] || a.itemName.localeCompare(b.itemName);
      });
    }
    customizeInfo.steps.push(stepInfo);
  }

  //Build and push selection steps
  for (let i = 0; i < selectionStepsData.length; i++) {
    stepInfo = {
      pageItem: selectionStepsData[i].name[`${locale}`],
      selectionOption:
        selectionStepsData[i].name[`${locale}`] === 'Message' ? '' : selectionStepsData[i]?.custom?.fields?.allowMultiQty ? 'quantity' : 'select',
      // choices: selectionProducts,
      catIds: [selectionStepsData[i].id],
      limit: selectionStepsData[i]?.custom?.fields?.max,
      min: selectionStepsData[i]?.custom?.fields?.min,
      orderHint: selectionStepsData[i]?.orderHint,
      slug: selectionStepsData[i].slug[`${locale}`],
    };
    // logger.log('STEP INFO', JSON.stringify(selectionStepsData[i], null, 2));
    customizeInfo.steps.push(stepInfo);
  }

  //Push Message and Review Steps
  customizeInfo.steps.push({
    pageItem: 'Review',
    selectionOption: '',
    choices: [],
    limit: 1,
    min: 1,
    orderHint: 1,
    catIds: [],
  });
  return customizeInfo;
};

const getCategoryInfo = async (categoryId: string) => {
  const categoryData = await apiClient.get<any>(`/categories/${categoryId}/?expand=ancestors[*]`);
  return categoryData.ok ? categoryData.data : false;
};

const getSubcategoryByParentId = async (categoryId: string) => {
  const categoryData = await apiClient.get<any>(`/categories/?where=parent%28id%3D%22${categoryId}%22%29`);
  return categoryData.ok ? categoryData.data : false;
};

export default {
  getCustomizeInfo,
  getCakeBuilderInfo,
  getSubcategoryByParentId,
};

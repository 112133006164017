import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { StyleSheet } from 'react-native';
import { containerWidth, defaultFontSize, lineHeight, scale, screenHeight, screenWidth } from './constants';

const appStyles = StyleSheet.create({
  'screen': {
    flex: 1,
    width: screenWidth,
    alignSelf: 'center',
    justifyContent: 'flex-start',
  },

  'container': {
    width: '100%',
    paddingTop: 5,
    paddingHorizontal: 8,
  },
  'brxmContainer': {
    width: '100%',
    flex: 1,
    height: '65%',
  },
  'row': {
    width: '100%',
    paddingTop: 20,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  'column': {
    width: '100%',
    paddingTop: 20,
    paddingHorizontal: 8,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  'scrollView': { position: 'relative', marginTop: 10, marginBottom: 60 },
  'text': {
    fontSize: scale(defaultFontSize),
    fontFamily: FontFamily.LarsseitLight,
  },
  'link': {
    textDecorationLine: 'underline',
    fontSize: scale(defaultFontSize),
    lineHeight: lineHeight(defaultFontSize),
    fontFamily: FontFamily.LarsseitLight,
  },
  'smallLink': {
    textDecorationLine: 'underline',
    fontSize: scale(defaultFontSize * 0.8),
    fontFamily: FontFamily.AleoBold,
  },
  'footerLink': {
    textDecorationLine: 'underline',
    fontSize: scale(defaultFontSize * 0.8),
    fontFamily: FontFamily.LarsseitLight,
  },
  'smallIcon': {
    width: 15,
    height: 15,
  },
  'icon': {
    width: 20,
    height: 20,
  },
  'mediumIcon': {
    width: 25,
    height: 25,
  },
  'largeIcon': {
    width: 35,
    height: 35,
  },
  'homeScreenContainer': {
    height: 'auto',
    flex: 1,
    padding: 16,
    position: 'relative',
    width: containerWidth,
    top: 0,
  },

  'textInput': {
    borderRadius: 15,
    flexDirection: 'row',
    padding: 10,
    marginVertical: 10,
    fontSize: scale(defaultFontSize),
  },

  'headerText': {
    fontSize: scale(defaultFontSize * 1.2),
    fontWeight: 'bold',
    textAlign: 'center',
    paddingVertical: 10,
    fontFamily: FontFamily.LarsseitBold,
  },
  'carouselImg': {
    width: '100%',
    height: 'auto',
  },
  'carouselDots': {
    backgroundColor: colors.white,
    opacity: 0.75,
    width: 10,
    height: 10,
    borderRadius: 100,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 4,
    marginLeft: 4,
  },
  'carouselDotsActive': {
    backgroundColor: colors.white,
    opacity: 1,
    width: 10,
    height: 10,
    borderRadius: 100,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 4,
    marginLeft: 4,
  },
  'carouselDotsSmall': {
    backgroundColor: colors.primary,
    opacity: 0.2,
    width: 7,
    height: 7,
    borderRadius: 100,
    marginTop: 3,
    marginRight: 3,
    marginBottom: 3,
    marginLeft: 3,
  },
  'carouselDotsSmallActive': {
    backgroundColor: colors.primary,
    opacity: 1,
    width: 7,
    height: 7,
    borderRadius: 100,
    marginTop: 3,
    marginRight: 3,
    marginBottom: 3,
    marginLeft: 3,
  },
  'cartIcon': {
    color: colors.white,
    // fontSize: 14,
    // fontFamily: FontFamily.LarsseitBold,
    // textAlign: 'center',
  },
  'cartIconText': {
    color: colors.white,
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitBold,
  },

  'quantityField': {
    height: 40,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderStyle: 'solid',
    borderRadius: 0,
    backgroundColor: colors.white,
    fontFamily: FontFamily.LarsseitBold,
    marginVertical: 3,
    fontSize: scale(defaultFontSize),
  },
  'searcar': {
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitLight,
    height: 54,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderStyle: 'solid',
  },
  'searchBarIcons': {
    width: 'auto',
    height: 21,
  },
  'formLabels': {
    color: colors.text,
    fontSize: scale(defaultFontSize),
    fontFamily: FontFamily.LarsseitLight,
    marginBottom: 3,
    paddingLeft: 10,
    textAlign: 'left',
  },
  'formLabelsTopRight': {
    color: colors.text,
    fontSize: scale(defaultFontSize / 1.5),
    lineHeight: lineHeight(defaultFontSize / 1.5),
    fontFamily: FontFamily.Larsseit,
  },
  'formLabelsBottomRight': {
    color: colors.text,
    fontSize: scale(defaultFontSize / 1.5),
    lineHeight: lineHeight(defaultFontSize / 1.5),
    fontFamily: FontFamily.Larsseit,
  },
  'fontMobileH5': {
    color: colors.text,
    fontSize: scale(22),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(22),
  },
  'fontMobileH6': {
    color: colors.text,
    fontSize: scale(25),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(30),
  },
  'fontMobileH6Red': {
    color: colors.red,
    fontSize: scale(25),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(30),
  },
  'fontMobileH6Center': {
    color: colors.text,
    fontSize: 22,
    fontFamily: FontFamily.AleoBold,
    lineHeight: 30,
    textAlign: 'center',
  },
  'fontMobileListSmallLeft': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
  'fontMobileListSmallLeftRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(45),
    textAlign: 'left',
  },
  'fontMobileSmallLeftRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'left',
  },
  'fontMobileTinyLight': {
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  'formField': {
    height: 48,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderStyle: 'solid',
    borderRadius: 15,
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    marginBottom: 5,
    alignItems: 'center',
  },
  'formFieldText': {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(defaultFontSize),
    borderWidth: 0,
  },
  'formFieldMultilineText': {
    paddingTop: 15,
    paddingBottom: 15,
    lineHeight: lineHeight(defaultFontSize),
    textAlignVertical: 'top',
    height: '100%',
  },
  'formFieldError': {
    borderColor: colors.red,
    borderWidth: 2,
  },
  'formErrorText': {
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitLight,
    textAlign: 'right',
    color: colors.red,
  },
  'eyeIcon': {
    width: 16,
    height: 'auto',
  },
  'closeIcon': {
    width: 20,
    height: 20,
  },
  'primaryQuantityBox': {
    minWidth: 145,
    maxWidth: containerWidth * 0.55,
    height: 75,
    padding: 15,
    alignSelf: 'center',
  },
  'primaryQuantitySmallBox': {
    minWidth: scale(120),
    maxWidth: scale(120),
    borderWidth: 0,
    height: 32,
  },
  'secondaryQuantitySmallBox': {
    minWidth: scale(120),
    maxWidth: scale(120),
    borderWidth: 0,
    height: 32,
  },
  'primaryButton': {
    minWidth: 200,
    maxWidth: 350,
    height: 56,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignSelf: 'center',
    color: colors.primary,
    backgroundColor: colors.darkCream,
    borderWidth: 0,
    borderColor: 'black',
    borderStyle: 'solid',
    borderRadius: 28,
    margin: 8,
    justifyContent: 'center',
  },
  'primaryButtonText': {
    marginTop: 2.5,
    color: colors.text,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
    textAlignVertical: 'bottom',
  },
  'primaryButtonSmall': {
    minWidth: 100,
    maxWidth: 200,
    height: 40,
    paddingVertical: 0,
    paddingHorizontal: 16,
    alignSelf: 'center',
    color: colors.primary,
    backgroundColor: colors.darkCream,
    borderWidth: 0,
    borderColor: 'black',
    borderStyle: 'solid',
    borderRadius: 28,
    margin: 8,
    justifyContent: 'center',
  },
  'primaryButtonSmallText': {
    marginTop: 2,
    color: colors.text,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
    textAlignVertical: 'bottom',
  },
  'primaryButtonSmallTextRegular': {
    color: colors.text,
    fontFamily: FontFamily.Larsseit,
    textAlign: 'center',
    fontSize: scale(16),
    lineHeight: lineHeight(16),
  },
  'secondaryQuantityBox': {
    minWidth: 205,
    maxWidth: 350,
    height: 56,
    padding: 15,
  },
  'secondaryQuantityBoxSmall': {
    minWidth: 205,
    maxWidth: 350,
    height: 56,
    padding: 15,
  },
  'secondaryButton': {
    minWidth: 200,
    maxWidth: 350,
    height: 56,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignSelf: 'center',
    color: colors.secondary,
    backgroundColor: 'transparent',
    borderWidth: 2.5,
    borderColor: colors.secondary,
    borderStyle: 'solid',
    borderRadius: 28,
    margin: 8,
    justifyContent: 'center',
  },
  'secondaryButtonText': {
    marginTop: 2.5,
    color: colors.secondary,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
    textAlignVertical: 'bottom',
  },
  'secondaryButtonSmall': {
    minWidth: 50,
    maxWidth: 200,
    height: 40,
    paddingVertical: 0,
    paddingHorizontal: 16,
    alignSelf: 'center',
    color: colors.secondary,
    backgroundColor: 'transparent',
    borderWidth: 2.5,
    borderColor: colors.secondary,
    borderStyle: 'solid',
    borderRadius: 28,
    margin: 8,
    justifyContent: 'center',
  },
  'secondaryButtonSmallText': {
    marginTop: 2,
    color: colors.secondary,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
    textAlignVertical: 'bottom',
  },
  'secondaryButtonXsmall': {
    minWidth: 100,
    maxWidth: 200,
    height: 30,
    paddingVertical: 7,
    color: colors.secondary,
    borderWidth: 1.5,
    borderColor: colors.secondary,
    borderRadius: 15,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  'topLogoImg': {
    width: '100%',
    height: 'auto',
  },
  'arrowLeft50Opacity': {
    width: 31,
    height: 20,
  },
  'overlayBg': {
    backgroundColor: colors.black,
    opacity: 0.25,
  },
  'criticalAlert': {
    backgroundColor: colors.notification,
    width: '100%',
    paddingTop: 25,
    paddingRight: 25,
    paddingBottom: 25,
    paddingLeft: 25,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  'promoAlert': {
    backgroundColor: colors.white,
    paddingTop: 25,
    paddingRight: 25,
    paddingBottom: 25,
    paddingLeft: 25,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  'h1': {
    color: colors.text,
    fontSize: scale(35),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(35),
  },
  'h2': {
    color: colors.text,
    fontSize: scale(32),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(32),
    textAlign: 'center',
  },
  'h2Left': {
    color: colors.text,
    fontSize: scale(32),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(32),
    textAlign: 'left',
  },
  'h3Left': {
    color: colors.text,
    fontSize: scale(28),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(28),
    textAlign: 'left',
  },
  'h3B': {
    color: colors.text,
    fontSize: scale(28),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(28),
  },
  'h3BRed': {
    color: colors.saleRed,
    fontSize: scale(28),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(28),
  },
  'h5': {
    color: colors.text,
    fontSize: scale(22),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(22),
  },
  'h5Red': {
    color: colors.red,
    fontSize: scale(22),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(24),
  },
  'h5L': {
    color: colors.text,
    fontSize: scale(22),
    fontFamily: FontFamily.Larsseit,
    lineHeight: lineHeight(22),
  },
  'h6': {
    color: colors.text,
    fontSize: scale(25),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(25),
  },
  'linkRedSmall': {
    color: colors.red,
    fontSize: scale(20),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(20),
    textDecorationLine: 'underline',
    textDecorationColor: colors.red,
    textDecorationStyle: 'solid',
  },
  'eyebrowCenterRed': {
    color: colors.saleRed,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'bodyLargeCenter': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'center',
  },
  'bodyLargeLeft': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
  'bodyCenterRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'bodySmall': {
    color: colors.text,
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(15),
    lineHeight: lineHeight(15),
    textAlign: 'center',
  },
  'bodySmallLeft': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(15),
    textAlign: 'left',
  },
  'bodySmallLeftRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  'bodySmallCenterRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: 16,
    lineHeight: 25,
    textAlign: 'center',
  },
  'bodySmallRed': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'bodySmallLeftRedLight': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'left',
  },
  'bodySmall-50gray': {
    color: colors.black,
    opacity: 0.5,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'boldLinkLargeCenterB': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'center',
  },
  'boldLinkLargeCenter': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'bodyLeftBold': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
  // 'bodySmallLight': {
  //   color: colors.text,
  //   fontFamily: FontFamily.LarsseitLight,
  //   fontSize: scale(14),
  //   lineHeight: lineHeight(14),
  //   textAlign: 'left',
  // },
  /// trying to match fontMoblileBody fonts
  'bodyBoldXLarge': {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(30),
    lineHeight: lineHeight(30),
    textAlign: 'left',
    color: colors.darkText,
  },
  'bodyBoldLarge': {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(22),
    lineHeight: lineHeight(22),
    textAlign: 'left',
    color: colors.darkText,
  },
  'fontMobileBodyLarge': {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(22),
    lineHeight: lineHeight(22),
    textAlign: 'left',
    color: colors.darkText,
  },
  'fontMobileBodyMedium': {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    textAlign: 'left',
    color: colors.darkText,
  },
  'fontMobileBodyMediumRegular': {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'left',
    color: colors.darkText,
  },
  'fontMobileBodySmall': {
    color: colors.text,
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    textAlign: 'center',
  },
  'fontMobileBodySmallLeft': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
  'fontMobileBodySmBoldGray': {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    color: colors.text,
  },
  'fontMobileBodySmBoldRed': {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    color: colors.red,
  },
  'fontMobileBodySmallLight': {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    color: colors.text,
  },
  'fontMobileBodyCenterRegular': {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  'fontMobileBodyLeftRegular': {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'left',
  },
  'bodyMediumLight': {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'left',
    color: colors.darkText,
  },
  'body': {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(18),
    lineHeight: lineHeight(18),
    textAlign: 'left',
    color: colors.darkText,
  },
  'bodySmallBold': {
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  'bodySmallLight': {
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
  'bodySmallRegular': {
    color: colors.darkText,
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  'smallRed': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'left',
  },
  'eyebrowCenterWhite': {
    color: colors.white,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    letterSpacing: 1.96,
    textAlign: 'center',
  },
  'h1CenterWhite': {
    color: colors.white,
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(35),
    lineHeight: lineHeight(35),
    textAlign: 'center',
  },
  'tiny': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(12),
    textAlign: 'center',
  },
  'headerContainer': {
    height: 96,
    marginBottom: 5,
    paddingHorizontal: 8,
  },
  'breadcrumb': {
    color: colors.text,
    textAlign: 'left',
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitLight,
  },
  'breadcrumbCurrent': {
    color: colors.text,
    textAlign: 'left',
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBoldItalic,
  },
  'mt20': { marginTop: 20 },
  'mt10': { marginTop: 10 },
  'fontProp65Warning': {
    color: colors.text,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'fontShowMoreLink': {
    color: colors.text,
    fontSize: scale(14),
    fontFamily: FontFamily.AleoBold,
    textDecorationLine: 'underline',
  },
  'fontSmallLink': {
    color: colors.text,
    fontSize: scale(14),
    fontFamily: FontFamily.AleoBold,
    textDecorationLine: 'underline',
  },
  'fontSmallLinkSanSerif': {
    color: colors.text,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
  },
  'titleRow': {
    flexDirection: 'row',
    width: containerWidth,
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  'headSeparator': {
    height: 1,
    backgroundColor: colors.dark,
    marginTop: 10,
  },
  'zeroWidthBottomBorder': {
    borderBottomWidth: 0,
    marginTop: 5,
    zIndex: 9,
  },
  'filterPills2': {
    color: colors.text,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    backgroundColor: colors.darkCream,
    borderRadius: 5,
    height: 23,
  },
  'filterPills2Close': {
    color: colors.text,
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitLight,
    marginRight: 0.75,
    textAlign: 'left',
  },
  'filterAmount': {
    color: colors.white,
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitBold,
    borderRadius: 10,
    width: 17,
    height: 17,
    textAlign: 'center',
    lineHeight: 17,
  },
  'filterpills': {
    color: colors.darkText,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitLight,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderRadius: 5,
    padding: 1,
  },
  'accordionHeader': {
    color: colors.red,
    fontSize: scale(28),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(32),
  },
  'fontProdListProdPrice': {
    color: colors.darkText,
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
  },
  'fontProdListProdUnitPrice': {
    color: colors.darkText,
    fontSize: scale(18),
    fontFamily: FontFamily.Larsseit,
  },
  'fontProdListProdPriceSale': {
    color: colors.saleRed,
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
  },
  'fontProdListProdName': {
    color: colors.darkText,
    fontSize: 14,
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: 16,
  },
  'fontProdListProdSpecs': {
    color: colors.darkText,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'line-through',
  },
  'fontMoblieLargeBodyRight': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: 18,
    lineHeight: 25,
    textAlign: 'left',
  },
  'customizeH2': {
    fontSize: scale(20),
    lineHeight: lineHeight(24),
    fontFamily: FontFamily.AleoBold,
    color: colors.primary,
  },
  'customizeLabelSm': {
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.primary,
    textAlign: 'left',
  },
  'customizeLabelSmBold': {
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.primary,
    textAlign: 'left',
  },
  'customizeStepLabel': {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    color: colors.text,
  },
  'pdpQtyControlBtnNumberSm': {
    color: colors.primary,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
  },
  'pdpQtyControlBtnToggleSymbols': {
    color: colors.primary,
    fontSize: scale(28),
    fontFamily: FontFamily.LarsseitLight,
    textAlign: 'center',
  },
  'prodListControlBtnSetSm': {
    width: '100%',
    height: 30,
    color: colors.primary,
    backgroundColor: colors.white,
    borderRadius: 28,
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitLight,
  },
  'fontMobileBodyXS': {
    color: colors.text,
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(10),
    lineHeight: lineHeight(16),
  },
  'fontMobileBodyXSBoldRed': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(12),
    lineHeight: lineHeight(14),
  },
  'priceRed': {
    color: colors.saleRed,
    fontSize: scale(28),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(32),
  },
  'cartBottomButton': {
    height: 42,
    justifyContent: 'center',
    width: containerWidth * 0.85,
    maxWidth: containerWidth * 0.85,
  },
  'valdiationButton': {
    height: 42,
    justifyContent: 'center',
    width: containerWidth * 0.65,
    maxWidth: containerWidth * 0.65,
  },
  'ValdiationButton2Line': {
    height: 63,
    justifyContent: 'center',
    width: containerWidth * 0.85,
    maxWidth: containerWidth * 0.85,
  },
  'fontMobileListSmallCenterRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'center',
  },
  'walletContainer': {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: screenHeight * 0.8,
  },
  'fontInputInstructions': {
    color: colors.text,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitLight,
  },
  'customizeLabelBold': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(18),
    lineHeight: lineHeight(20),
    textAlign: 'left',
  },
  'bodyXsRight': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(10),
    lineHeight: lineHeight(14),
    textAlign: 'right',
  },
  'bodyXsBoldGrey': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(12),
    lineHeight: lineHeight(14),
  },
  'bodyXsBoldMediumGrey': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
  },
  'bodyXsCenter': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(10),
    lineHeight: lineHeight(12),
    textAlign: 'center',
  },
  'prodListSecondaryButtonSm': {
    width: '100%',
    height: 30,
    paddingTop: 10,
    paddingBottom: 10,
    color: colors.red,
    borderColor: colors.secondary,
    borderWidth: 2.5,
    backgroundColor: 'transparent',
    borderRadius: 28,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
  },
  'bodySmallRedLink': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  'smallBoldCenterRed': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'tinylightredlink': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  'smallCenterRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'center',
  },
  'fontMobileTextGreenRight': {
    color: colors.clippedGreen,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(12),
    textAlign: 'right',
  },
  'itemCardHeaderRed': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    textAlign: 'center',
  },
  'smLightCenter': {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'tinyLight': {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    textAlign: 'right',
  },
  'tableHeaderText': {
    color: colors.white,
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  'bodySmallLightLinkCenter': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(18),
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  'dropShadowTitle': {
    color: colors.primary,
    textAlign: 'left',
    fontSize: scale(22),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(22),
  },
  'bodyXsRedLinkLeftLight': {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(18),
    textAlign: 'left',
    textDecorationLine: 'underline',
  },
  'actionNotice': {
    color: colors.white,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    textAlign: 'left',
  },
  'actionNoticeLink': {
    color: colors.white,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    textAlign: 'right',
    textDecorationLine: 'underline',
  },
  'bodyCenterBold': {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(18),
    lineHeight: scale(25),
    textAlign: 'center',
  },
  'bodyLeftRegular': {
    color: '#4B5358',
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'left',
  },
  'fontMobileBodySmallLeftRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: scale(25),
    textAlign: 'left',
  },
  'fontMobileBodySmallBald': {
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  'fontMobileBodySmallRegular': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'center',
  },
  'fontMobileBodySmallRightLight': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: scale(18),
    textAlign: 'right',
  },
  'fontMobileBoldLinkLargeRight': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: scale(20),
    textAlign: 'right',
    textDecorationLine: 'underline',
  },
  'fontMobileBoldLeft': {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: scale(20),
    textAlign: 'center',
  },
});

export default appStyles;

import { BottomBar, Button, KeyboardAvoidingScreen, Screen, Text } from '@components';
import { Form, FormField } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps } from '@navigation/routes';
import { appStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import helpers, { formatDOB } from '@utils/helpers';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import React, { createRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';

type AgeRequirementScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.AgeRequirementScreen>;

const AgeRequirementScreen = ({ route, navigation }: AgeRequirementScreenProps): JSX.Element => {
  const [isBelowAge, setIsBelowAge] = useState<boolean>(true);
  const { t } = useTranslation('ageRequirementScreen');

  const { age } = route.params;

  const dobRef = useRef<TextInput>(null);
  const formRef = createRef<FormikProps<any>>();

  const validationSchema = Yup.object().shape({
    dob: Yup.string()
      .required()
      .label('Date of Birth')
      .matches(helpers.DOB_REGEX, t('dobValidationMessage'))
      .test('AgeVerification', t('ageVerificationMessage'), (value) => {
        const birthDate = Number(value && value.length === 10 && value.split('/')[1].trim()) ?? 0;
        const birthYear = Number(value && value.length === 10 && value.split('/')[2].trim()) ?? 0;
        const isValidDate =
          value?.length === 10 &&
          dayjs(value).isValid() &&
          dayjs(value).get('date') === birthDate &&
          birthYear >= Number(dayjs().add(-200, 'year').format('YYYY'));
        const result = isValidDate && dayjs().diff(value, 'year') >= age;

        setIsBelowAge(!result);
        return result;
      }),
  });

  const onContinueClick = () => {
    if (!isBelowAge) {
      navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.Checkout, params: {} });
    }
  };

  const cancelOnClick = () => {
    navigation.goBack();
  };

  return (
    <Screen>
      <View style={styles.modal}>
        <View style={styles.textWrapper}>
          <Text style={styles.h3BoldCenter} testID="AgeRequirementTitle">
            {t('ageRequirementTitle').replace(/%AGE%/g, age.toString())}
          </Text>
        </View>
        <View style={[styles.lineSeperator]} />
        <Form initialValues={{ dob: '' }} onSubmit={() => {}} validationSchema={validationSchema} innerRef={formRef}>
          <KeyboardAvoidingScreen>
            <ScrollView bounces={false} contentContainerStyle={[appStyles.container]} keyboardShouldPersistTaps={'handled'}>
              <View style={[styles.subTitleWrapper]}>
                <Text style={[styles.subtitle]} testID="AgeRequirementSubTitle">
                  {t('ageRequirementSubTitle').replace(/%AGE%/g, age.toString())}
                </Text>
              </View>
              <View style={[styles.form, { backgroundColor: colors.cream }]}>
                <FormField
                  testID="dob"
                  label="Date of Birth"
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="number-pad"
                  maxLength={10}
                  name="dob"
                  placeholder="MM/DD/YYYY"
                  textContentType="none"
                  returnKeyType="done"
                  ref={dobRef}
                  formatter={formatDOB}
                />
              </View>
              <View style={[styles.messageWrapper]}>
                <Text style={[styles.message]} testID="AgeRequirementMessage">
                  {t('ageRequirementMessage').replace(/%AGE%/g, age.toString())}
                </Text>
              </View>
            </ScrollView>
          </KeyboardAvoidingScreen>
        </Form>
        <BottomBar>
          <TouchableOpacity activeOpacity={0.7} onPress={cancelOnClick} testID="CancelButton">
            <Text style={[appStyles.smallLink, styles.cancelLink]} testID="Cancel">
              Cancel
            </Text>
          </TouchableOpacity>
          <Button
            testID="ContiueButton"
            size={'small'}
            buttonStyle={[appStyles.primaryButtonSmall, styles.button]}
            textStyle={[appStyles.primaryButtonSmallText]}
            onPress={onContinueClick}
            title={'Continue'}
            disabled={isBelowAge}
          />
        </BottomBar>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  textWrapper: {
    paddingHorizontal: 25,
    paddingBottom: 8,
  },
  lineSeperator: {
    backgroundColor: colors.sectionBorder,
    height: 1,
    width: '100%',
    alignSelf: 'center',
    marginTop: 12,
  },
  form: {
    width: '100%',
    paddingHorizontal: 10,
    marginTop: 0,
  },
  subTitleWrapper: {
    padding: 15,
    paddingTop: 28,
  },
  subtitle: {
    ...appStyles.fontMobileBoldLeft,
    textAlign: 'justify',
  },
  messageWrapper: {
    paddingHorizontal: 15,
    paddingTop: 28,
    paddingBottom: 50,
  },
  message: {
    ...appStyles.fontMobileBodySmallLight,
    textAlign: 'justify',
  },
  button: {
    minWidth: '45%',
  },
  updateButton: {
    width: screenWidth * 0.4,
    height: '70%',
    backgroundColor: colors.darkCream,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    padding: 0,
  },
  addMemberBar: {
    width: screenWidth,
    top: 25,
    bottom: 0,
    alignSelf: 'center',
    height: scale(56),
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelLink: {
    color: colors.darkText,
    fontFamily: FontFamily.AleoBold,
  },
});

export default AgeRequirementScreen;

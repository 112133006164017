import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import useCancelToken from '@hooks/useCancelToken';
import { AppStackRoutes, RewardsStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useOffersStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { FlatList, Platform, RefreshControl, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';
import LoadingScreen from '../LoadingScreen';
import OfferCard from './OfferCard';

interface ClippedSavingsProps {
  //TODO: offer type
  style?: StyleProp<ViewStyle>;
  offers?: any[] | undefined;
  currTab?: string;
}

const ClippedSavings: FC<ClippedSavingsProps> = ({ style }): JSX.Element => {
  const { acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons, offersState, fetchOffers } = useOffersStore();
  const { showAlertModal } = useCustomModalContext();
  const navigation = useNavigation();
  const cancelToken = useCancelToken();

  const acceptedOffers = acceptedSomethingExtraOffers.concat(acceptedWeeklyExclusiveOffers).concat(acceptedDigitalCoupons);

  const refetchCoupons = () => {
    fetchOffers(cancelToken);
  };

  const renderListHeaderComponent = () => {
    return (
      <View style={styles.headerWrapper}>
        <Text style={styles.tabTitle}>Clipped</Text>
        <TouchableOpacity
          onPress={() => {
            showAlertModal();
          }}>
          <Icon name="sort-icon" size={28} style={appStyles.icon} />
        </TouchableOpacity>
      </View>
    );
  };

  const renderListFooterComponent = () => {
    return (
      <View style={styles.modalWrapperFirst}>
        <View style={styles.savingModal}>
          <Text style={[appStyles.bodySmallRedLink, styles.modalText]}>Add Savings To Your Wallet</Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Rewards,
                params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
              });
            }}>
            <Text style={appStyles.bodySmallLightLinkCenter}>See All Available Savings</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return offersState === 'loading' ? (
    <LoadingScreen />
  ) : (
    <>
      <View style={[styles.container, style]}>
        <FlatList
          // columnWrapperStyle={{ justifyContent: 'space-evenly' }}
          ListHeaderComponent={acceptedOffers.length > 0 ? renderListHeaderComponent() : null}
          ListFooterComponent={renderListFooterComponent()}
          refreshControl={<RefreshControl refreshing={false} onRefresh={refetchCoupons} />}
          keyExtractor={(_, i) => i.toString()}
          data={acceptedOffers ?? []}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.offerCardWrapper}>
                <OfferCard item={item} index={index} />
              </View>
            );
          }}
          numColumns={2}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: Platform.select({
      ios: 90,
      android: 120,
    }),
    paddingTop: Platform.select({ ios: 50, android: 10 }),
    paddingHorizontal: 6,
    // marginLeft: Platform.select({
    //   ios: screenHeight < 740 ? 5 : 13,
    //   android: 15,
    // }),
  },
  offersWrapper: {
    width: screenWidth,
    flexGrow: 1,
    minHeight: 100,
    marginTop: 16,
    paddingBottom: 30,
    alignSelf: 'center',
    alignItems: 'center',
  },
  tabTitle: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    paddingHorizontal: 30,
    marginBottom: 20,
  },
  sortIconWrapper: {
    alignSelf: 'center',
    padding: 5,
    paddingLeft: 80,
  },
  modalWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 30,
  },
  modalWrapperFirst: {
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 50,
    marginLeft: 10,
    marginTop: 20,
  },
  savingModal: {
    backgroundColor: colors.white,
    width: (screenWidth * 2) / 2.25,
    height: 130,
    alignItems: 'center',
    padding: 36,
    borderRadius: 15,
  },
  modalText: {
    textDecorationLine: 'none',
    paddingBottom: 5,
  },
  emptyModalWrapper: {
    alignItems: 'center',
    marginRight: 10,
  },
  offerCardWrapper: {
    marginBottom: 16,
    flex: 1 / 2,
    alignItems: 'center',
    // marginLeft: 12,
  },
});

export default ClippedSavings;

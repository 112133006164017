import Text from '@components/Text';
import colors from '@config/colors';
import { defaultMoney } from '@fieldera-raleys/client-commercetools';
import { Money } from '@fieldera-raleys/client-commercetools/schema';
import { Order, OrderPaymentMethod } from '@fieldera-raleys/client-common/types/order';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, scale, screenWidth } from '@styles/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import { formatValue } from '../../utils/helpers';
import { getCRVAmountMoneyBWOrder, moneyValue, pointsValue } from '../../utils/orderHelpers';
import Icon from '../Icon';

const ITEM_SPACE = <></>;

interface InvoiceProps {
  obj: Order | undefined;
  isDelivery?: boolean;
  isOnline?: boolean;
  backgroundColor?: string;
  title?: string;
}

const InvoiceOrder: React.FC<InvoiceProps> = (props) => {
  const { obj, title, isDelivery, isOnline } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation(['cart', 'purchaseHistory']);
  let key = 0;

  const subLine = (txt: string | JSX.Element, val: string | number, style: StyleProp<TextStyle>) => (
    <View key={`subkey_${++key}`} style={[styles.row]}>
      <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[style, styles.subtotalsUnit]} testID="subLineTxt">
        {txt}
      </Text>
      <Text style={[style, styles.subtotalsDollar]} testID="itemspaceTxt">
        {ITEM_SPACE}
      </Text>
      <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[style, styles.subtotalsPrice]} testID="valText">
        {val}
      </Text>
    </View>
  );

  const saveLine = (txt: string | JSX.Element, val: string | number, style: StyleProp<TextStyle>) => (
    <View key={`savekey_${++key}`} style={[styles.row, utilityStyles.mb2]} testID="saveLineView">
      <View style={[styles.subtotalsUnit, { flexDirection: 'row' }]}>
        <Text style={{ paddingLeft: 20 }} />
        <Text numberOfLines={2} adjustsFontSizeToFit={true} style={[style, { minWidth: '70%' }]} testID="saveLineTxt">
          {txt}
        </Text>
      </View>
      <Text style={[style, styles.subtotalsDollar]} testID="itemspaceTxt">
        {ITEM_SPACE}
      </Text>
      <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[style, styles.subtotalsPrice]} testID="itemSpaceValText">
        {val}
      </Text>
    </View>
  );

  if (!obj) {
    return subLine('TOTAL', (0).toFixed(2), appStyles.bodySmall);
  }

  const getTotalNet = () => {
    const currencyCode = 'USD';
    const fractionDigits = 2;

    const centAmount = (obj.productAmount + obj.serviceFeeAmount) * 100;

    let money: Money = {
      type: 'centPrecision',
      currencyCode: currencyCode,
      centAmount: centAmount,
      fractionDigits: fractionDigits,
    };

    return money;
  };

  const isCompleted = obj?.orderStatus.value?.toUpperCase() === 'COMPLETED' || obj?.orderStatus.value?.toUpperCase() === 'DELIVERED';

  const totalItemQuantity = obj.orderItems.reduce((qty, li) => {
    return qty + li.qty;
  }, 0);
  const taxRate = (obj.taxRate * 100.0).toFixed(2);

  const serviceFee = formatValue(obj.serviceFeeAmount);

  const deliveryFee = formatValue(obj.shippingAmount);
  const deliveryTipValue = formatValue(obj.tipAmount);

  const getMoneyValue = (value: number) => {
    const currencyCode = 'USD';
    const fractionDigits = 2;

    const centAmount = value * 100;

    let money: Money = {
      type: 'centPrecision',
      currencyCode: currencyCode,
      centAmount: centAmount,
      fractionDigits: fractionDigits,
    };

    return money;
  };

  const promos =
    obj?.orderAdjustmentTransactions &&
    obj?.orderAdjustmentTransactions.reduce((acc, pe) => {
      if (pe.adjustmentAmount < 0) {
        acc.push({
          description: pe.note ?? 'Savings',
          value: getMoneyValue(pe.adjustmentAmount),
        });
      }
      return acc;
    }, [] as { description: string; value: Money }[]);
  const savings = promos?.reduce((acc, pe) => (acc += pe.value.centAmount / 100.0), 0 as number) ?? 0;
  const crvMoney = getCRVAmountMoneyBWOrder(obj) ?? 0;

  let donationMoney, donationValue;
  const donation = obj.orderItems.find((ci) => ci.orderItemSubItems?.length === 0 && (ci.productListing.customProperties?.ExtProductId ?? '') === '970002203');
  if (donation) {
    donationValue = donation.unitPrice * donation.qty;
    donationMoney = { ...defaultMoney, centAmount: donationValue };
  }
  const getPaymentMethodString = (paymentMethodObj: OrderPaymentMethod) => {
    let paymentMethodDetails = {
      methodType: paymentMethodObj?.paymentMethodType?.value || 'Unavailable',
      cardType: '',
      lastFour: '',
    };

    if (isOnline) {
      paymentMethodDetails.cardType = paymentMethodObj?.creditCardType?.value ?? '';
      paymentMethodDetails.lastFour = paymentMethodObj?.creditCardMaskValue.substring(paymentMethodObj?.creditCardMaskValue.length - 4) ?? '';
    }

    return paymentMethodDetails;
  };

  const mapPromos = (pset?: { description: string; value: Money }[]) => {
    if (pset && pset.length) {
      return pset.map((p, i) => (
        <View key={`pk${i}`} style={[{ flexDirection: 'row', width: containerWidth }, utilityStyles.my1]}>
          <Text style={[appStyles.bodySmallRegular, { color: colors.red }, utilityStyles.px2]} />
          <Text testID="buyTwoGetOneText" ellipsizeMode={'tail'} numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { color: colors.red, flex: 0.9 }]}>
            {p.description}
          </Text>
          <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]}>
            &minus;&nbsp;{moneyValue(p.value)}
          </Text>
        </View>
      ));
    }

    return [];
  };
  const estimatedLbl = isCompleted ? '' : 'Estimated ';
  const invoceItems: JSX.Element[] = [
    <View key={'invocieTotals_Root'} style={{ paddingTop: 20, paddingBottom: 20, flexDirection: 'column' }}>
      {subLine(title ? title : ['Items (', totalItemQuantity, ')'].join(''), formatValue(obj.productAmount), appStyles.bodyLeftBold)}
      {taxRate ? subLine(`${estimatedLbl}Tax (${taxRate}%)`, formatValue(obj.productTaxAmount), appStyles.bodySmallRegular) : null}
      {crvMoney && crvMoney > 0 ? subLine('CRV', `${formatValue(crvMoney)}`, appStyles.bodySmallRegular) : null}
      {serviceFee ? subLine('Service Fee', `${serviceFee}`, appStyles.bodySmallRegular) : null}
      {isDelivery && deliveryFee ? subLine('Delivery Fee', `${deliveryFee}`, appStyles.bodySmallRegular) : null}
      {isDelivery && deliveryTipValue && subLine(t('deliveryTip'), `${deliveryTipValue}`, appStyles.bodySmallRegular)}
      {donationValue && donationMoney && donationMoney.centAmount > 0
        ? subLine(t('fffHeader'), `${formatValue(donationValue)}`, appStyles.bodySmallRegular)
        : null}
      {savings < 0 ? (
        <TouchableOpacity
          testID="invoceItemsClick"
          onPress={() => {
            setIsOpen(!isOpen);
          }}>
          <View style={[styles.row]}>
            <View style={[styles.row, { alignSelf: 'flex-start' }]}>
              <Text
                numberOfLines={2}
                adjustsFontSizeToFit={true}
                style={[appStyles.bodyLeftBold, styles.subtotalsUnit, { color: colors.red, minWidth: '60%' }]}
                testID="savingTxt">
                {estimatedLbl.toUpperCase()}SAVINGS
                <Icon
                  testID="triangleIcon"
                  name={isOpen ? 'triangle-up' : 'triangle-down'}
                  size={15}
                  stroke={colors.darkText}
                  fill={colors.darkText}
                  style={[appStyles.smallIcon, { paddingLeft: 5 }]}
                />
              </Text>
              <Text style={[appStyles.bodySmall, styles.subtotalsDollar, { color: colors.red }]} testID="itemspace">
                {ITEM_SPACE}
              </Text>
              <Text
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.bodyLeftBold, styles.subtotalsPrice, { color: colors.red }]}
                testID="adjustmentAmount">
                {`-${formatValue(savings)}`}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      ) : (
        <></>
      )}
      {/* {savings && isOpen ? <>{saveLine('Total Promotion Savings', `-${formatValue(savings)}`, appStyles.bodySmallRegular)}</> : <></>} */}
      {savings && isOpen ? mapPromos(promos) : <></>}

      {subLine(isCompleted ? t('total') : t('estimatedTotal'), formatValue(obj.totalPrice), appStyles.bodyLeftBold)}

      {isCompleted &&
        subLine(t('sePointsEarned', { ns: 'purchaseHistory' }), pointsValue(getTotalNet()) ?? '', [appStyles.bodyLeftBold, { color: colors.raleysRed }])}
      {/* {subLine('Credit Applied to Account', (0).toFixed(2), appStyles.bodySmallRegular)}
      {subLine('VISA xxxx1111', (-1 * Math.max(0, total)).toFixed(2), appStyles.bodySmallRegular)} */}

      {subLine('Payment Method', '', [appStyles.bodyLeftBold, utilityStyles.mt3])}
      {props.obj?.orderPaymentMethods?.length
        ? props.obj?.orderPaymentMethods?.map((payMethod) => {
            let paymentString = getPaymentMethodString(payMethod);
            return subLine(paymentString.methodType, isOnline ? `${paymentString.cardType} ...${paymentString.lastFour}` : '', [
              appStyles.bodySmallRegular,
              { marginTop: -8 },
            ]);
          })
        : obj.offlinePaymentMethodType &&
          obj?.offlinePaymentMethodType.value &&
          subLine(obj?.offlinePaymentMethodType.value, '', [appStyles.bodySmallRegular, { marginTop: -8 }])}

      <Text numberOfLines={2} adjustsFontSizeToFit={true} style={[appStyles.bodySmallLight, utilityStyles.mt3]} testID="finalPricing">
        Final order pricing will display online 24 hours after the order is completed.
      </Text>
    </View>,
  ];

  return (
    <View style={[styles.mainContainer, props.backgroundColor ? { backgroundColor: props.backgroundColor } : null]}>
      <View style={[styles.subContainer, props.backgroundColor ? { backgroundColor: props.backgroundColor } : null]} testID="invoceItemsView">
        {invoceItems}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    width: screenWidth,
  },
  subContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: containerWidth,
    backgroundColor: colors.cream,
  },
  row: {
    flexDirection: 'row',
    marginVertical: 4,
    alignSelf: 'flex-start',
  },
  subtotalsPrice: {
    textAlign: 'right',
    flex: 1,
    minWidth: scale(40),
    alignSelf: 'flex-end',
  },
  subtotalsDollar: {
    marginLeft: 'auto',
    textAlign: 'right',

    alignSelf: 'flex-end',
  },
  subtotalsUnit: {
    flex: 1,
    alignSelf: 'flex-end',
  },
  unitSeparator: {
    width: 0.03,
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.cream,
    alignSelf: 'flex-end',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  notePad: {
    backgroundColor: colors.darkCream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 20,
  },
});

export default InvoiceOrder;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { scale } from '@styles/constants';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from '../Icon';
import SEPointsBalance from '../somethingExtra/SEPointsBalance';

const BalanceCard = () => {
  const [homeExtensionVisible, setHomeExtensionVisible] = useState(false);
  const handleOnPress = () => {
    setHomeExtensionVisible(!homeExtensionVisible);
  };
  return (
    <View style={styles.pointsSection}>
      <TouchableOpacity onPress={handleOnPress}>
        <View style={styles.psHeader}>
          <Icon
            style={[styles.seIcon, { left: 10 }]}
            size={65}
            fill={colors.white}
            stroke={'transparent'}
            strokeSecondary={colors.seRed}
            name={'something-extra'}
          />
          <View style={styles.titleContainer}>
            <Text style={{ fontFamily: FontFamily.LarsseitBold, color: colors.white, paddingBottom: 5, fontSize: scale(20) }}>Something Extra</Text>
            <Text style={{ fontFamily: FontFamily.LarsseitLight, color: colors.white }}>View My Rewards Progress</Text>
          </View>
          {/* <TouchableOpacity onPress={handleOnPress}> */}
          <Icon
            size={18}
            fill={colors.cream}
            stroke={'none'}
            name={homeExtensionVisible ? 'triangle-up' : 'triangle-down'}
            style={{ transform: [{ translateY: 7 }] }}
          />
          {/* </TouchableOpacity> */}
          {/* <TouchableOpacity onPress={() => console.log('nav to barcode')}>
          <Icon style={styles.barcodeIcon} stroke={colors.white} name={'wallet-icon'} />
        </TouchableOpacity> */}
        </View>
      </TouchableOpacity>
      {/* <View style={styles.psTitle}>
        <Text style={{ fontFamily: FontFamily.AleoBold, color: colors.white, fontSize: scale(24) }}>Something Extra Rewards</Text>
      </View> */}
      <View style={{ alignItems: 'center' }}>{homeExtensionVisible && <SEPointsBalance isRegHome={true} homeExtensionVisible={homeExtensionVisible} />}</View>
    </View>
  );
};
const styles = StyleSheet.create({
  pointsSection: {
    backgroundColor: colors.seRed,
    alignSelf: 'center',
    width: '100%',
    //borderWidth: 2,
    borderTopWidth: 2,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderColor: colors.lineSeparator,
    borderStyle: 'solid',
    marginBottom: 40,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 2.54,

    elevation: 2,
  },
  quickTipSection: {
    paddingHorizontal: 16,
    paddingVertical: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  psHeader: {
    flexDirection: 'row',
    height: scale(82),
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  psTitle: {
    height: 50,
    justifyContent: 'center',
  },
  seIcon: {
    position: 'absolute',
    top: -5,
    left: 0,
  },
  barcodeIcon: {
    marginLeft: 40,
    marginRight: 8,
  },
  titleContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    left: 50,
  },
  detailText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.red,
    fontSize: scale(12),
  },
});
export default BalanceCard;

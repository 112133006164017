import Text from '@components/Text';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { AppStackRoutes, CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { customizeService, productService } from '@services/commerceTools';
import { useCartStore, useCustomizeStore, useListsStore } from '@store';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { FC, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { CakeMessage, ChosenItem, CustomizeInfo } from '../../models/customize';
import logger from '../../utils/logger';
import { getfulfillmentLeadTimeMinutes } from '../../utils/productHelper';

interface InitialCustomizeScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Initial> {}

const InitialCustomizeScreen: FC<InitialCustomizeScreenProps> = ({ route }) => {
  const { productKey, prevPageData, editCustomize, lineItemId, editSavedForLater } = route.params;
  const customizeStore = useCustomizeStore();
  const listStore = useListsStore();
  const cartStore = useCartStore();
  const navigation = useNavigation();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (editCustomize) {
      handleEdit('cart');
    } else if (editSavedForLater) {
      handleEdit('sfl');
    } else {
      handleCustomize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCustomize = async () => {
    customizeStore.setCustomizeLoading(true);
    customizeStore.setPrevPageData({});
    customizeStore.clearCustomize();

    try {
      var customizeSteps: CustomizeInfo | undefined;

      const productData: Product = await productService.getProductBySku(productKey);
      if (!productData) {
        logger.log(productData);
        handleError('No Customize Data Found');
        return;
      }
      customizeStore.setProductLeadTime(getfulfillmentLeadTimeMinutes(productData));
      const isCakeBuilder =
        productData?.masterData?.current?.masterVariant?.attributesRaw?.find((val: any) => val.name === 'configurationUITemplate')?.value.key === 'cakeBuilder';

      // console.log(JSON.stringify(productData, null, 3), isCakeBuilder);
      if (isCakeBuilder || productData.masterData?.current?.name?.toLowerCase().includes('donut')) {
        customizeStore.setConfigType('cakeBuilder');
        customizeSteps = await customizeService.getCakeBuilderInfo(productData);
        // customizeSteps = await commerceService.getCakeBuilderInfo(productData);
      } else {
        customizeStore.setConfigType('standard');
        customizeSteps = await customizeService.getCustomizeInfo(productData);
        // logger.log('INFO', JSON.stringify(customizeSteps, null, 2));
      }

      if (!customizeSteps) {
        handleError('No Customize Data Found');
        return;
      }

      if (customizeStore.savedCustomizedItems.some((item) => item.itemId === productKey)) {
        var savedItem = customizeStore.savedCustomizedItems.find((item) => item.itemId === productKey);
        customizeStore.setSelectedSize(savedItem?.selectedSize);
        customizeStore.setSelectedContainer(savedItem?.selectedContainer);
        customizeStore.setSelectedIngredients(savedItem?.selectedIngredients);
        customizeStore.setNotes(savedItem?.notes);
        customizeStore.setCakeMessage(savedItem?.cakeMessage);
      }
      customizeStore.setItem(customizeSteps?.itemName);
      customizeStore.setSteps(customizeSteps.steps);
      customizeStore.setCurrStep(1);
      customizeStore.calculateSubtotal();
      if (prevPageData) {
        customizeStore.setPrevPageData(prevPageData);
      }
      customizeStore.setItemId(productKey);

      if (customizeSteps?.steps[0].pageItem !== 'Size') {
        customizeStore.setSelectedSize({
          itemName: productData?.masterData?.current?.name ?? '',
          id: productData.id,
          sku: productData?.masterData?.current?.masterVariant.sku ?? '',
          description: 1,
          cost:
            (productData?.masterData?.current?.masterVariant?.price?.value.centAmount ?? 0) /
            10 ** (productData?.masterData?.current?.masterVariant?.price?.value.fractionDigits ?? 0),
        });
        customizeStore.setSubtotal(
          (productData?.masterData?.current?.masterVariant?.price?.value.centAmount ?? 0) /
            10 ** (productData?.masterData?.current?.masterVariant?.price?.value.fractionDigits ?? 0),
        );
      }

      let basic: CustomizeStackRoutes | undefined;
      let specializedPages = ['theme', 'size', 'filling', 'container'];
      if (!specializedPages.some((pi) => customizeSteps?.steps[0]?.pageItem?.toLowerCase().includes(pi))) {
        if (isCakeBuilder || productData.masterData?.current?.name?.toLowerCase().includes('donut')) {
          basic = CustomizeStackRoutes.BasicSelection;
        }
        if (
          (customizeSteps?.steps[0]?.selectionOption !== 'select' || !isCakeBuilder) &&
          !productData.masterData?.current?.name?.toLowerCase().includes('donut')
        ) {
          basic = CustomizeStackRoutes.Selection;
        }
      }
      // console.log(JSON.stringify(customizeSteps.steps, null, 3));
      navigation.navigate(AppStackRoutes.Customize, {
        screen: basic ?? (customizeSteps?.steps[0]?.pageItem as CustomizeStackRoutes),
        params: customizeSteps?.steps[0],
      });
      customizeStore.setCustomizeLoading(false);
    } catch (err) {
      logger.error(err);
      handleError('Erorr fetching customize info');
      customizeStore.setCustomizeLoading(false);
    }
  };

  const handleEdit = async (editFrom: string) => {
    customizeStore.setCustomizeLoading(true);
    customizeStore.setPrevPageData({});
    customizeStore.clearCustomize();
    // customizeStore.setEditCartId(lineItemId ?? '');
    var customizedItem;

    if (editFrom === 'cart') {
      customizeStore.setEditCartId(lineItemId ?? '');
      customizedItem = cartStore.cart?.lineItems.find((li) => li.id === lineItemId);
    } else if (editFrom === 'sfl') {
      customizeStore.setEditSavedForLaterId(lineItemId ?? '');
      customizedItem = listStore.lists?.find((l) => l.name === 'Saved For Later')?.lineItems.find((li) => li.id === lineItemId);
    }
    if (!customizedItem) {
      customizeStore.setEditCartId('');
      customizeStore.setEditSavedForLaterId('');
      return;
    }
    var productSkus = [];

    try {
      var customizeSteps: CustomizeInfo | undefined;
      var customizedInfo = {
        selectedSize: {},
        selectedIngredients: [],
        cakeMessage: {},
        notes: '',
        selectedContainer: {},
      };

      customizedInfo.selectedSize = {
        id: customizedItem?.variant?.id,
        sku: customizedItem?.variant?.sku,
        itemName: customizedItem?.variant?.attributesRaw.find((a) => a.name === 'name')?.value?.trim(),
        cost: customizedItem?.variant?.price?.value.centAmount / 10 ** (customizedItem?.variant?.price?.value.fractionDigits ?? 0),
        description: customizedItem?.variant?.attributesRaw.find((att) => att.name === 'feeds')?.value || '',
      };
      productSkus.push(customizedItem?.variant?.sku);

      const children = customizedItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'childLineItems')?.value;

      const productData = await productService.getProductBySku(customizedItem?.variant?.sku ?? '');
      if (!productData) {
        logger.log(productData);
        handleError('No Customize Data Found');
        return;
      }
      const isCakeBuilder =
        productData?.masterData?.current?.masterVariant?.attributesRaw?.find((val: any) => val.name === 'configurationUITemplate')?.value.key === 'cakeBuilder';

      if (
        productData?.masterData?.current?.masterVariant?.attributesRaw?.find((val: any) => val.name === 'configurationUITemplate')?.value.key ===
          'cakeBuilder' ||
        productData?.masterData?.current?.name?.toLowerCase()?.includes('donut')
      ) {
        customizeStore.setConfigType('cakeBuilder');
        customizeSteps = await customizeService.getCakeBuilderInfo(productData);
      } else {
        customizeStore.setConfigType('standard');
        customizeSteps = await customizeService.getCustomizeInfo(productData);
      }
      if (!customizeSteps) {
        handleError('No Customize Data Found');
        return;
      }

      customizeStore.setItem(customizeSteps?.itemName ?? '');
      customizeStore.setSteps(customizeSteps.steps);
      customizeStore.setCurrStep(1);
      customizeStore.setInitialPage(customizeSteps.steps[0].pageItem ?? '');
      customizeStore.calculateSubtotal();

      if (children.length) {
        for (var i = 0; i < children.length; i++) {
          const ing =
            editFrom === 'cart'
              ? cartStore.cart?.lineItems?.find((li) => li.id === children[i])
              : listStore.lists?.find((l) => l.name === 'Saved For Later')?.lineItems.find((li) => li.id === children[i]);

          const stepNumber = ing?.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value.split('|')[0];
          const childIngredient: ChosenItem | CakeMessage = {
            id: ing?.productId,
            sku: ing?.variant?.sku || undefined,
            itemName: ing?.name || '',
            cost: ing?.price?.value.centAmount / 10 ** (ing?.price?.value?.fractionDigits ?? 1),
            color: ing?.variant?.attributesRaw.find((att) => att.name === 'name')?.value || 'White',
            message: (ing?.custom?.customFieldsRaw ?? []).find((cf) => cf.name === 'itemNote')?.value || undefined,
            description: ing?.quantity || 1,
          };

          productSkus.push(ing?.variant?.sku);

          if (customizeSteps?.steps[stepNumber - 1]?.pageItem === 'Message') {
            customizedInfo.cakeMessage = childIngredient;
          } else {
            if (customizedInfo.selectedIngredients.some((ingr) => ingr.ingredientName === customizeSteps?.steps[stepNumber - 1]?.pageItem)) {
              customizedInfo.selectedIngredients
                .find((ingr) => ingr.ingredientName === customizeSteps?.steps[stepNumber - 1]?.pageItem)
                ?.selections.push(childIngredient);
            } else {
              customizedInfo.selectedIngredients.push({
                ingredientName: customizeSteps?.steps[stepNumber - 1]?.pageItem,
                sortOrderHint: stepNumber,
                selections: [childIngredient],
              });
            }
          }
          customizeStore.setCakeMessage(customizedInfo?.cakeMessage);
          customizeStore.setNotes(customizedInfo.notes);
          customizeStore.setSelectedContainer(customizedInfo.selectedContainer);
          customizeStore.setSelectedIngredients(customizedInfo.selectedIngredients);
          customizeStore.setSelectedSize(customizedInfo.selectedSize);

          productSkus.push(customizedInfo?.cakeMessage?.sku);
          productSkus.push(customizedInfo?.selectedContainer?.sku);
        }
      }
      customizeStore.setPrevPageData({ id: '', name: '', children: [] });
      customizeStore.setItemId('');

      if (customizeSteps?.steps[0].pageItem !== 'Size') {
        customizeStore.setSelectedSize({
          itemName: productData?.masterData?.current?.name ?? '',
          id: productData?.id,
          sku: productData?.masterData?.current?.masterVariant.sku ?? '',
          description: 1,
          cost:
            (productData?.masterData?.current?.masterVariant?.price?.value.centAmount ?? 0) /
            10 ** (productData?.masterData?.current?.masterVariant?.price?.value.fractionDigits ?? 0),
        });
        customizeStore.setSubtotal(
          (productData?.masterData?.current?.masterVariant?.price?.value.centAmount ?? 0) /
            10 ** (productData?.masterData?.current?.masterVariant?.price?.value.fractionDigits ?? 0),
        );
      }

      const skuArr = productSkus.filter((s) => s != null);
      customizeStore.setPrevProductSkus(skuArr);
      customizeStore.calculateSubtotal();

      let basic: CustomizeStackRoutes | undefined;
      let specializedPages = ['theme', 'size', 'filling', 'container', 'decoration'];
      if (!specializedPages.some((pi) => customizeSteps?.steps[0]?.pageItem?.toLowerCase().includes(pi))) {
        if (isCakeBuilder || productData.masterData?.current?.name?.toLowerCase().includes('donut')) {
          basic = CustomizeStackRoutes.BasicSelection;
        }
        if (
          (customizeSteps?.steps[0]?.selectionOption !== 'select' || !isCakeBuilder) &&
          !productData.masterData?.current?.name?.toLowerCase().includes('donut')
        ) {
          basic = CustomizeStackRoutes.Selection;
        }
      }
      navigation.navigate(AppStackRoutes.Customize, {
        screen: basic ?? (customizeSteps.steps[0].pageItem as CustomizeStackRoutes),
        params: customizeSteps?.steps[0],
      });
    } catch (err) {
      logger.error(err);
      customizeStore.setCustomizeLoading(false);
    }
    customizeStore.setCustomizeLoading(false);
  };

  return (
    <View style={styles.container}>
      <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} testID="loading" />
      <Text testID="loadingText" style={[appStyles.customizeLabelBold, styles.description]}>
        Loading your choices...
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 50,
  },
  description: {
    alignItems: 'center',
    textAlign: 'center',
    width: screenWidth,
    marginTop: 16,
  },
});

export default InitialCustomizeScreen;

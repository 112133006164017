import { CloseButton, NavigationHeader, ProductCard, Screen } from '@components';
import colors from '@config/colors';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { PagedArray } from '@fieldera-raleys/client-common';
import { useRefetchOnFocus } from '@hooks';
import { AppStackRoutes, AppStackScreenProps } from '@navigation/routes';
import brsmService from '@services/brsm/brsmService';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { ActivityIndicator, FlatList, ListRenderItemInfo, RefreshControl, StyleSheet, View } from 'react-native';

type ProductCarouselScreenProps = AppStackScreenProps<AppStackRoutes.ProductCarouselList>;

const ProductCarouselListScreen = ({ route }: ProductCarouselScreenProps) => {
  const { widgetType, widgetId, widgetParams, actionType = 'cart', itemId } = route.params;
  const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isRefetching } = brsmService.useGetWidgetData(widgetType, widgetId, widgetParams, true);

  useRefetchOnFocus(refetch);
  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<Product>) => {
      return (
        <>
          <ProductCard product={item} actionType={actionType} itemId={itemId} index={index} />
        </>
      );
    },
    [actionType, itemId],
  );

  const renderItemSepatator = useCallback(() => <View style={styles.separator} />, []);
  const handleEndReached = async () => !isFetchingNextPage && fetchNextPage();
  let title,
    titleStyle = {};
  if (actionType === 'substitute') {
    title = 'Suggested Backup Items';
    titleStyle = { ...appStyles.h3B, paddingLeft: 20, paddingTop: 5 };
  }
  return (
    <>
      <Screen headerVisible={true}>
        <NavigationHeader
          style={[styles.chekoutHeaderStyle]}
          title={title}
          subStyle={[{}]}
          titleStyle={[{ margin: 10 }, titleStyle]}
          icon={<CloseButton style={{}} />}
        />

        <FlatList
          testID="productsList"
          data={data?.pages.flatMap((x) => x.products.data)}
          keyExtractor={(_, idx) => idx.toString()}
          renderItem={renderItem}
          numColumns={2}
          ItemSeparatorComponent={renderItemSepatator}
          refreshing={isRefetching}
          refreshControl={
            <RefreshControl refreshing={isLoading} onRefresh={() => refetch<PagedArray<Product>>({ refetchPage: (page) => page.offset === 0 })} />
          }
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.5}
          ListEmptyComponent={<></>}
          ListFooterComponent={<>{isFetchingNextPage && <ActivityIndicator />}</>}
        />
      </Screen>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
    backgroundColor: colors.sectionHeader,
    shadowOpacity: 0,
  },
  separator: {
    backgroundColor: colors.darkCream,
    height: 1.5,
    width: screenWidth * 0.92,
    transform: [{ translateX: (screenWidth * 0.08) / 2 }],
  },
});

export default ProductCarouselListScreen;

import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address } from '@fieldera-raleys/client-common';
import { userService, validationService } from '@services/brandywine';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';
import helpers, { formatPhone } from '../../utils/helpers';
import { queryClient } from '../../utils/reactQuery';
import Icon from '../Icon';
import KeyboardAvoidingScrollView from '../KeyboardAvoidingScrollView';
import LoadingScreen from '../LoadingScreen';
import Modal from '../Modal';
import { PickerComponent, PickerItem } from '../Picker2';
import ErrorMessage from './ErrorMessage';
import Form from './Form';
import FormField from './FormField';
import FormPicker from './FormPicker';
import FormSwitch from './FormSwitch';
import SubmitButton from './SubmitButton';

type AddressFormProps = {
  addressId?: string | number;
  mode?: 'billing' | 'shipping';
  onAfterSubmit?: (addressId: string | number) => void;
};

type AddressFormType = {
  firstName?: string;
  lastName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string;
  companyName?: string;
  isBusinessAddress: boolean;
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
};
const HEADER_HEIGHT = scale(56);

const AddressForm = ({ addressId, mode, onAfterSubmit }: AddressFormProps) => {
  const formValues = useRef<AddressFormType>({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    phone: '',
    companyName: '',
    isBusinessAddress: false,
    isDefaultBilling: false,
    isDefaultShipping: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCompanyField, setshowCompanyField] = useState<boolean>(false);
  const [isbuttonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [showAddressValidationModal, setShowAddressValidationModal] = useState(false);
  const [enteredAddress, setEnteredAddress] = useState<undefined | Address>({
    firstName: '',
    lastName: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    addressType: 'home',
    country: 'US',
    isDefaultBilling: false,
    isDefaultShipping: false,
    isValidated: false,
  });
  const [correctedAddress, setCorrectedAddress] = useState<undefined | Address>({
    firstName: '',
    lastName: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    addressType: 'home',
    country: 'US',
    isDefaultBilling: false,
    isDefaultShipping: false,
    isValidated: false,
  });
  const [selectedAddress, setSelectedAddress] = useState<number>(0);
  const handleError = useErrorHandler();
  const errorMessageRef = useRef<string>();

  const firstnameInputRef = createRef<TextInput>();
  const lastnameInputRef = createRef<TextInput>();
  const address1InputRef = createRef<TextInput>();
  const address2InputRef = createRef<TextInput>();
  const cityInputRef = createRef<TextInput>();
  const stateInputRef = createRef<
    PickerComponent<{
      id: number;
      name: string;
      abbreviation: string;
    }>
  >();
  const postalCodeInputRef = createRef<TextInput>();
  const phoneInputRef = createRef<TextInput>();

  useEffect(() => {
    const initialize = async () => {
      try {
        if (addressId) {
          const address = await queryClient.fetchQuery(['address', addressId], async () => userService.getAddressById(addressId));
          formValues.current = { ...address, isBusinessAddress: address.addressType === 'business', companyName: address.companyName ?? '' };
          setshowCompanyField(address.addressType === 'business');
          setIsLoading(false);
        } else {
          formValues.current = {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: 'US',
            phone: '',
            companyName: '',
            isBusinessAddress: false,
            isDefaultBilling: false,
            isDefaultShipping: false,
          };
          setTimeout(() => {
            setIsLoading(false);
          }, 100);
        }
      } catch (e) {
        handleError(e);
      }
    };
    setIsLoading(true);
    errorMessageRef.current = undefined;
    initialize();
  }, [addressId, handleError]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required().label('First Name'),
    lastName: Yup.string().trim().required().label('Last Name'),
    address1: Yup.string().trim().required().label('Address'),
    city: Yup.string().trim().required().label('City'),
    state: Yup.string().trim().required().label('State'),
    postalCode: Yup.string().trim().matches(helpers.POSTAL_CODE_REGEX, 'Invalid Zip Code').required().label('Zip Code'),
    phone: Yup.string().trim().matches(helpers.PHONE_NUMBER_REGEX, 'Invalid phone number').required().label('Phone'),
    isBusinessAddress: Yup.boolean(),
    companyName: Yup.string()
      .nullable()
      .when('isBusinessAddress', {
        is: true,
        then: Yup.string().required('The Company Name is required'),
      })
      .label('Company Name'),
  });

  const submitAddress = async (formData: AddressFormType) => {
    setIsButtonLoading(true);
    let address = {
      ...formData,
      companyName: formData.isBusinessAddress ? formData.companyName : undefined,
      country: formData.country ?? 'US',
      addressType: formData.isBusinessAddress ? 'business' : 'home',
      isValidated: false,
      validatedDate: undefined,
    } as Address;
    const response = await validationService.validateAddress(address);
    if (response) {
      if (response[0].InterpretedResult === 'Valid') {
        address = {
          ...address,
          address1: response[0].AddressLine1,
          address2: response[0].Suite,
          // addressType: response[0].DeliveryIndicator === 'B' ? 'business' : 'home',
          city: response[0].City,
          postalCode: response[0].PostalCode,
          state: response[0].State,
          isValidated: true,
          validatedDate: response[0].ValidatedDate,
          latitude: response[0].Latitude,
          longitude: response[0].Longitude,
        };
        queryClient.executeMutation({
          mutationKey: ['address', addressId],
          mutationFn: async () => (addressId ? userService.updateAddress(address) : userService.addAddress(address)),
          onError: (ex: any) => {
            if ((ex as Error).message.indexOf('Service Area not found') >= 0) {
              errorMessageRef.current = (ex as Error).message;
            } else {
              handleError(ex);
            }
          },
          onSuccess: async (data) => {
            await queryClient.invalidateQueries(['address', data]);
            await queryClient.invalidateQueries('address-list');
            onAfterSubmit && onAfterSubmit(data);
          },
          onSettled: () => {
            setIsButtonLoading(false);
          },
        });
      } else {
        //Invalid address
        setEnteredAddress(address);
        setCorrectedAddress(undefined);
        if (response[0].InterpretedResult === 'Corrected') {
          //With corrected address
          setCorrectedAddress({
            ...formData,
            addressType: formData.isBusinessAddress ? 'business' : 'home',
            address1: response[0].AddressLine1,
            address2: response[0].AddressLine2 ? response[0].AddressLine2 : response[0].Suite,
            city: response[0].City,
            state: response[0].State,
            postalCode: response[0].PostalCode,
            isValidated: true,
            validatedDate: response[0].ValidatedDate,
            latitude: response[0].Latitude,
            longitude: response[0].Longitude,
          });
        }
        setShowAddressValidationModal(true);
      }
    }
  };

  const saveCorrection = async () => {
    if (enteredAddress) {
      const address = correctedAddress && selectedAddress === 1 ? correctedAddress : enteredAddress;
      queryClient.executeMutation({
        mutationKey: ['address', addressId],
        mutationFn: async () => (addressId ? userService.updateAddress(address) : userService.addAddress(address)),
        onError: (ex: any) => {
          if ((ex as Error).message.indexOf('Service Area not found') >= 0) {
            errorMessageRef.current = (ex as Error).message;
          } else {
            handleError(ex);
          }
        },
        onSuccess: async (data) => {
          await queryClient.invalidateQueries(['address', data]);
          await queryClient.invalidateQueries('address-list');
          onAfterSubmit && onAfterSubmit(data);
        },
        onSettled: () => {
          setIsButtonLoading(false);
        },
      });
    }
    setShowAddressValidationModal(false);
  };

  const handleCancel = () => {
    setIsButtonLoading(false);
    setShowAddressValidationModal(false);
  };

  const handleProceed = async () => {
    if (enteredAddress) {
      queryClient.executeMutation({
        mutationKey: ['address', addressId],
        mutationFn: async () => (addressId ? userService.updateAddress(enteredAddress) : userService.addAddress(enteredAddress)),
        onError: (ex: any) => {
          if ((ex as Error).message.indexOf('Service Area not found') >= 0) {
            errorMessageRef.current = (ex as Error).message;
          } else {
            handleError(ex);
          }
        },
        onSuccess: async (data) => {
          await queryClient.invalidateQueries(['address', data]);
          await queryClient.invalidateQueries('address-list');
          onAfterSubmit && onAfterSubmit(data);
        },
        onSettled: () => {
          setIsButtonLoading(false);
        },
      });
    }
    setShowAddressValidationModal(false);
  };

  const getPickerValue = useCallback((state: string | undefined) => {
    const selected = appConstants.STATES_LIST.find((x) => x.abbreviation === state);
    return selected
      ? ({
        id: selected.abbreviation,
        text: selected?.name,
        value: selected,
      } as PickerItem<{
        id: number;
        name: string;
        abbreviation: string;
      }>)
      : undefined;
  }, []);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <Form initialValues={formValues.current} onSubmit={submitAddress} validationSchema={validationSchema}>
        <KeyboardAvoidingScrollView scrollEnabled={true} extraKeyboardOffset={96} contentContainerStyle={styles.container} bounces={false}>
          <ErrorMessage
            error={errorMessageRef.current}
            visible={!!errorMessageRef.current}
            style={{ ...utilityStyles.p3, textAlign: 'center' }}
            testID={'errorMessage'}
          />
          <FormField
            ref={firstnameInputRef}
            label="First Name"
            autoCapitalize="words"
            autoCorrect={false}
            keyboardType="ascii-capable"
            name="firstName"
            textContentType="givenName"
            returnKeyType="next"
            blurOnSubmit={false}
            onSubmitEditing={() => {
              lastnameInputRef.current?.focus();
            }}
            testID="firstName"
          />
          <FormField
            ref={lastnameInputRef}
            label="Last Name"
            autoCapitalize="words"
            autoCorrect={false}
            keyboardType="ascii-capable"
            name="lastName"
            textContentType="familyName"
            returnKeyType="next"
            blurOnSubmit={false}
            onSubmitEditing={() => {
              address1InputRef.current?.focus();
            }}
            testID="lastName"
          />
          <FormField
            ref={address1InputRef}
            label="Street Address"
            autoCapitalize="words"
            autoCorrect={false}
            keyboardType="ascii-capable"
            name="address1"
            textContentType="streetAddressLine1"
            returnKeyType="next"
            blurOnSubmit={false}
            onSubmitEditing={() => {
              address2InputRef.current?.focus();
            }}
            testID="address1"
          />
          <View style={[styles.row]}>
            <View style={{ flex: 0.45 }}>
              <FormField
                ref={address2InputRef}
                label="Apt /Suite"
                autoCapitalize="words"
                autoCorrect={false}
                keyboardType="ascii-capable"
                name="address2"
                textContentType="streetAddressLine2"
                returnKeyType="next"
                blurOnSubmit={false}
                placeholder={'optional'}
                onSubmitEditing={() => {
                  cityInputRef.current?.focus();
                }}
                testID="address2"
              />
            </View>
            <View style={{ flex: 0.45 }}>
              <FormField
                ref={cityInputRef}
                label="City"
                autoCapitalize="words"
                autoCorrect={false}
                keyboardType="ascii-capable"
                name="city"
                textContentType="addressCity"
                returnKeyType="next"
                blurOnSubmit={false}
                onSubmitEditing={() => {
                  stateInputRef.current?.focus();
                }}
                testID="city"
              />
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={{ flex: 0.45 }}>
              <FormPicker
                ref={stateInputRef}
                label="State"
                name="state"
                onSelect={() => {
                  postalCodeInputRef.current?.focus();
                }}
                initialValue={getPickerValue(formValues.current.state)}
                options={appConstants.STATES_LIST.map((m) => ({ id: m.abbreviation, text: m.name, value: m }))}
                placeholder={'Select'}
                style={{ paddingHorizontal: 10 }}
                textStyle={{ fontSize: scale(18) }}
                headerTitle={'Select Your State'}
                testID="state"
              />
            </View>
            <View style={{ flex: 0.45 }}>
              <FormField
                ref={postalCodeInputRef}
                label="Zip Code"
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="number-pad"
                name="postalCode"
                textContentType="postalCode"
                returnKeyType="done"
                blurOnSubmit={false}
                onSubmitEditing={() => {
                  phoneInputRef.current?.focus();
                }}
                testID="postalCode"
              />
            </View>
          </View>
          <FormField
            ref={phoneInputRef}
            label={'Phone'}
            maxLength={14}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="phone-pad"
            name="phone"
            textContentType="telephoneNumber"
            returnKeyType="done"
            formatter={formatPhone}
            testID="phone"
          />
          <FormSwitch
            testID="isBusinessAddress"
            name={'isBusinessAddress'}
            label={'This is a Business Address'}
            containerStyle={{ alignSelf: 'flex-start' }}
            onChange={() => setshowCompanyField(!showCompanyField)}
          />
          <FormField
            hidden={!showCompanyField}
            label={'Company Name'}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="ascii-capable"
            name="companyName"
            returnKeyType="next"
            testID="companyName"
          />
          {!(mode || mode === 'billing') && (
            <FormSwitch
              testID="isDefaultBilling"
              disabled={(!!addressId && formValues.current.isDefaultBilling) ?? false}
              name={'isDefaultBilling'}
              label={'Set as default Billing Address'}
              containerStyle={{ alignSelf: 'flex-start' }}
            />
          )}
          {!(mode || mode === 'shipping') && (
            <FormSwitch
              testID="isDefaultShipping"
              disabled={(!!addressId && formValues.current.isDefaultShipping) ?? false}
              name={'isDefaultShipping'}
              label={'Set as default Delivery Address'}
              containerStyle={{ alignSelf: 'flex-start' }}
            />
          )}
        </KeyboardAvoidingScrollView>
        <View style={styles.saveBar} testID={'submitBtn'}>
          <SubmitButton testID="AddressFormBtn" title={addressId ? 'Update' : 'Add'} buttonStyle={styles.saveButton} isButtonLoading={isbuttonLoading} />
        </View>
      </Form>
      <Modal
        style={{
          height: correctedAddress
            ? screenHeight * 0.85
            : Platform.select({
              ios: screenHeight * 0.65,
              android: screenHeight * 0.55,
            }),
        }}
        title={'Verify Address'}
        location={'top'}
        cancelButtonOnPress={correctedAddress ? saveCorrection : handleCancel}
        visible={showAddressValidationModal}
        okButtonText={correctedAddress ? undefined : 'Proceed'}
        okButtonOnPress={handleProceed}
        cancelButtonText={correctedAddress ? 'Save' : 'Cancel'}>
        <View>
          <Text style={appStyles.bodyLargeCenter} testID={'correctedAddress'}>
            {'We are unable to confirm the address you entered. '}
            {!correctedAddress && (
              <Text>
                Using this address <Text style={{ color: colors.red, fontFamily: FontFamily.LarsseitBold }}>may cause delivery delays.</Text>
              </Text>
            )}
          </Text>
          {correctedAddress ? (
            <>
              <Text style={[appStyles.bodySmallRed, { alignSelf: 'flex-start', marginLeft: -20, marginTop: 16 }]} testID="deliveryDelayText">
                ORIGINAL: (May cause delivery delays)
              </Text>
              <TouchableOpacity
                onPress={() => setSelectedAddress(0)}
                style={[styles.infoPad, { borderWidth: selectedAddress === 0 ? 1.5 : 1 }]}
                testID="correctedAddressButton">
                <View style={styles.modalIconWrapper}>
                  <Icon
                    name={selectedAddress === 0 ? 'checked-circle' : 'unchecked-circle'}
                    stroke={selectedAddress === 0 ? colors.red : undefined}
                    strokeSecondary={colors.red}
                    testID="correctedAddressSelect"
                  />
                </View>
                <View style={styles.modalTextWrapper}>
                  <Text
                    testID="firstLastName"
                    numberOfLines={1}
                    style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]}>{`${enteredAddress?.firstName} ${enteredAddress?.lastName}`}</Text>
                  <Text numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]} testID={'enteredAddress1'}>
                    {enteredAddress?.address1}
                  </Text>
                  {enteredAddress?.address2 ? (
                    <Text numberOfLines={1} style={[appStyles.bodySmallLeft, { lineHeight: lineHeight(20) }]} testID={'enteredAddress2'}>
                      {enteredAddress.address2}
                    </Text>
                  ) : (
                    <></>
                  )}
                  <Text
                    testID="enteredAddressAll"
                    numberOfLines={1}
                    style={[
                      appStyles.bodySmallLeftRegular,
                      { lineHeight: lineHeight(20) },
                    ]}>{`${enteredAddress?.city}, ${enteredAddress?.state} ${enteredAddress?.postalCode}`}</Text>
                  <Text numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]} testID="enteredAddressPhone">
                    {enteredAddress?.phone}
                  </Text>
                </View>
              </TouchableOpacity>
              <Text style={[appStyles.bodySmallLeft, { alignSelf: 'flex-start', marginLeft: -20, marginTop: 16 }]} testID={'suggested'}>
                SUGGESTED
              </Text>
              <TouchableOpacity
                onPress={() => setSelectedAddress(1)}
                style={[styles.infoPad, { borderWidth: selectedAddress === 1 ? 1.5 : 1 }]}
                testID="suggestedAddressButton">
                <View style={styles.modalIconWrapper}>
                  <Icon
                    name={selectedAddress === 1 ? 'checked-circle' : 'unchecked-circle'}
                    stroke={selectedAddress === 1 ? colors.red : undefined}
                    strokeSecondary={colors.red}
                    testID="suggestedAddressSelect"
                  />
                </View>
                <View style={styles.modalTextWrapper}>
                  <Text
                    testID="correctedAddressFirstLastName"
                    numberOfLines={1}
                    style={[
                      appStyles.bodySmallLeftRegular,
                      { lineHeight: lineHeight(20) },
                    ]}>{`${correctedAddress.firstName} ${correctedAddress.lastName}`}</Text>
                  <Text numberOfLines={1} style={[appStyles.bodySmallLeft, { lineHeight: lineHeight(20) }]} testID="correctedAddress1">
                    {correctedAddress.address1}
                  </Text>
                  {correctedAddress.address2 ? (
                    <Text numberOfLines={1} style={[appStyles.bodySmallLeft, { lineHeight: lineHeight(20) }]} testID="correctedAddress2">
                      {correctedAddress.address2}
                    </Text>
                  ) : (
                    <></>
                  )}
                  <Text
                    testID="correctedAddressAll"
                    numberOfLines={1}
                    style={[
                      appStyles.bodySmallLeftRegular,
                      { lineHeight: lineHeight(20) },
                    ]}>{`${correctedAddress.city}, ${correctedAddress.state} ${correctedAddress.postalCode}`}</Text>
                  <Text numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]} testID="correctedAddressPhone">
                    {correctedAddress.phone}
                  </Text>
                </View>
              </TouchableOpacity>
            </>
          ) : enteredAddress ? (
            <View style={[styles.infoPad, utilityStyles.mt3]} testID="enteredAddress">
              <View style={[styles.modalIconWrapper, utilityStyles.mx4]}>
                <Icon name={'caution-icon'} size={30} testID="cautionIcon" />
              </View>
              <View style={styles.modalTextWrapper}>
                <Text
                  testID="eaFirstLatsName"
                  numberOfLines={1}
                  style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]}>{`${enteredAddress.firstName} ${enteredAddress.lastName}`}</Text>
                <Text numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]} testID="eaAddress1">
                  {enteredAddress.address1}
                </Text>
                <Text
                  testID="eaAddressAll"
                  numberOfLines={1}
                  style={[
                    appStyles.bodySmallLeftRegular,
                    { lineHeight: lineHeight(20) },
                  ]}>{`${enteredAddress.city}, ${enteredAddress.state} ${enteredAddress.postalCode}`}</Text>
                <Text numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(20) }]} testID="eaPhone">
                  {enteredAddress.phone}
                </Text>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  saveButton: {
    width: screenWidth * 0.4,
    height: '70%',
    backgroundColor: colors.darkCream,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    padding: 0,
  },
  saveBar: {
    width: screenWidth,
    bottom:
      Platform.OS === 'web'
        ? -screenHeight * 0.255
        : Platform.OS === 'ios'
          ? screenHeight < 740
            ? screenHeight * 0.005
            : -screenHeight * 0.007
          : screenHeight < 641
            ? screenHeight * 0.0095
            : screenHeight * 0.015,
    alignSelf: 'center',
    height: HEADER_HEIGHT,
    backgroundColor: colors.white,
    borderTopWidth: 1,
    borderColor: '#707070',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoPad: {
    backgroundColor: colors.white,
    width: containerWidth,
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    minHeight: scale(90),
    alignContent: 'space-between',
    alignItems: 'center',
  },
  modalIconWrapper: {
    width: '22%',
    alignItems: 'center',
  },
  modalTextWrapper: {
    width: '75%',
    alignItems: 'flex-start',
  },
  container: {
    ...appStyles.container,
    paddingBottom: 20,
  },
});
export default AddressForm;

import { PagedArray } from '@fieldera-raleys/client-common';
import { Promotion, Voucher } from '@fieldera-raleys/client-common/services/brandywine/types';
import { offerService } from '@services/brandywine';
import logger from '@utils/logger';
import { CancelToken } from 'axios';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
// import useShopStore from './shopStore';
import { LoadingStatus, OffersStore } from './storeTypes';

// const getSelectedStore = () => useShopStore.getState().selectedStore?.number ?? '1';
const getSelectedStore = () => '';
let refreshVouchersTm: NodeJS.Timeout;

const useOffersStore = create<OffersStore>()(
  persist(
    (set, get) => {
      return {
        availableSomethingExtraOffers: [] as Promotion[],
        acceptedSomethingExtraOffers: [] as Promotion[],
        availableWeeklyExclusiveOffers: [] as Promotion[],
        acceptedWeeklyExclusiveOffers: [] as Promotion[],
        availableDigitalCoupons: [] as Promotion[],
        acceptedDigitalCoupons: [] as Promotion[],
        availableVouchers: [] as Voucher[],
        acceptedVouchers: [] as Voucher[],
        redeemedVouchers: [] as Voucher[],
        nonTargetedOffers: [] as Promotion[],
        offersState: 'loading' as LoadingStatus,
        vouchersState: 'loading' as LoadingStatus,
        refreshingVouchers: false as boolean,
        digitalCouponsState: 'success' as LoadingStatus,
        initialize: (cts?: CancelToken) => {
          get().fetchSomethingExtraOffers(cts);
          get().fetchWeeklyExclusiveOffers(cts);
          get().fetchDigitalCoupons(cts);
          get().fetchVouchers(cts);
          get().fetchNonTargetedOffers(cts);
        },
        fetchOffers: async (cts?: CancelToken) => {
          get().fetchSomethingExtraOffers(cts);
          get().fetchWeeklyExclusiveOffers(cts);
          get().fetchDigitalCoupons(cts);
          get().fetchNonTargetedOffers(cts);
        },
        fetchOfferByOfferId: async (offerId: number) => {
          const offers = [
            ...get().availableSomethingExtraOffers,
            ...get().availableWeeklyExclusiveOffers,
            ...get().availableDigitalCoupons,
            ...get().acceptedSomethingExtraOffers,
            ...get().acceptedWeeklyExclusiveOffers,
            ...get().acceptedDigitalCoupons,
            ...get().nonTargetedOffers,
          ];

          const promos = offers.filter((x) => Number(x.ExtPromotionId) === offerId);

          if (promos && promos.length > 0) {
            return Promise.resolve(promos[0]);
          }

          return await offerService.getCustomerPromotionById(offerId);
        },
        fetchNonTargetedOffers: async (cts?: CancelToken) => {
          try {
            Promise.all<PagedArray<Promotion>>([offerService.getNonTargeted('app', getSelectedStore(), 0, 999, undefined, cts)]).then((results) => {
              set((state) => ({
                ...state,
                nonTargetedOffers: results[0].data,
                offersState: 'success',
              }));
            });
          } catch (error: any) {
            error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
            set((state) => ({
              ...state,
              nonTargetedOffers: [],
              offersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
            }));
          }
        },
        fetchSomethingExtraOffers: async (cts?: CancelToken) => {
          try {
            Promise.all<PagedArray<Promotion>>([
              offerService.getTargeted('app', 'available', getSelectedStore(), 0, 999, 'SomethingExtra', cts),
              offerService.getTargeted('app', 'accepted', getSelectedStore(), 0, 999, 'SomethingExtra', cts),
            ]).then((results) => {
              set((state) => ({
                ...state,
                availableSomethingExtraOffers: results[0].data,
                acceptedSomethingExtraOffers: results[1].data,
                offersState: 'success',
              }));
            });
          } catch (error: any) {
            error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
            set((state) => ({
              ...state,
              availableSomethingExtraOffers: [],
              acceptedSomethingExtraOffers: [],
              offersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
            }));
          }
        },
        fetchWeeklyExclusiveOffers: async (cts?: CancelToken) => {
          try {
            Promise.all<PagedArray<Promotion>>([
              offerService.getTargeted('app', 'available', getSelectedStore(), 0, 999, 'WeeklyExclusive', cts),
              offerService.getTargeted('app', 'accepted', getSelectedStore(), 0, 999, 'WeeklyExclusive', cts),
            ]).then((results) => {
              set((state) => ({
                ...state,
                availableWeeklyExclusiveOffers: results[0].data,
                acceptedWeeklyExclusiveOffers: results[1].data,
                offersState: 'success',
              }));
            });
          } catch (error: any) {
            error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
            set((state) => ({
              ...state,
              availableWeeklyExclusiveOffers: [],
              acceptedWeeklyExclusiveOffers: [],
              offersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
            }));
          }
        },
        fetchDigitalCoupons: async (cts?: CancelToken) => {
          try {
            Promise.all<PagedArray<Promotion>>([
              offerService.getCoupons('app', 'available', getSelectedStore(), 0, 999, undefined, cts),
              offerService.getCoupons('app', 'accepted', getSelectedStore(), 0, 999, undefined, cts),
            ]).then((results) => {
              set((state) => ({
                ...state,
                availableDigitalCoupons: results[0].data,
                acceptedDigitalCoupons: results[1].data,
                cou: 'success',
              }));
            });
          } catch (error: any) {
            error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
            set((state) => ({
              ...state,
              availableDigitalCoupons: [],
              acceptedDigitalCoupons: [],
              digitalCouponsState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
            }));
          }
        },
        fetchVouchers: async (cts?: CancelToken) => {
          try {
            Promise.all<PagedArray<Voucher>>([
              offerService.getVouchers('app', 'available', 0, 999, cts),
              offerService.getVouchers('app', 'accepted', 0, 999, cts),
              offerService.getVouchers('app', 'redeemed', 0, 999, cts),
            ]).then((results) => {
              set((state) => ({
                ...state,
                availableVouchers: results[0].data,
                acceptedVouchers: results[1].data,
                redeemedVouchers: results[2].data,
                vouchersState: 'success',
              }));
            });
          } catch (error: any) {
            error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
            set((state) => ({
              ...state,
              availableVouchers: [],
              acceptedVouchers: [],
              redeemedVouchers: [],
              vouchersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
            }));
          }
        },
        refreshVouchers: () => {
          clearTimeout(refreshVouchersTm);
          refreshVouchersTm = setTimeout(() => {
            if (get().refreshingVouchers === true) {
              set((state) => ({ ...state, refreshingVouchers: false }));
              get().refreshVouchers();
            } else {
              get().fetchVouchers();
            }
          }, 2000);
        },
        acceptOffer: async (offerId: number | number[], cts?: CancelToken) => {
          let acceptedIds = Array.isArray(offerId) ? offerId : [offerId];
          let available = [...get().availableSomethingExtraOffers, ...get().availableWeeklyExclusiveOffers];
          let offers = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
          if (!offers.length) {
            //refetch
            const ac = await offerService.getTargeted('app', 'available', getSelectedStore(), 0, 999, 'SomethingExtra', cts);
            const uac = await offerService.getTargeted('app', 'accepted', getSelectedStore(), 0, 999, 'SomethingExtra', cts);
            set((state) => ({
              ...state,
              availableSomethingExtraOffers: ac.data,
              acceptedSomethingExtraOffers: uac.data,
              offersState: 'success',
            }));
            available = [...ac.data, ...uac.data];
            offers = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
            if (!offers.length) {
              return;
            }
          }
          const result = await offerService.acceptOffers(acceptedIds);
          if (result) {
            let accepted = [...get().acceptedSomethingExtraOffers, ...get().acceptedWeeklyExclusiveOffers];
            accepted.unshift(...offers);

            set((state) => ({
              ...state,
              availableSomethingExtraOffers: available.filter(
                (x) => x.ExtBadgeTypeCode === 'SomethingExtra' && !acceptedIds.includes(Number(x.ExtPromotionId)),
              ),
              acceptedSomethingExtraOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'SomethingExtra'),
              availableWeeklyExclusiveOffers: available.filter(
                (x) => x.ExtBadgeTypeCode === 'WeeklyExclusive' && !acceptedIds.includes(Number(x.ExtPromotionId)),
              ),
              acceptedWeeklyExclusiveOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'WeeklyExclusive'),
            }));
          }
        },
        unacceptOffer: async (offerId: number) => {
          const accepted = [...get().acceptedSomethingExtraOffers, ...get().acceptedWeeklyExclusiveOffers];
          const offer = accepted.find((x) => Number(x.ExtPromotionId) === offerId);
          if (!offer) {
            return;
          }

          const result = await offerService.unacceptOffer(offerId);
          if (result) {
            const available = [...get().availableSomethingExtraOffers, ...get().availableWeeklyExclusiveOffers];
            available.unshift(offer);

            set((state) => ({
              ...state,
              availableSomethingExtraOffers: available.filter((x) => x.ExtBadgeTypeCode === 'SomethingExtra'),
              acceptedSomethingExtraOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'SomethingExtra' && offerId !== Number(x.ExtPromotionId)),
              availableWeeklyExclusiveOffers: available.filter((x) => x.ExtBadgeTypeCode === 'WeeklyExclusive'),
              acceptedWeeklyExclusiveOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'WeeklyExclusive' && offerId !== Number(x.ExtPromotionId)),
            }));
          }
        },
        acceptVoucher: async (offerId: number | number[]) => {
          const acceptedIds = Array.isArray(offerId) ? offerId : [offerId];
          const available = get().availableVouchers;
          const accepted = get().acceptedVouchers;
          const voucher = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
          if (!voucher.length) {
            return;
          }
          set((state) => ({ ...state, refreshingVouchers: true }));
          const result = await offerService.acceptOffers(acceptedIds);
          if (result) {
            accepted.unshift(...voucher);
            set((state) => ({
              ...state,
              availableVouchers: available.filter((x) => !acceptedIds.includes(Number(x.ExtPromotionId))),
              acceptedVouchers: accepted,
            }));
          }
          get().refreshVouchers();
        },
        unacceptVoucher: async (offerId: number) => {
          const accepted = get().acceptedVouchers;
          const available = get().availableVouchers;
          const voucher = accepted.find((x) => +x.ExtPromotionId === offerId);
          if (!voucher) {
            return;
          }
          set((state) => ({ ...state, refreshingVouchers: true }));
          const result = await offerService.unacceptOffer(offerId);
          if (result) {
            available.unshift(voucher);
            set((state) => ({
              ...state,
              availableVouchers: available,
              acceptedVouchers: accepted.filter((x) => Number(x.ExtPromotionId) !== offerId),
            }));
          }
          get().refreshVouchers();
        },
        acceptCoupon: async (offerId: number | number[]) => {
          const acceptedIds = Array.isArray(offerId) ? offerId : [offerId];
          const available = get().availableDigitalCoupons;
          const offers = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
          if (!offers.length) {
            return;
          }
          try {
            const result = await offerService.acceptCoupon(acceptedIds);
            if (result) {
              const accepted = get().acceptedDigitalCoupons;
              accepted.unshift(...offers);

              set((state) => ({
                ...state,
                availableDigitalCoupons: available.filter((x) => !acceptedIds.includes(Number(x.ExtPromotionId))),
                acceptedDigitalCoupons: accepted,
              }));
            }
          } catch (ex: any) {
            ex.message !== 'CANCEL_ERROR' && logger.warn('failed to get offers', ex);
          }
        },
        acceptPromo: async (promoCode: string) => {
          offerService
            .acceptPromo(promoCode)
            .then(() => {
              return true;
            })
            .catch((ex: any) => {
              ex.message !== 'CANCEL_ERROR' && logger.warn(`failed to accept promo ${promoCode ?? 'undefined'}`, ex);
            });
        },
        unAcceptPromo: async (promoCode: string) => {
          return offerService
            .unAcceptPromo(promoCode)
            .then(() => {
              return true;
            })
            .catch((ex: any) => {
              ex.message !== 'CANCEL_ERROR' && logger.warn(`failed to unaccept promo ${promoCode ?? 'undefined'}`, ex);
              return false;
            });
        },
        clearOffersStore: () => {
          set((state) => ({
            ...state,
            availableSomethingExtraOffers: [] as Promotion[],
            acceptedSomethingExtraOffers: [] as Promotion[],
            availableWeeklyExclusiveOffers: [] as Promotion[],
            acceptedWeeklyExclusiveOffers: [] as Promotion[],
            availableDigitalCoupons: [] as Promotion[],
            acceptedDigitalCoupons: [] as Promotion[],
            availableVouchers: [] as Voucher[],
            acceptedVouchers: [] as Voucher[],
            redeemedVouchers: [] as Voucher[],
            offersState: 'loading',
            vouchersState: 'loading',
            digitalCouponsState: 'loading',
          }));
        },
      };
    },
    {
      name: 'uc-offers', // unique name
      storage: createJSONStorage<OffersStore>(() => asyncStorage), // (optional) by default the 'localStorage' is used
    },
  ),
);

export default useOffersStore;

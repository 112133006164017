import { ProductService } from '@fieldera-raleys/client-commercetools/api';
import Config from 'react-native-config';
import { getJwtToken } from '../authHelper';
import { getSelectedStoreKey } from '../helper';

const productService = new ProductService({
  apiUrl: Config.CT_API_URL,
  authToken: getJwtToken,
  selectedStoreKey: getSelectedStoreKey,
});
export default productService;

import Text from '@components/Text';
import colors from '@config/colors';
import { Store } from '@fieldera-raleys/client-common';
import { appStyles } from '@styles';
import { scale } from '@styles/constants';
import React, { forwardRef, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import MapView, { MapEvent, MapViewProps, Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import Icon from '../Icon';

interface StoreMapViewProps extends Omit<MapViewProps, 'onMarkerPress'> {
  containerStyle?: StyleProp<ViewStyle>;
  data: Store[] | undefined;
  activeMarker?: Store;
  onMarkerPress?: (marker: Store, index: number) => void | undefined;
  maxZoomLevel?: number;
}

const StoreMapView = forwardRef<MapView, StoreMapViewProps>(
  ({ containerStyle, data, activeMarker, onPress, onMarkerPress, maxZoomLevel, ...restProps }, ref) => {
    const [region, setRegion] = React.useState({
      latitude: 0,
      longitude: 0,
      latitudeDelta: 0,
      longitudeDelta: 0,
    });
    const mapRef = useRef<MapView>();
    const fitToMarkers = useCallback(() => {
      if (Platform.OS === 'ios') {
        mapRef?.current?.fitToSuppliedMarkers(data ? data.map(({ id }) => id.toString()) : [], {
          edgePadding: styles.mapEdgePadding,
        });
      } else {
        setTimeout(() => {
          mapRef?.current?.fitToSuppliedMarkers(data ? data.map(({ id }) => id.toString()) : [], {
            edgePadding: styles.mapEdgePadding,
          });
        }, 1000);
      }
    }, [data, mapRef]);

    useEffect(() => {
      if (data) {
        setRegion({
          latitude: parseFloat(data[0]?.coordinates.latitude),
          longitude: parseFloat(data[0]?.coordinates.longitude),
          latitudeDelta: 0.1,
          longitudeDelta: 0.1,
        });
      }

      if (activeMarker === undefined) {
        fitToMarkers();
      }
    }, [activeMarker, data, fitToMarkers]);

    const mapStyle = [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ];

    const getMarkerIcon = (index: number, marker: Store) => {
      let fillColor;
      let strokeColor;
      let textColor;
      if (activeMarker?.address.street === marker.address.street) {
        fillColor = colors.secondary;
        strokeColor = colors.secondary;
        textColor = colors.white;
      } else {
        fillColor = colors.white;
        strokeColor = colors.dark;
        textColor = colors.black;
      }
      return (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon name="location-pin" fill={fillColor} stroke={strokeColor} style={appStyles.largeIcon} size={30} />
          <Text style={[styles.markerIconText, { color: textColor }]} testID="markerNumber">
            {index}
          </Text>
        </View>
      );
    };

    const showMarkerOnMap = (marker: Store, index: number) => {
      return (
        <Marker
          testID={marker.id.toString()}
          identifier={marker.id.toString()}
          key={marker.id}
          coordinate={{
            latitude: parseFloat(marker.coordinates.latitude),
            longitude: parseFloat(marker.coordinates.longitude),
          }}
          focusable
          anchor={{ x: 0, y: 0 }}
          onPress={() => {
            onMarkerPress && onMarkerPress(marker, index);
          }}>
          {getMarkerIcon(index + 1, marker)}
        </Marker>
      );
    };

    const handleOnPress = (event: MapEvent) => {
      onPress && onPress(event);
    };

    return (
      <View style={[styles.mapContainer, containerStyle]}>
        <MapView
          onPress={handleOnPress}
          customMapStyle={mapStyle}
          loadingEnabled
          scrollEnabled
          zoomEnabled
          pitchEnabled
          rotateEnabled
          ref={(map) => {
            if (map) {
              mapRef.current = map;
              if (ref) {
                (ref as MutableRefObject<MapView>).current = map;
              }
            }
          }}
          provider={PROVIDER_GOOGLE}
          style={styles.map}
          initialRegion={region}
          showsUserLocation
          focusable
          showsPointsOfInterest={false}
          showsBuildings={false}
          minZoomLevel={1}
          maxZoomLevel={maxZoomLevel}
          {...restProps}>
          {data?.map((marker: Store, index) => showMarkerOnMap(marker, index))}
        </MapView>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  mapContainer: {
    ...StyleSheet.absoluteFillObject,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  markerIconText: {
    position: 'absolute',
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(12),
    paddingEnd: 5,
    top: Platform.OS === 'android' ? 3 : 6,
  },
  mapEdgePadding: {
    top: scale(25),
    right: scale(25),
    bottom: scale(100),
    left: scale(25),
  },
});

export default StoreMapView;

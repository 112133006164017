import { AnalyticsService } from '@fieldera-raleys/client-common';
import useAnalyticsStore from '@store/analyticsStore';
import { logToAppCenter } from '@utils/analyticsHelper';
import Config from 'react-native-config';
import { getUniqueId } from './authHelper';
import { getSelectedStoreKey, getUserSepiNumber } from './helper';

const analyticsService = new AnalyticsService({
  accountId: Config.BRSM_ACCOUNT_ID,
  apiUrl: Config.BRSM_PIXEL_URL,
  hitCount: async (title) => Promise.resolve(useAnalyticsStore.getState().getHitCount(title)),
  selectedStoreKey: getSelectedStoreKey,
  uniqueId: getUniqueId,
  userId: getUserSepiNumber,
  eventLogger: (eventType, eventData) => logToAppCenter(eventType, eventData),
});

export default analyticsService;

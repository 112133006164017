import { CloseButton, Icon, LoadingScreen, NavigationHeader, Screen } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { AppStackRoutes, AppStackScreenProps, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { brsmService } from '@services';
import { appStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut, TransitioningView } from 'react-native-reanimated';

type AccordionListScreenProps = AppStackScreenProps<AppStackRoutes.AccordionScreen>;

const AccordionListScreen = ({ route, navigation }: AccordionListScreenProps) => {
  const { categoryId, title } = route?.params;
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const isRoot = categoryId === undefined;
  const viewRef = useRef<TransitioningView>(null);
  const { status, data, error: categoriesLoadError } = brsmService.useCategoriesQuery();
  useErrorHandler(categoriesLoadError);

  useEffect(() => {
    const getCategoriesData = async () => {
      if (status === 'success' && data) {
        if (categoryId) {
          setCategoryData(data.filter((item) => item.id === categoryId)[0].children);
        } else {
          setCategoryData(data.filter((item) => !item.parentName));
        }
        setPageLoading(false);
      }
    };
    getCategoriesData();
  }, [status, data, categoryId]);

  return pageLoading ? (
    <LoadingScreen />
  ) : (
    <Screen>
      <Animated.View
        entering={FadeIn.duration(400)}
        exiting={FadeOut.duration(100)}
        style={[styles.container, { backgroundColor: isRoot ? colors.cream : colors.white }]}>
        <NavigationHeader
          style={{ borderBottomColor: '#B4B4B4', backgroundColor: isRoot ? colors.cream : colors.white }}
          icon={<CloseButton size={24} style={{ top: 5 }} />}>
          <HeaderTitle style={{ top: -20 }}>{title}</HeaderTitle>
        </NavigationHeader>
        <ScrollView contentContainerStyle={styles.listContainer}>
          {categoryData.map((item: Category, index: number) => {
            return (
              <View style={[styles.catWrapper, isRoot ? { borderColor: '#B4B4B4' } : null]} key={index.toString()}>
                <TouchableOpacity
                  onPress={() => {
                    if (!isRoot) {
                      navigation.navigate(AppStackRoutes.RootTabs, {
                        screen: RootTabRoutes.Shop,
                        params: { screen: ShopStackRoutes.ProductList, params: { rootCategoryId: item.rootId, categoryId: item.id } },
                      });
                      return;
                    }
                    if (isRoot && item.children.length <= 0) {
                      navigation.navigate(AppStackRoutes.RootTabs, {
                        screen: RootTabRoutes.Shop,
                        params: { screen: ShopStackRoutes.Category, params: { categoryId: item.id } },
                      });
                      return;
                    }
                    viewRef?.current?.animateNextTransition();
                    setCurrentIndex(currentIndex === index ? null : index);
                  }}
                  activeOpacity={0.8}
                  style={styles.topLevelButton}>
                  <Text style={[appStyles.fontMobileListSmallLeft, !isRoot ? appStyles.fontMobileListSmallLeftRegular : null]}>
                    {item.name}
                    {item.count && item.count > 0 ? ` (${item.count})` : null}
                  </Text>
                  {isRoot && item.children.length > 0 ? (
                    <View>
                      {currentIndex === index ? (
                        <Icon name="arrow-up" size={20} style={{ marginTop: 10 }} />
                      ) : (
                        <Icon name="arrow-down" size={20} style={{ marginTop: 10 }} />
                      )}
                    </View>
                  ) : null}
                </TouchableOpacity>
                {isRoot ? (
                  <View>
                    {currentIndex === index && (
                      <View style={styles.subCatContainer}>
                        {item.children.map((sub: Category) => {
                          return (
                            <TouchableOpacity
                              key={sub.id}
                              onPress={() => {
                                navigation.navigate(AppStackRoutes.RootTabs, {
                                  screen: RootTabRoutes.Shop,
                                  params: {
                                    screen: ShopStackRoutes.ProductList,
                                    params: {
                                      rootCategoryId: sub.rootId,
                                      categoryId: sub.id,
                                    },
                                  },
                                });
                              }}>
                              <Text style={styles.subCatText}>
                                {sub.name}
                                {sub.count && sub.count > 0 ? ` (${sub.count})` : null}
                              </Text>
                            </TouchableOpacity>
                          );
                        })}
                      </View>
                    )}
                  </View>
                ) : null}
              </View>
            );
          })}
        </ScrollView>
      </Animated.View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: colors.cream,
    alignItems: 'center',
  },
  container: {
    width: screenWidth,
    height: screenHeight,
    paddingBottom: 25,
    backgroundColor: colors.cream,
    alignItems: 'center',
  },
  listContainer: {
    paddingBottom: screenHeight < 740 ? screenHeight * 0.11 : screenHeight * 0.15,
  },
  catWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
    flexGrow: 1,
  },
  topLevelButton: {
    width: screenWidth * 0.93,
    height: 60,
    paddingRight: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  topLevelText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    color: colors.darkText,
  },
  subCatContainer: {
    flexGrow: 1,
  },
  subCatText: {
    marginBottom: 25,
    marginLeft: 30,
    fontFamily: 'Larsseit-Light',
    fontSize: scale(16),
    color: colors.darkText,
  },
});
export default AccordionListScreen;

import { Route } from '@react-navigation/native';
import { create } from 'zustand';
import { AnalyticsStore } from './storeTypes';

const useAnalyticsStore = create<AnalyticsStore>()((set, get) => {
  const hitCount: Record<string, number> = {};
  return {
    previousRoute: undefined,
    setPreviousRoute: (route: Route<string, object | undefined> | undefined) => {
      const previous = get().previousRoute;
      if (route && previous?.name !== route.name) {
        hitCount[route.name] = (hitCount[route.name] ?? 0) + 1;
        set((state) => ({
          ...state,
          previousRoute: route,
        }));
      }
    },
    getHitCount: (routeName) => {
      return hitCount[routeName] ?? 1;
    },
  };
});

export default useAnalyticsStore;

import { Button, LinkButton, LoadingScreen, NavigationHeader, Screen, Text } from '@components';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AccountStackRoutes, AppStackRoutes, HelpCenterStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useLinkBuilder, useNavigation } from '@react-navigation/native';
import liveagentService from '@services/salesforce/liveagentService';
import { useAnalyticsStore, useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight } from '@styles/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
import { WebView, WebViewMessageEvent } from 'react-native-webview';
import { useQuery } from 'react-query';
import { useCustomModalContext } from '../contexts';

type ChatStatus = 'not-started' | 'started' | 'canceled' | 'ended' | 'agent-ended';
const LiveAgentChatScreen = () => {
  const [state, setState] = useState<{
    sessionId?: string;
    contentServerUrl?: string;
    isAvailable: boolean;
    loading: boolean;
    chatStatus: ChatStatus;
    prechatData: Record<string, any>;
  }>({ loading: true, isAvailable: false, chatStatus: 'not-started', prechatData: {} });
  const { data: isAvailable, status } = useQuery(['liveagent_availablity'], async () => await liveagentService.getAgentAvailablity());
  const { userProfile } = useUserProfileStore();
  const { previousRoute } = useAnalyticsStore();
  const buildLink = useLinkBuilder();
  const { showModal } = useCustomModalContext();
  const navigation = useNavigation();
  const webViewRef = useRef<WebView>();
  const runBeforeFirst = `window.onerror = function(message, sourcefile, lineno, colno, error) {
                            alert("Message: " + message + " - Source: " + sourcefile + " Line: " + lineno + ":" + colno);
                            return true;
                          };
                          window.isNativeApp = true;
                          window.name = "liveagent";
                          true; // note: this is required, or you'll sometimes get silent failures
                        `;
  // add envent handlers from liveagent.chasitor.Events.* to handle cancel / end events
  const jsCode = `
                  function handlePageLoad() {
                    var css = '.liveAgentCancelButton, .liveAgentSaveButton { display:none !important; } ';
                    const head = document.head || document.getElementsByTagName('head')[0];
                    const style = document.createElement('style'); 
                    style.setAttribute('type', 'text/css');
                    if (style.styleSheet){
                      // This is required for IE8 and below.
                      style.styleSheet.cssText = css;
                    } else {
                      style.appendChild(document.createTextNode(css));
                    }
                    head.appendChild(style);
                    
                    const script = document.createElement('script'); 
                    script.setAttribute('type', 'text/javascript');
                    script.appendChild(document.createTextNode(" \
                      var endButtonEl = document.querySelector('.liveAgentEndButton'); \
                      if (endButtonEl) { \
                        endButtonEl.addEventListener('click',() => { window.ReactNativeWebView.postMessage('ended')}); \
                      } \
                      var cancelButtonEl = document.querySelector('.liveAgentCancelButton'); \
                      if (cancelButtonEl) { \
                        cancelButtonEl.addEventListener('click',() => { window.ReactNativeWebView.postMessage('canceled')}); \
                      } \
                      liveagent.addEventListener(liveagent.chasitor.Events.CHAT_ESTABLISHED,() => { window.ReactNativeWebView.postMessage('started')} ); \
                      liveagent.addEventListener(liveagent.chasitor.Events.AGENT_CHAT_ENDED,() => { window.ReactNativeWebView.postMessage('agent-ended')} ); \
                    "));
                    head.appendChild(script);
                  } 

                  if (window.addEventListener) {
                    window.addEventListener('load', handlePageLoad, false);
                    window.addEventListener('message',function(event){
                    }, false);
                  } else { 
                    window.attachEvent('onload', handlePageLoad, false);
                    window.attachEvent('message',function(event){
                    }, false);
                  }
            
                  `;

  useEffect(() => {
    const initialize = async () => {
      const sessionId = await liveagentService.getSessionId();
      const settings = await liveagentService.getSettings();
      setState((prev) => ({
        ...prev,
        sessionId: sessionId,
        contentServerUrl: settings?.contentServerUrl ?? Config.SALESFORCE_CHAT_SERVER_URL,
        isAvailable: isAvailable ?? false,
        loading: false,
        prechatData: {
          deploymentId: Config.SALESFORCE_CHAT_DEPLOYMENT_ID,
          orgId: Config.SALESFORCE_CHAT_ORG_ID,
          vc: 0,
          sid: sessionId,
          ptid: undefined,
          det: [
            { label: 'Contact E-mail', value: userProfile?.email, displayToAgent: true, entityMaps: [], transcriptFields: [], doKnowledgeSearch: false },
            { label: 'First Name', value: userProfile?.firstName, displayToAgent: true, entityMaps: [], transcriptFields: [], doKnowledgeSearch: false },
            { label: 'Last Name', value: userProfile?.lastName, displayToAgent: true, entityMaps: [], transcriptFields: [], doKnowledgeSearch: false },
            { label: 'Phone Number', value: userProfile?.phone, displayToAgent: true, entityMaps: [], transcriptFields: [], doKnowledgeSearch: false },
            { label: 'Customer Id', value: userProfile?.sepiNumber, displayToAgent: true, entityMaps: [], transcriptFields: [], doKnowledgeSearch: false },
            { label: 'Loyalty Id', value: userProfile?.loyaltyId, displayToAgent: true, entityMaps: [], transcriptFields: [], doKnowledgeSearch: false },
          ],
          oref: undefined,
          pages: [{ location: previousRoute?.name && buildLink(previousRoute?.name, previousRoute?.params), time: '4873' }],
          sessionStart: 4873,
          ent: [
            {
              entityName: 'Contact',
              showOnCreate: false,
              linkToEntityName: '',
              linkToEntityField: '',
              entityFieldsMaps: [
                { fieldName: 'Email', label: 'Contact E-mail', doFind: true, isExactMatch: true, doCreate: true },
                { fieldName: 'FirstName', label: 'First Name', doFind: false, isExactMatch: false, doCreate: true },
                { fieldName: 'LastName', label: 'Last Name', doFind: false, isExactMatch: false, doCreate: true },
                { fieldName: 'Phone', label: 'Phone Number', doFind: false, isExactMatch: false, doCreate: true },
                { fieldName: 'CustomerId', label: 'Customer Id', doFind: false, isExactMatch: false, doCreate: true },
                { fieldName: 'LoyaltyId', label: 'Loyalty Id', doFind: false, isExactMatch: false, doCreate: true },
              ],
            },
            {
              entityName: 'Contact',
              saveToTranscript: 'ContactId',
              showOnCreate: true,
              linkToEntityName: 'Case',
              linkToEntityField: 'ContactId',
              entityFieldsMaps: [],
            },
          ],
          visitorName: `${userProfile?.firstName} ${userProfile?.lastName}`,
        },
      }));
    };
    if (status === 'success' && isAvailable) {
      initialize();
    } else {
      setState((prev) => ({ ...prev, loading: false, chatStatus: 'not-started', isAvailable: false, prechatData: {} }));
    }

    return () => {
      setState((prev) => ({ ...prev, loading: true, chatStatus: 'not-started', isAvailable: false, prechatData: {} }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isAvailable]);

  const goBack = useCallback(() => {
    let parent = navigation;
    while (parent.getState()?.index === 0 && parent.getParent()) {
      parent = parent.getParent();
    }
    parent?.goBack();
  }, [navigation]);

  useEffect(() => {
    if (state.chatStatus === 'canceled' || state.chatStatus === 'ended') {
      setTimeout(goBack, 250);
    }
  }, [goBack, state.chatStatus]);

  const handlePress = () => {
    if (state.chatStatus !== 'started') {
      goBack();
    } else {
      showModal({
        okButtonOnPress: () => {
          webViewRef.current && webViewRef.current.postMessage('ended');
          setState((prev) => ({ ...prev, chatStatus: 'ended' }));
        },
        title: 'End Chat?',
        children: (
          <View style={styles.modalBodyContainer}>
            <Text testID="sessionClosingText">If you leave, you will not be able to continue commenting on this chat.</Text>
          </View>
        ),
        okButtonText: 'Yes',
        location: 'top',
        cancelButtonText: 'No',
      });
    }
  };

  return (
    <Screen>
      <NavigationHeader title={'Live Chat'} onPress={handlePress} />
      {state.loading ? (
        <LoadingScreen />
      ) : !state.isAvailable ? (
        <View style={styles.offlineContainer}>
          <Text testID="offlineTitleText" style={styles.offlineTitleText}>
            Unfortunately, no representatives are available at this time.
          </Text>
          <Text testID="offlineBodyText" style={styles.offlineBodyText}>
            For other options, please visit our
          </Text>
          <LinkButton
            style={styles.offlineLinkButtonStyle}
            onPress={() =>
              navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Account,
                params: {
                  screen: AccountStackRoutes.HelpCenter,
                  params: { screen: HelpCenterStackRoutes.HelpCenterScreen },
                },
              })
            }>
            Help Center
          </LinkButton>
        </View>
      ) : state.chatStatus !== 'agent-ended' ? (
        <WebView
          ref={(ref) => (webViewRef.current = ref ?? undefined)}
          style={state.loading && { display: 'none' }}
          sharedCookiesEnabled={true}
          source={{
            // html: customHTML,
            uri: `${state.contentServerUrl}/s/prechatVisitor?endpoint=${state.contentServerUrl}/s/chat?language=#deployment_id=${Config.SALESFORCE_CHAT_DEPLOYMENT_ID}&org_id=${Config.SALESFORCE_CHAT_ORG_ID}&button_id=${Config.SALESFORCE_CHAT_AVAILABLITY_ID}&session_id=${state.sessionId}`,
            body: Object.keys(state.prechatData)
              .map((key) => `${key}=${Array.isArray(state.prechatData[key]) ? JSON.stringify(state.prechatData[key]) : state.prechatData[key]}`)
              .join('&'),
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
          }}
          startInLoadingState={true}
          setSupportMultipleWindows={false}
          injectedJavaScriptBeforeContentLoaded={runBeforeFirst}
          injectedJavaScript={jsCode}
          scalesPageToFit={false}
          contentMode="mobile"
          onMessage={({ nativeEvent }: WebViewMessageEvent) => {
            setState((prev) => ({ ...prev, chatStatus: nativeEvent.data as ChatStatus }));
          }}
          onLoadEnd={() => {
            setState((prev) => ({ ...prev, loading: false, chatStatus: 'started' }));
          }}
        />
      ) : (
        <View style={styles.offlineContainer}>
          <Text testID="thankYouText" style={[appStyles.h1, { alignSelf: 'center', paddingBottom: 20 }]}>
            Thank You!
          </Text>
          <Text testID="sessionClosedText" style={[styles.offlineBodyText, { marginBottom: 50 }]}>
            The chat session has been closed. Thank you for contacting us.
          </Text>
          <Button
            onPress={() =>
              navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Account,
                params: {
                  screen: AccountStackRoutes.HelpCenter,
                  params: { screen: HelpCenterStackRoutes.HelpCenterScreen },
                },
              })
            }
            title={'Exit'}
            type={'secondary'}
            size="regular"
            testID={'cancel'}
          />
        </View>
      )}
    </Screen>
  );
};
const styles = StyleSheet.create({
  offlineContainer: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  offlineTitleText: {
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(20),
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  offlineBodyText: {
    marginTop: 10,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: FontFamily.Larsseit,
    lineHeight: lineHeight(20),
    paddingHorizontal: 20,
  },
  offlineLinkButtonStyle: {
    alignSelf: 'center',
    fontFamily: FontFamily.LarsseitMedium,
    lineHeight: lineHeight(20),
  },
  modalBodyContainer: { justifyContent: 'center', alignItems: 'center' },
});
export default LiveAgentChatScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address } from '@fieldera-raleys/client-common';
import { utilityStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Icon from './Icon';
import LinkButton from './LinkButton';

type AddressItemProps = {
  item: Address;
  onPress?: (item: Address) => void;
  onEdit?: (item: Address) => void;
  onRemove?: (item: Address) => void;
  isSelected?: boolean;
  index?: number;
};

const AddressItem = ({ item, isSelected = false, onPress, onEdit, onRemove, index }: AddressItemProps): JSX.Element => {
  return (
    <View style={styles.addressContainer}>
      <TouchableOpacity
        testID={`addressItem${index}`}
        activeOpacity={0.7}
        style={[styles.addressItem, utilityStyles.p2, isSelected ? { borderColor: 'red' } : {}]}
        onPress={() => onPress && onPress(item)}>
        <View style={styles.iconWrapper}>
          <Icon
            name={item.addressType === 'home' ? 'house-icon' : 'office-icon'}
            size={50}
            style={{ width: 50, height: 50 }}
            fill={colors.sectionBorder}
            stroke={'none'}
            testID={item.addressType === 'home' ? 'homeicon' : 'officeicon'}
          />
        </View>
        <View style={styles.addressInfo}>
          <Text numberOfLines={1} style={styles.addressText} testID="firstLastName">
            {`${item.firstName} ${item.lastName}`}
          </Text>
          {item.companyName !== undefined && item.companyName?.length > 0 && (
            <Text numberOfLines={1} style={styles.addressText} testID="companyName">
              {item.companyName}
            </Text>
          )}
          <Text numberOfLines={1} style={styles.addressText} testID="addressText">
            {`${item.address1}${item.address2 ? ', ' + item.address2 : ''}`}
          </Text>
          <Text numberOfLines={1} style={styles.addressText} testID="addressStateInfo">
            {`${item.city}, ${item.state} ${item.postalCode}`}
          </Text>
          <Text numberOfLines={1} style={[styles.addressText, { fontFamily: FontFamily.LarsseitLight }]} testID="phone">
            {item.phone}
          </Text>
        </View>
      </TouchableOpacity>
      <View style={[styles.addressOptionWrapper, utilityStyles.pt1]}>
        {!item.isDefaultBilling && !item.isDefaultShipping && (
          <LinkButton testID="remove" style={[styles.addressOptionText, utilityStyles.ml3]} onPress={() => onRemove && onRemove(item)}>
            Remove
          </LinkButton>
        )}

        <LinkButton testID="edit" style={[styles.addressOptionText, utilityStyles.ml3]} onPress={() => onEdit && onEdit(item)}>
          Edit
        </LinkButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  addressContainer: {
    width: '100%',
    minHeight: 100,
    marginBottom: 25,
    paddingHorizontal: 10,
  },
  iconWrapper: {
    alignItems: 'center',
    width: '30%',
  },
  addressItem: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'white',
    width: '100%',
    alignItems: 'center',
    borderColor: colors.sectionBorder,
    borderWidth: 1,
    borderRadius: 5,
  },
  addressInfo: {
    paddingVertical: 12,
    paddingTop: 16,
    paddingRight: 16,
    justifyContent: 'space-between',

    width: '70%',
  },
  addressText: {
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: lineHeight(15),
    fontFamily: FontFamily.LarsseitBold,
  },
  addressOptionWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  addressOptionText: {
    fontSize: scale(15),
    lineHeight: lineHeight(15),
    color: colors.darkText,
    fontFamily: FontFamily.Aleo,
  },
});
export default AddressItem;

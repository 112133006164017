import colors from '@config/colors';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type ListItemSeparatorProps = {
  separatorStyle?: StyleProp<ViewStyle>;
};

function ListItemSeparator({ separatorStyle = {} }: ListItemSeparatorProps) {
  return <View style={[styles.separator, separatorStyle]} />;
}

const styles = StyleSheet.create({
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: colors.light,
  },
});

export default ListItemSeparator;

import Text from '@components/Text';
import colors from '@config/colors';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { FontFamily } from '../../config/fonts';
import { useSearchStore } from '../../store';
import { lineHeight } from '../../styles/constants';
import BackButton from '../BackButton';
import Icon from '../Icon';

interface CategoryProps {
  isRootCategory: boolean;
  categoryName?: string;
  isCustomBack?: boolean;
  onFilterPress?: () => void;
}

const CategoryHeader = ({ categoryName, isRootCategory = false, isCustomBack = false, onFilterPress }: CategoryProps) => {
  const navigation = useNavigation();
  // const { showAlertModal } = useCustomModalContext();
  const { resetFilters, getSelectedFiltersCount } = useSearchStore();
  const filterBadgeCount = getSelectedFiltersCount();

  const handleBackButtonPress = () => {
    resetFilters();
    if (isCustomBack) {
      navigation.dispatch(StackActions.replace(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } })); // To handle navigation for Search Assist
    } else {
      let parent = navigation;
      while (parent.getState()?.index === 0 && parent.getParent()) {
        parent = parent.getParent();
      }
      parent?.goBack();
    }
  };

  const handleShowAllCategories = () => {
    navigation.navigate(AppStackRoutes.AccordionScreen, {
      title: 'All Categories',
    });
  };

  return (
    <>
      <View style={styles.container} testID="CategoryHeader">
        <View style={styles.leftContainer}>
          <BackButton style={{ ...appStyles.mediumIcon, paddingVertical: 2 }} size={20} onPress={handleBackButtonPress} />
          <Text style={styles.headerText} numberOfLines={2} ellipsizeMode={'tail'} testID="CategoryName">
            {categoryName}
          </Text>
        </View>
        {categoryName && (
          <View style={styles.rightContainer}>
            <TouchableOpacity onPress={handleShowAllCategories}>
              <Icon name="categories-icon" size={20} style={styles.icon} />
            </TouchableOpacity>
            {!isRootCategory ? (
              <TouchableOpacity onPress={onFilterPress}>
                <Icon name="sort-icon" size={28} style={styles.icon} />
                {filterBadgeCount ? (
                  <View style={[styles.badge]}>
                    <Text style={styles.badgeText}>{filterBadgeCount}</Text>
                  </View>
                ) : null}
              </TouchableOpacity>
            ) : null}
          </View>
        )}
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.cream,
  },
  leftContainer: {
    paddingLeft: 10,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    width: '75%',
    overflow: 'hidden',
  },
  headerText: {
    ...appStyles.h6,
    ...utilityStyles.pr3,
    ...utilityStyles.mr3,
    fontSize: scale(18),
    left: 5,
    lineHeight: scale(18),
  },
  rightContainer: {
    width: '25%',
    flexDirection: 'row-reverse',
    paddingHorizontal: 15,
    alignItems: 'center',
    alignContent: 'center',
  },
  icon: {
    ...appStyles.icon,
    marginHorizontal: 10,
  },
  badge: {
    borderRadius: 10,
    height: 12,
    width: 12,
    top: scale(0),
    right: scale(6),
    backgroundColor: colors.secondary,
    position: 'absolute',
  },
  badgeText: {
    fontSize: scale(10),
    lineHeight: lineHeight(10),
    color: colors.white,
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
  },
});
export default CategoryHeader;

import { DropShadow, NavigationHeader, Screen } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import Text from '@components/Text';
import Tombstone from '@components/Tombstone';
import OffersList from '@components/somethingExtra/OffersList';
import WeeklyExclusives from '@components/somethingExtra/WeeklyExclusives';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { RewardsStackRoutes, RewardsStackScreenProps } from '@navigation/routes';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { DigitalCouponsScreen } from '../shop';

type SEOffersScreenProps = RewardsStackScreenProps<RewardsStackRoutes.SEOffers>;
const SEOffersScreen = ({ route }: SEOffersScreenProps) => {
  const { currTab: initialTab = 'Personalized' } = route.params ?? { currTab: undefined };
  const [currTab, setCurrTab] = useState(initialTab);
  const tabs = [
    {
      name: 'Personalized',
      navLink: undefined,
    },
    {
      name: 'Weekly Exclusives',
      navLink: undefined,
    },
    {
      name: 'Digital Coupons',
      navLink: undefined,
    },
  ];

  useEffect(() => {
    setCurrTab(initialTab);
  }, [initialTab]);

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }} showShadow={false}>
        <HeaderTitle>Offers & Savings</HeaderTitle>
      </NavigationHeader>
      <View style={styles.container}>
        <DropShadow>
          <View style={styles.tabWrapper}>
            {tabs.map((t) => {
              return (
                <TouchableOpacity
                  key={t.name}
                  onPress={() => setCurrTab(t.name)}
                  style={[styles.tab, { borderBottomWidth: currTab === t.name ? 1 : 0, borderBottomColor: colors.red }]}>
                  <Text style={[styles.tabTitle, currTab === t.name ? appStyles.bodySmallRed : appStyles['bodySmall-50gray'], { fontSize: scale(14) }]}>
                    {t.name}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </DropShadow>
        {currTab === 'Personalized' && <OffersList />}
        {currTab === 'Weekly Exclusives' && <WeeklyExclusives />}
        {currTab === 'Digital Coupons' && <DigitalCouponsScreen />}
      </View>
      {currTab === 'Weekly Ad' && <Tombstone />}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 60,
  },
  noShadow: {
    shadowOpacity: 0,
  },
  tabWrapper: {
    marginBottom: 0,
    flexDirection: 'row',
    minWidth: '100%',
    minHeight: 32,
    maxHeight: 50,
    justifyContent: 'space-evenly',
    backgroundColor: colors.white,
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingHorizontal: 4,
    top: Platform.select({ android: scale(2.5) }),
  },
  tabTitle: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
  },
  scrollView: {
    flexGrow: 1,
  },
  toggleBar: {
    marginTop: 65,
    alignSelf: 'center',
    width: 240,
    height: 25,
    borderRadius: 5,
    backgroundColor: colors.darkCream,
    flexDirection: 'row',
    padding: 1,
  },
  toggleWrapper: {
    flexDirection: 'row',
  },
  toggle: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
});

export default SEOffersScreen;

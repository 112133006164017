import Text from '@components/Text';
import colors from '@config/colors';
import { Cart, Order, Product } from '@fieldera-raleys/client-commercetools/schema';
import { ProductType } from '@fieldera-raleys/client-common';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@styles/appStyles';
import { getAllowSubstitution, getSubstitutionItems, getSubstitutionType } from '@utils/orderHelpers';
import { getProductsfromCommerceTools } from '@utils/productHelper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, TouchableOpacity, TouchableOpacityProps, View } from 'react-native';
import LinkButton from '../LinkButton';

interface ItemSubstitutionProps extends TouchableOpacityProps {
  itemId: string;
  readOnly?: boolean;
  obj: Cart | Order;
}

const ItemSubstitution: React.FC<ItemSubstitutionProps> = ({ itemId, readOnly = true, obj }: ItemSubstitutionProps) => {
  const { t } = useTranslation('cart');
  const navigation = useNavigation();

  const procBackupClick = () => {
    const lineItem = (obj.lineItems ?? []).find((item) => item.id === itemId);
    const allowSub = getAllowSubstitution(obj) ?? true;
    if (lineItem && allowSub && lineItem && lineItem.productType?.name !== ProductType.CONFIGURABLE) {
      navigation.navigate(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.BackupScreen,
        params: { itemId: itemId },
      });
    }
  };

  const [subText, setSubText] = useState('');
  const [subDisabled, setSubDisabled] = useState(true);
  useEffect(() => {
    const li = (obj.lineItems ?? []).find((item) => item.id === itemId);
    if (li) {
      if (li.productType?.name === ProductType.CONFIGURABLE) {
        setSubText(t('subDontSubstitute'));
        setSubDisabled(true);
      } else {
        setSubDisabled(false);
        const allowSub = getAllowSubstitution(obj) ?? true;
        if (li) {
          const subType = getSubstitutionType(li);
          if (!allowSub || subType === 'None') {
            setSubText(t('subDontSubstitute'));
          } else if (subType === 'Best-Available') {
            setSubText(t('subBestAvailableItem'));
          } else {
            setSubText(t('subSpecificItem'));
            const items = getSubstitutionItems(li);
            if (items.length && items) {
              getProductsfromCommerceTools(items).then((pd) => {
                const attrs: { [key: string]: string } = {};
                pd.some((p: Product) => {
                  if (p.masterData.current?.masterVariant.attributesRaw ?? []) {
                    p.masterData.current?.masterVariant.attributesRaw.forEach((field) => {
                      attrs[field.name] = field.value;
                    });
                  }
                  p.masterData?.current?.variants.some((vi) => {
                    if ((li.variant?.sku ?? '') === vi.sku ?? '') {
                      (vi?.attributesRaw ?? []).forEach((field) => {
                        attrs[field.name] = field.value;
                      });

                      return true;
                    }
                    return false;
                  });
                  return false;
                });
                setSubText(attrs.name ?? '');
              });
            }
          }
        }
      }
    }
  }, [obj, itemId, t]);

  return readOnly ? (
    <View style={[styles.container]}>
      <Text style={[appStyles.fontMobileBodySmBoldGray, styles.subTitle]} testID="backupTextReadOnly">
        Backup:
      </Text>
      <Text style={[appStyles.fontMobileBodySmallLight, styles.subWidth]} numberOfLines={1} testID="substituionTypeReadOnly">
        {subText}
      </Text>
    </View>
  ) : (
    <TouchableOpacity onPress={() => {}} activeOpacity={1} style={styles.container}>
      {(getAllowSubstitution(obj) ?? true) && !subDisabled ? (
        <LinkButton onPress={procBackupClick} style={[appStyles.fontMobileBodySmBoldGray, styles.subTitle]} testID="backupLabel">
          {t('backupLabel')}
        </LinkButton>
      ) : (
        <Text style={[appStyles.fontMobileBodySmBoldGray, styles.subTitle, styles.subDisabled]} testID="backupText">
          Backup:
        </Text>
      )}
      {obj && subText ? (
        (getAllowSubstitution(obj) ?? true) && !subDisabled ? (
          <LinkButton onPress={procBackupClick} style={[appStyles.fontMobileBodySmallLight, styles.subWidth]} numberOfLines={1} testID="substituionTypeText">
            {subText}
          </LinkButton>
        ) : (
          <Text style={[appStyles.fontMobileBodySmallLight, styles.subWidth]} testID="backupText">
            {subText}
          </Text>
        )
      ) : (
        <ActivityIndicator size={'small'} color={colors.primary} />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  subWidth: {
    width: '70%',
    textDecorationLine: 'none',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  subTitle: {
    marginRight: 4,
  },
  subDisabled: {
    color: colors.darkCream,
  },
});

export { ItemSubstitution };

import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import React from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const withModalProvider = <P extends object>(Component: React.ComponentType<P>) =>
  class WithBottomModal extends React.Component<P> {
    render() {
      const { ...props } = this.props;
      return (
        <GestureHandlerRootView style={{ flex: 1 }}>
          <BottomSheetModalProvider>
            <Component {...(props as P)} />
          </BottomSheetModalProvider>
        </GestureHandlerRootView>
      );
    }
  };

export default withModalProvider;

import { BrProps } from '@bloomreach/react-sdk';
import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

const ComponentContainer: React.FC<PropsWithChildren<BrProps>> = ({ page, children }) => {
  return React.Children.toArray(children).length > 0 ? (
    <>
      {React.Children.map(children, (child) => (
        <View>{child}</View>
      ))}
    </>
  ) : page.isPreview() ? (
    <View style={styles.brxmContainer} />
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  brxmContainer: {
    alignItems: 'center',
    minHeight: 100,
    justifyContent: 'center',
    width: 100,
  },
});

export default ComponentContainer;

import Carousel from '@components/Carousel';
import Text from '@components/Text';
import OfferCard from '@components/somethingExtra/OfferCard';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { defaultMoney } from '@fieldera-raleys/client-commercetools/utils';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCartStore } from '@store';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { FC, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { moneyValue } from '../../utils/orderHelpers';

const containerLayout = { width: 106, imageWidth: 72, imageHeight: 72 };

export type ItemSavingsProps = {
  itemId?: string;
  callDismiss?: () => void;
};

const CARD_WIDTH = 168;
const SLIDE_GAP = 12;
const carouselConfig = {
  slidesPerPage: 1,
  nextSlidePartial: CARD_WIDTH * 0.44,
  slideWidth: CARD_WIDTH,
  sgPerLine: 1,
};

const ItemSavings: FC<ItemSavingsProps> = ({ itemId, callDismiss }): JSX.Element => {
  const { cart } = useCartStore();

  const showEligibleCalled = () => {
    if (callDismiss) {
      callDismiss();
    }
  };

  const [promotion, setPromotion] = useState<Promotion[]>([]);
  const [savings, setSavings] = useState<{ applied: number; available: number }>({ applied: 0, available: 0 });
  useEffect(() => {
    let pSet: Promotion[] = [];
    let cpSet: Promotion[] = [];
    if (cart) {
      const lineItem = cart.lineItems.find((li) => li.id === itemId);
      const cartPromos = JSON.parse(cart?.custom?.customFieldsRaw?.find((f) => f.name === 'promotions')?.value ?? '[]') as Promotion[];
      if (lineItem) {
        cpSet = cartPromos.filter(
          (p) =>
            (p.ExtPromotionTypeCode ?? '').toUpperCase() !== 'BCS' &&
            (p.ExtPromotionTypeCode ?? '').toUpperCase() !== 'DPT' &&
            (p.Rewards ?? []).some((o) => o.LineItemId === lineItem.id),
        );
        // pSet = acceptedSomethingExtraOffers
        //   .concat(availableSomethingExtraOffers)
        //   .concat(acceptedWeeklyExclusiveOffers)
        //   .concat(availableWeeklyExclusiveOffers)
        //   .concat(acceptedDigitalCoupons)
        //   .concat(availableDigitalCoupons)
        //   .concat(nonTargetedOffers)
        //   .filter((p) => !!cpSet.find((c) => c.ExtPromotionId === p.ExtPromotionId));
        pSet = cpSet; // testing reduced dependency on offerStore, the CartScreen is a parent for this and handles cart update for this to be triggered
      }
      setSavings(
        cpSet.reduce(
          (acc, p) => {
            acc.applied += p.AutoApply || p.IsAccepted ? p.Rewards?.reduce((sum, r) => (sum += r.RewardDiscount ?? 0), 0) ?? 0 : 0;
            acc.available += !(p.AutoApply || p.IsAccepted) ? p.AvailableSavings ?? 0 : 0;
            return acc;
          },
          { applied: 0, available: 0 } as { applied: number; available: number },
        ),
      );
    }
    setPromotion(pSet);
  }, [cart, itemId]);

  // console.log(JSON.stringify(promotion, null, 2));
  // console.log(cart?.id ?? '');
  return !promotion.length ? (
    <>
      <Text>No Promotions</Text>
    </>
  ) : (
    <>
      <View style={[styles.cartCarouselWrapper, styles.carousaelWrapperExtra]}>
        <View style={[styles.carouselMainView, utilityStyles.my2]}>
          {promotion.length ? (
            <Carousel
              windwoSize={promotion.length}
              snapToInterval={carouselConfig.slideWidth + SLIDE_GAP}
              contentContainerStyle={{
                ...styles.carouselContainer,
                width: (promotion.length ? promotion.length : 0) * (carouselConfig.slideWidth + SLIDE_GAP) + SLIDE_GAP,
              }}
              entities={promotion || []}
              renderItem={({ item: io, index }) => {
                return (
                  <View style={styles.carouselView}>
                    <OfferCard item={io} index={index} onShowEligibleItems={showEligibleCalled} />
                  </View>
                );
              }}
              pagerStyle={styles.pagerStyle}
              pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
              pagerInactiveItemStyle={appStyles.carouselDotsSmall}
              showPager={false}
              removeClippedSubviews={false}
            />
          ) : (
            <Text>...</Text>
          )}
        </View>

        <View style={styles.savingsParent}>
          {savings.available !== 0 && (
            <View key={'available-savings-row'} style={[styles.savingsBox, utilityStyles.mt3]}>
              <Text testID="applieditemSaving" style={appStyles.fontMobileBodySmallBald}>
                Available Item Savings*
              </Text>
              <Text testID="applieditemSaving" style={[appStyles.fontMobileBodySmallBald, { color: colors.alertBoxGreen, marginLeft: 'auto' }]}>
                {moneyValue({ ...defaultMoney, centAmount: savings.available * 100.0 })}
              </Text>
            </View>
          )}
          {savings.applied !== 0 && (
            <View key={'applied-savings-row'} style={[styles.savingsBox, utilityStyles.mt3]}>
              <Text testID="applieditemSaving" style={appStyles.fontMobileBodySmallBald}>
                Applied Item Savings*
              </Text>
              <Text testID="applieditemSaving" style={[appStyles.fontMobileBodySmallBald, { color: colors.alertBoxGreen, marginLeft: 'auto' }]}>
                {moneyValue({ ...defaultMoney, centAmount: savings.applied * 100.0 })}
              </Text>
            </View>
          )}
          <View style={[styles.savingsBox, styles.totalText]}>
            <Text testID="totalSavingText" style={[appStyles.fontMobileTinyLight, { textAlign: 'center' }]}>
              *Total savings may differ at checkout based on other discounts
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  separator: {
    height: 1,
    width: containerWidth,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  itemSeparator: {
    height: 0.5,
    width: containerWidth,
    backgroundColor: colors.productBorder,
    alignSelf: 'center',
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  categoryHeader: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.promoCream,
    paddingTop: 10,
  },
  subContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 22,
    paddingBottom: 22,
  },
  subContainerItemSubst: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.white,
    paddingTop: 22,
    paddingBottom: 22,
  },
  orderNoteContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  orderNoteSubContainer: {
    flexDirection: 'column',
    width: containerWidth,
    alignSelf: 'center',
  },
  orderNoteSubContent: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
  },
  captureView: { backgroundColor: colors.cream, opacity: 1 },
  leadWarningContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
  },
  leadWarningContainerText: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  noteContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 15,
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    paddingBottom: 5,
  },
  flex1: { flex: 1 },
  flexrow: { flexDirection: 'row' },
  flexcol: { flexDirection: 'column' },
  itemsHeader: {
    backgroundColor: colors.sectionPad,
    paddingBottom: 2,
    paddingTop: 6,
  },
  itemLeftHeader: {
    minWidth: containerLayout.width,
    width: containerLayout.width,
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  empty: {
    width: containerWidth,
    textAlign: 'center',
    alignSelf: 'center',
  },
  flatPadContainer: {
    backgroundColor: colors.white,
    width: screenWidth,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  flatPad: {
    backgroundColor: colors.white,
    width: containerWidth,
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: scale(12),
    marginTop: scale(12),
    minHeight: scale(60),
  },

  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
  },
  itemLeft: {
    minWidth: containerLayout.width,
    width: containerLayout.width,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flex: 0,
    justifyContent: 'space-between',
  },
  itemLeft1: {
    alignSelf: 'stretch',
    minWidth: 40,
    paddingRight: 5,
    flex: 1,
    alignItems: 'center',
  },
  itemLeft2: {
    width: containerLayout.imageWidth,
    minWidth: containerLayout.imageWidth,
  },
  itemLeftCart: {
    minWidth: containerLayout.imageWidth,
    width: containerLayout.imageWidth,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    flex: 0,
    alignItems: 'flex-start',
  },
  headerQty: {
    minWidth: containerLayout.width,
    width: containerLayout.width,
    textAlign: 'left',
  },
  headerQtyCart: {
    minWidth: containerLayout.width,
    width: containerLayout.width,
    textAlign: 'left',
  },
  itemQuantity: {
    alignSelf: 'center',
    marginTop: 10,
    width: containerLayout.imageWidth,
    height: 32,
    borderColor: colors.productBorder,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 1.5 },
    shadowOpacity: 0.2,
    shadowRadius: 1.5,
  },
  actionLinksBottom: {},
  actionLinksBottomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  actionLinksLeft: {
    textAlign: 'center',
    marginTop: 3,
    marginBottom: 3,
  },
  carouselContainer: {
    borderWidth: 0,
    overflow: 'visible',
    marginRight: 0,
  },
  actionLinksLeftContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 0,
  },
  productImage: {
    width: containerLayout.imageWidth,
    alignSelf: 'center',
    height: containerLayout.imageHeight,
    borderWidth: 1,
    borderColor: colors.productBorder,
  },
  itemRightRO: {
    flex: 1,
    flexDirection: 'column',
  },
  itemRight: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 8,
  },
  priceContainer: {
    flexDirection: 'row',
  },
  customizationsContainer: {
    paddingTop: 8,
    flexDirection: 'column',
  },
  errorMessage: {
    flexDirection: 'row',
    textAlign: 'left',
  },
  subseparator: {
    height: 0.33,
    width: '100%',
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.cream,
  },
  subtotal: {
    textAlign: 'right',
    flex: 0.29,
    alignSelf: 'flex-end',
  },
  approxSubtotal: {
    marginTop: scale(-6),
    marginBottom: scale(2),
    textAlign: 'right',
    flex: 0.29,
    marginLeft: 'auto',
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    alignSelf: 'flex-end',
  },
  subRegprice: {
    marginTop: -4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  subprice: {
    marginTop: 4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  newPrice: {
    color: colors.red,
  },
  unitHeader: {
    flex: 0.55,
    marginRight: 1,
  },
  unit: {
    flex: 0.55,
    marginRight: 1,
    alignSelf: 'flex-end',
  },
  unitSeparator: {
    width: 0.03,
    height: '100%',
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.darkCream,
    alignSelf: 'flex-end',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  cartEmpty: {
    marginBottom: 25,
  },
  swipedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.sectionHeader,
  },
  deleteButton: {
    backgroundColor: colors.red,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: 20,
    marginLeft: 'auto',
  },
  menuOption: {
    padding: 0,
    margin: 0,
    height: 38,
  },
  menuOptions: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 200,
    height: 86,
    backgroundColor: colors.white,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    borderColor: colors.darkCream,
    borderWidth: 1,
    borderRadius: 8,
  },
  menuOptionText: {
    paddingLeft: 14,
    backgroundColor: colors.white,
    lineHeight: scale(38),
    textAlignVertical: 'top',
  },
  menuOptionWrapper: {
    backgroundColor: colors.sectionBorder,
    color: 'white',
  },
  menuOptionsTouchable: {
    activeOpacity: 0.75,
    textAlign: 'center',
  },
  accessory: {
    width: containerWidth,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    paddingHorizontal: 8,
  },
  alertBox: {
    bottom:
      screenHeight < 736
        ? screenHeight * 0.225
        : screenHeight > 812
        ? screenHeight > 896
          ? screenHeight * 0.21
          : screenHeight * 0.215
        : screenHeight < 740
        ? screenHeight * 0.225
        : screenHeight * 0.225,
  },
  carouselWrapper: {
    width: screenWidth,
    alignSelf: 'center',
    backgroundColor: colors.cream,
  },
  carouselView: {
    marginBottom: 16,
    marginLeft: SLIDE_GAP,
  },
  cartCarouselWrapper: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: colors.promoCream,
  },
  pagerStyle: {
    marginTop: 20,
    marginBottom: 10,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  savingsParent: {
    borderTopWidth: 1,
    borderTopColor: colors.sectionBorder,
    backgroundColor: colors.cream,
    width: screenWidth,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    borderBottomEndRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomWidth: 0,
  },
  savingsBox: {
    width: containerWidth,
    backgroundColor: colors.cream,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  averageStyle: {
    marginTop: 2,
  },
  savingsMain: {
    width: containerWidth,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: colors.cream,
    shadowOpacity: 0,
    paddingHorizontal: 10,
  },
  totalText: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: 6,
    width: screenWidth * 0.9,
  },
  carousaelWrapperExtra: {
    flexDirection: 'column',
    borderBottomEndRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  carouselMainView: {
    width: screenWidth,
    backgroundColor: colors.promoCream,
  },
});

export default ItemSavings;

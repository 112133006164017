import { useTheme } from '@react-navigation/native';
import { appStyles } from '@styles';
import React from 'react';
import { Platform, StatusBar, View, ViewProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface ScreenProps extends ViewProps {
  headerVisible?: boolean;
}
const Screen: React.FC<ScreenProps> = ({ headerVisible = false, children, style }) => {
  const insets = useSafeAreaInsets();
  const paddingTop = 16;
  const { colors } = useTheme();
  var margins = !headerVisible
    ? { paddingTop: Platform.OS === 'web' ? paddingTop : insets.top, paddingLeft: insets.left, paddingRight: insets.right, paddingBottom: insets.bottom }
    : { paddingTop: Platform.OS === 'web' ? paddingTop : 0 };
  return (
    <View
      style={[
        appStyles.screen,
        {
          backgroundColor: colors.background,
          ...margins,
        },
        style,
      ]}>
      <StatusBar animated={true} backgroundColor={colors.background} barStyle={'dark-content'} showHideTransition={'slide'} />

      {children}
    </View>
  );
};
export default Screen;

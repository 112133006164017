import { BottomBar, Button, Icon, QuantityBox } from '@components';
import CustomizeCartInfo from '@components/CustomizeCartInfo';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AddLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { ShopType, Store } from '@fieldera-raleys/client-common';
import { useAnalytics } from '@hooks';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, CustomizeStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/core';
import { CommonActions } from '@react-navigation/native';
import { useListsStore } from '@store';
import { useCartStore } from '@store/cartStore';
import useCustomizeStore from '@store/customizeStore';
import useShopStore from '@store/shopStore';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Dimensions, LayoutChangeEvent, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomModalContext } from '../../contexts';
import { ChosenItem } from '../../models/customize';
import { hapticNotify } from '../../utils/helpers';
import logger from '../../utils/logger';

const { height } = Dimensions.get('window');

interface IBasicSectionProps {
  items: string;
  details?: string;
  cost: string | number | undefined;
  addition?: boolean;
}

interface IIngredientProps {
  ingredient: any;
  details?: string;
  cost?: string;
}

interface ITotalsSectionProps {
  total: number;
  selectedLocation?: Store;
  itemQuantity: number;
}

//Standard section with item, details, cost...(platter, size, container sections)
const BasicSection: FC<IBasicSectionProps> = ({ items, details, cost, addition }): JSX.Element => {
  return (
    <View style={styles.basicSection}>
      <Text testID="itemsText" style={styles.itemsText}>
        {items}
      </Text>
      <Text testID="itemDetails" style={[styles.detailsText, { marginTop: -2 }]}>
        {details}
      </Text>
      {cost === 'included' || cost === 0 ? (
        <Text testID="included" style={styles.thinText}>
          Included
        </Text>
      ) : (
        <Text testID="cost" style={styles.costText}>
          {addition ? '+ ' : null}${cost}
        </Text>
      )}
    </View>
  );
};

//Ingredient list items
const Ingredient: FC<IIngredientProps> = ({ ingredient }): JSX.Element => {
  var title = ingredient.ingredientName;
  var chosenItems: any = ingredient.selections;

  return (
    <View style={[styles.ingredientWrapper, title === 'Filling' && { marginTop: 0 }]}>
      <Text testID="title" style={[styles.ingredientText, title === 'Filling' && styles.itemsText]}>
        {title}
      </Text>
      <View style={styles.detailsText}>
        {chosenItems.map((item: ChosenItem, idx: number) => {
          return (
            <Text testID={`itemDetails-${idx}`} key={item.itemName} style={[styles.detailsText, styles.bottomMargin]}>
              {item.itemName} {item.description && item.description !== 1 ? `x${item.description}` : null}
            </Text>
          );
        })}
      </View>
      <View style={styles.thinText}>
        {chosenItems.map((i: ChosenItem, idx: number) => {
          return (
            <View key={i.itemName} style={styles.thinText}>
              {i.cost === 0 || !i.cost ? (
                <Text testID={`itemName-${idx}`} key={i.itemName} style={[styles.thinText, styles.bottomMargin, { marginTop: 5 }]}>
                  Included
                </Text>
              ) : (
                <Text testID={`itemDescription-${idx}`} key={i.itemName} style={[styles.costText, styles.bottomMargin, { marginTop: 5 }]}>
                  {i.description ? `+ $${+parseFloat(i.cost.toString()) * +parseFloat(i.description.toString())}` : `+ $${+parseFloat(i.cost.toString())}`}
                </Text>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

const TotalsSection: FC<ITotalsSectionProps> = ({ total, itemQuantity = 1 }): JSX.Element => {
  return (
    <View style={styles.totalCostSection}>
      <View style={styles.totalWrapper}>
        <Text testID="itemPrice" style={[styles.itemsText, { flex: 1 }]}>
          Item Price
        </Text>
        <Text testID="total" style={styles.totalsText}>
          ${total.toFixed(2)}
        </Text>
      </View>
      <View style={styles.totalWrapper}>
        {/* <Text style={styles.grandTotalText}>{selectedLocation.taxRate ? `Tax (${selectedLocation.taxRate}%)` : 'Tax (--)'}</Text> */}
        {/* <Text style={styles.totalsText}>{selectedLocation.taxRate ? `$${taxAmount.toFixed(2)}` : 'TBD'}</Text> */}
      </View>
      <View style={styles.grandTotalWrapper}>
        <Text testID="subtotal" style={styles.grandTotalText}>
          Subtotal
        </Text>
        <Text testID="grandTotal" style={styles.grandTotal}>
          ${(total * itemQuantity).toFixed(2)}
        </Text>
      </View>
    </View>
  );
};

const ReviewScreen: FC<{}> = (): JSX.Element => {
  const {
    prevPageData,
    itemId,
    item,
    setItem,
    steps,
    setSteps,
    currStep,
    setCurrStep,
    subtotal,
    setSubtotal,
    selectedSize,
    setSelectedSize,
    selectedContainer,
    setSelectedContainer,
    selectedIngredients,
    setSelectedIngredients,
    notes,
    setNotes,
    inCart,
    setInCart,
    cakeMessage,
    setEditCartId,
    editCartId,
    editSavedForLaterId,
    setEditSavedForLaterId,
  } = useCustomizeStore();
  const listStore = useListsStore();
  const { selectedStore: selectedLocation, selectedShopType } = useShopStore();
  const cartStore = useCartStore();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [itemQty, setItemQty] = useState(1);
  const [lineItemId, setLineItemId] = useState<string>();
  const handleError = useErrorHandler();
  const { trackAddToCartEvent } = useAnalytics();
  var isCake = item.toLowerCase().includes('cake') || item.toLowerCase().includes('donut');
  const { showAlertModal, showModal } = useCustomModalContext();
  // useEffect(() => {
  //   console.log(JSON.stringify(selectedSize, null, 3));
  //   console.log(JSON.stringify(selectedIngredients, null, 3));
  //   console.log(JSON.stringify(cakeMessage, null, 3));
  //   console.log(JSON.stringify(selectedContainer, null, 3));
  // }, []);

  const insets = useSafeAreaInsets();
  const [contentBottom, setContentBottom] = useState<{ paddingBottom: number }>({ paddingBottom: insets.bottom });

  const onButtonLayout = (le: LayoutChangeEvent) => {
    setContentBottom({ paddingBottom: le.nativeEvent.layout.height });
  };

  const goBack = () => {
    if (currStep === 1) {
      return;
    }
    var prevPage = steps[currStep - 2];
    setCurrStep(currStep - 1);

    if (
      prevPage.pageItem?.toLowerCase().includes('cake flavor') ||
      prevPage.pageItem?.toLowerCase().includes('icing') ||
      prevPage.pageItem?.toLowerCase().includes('decoration') ||
      prevPage.pageItem?.toLowerCase().includes('border')
    ) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.BasicSelection,
        params: prevPage,
        key: prevPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (prevPage.pageItem?.includes('Theme')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Theme,
        params: prevPage,
        key: prevPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (prevPage.pageItem?.includes('Filling')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Filling,
        params: prevPage,
        key: prevPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (prevPage.selectionOption) {
      // navigation.navigate('Selection', prevPage);
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Selection,
        params: prevPage,
        key: prevPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (prevPage.pageItem) {
      const navAction = CommonActions.navigate({
        name: prevPage.pageItem,
        params: prevPage,
      });
      navigation.dispatch(navAction);
    }
  };

  const closeCustomize = () => {
    setSelectedSize({ itemName: '', cost: '' });
    setSelectedContainer({ itemName: '', cost: '' });
    setSelectedIngredients([]);
    setNotes('');
    setSubtotal(0);
    setItem('');
    setCurrStep(1);
    setSteps([]);
    setInCart(false);
    if (prevPageData.parentId) {
      try {
        if (prevPageData.parentId === 'favoriteList') {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Account,
            params: { screen: AccountStackRoutes.CustomizeNeeded, params: prevPageData.params },
          });
          return;
        }
        if (prevPageData.parentId === 'searchLanding') {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Shop,
            params: {
              screen: ShopStackRoutes.SearchLanding,
              params: { query: prevPageData.query ?? '', prevRoute: RootTabRoutes.Home },
            },
          });
          return;
        }
        if (prevPageData.parentId === 'cart') {
          navigation.navigate(AppStackRoutes.CartAndCheckout, {
            screen: CheckoutStackRoutes.CartLanding,
            params: { storeNumber: undefined },
          });
          return;
        }
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: { screen: ShopStackRoutes.ProductList, params: { rootCategoryId: prevPageData.parentId, categoryId: prevPageData.id } },
        });
        return;
      } catch (err) {
        logger.error('Customize close error');
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
        return;
      }
    }

    if (!itemId) {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
      return;
    }

    navigation.navigate(AppStackRoutes.ProductDetails, { productKey: itemId });
  };

  const closeAlert = () => {
    //Need productKey for navigation back to page
    //Users should have the option to save customized settings which will save to localstorage
    if (inCart) {
      closeCustomize();
      return;
    }

    showModal({
      title: 'Unsaved Changes',
      children: <Text style={appStyles.bodyLargeCenter}>Would you like to save your current selections?</Text>,
      cancelButtonOnPress: handleSave,
      cancelButtonText: 'Save',
      okButtonOnPress: closeCustomize,
      okButtonText: 'No Thanks',
      location: 'top',
      showCloseIcon: false,
    });
  };

  const handleSave = () => {
    setCurrStep(1);
    setSteps([]);
    if (prevPageData.parentId === 'favoriteList') {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: { screen: AccountStackRoutes.CustomizeNeeded, params: prevPageData.params },
      });
      return;
    }
    navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
  };

  const failAlert = () => {
    showAlertModal({ title: 'Failed to Add', message: 'We could not add the item to your cart. Please try again.' });
    setLoading(false);
  };

  const addToCart = async () => {
    setLoading(true);
    setInCart(true);
    // logger.log(JSON.stringify(selectedIngredients, null, 2));
    if (selectedSize?.sku) {
      const productKeys: AddLineItem[] = [];
      //TODO: Track QTY if editing...
      var qty;
      if (editCartId) {
        qty = await cartStore.getLineItemQuantity({ lineItemId: editCartId });
      } else if (editSavedForLaterId) {
        qty = listStore?.lists?.find((l) => l.name === 'Saved For Later')?.lineItems.find((li) => li.id === editSavedForLaterId)?.quantity ?? 1;
      }
      productKeys.push({ sku: selectedSize.sku, quantity: qty || 1, itemNote: notes });
      (selectedIngredients ?? []).map((ingredient) =>
        (ingredient?.selections ?? []).forEach((select: any) =>
          productKeys.push({
            sku: select.sku,
            quantity: isNaN(select?.description) ? 1 : select?.description ?? 1,
            parentLineItemId: selectedSize.sku,
            customStepSort: String(ingredient.sortOrderHint) ?? 0,
          }),
        ),
      );
      selectedContainer?.sku &&
        productKeys.push({
          sku: selectedContainer?.sku,
          quantity: 1,
          parentLineItemId: selectedSize.sku,
        });
      cakeMessage?.sku &&
        productKeys.push({
          sku: cakeMessage?.sku.toString(),
          quantity: 1,
          parentLineItemId: selectedSize.sku,
          itemNote: cakeMessage?.message,
          customStepSort: String(cakeMessage?.sortOrderHint ?? steps.length - 2),
        });

      var result;
      if (editCartId) {
        try {
          await cartStore.removeLineItem(editCartId);
          result = await cartStore.addCustomizableLineItem(productKeys);
          hapticNotify('notificationSuccess');
        } catch (err) {
          hapticNotify('notificationError');
          setInCart(false);
          setEditCartId('');
          setEditSavedForLaterId('');
          setLoading(false);
          handleError(err);
          // failAlert();
          return;
        }
      } else if (editSavedForLaterId) {
        try {
          await listStore.removeItemFromList(editSavedForLaterId, 'Saved For Later');
          result = await listStore.addCustomizableListLineItem(productKeys, 'Saved For Later');
          hapticNotify('notificationSuccess');
        } catch (err) {
          hapticNotify('notificationError');
          setInCart(false);
          setEditCartId('');
          setEditSavedForLaterId('');
          setLoading(false);
          handleError(err);
          // failAlert();
          return;
        }
      } else {
        // console.log(JSON.stringify(productKeys, null, 3));
        await cartStore
          .addCustomizableLineItem(productKeys)
          .then((res) => {
            result = res;
            hapticNotify('notificationSuccess');
          })
          .catch((err) => {
            hapticNotify('notificationError');
            setInCart(false);
            setEditCartId('');
            setLoading(false);
            handleError(err);
            // failAlert();
            return;
          });
        // result = await cartStore.addCustomizableLineItem(productKeys).catch(() => {}, handleError);
      }
      if (!result) {
        setInCart(false);
        setEditCartId('');
        failAlert();
        setLoading(false);
        return;
      }
      trackAddToCartEvent({ sku: selectedSize?.sku, prod_name: item, prod_id: itemId });
      setEditCartId('');
      setLineItemId(result);
      setInCart(true);
    }
    setLoading(false);
  };

  const handleQuantityChange = _.debounce(
    async (value: number) => {
      if (value === 0 && lineItemId) {
        cartStore.removeLineItem(lineItemId);
        setInCart(false);
        setItemQty(1);
      } else {
        cartStore.setLineItemQuantity({ lineItemId: lineItemId }, value);
        setItemQty(value);
      }
    },
    1000,
    { trailing: true },
  );

  const quantity = cartStore.getLineItemQuantity({ lineItemId: lineItemId });
  useEffect(() => {
    if (quantity === 0 || !quantity) {
      setInCart(false);
    }
  }, [quantity, setInCart]);

  return (
    <>
      <View style={[styles.container, contentBottom]}>
        <ScrollView bounces={false} style={[styles.body]}>
          <View style={styles.bodySection}>
            <Text style={styles.itemNameText}>Review Order Details</Text>
            <View style={styles.sectionHeaders}>
              <Text testID="items" style={[styles.headerText, { flex: 0.28 }]}>
                ITEMS
              </Text>
              <Text testID="details" style={[styles.headerText, { flex: 0.52 }]}>
                DETAILS
              </Text>
              <Text testID="cost" style={[styles.headerText, { flex: 0.2 }, { textAlign: 'center' }]}>
                COST
              </Text>
            </View>
            {/* {!isCake && <BasicSection items="Platter" cost={selectedSize?.cost} />} */}
            {selectedSize?.itemName && selectedSize?.itemName !== item ? <BasicSection items="Size" details={selectedSize?.itemName} cost="included" /> : null}
            {isCake && (
              <>
                {selectedIngredients?.some((ing) => ing.ingredientName.toLowerCase().includes('theme')) && (
                  <BasicSection
                    items="Theme"
                    details={selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('theme'))?.selections[0]?.itemName}
                    cost="included"
                  />
                )}
                {selectedIngredients?.some((ing) => ing.ingredientName.toLowerCase().includes('flavor')) && (
                  <BasicSection
                    items="Flavor"
                    details={selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('flavor'))?.selections[0]?.itemName}
                    cost="included"
                  />
                )}
                {selectedIngredients?.some((ing) => ing.ingredientName.toLowerCase().includes('filling')) && (
                  <View style={styles.ingredientSection}>
                    {/* <Text style={styles.itemsText}>Filling</Text> */}
                    <Ingredient ingredient={selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('filling'))} />
                  </View>
                )}
                {selectedIngredients?.some((ing) => ing.ingredientName.toLowerCase().includes('icing')) && (
                  <BasicSection
                    items="Icing"
                    details={selectedIngredients
                      ?.find((ing) => ing.ingredientName.toLowerCase().includes('icing'))
                      ?.selections[0]?.itemName.concat(
                        selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('icing'))?.selections[0]?.color &&
                          selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('icing'))?.selections[0]?.color !==
                            selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('icing'))?.selections[0]?.itemName
                          ? ` - ${selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('icing'))?.selections[0]?.color}`
                          : '',
                      )}
                    cost="included"
                  />
                )}
                {selectedIngredients?.some((ing) => ing.ingredientName.toLowerCase().includes('decoration')) && (
                  <BasicSection
                    items="Decor"
                    details={selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('decoration'))?.selections[0]?.itemName}
                    cost="included"
                  />
                )}
                {selectedIngredients?.some((ing) => ing.ingredientName.toLowerCase().includes('border')) && (
                  <BasicSection
                    items="Border"
                    details={selectedIngredients
                      ?.find((ing) => ing.ingredientName.toLowerCase().includes('border'))
                      ?.selections[0]?.itemName.concat(
                        selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('border'))?.selections[0]?.color &&
                          selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('border'))?.selections[0]?.color !==
                            selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('border'))?.selections[0]?.itemName
                          ? ` - ${selectedIngredients?.find((ing) => ing.ingredientName.toLowerCase().includes('border'))?.selections[0]?.color}`
                          : '',
                      )}
                    cost="included"
                  />
                )}
                {steps.some((step) => step.pageItem?.toLowerCase().includes('message')) ? (
                  <View style={styles.noteSection}>
                    <Text testID="message" style={styles.itemsText}>
                      Message
                    </Text>
                    <View style={styles.noteText}>
                      <Text testID="cakeMessage" style={styles.noteText}>
                        {cakeMessage?.message || 'No Message'}
                      </Text>
                      <Text testID="cakeMessageText" style={[styles.noteText, { fontFamily: FontFamily.LarsseitBold, marginTop: 10 }]}>
                        {cakeMessage?.color?.replace(cakeMessage?.color[0], cakeMessage?.color[0]?.toUpperCase())}
                      </Text>
                    </View>
                    <TouchableOpacity onPress={goBack}>
                      <Text testID="editNotesButton" style={[styles.editNotesButton, !cakeMessage?.message ? { marginTop: 0 } : null]}>
                        Edit
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
              </>
            )}
            {!isCake && (
              <>
                <View style={styles.ingredientSection}>
                  <Text testID="ingredients" style={styles.itemsText}>
                    Ingredients
                  </Text>
                  {selectedIngredients?.map((ingredient, index) => {
                    return <Ingredient ingredient={ingredient} key={index.toString()} />;
                  })}
                </View>
                {selectedContainer?.id && (
                  <BasicSection items="Container" details={selectedContainer?.itemName} cost={selectedContainer?.cost} addition={true} />
                )}
                <View style={styles.noteSection}>
                  <Text testID="itemNotes" style={styles.itemsText}>
                    Item Notes
                  </Text>
                  <View style={styles.noteText}>
                    <Text testID="notesText" style={styles.noteText}>
                      {notes}
                    </Text>
                  </View>
                  <TouchableOpacity onPress={goBack}>
                    <Text testID="editButton" style={styles.editNotesButton}>
                      Edit
                    </Text>
                  </TouchableOpacity>
                </View>
              </>
            )}
            <TotalsSection total={subtotal} selectedLocation={selectedLocation} itemQuantity={itemQty} />
          </View>
          <View style={styles.footerWrapper}>
            {!inCart ? (
              <TouchableOpacity onPress={goBack}>
                <Text style={styles.backTextWrapper}>
                  <View style={styles.iconWrapper}>
                    <Icon testID="arrowLeftIcon" name="arrow-left" stroke={colors.darkText} size={20} />
                  </View>{' '}
                  <Text testID="backText" style={styles.backText}>
                    Back
                  </Text>
                </Text>
              </TouchableOpacity>
            ) : (
              <Button testID="doneButton" disabled={loading} title={'Done'} onPress={closeAlert} size={'small'} buttonStyle={styles.doneButton} />
            )}
            {/* {inCart ? <Button title={'Done'} onPress={closeAlert} size={'small'} buttonStyle={styles.doneButton} /> : null} */}
          </View>
        </ScrollView>
      </View>
      <BottomBar onLayout={onButtonLayout}>
        <View style={[utilityStyles.mt3, styles.actionBar]}>
          {selectedShopType === ShopType.IN_STORE ? (
            <View style={styles.actionWrapper}>
              <Button
                testID="addToListButton"
                title={'Add to List'}
                onPress={() => showAlertModal()}
                isButtonLoading={loading}
                buttonStyle={styles.addToCartButton}
              />
            </View>
          ) : (
            <View style={styles.actionWrapper}>
              {inCart && !loading && !editSavedForLaterId ? (
                <>
                  <QuantityBox
                    onChange={handleQuantityChange}
                    initialValue={quantity}
                    showCartText
                    borderStyle={styles.quantityBox}
                    containerStyle={styles.quantityBoxWrapper}
                    incrementBy={1}
                    maxLimit={cartStore.getMaxProductQuantity(selectedContainer?.sku)}
                  />
                </>
              ) : (
                <Button
                  testID="addToCartButton"
                  disabled={editSavedForLaterId && inCart ? true : false}
                  title={editCartId ? 'Update Cart' : editSavedForLaterId ? 'Save for Later' : 'Add to Cart'}
                  onPress={addToCart}
                  isButtonLoading={loading}
                  buttonStyle={styles.addToCartButton}
                />
              )}
              {inCart && !loading && selectedSize && selectedSize.sku ? (
                <CustomizeCartInfo isButton={true} boxStyle={[styles.customizeCartQtyBox]} productKey={itemId} />
              ) : null}
            </View>
          )}
        </View>
      </BottomBar>
    </>
  );
};

const styles = StyleSheet.create({
  customizeCartQtyBox: {
    marginBottom: 0,
    marginTop: 10,
    width: '73%',
  },
  actionBar: {
    margin: 0,
    paddingBottom: 16,
  },
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    padding: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
    paddingBottom: 0,
    marginBottom: -16,
  },
  body: {
    height: '85%',
    width: screenWidth,
    paddingHorizontal: 20,
    paddingTop: Platform.OS === 'ios' ? (height > 740 ? height * 0.025 : height * 0.025) : height > 640 ? height * 0.025 : height * 0.025,
    marginBottom: 16,
  },
  bodySection: {
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingTop: 24,
    paddingBottom: 15,
    // overflow: 'scroll',
  },
  orderDetailsText: {
    fontSize: scale(16),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 25,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  itemNameText: {
    fontSize: scale(20),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 32,
  },
  sectionHeaders: {
    flexDirection: 'row',
    width: '100%',
  },
  headerText: {
    fontSize: scale(16),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  basicSection: {
    flexDirection: 'row',
    width: '100%',
    borderTopWidth: 1,
    borderColor: colors.lineSeparator,
    marginTop: 15,
    paddingTop: 20,
  },
  itemsText: {
    flex: 0.3,
    fontFamily: 'Aleo-Bold',
    fontSize: scale(14),
    color: colors.darkText,
  },
  detailsText: {
    flex: 0.5,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(16),
    color: colors.darkText,
    paddingRight: 15,
    // transform: [{ translateY: 2 }],
  },
  thinText: {
    flex: 0.2,
    textAlign: 'center',
    fontFamily: 'Larsseit-Light',
    fontSize: scale(14),
    color: colors.darkText,
    lineHeight: lineHeight(14),
  },
  costText: {
    flex: 0.2,
    textAlign: 'center',
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(14),
    color: colors.darkText,
    lineHeight: lineHeight(14),
  },
  ingredientSection: {
    width: '100%',
    borderTopWidth: 1,
    borderColor: colors.lineSeparator,
    marginTop: 15,
    paddingTop: 20,
  },
  ingredientWrapper: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 15,
  },
  ingredientText: {
    flex: 0.3,
    fontSize: scale(16),
    color: colors.darkText,
    fontFamily: 'Larsseit-Light',
  },
  noteSection: {
    flexDirection: 'row',
    width: '100%',
    borderTopWidth: 1,
    borderColor: colors.lineSeparator,
    marginTop: 15,
    paddingTop: 20,
  },
  noteText: {
    flex: 0.6,
    fontFamily: 'Larsseit-Light',
    color: colors.darkText,
    fontSize: scale(16),
    lineHeight: 20,
  },
  editNotesButton: {
    marginTop: 0,
    marginRight: 0,
    textDecorationLine: 'underline',
    fontFamily: FontFamily.AleoBold,
    color: colors.darkText,
    position: 'absolute',
    top: 0,
    right: -28,
  },
  totalCostSection: {
    // flexDirection: 'row',
    width: '100%',
    borderTopWidth: 1,
    borderColor: colors.lineSeparator,
    marginTop: 15,
    paddingTop: 20,
  },
  totalWrapper: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  totalsText: {
    flex: 0.9,
    textAlign: 'right',
    fontSize: scale(16),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    lineHeight: lineHeight(16),
  },
  grandTotalWrapper: {
    flexDirection: 'row',
    marginVertical: 25,
  },
  grandTotalText: {
    flex: 0.4,
    fontSize: scale(14),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
  },
  grandTotal: {
    flex: 0.6,
    textAlign: 'right',
    transform: [{ translateY: -10 }],
    color: colors.saleRed,
    fontWeight: '700',
    fontSize: scale(30),
    fontFamily: 'Larsseit-Bold',
    lineHeight: lineHeight(30),
  },
  quantityBoxWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: 205,
    // minWidth: 205,
    // maxWidth: 350,
    height: 56,
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: 0,
    marginBottom: 0,
    borderRadius: 100,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 2.22,
    shadowOpacity: Platform.select({ android: 0.22, ios: 0.22 }),
    elevation: Platform.select({ android: 2, ios: 3 }),
  },
  quantityBox: {
    height: '100%',
    top: 0,
  },
  addToCartButton: {
    marginVertical: 0,
  },
  actionWrapper: {
    width: '100%',
    alignItems: 'center',
  },
  footerWrapper: {
    height: height * 0.1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: 50,
  },
  backTextWrapper: {
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  iconWrapper: {
    transform: [{ translateY: 5 }],
  },
  backText: {
    textDecorationLine: 'underline',
    fontSize: scale(14),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
  },
  doneButton: {
    transform: [{ translateY: 3 }],
  },
  bottomMargin: {
    marginBottom: screenHeight * 0.015,
  },
});

export default ReviewScreen;

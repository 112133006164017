import Text from '@components/Text';
import colors from '@config/colors';
import { appStyles } from '@styles';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon from './Icon';

export interface RadioButtonProps {
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  value?: boolean;
  id?: string | number | undefined;
  toggle?: (id: any) => void | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  buttonSize?: number;
  buttonStyle?: StyleProp<ViewStyle>;
  showLastDivider?: boolean;
  strokeColor?: string;
  fillColor?: string;
  secondaryStrokeColor?: string;
  disabled?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({
  containerStyle,
  text,
  textStyle,
  value,
  id,
  toggle,
  buttonStyle,
  buttonSize,
  showLastDivider,
  strokeColor = 'none',
  fillColor = colors.secondary,
  secondaryStrokeColor = colors.white,
  disabled = false,
}): JSX.Element => {
  return (
    <View style={[styles.container, containerStyle, { borderBottomWidth: showLastDivider ? 1 : 0 }]}>
      <Text style={[appStyles.fontMobileSmallLeftRegular, textStyle]} testID="radioText">
        {text}
      </Text>
      <View style={styles.radioButtonView}>
        <TouchableOpacity disabled={disabled} activeOpacity={0.7} style={[styles.radioButton]} onPress={() => toggle && toggle(id)} testID={`radioItem-${id}`}>
          {value ? (
            <Icon
              name="checked-circle"
              fill={fillColor}
              stroke={strokeColor}
              strokeSecondary={secondaryStrokeColor}
              fillSecondary={'none'}
              style={buttonStyle ?? appStyles.icon}
              size={buttonSize ?? 20}
              testID="checkedcircle"
            />
          ) : (
            <Icon
              testID="uncheckedcircle"
              name="unchecked-circle"
              fill={colors.white}
              stroke={colors.darkText}
              style={buttonStyle ?? appStyles.icon}
              size={buttonSize ?? 20}
            />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: colors.navBorder,
    paddingVertical: 5,
  },
  radioButtonView: {
    paddingBottom: 5,
    marginRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default RadioButton;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Schedule } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useRefetchOnFocus } from '@hooks';
import { AppStackRoutes, RewardsStackRoutes, RootTabRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { userService } from '@services/brandywine';
import { useAppConfigStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { Button, Icon, Modal } from '../';
import { parseSchedule } from '../../utils/helpers';
import ProgressBar from '../ProgressBar';
import ProgressNodes from '../ProgressNodes';

export interface SEPointsBalanceProps {
  isRegHome: boolean;
  homeExtensionVisible: boolean;
}

const SEPointsBalance = ({ isRegHome, homeExtensionVisible }: SEPointsBalanceProps) => {
  const navigation = useNavigation();
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [extensionVisible, setExtensionVisible] = useState(false);
  const [currNode, setCurrNode] = useState(1);
  const [nodes, setNodes] = useState<number[]>([]);
  const [pointProgress, setPointProgress] = useState(0);
  const [showInitialProgress, setShowInitialProgress] = useState(true);
  const { getConfigValue } = useAppConfigStore();
  const [earningPeriodObject, setEarningPeriodObject] = useState({
    nextVoucherDate: '',
    nextEndDate: '',
    isBeforeIssue: false,
  });
  const { data: pointsBalance, refetch } = useQuery('points', userService.getPointsBalance);
  useRefetchOnFocus(refetch);

  const getRangeLimits = (base: number) => {
    const num = base % 5;
    const range = {
      min: 0,
      max: 0,
    };
    range.min = base - num;
    range.max = base + (5 - num);
    return range;
  };

  const setNodeValues = () => {
    if (+(pointsBalance ?? 0) < 500) {
      setShowInitialProgress(true);
      const testNodes = [0, 1, 2, 3, 4, 5];
      setCurrNode(1);
      setNodes(testNodes);
    } else {
      setShowInitialProgress(false);
      const rangeBase = Math.floor(+(pointsBalance ?? 0) / 100);
      const actualBase = +((+(pointsBalance ?? 0) / 100) % 5).toFixed(2);
      const limits = getRangeLimits(rangeBase);
      const nodeArr = [];
      for (var i = limits.min; i <= limits.max; i++) {
        nodeArr.push(i);
      }
      setPointProgress(actualBase);
      setCurrNode((rangeBase % 5) + 1);
      setNodes(nodeArr);
    }
  };
  const handleNav = (page: string) => {
    if (!page) {
      return;
    }
    switch (page) {
      case 'wallet':
        navigation.dispatch(
          StackActions.push(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Wallet,
            params: {
              showBack: true,
              link: AppStackRoutes.RootTabs,
              linkParams: { screen: RootTabRoutes.Rewards, params: { screen: RewardsStackRoutes.SEHome, params: {} } },
              toDollarsTab: true,
            },
          }),
        );
        break;
      case 'benefits':
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Rewards, params: { screen: RewardsStackRoutes.SEBenefits, params: {} } });
        break;
    }
  };

  useEffect(() => {
    if (homeExtensionVisible) {
      setExtensionVisible(true);
    }
    setNodeValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeExtensionVisible, pointsBalance]);

  useEffect(() => {
    const endSchedule = getConfigValue<{ VoucherEarningEndSchedule: Schedule[] }>('VoucherEarningEndSchedule') ?? undefined;
    const endDates = parseSchedule(endSchedule?.VoucherEarningEndSchedule ?? []);
    var currDate = dayjs();
    const issueSchedule = getConfigValue<{ VoucherIssueSchedule: Schedule[] }>('VoucherIssueSchedule') ?? undefined;
    const arrivalDates = parseSchedule(issueSchedule?.VoucherIssueSchedule ?? [], endDates);

    const nextEarningEndDate = endDates?.find((ed) => currDate.diff(dayjs(ed)) < 0);
    const nextIssueDate = arrivalDates?.find((ad) => currDate.diff(dayjs(ad)) < 0);

    new Date(nextEarningEndDate) > new Date(nextIssueDate)
      ? setEarningPeriodObject({
          nextVoucherDate: nextIssueDate,
          nextEndDate: nextEarningEndDate,
          isBeforeIssue: true,
        })
      : setEarningPeriodObject({
          nextVoucherDate: nextIssueDate,
          nextEndDate: nextEarningEndDate,
          isBeforeIssue: false,
        });
  }, [getConfigValue]);

  return (
    <>
      <Modal
        contentStyle={styles.modalContent}
        style={{ flexGrow: 1 }}
        title="Points Earning Period"
        visible={infoModalVisible}
        cancelButtonOnPress={() => setInfoModalVisible(false)}
        location="top">
        <Text style={[appStyles.bodyMediumLight, styles.modalContentText]}>
          You must earn at least 500 points at the end of a Something Extra points earning period to receive five Something Extra dollars.
        </Text>
      </Modal>

      <View style={isRegHome ? [styles.container, { width: '100%', borderRadius: 0 }] : [styles.container]}>
        <View style={styles.top}>
          <View>
            <Text style={styles.ptsText}>POINTS BALANCE</Text>
            <Text style={[styles.ptsText, { fontSize: scale(32), lineHeight: lineHeight(30) }]}>{pointsBalance ? pointsBalance : 0}</Text>
          </View>
          {!isRegHome ? (
            <TouchableOpacity onPress={() => setExtensionVisible(!extensionVisible)}>
              <Text style={styles.detailText}>
                Details{'  '}
                <Icon
                  size={18}
                  fill={colors.red}
                  stroke={'none'}
                  name={extensionVisible ? 'triangle-up' : 'triangle-down'}
                  style={{ transform: [{ translateY: 7 }] }}
                />
              </Text>
            </TouchableOpacity>
          ) : (
            <></>
          )}
        </View>
        <View style={styles.mid}>
          {showInitialProgress ? (
            <ProgressBar progressText={500 - (pointsBalance || 0)} progress={pointsBalance || 0} max={500} maxTitle="$5" showMax showProgress />
          ) : (
            <ProgressNodes currStep={currNode} steps={nodes} progress={pointProgress} />
          )}
        </View>
        {+(pointsBalance ?? 0) < 500 ? (
          <View style={[styles.bottom, { marginTop: -28 }]}>
            <Text style={styles.pointsToNextText}>
              <Text style={styles.pointsToNextTextBold}>{500 - (+(pointsBalance ?? 0) % 100)}</Text> Points Needed to Earn SE Dollars{' '}
              <TouchableOpacity style={styles.infoIconWrapper} onPress={() => setInfoModalVisible(true)}>
                <Icon style={styles.infoIcon} size={16} name={'info-icon'} />
              </TouchableOpacity>
            </Text>
          </View>
        ) : (
          <View style={styles.bottom}>
            <Text style={styles.pointsToNextText}>Shop More, Earn More </Text>
          </View>
        )}

        {extensionVisible &&
          (isRegHome ? (
            <View style={[styles.extensionContainer, { height: 115 }]}>
              <View style={[styles.howItWorks, { marginTop: 0, paddingTop: 16 }]}>
                {+(pointsBalance ?? 0) < 500 ? (
                  <Text />
                ) : earningPeriodObject.isBeforeIssue ? (
                  <Text style={[styles.pointsToNextTextBold, { alignSelf: 'center' }]}>Your Next Reward Arrives on {earningPeriodObject.nextVoucherDate}</Text>
                ) : (
                  <Text style={[styles.pointsToNextTextBold, { alignSelf: 'center' }]}>
                    Earned SE Dollars will be issued on {earningPeriodObject.nextEndDate}
                  </Text>
                )}
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate(AppStackRoutes.RootTabs, {
                      screen: RootTabRoutes.Rewards,
                      params: { screen: RewardsStackRoutes.SEHome, params: {} },
                    })
                  }>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignSelf: 'center' }}>
                    <Text style={[styles.learnMoreLink, { lineHeight: lineHeight(20), alignSelf: 'center', paddingTop: 12 }]}>
                      View Your Something Extra DashBoard
                    </Text>
                    <Icon size={18} fill={colors.cream} stroke={'none'} name={'rewards-icon-red'} style={{ paddingTop: 14, left: 5 }} />
                  </View>
                </TouchableOpacity>
                {/* <Text style={[styles.pointsToNextText, { lineHeight: lineHeight(14), alignSelf: 'center', paddingTop: 12 }]}>Earn one point per dollar</Text> */}
              </View>
            </View>
          ) : (
            <View style={styles.extensionContainer}>
              {+(pointsBalance ?? 0) < 500 ? null : earningPeriodObject.isBeforeIssue ? (
                <Text style={[styles.pointsToNextTextBold]}>Your Next Reward Arrives on {earningPeriodObject.nextVoucherDate}</Text>
              ) : (
                <Text style={[styles.pointsToNextTextBold]}>Earned SE Dollars will be issued on {earningPeriodObject.nextEndDate}</Text>
              )}
              <View style={[styles.dollarsButtonWrapper, { marginTop: +(pointsBalance ?? 0) < 500 ? 0 : 16 }]}>
                <Button
                  type={'secondary'}
                  size="small"
                  title={'SE Dollars in Wallet'}
                  onPress={() => handleNav('wallet')}
                  buttonStyle={utilityStyles.py0}
                  textStyle={{ lineHeight: Platform.select({ android: lineHeight(16) }) }}
                />
              </View>
              <View style={styles.howItWorks}>
                <Text style={[styles.pointsToNextTextBold, { lineHeight: lineHeight(18) }]}>How It Works</Text>
                <Text style={[styles.pointsToNextText, { lineHeight: lineHeight(18) }]}>Benefits, Earning, Redeeming & More</Text>
                <TouchableOpacity onPress={() => handleNav('benefits')}>
                  <Text style={[styles.learnMoreLink, { lineHeight: lineHeight(18) }]}>Learn More</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    // height: 200,
    flexGrow: 1,
    backgroundColor: colors.white,
    padding: 16,
    paddingHorizontal: 24,
    borderRadius: 5,
  },
  top: {
    height: 45,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: 'blue',
  },
  mid: {
    height: 80,
    width: '100%',
    justifyContent: 'center',
    paddingBottom: 16,
    paddingTop: 10,
  },
  bottom: {
    height: 40,
    width: '100%',
    alignItems: 'center',
  },
  ptsText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    fontSize: scale(12),
  },
  detailText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.red,
    fontSize: scale(12),
  },
  pointsToNextText: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'center',
  },
  pointsToNextTextBold: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.text,
    paddingTop: 16,
  },
  infoIconWrapper: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 4,
  },
  infoIcon: {
    marginRight: -8,
  },
  extensionContainer: {
    height: 235,
    alignItems: 'center',
    paddingTop: 12,
    // backgroundColor: 'gold',
  },
  dollarsButtonWrapper: {
    marginTop: 32,
  },
  howItWorks: {
    width: '100%',
    paddingTop: 0,
    marginTop: 20,
    borderTopColor: colors.navBorder,
    borderTopWidth: 1,
    alignItems: 'flex-start',
  },
  learnMoreLink: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.red,
  },
  modalContent: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screenWidth * 0.9,
  },
  modalContentText: {
    textAlign: 'center',
    fontWeight: 'normal',
  },
});

export default SEPointsBalance;

import { ErrorFallbackComponent, NavigationHeader, Screen } from '@components';
import AddressForm from '@components/forms/AddressForm';
import HeaderTitle from '@components/HeaderTitle';
import { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

type AddEditAddressScreenProps = AccountStackScreenProps<AccountStackRoutes.AddEditAddress>;

const AddEditAddressScreen = ({ navigation, route }: AddEditAddressScreenProps) => {
  const { addressId, mode, calledFrom } = route.params;

  const handleAfterSubmit = useCallback(
    (addrId: string | number): void => {
      if (calledFrom) {
        navigation.navigate({
          name: calledFrom,
          params: { addressId: addrId },
          merge: true,
        });
      } else {
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
      }
    },
    [calledFrom, navigation],
  );

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID={`${addressId ? 'Edit' : 'Add'} AddressTitle`}>{`${addressId ? 'Edit' : 'Add'} Address`}</HeaderTitle>
      </NavigationHeader>
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
        <AddressForm mode={mode} addressId={addressId} onAfterSubmit={handleAfterSubmit} />
      </ErrorBoundary>
    </Screen>
  );
};

export default AddEditAddressScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, CustomizeStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { CommonActions, useNavigation } from '@react-navigation/native';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import logger from '@utils/logger';
import React, { FC } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../contexts';
import Button from './Button';
import DropShadow from './DropShadow';
import Icon from './Icon';

const { height } = Dimensions.get('window');

interface ICustomizeFooterProps {
  disabled?: boolean;
  showTotal?: boolean;
}

const CustomizeFooter: FC<ICustomizeFooterProps> = ({ disabled, showTotal = true }): JSX.Element => {
  const navigation = useNavigation();
  const { steps, currStep, setCurrStep, subtotal, prevPageData, itemId, selectedSize, setEditCartId, configType } = useCustomizeStore();
  const { showAlertModal } = useCustomModalContext();
  const goToNext = () => {
    if (currStep === steps.length) {
      return;
    }
    var nextPage = steps[currStep];
    setCurrStep(currStep + 1);
    let specializedPages = ['theme', 'size', 'filling', 'container', 'decoration'];

    if (
      nextPage.pageItem?.toLowerCase().includes('cake flavor') ||
      nextPage.pageItem?.toLowerCase().includes('icing') ||
      (nextPage.pageItem?.toLowerCase().includes('decoration') && selectedSize?.itemName.toLowerCase().includes('donuts')) ||
      nextPage.pageItem?.toLowerCase().includes('border') ||
      (nextPage.selectionOption === 'select' && !specializedPages.some((pi) => nextPage?.pageItem?.toLowerCase().includes(pi)))
    ) {
      const navAction = CommonActions.navigate({
        name: configType === 'cakeBuilder' ? CustomizeStackRoutes.BasicSelection : CustomizeStackRoutes.Selection,
        params: nextPage,
        key: nextPage.pageItem,
      });
      navigation.dispatch(navAction);
      // navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.BasicSelection, params: { ...nextPage } });
      return;
    } else if (nextPage.pageItem?.toLowerCase().includes('theme')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Theme,
        params: nextPage,
        key: nextPage.pageItem,
      });
      navigation.dispatch(navAction);
      // navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.Theme, params: { ...nextPage } });
      return;
    } else if (nextPage.pageItem?.toLowerCase().includes('filling')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Filling,
        params: nextPage,
        key: nextPage.pageItem,
      });
      navigation.dispatch(navAction);
      // navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.Filling, params: { ...nextPage } });
      return;
    } else if (nextPage.selectionOption || nextPage.pageItem?.toLowerCase().includes('decoration')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Selection,
        params: nextPage,
        key: nextPage.pageItem,
      });
      navigation.dispatch(navAction);
      // navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.Selection, params: { ...nextPage } });
      return;
    } else if (nextPage.pageItem) {
      logger.log(nextPage.pageItem);
      navigation.navigate(AppStackRoutes.Customize, { screen: nextPage.pageItem as CustomizeStackRoutes, params: { ...nextPage } });
    }
  };

  const goBack = () => {
    if (currStep === 1) {
      navBack();
      return;
    }
    var prevPage = steps[currStep - 2];
    setCurrStep(currStep - 1);
    let specializedPages = ['theme', 'size', 'filling', 'container', 'decoration'];

    if (
      prevPage.pageItem?.toLowerCase().includes('cake flavor') ||
      prevPage.pageItem?.toLowerCase().includes('icing') ||
      prevPage.pageItem?.toLowerCase().includes('donut type') ||
      prevPage.pageItem?.toLowerCase().includes('border') ||
      (prevPage.selectionOption === 'select' && !specializedPages.some((pi) => prevPage?.pageItem?.toLowerCase().includes(pi)))
    ) {
      navigation.navigate(AppStackRoutes.Customize, {
        screen: configType === 'cakeBuilder' ? CustomizeStackRoutes.BasicSelection : CustomizeStackRoutes.Selection,
        params: { ...prevPage },
      });
    } else if (prevPage.pageItem?.toLowerCase().includes('theme')) {
      navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.Theme, params: { ...prevPage } });
    } else if (prevPage.pageItem?.toLowerCase().includes('filling')) {
      navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.Filling, params: { ...prevPage } });
    } else if (prevPage.selectionOption || prevPage.pageItem?.toLowerCase().includes('decoration')) {
      navigation.navigate(AppStackRoutes.Customize, { screen: CustomizeStackRoutes.Selection, params: { ...prevPage } });
    } else if (prevPage.pageItem) {
      navigation.navigate(AppStackRoutes.Customize, { screen: prevPage.pageItem as CustomizeStackRoutes, params: { ...prevPage } });
    }
  };

  const navBack = () => {
    setEditCartId('');
    if (prevPageData.parentId) {
      try {
        if (prevPageData.parentId === 'favoriteList') {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Account,
            params: { screen: AccountStackRoutes.CustomizeNeeded, params: prevPageData.params },
          });
          return;
        } else if (prevPageData.parentId === 'searchLanding') {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Shop,
            params: {
              screen: ShopStackRoutes.SearchLanding,
              params: { query: '', prevRoute: RootTabRoutes.Home },
            },
          });
          return;
        } else if (prevPageData.parentId === 'cart') {
          navigation.navigate(AppStackRoutes.CartAndCheckout, {
            screen: CheckoutStackRoutes.CartLanding,
            params: { storeNumber: undefined },
          });
          return;
        } else {
          navigation.navigate(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Shop,
            params: { screen: ShopStackRoutes.ProductList, params: { rootCategoryId: prevPageData.parentId, categoryId: prevPageData.id } },
          });
          return;
        }
      } catch (err) {
        logger.error('Customize close error');
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } });
        return;
      }
    }

    if (!itemId) {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home, params: {} });
      return;
    }

    navigation.navigate(AppStackRoutes.ProductDetails, { productKey: itemId });
  };

  const alert = () => {
    showAlertModal({ title: 'Missing Selections', message: 'Select more choices for your customized item' });
  };

  return (
    <View style={styles.container}>
      {showTotal && (
        <DropShadow style={{ shadowOffset: { width: 0, height: -2 }, shadowOpacity: 0.1 }}>
          <View style={styles.totalWrapper}>
            <Text style={styles.subtotalText}>Subtotal ${subtotal}</Text>
          </View>
        </DropShadow>
      )}
      <View style={styles.actionWrapper}>
        <TouchableOpacity onPress={goBack}>
          <Text style={styles.backTextWrapper}>
            <View style={styles.iconWrapper}>
              <Icon name="arrow-left" stroke={colors.darkText} size={20} />
            </View>{' '}
            <Text style={styles.backText}>Back</Text>
          </Text>
        </TouchableOpacity>
        <Button
          rDecore={<Icon name={'arrow-right'} size={20} style={{ transform: [{ translateX: 10 }] }} />}
          onPress={disabled ? alert : goToNext}
          title="Next Step"
          buttonStyle={[styles.nextButton, appStyles.primaryButton]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: height * 0.2,
    width: '100%',
    // justifyContent: 'space-evenly',
    alignItems: 'center',
    // position: 'absolute',
    bottom: screenHeight > 844 ? 0 : screenHeight > 811 ? 10 : 30,
    // backgroundColor: 'gold',
  },
  totalWrapper: {
    width: screenWidth,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  actionWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
  },
  subtotalText: {
    fontSize: scale(18),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    textAlign: 'center',
    marginBottom: height > 640 ? 0 : 20,
    lineHeight: scale(18),
  },
  backTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  iconWrapper: {
    transform: [{ translateY: 5 }],
  },
  nextButton: {
    width: '75%',
    marginBottom: 10,
    marginLeft: 40,
  },
  backText: {
    textDecorationLine: 'underline',
    fontSize: scale(14),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
  },
});

export default CustomizeFooter;

import { useFormikContext } from 'formik';
import React from 'react';
import Button, { ButtonProps } from '../Button';

type SubmitButtonProps = Omit<ButtonProps, 'onPress' | 'onLongPress'>;

const SubmitButton = ({ ...props }: SubmitButtonProps) => {
  const { handleSubmit } = useFormikContext<any>();

  return <Button onPress={handleSubmit} {...props} testID={props.testID} />;
};

export default SubmitButton;

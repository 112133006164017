import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, RewardsStackRoutes, RootTabRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useOffersStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import React from 'react';
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native';
import Carousel from '../Carousel';
import OfferCard from './OfferCard';

const CARD_WIDTH = 165;
const SLIDE_GAP = 12;

const SEOffers = () => {
  const {
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    offersState,
  } = useOffersStore();

  const allOffers = availableSomethingExtraOffers
    .concat(acceptedSomethingExtraOffers)
    .concat(availableWeeklyExclusiveOffers)
    .concat(acceptedWeeklyExclusiveOffers);

  const navigation = useNavigation();
  const forgetConfig = {
    slidesPerPage: 1,
    nextSlidePartial: CARD_WIDTH * 0.44,
    slideWidth: CARD_WIDTH,
    sgPerLine: 1,
  };

  const handleNavigation = (tab?: string) => {
    let currentTab = 'Personalized';
    if (availableSomethingExtraOffers.concat(acceptedSomethingExtraOffers).length > 0) {
      currentTab = 'Personalized';
    } else if (availableWeeklyExclusiveOffers.concat(acceptedWeeklyExclusiveOffers).length > 0) {
      currentTab = 'Weekly Exclusives';
    } else if (availableDigitalCoupons.concat(acceptedDigitalCoupons).length > 0) {
      currentTab = 'Digital Coupons';
    }
    navigation.dispatch(
      StackActions.push(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Rewards,
        params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: tab ?? currentTab } },
      }),
    );
  };

  return offersState === 'loading' ? (
    <ActivityIndicator size={'small'} />
  ) : (allOffers.length ?? 0) > 0 ? (
    <>
      <View style={[styles.container, utilityStyles.mb5]}>
        <View style={styles.header}>
          <View style={styles.offerHeader}>
            <Text style={[styles.title, appStyles.dropShadowTitle]}>Something Extra Offers</Text>
            <TouchableOpacity onPress={() => handleNavigation()}>
              <Text style={styles.seeAllLink}>See All</Text>
            </TouchableOpacity>
          </View>
          {/* <View style={styles.seeAllWrapper}>
            <TouchableOpacity onPress={() => handleNavigation('SE Offers')}>
              <Text style={styles.seeAllLink}>See All {allOffers?.length ?? 0}</Text>
            </TouchableOpacity>
          </View> */}
        </View>
        <View style={styles.carouselWrapper}>
          <Carousel
            windwoSize={allOffers.length * 1.1}
            snapToInterval={forgetConfig.slideWidth + SLIDE_GAP}
            contentContainerStyle={{
              ...styles.carouselContainer,
              width: (allOffers.length ? allOffers.slice(0, 10).length : 0) * (forgetConfig.slideWidth + SLIDE_GAP) + SLIDE_GAP,
              marginRight: 10,
            }}
            entities={
              availableSomethingExtraOffers.concat(acceptedSomethingExtraOffers).length
                ? availableSomethingExtraOffers.concat(acceptedSomethingExtraOffers).slice(0, 10)
                : availableWeeklyExclusiveOffers.concat(acceptedWeeklyExclusiveOffers).length
                ? availableWeeklyExclusiveOffers.concat(acceptedWeeklyExclusiveOffers).slice(0, 10)
                : allOffers.length
                ? allOffers.slice(0, 10)
                : []
            }
            renderItem={({ item, index }) => {
              return (
                <View style={{ marginBottom: 16, marginLeft: 12 }} key={'carousel_item_key_' + index}>
                  <OfferCard item={item} index={index} />
                </View>
              );
            }}
            pagerStyle={styles.pagerStyle}
            pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
            pagerInactiveItemStyle={appStyles.carouselDotsSmall}
            showPager={false}
            removeClippedSubviews={false}
          />
        </View>
      </View>
    </>
  ) : (
    <View style={[styles.container, utilityStyles.mb5, utilityStyles.px3]}>
      <Text style={[styles.title, { marginBottom: scale(30) }]}>Something Extra Offers</Text>
      <View style={styles.whitebox}>
        <Text style={[appStyles.bodyLargeCenter, { fontFamily: FontFamily.LarsseitBold, width: offersState !== 'error' ? '70%' : '85%' }]}>
          {offersState === 'error' ? "We're sorry, there seems to be a technical problem." : 'There are currently no SE Offers available.'}
        </Text>
        <Text style={[appStyles.bodySmallLight, { marginBottom: 16, marginTop: 24, textAlign: 'center', width: '85%' }]}>
          {offersState === 'error' ? 'The app is having trouble connecting. Unfortunately, SE Offers cannot be shown at this time.' : 'Other ways to save:'}
        </Text>
        {!(offersState === 'error') && (
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-evenly' }}>
            <TouchableOpacity onPress={() => handleNavigation('Weekly Exclusives')}>
              <Text style={appStyles.smallBoldCenterRed}>Weekly Exclusives</Text>
            </TouchableOpacity>
            <TouchableOpacity
              // onPress={() => navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.DigitalCoupons } })}>
              onPress={() => handleNavigation('Digital Coupons')}>
              <Text style={appStyles.smallBoldCenterRed}>Digital Coupons</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  whitebox: {
    width: '100%',
    backgroundColor: colors.white,
    height: 220,
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  offerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  container: {
    flex: 1,
    width: screenWidth,
    height: '100%',
    alignSelf: 'center',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginBottom: 12,
  },
  title: {
    fontFamily: FontFamily.AleoBold,
    color: colors.darkText,
    fontSize: scale(24),
  },
  seeAllWrapper: {
    alignSelf: 'flex-end',
    paddingBottom: 4,
  },
  seeAllLink: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    fontSize: scale(15),
    textDecorationLine: 'underline',
    marginBottom: 3,
  },
  carouselWrapper: {
    height: 345,
    width: screenWidth,
    alignSelf: 'center',
    // backgroundColor: 'gold',
  },
  carouselContainer: {
    // marginTop: scale(10),
    // marginBottom: scale(10),
    borderWidth: 0,
    overflow: 'visible',
  },
  carouselItemPadding: {
    borderWidth: 0,
    width: 10,
  },
  pagerStyle: {
    marginTop: 20,
    marginBottom: 10,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  productContainer: {
    width: CARD_WIDTH,
    height: 300,
    borderWidth: 1.5,
    borderColor: colors.red,
    borderRadius: 10,
    borderStyle: 'dashed',
    // padding: 12,
    backgroundColor: colors.white,
  },
  itemInfo: {
    justifyContent: 'space-evenly',
    flex: 1,
    padding: 12,
  },
  itemButtonWrapper: {
    alignItems: 'center',
    paddingBottom: 12,
  },
  itemOfferText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.red,
    fontSize: scale(14),
    textAlign: 'center',
    lineHeight: lineHeight(14),
  },
  itemNameText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.text,
    fontSize: scale(14),
    textAlign: 'center',
    lineHeight: lineHeight(14),
  },
  itemDateText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(14),
    textAlign: 'center',
  },
  clipButton: {
    marginTop: 0,
    minWidth: 125,
    maxWidth: 150,
    height: 34,
    alignSelf: 'center',
    paddingBottom: 0,
    paddingTop: -4,
  },
  clippedButton: {
    marginTop: 0,
    minWidth: 125,
    maxWidth: 150,
    height: 34,
    alignSelf: 'center',
    paddingBottom: 0,
    paddingTop: -4,
    backgroundColor: 'green',
    borderWidth: 0,
  },
});

export default SEOffers;

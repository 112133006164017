import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { loadAsync } from 'expo-font';
import React, { useState } from 'react';
import NativeConfig from 'react-native-config';
import AppRoot from './src/AppRoot';
import { customFonts } from './src/config/fonts';
import { useEffectOnce } from './src/hooks';
import logger from './src/utils/logger';

const App = () => {
  try {
    const env = require('./env-config');
    for (var configKey in NativeConfig) {
      var value = env.hasOwnProperty(configKey) ? env[configKey] : NativeConfig[configKey];
      NativeConfig[configKey] = value;
    }
  } catch (e) {
    logger.error(e);
  }

  const [appIsReady, setIsAppReady] = useState(false);
  useEffectOnce(() => {
    const handleAppLoad = async () => {
      // load fonts
      await loadAsync(customFonts);
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.extend(isoWeek);
      //TODO: get device time zone
      dayjs.tz.setDefault(dayjs.tz.guess());
      setIsAppReady(true);
    };

    handleAppLoad();
  });
  if (!appIsReady) {
    return null;
  }
  return <AppRoot />;
};

export default App;

import { CloseButton, KeyboardAvoidingScreen, KeyboardAvoidingScrollView, Modal, Text } from '@components';
import { Form, FormField, SubmitButton } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { authService } from '@services/brandywine';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import helpers from '@utils/helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Platform, ScrollView, StyleSheet, View } from 'react-native';
import * as Yup from 'yup';

type ResetPasswordProp = AuthStackScreenProps<AuthStackRoutes.ResetPassword>;

const ResetPasswordScreen = ({ navigation, route }: ResetPasswordProp) => {
  const { current } = useCardAnimation();
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [showResetPasswordProcessedSuccessModal, setShowResetPasswordProcessedSuccessfullyModal] = useState(false);
  const [showResetPasswordProcessedErrorModal, setShowResetPasswordProcessedErrorModal] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const newPasswordRef = React.createRef<TextInput>();
  const confirmPasswordRef = React.createRef<TextInput>();

  const { t } = useTranslation('resetPassword');

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required()
      .min(8, t('passwordLengthValidation'))
      .matches(helpers.PASSWORD_REGEX, t('passwordNumCharValidation'))
      .label('New password'),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('newPassword'), null], t('newConfirmPasswordValidationText'))
      .label('Confirm password'),
  });

  const resetPassword = async ({ newPassword }: any) => {
    setIsButtonLoading(true);

    try {
      const result = await authService.resetPassword(route.params.email!, newPassword, route.params.code!);
      if (result === false) {
        newPasswordRef.current?.clear();
        confirmPasswordRef.current?.clear();
        setShowResetPasswordProcessedSuccessfullyModal(true);
      } else {
        setShowResetPasswordProcessedErrorModal(true);
      }
    } catch (error) {
      setShowResetPasswordProcessedErrorModal(true);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const navigateToLogin = () => {
    navigation.goBack();
  };

  const renderCreateNewPassword = () => {
    return (
      <>
        <View style={styles.textWrapper}>
          <Text style={styles.h3BoldCenter} testID={'resetPassword'}>
            {t('resetPassword')}
          </Text>
          <Text style={styles.bodyLargeCenter} testID={'passwordPolicyLabel'}>
            {t('passwordPolicyLabel')}
          </Text>
        </View>
        <View style={styles.form}>
          <Form initialValues={{ newPassword: '', confirmPassword: '' }} onSubmit={resetPassword} validationSchema={validationSchema}>
            <FormField
              testID="newPassword"
              autoCapitalize="none"
              autoCorrect={false}
              name="newPassword"
              label={t('newPasswordLabelText')}
              secureTextEntry={!showNewPassword}
              textContentType="password"
              ref={newPasswordRef}
              returnKeyType="next"
              onSubmitEditing={() => {
                confirmPasswordRef?.current?.focus();
              }}
              icon={{
                testID: 'newPasswordIcon',
                name: showNewPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={() => {
                setShowNewPassword(!showNewPassword);
              }}
              keyboardType={Platform.select({ android: 'default' })}
            />
            <FormField
              testID="confirmPassword"
              autoCapitalize="none"
              autoCorrect={false}
              name="confirmPassword"
              label={t('confirmPasswordLabelText')}
              secureTextEntry={!showConfirmPassword}
              textContentType="password"
              ref={confirmPasswordRef}
              returnKeyType="go"
              icon={{
                testID: 'confirmPasswordIcon',
                name: showConfirmPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              keyboardType={Platform.select({ android: 'default' })}
            />
            <View style={styles.buttonsContainer}>
              <SubmitButton testID="savePasswordText" title={t('savePasswordText')} isButtonLoading={isButtonLoading} />
            </View>
          </Form>
        </View>
      </>
    );
  };

  const showPasswordChangeSuccessModal = () => {
    return (
      <Modal
        visible={showResetPasswordProcessedSuccessModal}
        title={t('success')}
        location="top"
        cancelButtonOnPress={navigateToLogin}
        cancelButtonText={t('okText')}>
        <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID={'successMessage'}>
          {t('successMessage')}
        </Text>
      </Modal>
    );
  };

  const showPasswordChangeErrorModal = () => {
    return (
      <Modal
        visible={showResetPasswordProcessedErrorModal}
        title={t('error')}
        location="top"
        cancelButtonOnPress={() => {
          setShowResetPasswordProcessedErrorModal(false);
        }}
        cancelButtonText={t('okText')}>
        <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID={'errorMessage'}>
          {t('errorMessage')}
        </Text>
      </Modal>
    );
  };

  return (
    <KeyboardAvoidingScreen keyboardVerticalOffset={-100} style={{ backgroundColor: 'transparent' }}>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.65, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <View style={styles.header} testID={'closeButton'}>
            <CloseButton style={styles.closeButton} />
          </View>
          <ScrollView style={styles.form} testID={'createAccountForm'}>
            <KeyboardAvoidingScrollView scrollEnabled={true} extraKeyboardOffset={240} contentContainerStyle={appStyles.container} bounces={false}>
              {renderCreateNewPassword()}
            </KeyboardAvoidingScrollView>
          </ScrollView>
        </View>
      </Animated.View>
      {showResetPasswordProcessedSuccessModal && showPasswordChangeSuccessModal()}
      {showResetPasswordProcessedErrorModal && showPasswordChangeErrorModal()}
    </KeyboardAvoidingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: screenHeight * 0.85,
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  textWrapper: {
    paddingHorizontal: 0,
    paddingBottom: 25,
  },
  bodyLargeCenter: {
    ...appStyles.bodyLargeCenter,
    marginTop: 15,
  },
  form: {
    width: '100%',
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  errorMessage: {
    ...appStyles.bodySmallRed,
    textAlign: 'center',
    paddingBottom: 15,
  },
  errorWrapper: {
    width: 330,
    height: 25,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: scale(16),
    fontFamily: 'Larsseit-Light',
  },
  errorBorder: {
    borderColor: colors.red,
  },
  buttonsContainer: {
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 25,
  },
  textInfo: {
    fontSize: scale(18),
    textAlign: 'center',
    lineHeight: lineHeight(18),
    marginBottom: 45,
    fontFamily: FontFamily.LarsseitLight,
    width: screenWidth * 0.8,
    alignSelf: 'center',
  },
});

export default ResetPasswordScreen;

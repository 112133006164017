import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';

const useRefetchOnFocus = <TData, TError>(
  refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<TData, TError>>,
) => {
  const enabledRef = React.useRef(false);

  useFocusEffect(
    useCallback(() => {
      if (enabledRef.current) {
        refetch();
      } else {
        enabledRef.current = true;
      }
    }, [refetch]),
  );
};
export default useRefetchOnFocus;

import { createStackNavigator, StackCardInterpolationProps } from '@react-navigation/stack';
import {
  AccountScreen,
  AddBirthdayClubMemberScreen,
  AddEditAddressScreen,
  AddEditPaymentMethodScreen,
  AddressBookScreen,
  AppSettingsScreen,
  ChangePasswordScreen,
  CommunicationPreferencesScreen,
  ContactCustomerScreen,
  CustomizeNeededScreen,
  EditProfileScreen,
  ListFilterScreen,
  ListToCartScreen,
  ManageListsScreen,
  PaymentMethodsScreen,
  ViewListScreen,
} from '@screens/account';
import React from 'react';
import HelpCenterNavigator from './HelpCenterNavigator';
import MessageCenterNavigator from './MessageCenterNavigator';
import OrderHistoryNavigator from './OrderHistoryNavigator';
import { AccountStackParamList, AccountStackRoutes } from './routes';
import StoreFinderNavigator from './StoreFinderNavigator';

const AccountNavigator = () => {
  const AccountStack = createStackNavigator<AccountStackParamList>();

  const verticalTransition = {
    cardStyleInterpolator: ({ current, layouts }: StackCardInterpolationProps) => {
      return {
        cardStyle: {
          transform: [
            {
              translateY: current.progress.interpolate({
                inputRange: [0, 1],
                outputRange: [-layouts.screen.height, 0],
              }),
            },
          ],
        },
      };
    },
  };

  return (
    <AccountStack.Navigator
      initialRouteName={AccountStackRoutes.AccountHome}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <AccountStack.Screen name={AccountStackRoutes.AccountHome} component={AccountScreen} initialParams={{ reload: false }} />
      <AccountStack.Screen name={AccountStackRoutes.CommunicationPreferences} component={CommunicationPreferencesScreen} />
      <AccountStack.Screen name={AccountStackRoutes.AppSettings} component={AppSettingsScreen} />
      <AccountStack.Screen name={AccountStackRoutes.OrderHistory} component={OrderHistoryNavigator} />
      <AccountStack.Screen name={AccountStackRoutes.ChangePassword} component={ChangePasswordScreen} />
      <AccountStack.Screen name={AccountStackRoutes.EditProfile} component={EditProfileScreen} />
      <AccountStack.Screen
        name={AccountStackRoutes.AddBirthdayClubMember}
        component={AddBirthdayClubMemberScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
      />
      <AccountStack.Screen name={AccountStackRoutes.ViewList} component={ViewListScreen} />
      <AccountStack.Screen name={AccountStackRoutes.ListFilter} component={ListFilterScreen} options={verticalTransition} />
      <AccountStack.Screen name={AccountStackRoutes.ListToCart} component={ListToCartScreen} options={verticalTransition} />
      <AccountStack.Screen name={AccountStackRoutes.CustomizeNeeded} component={CustomizeNeededScreen} options={verticalTransition} />
      <AccountStack.Screen name={AccountStackRoutes.ManageShoppingList} component={ManageListsScreen} />
      {/* <AccountStack.Screen name={AccountStackRoutes.ManageShoppingList} component={ShoppingListScreen} /> */}
      <AccountStack.Screen name={AccountStackRoutes.PaymentMethods} component={PaymentMethodsScreen} initialParams={{ showBack: true }} />
      <AccountStack.Screen name={AccountStackRoutes.AddEditPaymentMethod} component={AddEditPaymentMethodScreen} initialParams={{}} />
      <AccountStack.Screen name={AccountStackRoutes.ContactCustomerService} component={ContactCustomerScreen} options={verticalTransition} />
      <AccountStack.Screen name={AccountStackRoutes.AddressBook} component={AddressBookScreen} />
      <AccountStack.Screen name={AccountStackRoutes.AddEditAddress} component={AddEditAddressScreen} initialParams={{}} />
      <AccountStack.Screen name={AccountStackRoutes.StoreFinder} component={StoreFinderNavigator} />
      <AccountStack.Screen name={AccountStackRoutes.MessageCenter} component={MessageCenterNavigator} />
      <AccountStack.Screen name={AccountStackRoutes.HelpCenter} component={HelpCenterNavigator} />
    </AccountStack.Navigator>
  );
};

export default AccountNavigator;

/* eslint-disable no-bitwise */

import {
  Accordion,
  BookmarkButton,
  Button,
  Carousel,
  CloseButton,
  DropShadow,
  HtmlView,
  Icon,
  Image,
  LinkButton,
  LoadingScreen,
  ProductImage,
  Screen,
  Text,
  TextBox,
} from '@components';
import CartButton from '@components/CartButton';
import CartInfoBanner from '@components/CartInfoBanner';
import CustomizeButton from '@components/CustomizeButton';
import CustomizeCartInfo from '@components/CustomizeCartInfo';
import { ProductCarousel } from '@components/brsm';
import { CartQuantityButton } from '@components/cart/CartQuantityButton';
import PDPSavingsCard from '@components/somethingExtra/PDPSavingsCard';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import {
  Image as ImageData,
  InventoryMode,
  Money,
  Product,
  ProductData,
  ProductTypeDefinition,
  ProductVariant,
  RawCustomField,
  RawProductAttribute,
} from '@fieldera-raleys/client-commercetools/schema';
import { CategoryCrumb, ItemWidgetParams, ProductType, ShopType, TourKey } from '@fieldera-raleys/client-common';
import Constants from '@fieldera-raleys/client-common/constants/app';
import { LabelNutrient, NutrientDetail, NutritionLabels, Panels, Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useAnalytics } from '@hooks';
import { AppStackRoutes, AppStackScreenProps, CheckoutStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useIsFocused } from '@react-navigation/native';
import { brsmService } from '@services';
import { productService } from '@services/commerceTools';
import { useAnalyticsStore, useAppConfigStore, useCartStore, useCustomizeStore, useShopStore, useUserSettingsStore } from '@store';
import useSearchStore from '@store/searchStore';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { getLineItemsToEvaluateCart } from '@utils/cartHelper';
import { formatValue, toTitleCase } from '@utils/helpers';
import {
  AvailablityResult,
  ProductPromotion,
  getDisplayPriceBySellType,
  getDisplayPriceBySellTypeAndPriceMessage,
  getFormattedPrice,
  getProductAttributeValue,
  getProductAvailablity,
  getProductPromotions,
  getQuantityFromPriceMessage,
  getfulfillmentLeadTimeMinutes,
} from '@utils/productHelper';
import { evaluateProduct, getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import dayjs from 'dayjs';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, LayoutChangeEvent, ListRenderItemInfo, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Autolink from 'react-native-autolink';
import Config from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { useTourGuideController } from 'rn-tourguide';
import NutritionLabel from './nutritionFacts/NutritionLabel';

const containerWidth = screenWidth - 16;

type ProductDetailsScreenProps = AppStackScreenProps<AppStackRoutes.ProductDetails>;

const ProductDetailsScreen = ({ navigation, route }: ProductDetailsScreenProps) => {
  const { addRecentlyViewed } = useSearchStore();
  const { productKey } = route.params;
  const { showMoreFlag } = route.params;
  const { t } = useTranslation(['productDetails', 'tourGuide']);
  const insets = useSafeAreaInsets();
  const customizeStore = useCustomizeStore();
  const { selectedShopType, selectedStore } = useShopStore();
  const [pageLoading, setPageLoading] = useState(true);
  const [productInfo, setProductInfo] = useState<{
    productType: ProductTypeDefinition;
    productData: ProductData;
    masterVariant: ProductVariant;
    productAttributes: RawProductAttribute[];
    availabilityInfo?: RawCustomField[];
    leadTimeWarning?: string;
    leadTimeWarningEx?: string;
    shelfGuideInfo: string[];
    productCategoryCrumbs: CategoryCrumb[];
    price: number | string;
    regularPrice?: number | undefined;
    unitSize?: number | undefined;
    pricePerUnit?: string | undefined;
    onSale: boolean;
    sellType: string;
    unitOfMeasure: string;
    validFrom?: string | undefined;
    validUntil?: string | undefined;
    priceMessageQty: number;
  }>();
  const [notFound, setNotFound] = useState(false);
  const [layout, setLayout] = useState({ warningAccordion: { height: 0, width: 0, x: 0, y: 0 } });
  const flatListRef = createRef<FlatList<CategoryCrumb>>();
  const [showMore, setShowMore] = useState(showMoreFlag);
  const [showMoreOffers, setShowMoreOffers] = useState(false);
  const [allOffers, setAllOffers] = useState<Promotion[]>([]);
  const [reload, setReload] = useState(true);
  const [availability, setAvailability] = useState<AvailablityResult>({ availability: 'Available', inventoryMode: InventoryMode.None });
  const [nutritionFacts, setNutritionFacts] = useState<NutritionLabels>();
  const baseImageUrl = Config.BRXM_IMAGE_URL;
  const svRef = useRef<ScrollView>(null);
  const accordionTitlesArr = [
    'ingredients',
    'nutritionInformation',
    'disclaimer',
    'prop65List',
    'instructions',
    'supportingContent',
    'marketingMessages',
    'warnings',
  ];
  const [contentBottom, setContentBottom] = useState<{ paddingBottom: number }>({ paddingBottom: insets.bottom });
  const [infoBox, setInfoBox] = useState<{ bottom: number }>({ bottom: insets.bottom });
  const onButtonLayout = (le: LayoutChangeEvent) => {
    setContentBottom({ paddingBottom: le.nativeEvent.layout.height });
    setInfoBox({ bottom: le.nativeEvent.layout.height });
  };

  const urlConfig = {
    schemeMatches: true,
    wwwMatches: true,
    tldMatches: true,
  };
  const { trackPageViewEvent } = useAnalytics();
  const { previousRoute } = useAnalyticsStore();
  const isFocused = useIsFocused();
  const { MeasurePrecisionCode, MetricUnit, NutritionFacts } = Constants;
  const { data: product, status: productLoadStatus } = useQuery(
    ['product', productKey],
    async () => {
      var data = await productService.getProductBySku(productKey);
      if (data.masterData.current) {
        var previouslyPurchasedFlag = await brsmService.getPreviouslyPurchasedProducts([productKey]);
        var attributesRaw = [...(data.masterData.current.masterVariant.attributesRaw ?? [])];
        attributesRaw.push({ name: 'previouslyPurchased', value: previouslyPurchasedFlag.length !== 0, referencedResourceSet: [] });
        data.masterData.current.masterVariant.attributesRaw = attributesRaw;
      }
      return data;
    },
    { enabled: isFocused },
  );
  const pageConfig = useAppConfigStore().getConfigGroup('PDP');
  const [dropDownState, setDropDownState] = useState<{
    ingredients: boolean;
    nutrition: boolean;
    disclaimer: boolean;
    warning: boolean;
    instructions: boolean;
    supporting: boolean;
  }>({
    ingredients: false,
    nutrition: false,
    disclaimer: false,
    warning: false,
    instructions: false,
    supporting: false,
  });

  const [promotoion, setPromotion] = useState<ProductPromotion>();
  const { userSettings } = useUserSettingsStore();
  const { cart, tombSet } = useCartStore();
  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function  to stopping it
    TourGuideZone,
  } = useTourGuideController(TourKey.ProductDetailScreen);

  const startTourGuide = useCallback(() => {
    if (canStart && (userSettings?.showTourGuide ?? false)) {
      start();
    }
  }, [canStart, start, userSettings?.showTourGuide]);

  useEffect(() => {
    startTourGuide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStart]);

  const buildProductCategoryCrumb = useCallback(async (item: Product): Promise<CategoryCrumb[]> => {
    var crumbList: CategoryCrumb[] = [];
    const masterData = item.masterData.current;
    // logger.log(JSON.stringify(masterData, null, 2));
    // logger.log('CAT TREE', JSON.stringify(categories, null, 2));
    //push crumbs
    if (masterData?.categories.length) {
      const productCategory = masterData?.categories[0];
      //push current item
      crumbList.push({
        categoryId: item.id,
        categoryName: masterData?.name
          ? masterData?.name.replace(masterData?.name.slice(masterData?.name.indexOf('(') - 1, masterData?.name.lastIndexOf(')') + 1), '')
          : '',
        parentId: productCategory?.parent?.id || '',
        rootId: productCategory?.id || '',
      });
      crumbList.unshift({
        categoryId: productCategory?.key ?? '',
        categoryName: productCategory?.name ?? '',
        parentId: productCategory?.parent?.key || '',
        rootId: productCategory?.parent?.key || '',
      });

      var currCat = productCategory?.ancestors.find((a) => a.key === productCategory.parent?.key);
      while (currCat?.parent?.key) {
        crumbList.unshift({
          categoryId: currCat.key ?? '',
          categoryName: currCat.name ?? '',
          parentId: currCat?.parent?.key || '',
          rootId: productCategory?.parent?.key || '',
        });
        currCat = productCategory?.ancestors.find((a) => a.key === currCat?.parent?.key);
      }

      // logger.log('crumb', JSON.stringify(crumbList, null, 2));
      // if (productCategory) {
      //   crumbList = [...productCategory.crumb, ...crumbList];
      // }
    }
    return crumbList;
  }, []);

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', async () => {
      if (reload) {
        setReload(false);
      }
      // startTourGuide();
    });

    const unsubscribeBlur = navigation.addListener('blur', () => {
      setPageLoading(true);
      setReload(true);
      setProductInfo(undefined);
      stop();
    });

    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
    };
  }, [navigation, reload, startTourGuide, canStart, stop]);

  const buildNutrientFacts = (facts: Panels) => {
    let nutritionLabels: NutritionLabels = {
      servingSize: 0,
      servingSizeUnit: '',
      labelNutrients: [],
      numberOfServings: '',
      metricServingSize: 0,
      metricServingSizeUnit: '',
    };
    if (facts?.NutrientDetails.length > 0) {
      facts.ServingSize && (nutritionLabels.servingSize = facts.ServingSize.Value);
      nutritionLabels.servingSizeUnit =
        facts.ServingSize && facts.ServingSize.Code ? MetricUnit[facts.ServingSize.Code] ?? toTitleCase(facts?.ServingSize?.Code) : '';
      facts.NumberOfServings && (nutritionLabels.numberOfServings = facts.NumberOfServings);
      facts.MetricServingSize && (nutritionLabels.metricServingSize = facts.MetricServingSize.Value);
      nutritionLabels.metricServingSizeUnit =
        facts.MetricServingSize && facts.MetricServingSize.Code ? MetricUnit[facts.MetricServingSize.Code] ?? toTitleCase(facts?.MetricServingSize?.Code) : '';
      nutritionLabels.labelNutrients = facts?.NutrientDetails.filter((n: NutrientDetail) => {
        return n.NutrientTypeCode !== null && n.NutrientTypeCode !== undefined;
      })
        .map((n: NutrientDetail) => {
          const ln: LabelNutrient = {
            nutrientTypeCode: n.NutrientTypeCode,
            nutrientType: NutritionFacts[n?.NutrientTypeCode] ?? {
              displayName: n.NutrientTypeCode,
              isBold: false,
              isIndent: false,
              sortOrder: 99999,
              height: 0.3,
            },
            unit: n.QuantityContained ? MetricUnit[n.QuantityContained.Code] ?? n?.QuantityContained?.Code : '',
            value: n.QuantityContained?.Value! >= 0 ? `${n.QuantityContained?.Value}` : '',
            dailyValue: n.DailyValueIntakePercent! >= 0 ? `${n.DailyValueIntakePercent}%` : '',
            precisionCode: n.MeasurementPrecisionCode ? MeasurePrecisionCode[n.MeasurementPrecisionCode] : '',
          };
          return ln;
        })
        .sort((a, b) => (a?.nutrientType.sortOrder < b?.nutrientType.sortOrder ? -1 : 0));

      setNutritionFacts(nutritionLabels);
    }
  };

  // load product info
  useEffect(() => {
    const pageInit = async () => {
      if (product && product.masterData.current) {
        // logger.log(JSON.stringify(product, null, 2));
        const categoryCrumb = await buildProductCategoryCrumb(product);
        const availabilityInfo = product.masterData.current.masterVariant.availability?.channels.results[0] ?? undefined;
        if (availabilityInfo?.availability.id) {
        }
        const leadTime = getfulfillmentLeadTimeMinutes(product);
        let leadMessage, leadMessageEx;
        if (leadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES) {
          leadMessage =
            leadTime > +Config.LEAD_TIME_MAX_HOURS_TOSHOW * 60 ? `${(leadTime / 60 / 24 + 1) | 0} Day Lead Time` : `${(leadTime / 60) | 0}-Hour Lead Time`;
          leadMessageEx = 'This item requires advance ordering. Would you like to continue?';
        }

        const unitsPerPackage = getProductAttributeValue('unitsPerPackage', product.masterData.current.masterVariant.attributesRaw);
        const unitOfMeasure = getProductAttributeValue('unitOfMeasure', product.masterData.current.masterVariant.attributesRaw);
        let pricePerUnit =
          product.masterData.current.masterVariant.price?.value && getFormattedPrice(product.masterData.current.masterVariant.price?.value, unitsPerPackage);
        const priceCustomValues = product.masterData.current.masterVariant.price?.custom?.customFieldsRaw ?? [];
        // const salePrice =
        //   product.masterData.current.masterVariant.price?.discounted?.value &&
        //   getFormattedPrice(product.masterData.current.masterVariant.price?.discounted?.value);
        const priceMessage = product.masterData.current.masterVariant.price?.custom?.customFieldsRaw?.find((cf) => cf.name === 'PriceMessage')?.value;
        const price = product.masterData.current.masterVariant.price?.value && getFormattedPrice(product.masterData.current.masterVariant.price?.value);
        let regularPrice =
          priceCustomValues.length && priceCustomValues.length > 0
            ? priceCustomValues.length &&
              priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value &&
              getFormattedPrice(priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value)
            : price;
        // regularPrice = regularPrice === price ? 0 : +regularPrice;
        let onSale =
          (product.masterData.current.masterVariant.price?.value?.centAmount ?? 0) <
            (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value?.centAmount ?? 0) &&
          priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value
            ? true
            : false;
        let sellType = product.masterData.current.masterVariant.attributesRaw.find((a) => a.name === 'unitSellType')?.value.key ?? 'byEach';
        const packageCount = getProductAttributeValue('packageCount', product.masterData.current.masterVariant.attributesRaw) ?? 1;

        const validFrom =
          (product.masterData.current.masterVariant.price?.validFrom && dayjs(product.masterData.current.masterVariant.price?.validFrom).format('MMM DD')) ??
          '';
        const validUntil =
          (product.masterData.current.masterVariant.price?.validUntil && dayjs(product.masterData.current.masterVariant.price?.validUntil).format('MMM DD')) ??
          '';

        let unitSize = parseFloat((Number(packageCount) * Number(unitsPerPackage)).toFixed(1));
        if (sellType === 'byEach') {
          const currencyCode = 'USD';
          const fractionDigits = 2;
          const centAmount =
            (product.masterData.current.masterVariant.price?.value.centAmount ??
              (priceCustomValues.length && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount)) / unitSize;
          let money: Money = {
            type: 'centPrecision',
            currencyCode: currencyCode,
            centAmount: centAmount,
            fractionDigits: fractionDigits,
          };

          pricePerUnit = getFormattedPrice(money);
        }

        let avgBuyWeight = product.masterData.current.masterVariant.attributesRaw.find((a) => a.name === 'unitAverageBuyWeight')?.value;
        const priceMessageQty = getQuantityFromPriceMessage(priceMessage);
        // logger.log('AVGBUY:', avgBuyWeight, 'PROPS:', JSON.stringify(product.masterData.current.masterVariant.attributesRaw, null, 2));
        // logger.log('P:', price, 'RP:', priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, 'MESS:', priceMessage, 'OnSale:', onSale);
        // logger.log(JSON.stringify(product.masterData.current.masterVariant.attributesRaw, null, 2));
        setProductInfo({
          productType: product.productType as ProductTypeDefinition,
          productData: product.masterData.current,
          masterVariant: product.masterData.current.masterVariant,
          productAttributes: product.masterData.current.masterVariant.attributesRaw,
          availabilityInfo: product.masterData.current.masterVariant.price?.custom?.customFieldsRaw ?? undefined,
          leadTimeWarning: leadMessage,
          leadTimeWarningEx: leadMessageEx,
          shelfGuideInfo: product.masterData.current.masterVariant.attributesRaw.find((a) => a.name === 'shelfGuideIndicators')?.value ?? [],
          productCategoryCrumbs: categoryCrumb,
          onSale: onSale,
          regularPrice: regularPrice,
          priceMessageQty: 1,
          price: onSale
            ? priceMessage.length && priceMessage.includes(' for $')
              ? priceMessage
              : priceMessageQty > 1
              ? getDisplayPriceBySellTypeAndPriceMessage(sellType.key, priceMessage, product.masterData.current.masterVariant.price?.value, avgBuyWeight)
              : getDisplayPriceBySellType(sellType, product.masterData.current.masterVariant.price?.value, avgBuyWeight)
            : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value &&
              priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount
            ? getDisplayPriceBySellType(sellType, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, avgBuyWeight)
            : getDisplayPriceBySellType(sellType, product.masterData.current.masterVariant.price?.value, avgBuyWeight),
          sellType: sellType ?? 'byEach',
          unitSize: unitSize,
          pricePerUnit: pricePerUnit,
          unitOfMeasure: unitOfMeasure,
          validFrom: validFrom,
          validUntil: validUntil,
        });

        const p = product.masterData.current.masterVariant.price?.value ?? { type: 'centPrecision', currencyCode: 'USD', centAmount: 0, fractionDigits: 2 };
        const rp = priceCustomValues.length > 0 ? priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value : p;
        const relatedOffers = await evaluateProduct(
          selectedStore?.number ?? Config.BRSM_DEFAULT_STORE,
          selectedShopType === ShopType.DELIVERY ? 'delivery' : 'pickup',
          {
            ExtProductId: product.masterData.current.masterVariant?.sku ?? '',
            Quantity: 0,
            Price: +(Math.ceil(p.centAmount / 1) / (10 ** p.fractionDigits || 1)),
            RegularPrice: +(Math.ceil(rp.centAmount / 1) / (10 ** rp.fractionDigits || 1)),
          },
          getLineItemsToEvaluateCart(cart?.lineItems ?? []),
        );
        // const availableOffers = getAvailableOffers(product.masterData.current.masterVariant.attributesRaw.find((attr) => attr.name === 'primaryUPC')?.value);
        // const acceptedOffers = getAcceptedOffers(product.masterData.current.masterVariant.attributesRaw.find((attr) => attr.name === 'primaryUPC')?.value);
        // setAllOffers(availableOffers.concat(acceptedOffers).concat(relatedOffers) ?? []);
        // setAllOffers(relatedOffers.filter((promotion) => promotion.PDPDisplay === true) ?? []);
        setAllOffers(relatedOffers.filter((promo) => promo.PDPDisplay !== false) ?? []);

        const nutritionInfo = getProductAttributeValue('nutritionInformation', product.masterData.current.masterVariant.attributesRaw);
        if (nutritionInfo) {
          const nutritionInformation = JSON.parse(nutritionInfo);
          nutritionInformation.Panels && nutritionInformation.Panels.length > 0 && buildNutrientFacts(nutritionInformation.Panels[0]);
        }

        const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE, [productKey]);
        const updatedProductList = getProductPromotions(promotions, [product]);
        const productPromotion = updatedProductList[0].promotion;
        setPromotion(productPromotion);

        addRecentlyViewed(productKey);
        trackPageViewEvent({
          prod_id: productKey,
          prod_name: encodeURI(product.masterData.current.name as string),
          sku: productKey,
        });
        setAvailability(getProductAvailablity(product?.masterData));
      } else {
        setNotFound(true);
      }
      setPageLoading(false);
    };
    if (productLoadStatus === 'success') {
      pageInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productLoadStatus, reload, buildProductCategoryCrumb, productKey, trackPageViewEvent, addRecentlyViewed]);

  const renderImage = useCallback(
    ({ item }: ListRenderItemInfo<ImageData>) => {
      return (
        <View style={styles.imageSlide}>
          <ProductImage
            imageUrl={item.url}
            imageStyle={styles.imageSlideImage}
            product={product!}
            imageSize="large"
            resizeMode={'contain'}
            modalImageResizeMode={'contain'}
            showBadge={false}
            productPromotion={promotoion}
          />
        </View>
      );
    },
    [product, promotoion],
  );

  const renderCrumb = ({ item, index }: ListRenderItemInfo<CategoryCrumb>) => {
    const handleCrumbClick = async () => {
      if (index + 1 === (productInfo?.productCategoryCrumbs.length ?? 1)) {
        return;
      }
      if (!item.parentId) {
        navigation.canGoBack() && navigation.goBack();
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.ProductList,
            params: { categoryId: item.categoryId },
          },
        });
      } else {
        navigation.canGoBack() && navigation.goBack();
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.ProductList,
            params: { rootCategoryId: item.rootId, categoryId: item.categoryId },
          },
        });
      }
    };
    const isLast = (productInfo?.productCategoryCrumbs.length ?? 1) - 1 === index;

    return (
      <View>
        <TouchableOpacity onPress={handleCrumbClick}>
          <Text testID={`${item.categoryName}`} style={isLast ? appStyles.breadcrumbCurrent : appStyles.breadcrumb}>
            {item.categoryName}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderCrumbSeperator = () => <Icon name="arrow-right" style={styles.breadcrumbItemSeperator} size={12} />;

  const displayWarning = () => {
    svRef?.current?.scrollTo({
      y: layout.warningAccordion.y + 700,
      animated: true,
    });
    setDropDownState({ ...dropDownState, warning: true });
  };

  const scrollToProductLocation = (y: number) => {
    userSettings?.showTourGuide === true &&
      selectedShopType === ShopType.IN_STORE &&
      productInfo?.availabilityInfo &&
      productInfo?.availabilityInfo.find((x) => x.name === 'areaBayShelfBin') &&
      svRef?.current?.scrollTo({ y: y - 100, animated: true });
    startTourGuide();
  };

  const handleShelfGuideNavigation = (shelfGuideName: string) => {
    stop();

    navigation.navigate(AppStackRoutes.ShelfGuideDefinitions, {
      shelfGuide: shelfGuideName.replace(/\s/g, '').toLowerCase(),
      shelfGuideName: shelfGuideName,
    });
  };

  brsmService.sortShelfGuideArrayData(productInfo?.shelfGuideInfo);
  let attrib: RawProductAttribute | RawCustomField | undefined;
  return productLoadStatus === 'loading' || pageLoading || customizeStore.customizeLoading ? (
    <LoadingScreen />
  ) : productInfo && !notFound ? (
    <Screen headerVisible={true}>
      <View style={[appStyles.container, styles.wrapper, { paddingHorizontal: 0 }]}>
        <CloseButton style={{ top: 16, left: 16 }} />
        <View style={styles.cartButtonWrapper}>
          <CartButton />
        </View>
        <DropShadow>
          <View style={styles.breadcrumb}>
            <FlatList
              ref={flatListRef}
              ItemSeparatorComponent={renderCrumbSeperator}
              data={productInfo.productCategoryCrumbs || []}
              keyExtractor={(_, indx) => indx.toString()}
              renderItem={renderCrumb}
              maxToRenderPerBatch={5}
              windowSize={2}
              contentContainerStyle={[styles.breadcrumbContainer, { backgroundColor: colors.cream }]}
              horizontal
              style={{
                shadowOpacity: 0,
              }}
              showsHorizontalScrollIndicator={false}
              // onContentSizeChange={() => flatListRef?.current?.scrollToEnd({ animated: true })}
              // onLayout={() => flatListRef?.current?.scrollToEnd({ animated: true })}
            />
          </View>
        </DropShadow>
        <ScrollView
          ref={svRef}
          style={[appStyles.scrollView, styles.scrollView, utilityStyles.mt0]}
          contentContainerStyle={[styles.scrollViewContent, contentBottom]}
          nestedScrollEnabled={true}
          bounces={false}
          showsVerticalScrollIndicator={false}>
          <CartInfoBanner />
          <View style={styles.carousel}>
            <Carousel
              snapToInterval={screenWidth - 16}
              entities={productInfo.masterVariant.images || []}
              renderItem={renderImage}
              showPager={productInfo.masterVariant.images && productInfo.masterVariant.images.length > 1}
              pagerStyle={styles.pagerStyle}
              pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
              pagerInactiveItemStyle={appStyles.carouselDotsSmall}
              ListEmptyComponent={
                <View style={styles.imageSlide}>
                  <ProductImage imageUrl={''} imageStyle={styles.imageSlideImage} product={product!} imageSize="large" resizeMode={'contain'} disabled={true} />
                </View>
              }
            />
          </View>
          <View
            style={styles.productDetails}
            onLayout={(event) => {
              scrollToProductLocation(event.nativeEvent.layout.y);
            }}>
            <View style={styles.productInfo}>
              <Text testID="productName" style={[appStyles.h6, { width: '100%' }]}>
                {productInfo.productData.name}
              </Text>
              {/* TODO:// data from product model */}
              <View style={styles.productAttributes}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingTop: Platform.select({ android: 13, default: 0 }) }}>
                    {productInfo.masterVariant.price && (
                      <View style={styles.leftProductAttr}>
                        {productInfo.productAttributes && productInfo.sellType === 'byEach' ? (
                          <View style={styles.productAttribute}>
                            <Text testID="productAttributes" style={styles.productAttributeText}>
                              {productInfo.unitSize &&
                                productInfo.unitSize + ` ${productInfo.productAttributes.find((x) => x.name === 'unitOfMeasure')?.value}`}
                            </Text>
                          </View>
                        ) : null}
                        {productInfo.productAttributes && productInfo.sellType === 'byEach' && (
                          <View style={styles.productAttribute}>
                            <Text testID="unitsPerPackage" style={styles.productAttributeText}>
                              {productInfo.pricePerUnit + ` / ${productInfo.unitOfMeasure}`}
                            </Text>
                          </View>
                        )}
                        {productInfo.sellType === 'weightByEach' &&
                        productInfo.productAttributes &&
                        productInfo.productAttributes.find((x) => x.name === 'unitOfMeasure')?.value !== 'Each' &&
                        (attrib = productInfo.productAttributes.find((x) => x.name === 'unitOfMeasure')) ? (
                          <View style={styles.productAttribute}>
                            <Text testID="unitOfMeasure" style={styles.productAttributeText}>
                              {formatValue(
                                Math.ceil(
                                  productInfo.masterVariant.price?.value.centAmount /
                                    productInfo.productAttributes.find((x) => x.name === 'unitsPerPackage')?.value,
                                ) / (10 ** (productInfo?.masterVariant?.price?.value?.fractionDigits ?? 2) || 1),
                              )}{' '}
                              / {productInfo.productAttributes.find((x) => x.name === 'unitOfMeasure')?.value}
                            </Text>
                          </View>
                        ) : null}
                        {productInfo.sellType === 'weightByEach' && productInfo.onSale && (
                          <Text style={[styles.productPriceStrike]}>
                            {productInfo.regularPrice}
                            {` / ${productInfo.unitOfMeasure}`}
                          </Text>
                        )}
                        {selectedStore?.address.state.toUpperCase() === 'CA' &&
                        productInfo.productAttributes &&
                        (attrib = productInfo.productAttributes.find((x) => x.name === 'crv')) &&
                        productInfo.productAttributes.find((x) => x.name === 'crv')?.value?.centAmount > 0 ? (
                          <View style={styles.productAttribute}>
                            <Text testID="productAttributeCRV" style={styles.productAttributeText}>
                              CRV: ${(attrib.value.centAmount / 10 ** attrib.value.fractionDigits).toFixed(2)}
                            </Text>
                          </View>
                        ) : null}
                      </View>
                    )}
                  </View>
                  {selectedShopType === ShopType.IN_STORE &&
                  productInfo?.availabilityInfo &&
                  (attrib = productInfo?.availabilityInfo.find((x) => x.name === 'areaBayShelfBin')) ? (
                    <TourGuideZone
                      text={t('tgProductDetailTxt', { ns: 'tourGuide' })}
                      zone={1}
                      shape={'rectangle'}
                      isTourGuide={userSettings?.showTourGuide === true && selectedShopType === ShopType.IN_STORE}>
                      <View style={{ flexDirection: 'row', transform: [{ translateY: Platform.select({ android: 5, default: 0 }) }] }}>
                        <Icon
                          testID="locationIcon"
                          name="location-icon"
                          fill={colors.darkText}
                          stroke={colors.darkText}
                          size={20}
                          style={styles.productLocationIcon}
                        />
                        <Text testID="productAttributeValue" style={styles.productAttributeText}>
                          {attrib.value}
                        </Text>
                      </View>
                    </TourGuideZone>
                  ) : null}
                </View>
              </View>
            </View>
            <View style={styles.productPricing}>
              {/* TODO:// discounted pricing and pricing*/}
              {productInfo.masterVariant.price && (
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      testID="productPrice"
                      style={[
                        appStyles.priceRed,
                        { color: productInfo.onSale ? colors.saleRed : colors.text, paddingTop: Platform.select({ android: 15, default: 0 }) },
                      ]}>
                      {productInfo.productType?.name !== 'standard' ? 'Starts at ' : ''}
                      {productInfo.productAttributes && productInfo.productAttributes.find((x) => x.name === 'unitSellType')?.value.key === 'weightByEach' ? (
                        <Text testID="approx" style={styles.productAttributeText}>
                          {'approx '}
                        </Text>
                      ) : null}
                      {productInfo.price}
                      {!productInfo.price.toString().includes(' for $') && (
                        <Text testID="productInfoPrice" style={[appStyles.bodyLargeLeft, { transform: [{ translateY: 3 }] }]}>
                          {productInfo.priceMessageQty > 1 ? null : productInfo.sellType === 'byWeight' ? ` / ${productInfo.unitOfMeasure}` : ' ea'}
                        </Text>
                      )}
                    </Text>

                    {productInfo.sellType === 'weightByEach'
                      ? productInfo.productAttributes.find((x) => x.name === 'unitAverageBuyWeight')?.value && (
                          <Text testID="productInfoPriceStrike" style={[styles.productAttributeText, { paddingLeft: 25 }, { transform: [{ translateY: 4 }] }]}>
                            Avg. {productInfo.productAttributes.find((x) => x.name === 'unitAverageBuyWeight')?.value}{' '}
                            {productInfo.productAttributes.find((x) => x.name === 'unitOfMeasure')?.value}
                          </Text>
                        )
                      : (productInfo.sellType === 'byWeight' || productInfo.sellType === 'byEach') &&
                        productInfo.onSale && (
                          <Text style={[styles.productPriceStrike, appStyles.bodyLargeLeft, { transform: [{ translateY: 4 }] }]}>
                            {productInfo.regularPrice}
                            {productInfo.sellType === 'byWeight' ? ` / ${productInfo.unitOfMeasure}` : ' ea'}
                          </Text>
                        )}
                  </View>
                  {productInfo.validUntil && (
                    <Text testID="productAttribute" style={[styles.productAttributeText, { transform: [{ translateY: 7 }] }]}>
                      Ends {productInfo.validUntil}
                    </Text>
                  )}
                </View>
              )}
              {productInfo.masterVariant.price?.discounted ? (
                <Text testID="unitOfMeasure" style={appStyles.priceRed}>
                  ${productInfo.masterVariant.price?.value} / {productInfo.productAttributes?.find((x) => x.name === 'unitOfMeasure')?.value}
                </Text>
              ) : null}
            </View>
            {availability.availability === 'LowStock' && (
              <View style={{ flexDirection: 'row' }}>
                <Icon testID="flagRedIcon" name={'flag-red-icon'} stroke={'none'} size={11} style={{ transform: [{ translateY: 4 }], marginRight: -4 }} />
                <Text testID="LowStock" style={styles.lowStockText}>
                  Low Stock
                </Text>
              </View>
            )}
            {allOffers?.length > 0 && (
              <View>
                <View style={styles.itemSeperator} />
                <View style={[{ width: '100%', alignSelf: 'center' }]}>
                  <Text testID="availableOffers" style={[appStyles.h6, { paddingTop: scale(36) }]}>
                    Available Savings
                  </Text>
                </View>
                <View>
                  {showMoreOffers
                    ? allOffers?.map((offer, idx) => {
                        return <PDPSavingsCard key={`related1_${offer.ExtPromotionId}_${idx}}`} offer={offer} />;
                      })
                    : allOffers.slice(0, 2)?.map((offer, idx) => {
                        return <PDPSavingsCard key={`related1_${offer.ExtPromotionId}_${idx}}`} offer={offer} />;
                      })}
                  {allOffers.length > 2 ? (
                    <View style={styles.showMoreOffersWrapper}>
                      <TouchableOpacity onPress={() => setShowMoreOffers(!showMoreOffers)} activeOpacity={0.7}>
                        {!showMoreOffers ? (
                          <Text testID="showMoreOffer" style={styles.offersShowMoreLink}>
                            Show More
                          </Text>
                        ) : (
                          <Text testID="showLessOffer" style={styles.offersShowMoreLink}>
                            Show Less
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  ) : null}
                  <View style={[styles.itemSeperator, { marginTop: scale(36) }]} />
                </View>
              </View>
            )}
            {productInfo.productType?.name !== 'standard' ? (
              productInfo.leadTimeWarning && (
                <>
                  <View style={styles.customizeDisclaimer}>
                    <Text testID="leadTimeWarning" style={styles.customizeDisclaimerText}>
                      {productInfo.leadTimeWarning}
                    </Text>
                    {productInfo.productAttributes && (attrib = productInfo.productAttributes.find((x) => x.name === 'feeds')) ? (
                      <Text testID="customDisclaimerServes" style={styles.customizeDisclaimerText}>
                        Serves {attrib.value.replace('to', '-').replace(/\s/g, '')}
                      </Text>
                    ) : null}
                  </View>
                </>
              )
            ) : (
              <>
                <View style={[appStyles.mt10, styles.productDescription]}>
                  {productInfo.productAttributes?.find((attr) => attr.name === 'feeds')?.value && (
                    <Text testID="prodDescriptionServes" style={[styles.customizeDisclaimerText, styles.standardServes]}>
                      Serves {productInfo.productAttributes?.find((attr) => attr.name === 'feeds')?.value}
                    </Text>
                  )}
                  {productInfo.productData?.description && (
                    <TextBox
                      allowFontScaling={true}
                      maxFontSizeMultiplier={4}
                      textStyle={[appStyles.bodyLeftRegular, { fontSize: Platform.select({ android: scale(15), default: scale(16) }) }]}
                      text={productInfo.productData?.description || ''}
                    />
                  )}
                </View>
                {productInfo.shelfGuideInfo && productInfo.shelfGuideInfo.length > 0 ? (
                  showMore ? (
                    <View style={styles.shelfGuideWrap}>
                      {productInfo.shelfGuideInfo.map((sg: string, idx) => {
                        return (
                          <View style={styles.sgWrapper} key={`sgView_${sg}_${idx}`}>
                            <TouchableOpacity onPress={() => handleShelfGuideNavigation(sg)} activeOpacity={0.7}>
                              <Image
                                testID={`showMore${sg}Image`}
                                style={styles.sgImage}
                                source={{
                                  uri: `${baseImageUrl}/consumer-app/shelf-guide/icons/${sg.replace(/\s/g, '').toLowerCase()}.png`,
                                }}
                                resizeMode={'contain'}
                              />
                              <Text testID={`showMore${sg}`} style={styles.sgText}>
                                {sg}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        );
                      })}
                    </View>
                  ) : (
                    <View style={styles.shelfGuideWrap}>
                      {productInfo.shelfGuideInfo.slice(0, 3).map((sg: string, idx) => {
                        return (
                          <View style={styles.sgWrapper} key={`giView_${sg}_${idx}`}>
                            <TouchableOpacity onPress={() => handleShelfGuideNavigation(sg)} activeOpacity={0.7}>
                              <Image
                                testID={`showLess${sg}Image`}
                                style={styles.sgImage}
                                source={{
                                  uri: `${baseImageUrl}/consumer-app/shelf-guide/icons/${sg.replace(/\s/g, '').toLowerCase()}.png`,
                                }}
                                resizeMode={'contain'}
                              />
                              <Text testID={`showLess${sg}`} style={styles.sgText}>
                                {sg}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        );
                      })}
                    </View>
                  )
                ) : null}
                {productInfo.shelfGuideInfo.length > 3 ? (
                  <View style={styles.showMoreWrapper}>
                    <TouchableOpacity onPress={() => setShowMore(!showMore)} activeOpacity={0.7}>
                      {!showMore ? (
                        <Text testID="shelfGuideShowMore" style={styles.showMoreLink}>
                          Show More
                        </Text>
                      ) : (
                        <Text testID="shelfGuideShowLess" style={styles.showMoreLink}>
                          Show Less
                        </Text>
                      )}
                    </TouchableOpacity>
                  </View>
                ) : null}
                {productInfo.productAttributes?.some((attr) => accordionTitlesArr.indexOf(attr.name) >= 0 && attr.value.length) &&
                (productInfo.productAttributes?.filter((pa) => accordionTitlesArr.indexOf(pa.name) >= 0)?.length <= 3 &&
                (productInfo.productAttributes.some((pa) => pa.name === 'prop65List') ||
                  productInfo.productAttributes.some((pa) => pa.name === 'marketingMessages') ||
                  productInfo.productAttributes.some((pa) => pa.name === 'warnings'))
                  ? (productInfo?.productAttributes?.find((pa) => pa.name === 'prop65List')?.value.length ?? -1 > 0) ||
                    (productInfo?.productAttributes?.find((pa) => pa.name === 'marketingMessages')?.value.length ?? -1 > 0) ||
                    (productInfo?.productAttributes?.find((pa) => pa.name === 'warnings')?.value.length ?? -1 > 0)
                    ? true
                    : productInfo?.productAttributes?.filter((pa) => pa.name === 'prop65List' || pa.name === 'marketingMessages' || pa.name === 'warnings')
                        .length === 3
                    ? false
                    : true
                  : true) ? (
                  <View style={styles.accordionContainer}>
                    <Image
                      testID="productDetailImage"
                      resizeMode={'contain'}
                      source={require('../assets/images/productDetailsOrnament.png')}
                      style={styles.detailsOrnament}
                    />
                    <Text testID="productDetails" style={[appStyles.accordionHeader, { fontSize: scale(25) }]}>
                      Product Details
                    </Text>
                    {productInfo.productAttributes?.find((attr) => attr.name === 'ingredients')?.value && (
                      <Accordion
                        style={styles.accordionWrapper}
                        shadowEnabled={false}
                        headerStyle={[styles.accordion, dropDownState.ingredients ? null : styles.bottomBorder]}
                        title={'Ingredients'}
                        textStyle={appStyles.bodySmallLeft}
                        setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, ingredients: isOpen })}
                        isOpen={dropDownState.ingredients}
                        icon={<Icon name={!dropDownState.ingredients ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                        <View style={styles.accordionContentContainer}>
                          <View style={[styles.accordionContent, { paddingHorizontal: 5 }]}>
                            <Text testID="ingredients" style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(18) }]}>
                              {productInfo.productAttributes?.find((attr) => attr.name === 'ingredients')?.value}
                            </Text>
                          </View>
                        </View>
                      </Accordion>
                    )}
                    {productInfo.productAttributes?.find((attr) => attr.name === 'disclaimer')?.value && (
                      <Accordion
                        style={styles.accordionWrapper}
                        shadowEnabled={false}
                        headerStyle={[styles.accordion, dropDownState.disclaimer ? null : styles.bottomBorder]}
                        title={'Disclaimer'}
                        textStyle={appStyles.bodySmallLeft}
                        setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, disclaimer: isOpen })}
                        isOpen={dropDownState.disclaimer}
                        icon={<Icon name={!dropDownState.disclaimer ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                        <View style={styles.accordionContentContainer}>
                          <View style={[styles.accordionContent, { paddingHorizontal: 5 }]}>
                            <Text testID="productDisclaimer" style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(18) }]}>
                              {productInfo.productAttributes?.find((attr) => attr.name === 'disclaimer')?.value}
                            </Text>
                          </View>
                        </View>
                      </Accordion>
                    )}
                    <View
                      onLayout={(e) => {
                        setLayout({ warningAccordion: e.nativeEvent.layout });
                      }}
                    />
                    {(productInfo.productAttributes?.find((attr) => attr.name === 'prop65List') &&
                      productInfo.productAttributes?.find((attr) => attr.name === 'prop65List')?.value.length) ||
                    (productInfo.productAttributes?.find((attr) => attr.name === 'warnings') &&
                      productInfo.productAttributes?.find((attr) => attr.name === 'warnings')?.value.length) ||
                    (productInfo.productAttributes?.find((attr) => attr.name === 'marketingMessages') &&
                      productInfo.productAttributes?.find((attr) => attr.name === 'marketingMessages')?.value.length) ? (
                      <Accordion
                        style={styles.accordionWrapper}
                        shadowEnabled={false}
                        headerStyle={[styles.accordion, dropDownState.warning ? null : [styles.bottomBorder]]}
                        title={'Warning'}
                        textStyle={appStyles.bodySmallLeft}
                        setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, warning: isOpen })}
                        isOpen={dropDownState.warning}
                        icon={<Icon name={!dropDownState.warning ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                        <View style={styles.accordionContentContainer}>
                          <View style={[styles.accordionContent, { paddingHorizontal: 5 }]}>
                            <Autolink
                              text={productInfo.productAttributes?.find((attr) => attr.name === 'prop65List')?.value.join('. ')}
                              renderText={(text) => <Text style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(18) }]}>{text}</Text>}
                              url={urlConfig}
                              showAlert
                            />
                            {productInfo.productAttributes
                              ?.find((attr) => attr.name === 'warnings')
                              ?.value.map((w: string, i: number) => {
                                return (
                                  <Text
                                    testID="warnings"
                                    key={`pAttrView_${w}_${i}`}
                                    style={[
                                      appStyles.bodySmallLeftRegular,
                                      {
                                        lineHeight: lineHeight(18),
                                        marginTop: i === 0 && !productInfo.productAttributes?.find((attr) => attr.name === 'prop65List')?.value.length ? 0 : 16,
                                      },
                                    ]}>
                                    {w}
                                  </Text>
                                );
                              })}
                            {productInfo.productAttributes?.find((attr) => attr.name === 'marketingMessages')?.value.length && (
                              <HtmlView
                                contentWidth={screenWidth - 24}
                                text={productInfo.productAttributes?.find((attr) => attr.name === 'marketingMessages')?.value.join('')}
                              />
                            )}
                          </View>
                        </View>
                      </Accordion>
                    ) : null}
                    {productInfo.productAttributes?.find((attr) => attr.name === 'directions')?.value && (
                      <Accordion
                        style={styles.accordionWrapper}
                        shadowEnabled={false}
                        headerStyle={[styles.accordion, dropDownState.instructions ? null : styles.bottomBorder]}
                        title={'Instructions'}
                        textStyle={appStyles.bodySmallLeft}
                        setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, instructions: isOpen })}
                        isOpen={dropDownState.instructions}
                        icon={<Icon name={!dropDownState.instructions ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                        <View style={styles.accordionContentContainer}>
                          <View style={[styles.accordionContent, { paddingHorizontal: 5 }]}>
                            <Text testID="direction" style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(18) }]}>
                              {productInfo.productAttributes?.find((attr) => attr.name === 'directions')?.value}
                            </Text>
                          </View>
                        </View>
                      </Accordion>
                    )}
                    {productInfo.productAttributes?.find((attr) => attr.name === 'supportingContent')?.value && (
                      <Accordion
                        style={styles.accordionWrapper}
                        shadowEnabled={false}
                        headerStyle={[styles.accordion]}
                        title={'More Information'}
                        textStyle={appStyles.bodySmallLeft}
                        setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, supporting: isOpen })}
                        isOpen={dropDownState.supporting}
                        icon={<Icon name={!dropDownState.supporting ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                        <View style={styles.accordionContentContainer}>
                          <View style={[styles.accordionContent, { paddingHorizontal: 5 }]}>
                            <Text testID="prodSupportingContent" style={[appStyles.bodySmallLeftRegular, { lineHeight: lineHeight(18) }]}>
                              {productInfo.productAttributes?.find((attr) => attr.name === 'supportingContent')?.value}
                            </Text>
                          </View>
                        </View>
                      </Accordion>
                    )}
                    {nutritionFacts && (
                      <Accordion
                        style={styles.accordionWrapper}
                        shadowEnabled={false}
                        headerStyle={[styles.accordion, dropDownState.nutrition ? null : styles.bottomBorder]}
                        title={'Nutrition'}
                        textStyle={appStyles.bodySmallLeft}
                        setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, nutrition: isOpen })}
                        isOpen={dropDownState.nutrition}
                        icon={<Icon name={!dropDownState.nutrition ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                        <View style={(styles.accordionContentContainer, {})}>
                          <View style={[styles.accordionContent]}>
                            <NutritionLabel nutritionFact={nutritionFacts} />
                          </View>
                        </View>
                      </Accordion>
                    )}
                  </View>
                ) : null}
                {productInfo.productAttributes &&
                (attrib = productInfo.productAttributes.find((x) => x.name === 'prop65List')) &&
                productInfo.productAttributes?.find((attr) => attr.name === 'prop65List')?.value.length ? (
                  <TouchableOpacity onPress={displayWarning} activeOpacity={1} style={styles.prop65Warning}>
                    <Icon testID="cautionIcon" name="caution-icon" fill={'yellow'} size={25} style={appStyles.smallIcon} />
                    <Text
                      testID="prop65Warning"
                      style={[
                        appStyles.fontProp65Warning,
                        { textDecorationLine: 'underline', paddingLeft: 20, paddingTop: 4, transform: [{ translateY: 7 }] },
                      ]}>
                      PROP 65 WARNING
                    </Text>
                  </TouchableOpacity>
                ) : null}
                {pageConfig?.RelatedProducts && (
                  <View style={{ alignSelf: 'center' }}>
                    <ProductCarousel
                      title="Suggested Items"
                      contentContainerStyle={[styles.contentContainer, styles.cream]}
                      addCartButtonStyle={styles.cartAddQty}
                      backgroundStyle={styles.cream}
                      widgetType={'SimilarProducts'}
                      widgetId={pageConfig.RelatedProducts}
                      widgetParams={
                        { type: 'item', item_ids: productKey, context_id: productKey, url: route.name, ref_url: previousRoute?.name ?? '/' } as ItemWidgetParams
                      }
                    />
                  </View>
                )}
              </>
            )}
          </View>

          <View style={[styles.disclaimer, { paddingBottom: 150 + insets.bottom, backgroundColor: '#F9F4F1' }]}>
            <Text testID="disclaimer" style={[appStyles.bodySmallLight, { fontSize: scale(12) }]} allowFontScaling={true} maxFontSizeMultiplier={4}>
              {t('disclaimer')}
            </Text>
          </View>
        </ScrollView>
        <View
          onLayout={onButtonLayout}
          style={[
            styles.fixedCTA,
            {
              paddingBottom: screenHeight < 740 ? 10 + insets.bottom : insets.bottom,
              flexGrow: 1,
            },
          ]}>
          <View style={styles.buttonWrapper}>
            {productInfo.productType?.name === ProductType.CONFIGURABLE ? (
              <View>
                <View style={styles.innerButtonWrapper}>
                  <CustomizeButton
                    disabled={!productInfo.masterVariant.price}
                    productKey={productKey}
                    type={'secondary'}
                    buttonStyle={{ width: '70%', marginRight: 24 }}
                    productLeadTime={productInfo.leadTimeWarningEx}
                    testID={'productAddToCartBtn'}
                    availabilityResult={availability}
                  />
                  {selectedShopType === ShopType.IN_STORE ? null : (
                    <BookmarkButton
                      productSku={productKey}
                      size={35}
                      alertContainerStyle={[styles.alertBox, infoBox]}
                      disabled={productInfo.masterVariant.price ? false : true}
                    />
                  )}
                </View>
                <CustomizeCartInfo
                  // isButton={true}
                  boxStyle={[{ backgroundColor: 'transparent', alignItems: 'center', marginVertical: -6, width: '80%' }]}
                  productKey={productKey}
                />
              </View>
            ) : (
              <View>
                <View style={styles.innerButtonWrapper}>
                  <View style={{ flexShrink: 1, alignItems: 'center' }}>
                    <CartQuantityButton
                      product={product!}
                      infoBox={styles.alertBox}
                      unitsPerPackage={getProductAttributeValue('unitsPerPackage', productInfo.productAttributes)}
                      productSellType={getProductAttributeValue('unitSellType', productInfo.productAttributes)}
                      unitBuyIncrement={getProductAttributeValue('unitBuyIncrement', productInfo.productAttributes)}
                      unitBuyMaximum={getProductAttributeValue('unitBuyMaximum', productInfo.productAttributes)}
                      unitBuyMinimum={getProductAttributeValue('unitBuyMinimum', productInfo.productAttributes)}
                      unitOfMeasure={getProductAttributeValue('unitOfMeasure', productInfo.productAttributes)}
                      disabled={!productInfo.masterVariant.price}
                      type="secondary"
                      variantKey={productKey}
                      productName={productInfo.productData?.name ?? ''}
                      productKey={productInfo.masterVariant?.sku ?? ''}
                      productLeadTime={productInfo.leadTimeWarningEx}
                      showCartText={true}
                      textStyle={styles.cartQtyText}
                      cartTextStyle={styles.inCartText}
                      borderStyle={styles.border}
                      buttonStyle={{ width: '70%' }}
                      availabilityResult={availability}
                      testID={'productAddToCartBtn'}
                      infoBox={infoBox}
                    />
                  </View>
                  {/* {selectedShopType === ShopType.IN_STORE ? null : ( */}
                  <BookmarkButton
                    productSku={productKey}
                    size={35}
                    alertContainerStyle={[styles.alertBox, infoBox]}
                    disabled={productInfo.masterVariant.price ? false : true}
                  />
                  {/* )} */}
                </View>
                {availability.availability === 'Discontinued' && pageConfig && (
                  <View style={styles.statusBtnView}>
                    <LinkButton
                      style={styles.linkBtnText}
                      onPress={() => {
                        const widgetId = pageConfig.SimilarProducts;
                        const widgetType = 'SimilarProducts';
                        const widgetParams = { item_ids: productKey, context_id: productKey } as ItemWidgetParams;
                        navigation.navigate(AppStackRoutes.ProductCarouselList, {
                          widgetType,
                          widgetId,
                          widgetParams,
                        });
                      }}>
                      View Similar Items
                    </LinkButton>
                  </View>
                )}
                {availability.availability === 'Unavailable' && (
                  <View style={styles.unavailableView}>
                    <Text testID="notAvailable" style={[styles.linkBtnText, { color: colors.primary, marginRight: 5 }]}>
                      Not Available at
                    </Text>
                    <LinkButton
                      style={styles.linkBtnText}
                      onPress={() => {
                        tombSet('FindAStore');
                        navigation.navigate(AppStackRoutes.CartAndCheckout, {
                          screen: CheckoutStackRoutes.CartLanding,
                          params: { storeNumber: undefined },
                        });
                      }}>
                      Selected Location
                    </LinkButton>
                  </View>
                )}

                <CustomizeCartInfo
                  isButton={true}
                  boxStyle={[{ backgroundColor: 'transparent', alignItems: 'center', marginVertical: -6, width: '90%' }]}
                  productKey={productKey}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    </Screen>
  ) : (
    //TODO:// set to generic error page
    <View style={{ flex: 1, backgroundColor: colors.cream, alignItems: 'center', justifyContent: 'center' }}>
      <Text testID="tempErrorFallback">Temporary Error Fallback...sign out of account.</Text>
      <Button onPress={() => navigation.goBack()} title={'Go back'} />
    </View>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    marginBottom: 80,
  },
  cartButtonWrapper: {
    position: 'absolute',
    top: 9,
    right: 12,
    width: 50,
    zIndex: 999,
  },
  offerItemContainer: {
    flexDirection: 'row',
    width: '80%',
    paddingTop: 8,
  },
  offerText: {
    marginBottom: 4,
  },
  detailsOrnament: {
    height: 100,
    width: 120,
    overflow: 'visible',
    position: 'absolute',
    top: -60,
    left: 0,
  },
  accordionContainer: {
    flexGrow: 1,
    width: screenWidth,
    alignSelf: 'center',
    backgroundColor: colors.white,
    // backgroundColor: 'gold',
    marginBottom: 40,
    paddingHorizontal: 16,
    paddingTop: 40,
    marginTop: 24,
  },
  accordionWrapper: {
    width: screenWidth,
    alignSelf: 'center',
    backgroundColor: colors.white,
  },
  accordionContentContainer: {
    flexGrow: 1,
    backgroundColor: colors.white,
    padding: 16,
    paddingTop: 0,
  },
  accordionContent: {
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingBottom: 32,
  },
  accordion: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '92%',
    backgroundColor: 'white',
    paddingHorizontal: 0,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderColor: 'rgba(216, 191, 172, 0.5)',
  },
  wrapper: {
    padding: 0,
    marginVertical: 0,
  },
  disclaimer: {
    width: '100%',
    backgroundColor: colors.sectionPad,
    padding: 20,
  },
  favoriteWrapper: {
    width: '25%',
    alignItems: 'flex-end',
    paddingRight: 12,
  },
  fixedCTA: {
    position: 'absolute',
    bottom: 90,
    backgroundColor: colors.cream,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 16,
    width: screenWidth,
    borderTopColor: colors.darkCream,
    borderTopWidth: 2,
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.14 : screenHeight > 812 ? screenHeight * 0.122 : screenHeight < 740 ? screenHeight * 0.126 : screenHeight * 0.136,
  },
  previouslyPurchasedWrapper: {
    backgroundColor: 'white',
    opacity: 0.85,
    position: 'absolute',
    flexDirection: 'row',
    left: 16,
    top: 16,
    width: '50%',
    alignItems: 'center',
  },
  prevPurchasedText: {
    fontFamily: 'Larsseit-Thin',
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    color: colors.darkText,
    marginLeft: 8,
    marginTop: 2,
  },
  breadcrumb: {
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
    height: 60,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
    backgroundColor: colors.cream,
    overflow: 'hidden',
  },
  breadcrumbContainer: {
    alignItems: 'baseline',
    overflow: 'visible',
    paddingHorizontal: 16,
    shadowOpacity: 0,
  },
  breadcrumbItemSeperator: {
    marginHorizontal: 5,
    marginTop: Platform.select({ android: 4 }),
    width: 12,
    height: 12,
  },
  carousel: {
    width: containerWidth,
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  pagerStyle: {
    marginTop: 5,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  inCartText: {
    fontSize: scale(16),
    marginTop: -2,
  },
  cartQtyText: {
    fontSize: scale(22),
    marginBottom: 2,
  },
  pagerItemStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 2,
  },
  imageSlide: {
    width: containerWidth,
    alignContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: 8,
    backgroundColor: colors.white,
  },
  imageSlideImage: {
    width: containerWidth * 0.95,
    aspectRatio: 1,
  },
  productDetails: {
    paddingHorizontal: 15,
  },
  productInfo: {},
  productAttributes: {
    marginTop: 10,
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: 'blue',
    // alignContent: 'space-between',
  },
  productAttribute: {
    marginRight: 25,
  },
  productAttributeText: {
    fontFamily: 'Larsseit-Light',
    fontSize: scale(15),
  },
  productLocationIcon: {
    width: 15,
    height: 15,
    marginRight: 8,
    transform: Platform.OS === 'ios' ? [{ translateY: -6 }] : [{ translateY: -2 }],
  },
  productPricing: {
    marginTop: 10,
    marginBottom: 5,
    paddingBottom: 10,
    flex: 1,
    alignItems: 'baseline',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignContent: 'space-between',
    // borderBottomWidth: 1.5,
    // borderColor: colors.darkCream,
  },
  quantityBox: {
    height: 56,
  },
  productPriceStrike: {
    ...appStyles.bodyLargeLeft,
    fontFamily: 'Larsseit-Light',
    fontSize: scale(15),
    lineHeight: lineHeight(15),
    marginLeft: 16,
    // marginTop: 4,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  buttonStyle: {
    width: '80%',
    marginBottom: 5,
  },
  productDescription: {
    marginTop: 10,
    marginBottom: 35,
    width: '93%',
  },
  showMoreLink: {
    color: colors.darkText,
    fontSize: scale(14),
    fontFamily: 'Aleo-Bold',
    textDecorationLine: 'underline',
    lineHeight: lineHeight(25),
    // marginLeft: 10,
  },
  offersShowMoreLink: {
    color: colors.darkText,
    fontSize: scale(18),
    fontFamily: 'Aleo-Bold',
    textDecorationLine: 'underline',
    lineHeight: lineHeight(25),
    // marginLeft: 10,
  },
  shelfGuideWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 48,
  },
  sgWrapper: {
    width: '33%',
    alignItems: 'center',
    marginBottom: 10,
  },
  sgImage: {
    width: 50,
    height: 50,
    alignSelf: 'center',
    marginBottom: 10,
  },
  sgText: {
    textDecorationLine: 'underline',
    textAlign: 'center',
    fontSize: scale(14),
  },
  showMoreWrapper: {
    alignItems: 'center',
    marginBottom: 35,
  },
  showMoreOffersWrapper: {
    alignItems: 'center',
    marginTop: scale(20),
  },
  prop65Warning: {
    marginBottom: 32,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  container: {
    flex: 1,
    backgroundColor: '#98B3B7',
    justifyContent: 'center',
  },
  extraBar: {
    position: 'absolute',
    top: 0,
    right: 8,
    width: '25%',
    alignItems: 'center',
  },
  extraText: {
    color: colors.white,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(16),
  },
  leadTimeBar: {
    backgroundColor: colors.teal,
    position: 'absolute',
    bottom: screenHeight > 812 ? -13 : -15,
    left: 8,
    width: '50%',
    borderTopRightRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 7,
    paddingTop: 10,
    paddingBottom: 10,
  },
  leadTimeText: {
    color: colors.white,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(13),
  },
  promoBar: {
    backgroundColor: 'gold',
    position: 'absolute',
    bottom: 0,
    left: 5,
    width: '35%',
    borderTopRightRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 7,
    paddingTop: 10,
    paddingBottom: screenHeight > 740 ? 10 : 20,
  },
  promoText: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(13),
  },
  customizeDisclaimer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 30,
  },
  customizeDisclaimerText: {
    fontFamily: 'Larsseit',
    fontSize: scale(18),
    color: colors.darkText,
  },
  standardServes: {
    alignSelf: 'flex-start',
    marginBottom: 16,
  },
  customizeCartQtyBox: {
    marginBottom: 0,
    marginTop: -60,
    width: '73%',
  },
  buttonWrapper: {
    // paddingTop: 12,
    paddingLeft: 0,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    // backgroundColor: 'green',
  },
  innerButtonWrapper: {
    flexDirection: 'row',
    width: screenWidth,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingRight: 12,
    paddingBottom: screenHeight < 740 ? 6 : 0,
  },
  customizeCartInfoContainer: {
    position: 'relative',
    bottom: 10,
    width: '80%',
    height: 'auto',
    backgroundColor: colors.cream,
    marginTop: 10,
    marginBottom: 15,
  },
  border: {
    height: 56,
    top: -5,
  },
  zoomedImgContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    // width: screenWidth,
    height: 400,
    alignSelf: 'center',
    backgroundColor: 'transparent',
    // borderWidth: 1,
    borderColor: colors.darkCream,
  },
  modalContent: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    width: screenWidth,
    paddingTop: 0,
    marginTop: screenHeight > 740 ? 32 : 0,
    height: 400,
  },
  modalContainer: {
    height: Platform.select({ ios: screenHeight, android: screenHeight * 0.95 }),
    padding: 0,
    paddingTop: 16,
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  leftProductAttr: {
    flexDirection: 'row',
  },
  scrollViewContent: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.cream,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cream: {
    backgroundColor: colors.cream,
  },
  cartAddQty: {
    backgroundColor: colors.darkCream,
  },
  statusBtnView: {
    paddingBottom: screenHeight < 740 ? 8 : 0,
    paddingLeft: '25%',
    marginTop: scale(4),
  },
  linkBtnText: {
    color: colors.raleysRed,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: scale(16),
  },
  unavailableView: {
    flexDirection: 'row',
    paddingBottom: screenHeight < 740 ? 4 : 0,
    paddingLeft: '12%',
    marginTop: scale(4),
  },
  lowStockText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    lineHeight: scale(18),
    color: colors.red,
  },
  itemSeperator: {
    width: '100%',
    alignSelf: 'center',
    height: 1.5,
    backgroundColor: colors.darkCream,
  },
});

export default ProductDetailsScreen;

import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import colors from '@config/colors';
import { AppStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { appStyles } from '@styles';
import { scale } from '@styles/constants';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button, Image, Text } from '../index';

const ContentCardComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const navigation = useNavigation();
  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { title, image: imageRef, subtitle, content, flyer: flyerRef, linkText, url, flyerAlt, ImageAlt } = document.getData();

  const image = imageRef && page.getContent<ImageSet>(imageRef);
  const flyer = flyerRef && page.getContent<ImageSet>(flyerRef);

  return (
    <>
      <View style={styles.container} testID="ContentCardComponent">
        <View style={styles.imageContainer}>
          {image && (
            <Image accessibilityLabel={ImageAlt} style={styles.image} source={{ cache: 'web', uri: image?.getOriginal()?.getUrl() }} resizeMode="contain" />
          )}
          {flyer && (
            <Image accessibilityLabel={flyerAlt} style={styles.flyer} source={{ cache: 'web', uri: flyer?.getOriginal()?.getUrl() }} resizeMode="contain" />
          )}
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.eyebrowLeftRed} testID="ContentCardComponentTitle">
            {title}
          </Text>
          <Text style={styles.h2} testID="ContentCardComponentSubtitle">
            {subtitle}
          </Text>
          <Text style={styles.bodyLargeLeft} testID="ContentCardComponentDescription">
            {content}
          </Text>
          {/* TODO:// do we wnat hard coded action and button text? */}
          {linkText && (
            <Button
              type="secondary"
              size="small"
              title={linkText}
              buttonStyle={styles.buttonStyle}
              onPress={() => navigation.dispatch(StackActions.push(AppStackRoutes.WebBrowser, { url: url, title: 'Feedback' }))}
            />
          )}
        </View>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
    paddingHorizontal: 10,
    backgroundColor: colors.cream,
    flexDirection: 'column',
  },
  imageContainer: {
    marginTop: 50,
    paddingHorizontal: 5,
  },
  flyer: {
    position: 'absolute',
    width: 150,
    height: 100,
    top: 0,
    zIndex: -1,
  },
  image: {
    maxWidth: '85%',
    height: 400,
    left: 55,
  },
  contentContainer: { paddingHorizontal: 10 },
  eyebrowLeftRed: {
    ...appStyles.eyebrowCenterRed,
    textAlign: 'left',
    letterSpacing: 1.5,
  },
  h2: {
    ...appStyles.h2,
    textAlign: 'left',
    paddingTop: 5,
  },
  bodyLargeLeft: {
    ...appStyles.bodyLargeLeft,
    paddingTop: 5,
    fontSize: scale(19),
  },
  buttonStyle: { marginTop: 50, alignSelf: 'flex-start', width: '80%' },
});

export default ContentCardComponent;

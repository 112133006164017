import { useTheme } from '@react-navigation/native';
import React from 'react';
import { ImageBackground, ImageBackgroundProps, StyleSheet } from 'react-native';

const Background: React.FC<ImageBackgroundProps> = ({ source, resizeMode, children, style, ...props }) => {
  const { colors } = useTheme();
  return (
    <ImageBackground
      source={source}
      resizeMode={resizeMode}
      style={[imageBackgroundStyles.background, { backgroundColor: colors.background }, style]}
      {...props}>
      {children}
    </ImageBackground>
  );
};

const imageBackgroundStyles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
  },
  container: {
    flex: 1,
    padding: 20,
    width: '100%',
    maxWidth: 340,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default Background;

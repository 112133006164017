import ListItem from '@components/lists/ListItem';
import ListItemSeparator from '@components/lists/ListItemSeparator';
import { AutoSuggestResult } from '@fieldera-raleys/client-common';
import { useAnalytics, useEffectOnce } from '@hooks';
import { appStyles } from '@styles';
import { screenHeight, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { FlatList, Keyboard, KeyboardEvent, ListRenderItemInfo, StyleSheet, View } from 'react-native';
import Text from './../Text';

type AutoSuggestProps = {
  searchText: string | undefined;
  data: AutoSuggestResult[];
  onSelect: (text: string, category: string) => void;
  tabBarHeight?: number;
};

const AutoSuggest: FC<AutoSuggestProps> = ({ searchText, data, onSelect, tabBarHeight = 60 }): JSX.Element => {
  const [autoSuggestHeight, setAutoSuggestHeight] = useState(screenHeight - (screenHeight < 740 ? screenHeight * 0.2 : screenHeight * 0.21) - tabBarHeight);
  const { trackSearchSuggestEvent } = useAnalytics();
  const onKeyboardDidShow = (e: KeyboardEvent) => {
    setAutoSuggestHeight(screenHeight - e.endCoordinates.height);
  };

  const onKeyboardDidHide = () => {
    setAutoSuggestHeight(screenHeight - tabBarHeight);
  };

  useEffectOnce(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow);
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide);

    // cleanup listeners
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  });

  const renderItem = ({ item, index }: ListRenderItemInfo<AutoSuggestResult>) => (
    <ListItem
      key={index}
      testID={`auto-suggest-${item.query}`}
      title={highlightSearchSubText(item.displayText)}
      subTitle={`in ${item.name}`}
      onPress={() => {
        onSelect(item.displayText, item.value);
        trackSearchSuggestEvent({ aq: searchText, q: item.displayText });
      }}
      titleStyle={styles.titleStyle}
    />
  );

  const highlightSearchSubText = (searchResult: string) => {
    const searchQuery = searchText?.toLowerCase().trim() ?? '';
    const searchTextIndex = searchResult.toLowerCase().indexOf(searchQuery);

    let searchResultSplitArr = [];
    const splitIndex = searchTextIndex + searchQuery.length;
    if (searchTextIndex === -1) {
      searchResultSplitArr = [searchResult];
    }
    if (searchTextIndex === 0) {
      searchResultSplitArr = [searchResult.slice(searchTextIndex, splitIndex), searchResult.slice(splitIndex)];
    } else {
      searchResultSplitArr = [searchResult.slice(0, searchTextIndex), searchResult.slice(searchTextIndex, splitIndex), searchResult.slice(splitIndex)];
    }
    const textChildComponent = searchResultSplitArr.map((item, idx) => {
      if (item === searchQuery) {
        return (
          <Text testID={'bold-sub-text'} style={appStyles.bodySmallLeft} key={`${item}_${idx}`}>
            {item}
          </Text>
        );
      }
      return (
        <Text style={appStyles.bodySmallLight} key={`${item}_${idx}`}>
          {item}
        </Text>
      );
    });
    return <Text>{textChildComponent}</Text>;
  };

  return (
    <View style={[styles.autoSuggestContainer, { height: data.length > 0 ? autoSuggestHeight : 0, bottom: 0 }]}>
      <FlatList
        data={data}
        keyExtractor={(item) => `${item.displayText}-${item.name}`}
        // eslint-disable-next-line react/no-unstable-nested-components
        ItemSeparatorComponent={() => <ListItemSeparator separatorStyle={{ backgroundColor: '#e2deda' }} />}
        bounces={false}
        contentContainerStyle={styles.cardContainer}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  autoSuggestContainer: {
    width: screenWidth,
    marginTop: 10,
    paddingHorizontal: 15,
  },
  cardContainer: {
    overflow: 'hidden',
    paddingBottom: screenHeight * 0.25,
  },

  overlay: {
    width: screenWidth + 50,
    height: screenHeight,
    position: 'absolute',
    bottom: -40,
    left: -50,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 9,
  },
  titleStyle: {
    alignSelf: 'flex-start',
  },
});

export default AutoSuggest;

import { ShoppingListLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { Me } from '@services/commerceTools';
import logger from '@utils/logger';
import { create } from 'zustand';
import { FavoritesStore } from './storeTypes';

const LISTNAME = 'Favorites';
const useFavoritesStore = create<FavoritesStore>()((set, get) => ({
  favorites: undefined,
  initialize: async (priceChannelId?: string, availablityChannelIds?: string[], signal?: AbortSignal): Promise<void> => {
    //TODO:// add logic to verify the channel and only load if channel changes
    let shoppingList = await Me.shoppingListService.getShoppingList({ listName: LISTNAME, priceChannelId, availablityChannelIds }, signal);
    if (shoppingList) {
      set((state) => ({ ...state, favorites: shoppingList }), true);
      return;
    }
    shoppingList = await Me.shoppingListService.createShoppingList({ listName: LISTNAME }, signal);
    if (shoppingList) {
      set((state) => ({ ...state, favorites: shoppingList }), true);
      return;
    }
    if (!signal?.aborted) {
      throw new Error('useFavoritesStore: failed to initialize');
    }
  },
  isFavorite: (productKey: string) => {
    const current = get().favorites;
    return current
      ? current?.lineItems.findIndex((x: ShoppingListLineItem) => {
          return x.variant?.sku === productKey;
        }) > -1
      : false;
  },
  toggleFavorite: async (productKey: string) => {
    const current = get().favorites;
    let updated = current;
    if (current) {
      const lineitem = current.lineItems.find((x: ShoppingListLineItem) => x.variant?.sku === productKey);
      if (!lineitem) {
        updated = await Me.shoppingListService.addToShoppingList(current.id, current.version, productKey);
      } else {
        updated = await Me.shoppingListService.removeFromShoppingList(current.id, current.version, lineitem.id);
      }
      if (updated) {
        set((state) => ({ ...state, favorites: updated }), true);
      } else {
        logger.warn('Favorites undefined');
      }
    } else {
      logger.warn('Favorites List not initialized');
    }
  },
}));

export default useFavoritesStore;

import colors from '@config/colors';
import appStyles from '@styles/appStyles';
import React from 'react';
import { Animated, StyleProp, TextProps, TextStyle } from 'react-native';

type AccountsTitleProps = Omit<TextProps, 'style'> & {
  tintColor?: string;
  style?: Animated.WithAnimatedValue<StyleProp<TextStyle>>;
};

const HeaderTitle = ({ tintColor, children, style, ...rest }: AccountsTitleProps) => {
  return (
    <Animated.Text
      allowFontScaling={false}
      maxFontSizeMultiplier={1}
      minimumFontScale={1}
      accessibilityRole="header"
      aria-level="1"
      numberOfLines={1}
      {...rest}
      style={[appStyles.h3B, { color: tintColor ? tintColor : colors.text }, style]}>
      {children}
    </Animated.Text>
  );
};

export default HeaderTitle;

import { Modal } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useCartStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { getOriginalOrderNumber } from '@utils/orderHelpers';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, Platform, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

export type CartInfoBannerProps = {
  linkText?: string;
  linkAction?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  onTitlePress?: (e: GestureResponderEvent) => void;
};

const CartInfoBanner: FC<CartInfoBannerProps> = ({ linkText, linkAction, containerStyle, textStyle, onTitlePress }) => {
  const { cart, resetMyCart } = useCartStore();
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const { t } = useTranslation('cart');
  const navigation = useNavigation();
  useEffect(() => {
    setShowBanner(getOriginalOrderNumber(cart) ?? false);
  }, [cart]);

  const [showConfirm, setShowConfirm] = useState(false);
  const resetCart = () => {
    setShowConfirm(true);
  };

  const titlePress = (e) => {
    if (onTitlePress) {
      onTitlePress(e);
    } else {
      navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.CartLanding, params: {} });
    }
  };

  const _resetCart = async () => {
    await resetMyCart();
  };

  return showBanner ? (
    <>
      <View style={[styles.container, containerStyle]}>
        <TouchableOpacity onPress={(e) => titlePress(e)} style={styles.textWidth}>
          <Text numberOfLines={1} style={[appStyles.actionNotice, textStyle]} testID="message">
            {t('checkoutToApplyText')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          testID="linkClick"
          style={styles.link}
          onPress={() => {
            linkAction ? linkAction() : resetCart();
          }}>
          <Text style={[appStyles.actionNoticeLink]} testID="linkText">
            {linkText ? linkText : t('discardModificationsLink')}
          </Text>
        </TouchableOpacity>
      </View>
      <Modal
        visible={showConfirm}
        headerStyle={styles.showModificationHeader}
        title={'Exit Order Modification Mode'}
        cancelButtonOnPress={() => _resetCart()}
        cancelButtonType="secondary"
        cancelButtonText={'Exit'}
        okButtonText={'Edit Order'}
        okButtonOnPress={() => setShowConfirm(false)}
        location="top"
        style={styles.modalHeight}
        titleStyle={{}}
        subTextViewStyle={styles.subTextStyle}>
        <Text style={[styles.textInfo]} testID="showModificationText">
          Any changes made will not be applied.
        </Text>
      </Modal>
    </>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 18,
    paddingHorizontal: 16,
    width: screenWidth,
    backgroundColor: colors.alertBoxGreen,
    // height: 50,
    zIndex: 900,
  },
  textWidth: {
    width: '80%',
  },
  link: {
    width: '20%',
    alignItems: 'center',
  },
  textInfo: {
    fontSize: scale(18),
    color: colors.primary,
    textAlign: 'center',
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitLight,
  },
  subTextStyle: {
    marginVertical: 0,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  touchableIcon: {
    paddingLeft: 7.5,
    alignContent: 'center',
  },
  showModificationHeader: {},
  modalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.3 : screenHeight * 0.34) : screenHeight > 640 ? screenHeight * 0.33 : screenHeight * 0.48,
  },
  comfirmationModalTitle: {
    fontSize: scale(26),
  },
});

export default CartInfoBanner;

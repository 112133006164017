import colors from '@config/colors';
import { addEventListener, NetInfoState } from '@react-native-community/netinfo';
import { containerWidth, screenWidth } from '@styles/constants';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Text from './Text';

const OfflineNotice = (): JSX.Element => {
  const insets = useSafeAreaInsets();
  const [isConnected, setIsConnected] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = addEventListener((state: NetInfoState) => {
      setIsConnected(state.isConnected);
    });
    return () => {
      unsubscribe();
    };
  }, [isConnected]);

  return !isConnected ? (
    <View style={[styles.container, { width: screenWidth, top: insets.top }]}>
      <Text>No Internet Connection</Text>
    </View>
  ) : (
    <></>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.primary,
    height: 50,
    justifyContent: 'center',
    position: 'absolute',
    width: containerWidth,
    zIndex: 1,
  },
});

export default OfflineNotice;

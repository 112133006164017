import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Schedule } from '@fieldera-raleys/client-common/services/brandywine/types';
import { Voucher } from '@fieldera-raleys/client-common/services/brandywine/types/offer';
import { useCancelToken } from '@hooks';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useAppConfigStore, useOffersStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, RefreshControl, ScrollView, StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import { parseSchedule } from '../../utils/helpers';
import DropShadow from '../DropShadow';
import Switch from '../Switch';
import { Button, LoadingScreen } from '../index';

type ToggleItemProps = {
  item: Voucher;
  activatedText?: string;
  activatedTextStyle?: StyleProp<TextStyle>;
};

const ToggleItem: FC<ToggleItemProps> = ({ item, activatedText = 'Applies next time you shop!', activatedTextStyle = {} }): JSX.Element => {
  const EXPIRATION_DATE = new Date(item.EndDate);
  var options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
  };

  const EXP_MONTH = new Intl.DateTimeFormat('en-US', options).format(EXPIRATION_DATE);
  const EXP_DAY = EXPIRATION_DATE.getDate();
  const EXP_YEAR = EXPIRATION_DATE.getFullYear();
  const { acceptedVouchers, unacceptVoucher, acceptVoucher } = useOffersStore();
  const [loading, setLoading] = useState(false);
  var date = item.RedeemedDate ? new Date(item.RedeemedDate) : undefined;
  var day = date?.getDate();
  var month = new Intl.DateTimeFormat('en-US', options).format(date);
  var year = date?.getFullYear();
  // console.log('res:', JSON.stringify(availableVouchers, null, 2));

  const dateDiffInDays = (endDate: Date) => {
    const now = Date.now();
    const end = endDate.getTime();
    const diff = Math.floor((end - now) / (1000 * 60 * 60 * 24));

    return +diff;
  };
  const diff = dateDiffInDays(EXPIRATION_DATE);

  const toggleOffer = async () => {
    setLoading(true);
    if (acceptedVouchers?.some((vo) => vo.ExtPromotionId === item.ExtPromotionId)) {
      //unaccept
      await unacceptVoucher(+item.ExtPromotionId).then(() => {
        setLoading(false);
      });
    } else {
      //accept
      await acceptVoucher(+item.ExtPromotionId).then(() => {
        setLoading(false);
      });
    }
  };

  return (
    <DropShadow style={{ shadowOpacity: false ? 0 : 0.2 }}>
      <View
        style={[
          { backgroundColor: acceptedVouchers?.some((vo) => vo.ExtPromotionId === item.ExtPromotionId) ? colors.sectionHeader : colors.white },
          false ? styles.redeemedBar : styles.toggleBar,
        ]}>
        <Text
          testID="acceptedVouchersTextValue"
          style={[
            acceptedVouchers?.some((vo) => vo.ExtPromotionId === item.ExtPromotionId) && !false ? appStyles.fontMobileH6Red : appStyles.fontMobileH6,
            {
              width: '20%',
              textDecorationLine: false ? 'line-through' : 'none',
              opacity: false ? 0.4 : 1,
            },
          ]}>
          {/* ${item?.Rewards?.find((r) => r.Type === 'Discount')?.Value} */}$ {item?.Amount}
        </Text>
        {item.RedeemedDate ? (
          <Text style={appStyles.fontMobileBodySmallLight} testID="date">
            Redeemed {`${month}/${day}/${year}`}
          </Text>
        ) : (
          <>
            <View
              style={{
                justifyContent: 'space-between',
                width: '60%',
                paddingTop: 4,
              }}>
              {acceptedVouchers?.some((vo) => vo.ExtPromotionId === item.ExtPromotionId) && (
                <Text numberOfLines={1} style={[appStyles.fontMobileBodySmBoldGray, { textAlign: 'right' }, activatedTextStyle]} testID="activatedText">
                  {activatedText}
                </Text>
              )}
              <Text
                testID="daysLeft"
                numberOfLines={1}
                style={[
                  appStyles.fontMobileBodySmallLight,
                  { color: diff <= 30 ? colors.red : colors.darkText, textAlign: 'right' },
                  diff <= 30 ? appStyles.fontMobileBodySmBoldRed : null,
                ]}>
                {/* {'Expires 12/12/22'} */}
                {diff <= 30 ? `${diff} days left! - Expires ${EXP_MONTH}/${EXP_DAY}` : `Expires ${EXP_MONTH}/${EXP_DAY}/${EXP_YEAR}`}
              </Text>
            </View>
            {!loading ? (
              <Switch
                value={acceptedVouchers?.some((vo) => vo.ExtPromotionId === item.ExtPromotionId)}
                onValueChange={toggleOffer}
                testID="acceptedOfferSwitch"
              />
            ) : (
              <ActivityIndicator style={{ paddingHorizontal: 24 }} />
            )}
          </>
        )}
      </View>
    </DropShadow>
  );
};

type SEDollarsProps = {
  activatedText?: string;
  activatedTextStyle?: StyleProp<TextStyle>;
  noRewardMessageType?: 'wallet' | 'cart';
};
// interface PromotionState {
//   isLoading: boolean;
//   isRefreshing: boolean;
//   promotionResults: PagedArray<Promotion>;
// }

const SEDollars = ({ activatedText, activatedTextStyle, noRewardMessageType = 'wallet' }: SEDollarsProps): JSX.Element => {
  const navigation = useNavigation();
  const { availableVouchers, acceptedVouchers, redeemedVouchers, vouchersState, fetchVouchers } = useOffersStore();
  const { getConfigValue } = useAppConfigStore();
  const [voucherDates, setVoucherDates] = useState<string[] | undefined>([]);

  const cancelToken = useCancelToken();

  useEffect(() => {
    const issueSchedule = getConfigValue<{ VoucherIssueSchedule: Schedule[] }>('VoucherIssueSchedule') ?? undefined;
    const arrivalDates = parseSchedule(issueSchedule?.VoucherIssueSchedule ?? []);
    setVoucherDates(arrivalDates?.filter((d) => !dayjs(d).isBefore(dayjs(), 'day')));
  }, [getConfigValue]);

  const refetchVouchers = () => {
    fetchVouchers(cancelToken);
  };

  return (
    <ScrollView style={{ flexGrow: 1 }} refreshControl={<RefreshControl refreshing={false} onRefresh={refetchVouchers} />}>
      {vouchersState === 'loading' ? (
        <LoadingScreen />
      ) : (
        <>
          <View style={styles.earnedDollarsSection}>
            <View style={styles.dollarsHeader}>
              {noRewardMessageType === 'cart' ? (
                <Text style={[styles.dollarsHeaderText, appStyles.bodySmall]}>Would you like to use your available SE Dollars in this transaction?</Text>
              ) : (
                <Text style={[styles.dollarsHeaderText, appStyles.bodySmallLeft]}>Earned Something Extra Dollars</Text>
              )}
              {/* <TouchableOpacity onPress={() => setShowFutureFeature(true)}>
                    <Icon name={'sort-icon'} size={20} />
                  </TouchableOpacity> */}
            </View>
            {availableVouchers?.length || acceptedVouchers?.length ? (
              <>
                <FlatList
                  data={(availableVouchers ?? [])
                    .concat(acceptedVouchers ?? [])
                    .filter((v, i, va) => va.findIndex((vv, vi) => vv.ExtPromotionId === v.ExtPromotionId && vi !== i) === -1)
                    .sort((v1, v2) =>
                      v1.EndDate > v2.EndDate
                        ? 1
                        : v1.EndDate < v2.EndDate
                        ? -1
                        : v1.Amount > v2.Amount
                        ? 1
                        : v1.Amount < v2.Amount
                        ? -1
                        : v1.ExtPromotionId > v2.ExtPromotionId
                        ? 1
                        : v1.ExtPromotionId < v2.ExtPromotionId
                        ? -1
                        : 0,
                    )}
                  keyExtractor={(_, i) => i.toString()}
                  renderItem={({ item }) => {
                    return <ToggleItem item={item} activatedText={activatedText} activatedTextStyle={activatedTextStyle} />;
                  }}
                  contentContainerStyle={styles.dollarsContainer}
                  // refreshing={vouchersState === OfferStatus.LOADING}
                  // onRefresh={refetchVouchers}
                />
                {noRewardMessageType === 'cart' ? (
                  <>
                    <Text style={[appStyles.bodyXsCenter, { width: '80%', alignSelf: 'center' }]} testID="partialVoucherMsg">
                      Please Note, partial voucher use is not accepted. If the
                    </Text>
                    <Text style={[appStyles.bodyXsCenter, { width: '80%', alignSelf: 'center' }]}>voucher exceeds the total transaction amount, it will</Text>
                    <Text style={[appStyles.bodyXsCenter, { width: '80%', alignSelf: 'center' }]}>not be applied.</Text>
                  </>
                ) : (
                  <Text style={[appStyles.bodyXsCenter, { width: '80%', alignSelf: 'center' }]} testID="somethingExtraDollarsMsg">
                    Something Extra Dollars must be used in their entirety upon redemption on eligibile items
                  </Text>
                )}
              </>
            ) : (
              <>
                {vouchersState === 'error' ? (
                  <View style={[styles.noSEDollarsContainer, { justifyContent: 'center' }]}>
                    <Text style={[appStyles.bodyLargeCenter, { fontFamily: FontFamily.LarsseitBold, width: '85%' }]} testID="technicalIssueMsg">
                      We're sorry, there seems to be a technical problem.
                    </Text>
                    <Text style={[appStyles.bodySmallLight, { marginBottom: 16, marginTop: 24, textAlign: 'center', width: '85%' }]} testID="troubleMsg">
                      The app is having trouble connecting. Unfortunately, SE Dollars cannot be shown at this time.
                    </Text>
                  </View>
                ) : (
                  <View style={styles.noSEDollarsContainer}>
                    {noRewardMessageType === 'cart'
                      ? [
                          <Text key={'mssgkey1'} style={[styles.noDollarsInfo, appStyles.bodySmallBold]}>
                            There are currently no
                          </Text>,
                          <Text key={'mssgkey2'} style={[appStyles.bodySmallBold]}>
                            SE Dollars available
                          </Text>,
                        ]
                      : [
                          <Text key={'mssgkey1'} style={[styles.noDollarsHeader, appStyles.bodySmallLight]} testID="seDollarsMsg">
                            You have no SE Dollars available.
                          </Text>,
                          <Text key={'mssgkey2'} style={[styles.noDollarsInfo, appStyles.bodySmallRed]} testID="earnpointsMsg">
                            Shop to earn points and rewards!
                          </Text>,
                          <Button
                            testID="shopNowBtn"
                            key={'mssgkey3'}
                            type="secondary"
                            buttonStyle={[appStyles.prodListSecondaryButtonSm, { width: '50%' }]}
                            textStyle={{ lineHeight: lineHeight(23), fontSize: scale(14), height: 30 }}
                            title="Shop Now"
                            onPress={() =>
                              navigation.navigate(AppStackRoutes.RootTabs, {
                                screen: RootTabRoutes.Shop,
                                params: { screen: ShopStackRoutes.ShopLanding, params: {} },
                              })
                            }
                          />,
                        ]}

                    <Text style={[appStyles.bodySmallLight, { width: '70%', textAlign: 'center', marginTop: 16 }]}>
                      SE Dollars earned in the current period will be rewarded on
                    </Text>
                    <Text style={[appStyles.bodyCenterRegular, { textDecorationLine: 'none', marginTop: 8 }]}>
                      {voucherDates?.length ? voucherDates[0] : ''}
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>
          {noRewardMessageType === 'cart' ? null : (
            <View style={styles.earnedDollarsSection}>
              <View style={[styles.dollarsHeader, { paddingHorizontal: 32 }]}>
                <Text style={[styles.dollarsHeaderText, appStyles.bodySmallLeft]} testID="redeemedMsg">
                  Redeemed Something Extra Dollars
                </Text>
                <Text style={[styles.redeemedTimeText, appStyles.bodyXsRight]}>Last 12 months</Text>
              </View>
              <FlatList
                data={redeemedVouchers}
                keyExtractor={(_, i) => i.toString()}
                renderItem={({ item }) => {
                  return <ToggleItem item={item} activatedText={activatedText} activatedTextStyle={activatedTextStyle} />;
                }}
                contentContainerStyle={styles.dollarsContainer}
              />
            </View>
          )}
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  dollarsHeader: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    marginTop: 24,
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  dollarsHeaderText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    color: colors.text,
    marginBottom: 4,
    width: '100%',
  },
  noSEDollarsContainer: {
    backgroundColor: colors.white,
    width: '100%',
    height: 250,
    alignItems: 'center',
    padding: 16,
    // marginTop: 8,
    borderRadius: 10,
  },
  noDollarsHeader: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(14),
    marginTop: 12,
    marginBottom: 16,
  },
  noDollarsInfo: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.red,
    fontSize: scale(15),
    marginTop: 8,
    marginBottom: 0,
  },
  earnedDollarsSection: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  redeemedTimeText: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(15),
    color: colors.text,
    marginTop: 2,
  },
  redeemedBarOfferText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(28),
    color: colors.text,
    width: '18%',
    textDecorationLine: 'line-through',
    opacity: 0.3,
  },
  redeemedDateText: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    color: colors.text,
    textAlign: 'right',
  },
  dollarsContainer: {
    paddingBottom: 16,
  },
  toggleBar: {
    flexDirection: 'row',
    width: '100%',
    height: 65,
    borderRadius: 10,
    marginTop: 8,
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  redeemedBar: {
    flexDirection: 'row',
    width: '100%',
    height: 65,
    borderRadius: 10,
    marginTop: 8,
    paddingHorizontal: 16,
    alignItems: 'center',
    backgroundColor: colors.white,
    justifyContent: 'space-between',
  },
});

export default SEDollars;

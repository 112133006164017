import { Store } from '@fieldera-raleys/client-commercetools/schema';
import useCommerceToolsStore from '@store/commerceToolsStore';
import useUserProfileStore from '@store/userProfileStore';

const getStore = (): Store => {
  const { store, defaultStore } = useCommerceToolsStore.getState();
  const selectedStore = store ?? defaultStore;
  if (selectedStore) {
    return selectedStore;
  } else {
    throw new Error('getStore: store not initialized');
  }
};

export const getUserSepiNumber = (): string => useUserProfileStore.getState().userProfile?.sepiNumber?.toString() ?? '';

export const getSelectedStoreKey = () => getStore().key;

/* eslint-disable react-hooks/exhaustive-deps */

import { DropShadow } from '@components';
import SaveBar from '@components/SaveBar';
import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { ShopType, TimeSlot, TimeSlotData } from '@fieldera-raleys/client-common';
import { storeService } from '@services/brandywine';
import { useCartStore } from '@store';
import useShopStore from '@store/shopStore';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import Config from 'react-native-config';
import { getCartLeadTimeMinutes } from '../../utils/orderHelpers';

interface IDateItemProps {
  item: TimeSlot;
  index?: number;
  selectedDate?: TimeSlot;
  setSelectedDate: Dispatch<SetStateAction<TimeSlot>>;
  setSelectedTime: Dispatch<SetStateAction<TimeSlotData>>;
}

interface ITimeItemProps {
  item: TimeSlotData;
  index?: number;
  selectedTime?: TimeSlotData;
  setSelectedTime: Dispatch<SetStateAction<TimeSlotData>>;
}

interface ITimeSlotProps {
  toggleTombstone: () => void;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

const TimeItem: FC<ITimeItemProps> = ({ item, selectedTime, setSelectedTime, index }): JSX.Element => {
  var startTime = item.timeSlotHourStart;
  var endTime = item.timeSlotHourEnd;
  var startMeridiem = startTime === 12 ? ' PM' : ' AM';
  var endMeridiem = endTime === 12 ? ' PM' : ' AM';

  if (startTime >= 13) {
    startTime = startTime - 12;
    startMeridiem = ' PM';
  }
  if (endTime >= 13) {
    endTime = endTime - 12;
    endMeridiem = ' PM';
  }

  const freeSlot = item.timeSlotPrice === 0;

  return (
    <TouchableWithoutFeedback
      testID={`timeSlotButton-${index}`}
      onPress={() => {
        setSelectedTime(item);
      }}>
      <View style={[styles.timeItemWrapper, { backgroundColor: selectedTime?.timeSlotId === item.timeSlotId ? colors.red : colors.white }]}>
        <Text style={[styles.timeItemTime, { color: selectedTime?.timeSlotId === item.timeSlotId ? colors.white : colors.darkText }]} testID="timeItem">
          {startTime}
          {startMeridiem} - {endTime}
          {endMeridiem}
        </Text>
        <Text
          testID="timeslotPrice"
          style={[styles.timeItemCost, { color: selectedTime?.timeSlotId === item.timeSlotId ? colors.white : freeSlot ? colors.red : colors.darkText }]}>
          {freeSlot ? 'FREE' : `$${item.timeSlotPrice}`}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const DateItem: FC<IDateItemProps> = ({ item, selectedDate, setSelectedDate, setSelectedTime, index }): JSX.Element => {
  const displayDate = dayjs(item.timeSlotDate);

  const freeDay = item.timeSlots.every((ts) => {
    return ts.timeSlotPrice === 0;
  });

  return (
    <TouchableWithoutFeedback
      testID={`dateItemButton-${index}`}
      onPress={() => {
        setSelectedDate(item);
        setSelectedTime({
          timeSlotId: '',
          timeSlotHourStart: 0,
          timeSlotHourEnd: 0,
          timeSlotPrice: 0,
          timeSlotAvailableQty: 0,
          timeSlotDeliveryFee: 0,
        });
      }}>
      <View>
        <View style={[styles.dateItemWrapper, { backgroundColor: selectedDate?.timeSlotDate === item.timeSlotDate ? colors.red : colors.white }]}>
          <Text style={[styles.dateItemDay, { color: selectedDate?.timeSlotDate === item.timeSlotDate ? colors.white : colors.darkText }]} testID="day">
            {displayDate.format('ddd')}
          </Text>
          <Text
            style={[styles.dateItemDate, { color: selectedDate?.timeSlotDate === item.timeSlotDate ? colors.white : colors.darkText }]}
            testID="dateAndMonth">
            {displayDate.format('MMM DD')}
          </Text>
        </View>
        {freeDay ? (
          <View style={styles.tinyCapsuleTag}>
            <Text style={styles.tinyCapsuleTagText} testID="freeText">
              FREE
            </Text>
          </View>
        ) : null}
      </View>
    </TouchableWithoutFeedback>
  );
};

const TimeSlotScreen: FC<ITimeSlotProps> = ({ toggleTombstone, setCurrentPage }): JSX.Element => {
  //Data will come from API call dependent on which location was selected
  const { cart } = useCartStore();
  const { setSelectedTimeSlot, selectedStore, selectedShopType, deliveryAddress, selectedTimeSlot } = useShopStore();
  const dateListRef = useRef<FlatList>(null);
  const timeListRef = useRef<FlatList>(null);
  const [loading, setLoading] = useState(true);
  const [offsetMinutes, setOffsetMinutes] = useState(+(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ?? '0'));
  const [timeSlots, setTimeSlots] = useState<TimeSlotData[]>([]);
  const [availableDates, setAvailableDates] = useState<TimeSlot[]>([]);
  const [selectedDate, setSelectedDate] = useState<TimeSlot>({
    timeSlotDate: '',
    timeSlots: [] as TimeSlotData[],
  });
  const [selectedTime, setSelectedTime] = useState<TimeSlotData>({
    timeSlotId: '',
    timeSlotHourStart: 0,
    timeSlotHourEnd: 0,
    timeSlotPrice: 0,
    timeSlotAvailableQty: 0,
    timeSlotDeliveryFee: 0,
  });
  const [maxLeadTime, setMaxLeadTime] = useState<string | undefined>();

  //Setting the time list data after choosing a date
  useEffect(() => {
    if (!selectedDate) {
      return;
    }
    setTimeSlots(selectedDate.timeSlots);
  }, [selectedDate]);

  useEffect(() => {
    if (cart) {
      getLeadTime();
    }
  }, [cart?.lineItems]);

  useEffect(() => {
    if (typeof maxLeadTime !== 'undefined') {
      // wait for getLeadTime to complete
      searchTimes();
    }
  }, [maxLeadTime]);

  useEffect(() => {
    setSelectedDate(availableDates[0]);
    setTimes();
  }, [availableDates]);

  const getLeadTime = async () => {
    const leadTime = await getCartLeadTimeMinutes(cart);
    setOffsetMinutes(Math.max(+(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ?? '0'), leadTime));
    if (leadTime > +(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ?? '0')) {
      if (leadTime > +(Config.LEAD_TIME_MAX_HOURS_TOSHOW ?? '0') * 60) {
        // eslint-disable-next-line no-bitwise
        setMaxLeadTime(`${(leadTime / 60 / 24 + 1) | 0} day`);
      } else {
        // eslint-disable-next-line no-bitwise
        setMaxLeadTime(`${(leadTime / 60) | 0}-hour`);
      }
    } else {
      setMaxLeadTime('');
    }
  };

  const searchTimes = async () => {
    setLoading(true);
    var seedTime = dayjs().format('YYYYMMDDHHmmss');
    var shippingId = selectedShopType === ShopType.PICKUP ? appConstants.PICKUP_SHIPPING_ID : appConstants.DELIVERY_SHIPPING_ID;
    if (selectedStore) {
      const dates = await storeService.getTimeSlotsByStore(selectedStore.number, seedTime, offsetMinutes, shippingId);
      if (dates?.length) {
        // logger.log('AVAIL DATES:', JSON.stringify(dates, null, 2));
        setAvailableDates(dates);
      }
    }
    setLoading(false);
  };

  const saveTime = () => {
    var timeObject: TimeSlot = {
      timeSlotDate: selectedDate.timeSlotDate,
      timeSlots: [selectedTime],
    };
    setSelectedTimeSlot(timeObject);
    setCurrentPage('');
    toggleTombstone();
  };

  const setTimes = () => {
    if (selectedTimeSlot?.timeSlotDate) {
      const selected = availableDates?.find((d) => d.timeSlotDate === selectedTimeSlot?.timeSlotDate);
      const selectedIndex = availableDates?.findIndex((d) => d.timeSlotDate === selectedTimeSlot?.timeSlotDate) ?? 0;
      setSelectedDate(
        selected
          ? selected
          : {
            timeSlotDate: '',
            timeSlots: [] as TimeSlotData[],
          },
      );
      if (selectedIndex > -1 && availableDates.length) {
        dateListRef && dateListRef?.current?.scrollToIndex({ animated: true, index: selectedIndex });
      }
    }
    if (selectedTimeSlot?.timeSlots) {
      setSelectedTime(selectedTimeSlot?.timeSlots[0]);
    }
  };

  const changeStore = () => {
    if (selectedShopType === ShopType.PICKUP) {
      setCurrentPage('FindAStore');
    } else if (selectedShopType === ShopType.DELIVERY) {
      setCurrentPage('Delivery');
    }
  };

  useEffect(() => {
    const selectedTimeIndex = timeSlots?.findIndex((d) => d.timeSlotId === selectedTimeSlot?.timeSlots[0].timeSlotId) ?? 0;
    if (selectedTimeIndex > -1 && timeSlots.length) {
      timeListRef && timeListRef?.current?.scrollToIndex({ animated: true, index: selectedTimeIndex });
    }
  }, [timeSlots]);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="timeSlotChangeStore"
        onPress={() => {
          changeStore();
        }}>
        <Text style={styles.changeStoreText} testID={deliveryAddress?.postalCode ? 'changeDeliveryLocation' : 'changeStore'}>
          {deliveryAddress?.postalCode ? 'Change Delivery Location' : 'Change Store'}
        </Text>
      </TouchableOpacity>
      <Text style={styles.selectTimeText} testID="selectTimeSlot">
        Select Time Slot
      </Text>
      {maxLeadTime && maxLeadTime !== '' ? ( // to be formal on empty string as initialized but without a message
        <Text style={[styles.cateringHighlight, { fontFamily: FontFamily.LarsseitBold, color: colors.red, marginVertical: 8 }]} testID="maxLeadTime">
          {`Item(s) in your cart require ${maxLeadTime} notice.`}
        </Text>
      ) : (
        <View style={{ height: 30 }} />
        // <Text style={styles.cateringDisclaimer}>
        //   Catering orders require <Text style={styles.cateringHighlight}>24-hour</Text> lead time
        // </Text>
      )}
      <Text style={[styles.cateringDisclaimer, styles.feesDisclaimer]} testID="FeesMayApply">
        Fees may apply
      </Text>
      <View style={selectedTime.timeSlotId ? styles.padBottom : null}>
        {loading ? <ActivityIndicator style={styles.activityIndicator} /> : null}
        <DropShadow style={[styles.dropShadow]}>
          <View style={styles.hideTop} />
          <View style={[styles.dateListsContainer]}>
            {availableDates && (
              <FlatList
                onScrollToIndexFailed={(err) => {
                  const wait = new Promise((res) => setTimeout(res, 200));
                  wait.then(() => {
                    dateListRef.current?.scrollToIndex({ index: err.index, animated: true });
                  });
                }}
                horizontal
                initialNumToRender={50}
                ref={dateListRef}
                data={availableDates}
                extraData={availableDates}
                scrollIndicatorInsets={{ right: 5 }}
                bounces={false}
                keyExtractor={(_, index) => index.toString()}
                renderItem={({ item, index }) => {
                  return <DateItem selectedDate={selectedDate} setSelectedDate={setSelectedDate} setSelectedTime={setSelectedTime} item={item} index={index} />;
                }}
              />
            )}
          </View>
        </DropShadow>
        <View style={[styles.timeListsContainer]}>
          {timeSlots && (
            <FlatList
              onScrollToIndexFailed={(err) => {
                const wait = new Promise((res) => setTimeout(res, 200));
                wait.then(() => {
                  timeListRef.current?.scrollToIndex({ index: err.index, animated: true });
                });
              }}
              ref={timeListRef}
              initialNumToRender={50}
              data={timeSlots}
              extraData={timeSlots}
              contentContainerStyle={[styles.timeListContainer, styles.timeListContentContainerStyle]}
              scrollIndicatorInsets={{ right: 5 }}
              bounces={false}
              keyExtractor={(_, index) => index.toString()}
              renderItem={({ item, index }) => {
                return <TimeItem selectedTime={selectedTime} setSelectedTime={setSelectedTime} item={item} index={index} />;
              }}
            />
          )}
        </View>
      </View>
      {selectedTime.timeSlotId && timeSlots.length > 0 ? (
        <View style={[styles.saveBarSet]} pointerEvents={'box-none'}>
          <SaveBar disabled={loading} onPress={saveTime} testID={'save'} />
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: '85%',
    alignItems: 'center',
    paddingTop: Platform.OS === 'ios' ? 20 : 10,
    transform: [{ translateX: -11 }],
  },
  hideTop: {
    width: screenWidth,
    height: 2,
    backgroundColor: colors.cream,
    position: 'absolute',
    top: -1,
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    height: '70%',
  },
  changeStoreText: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  selectTimeText: {
    color: colors.darkText,
    fontFamily: 'Aleo-Bold',
    fontSize: scale(24),
    textAlign: 'center',
    lineHeight: lineHeight(24),
    marginTop: Platform.OS === 'ios' ? 20 : 10,
  },
  cateringDisclaimer: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Light',
    fontSize: scale(15),
    lineHeight: lineHeight(25),
    textAlign: 'left',
    marginTop: Platform.OS === 'ios' ? 2 : 0,
  },
  feesDisclaimer: {
    marginVertical: 0,
    lineHeight: scale(15),
    alignSelf: 'center',
  },
  cateringHighlight: {
    color: colors.darkText,
    fontWeight: '700',
  },
  dateListsContainer: {
    flexDirection: 'row',
    width: screenWidth,
    height: 81,
    marginTop: 15,
    paddingLeft: 2,
  },
  timeListsContainer: {
    flexDirection: 'row',
    width: screenWidth,
    height: Platform.select({ ios: '75%', android: '70%' }),
    marginTop: 0,
    paddingBottom: Platform.OS === 'ios' ? (screenHeight < 740 ? screenHeight * 0.1 : 50) : screenHeight < 641 ? 35 : 0,
    paddingLeft: 2,
  },
  padBottom: {
    //screenHeight * 0.09 === SaveBar height
    paddingBottom: screenHeight < 740 ? screenHeight * 0.125 + screenHeight * 0.09 : 50 + screenHeight * 0.09,
  },
  dateListContainer: {
    width: screenWidth * 0.33,
    borderColor: colors.lineSeparator,
  },
  dateItemWrapper: {
    width: 64,
    height: 64,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.lineSeparator,
    margin: 7,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'transparent',
  },
  dateItemDay: {
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(15),
    lineHeight: lineHeight(15),
  },
  dateItemDate: {
    fontFamily: 'Larsseit-Light',
    fontSize: scale(15),
    lineHeight: lineHeight(15),
  },
  timeListContainer: {
    // width: screenWidth,
    borderColor: colors.lineSeparator,
  },
  timeItemWrapper: {
    paddingHorizontal: 15,
    width: '100%',
    height: screenHeight * 0.09,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    flexDirection: 'row',
  },
  timeItemTime: {
    color: colors.darkText,
    fontWeight: '700',
    fontSize: scale(15),
    lineHeight: lineHeight(15),
  },
  timeItemCost: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Light',
    textAlign: 'left',
    fontSize: scale(15),
    lineHeight: lineHeight(15),
  },
  saveBarSet: {
    position: 'absolute',
    width: screenWidth,
    height: screenHeight,
    right: -1.3,
    bottom:
      Platform.OS === 'web'
        ? screenHeight * 0.405
        : Platform.OS === 'ios'
          ? screenHeight < 740
            ? -screenHeight * 0.1
            : -screenHeight * 0.095
          : screenHeight < 641
            ? -screenHeight * 0.0678
            : -screenHeight * 0.0735,
  },
  timeListContentContainerStyle: {
    paddingBottom: Platform.select({
      android: screenHeight > 740 ? screenHeight * 0.09 : screenHeight * 0.15,
      ios: screenHeight > 740 ? screenHeight * 0.03 : screenHeight * 0.05,
    }),
  },
  tinyCapsuleTag: {
    position: 'absolute',
    top: 0,
    right: 22,
    width: 40,
    height: 16,
    borderRadius: 8,
    backgroundColor: colors.red,
  },
  tinyCapsuleTagText: {
    color: colors.white,
    textAlign: 'center',
    lineHeight: lineHeight(12),
    fontSize: scale(11),
  },
  dropShadow: {
    width: screenWidth,
    justifyContent: 'center',
    backgroundColor: colors.cream,
  },
});

export default TimeSlotScreen;

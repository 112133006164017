import RedeemProgressBar from '@components/RedeemProgressBar';
import Text from '@components/Text';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { TourKey } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types/offer';
import { AppStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useOffersStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, GestureResponderEvent, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';
import DropShadow from '../DropShadow';
import Icon from '../Icon';

type OfferCardHProps = {
  item: Promotion;
  index: number;
  calledFrom?: string;
  onShowEligibleItems?: (e: GestureResponderEvent) => void;
};

const OfferCardH: FC<OfferCardHProps> = ({ item, index, calledFrom, onShowEligibleItems }): JSX.Element => {
  const OFFER_IMG = item.QualifiedImageUrl ?? '';
  var options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
  };
  const EXPIRATION_DATE = new Date(item.EndDate);
  const EXP_MONTH = new Intl.DateTimeFormat('en-US', options).format(EXPIRATION_DATE);
  const EXP_DAY = EXPIRATION_DATE.getDate();
  const EXP_YEAR = EXPIRATION_DATE.getFullYear().toString().substring(2, 4);
  const { acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons, acceptOffer, unacceptOffer, acceptCoupon } = useOffersStore();
  // const [clipped, setClipped] = useState(true);
  const [clipping, setClipping] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigation = useNavigation();
  // const [productLocation, setProductLocation] = useState('');

  const { TourGuideZone } = useTourGuideController(TourKey.SEHomeScreen);
  const { t } = useTranslation('tourGuide');

  const acceptedOffers = acceptedSomethingExtraOffers.concat(acceptedWeeklyExclusiveOffers).concat(acceptedDigitalCoupons);

  // useEffect(() => {
  //   const fetchProductLocation = async (sku: string) => {
  //     const prod = await productService.getProductBySku(sku);
  //     if (prod.masterData.current) {
  //       const aisleBayBin = getProductAisleBayBin(prod.masterData.current);

  //       setProductLocation(aisleBayBin);
  //     }
  //   };
  //   if (item?.ProductList && item.ProductList.length === 1) {
  //     fetchProductLocation(item.ProductList[0]);
  //   }
  // }, [item.ProductList]);

  const handleShowEligibleItems = (e: GestureResponderEvent) => {
    // navigation.navigate(AppStackRoutes.RootTabs, {
    //   screen: RootTabRoutes.Rewards,
    //   params: {
    //     screen: RewardsStackRoutes.SEOfferDetails,
    //     params: { offerId: item.OfferId, isClipped: acceptedOffers?.some((o) => o.OfferId === item.OfferId) },
    //   },
    // });
    if (onShowEligibleItems) {
      onShowEligibleItems(e);
    }
    navigation.navigate(AppStackRoutes.OfferDetails, {
      offerId: Number(item.ExtPromotionId),
      percentComplete: Math.max(Math.min(100, item.PercentComplete ?? 0), 0),
      percentCompleteMessage: item.PercentCompleteMessage ?? '',
      // isClipped: item.IsAccepted,
      isClipped: acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId),
      calledFrom: calledFrom,
    });
  };

  const isNotAllowUnclip = item.ExtPromotionTypeCode === 'DFEE' || item.ExtPromotionTypeCode === 'SFEE';
  const showAsAccepted = (p: Promotion) => {
    if (p.AutoApply || (p.IsPromoCodePromo && p.IsAccepted)) {
      return true;
    }
    return false;
  };

  const handleClip = async () => {
    setClipping(true);
    setDisabled(true);

    const isDigitalCoupon = (item.PromotionCode ?? '').toUpperCase().startsWith('INMAR');
    if (acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId)) {
      if (!isNotAllowUnclip) {
        if (!(isDigitalCoupon || calledFrom?.toLowerCase() === 'digitalcoupons')) {
          await unacceptOffer(+item.ExtPromotionId);
        }
      }
    } else {
      if (calledFrom?.toLowerCase() === 'digitalcoupons') {
        await acceptCoupon(+item.ExtPromotionId);
      } else {
        await acceptOffer(+item.ExtPromotionId);
      }
    }

    setClipping(false);
    setDisabled(false || (calledFrom?.toLowerCase() === 'digitalcoupons' && acceptedOffers.some((o) => +o.ExtPromotionId === +item.ExtPromotionId)));
  };

  const fallbackImage = assets.imageComingSoon;
  const getImageSource = () => {
    let url;
    if (OFFER_IMG && OFFER_IMG) {
      url = { cache: 'default', uri: `${OFFER_IMG.indexOf('//') === 0 ? 'https:' + OFFER_IMG : OFFER_IMG}` };
    } else {
      url = fallbackImage;
    }
    return url;
  };

  return (
    <DropShadow style={styles.shadow}>
      <TouchableOpacity
        onPress={handleShowEligibleItems}
        style={[
          styles.productContainer,
          {
            borderStyle:
              item.AutoApply === true || item.IsAccepted === true || acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId)
                ? 'solid'
                : 'dashed',
            // borderColor: item.IsAccepted ? colors.greyBorder : colors.offerBorder,
            // borderWidth: item.IsAccepted ? 0.5 : 1.7,
            borderColor:
              item.AutoApply === true || item.IsAccepted === true || acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId)
                ? colors.greyBorder
                : colors.offerBorder,
            borderWidth:
              item.AutoApply === true || item.IsAccepted === true || acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId) ? 0.5 : 1.7,
          },
        ]}>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              paddingTop: 12,
              flex: 0.435,
            }}>
            <TouchableOpacity key={'image_key_index_' + index} activeOpacity={1} onPress={handleShowEligibleItems} style={styles.imgWrapper}>
              <Image testID="eligibleItems" resizeMode={'contain'} source={getImageSource()} style={styles.image} />
            </TouchableOpacity>
          </View>
          <View style={[styles.itemInfo, { flex: 0.65 }]}>
            {/* {item.IsAccepted ? ( */}
            {((isNotAllowUnclip && item.IsAccepted) || showAsAccepted(item)) && (
              <>
                {acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId) ? (
                  <>
                    {clipping ? (
                      <View style={styles.clipIndicator}>
                        <ActivityIndicator />
                      </View>
                    ) : (
                      <TouchableOpacity disabled={disabled} style={[styles.clipped]} onPress={handleClip}>
                        <View style={styles.clippedWrapper}>
                          <Text style={styles.clippedText}>Clipped!</Text>
                          <Icon name={'circle-check'} fill={colors.clippedGreen} stroke={colors.white} strokeSecondary={colors.white} size={32} />
                        </View>
                      </TouchableOpacity>
                    )}
                  </>
                ) : (
                  <>
                    {clipping ? (
                      <View style={{ position: 'absolute', top: 12, right: -2 }}>
                        <ActivityIndicator />
                      </View>
                    ) : (
                      <TouchableOpacity onPress={handleClip} style={styles.addButton}>
                        <TourGuideZone text={t('tgOfferCardTxt')} zone={1} shape={'circle'} isTourGuide={index === 0}>
                          <Icon name={'circle-scissor'} stroke={'none'} size={46} />
                        </TourGuideZone>
                      </TouchableOpacity>
                    )}
                  </>
                )}
              </>
            )}
            {item.Headline && (
              <Text numberOfLines={2} style={[styles.itemOfferText]}>
                {item.Headline}
              </Text>
            )}
            {item.SubHeadline && (
              <Text numberOfLines={2} style={[styles.itemNameText]}>
                {item.SubHeadline}
              </Text>
            )}
            <Text style={[styles.itemDateText]}>{`Expires ${EXP_MONTH}/${EXP_DAY}/${EXP_YEAR}`}</Text>
            {/* {item?.ProductList && item?.ProductList.length === 1 && productLocation ? (
              <View style={styles.itemLocationWrapper}>
                <Icon name={'location-pin'} fill={colors.text} size={15} />
                <Text style={styles.itemLocationText}>{productLocation}</Text>
              </View>
            ) : null} */}
          </View>
        </View>
        <View style={styles.cardBottomView}>
          <View style={styles.cardProgressBarView}>
            {Number(item.PercentComplete ?? 0) >= 100 ? (
              <RedeemProgressBar progress={100} maxValue={100}>
                <Text testID="completed" style={[appStyles.fontMobileBodyXS, styles.font12, { textAlign: 'center' }]}>
                  {item.PercentCompleteMessage ?? 'Add more items to Complete!'}
                </Text>
              </RedeemProgressBar>
            ) : (
              <RedeemProgressBar progress={Number(item.PercentComplete ?? 0)} maxValue={100}>
                <Text testID="add1MoreText" style={[appStyles.fontMobileBodyXS, styles.font12]} numberOfLines={1}>
                  {item.PercentCompleteMessage ?? 'Add more items to Complete!'}
                </Text>
              </RedeemProgressBar>
            )}
          </View>
          <TouchableOpacity onPress={handleShowEligibleItems}>
            <Text style={[appStyles.tinylightredlink]}>Show eligible items</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </DropShadow>
  );
};

const styles = StyleSheet.create({
  productContainer: {
    height: 190,
    width: containerWidth,
    borderWidth: 1.7,
    borderColor: colors.offerBorder,
    borderRadius: 15,
    borderStyle: 'dashed',
    backgroundColor: colors.white,
    flexDirection: 'column',
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1.41,
    elevation: 2,
  },
  clipIndicator: {
    alignItems: 'flex-end',
    width: '100%',
    marginRight: -8,
    height: 30,
  },
  clippedWrapper: {
    flexDirection: 'row',
  },
  clippedText: {
    color: colors.clippedGreen,
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(16),
    lineHeight: lineHeight(22),
    marginRight: 4,
    paddingTop: 4,
  },
  addButton: {
    alignItems: 'flex-end',
    width: '100%',
    marginRight: -24,
    height: 30,
  },
  clipped: {
    alignItems: 'flex-end',
    marginRight: -10,
    height: 30,
  },
  itemInfo: {
    justifyContent: 'space-evenly',
    height: 150,
    paddingHorizontal: 12,
    flexDirection: 'column',
  },
  itemButtonWrapper: {
    alignItems: 'center',
    paddingBottom: 12,
  },
  itemOfferText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.primary,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'left',
    marginVertical: -6,
  },
  itemNameText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(12),
    textAlign: 'left',
    lineHeight: lineHeight(16),
    marginVertical: -6,
  },
  itemDateText: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(12),
    lineHeight: lineHeight(16),
    textAlign: 'left',
    marginVertical: -6,
  },
  itemLocationText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(15),
    textAlign: 'center',
    marginTop: 1,
    marginLeft: 5,
  },
  itemLocationWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: -4,
  },
  clipButton: {
    marginTop: 0,
    minWidth: 125,
    maxWidth: 150,
    height: 34,
    alignSelf: 'center',
    paddingBottom: 0,
    paddingTop: -4,
  },
  progressBarContainer: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 8,
    marginBottom: -24,
  },
  progressText: {
    fontSize: scale(12),
    textAlign: 'center',
  },
  imgWrapper: {
    width: 114,
    height: 114,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  image: {
    width: 100 - 5,
    height: 100,
  },
  cardProgressBarView: {
    width: screenWidth * 0.4,
    height: screenHeight * 0.032,
  },
  cardBottomView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 14,
    marginRight: 14,
  },
  font12: {
    width: screenWidth * 0.48,
    height: scale(24),
    marginTop: 5,
  },
});

export default OfferCardH;

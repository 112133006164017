import { Image, Screen } from '@components';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';

type MaintenanceScreenProps = {
  mode: 'PlannedMaintenance' | 'UnplannedMaintenance';
};

const MaintenanceScreen = ({}: MaintenanceScreenProps) => {
  const { t } = useTranslation('maintenanceScreen');
  return (
    <Screen>
      <View style={styles.container}>
        <Text style={styles.messageHeader}>{t('siteMaintenanceTitle')}</Text>
        <View style={styles.messageBodyView}>
          <Text style={styles.messageBody}>{t('siteMaintenanceMessage')}</Text>
          <Text style={styles.errorDesc}>{t('siteMaintenanceSubMessage')}</Text>
        </View>
        <Image resizeMode={'contain'} source={require('../../assets/images/quad-logo.png')} style={[styles.image]} />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageHeader: {
    color: colors.primary,
    fontSize: scale(30),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(34),
  },
  messageBody: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'center',
  },
  errorDesc: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'center',
    marginTop: scale(24),
  },
  messageBodyView: {
    marginTop: scale(24),
    justifyContent: 'center',
    alignItems: 'center',
    width: screenWidth * 0.8,
  },
  image: {
    marginTop: scale(40),
    width: screenWidth * 0.9,
    height: scale(25),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default MaintenanceScreen;

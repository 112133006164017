import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { DropShadow, Icon, LoadingScreen, NavigationHeader, Screen, TextInput } from '@components';
import { mapping } from '@components/bloomreach/mapping';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useBrxmPage } from '@hooks';
import { AppStackRoutes, HelpCenterStackRoutes, HelpCenterStackScreenProps } from '@navigation/routes';
import appStyles from '@styles/appStyles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../../contexts';
import { ChatLiveAgentIcon } from './HelpCenterScreen';

interface IHeaderItemProps {
  searchText: string | undefined;
  setSearchText: (text: string) => void;
  onPress: () => void;
}

export const HelpCenterHeader: FC<IHeaderItemProps> = ({ searchText, setSearchText, onPress }): JSX.Element => {
  const { t } = useTranslation('helpCenter');
  const { showAlertModal } = useCustomModalContext();
  return (
    <View style={styles.headerNextIconView}>
      <View style={styles.searchBarWrapper}>
        <TouchableOpacity style={styles.iconWrapper}>
          <Icon name="search-icon" size={22} style={appStyles.icon} testID="searchIconList" />
        </TouchableOpacity>
        <TextInput
          testID="helpCenterTextInputList"
          style={[appStyles.formFieldText, styles.searchTextInput]}
          placeholder={t('placeholder')}
          onChangeText={setSearchText}
          onSubmitEditing={({ nativeEvent: { text } }) => {
            if (!text) {
              return;
            }
          }}
          onFocus={() => showAlertModal()}
          value={searchText}
          placeholderTextColor={Platform.select({
            android: colors.darkCream,
          })}
        />
      </View>
      <DropShadow>
        <TouchableOpacity style={styles.contactIconView} onPress={onPress}>
          <Icon size={35} style={styles.smallIcon} stroke={colors.red} name={'contact-icon'} testID="contactIcon" />
        </TouchableOpacity>
      </DropShadow>
    </View>
  );
};

type HelpTopicsListProps = HelpCenterStackScreenProps<HelpCenterStackRoutes.HelpTopicsList>;

const HelpTopicsListScreen = ({ navigation }: HelpTopicsListProps) => {
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  const onButtonPress = () => {
    navigation.navigate(AppStackRoutes.LiveAgent);
  };

  return (
    <>
      {!brxmPage || !brxmConfiguration ? (
        <LoadingScreen />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          <Screen style={utilityStyles.pb0}>
            <NavigationHeader next={<ChatLiveAgentIcon onPress={onButtonPress} />} subStyle={[styles.navSubHeader, utilityStyles.pt2]} style={{}} />
            <ScrollView
              contentContainerStyle={styles.contentStyle}
              nestedScrollEnabled={true}
              bounces={true}
              keyboardShouldPersistTaps={'always'}
              showsVerticalScrollIndicator={false}
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
              <BrComponent path="body" />
            </ScrollView>
          </Screen>
        </BrPage>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    overflow: 'hidden',
  },
  header: {
    marginTop: 10,
    paddingBottom: 10,
  },
  contentStyle: {
    height: 'auto',
    paddingBottom: 75,
  },
  paddingHorizantal: {
    paddingHorizontal: 28,
  },
  headerNextIconView: {
    flexDirection: 'row',
    height: screenHeight * 0.064,
    width: screenWidth * 0.84,
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  navSubHeader: {
    alignItems: 'flex-start',
    minHeight: screenHeight * 0.064,
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  dropShadow: {
    width: screenWidth,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.cream,
    borderBottomWidth: 0,
    marginLeft: -20,
    marginTop: 0,
    ...Platform.select({
      ios: {
        height: 2,
      },
      android: {
        height: 0,
      },
      default: {
        height: 2,
      },
    }),
  },
  listHeading: {
    marginTop: 15,
    marginBottom: -20,
  },
  horizantalListContainer: {
    width: screenWidth * 1.41,
  },
  contentBox: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    width: 85,
  },

  alignLeft: {
    alignItems: 'flex-start',
  },
  searchBarWrapper: {
    width: screenWidth * 0.7,
    alignContent: 'center',
    justifyContent: 'space-between',
    marginLeft: 12,
    marginBottom: 40,
    marginTop: Platform.select({ android: 5, ios: screenHeight < 740 ? 0 : 5 }),
    backgroundColor: colors.white,
    flexDirection: 'row',
    borderRadius: 12.5,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderStyle: 'solid',
    height: 45,
  },
  searchTextInput: {
    alignSelf: 'center',
    padding: 10,
    width: '70%',
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
    flex: 1,
    color: colors.text,
  },
  iconWrapper: {
    padding: 5,
    paddingBottom: 5,
    alignSelf: 'center',
  },
  listItem: {
    borderBottomWidth: 0.5,
    borderColor: colors.sectionBorder,
    paddingVertical: 14,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  item: {
    width: screenWidth * 0.7,
  },
  contactIconView: {
    marginTop: 0,
    width: 50,
    height: 50,
    marginLeft: 10,
  },
  smallIcon: {
    height: 15,
    width: 15,
    marginTop: Platform.select({ android: 11, ios: screenHeight < 740 ? 6 : 11 }),
  },
});

export default HelpTopicsListScreen;

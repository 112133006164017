import { Carousel, LoadingScreen, ProductCard } from '@components';
import Text from '@components/Text';
import withShopNavigator from '@components/hoc/withShopNavigator';
import { Image } from '@components/image';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { FileType, ProductCollection } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCancelToken } from '@hooks';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { productService } from '@services/brandywine';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, screenHeight, screenWidth } from '@styles/constants';
import { getProductsfromCommerceTools } from '@utils/productHelper';
import React, { useEffect, useState } from 'react';
import { FlatList, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

type CollectionListingScreenProps = ShopStackScreenProps<ShopStackRoutes.CollectionListing>;

export type Collection = {
  id: string;
  // ItemCount = 0 ==> Type: Product
  // ItemCount > 0 ==> Type: Weekly Ads Collection
  itemCount: number;
  promotionalImage: string;
  products?: Product[] | [];
};

const SUBCOLLECTION_PAGE_SIZE = 75;

const RenderSubCollection = (collection: Collection): JSX.Element => {
  const [subCollection, setSubCollection] = useState<ProductCollection>();
  const [sortedProducts, setSortedProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelToken = useCancelToken();
  const navigation = useNavigation();

  const SLIDE_GAP = 12;
  const CARD_WIDTH = screenWidth * 0.45;

  const carouselConfig = {
    slidesPerPage: 1,
    nextSlidePartial: CARD_WIDTH * 0.44,
    slideWidth: CARD_WIDTH,
    sgPerLine: 1,
  };

  useEffect(() => {
    const fetchData = async () => {
      const results = await productService.getProductCollectionById(+collection.id, cancelToken);
      setSubCollection(results);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection.id]);

  useEffect(() => {
    setIsLoading(true);

    let productIDs =
      subCollection?.ProductCollectionItems.filter((p) => {
        return !p.ItemProductCollectionId;
      }).map((p) => {
        return p.ExtProductId ?? '';
      }) ?? [];

    const getProductList = async () => {
      let products: Product[] = [];
      for (let i = 0; i < productIDs.length; i = i + SUBCOLLECTION_PAGE_SIZE) {
        const prods = await getProductsfromCommerceTools(productIDs.slice(i, i + SUBCOLLECTION_PAGE_SIZE));
        products = [...products, ...prods];
      }

      const sortOrder = productIDs.reduce((list, x, idx) => list.set(x, idx), new Map<string, number>());
      const sortedProductList = products.sort((a, b) => {
        const aSku: string = a.masterData.current?.masterVariant.sku ?? '';
        const bSku: string = b.masterData.current?.masterVariant.sku ?? '';
        return (sortOrder.get(aSku) ?? -1) - (sortOrder.get(bSku) ?? -1);
      });

      setSortedProducts(sortedProductList);
      setIsLoading(false);
    };

    productIDs && productIDs.length > 0 && getProductList();
  }, [subCollection]);

  const navigateToDetailScreen = (id: number) => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.CollectionListing,
          params: {
            productCollectionId: id,
          },
        },
      }),
    );
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <View style={[styles.header]}>
        <View style={styles.offerHeader}>
          <Text testID="collectionDisplayName" style={[styles.collectionName]} numberOfLines={1}>
            {subCollection?.DisplayName}
          </Text>
          <TouchableOpacity
            style={styles.seeAllLinkWrapper}
            onPress={() => {
              navigateToDetailScreen(subCollection?.ProductCollectionId ?? -1);
            }}>
            <Text testID="seeAll" style={[appStyles.fontMobileBoldLinkLargeRight]}>
              See All {sortedProducts.length < 100 ? sortedProducts.length : ''}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {/* <View style={[styles.carouselWrapper]}> */}
      <Carousel
        windwoSize={10}
        snapToInterval={carouselConfig.slideWidth + SLIDE_GAP}
        carouselstyle={styles.carouselstyle}
        contentContainerStyle={styles.contentContainerStyle}
        entities={(sortedProducts || []).slice(0, 10)}
        renderItem={({ item, index }) => {
          return (
            <View>
              <ProductCard
                product={item}
                productCategory={{ parentId: 'collectionlisting', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
                index={index}
              />
            </View>
          );
        }}
        pagerStyle={styles.pagerStyle}
        pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
        pagerInactiveItemStyle={appStyles.carouselDotsSmall}
        showPager={true}
      />
      {/* </View> */}
    </>
  );
};

const renderListHeaderComponent = (banner: string, title: string, collections?: Collection[]) => {
  return (
    <>
      <>
        {banner ? (
          <View style={[styles.collectionCard]}>
            <Image
              testID="bannerImage"
              style={[styles.collectionImage]}
              resizeMode={'contain'}
              source={{ cache: 'web', uri: banner.indexOf('//') === 0 ? 'https:' + banner : banner }}
            />
          </View>
        ) : (
          <View style={[styles.titleHeader]}>
            <View style={styles.offerHeader}>
              <Text testID="headerTitle" style={[styles.collectionName]} numberOfLines={1}>
                {title}
              </Text>
            </View>
          </View>
        )}
      </>
      {collections && collections.length > 0 && (
        <View>
          <FlatList
            keyExtractor={(_, i) => i.toString()}
            data={collections}
            renderItem={({ item }) => {
              return <RenderSubCollection id={item.id} itemCount={item.itemCount} promotionalImage={item.promotionalImage} />;
            }}
          />
          <View style={styles.emptyView} />
        </View>
      )}
    </>
  );
};

const CollectionListingScreen = withShopNavigator(
  ({ route }: CollectionListingScreenProps) => {
    const { productCollectionId: productCollectionID } = route.params;
    const [productCollection, setProductCollection] = useState<ProductCollection>();
    const [sortedProducts, setSortedProducts] = useState<Product[]>([]);
    const [collections, setCollections] = useState<Collection[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigation = useNavigation();

    const cancelToken = useCancelToken();

    useEffect(() => {
      const fetchData = async () => {
        const results = await productService.getProductCollectionById(+productCollectionID, cancelToken);
        if (results.ProductCollectionItems) {
          setProductCollection(results);
        } else {
          navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.CollectionsLanding } });
        }
      };

      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCollectionID]);

    const bannerURl =
      productCollection?.ProductCollectionFiles?.find((f) => Number(f.FileType.Id) === FileType.MAIN_DETAIL_IMAGE)?.FileUrl.replace('/75/', '/0/') ?? '';

    useEffect(() => {
      let productIDs =
        productCollection?.ProductCollectionItems?.filter((p) => {
          return !p.ItemProductCollectionId;
        }).map((p) => {
          return p.ExtProductId ?? '';
        }) ?? [];

      let cols = productCollection?.ProductCollectionItems?.filter((p) => {
        return p.ItemProductCollectionId && p.ItemCount;
      }).map((product) => {
        //     // Type: Collection
        return { id: product.ItemProductCollectionId?.toString(), itemCount: product.ItemCount, promotionalImage: product.MainImageUrl } as Collection;
      });
      setCollections(cols);

      const getProductList = async () => {
        setIsLoading(true);

        let products: Product[] = [];
        for (let i = 0; i < productIDs.length; i = i + SUBCOLLECTION_PAGE_SIZE) {
          const prods = await getProductsfromCommerceTools(productIDs.slice(i, i + SUBCOLLECTION_PAGE_SIZE));
          products = [...products, ...prods];
        }

        const sortOrder = productIDs.reduce((list, x, idx) => list.set(x, idx), new Map<string, number>());
        const sortedProductList = products.sort((a, b) => {
          const aSku = a.masterData.current?.masterVariant.sku ?? '';
          const bSku = b.masterData.current?.masterVariant.sku ?? '';
          return (sortOrder.get(aSku) ?? -1) - (sortOrder.get(bSku) ?? -1);
        });

        setSortedProducts(sortedProductList);
        setIsLoading(false);
      };

      productIDs && productIDs.length > 0 && getProductList();
    }, [productCollection]);

    return isLoading ? (
      <LoadingScreen />
    ) : (
      <View style={[styles.collectionWrapper]}>
        <FlatList
          contentContainerStyle={[styles.flatListContentContainer]}
          keyExtractor={(_, i) => i.toString()}
          data={sortedProducts}
          ListHeaderComponent={renderListHeaderComponent(bannerURl, productCollection?.DisplayName ?? '', collections)}
          renderItem={({ item, index }) => {
            return (
              <View>
                <ProductCard
                  product={item}
                  productCategory={{ parentId: 'collectionlisting', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
                  index={index}
                />
              </View>
            );
          }}
          refreshing={false}
          numColumns={2}
        />
      </View>
    );
  },
  { screenName: 'collections', showTombstone: false },
);

const styles = StyleSheet.create({
  container: {
    height: screenHeight,
    paddingBottom: screenHeight > 740 ? screenHeight * 0.3 : screenHeight * 0.3,
  },
  collectionWrapper: {
    width: screenWidth,
    flexGrow: 1,
    minHeight: 100,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: colors.sectionPad,
  },
  flatListContentContainer: {
    paddingBottom: Platform.select({
      ios: screenHeight * 0.12,
      android: screenHeight * 0.15,
    }),
  },
  collectionCard: {
    width: screenWidth,
    height: 177,
    justifyContent: 'center',
    alignItems: 'center',
  },
  collectionImage: {
    width: '100%',
    height: '100%',
  },
  productCarouselContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  pagerStyle: {
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  carouselContent: {
    flex: 1,
    width: containerWidth,
    backgroundColor: colors.sectionPad,
  },
  link: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: 16,
    textAlign: 'right',
    lineHeight: lineHeight(22),
  },
  linkContainer: {
    marginLeft: 'auto',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginTop: 20,
    // marginBottom: 12,
  },
  titleHeader: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginTop: 10,
    // marginBottom: 12,
  },
  offerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  seeAllLinkWrapper: {
    marginLeft: 16,
    alignSelf: 'center',
  },
  carouselWrapper: {
    width: screenWidth,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselstyle: {
    marginTop: -5,
  },
  contentContainerStyle: {
    paddingVertical: 0,
  },
  emptyView: {
    height: 20,
  },
  collectionName: {
    ...appStyles.customizeH2,
    flex: 1,
    paddingTop: 12,
  },
});

export default CollectionListingScreen;

import { LineItem, Product, ShoppingListLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { OrderType, ShippingMethodType } from '@fieldera-raleys/client-common';
import { Offer, PaymentProfile, Schedule } from '@fieldera-raleys/client-common/services/brandywine/types';
import cardValidator from 'card-validator';
import dayjs from 'dayjs';
import { Platform, Vibration } from 'react-native';
import ReactNativeHapticFeedback, { HapticFeedbackTypes } from 'react-native-haptic-feedback';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatValue = (value: number | string | undefined): string => {
  const val = parseFloat(('' + value ?? '').replace(/[^0-9\.]/g, ''));
  if (isNaN(val)) {
    return '';
  }
  return currencyFormatter.format(val);
};

export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
});

export const formatDate = (value: number | Date | undefined): string => {
  return dateFormatter.format(value);
};

export const formatPhone = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : defaultValue;
};

export const unformatPhone = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/[^\d\+]/g, '') : defaultValue;
};

export const formatCardExpiryDate = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/\D+/g, '').replace(/(\d{2})(\d{2})/, '$1/$2') : defaultValue;
};

export const formatBirthDay = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/\D+/g, '').replace(/(\d{2})(\d{2})/, '$1/$2') : defaultValue;
};

export const formatDOB = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/\D+/g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3') : defaultValue;
};

export const formatCardNumber = (cardNumber: string | undefined) => {
  if (!cardNumber) {
    return undefined;
  }
  const cardType = cardValidator.number(cardNumber).card;

  if (!cardType) {
    return (cardNumber.match(/\d+/g) || []).join('');
  }
  const maxLength = Math.max(...cardType.lengths);
  const format = buildExpression(cardType.gaps, maxLength);

  if (format) {
    const execResult = format.exec(cardNumber.split(' ').join(''));

    if (execResult) {
      return execResult
        .splice(1)
        .filter((x) => x)
        .join(' ');
    }
  }

  return cardNumber;
};

const buildExpression = (gaps: number[], maxLength: number) => {
  let regex = gaps.reduce((exp, val, idx, arr) => {
    exp += `(\\d{1,${idx ? val - arr[idx - 1] : val}})${idx ? '?' : ''}`;
    return exp;
  }, '');
  regex += `(\\d{1,${maxLength - Math.max(...gaps)}})?`;
  return RegExp(regex);
};

export const hapticNotify = (type: HapticFeedbackTypes) => {
  if (Platform.OS !== 'web') {
    ReactNativeHapticFeedback.trigger(type, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  }
};

type VibrateFeedbackTypes = 'OneSecond' | 'Buzz';

const VibrateFeedbackPattern: { [key in VibrateFeedbackTypes]: number | number[] } = {
  OneSecond: 1000,
  Buzz: [100, 200, 100, 200],
};

export const vibrateNotify = (type: VibrateFeedbackTypes) => {
  if (Platform.OS !== 'web') {
    Vibration.vibrate(VibrateFeedbackPattern[type]);
  }
};

export const filterUnavailableProducts = (products: Product[]) => {
  return products.filter((p) => p?.masterData?.current?.masterVariant.price !== null);
};

export const sortProducts = (products: Product[], catId: string) => {
  return products?.sort(
    (a, b) =>
      +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catId[0])?.orderHint || 0.8) -
      +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catId[0])?.orderHint || 0.8),
  );
};

export const withRetry = async <T>(action: () => Promise<T>, retries = 3): Promise<T> => {
  try {
    return await action();
  } catch (ex: any) {
    if (retries > 0) {
      return withRetry(action, retries - 1);
    } else {
      throw ex;
    }
  }
};

export const toCamelCase = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) {
      return '';
    } // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const toTitleCase = (str: string) => {
  var result = str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', '')); // snake case to camel case
  result = result.replace(/([A-Z])/g, ' $1'); //camel case to title case
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const decimalFormat = (num: number): number => {
  return parseFloat(num.toFixed(2).replace(/\.00$/, ''));
};

export const getPointsStartDates = (endDatesArr: string[]): string[] | undefined => {
  if (!endDatesArr || endDatesArr.length < 1) {
    return undefined;
  }
  var startDates: string[] = [];
  for (const d of endDatesArr) {
    startDates.push(dayjs(d).add(1, 'day').format('M/D/YY'));
  }
  let firstDate = startDates.pop()?.split('/');
  firstDate?.pop();
  firstDate?.push(dayjs().subtract(1, 'year').year().toString().substring(2, 4));
  let first = firstDate?.join('/');
  //TODO: unshift 1st start date
  startDates.unshift(first ?? '');
  return startDates ?? [];
};

export const parseSchedule = (schedule: Schedule[], endScheduleArr: string[] = []) => {
  var formattedDates: string[] = [];

  if (schedule?.length) {
    for (var i = 0; i < schedule.length; i++) {
      var formatted = dayjs().isoWeek(schedule[i].WeekNumber).day(schedule[i].DayNumber).format('MM/DD/YY');
      formattedDates.push(formatted);
    }
    formattedDates.sort((a, b) => {
      var aa = a.split('/'),
        bb = b.split('/');
      aa.unshift(`${aa.pop()}`);
      bb.unshift(`${bb.pop()}`);
      var aaa = aa.join(),
        bbb = bb.join();
      return aaa < bbb ? -1 : aaa > bbb ? 1 : 0;
    });
    if (endScheduleArr.length) {
      var dateMonths = formattedDates.map((fd) => fd.split('/')[0]);
      // for (const d of endScheduleArr) {
      if (dateMonths.some((dm) => +dm - 1 < 1)) {
        let nextDate = dayjs(formattedDates[0]);

        let newNextDate = dayjs().add(1, 'year').isoWeek(nextDate.isoWeek()).day(nextDate.get('day')).format('MM/DD/YY');
        formattedDates.shift();
        formattedDates.push(newNextDate);
      }
      // }
    }
    return formattedDates ?? [];
  }
};

export const sortVouchers = (offers: Offer[]) => {
  var sortedVouchers: Offer[] = offers.slice();
  if (offers?.length) {
    sortedVouchers.sort((a, b) => {
      var aa = dayjs(a?.EndDate),
        bb = dayjs(b?.EndDate);
      var aaa = a?.Rewards?.find((r) => r.Type === 'Discount')?.Value ?? 0,
        bbb = b?.Rewards?.find((r) => r.Type === 'Discount')?.Value ?? 0;
      return aa.diff(bb) || bbb - aaa;
    });
    return sortedVouchers;
  }
  return offers;
};

export const sumProductPriceList = (productList: Product[] | LineItem[] | ShoppingListLineItem[]) => {
  if (!productList) {
    return;
  }
  var value: number = 0;
  if ((<LineItem[]>(<unknown>productList))[0] && (<LineItem[]>(<unknown>productList))[0].variant) {
    (<LineItem[]>(<unknown>productList)).forEach((li) => {
      value += (li?.variant?.price?.value.centAmount ?? 0) / 10 ** (li?.variant?.price?.value?.fractionDigits ?? 2);
    });
    return `$${value.toFixed(2)}`;
  }
};

export function getShippingMethods() {
  var keys = Object.keys(ShippingMethodType);
  return keys.slice(keys.length / 2) as (keyof typeof ShippingMethodType)[];
}

export function getOrderTypes() {
  var keys = Object.keys(OrderType);
  return keys.slice(keys.length / 2) as (keyof typeof OrderType)[];
}

export const toQueryString = (params: Record<string, string | number | undefined>) => {
  return Object.keys(params)
    .map((key) => params[key] && `${key}=${params[key]}`)
    .filter((x) => !!x)
    .join('&');
};

export default Object.freeze({
  SpecialCharacter: /[*|\":<>[\]{}`\\();$@?!^+_%#=~`]/,
  PASSWORD_REGEX: /(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/,
  PHONE_NUMBER_REGEX: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
  EXPIRATION_DATE_REGEX: /([0-9]{2})\/([0-9]{2})/,
  BIRTH_DATE_REGEX: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])$/,
  EMAIL_REGEX:
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
  SENUMBER_REGEX: /^\d{10,12}$/,
  CVV_REGEX: /^[0-9]{3,4}$/,
  DOB_REGEX: /^\d{2}\/\d{2}\/\d{4}$/,
  POSTAL_CODE_REGEX: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
});

export const resizeImage = (imageUrl: string, width: number, height: number): string => {
  var parts = imageUrl.split('/');
  if (parts.length < 9) {
    return imageUrl;
  } else {
    parts[6] = `${width}`;
    parts[7] = `${height}`;
    return parts.join('/');
  }
};

export const isCardExpired = (item: PaymentProfile): boolean => {
  return dayjs(item.CardExpirationDate).add(1, 'month').isBefore(dayjs(), 'day');
};

export const slug = (url: string) => (url ? new URL(url).pathname : url);

export const getHtmlAttribute = (name: string, htmlText: string) => {
  var re = new RegExp(`${name}="([^"]*)`);
  var result = re.exec(htmlText ?? '');

  return result !== null ? result[1] : '';
};

export const findTag = (text: string, tag: string) => {
  var re = new RegExp('tags:([^]*)', 'i');
  var result = re.exec(text ?? '');

  const tags = result !== null ? result[1] : '';
  const tagsArr = tags ? tags.split(',') : null;

  return tagsArr ? tagsArr.find((x) => x.toLowerCase() === `${tag}`) : null;
};

export function linktoNavigation(url: string, linkto: any): any {
  if (url?.length) {
    let path = url.replace(/(^\w+:|^)\/\//, '/');
    path = path.includes('search') ? (path.includes('query') ? path : path.includes('?') ? path + '&query=*' : path + '?query=*') : path;
    path = path.startsWith('/') ? path : '/' + path;
    try {
      return linkto(path);
    } catch (error) {
      console.log('Error: ' + error + ' ' + path);
      path = path.includes('search')
        ? '/search?query=*'
        : path.includes('category') || path.includes('product')
        ? '/categories'
        : path.includes('collection')
        ? '/collections'
        : path.includes('shelf-guide')
        ? '/shelf-guide-list'
        : '/home';
      path = '/search?query=*';
      path = '/categories';
      path = '/collections';
      path = '/home';

      console.log('Routing to Default Path: ' + path);
      return linkto(path);
    }
  } else {
    return null;
  }
}

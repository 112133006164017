import colors from '@config/colors';
import React, { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';
//@ts-ignore
import { CheckBox as NativeCheckBox, CheckBoxProps as NativeCheckBoxProps } from 'react-native-web';

export interface CheckBoxProps extends Omit<NativeCheckBoxProps, 'style' | 'boxType' | 'onCheckColor' | 'onFillColor' | 'onTintColor' | 'tintColors'> {}

const CheckBox = forwardRef<NativeCheckBox, CheckBoxProps>(
  (rest, ref): JSX.Element => (
    <View style={styles.container}>
      <CheckBox
        forwardedRef={ref}
        style={styles.checkBoxView}
        boxType="square"
        onCheckColor="white"
        onFillColor={colors.secondary}
        onTintColor={colors.secondary}
        tintColors={{ true: colors.secondary, false: colors.slateGray }}
        {...rest}
      />
    </View>
  ),
);

const styles = StyleSheet.create({
  container: { justifyContent: 'center', alignItems: 'center', alignSelf: 'center' },
  checkBoxView: {
    transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }],
  },
});

export default CheckBox;

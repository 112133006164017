import Text from '@components/Text';
import { appStyles } from '@styles';
import { lineHeight } from '@styles/constants';
import React, { FC, useState } from 'react';
import { StyleProp, StyleSheet, TextProps, TextStyle, View, ViewStyle } from 'react-native';

interface TextBoxProps {
  text: string;
  textStyle?: StyleProp<TextStyle>;
  boxStyle?: StyleProp<ViewStyle>;
  numberOfChars?: number;
}

const TextBox: FC<TextBoxProps & Pick<TextProps, 'allowFontScaling' | 'maxFontSizeMultiplier'>> = ({
  text,
  textStyle,
  boxStyle,
  numberOfChars = 200,
  allowFontScaling = false,
  maxFontSizeMultiplier = 1,
}) => {
  const [textShown, setTextShown] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const toggleNumberOfChars = () => {
    setTextShown(!textShown);
  };

  const handleLayout = () => {
    var numChars = text.length;
    if (numChars > numberOfChars) {
      setShowLink(true);
    } else {
      setShowLink(false);
    }
  };

  return (
    <View style={boxStyle}>
      <Text
        style={[appStyles.bodyLargeLeft, textStyle]}
        onPress={toggleNumberOfChars}
        onTextLayout={handleLayout}
        allowFontScaling={allowFontScaling}
        maxFontSizeMultiplier={maxFontSizeMultiplier}>
        {textShown ? text.concat(' ') : text.slice(0, numberOfChars).concat(text.length > numberOfChars ? '... ' : '')}
        {showLink ? (
          <Text
            style={[appStyles.fontShowMoreLink, styles.link]}
            onPress={toggleNumberOfChars}
            allowFontScaling={allowFontScaling}
            maxFontSizeMultiplier={maxFontSizeMultiplier}>
            {textShown ? 'Show Less' : 'Show More'}
          </Text>
        ) : (
          <></>
        )}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    lineHeight: lineHeight(18),
  },
});

export default TextBox;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Icon, LoadingScreen } from '@components';
import { mapping } from '@components/bloomreach';
import withShopNavigator from '@components/hoc/withShopNavigator';
import { List, ListItem } from '@components/lists';
import colors from '@config/colors';
import { Filter, ShelfGuideData } from '@fieldera-raleys/client-common';
import useBrxmPage from '@hooks/useBrxmPage';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import brsmService from '@services/brsm/brsmService';
import useSearchStore from '@store/searchStore';
import React, { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ListRenderItemInfo, Platform, RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type ShelfGuideScreenProps = ShopStackScreenProps<ShopStackRoutes.ShelfGuide>;

const ShelfGuideListScreen = withShopNavigator(
  ({}: ShelfGuideScreenProps) => {
    const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
    const insets = useSafeAreaInsets();
    const navigation = useNavigation();
    const [refreshing, setRefreshing] = useState(false);
    const { resetFilters, setAvailableFilters } = useSearchStore();
    const { isLoading, data, refetch, isRefetching, error: shelfGuidesLoadError } = brsmService.useShelfGuidesQuery();
    useErrorHandler(shelfGuidesLoadError);

    const handleNavigation = (shelfGuide: ShelfGuideData) => {
      resetFilters();
      setAvailableFilters([
        {
          id: 'shelf_guide_indicators',
          title: 'Diet & Lifestyle',
          children: [
            {
              id: shelfGuide.id,
              default: false,
              selected: true,
              title: `${shelfGuide.name} (${shelfGuide.count})`,
              value: `"${shelfGuide.name}"`,
              controlType: 'switch',
            },
          ],
        } as Filter,
      ]);
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.SearchLanding,
          params: { query: '*', shelfGuideId: shelfGuide.id, tabName: 'product' },
        },
      });
    };

    const handleRefresh = async () => {
      setRefreshing(true);
      await reloadPageAsync();
      setRefreshing(false);
    };

    const renderItem = ({ item }: ListRenderItemInfo<ShelfGuideData>) => {
      const baseImageUrl = Config.BRXM_IMAGE_URL;

      return (
        <>
          <ListItem
            style={styles.catWrapper}
            title={item.name}
            //title={item.name + ' (' + item.count + ')'}
            titleStyle={styles.textWrapper}
            image={
              item.imageUrl
                ? {
                    uri: item.imageUrl,
                  }
                : {
                    uri: `${baseImageUrl}/consumer-app/shelf-guide/icons/${item.id.replace(/\s/g, '').toLowerCase()}.png`,
                  }
            }
            imageStyle={styles.catagoryImage}
            onPress={() => handleNavigation(item)}>
            <View>
              <Icon testID="arrowRightIcon" name="arrow-right" size={20} />
            </View>
          </ListItem>
        </>
      );
    };

    return (
      <>
        {!brxmPage || !brxmConfiguration || isLoading ? (
          <LoadingScreen />
        ) : (
          //@ts-ignore
          <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
            <ScrollView
              style={Platform.OS === 'web' ? {} : { marginBottom: 0 - insets.bottom }}
              nestedScrollEnabled={true}
              showsVerticalScrollIndicator={false}
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
              <BrComponent path="hero" />
              <List
                data={data}
                renderItem={renderItem}
                style={{ marginTop: 0 }}
                isLoading={isLoading}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
              />
            </ScrollView>
          </BrPage>
        )}
      </>
    );
  },
  { screenName: 'shelf-guide' },
);

const styles = StyleSheet.create({
  catagoryImage: {
    backgroundColor: '#F0E5DF',
    borderRadius: 100,
    height: 60,
    width: 60,
  },
  textWrapper: {
    paddingLeft: 30,
  },
  catWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    paddingHorizontal: 12,
  },
});

export default ShelfGuideListScreen;

import { Dimensions, PixelRatio, Platform } from 'react-native';

//https://gs.statcounter.com/screen-resolution-stats/mobile/north-america
// using most common as baseline
const baseWidth = 375; // iphone 8
const baseHeight = 667; // iphone 8
const screenWidth = Platform.OS === 'web' ? baseWidth : Dimensions.get('screen').width;
const screenHeight = Platform.OS === 'web' ? baseHeight : Dimensions.get('screen').height;
const screenWidthWithPixelRatio = { width: screenWidth * PixelRatio.get(), height: screenHeight * PixelRatio.get() }; //calculate the width & height based on device pixel ratio
const screenHeightWithPixelRatio = { width: screenWidth * PixelRatio.get(), height: screenHeight * PixelRatio.get() }; //calculate the width & height based on device pixel ratio
const fontScale = PixelRatio.getFontScale();
const minWidth = screenWidth * 0.8;
const containerWidth = screenWidth - 30;
const defaultFontSize = 17;
const locale = 'en-US';
const spacing = 16;

const scale = (size: number) => Math.round((screenWidth / baseWidth) * size);
const verticalScale = (size: number) => Math.round((screenHeight / baseHeight) * size);
const lineHeight = (size: number) => scale(size) * 1.2;
const aspectRatio = {
  'square': 1,
  '3x2': 3 / 2,
  '4x3': 4 / 3,
  '5x4': 5 / 4,
  '7x5': 7 / 5,
  '16x9': 16 / 9,
};

export {
  screenWidth,
  minWidth,
  containerWidth,
  scale,
  lineHeight,
  verticalScale,
  screenHeight,
  defaultFontSize,
  locale,
  fontScale,
  screenWidthWithPixelRatio,
  screenHeightWithPixelRatio,
  spacing,
  aspectRatio,
};

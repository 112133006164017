import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { DropShadow, SearchHeader } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import { mapping } from '@components/bloomreach';
import { CategoryHeader, CategoryList, CategoryNavigation } from '@components/category';
import withTombstone from '@components/hoc/withTombstone';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { useAnalytics, useBrxmPage, useEffectOnce, useRefetchOnStoreChange } from '@hooks';
import { ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { StackActions, useIsFocused } from '@react-navigation/native';
import { brsmService } from '@services';
import { screenWidth } from '@styles/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ActivityIndicator, Platform, RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type CategoryScreenProps = ShopStackScreenProps<ShopStackRoutes.Category>;

const CategoryScreen = withTombstone(({ route, navigation }: CategoryScreenProps) => {
  const { categoryId, isCustomBack } = route.params;
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [selectedDepartment, setSelectedDepartment] = useState<Category>();
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const tabBarHeight = useBottomTabBarHeight();
  const {
    isLoading,
    data,
    refetch,
    isRefetching,
    error: categoriesLoadError,
  } = brsmService.useCategoriesQuery((x) => {
    var cat = x?.find((c) => c.id === categoryId);
    if (cat) {
      return [cat];
    }
    return [];
  }, isFocused);
  useErrorHandler(categoriesLoadError);
  const { trackPageViewEvent } = useAnalytics();
  const trackPageView = useRef<boolean>(true);

  useEffectOnce(() => {
    if (trackPageView.current) {
      trackPageViewEvent();
      trackPageView.current = false;
    }
    return () => {
      trackPageView.current = true;
    };
  });

  useEffect(() => {
    const navigate = () => {
      navigation.dispatch({
        ...StackActions.replace(ShopStackRoutes.Categories),
        source: route.key,
        target: navigation.getState().key,
      });
    };

    if (!isLoading) {
      if (data && data.length) {
        setSelectedDepartment(data[0]);
      } else {
        navigate();
      }
    }
  }, [categoryId, data, isLoading, navigation, route.key]);

  useRefetchOnStoreChange(refetch);

  const handleRefresh = async () => {
    await reloadPageAsync();
    const { data: refetched } = await refetch({ throwOnError: true, cancelRefetch: true });
    if (refetched && refetched.length) {
      setSelectedDepartment(refetched[0]);
    }
  };

  return (
    <>
      <CartInfoBanner />
      <SearchHeader tabBarHeight={tabBarHeight} />
      <DropShadow>
        <View style={[styles.navWrapper, { height: selectedDepartment?.children && selectedDepartment.children.length > 0 ? 80 : 32 }]}>
          <CategoryHeader categoryName={selectedDepartment?.name} isRootCategory={true} isCustomBack={isCustomBack} />
          {selectedDepartment && <CategoryNavigation subCategories={selectedDepartment.children} isRootCategory={false} categoryId={categoryId} />}
        </View>
      </DropShadow>
      {isLoading || !brxmPage || !brxmConfiguration ? (
        <ActivityIndicator size={'large'} style={[styles.activityIndicator]} />
      ) : (
        [
          // @ts-ignore
          <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
            <ScrollView
              style={Platform.OS === 'web' ? styles.scrollView : { marginBottom: 80 - insets.bottom }}
              nestedScrollEnabled={true}
              showsVerticalScrollIndicator={false}
              refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={handleRefresh} />}>
              {selectedDepartment && <CategoryList data={selectedDepartment.children} isRootCategory={false} categoryId={categoryId} />}
              <BrComponent path="main" />
            </ScrollView>
          </BrPage>,
        ]
      )}
    </>
  );
}, {});

const styles = StyleSheet.create({
  navWrapper: {},
  scrollView: {
    // top: 1.5,
    // paddingTop: 1.5,
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    height: '70%',
  },
});

export default CategoryScreen;

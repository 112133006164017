import Text from '@components/Text';
import colors from '@config/colors';
import { Order } from '@fieldera-raleys/client-common';
import appStyles from '@styles/appStyles';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacityProps, View } from 'react-native';

interface ItemSubstitutionProps extends TouchableOpacityProps {
  itemId: string;
  obj: Order;
}

const ItemSubstitutionOrder: React.FC<ItemSubstitutionProps> = ({ itemId, obj }: ItemSubstitutionProps) => {
  const [subText, setSubText] = useState<string>('');
  const [subDisabled, setSubDisabled] = useState(true);

  useEffect(() => {
    const orderItem = (obj.orderItems ?? []).find((item) => item.orderItemId.toString() === itemId);
    const subItems = orderItem?.orderItemSubItems;
    const backuptype = orderItem?.orderItemReplacementType?.value;
    const replacementItemsTitle = (orderItem?.orderItemReplacementItems ?? []).map((i) => i.replacementProductListing.value);
    if (subItems && subItems.length > 0) {
      setSubText("Don't Substitute");
      setSubDisabled(true);
    } else if (backuptype === 'Best Available') {
      setSubDisabled(false);
      setSubText('Best Available');
    } else if (backuptype === 'None') {
      setSubDisabled(false);
      setSubText("Don't Substitute");
    } else {
      setSubDisabled(false);
      setSubText(replacementItemsTitle);
    }
  }, [obj, itemId]);

  return subText ? (
    <View style={[styles.container]}>
      {!subDisabled ? (
        <Text style={[appStyles.fontMobileBodySmBoldGray, styles.subTitle]} testID="backupText">
          Backup:
        </Text>
      ) : (
        <Text style={[appStyles.fontMobileBodySmBoldGray, styles.subTitle, styles.subDisabled]} testID="backupText">
          Backup:
        </Text>
      )}
      <Text style={[appStyles.fontMobileBodySmallLight, styles.subWidth]} numberOfLines={1} testID="substituionType">
        {subText}
      </Text>
    </View>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  subWidth: {
    width: '70%',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  subTitle: {
    marginRight: 4,
  },
  subDisabled: {
    color: colors.darkCream,
  },
});

export { ItemSubstitutionOrder };

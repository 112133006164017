import useAuthStore from '@store/authStore';
import Cache, { UUID_CACHE_KEY } from '@utils/cache';

export const getJwtToken = async (): Promise<string> => {
  const { isAuthenticated, refreshAuthToken, authToken } = useAuthStore.getState();
  if (!authToken || !authToken.jwtToken) {
    throw new Error('authToken is undefined');
  }

  let jwtToken: string | undefined = authToken.jwtToken;
  if (!isAuthenticated()) {
    jwtToken = await refreshAuthToken();
    if (!jwtToken) {
      throw new Error('failed to refresh authToken');
    }
  }
  return jwtToken;
};

export const getUniqueId = async (): Promise<string> => {
  return (
    (await Cache.getItem<string>(
      UUID_CACHE_KEY,
      async () => {
        return await Promise.resolve(`${Math.floor(Math.random() * 10 ** 13)}`);
      },
      -1,
    )) ?? (await Promise.resolve(`${Math.floor(Math.random() * 10 ** 13)}`))
  );
};

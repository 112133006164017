import { CartValidationResult } from '@fieldera-raleys/client-common/types/cart';
import { useNavigation } from '@react-navigation/native';
import { useCartStore } from '@store';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

const useCartValidation = (storeNumber?: string) => {
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [result, setResult] = useState<CartValidationResult>();
  const { validateCart } = useCartStore();
  const navigation = useNavigation();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', async () => {
      if (isValidating) {
        try {
          const validationResult = await validateCart(storeNumber);
          setResult(validationResult);
        } catch (ex) {
          errorHandler(ex);
        } finally {
          setIsValidating(false);
        }
      }
    });

    const unsubscribeBlur = navigation.addListener('blur', async () => {
      setIsValidating(true);
    });
    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
    };
  }, [errorHandler, isValidating, navigation, storeNumber, validateCart]);

  return { isValidating, result };
};

export default useCartValidation;

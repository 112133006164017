import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { UserSettingsStore } from './storeTypes';

const useUserSettingsStore = create<UserSettingsStore>()(
  persist(
    (set, _) => ({
      userSettings: undefined,
      initialize: (settings) => {
        set((state) => ({ ...state, userSettings: settings }));
      },
      updateSetting: (name, value) => {
        set((state) => ({ ...state, userSettings: { ...state.userSettings, [name]: value } }));
      },
      clearAll: () =>
        set(
          (state) => ({
            ...state,
            userSettings: { ...state.userSettings, localAuthentication: false, showAppBenefits: true, showTourGuide: true, remindMeLater: false },
          }),
          true,
        ),
    }),
    {
      name: 'uc-user-settings',
      storage: createJSONStorage<UserSettingsStore>(() => asyncStorage),
    },
  ),
);

export default useUserSettingsStore;

import {
  Accordion,
  BottomBar,
  Button,
  DropShadow,
  Icon,
  Image,
  LinkButton,
  Modal,
  NavigationHeader,
  Screen,
  Text,
  WalletItem,
  WebBrowserLink,
} from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import { IconName } from '@components/Icon';
import KeyboardCloseButton from '@components/KeyboardCloseButton';
import CartViewOptions from '@components/cart/CartViewOptions';
import Invoice from '@components/cart/Invoice';
import { ErrorMessage, Form, FormField, FormLabel, FormSwitch, SubmitButton } from '@components/forms';
import SEDollars from '@components/somethingExtra/SEDollars';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { defaultMoney } from '@fieldera-raleys/client-commercetools';
import { Cart, FulfillmentStore, LineItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { Address, ShopType } from '@fieldera-raleys/client-common';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useAnalytics, useEffectOnce } from '@hooks';
import routes, { AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps } from '@navigation/routes';
import { StackActions, useFocusEffect } from '@react-navigation/native';
import { offerService, userService } from '@services/brandywine';
import { useAppConfigStore, useCartStore, useCommerceToolsStore, useOffersStore, useShopStore, useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, defaultFontSize, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import { evaluateCart, getLineItemsToEvaluateCart } from '@utils/cartHelper';
import logger from '@utils/logger';
import { queryClient } from '@utils/reactQuery';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Config } from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { useCustomModalContext } from '../../contexts';
import helpers, { formatPhone, formatValue, isCardExpired, vibrateNotify } from '../../utils/helpers';
import {
  getAddressType,
  getCartCustomerContact,
  getCartLeadTimeMinutes,
  getDeliveryTipValue,
  getDonationAmountMoney,
  getDonationAmountValue,
  getFormattedTimeSlot,
  getFulfillmentInstructions,
  getFulfilmentStore,
  getLogoColors,
  getOriginalOrderNumber,
  getPickupPersonName,
  getPromotions,
  getSelectedPayment,
  getTimeSlot,
  invidLineItems,
  isDelivery,
  moneyValue,
  preAuthValue,
  taxedGrossValue,
} from '../../utils/orderHelpers';
import { getProductAttributeValue, getProductAvailablity, getProductsfromCommerceTools } from '../../utils/productHelper';

type CheckoutScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.Checkout>;

const CheckoutScreen = ({ navigation }: CheckoutScreenProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { userProfile } = useUserProfileStore();
  const {
    transitCart,
    setDonationAmount,
    cart,
    initialize,
    tombSet,
    setPickupPerson,
    setContactFirstName,
    setContactLastName,
    setContactPhone,
    setContactAllowText,
    setFulfillmentInstructions,
    createOrderFromCart,
    getPromoCodeList,
    setPaymentProfile,
    setPaymentAuthorization,
    validateCart,
    getCartQuantity,
    setPromotions,
    setDeliveryTip,
  } = useCartStore();
  const {
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    availableVouchers,
    acceptedVouchers,
    redeemedVouchers,
    nonTargetedOffers,
    offersState,
    vouchersState,
    refreshingVouchers,
    digitalCouponsState,
  } = useOffersStore();
  const { trackConversionEvent } = useAnalytics();
  const { showAlertModal, showAlertPopup } = useCustomModalContext();
  const [canPayAtPickup, setCanPayAtPickup] = useState<boolean>(true);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({ showModal: false });
  const { storePrevious, selectedShopType, deliveryAddress, selectedStore, setSelectedTimeSlot } = useShopStore();
  const [showMoreText, setShowMoreText] = useState<boolean>(false);
  const [isOrdering, setOrdering] = useState<boolean>(false);
  const insets = useSafeAreaInsets();
  const [cartOptionsData, setCartOptionsData] = useState<JSX.Element | null>(null);
  const [lastEvalQuery, setLastEvalQuery] = useState<string>('');
  const [lastCartPromoSet, setLastCartPromoSet] = useState<string>('');

  const {
    data: paymentProfiles,
    status: paymentProfileLoadStatus,
    refetch: refetchPayments,
    error,
  } = useQuery<PaymentProfile[]>('paymentProfiles', async () => await userService.getPaymentProfiles());
  const [fffMore, setFffMore] = useState<boolean>(false);
  const [dropDownState, setDropDownState] = useState<{ contact: boolean; payment: boolean; pickup: boolean; sedollars: boolean }>({
    contact: true,
    payment: true,
    pickup: true,
    sedollars: true,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const paymentProfileRef = useRef<PaymentProfile | undefined>(undefined);
  const handleError = useErrorHandler(error);
  const [isBusy, setIsBusy] = useState(false);
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);

  const fffJpgUrl = [Config.BRXM_IMAGE_URL, 'consumer-app/cart-screen/fff.png'].join('/');
  const deliveryTipDefault = Config.DEFAULT_DELIVERY_TIP ?? 2;
  const PAY_AT_PICKUP = 'payAtPickup';
  const { getConfigValue } = useAppConfigStore();
  const { t } = useTranslation(['cart', 'checkout']);
  const paymentDisclaimerMore = t('paymentDisclaimerMore');
  const fffDescriptionMore = t('fffDescriptionMore');
  const { defaultStore } = useCommerceToolsStore();

  const debounceDelay: number = 500;
  const _setPickupPerson = useMemo(() => _.debounce(setPickupPerson, debounceDelay), [setPickupPerson]);
  const _setFulfillmentInstructions = useMemo(() => _.debounce(setFulfillmentInstructions, debounceDelay), [setFulfillmentInstructions]);
  const _setContactFirstName = useMemo(() => _.debounce(setContactFirstName, debounceDelay), [setContactFirstName]);
  const _setContactLastName = useMemo(() => _.debounce(setContactLastName, debounceDelay), [setContactLastName]);
  const _setContactPhone = useMemo(() => _.debounce(setContactPhone, debounceDelay), [setContactPhone]);
  const _setContactAllowText = useMemo(() => _.debounce(setContactAllowText, debounceDelay), [setContactAllowText]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label(t('firstName')),
    lastName: Yup.string().required().label(t('lastName')),
    phone: Yup.string().required().matches(helpers.PHONE_NUMBER_REGEX, t('invalidPhone')).label(t('phone')),
  });

  const flushDebounces = () => {
    _setPickupPerson.flush();
    _setFulfillmentInstructions.flush();
    _setContactLastName.flush();
    _setContactFirstName.flush();
    _setContactPhone.flush();
    _setContactAllowText.flush();
  };

  const [contentBottom, setContentBottom] = useState<{ paddingBottom: number }>({ paddingBottom: insets.bottom });
  const [infoBox, setInfoBox] = useState<{ bottom: number }>({ bottom: insets.bottom });
  const onButtonLayout = (le: LayoutChangeEvent) => {
    setContentBottom({ paddingBottom: le.nativeEvent.layout.height });
    setInfoBox({ bottom: le.nativeEvent.layout.height });
  };

  const handleAddPaynmentMethod = useCallback(() => {
    navigation.navigate(CheckoutStackRoutes.AddPaymentMethod, {
      calledFrom: CheckoutStackRoutes.Checkout,
    });
    setNeedRefresh(true);
  }, [navigation]);

  const handleEditPaymentMethod = useCallback(
    (item: PaymentProfile) => {
      navigation.navigate(CheckoutStackRoutes.AddPaymentMethod, {
        paymentMethodId: item.CustomerPaymentMethodId,
        calledFrom: CheckoutStackRoutes.Checkout,
      });
      setNeedRefresh(true);
    },
    [navigation],
  );

  const handleRemove = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowDeleteModal(true);
  };
  const handleRemovePaymentMethod = async (): Promise<void> => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.deletePaymentProfile({ ...paymentProfileRef.current }),
        onError: (e) => {
          handleError(e);
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetchPayments();
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowDeleteModal(false);
  };

  const handleSetDefault = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowSetDefaultModal(true);
  };

  const handleSetDefaultPayment = () => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.updatePaymentProfile({ ...paymentProfileRef.current, IsDefault: true }),
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetchPayments();
        },
        onError: (e) => {
          handleError(e);
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowSetDefaultModal(false);
  };

  const onSubmit = async () => {
    return false;
  };

  const [needRefetch, setNeedRefresh] = useState(false);
  useFocusEffect(() => {
    if (needRefetch) {
      setNeedRefresh(false);
      refetchPayments();
      setTimeout(() => {
        try {
          refetchPayments();
        } catch (e) {}
      }, 2500);
    }
  });

  const [promoCodeList, setPromoCodeList] = useState<JSX.Element | JSX.Element[]>(<></>);

  const unacceptPromoCode = useCallback(
    async (promoCode?: string): Promise<void> => {
      if (promoCode) {
        try {
          const unRes = await offerService.unAcceptPromo(promoCode);
          if (unRes?.ReturnMessage) {
            showAlertPopup({
              message: unRes.ReturnMessage,
              containerStyle: [styles.alertBox, infoBox],
              ellipsizeMode: 'head',
            });
          }
          setLastCartPromoSet(''); // force refresh, PromoCodes are returnedonly through evaluatecart
          // setFetchPromos(true); should already be triggered by collection of promos
        } catch (e) {
          console.log('Failed to unaccept promo ' + (promoCode ?? '') + e);
        }
      }
    },
    [infoBox, showAlertPopup],
  );

  const [cartPromoSet, setCartPromoSet] = useState<string>('');
  const doFetchPromos = useCallback(() => {
    if (cart && cartPromoSet) {
      const cartEvalQuery = JSON.stringify(getLineItemsToEvaluateCart(cart.lineItems ?? []));
      if (cartEvalQuery !== lastEvalQuery || cartPromoSet !== lastCartPromoSet) {
        evaluateCart(cart, selectedStore ? selectedStore.number : defaultStore?.key ?? '', selectedShopType === 'Pickup' ? 'pickup' : 'delivery')
          .then((result) => {
            setLastCartPromoSet(cartPromoSet);
            setLastEvalQuery(cartEvalQuery);
            const newPromos = JSON.stringify(result);
            if (newPromos !== getPromotions(cart)) {
              setPromotions(newPromos);
            }
            const promoCodes = result.filter((p) => p.IsPromoCodePromo && p.IsAccepted);
            setPromoCodeList([
              ...promoCodes.map((p, indx) => (
                <View key={`promoCode_${indx}`} style={[styles.promoCodePad, utilityStyles.my1]}>
                  <View key={`promoCode2_${indx}`} style={[{ flexDirection: 'row', flex: 1 }, utilityStyles.mt1]}>
                    <Text style={[appStyles.bodySmallBold, { color: colors.red }]}>{p.PromotionCode?.toUpperCase() ?? p.Headline ?? 'PROMO'}</Text>
                    <TouchableOpacity onPress={() => unacceptPromoCode(p.PromotionCode)} style={{ marginLeft: 'auto' }}>
                      <Text testID="buyTwoGetOneValue" style={[styles.promoCodeX]}>
                        +
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {0 ? (
                    <View key={`promoCodeSub_${indx}`} style={[{ flexDirection: 'row', width: '100%' }, utilityStyles.my1]}>
                      <Text style={[appStyles.bodySmallLeftRegular, { color: colors.red, flex: 1 }]}>
                        {p.Headline ?? p.PromotionCode?.toUpperCase() ?? 'PROMO'}
                      </Text>
                      {p.PercentComplete && p.PercentComplete < 100 ? (
                        <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]}>
                          Progress: {p.PercentComplete.toFixed(2)}%
                        </Text>
                      ) : (
                        <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]}>
                          &minus;&nbsp;{moneyValue({ ...defaultMoney, centAmount: (p.Rewards?.[0]?.RewardDiscount ?? 0) * 100 })}
                        </Text>
                      )}
                      <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]} />
                    </View>
                  ) : (
                    <></>
                  )}
                  <Text testID="buyTwoGetOneText" ellipsizeMode={'tail'} numberOfLines={4} style={[appStyles.bodySmallLeftRegular]}>
                    {p.Description}
                  </Text>
                </View>
              )),
            ]);
          })
          .catch((reason) => {
            logger.log(reason);
          })
          .finally(() => {});
      }
    }
  }, [cart, cartPromoSet, defaultStore?.key, lastCartPromoSet, lastEvalQuery, selectedShopType, selectedStore, setPromotions, unacceptPromoCode]);
  const _doFetchPromos = useMemo(() => _.debounce(doFetchPromos, debounceDelay), [doFetchPromos]);
  const handleOptionsCancelPress = () => {
    setShowCartOptions('');
  };

  useEffect(() => {
    let tmpSet = JSON.stringify([]);
    if (cart) {
      const promoSourceSet = [
        offersState,
        availableWeeklyExclusiveOffers,
        acceptedWeeklyExclusiveOffers,
        availableSomethingExtraOffers,
        acceptedSomethingExtraOffers,
        availableDigitalCoupons,
        acceptedDigitalCoupons,
        availableVouchers,
        acceptedVouchers,
        redeemedVouchers,
        nonTargetedOffers,
        vouchersState,
        refreshingVouchers,
        digitalCouponsState,
      ];
      tmpSet = JSON.stringify(
        promoSourceSet.reduce((acc, pset) => {
          acc.push(JSON.stringify(pset));
          return acc;
        }, [] as string[]),
      );
    }

    if (cartPromoSet !== tmpSet) {
      setCartPromoSet(tmpSet);
    }
    _doFetchPromos();
  }, [
    cart,
    cartPromoSet,
    offersState,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    availableVouchers,
    acceptedVouchers,
    redeemedVouchers,
    nonTargetedOffers,
    vouchersState,
    refreshingVouchers,
    digitalCouponsState,
    _doFetchPromos,
  ]);

  const cartViewOptionRef = React.useRef<React.ElementRef<typeof CartViewOptions>>(null);
  const handleOptionsApplyPress = () => {
    if (cartViewOptionRef.current) {
      cartViewOptionRef.current.doApply();
      setCartPromoSet('-'); // force refresh on adding promocodes

      _doFetchPromos();
    }
    setShowCartOptions('');
  };
  const [showCartOptions, setShowCartOptions] = useState<string>('');
  const doOptionsPress = useCallback(
    (opType: 'tip' | 'fff' | 'ptype' | 'promo') => {
      setCartOptionsData(<CartViewOptions optionType={opType} ref={cartViewOptionRef} infoBox={infoBox} />);
      setShowCartOptions(opType);
    },
    [infoBox],
  );

  useEffectOnce(() => {
    // pre-validate checkout screen
    const isDel = isDelivery(cart);
    if (
      cart &&
      (!(cart.lineItems?.length ?? 0) ||
        undefined === isDel ||
        !cart.shippingAddress ||
        !cart.shippingAddress?.streetName ||
        !cart.shippingAddress?.postalCode ||
        !cart.shippingAddress?.city ||
        !getFulfilmentStore(cart) ||
        !getTimeSlot(cart))
    ) {
      navigation.navigate(CheckoutStackRoutes.CartLanding, { storeNumber: undefined });
    }

    return () => {
      // flush all debounces
      flushDebounces();
    };
  });

  useEffect(() => {
    if (paymentProfileLoadStatus === 'success' && cart && !isOrdering) {
      setLoading(false);
    }
  }, [paymentProfileLoadStatus, cart, isOrdering]);

  useEffect(() => {
    if (modalData && !modalData.showModal && modalData.onClose) {
      if (modalData.onClose) {
        modalData.onClose();
        setModalData({ ...modalData, onClose: undefined });
      }
    }
  }, [modalData]);

  const canPlaceOrder = async (latestCart: Cart | undefined) => {
    if (!latestCart) {
      return false;
    }
    let errorTitle = t('errorCheckoutTitle');
    let onClose: any;
    const todo: string[] = [];
    if ((latestCart.lineItems?.length ?? 0) === 0) {
      todo.push(t('errorEmptyCart'));
    } else {
      const isDel = isDelivery(latestCart);
      if (isDel === undefined) {
        todo.push(t('errorShoppingMethod'));
        onClose = () => changeShoppingOptions('InitialShopOptions');
      } else if (selectedShopType !== ShopType.PICKUP && selectedShopType !== ShopType.DELIVERY) {
        todo.push(t('errorNotSupportedShoppingMethod', { selectedShopType }));
        onClose = () => changeShoppingOptions('InitialShopOptions');
      } else {
        if (
          !latestCart.shippingAddress ||
          !latestCart.shippingAddress?.streetName ||
          !latestCart.shippingAddress?.postalCode ||
          !latestCart.shippingAddress?.city ||
          !getFulfilmentStore(latestCart)
        ) {
          todo.push(isDel ? t('errorDeliveryAddress') : t('errorPickupStore'));
          onClose = () => changeShoppingOptions(isDel ? 'Delivery' : 'FindAStore');
        }
        const ts = getTimeSlot(latestCart);
        if (!ts) {
          todo.push(isDel ? t('errorDeliveryTimeslot') : t('errorPickupTimeslot'));
          onClose = () => changeShoppingOptions('TimeSlot');
        } else {
          let then = dayjs(ts.timeSlotDate);
          const now = dayjs();
          const leadTime = await getCartLeadTimeMinutes(cart);
          if (ts.timeSlots[0]?.timeSlotHourStart) {
            then = then.add(ts.timeSlots[0].timeSlotHourStart, 'hours');
          }
          if (then.diff(now.add(leadTime, 'minutes'), 'minutes') <= 0) {
            const originalOrder = getOriginalOrderNumber(cart);
            if (originalOrder) {
              errorTitle = t('modNoLongerAvailableTitle');
              todo.push(t('modNoLongerAvailable'));
            } else {
              const leadMessage =
                // eslint-disable-next-line no-bitwise
                leadTime > +(Config.LEAD_TIME_MAX_HOURS_TOSHOW ?? 24) * 60 ? `${(leadTime / 60 / 24 + 1) | 0} day` : `${(leadTime / 60) | 0}-hour`;

              todo.push(t('selectFutureTimeslot', { leadTime: leadMessage }));
              onClose = () => changeShoppingOptions('TimeSlot');
            }
          }
        }
      }
    }

    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      return await getProductsfromCommerceTools(skus);
    };

    // individual
    const skus: string[] = invidLineItems(latestCart.lineItems).reduce<string[]>((acc: string[], i: LineItem) => {
      if (i.variant?.sku && acc.findIndex((o) => o === i.variant!.sku)) {
        acc.push(i.variant.sku);
      }
      return acc;
    }, []);

    const prodSet: Product[] = await getProdSet(skus);
    prodSet.some((p: Product) => {
      const avail = getProductAvailablity(p.masterData);
      if (avail.availability !== 'Available' && avail.availability !== 'LowStock') {
        todo.push('Some of the products in the cart are not currently available');
        onClose = () => {};
        return true;
      }
      return false;
    });

    if (todo.length) {
      setModalData({
        location: 'top',
        title: errorTitle,
        description: <Text>{todo.join('\n')}</Text>,
        showModal: true,
        onClose: onClose,
      });
      return false;
    }

    return true;
  };

  const [mainRef, setMainRef] = useState<ScrollView | null>(null);
  const [mainScrollViewY, setMainScrollViewY] = useState(0);
  const [paymentMarkerY, setPaymentMarkerY] = useState(0);
  const scrollToPayment = () => {
    if (mainRef) {
      mainRef.scrollTo({ x: 0, y: mainScrollViewY + paymentMarkerY, animated: true });
    }
  };
  // order functions
  const placeOrder = async () => {
    setOrdering(true);
    const selectedPaymentMethodId = getSelectedPayment(cart);
    if (typeof selectedPaymentMethodId === 'undefined' || (!canPayAtPickup && selectedPaymentMethodId === 0)) {
      setModalData({
        location: 'top',
        title: t('errorCheckoutTitle'),
        description: <Text>Please Select a Valid Payment Method</Text>,
        showModal: true,
        onClose: scrollToPayment,
      });
      setOrdering(false);
      return false;
    }

    try {
      const validationResult = await validateCart();
      if (!validationResult.isValid) {
        setOrdering(false);
        navigation.dispatch(
          StackActions.replace(AppStackRoutes.CartAndCheckout, {
            screen: CheckoutStackRoutes.CartLanding,
            params: {
              storeNumber: undefined,
              shopType: selectedShopType ?? ShopType.PICKUP,
              deliveryAddress:
                selectedShopType && selectedShopType === ShopType.PICKUP
                  ? selectedStore?.address
                  : ({ state: deliveryAddress?.state ?? 'CA', postalCode: deliveryAddress?.postalCode ?? '' } as Address),
            },
          }),
        );
        return;
      }
    } catch (e) {
      setOrdering(false);
      throw e;
    }

    flushDebounces();
    const latestCart = await initialize();
    if (!canPlaceOrder(latestCart)) {
      setOrdering(false);
      return;
    }
    if (selectedStore) {
      storePrevious(selectedStore);
    }
    try {
      if (selectedPaymentMethodId !== 0) {
        const authAmount =
          Math.round(latestCart.taxedPrice?.totalGross.centAmount * (getConfigValue<number>('OrderAuthAmountMultiplier') ?? 1.15)) /
          10 ** (latestCart.taxedPrice?.totalGross?.fractionDigits ?? 2);
        if (isNaN(authAmount)) {
          throw new Error('Cart Total lookup failure!!');
        }

        const authResponse = await userService.authorizePayment(selectedPaymentMethodId, authAmount);
        if (authResponse) {
          await setPaymentAuthorization(`${authResponse.AuthCode}|${authAmount}|${authResponse.TransactionId}`);
        } else {
          // TODO:// auth faulure code
          throw new Error('Auth failure!!');
        }
      }
      const order = await createOrderFromCart();
      vibrateNotify('Buzz');
      setSelectedTimeSlot(undefined);
      trackConversionEvent(order);
      const payAtPickup = cart?.custom?.customFieldsRaw?.find((x) => x.name === 'offlinePaymentMethodType') ?? '';
      navigation.navigate(CheckoutStackRoutes.OrderComplete, { orderId: order.id, offlinePaymentMethod: payAtPickup !== '' ? payAtPickup.value : '' });
    } catch (e) {
      setOrdering(false);
      throw e;
    }
  };

  let checkoutFailed = false;
  let customerContact = getCartCustomerContact(cart);
  const ContactSection = useCallback(
    (): JSX.Element => (
      <View key={'mainScrollViewViewContact'} style={styles.contentPad}>
        <View style={[{ paddingTop: 13, paddingBottom: 13 }]}>
          <FormField
            testID="firstName"
            label={t('firstName')}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="ascii-capable"
            name="firstName"
            onChange={(e) => _setContactFirstName(e.nativeEvent.text)}
            textContentType="givenName"
            returnKeyType="next"
          />
          <FormField
            testID="lastName"
            label={t('lastName')}
            autoCapitalize="none"
            textContentType="familyName"
            autoCorrect={false}
            name="lastName"
            onChange={(e) => _setContactLastName(e.nativeEvent.text)}
            returnKeyType="next"
          />
          <FormField
            testID="phone"
            label="Mobile Phone Number"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="phone-pad"
            name="phone"
            onChange={(e) => _setContactPhone(e.nativeEvent.text)}
            placeholder=""
            textContentType="telephoneNumber"
            returnKeyType="done"
            formatter={formatPhone}
          />
          <View style={[styles.flexrow, { width: containerWidth }]}>
            <FormSwitch
              testID="allowText"
              labelStyle={{ width: screenWidth * 0.7 }}
              name={'allowText'}
              label={t('textOrderUpdates')}
              onChange={() => _setContactAllowText(!customerContact?.allowText ?? false)}
            />
            <TouchableOpacity
              style={[styles.biggerHitArea, { paddingTop: 24 }]}
              onPress={() =>
                setModalData({
                  location: 'top',
                  title: t('allowTextUpdatesInfoTitle'),
                  description: [
                    <Text testID="messageText" style={[appStyles.bodyLargeCenter, { lineHeight: lineHeight(22) }]}>
                      Message and data rates may apply.
                      {'\n'}Message frequency varies.
                      {'\n'}See{' '}
                      <WebBrowserLink
                        href={routes.TERMS_OF_USE}
                        style={[appStyles.footerLink]}
                        onPress={() => setModalData((prev) => ({ ...prev, showModal: false }))}>
                        SMS Terms
                      </WebBrowserLink>{' '}
                      for more info.
                    </Text>,
                  ],
                  showModal: true,
                })
              }>
              <Icon testID="questionCircleIcon" name="question-circle" size={20} style={appStyles.mediumIcon} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    ),
    [_setContactAllowText, _setContactFirstName, _setContactLastName, _setContactPhone, customerContact?.allowText, t],
  );

  const ts = getFormattedTimeSlot(cart, 'Unavailable');
  const renderLocation = useCallback(() => {
    let obj = cart;
    const dateSlot = getTimeSlot(obj)?.timeSlotDate;
    const a = obj?.shippingAddress;
    var monthOptions: Intl.DateTimeFormatOptions = { month: 'short' };
    var dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    let slotDate = t('unavailable');
    if (dateSlot) {
      try {
        const dateTime = new Date(dateSlot);
        const day = new Intl.DateTimeFormat('en-US', dayOptions).format(dateTime);
        const dt = dateTime.getDate();
        const month = new Intl.DateTimeFormat('en-US', monthOptions).format(dateTime);
        slotDate = `${day}. ${month} ${dt}`;
      } catch (e) {
        /* */
      }
    }
    const storeInfo: FulfillmentStore | undefined = getFulfilmentStore(cart);
    const logoColors = getLogoColors(storeInfo?.logo);
    const addressType = getAddressType(obj);
    return [
      <View key={'oc_timeSlot_View1'} style={[]}>
        <Text testID="slotDateText" style={[appStyles.h5, styles.textAlignCenter]}>
          {slotDate}
        </Text>
        <Text testID="timeSlotText" style={[appStyles.h5, styles.textAlignCenter]}>
          {ts}
        </Text>
      </View>,
      <View key={'oc_timeSlot_View2'} style={[styles.infoPadSeparator, { margin: 10 }]} />,
      <View key={'oc_timeSlot_View3'} style={[styles.location]}>
        <View style={styles.iconWrapper}>
          {isDelivery(obj) ? (
            <Icon
              testID="addressTypeIcon"
              name={addressType === 'home' ? 'home-icon' : 'office-icon'}
              size={50}
              fill={colors.darkCream}
              stroke={colors.darkCream}
              style={styles.homeIcon}
            />
          ) : (
            <Icon
              testID="storeInfoIcon"
              name={`${storeInfo?.logo}` as IconName}
              size={70}
              fill={logoColors?.fill || 'none'}
              fillSecondary={logoColors?.fillSecondary || 'none'}
              stroke={logoColors?.stroke || 'none'}
              style={styles.homeIcon}
            />
          )}
        </View>
        <View style={styles.locationInfo}>
          {isDelivery(obj) && (
            <Text testID="deliveryNameText" numberOfLines={1} style={styles.locationText}>
              {a?.firstName || a?.lastName ? `${a?.firstName ?? ''} ${a?.lastName ?? ''}` : t('unavailable')}
            </Text>
          )}
          {addressType === 'business' && a?.company ? (
            <Text testID="companyNameText" numberOfLines={1} style={styles.locationText}>
              {a.company}
            </Text>
          ) : null}
          <Text testID="streetNameText" numberOfLines={1} style={styles.locationText}>
            {a?.streetName ?? ''}
          </Text>
          <Text testID="cityText" numberOfLines={1} style={styles.locationText}>
            {a?.city && a?.state && a.postalCode ? [a.city, ', ', a.state, ' ', a.postalCode].join('') : ''}
          </Text>
          <Text testID="phoneText" numberOfLines={1} style={[styles.locationText, { fontFamily: FontFamily.LarsseitLight }]}>
            {a?.phone ?? ''}
          </Text>
        </View>
      </View>,
    ];
  }, [cart, t, ts]);

  const changeShoppingOptions = useCallback(
    (page?: string) => {
      tombSet(page);
      navigation.dispatch(
        StackActions.replace(AppStackRoutes.CartAndCheckout, {
          screen: CheckoutStackRoutes.CartLanding,
          params: { storeNumber: undefined },
        }),
      );
    },
    [navigation, tombSet],
  );

  const TipSection = useCallback(
    (): JSX.Element => (
      <DropShadow key={'checkoutScreenDeliveryContainerKey'} style={[{ borderBottomWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }]}>
        <View style={[styles.contentPad, utilityStyles.my3]}>
          <Text testID="deliveryTip" style={[appStyles.bodyBoldLarge]}>
            {t('deliveryTip')}
          </Text>
          <TouchableOpacity onPress={() => doOptionsPress('tip')}>
            <View style={[styles.flexrow, { alignItems: 'center' }]}>
              <FormField
                testID="checkoutScreenDeliveryTipKey"
                pointerEvents="none"
                onPressIn={() => true}
                key={'checkoutScreenDeliveryTipKey'}
                containerStyle={[styles.flexGrow]}
                hideLabel
                autoCapitalize="none"
                autoCorrect={false}
                value={getDeliveryTipValue(cart)}
                keyboardType="decimal-pad"
                name="deliveryTip"
                textContentType="none"
                returnKeyType="next"
                editable={false}
                formatter={formatValue}
              />
              <Text testID="editText" style={[appStyles.smallLink, utilityStyles.pl3]}>
                {t('edit')}
              </Text>
            </View>
          </TouchableOpacity>
          <Text style={[appStyles.bodySmallLeftRegular]}>{t('deliveryTipDescription', { tipDecimal: deliveryTipDefault })}</Text>
        </View>
      </DropShadow>
    ),
    [cart, deliveryTipDefault, doOptionsPress, t],
  );

  const DeliverySection = useCallback(
    (): JSX.Element => (
      <View key={'mainScrollViewViewDelivery'} style={styles.contentPad}>
        <View style={[utilityStyles.py3]}>
          <View style={[styles.flexcolumn]}>
            <FormField
              testID="deliveryInstructions"
              label={t('deliveryInstructions')}
              bottomRight={t('wordLimit')}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="ascii-capable"
              name="delivery_instructions"
              onChange={(e) => _setFulfillmentInstructions(e.nativeEvent.text)}
              textContentType="name"
              multiline={true}
              fieldStyleEx={[{ height: scale(defaultFontSize * 6) }]}
              maxLength={250}
            />

            <TouchableOpacity
              style={[styles.biggerHitArea]}
              onPress={() =>
                setModalData({
                  location: 'top',
                  title: t('deliveryInstructions'),
                  description: <Text>{t('deliveryInstructionsDescription')}</Text>,
                  showModal: true,
                })
              }>
              <Icon testID="mainScrollViewQuestionCircleIcon" name="question-circle" size={20} style={appStyles.mediumIcon} />
            </TouchableOpacity>
          </View>
          <KeyboardCloseButton />
          <View key={`OrderNoteSave_${cart?.id}`} style={[{ flexDirection: 'row', height: 45 }, utilityStyles.my1]}>
            <SubmitButton
              type={'secondary'}
              size={'small'}
              buttonStyle={{ position: 'absolute', top: -20 }}
              title={t('saveDeliveryInstructions')}
              testID="OrderNoteSaveSubmit"
            />
          </View>
          {/* Delivery Location */}
          <View style={[styles.flexrow, { paddingTop: 13 }]}>
            <FormLabel style={[]}>{t('deliveryDateTime')}</FormLabel>
          </View>
          <View style={[styles.infoPad, { paddingTop: 13 }]}>{renderLocation()}</View>
          <LinkButton style={[appStyles.smallLink, utilityStyles.pt1, { alignSelf: 'flex-end' }]} onPress={() => changeShoppingOptions('TimeSlot')}>
            {t('edit')}
          </LinkButton>
        </View>
      </View>
    ),
    [_setFulfillmentInstructions, cart?.id, changeShoppingOptions, renderLocation, t],
  );

  const PickupSection = useCallback(
    (): JSX.Element => (
      <View key={'renderLocation_pickup1'} style={[styles.contentPad]}>
        <View style={[utilityStyles.py3]}>
          <View style={[styles.flexcolumn, {}]}>
            <FormField
              testID="designatedPickupPerson"
              label={t('designatedPickupPerson')}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="ascii-capable"
              name="pickup_person"
              onChange={(e) => _setPickupPerson(e.nativeEvent.text)}
              textContentType="name"
              returnKeyType="done"
            />
            <TouchableOpacity
              style={[styles.biggerHitArea]}
              onPress={() =>
                showAlertModal({
                  title: t('designatedPickupPerson'),
                  message: t('designatedPickupPersonInfo'),
                })
              }>
              <Icon testID="renderLocationQuestionCircle" name="question-circle" size={20} style={appStyles.mediumIcon} />
            </TouchableOpacity>
          </View>

          <View style={[styles.flexrow, { paddingTop: 13 }]}>
            <FormLabel testID="pickupDateTime" style={[]}>
              {t('pickupDateTime')}
            </FormLabel>
          </View>
          <View style={[styles.infoPad, { paddingTop: 13 }]}>{renderLocation()}</View>
          <LinkButton style={[appStyles.smallLink, utilityStyles.pt1, { alignSelf: 'flex-end' }]} onPress={() => changeShoppingOptions('TimeSlot')}>
            {t('edit')}
          </LinkButton>
        </View>
      </View>
    ),
    [_setPickupPerson, changeShoppingOptions, renderLocation, showAlertModal, t],
  );

  useEffect(() => {
    if (cart) {
      setDeliveryTip();
    }
  }, [cart, setDeliveryTip]);

  useEffect(() => {
    if (cart) {
      // Choose the default paymment profile
      const selectedPaymentMethodId = getSelectedPayment(cart);
      if (typeof selectedPaymentMethodId === 'undefined') {
        const pp = paymentProfiles?.find((p) => p.IsDefault === true);
        pp && isCardExpired(pp) ? setPaymentProfile(0) : pp && setPaymentProfile(+pp.CustomerPaymentMethodId!);
      }

      if (getCartQuantity() === 0) {
        return;
      }

      for (let item of cart?.lineItems) {
        const productAttributes = item.variant?.attributesRaw ?? [];
        const isCateringManagerItem = Boolean(getProductAttributeValue('isCateringManagerItem', productAttributes) ?? false);
        if (isCateringManagerItem) {
          setCanPayAtPickup(false);

          // In case of categring item on cart, Pay at Pickup is not availale so switch back to default payment profile.
          if (selectedPaymentMethodId === 0) {
            const pp = paymentProfiles?.find((p) => p.IsDefault === true);
            pp && isCardExpired(pp) ? setPaymentProfile(0) : pp && setPaymentProfile(+pp.CustomerPaymentMethodId!);
          }

          break;
        }
      }
    }
  }, [cart, getCartQuantity, paymentProfiles, setPaymentProfile]);

  // payment section
  const PaymentSection = useCallback((): JSX.Element => {
    // payment functions
    const handlePaymentSelect = async (item: PaymentProfile) => {
      setOrdering(true);
      if (item.CustomerPaymentMethodId && !isCardExpired(item)) {
        setPaymentProfile(+item.CustomerPaymentMethodId);
      }
      setOrdering(false);
    };

    const payAtPickup = cart?.custom?.customFieldsRaw?.find((x) => x.name === 'offlinePaymentMethodType') ?? '';
    const payAtPickupDescription = payAtPickup ? t(payAtPickup.value) : t('payAtPickupDescription');
    const selectedPaymentMethodId = getSelectedPayment(cart);
    return (
      <View key={'renderpayment_1'} style={[styles.contentPad, utilityStyles.my1]}>
        <View style={utilityStyles.mt3}>
          {!isDelivery(cart) || paymentProfiles?.length ? (
            <>
              {paymentProfiles?.map((i, index) => {
                const item = { ...i };
                if (typeof selectedPaymentMethodId !== 'undefined') {
                  item.IsDefault = selectedPaymentMethodId === item.CustomerPaymentMethodId;
                }
                return (
                  <WalletItem
                    onPress={handlePaymentSelect}
                    item={item}
                    key={item.CustomerPaymentMethodId}
                    onEdit={handleEditPaymentMethod}
                    index={index + 1}
                    onRemove={handleRemove}
                    onSetDefault={!item.IsDefault ? handleSetDefault : undefined}
                  />
                );
              })}
              {isDelivery(cart) ? null : (
                <>
                  <WalletItem
                    readOnly={true}
                    index={0}
                    onPress={() => canPayAtPickup && doOptionsPress('ptype')}
                    item={
                      {
                        id: 'payAtPickup',
                        IsDefault: canPayAtPickup && selectedPaymentMethodId === 0,
                        CardNumber: t('payAtPickup'),
                        CustomerPaymentMethodId: 0,
                        CardType: PAY_AT_PICKUP,
                        CardExpirationDate: !canPayAtPickup ? t('noPayAtPickup', { ns: 'checkout' }) : payAtPickupDescription,
                        EmailAddress: userProfile?.email,
                        PaymentMethodId: 0,
                      } as PaymentProfile
                    }
                    key={'payAtPickupKey'}
                  />
                </>
              )}
            </>
          ) : (
            <Text testID="noPaymentMethod" style={[appStyles.bodyLeftBold, utilityStyles.mt5, utilityStyles.p5]}>
              You have no saved payment methods on your account.
            </Text>
          )}
          {/* Add New */}
          <View style={[utilityStyles.my0]}>
            <TouchableOpacity style={[styles.flexrow, { height: 50, alignSelf: 'center' }]} onPress={handleAddPaynmentMethod}>
              <Icon
                testID="addPaymentMethod"
                name={'circle-plus'}
                size={35}
                stroke={colors.red}
                strokeSecondary={colors.sectionBorder}
                fill={colors.white}
                style={[appStyles.largeIcon, { alignItems: 'flex-end' }]}
              />
              <FormLabel style={{ flex: 0, padding: 10 }}>Add Payment Method</FormLabel>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }, [canPayAtPickup, cart, doOptionsPress, handleAddPaynmentMethod, handleEditPaymentMethod, paymentProfiles, setPaymentProfile, t, userProfile?.email]);

  // payment section
  const SEDollarsSection = useCallback(
    (): JSX.Element => (
      <View key={'rendersedollars_1'} style={[styles.contentPad, utilityStyles.my1]}>
        <View style={utilityStyles.mt3}>
          <SEDollars activatedText={'Activated'} activatedTextStyle={appStyles.bodyXsBoldMediumGrey} noRewardMessageType={'cart'} />
        </View>
      </View>
    ),
    [],
  );

  let fffValue = 'Other'; // default display value for Other amount
  let fffSelected = ''; // which is the selected option, '' is none
  const fffAttr = getDonationAmountMoney(cart);
  if (fffAttr) {
    // if we have a value, try to match it
    switch (fffAttr.centAmount) {
      case 100:
        fffSelected = '1';
        break;
      case 500:
        fffSelected = '5';
        break;
      case 1000:
        fffSelected = '10';
        break;
      case 0:
        break;
      default:
        fffValue = getDonationAmountValue(cart);
        fffSelected = 'other';
    }
  }
  // maintainVisibleContentPosition={{ autoscrollToTopThreshold:1, minIndexForVisible: 0 }}
  let itemCount = <></>;
  return (
    <Screen>
      <NavigationHeader titleStyle={{ top: 5 }} title={t('checkOut')} next={itemCount}>
        <TouchableOpacity
          onPress={() => navigation.navigate(CheckoutStackRoutes.OrderSummary)}
          style={[{ flexDirection: 'row', alignSelf: 'center' }, utilityStyles.my3]}>
          <Text testID="viewOrderDetailsText" style={[appStyles.body, { textDecorationLine: 'underline' }]}>
            {t('viewOrderDetails')}
          </Text>
          <Text testID="taxedGrossValueText" numberOfLines={1} style={[appStyles.body, utilityStyles.px2, { alignSelf: 'flex-end' }]}>
            {taxedGrossValue(cart) ?? ''}
          </Text>

          <Icon style={[{ alignSelf: 'flex-end' }]} name="arrow-right" size={12} stroke={colors.darkText} />
        </TouchableOpacity>
      </NavigationHeader>

      <View style={[styles.mainContainer]} key={'checkoutScreenMainContainerKey'}>
        <ScrollView ref={(ref) => setMainRef(ref)} key={'checkoutScrollViewKey'} contentContainerStyle={[styles.scrollViewContent, contentBottom]}>
          <CartInfoBanner onTitlePress={() => null} />
          <View key={'mainScrollViewView'} onLayout={(e) => setMainScrollViewY(e.nativeEvent.layout.y)} style={{}}>
            <Form
              key={'checkoutScreenFormKey'}
              initialValues={{
                firstName: customerContact?.firstName ?? '',
                lastName: customerContact?.lastName ?? '',
                pickup_person: getPickupPersonName(cart, ''),
                promoCode: getPromoCodeList()?.join(', '),
                phone: formatPhone(customerContact?.phone),
                allowText: customerContact?.allowText ?? false,
                delivery_instructions: getFulfillmentInstructions(cart, ''),
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}>
              <ErrorMessage error="Please complete all fields." visible={checkoutFailed} />
              <View key={'checkoutScreenFormContentKey'} style={[]}>
                {/* contact */}
                <Accordion
                  key={'checkoutScreenContactContainerKey'}
                  iconStyle={[appStyles.largeIcon]}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, contact: isOpen })}
                  title={t('contactTabName')}
                  isOpen={dropDownState.contact}>
                  {ContactSection()}
                </Accordion>

                {/* location */}
                <Accordion
                  key={'checkoutScreenLocationContainerKey'}
                  iconStyle={[appStyles.largeIcon]}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, pickup: isOpen })}
                  title={isDelivery(cart) ? t('deliveryTab') : t('pickupTab')}
                  isOpen={dropDownState.pickup}>
                  {isDelivery(cart) ? DeliverySection() : PickupSection()}
                </Accordion>

                {/* payment */}
                <View key={'postPaymentMarker'} onLayout={(e) => setPaymentMarkerY(e.nativeEvent.layout.y)} />
                <Accordion
                  key={'checkoutScreenPaymentContainerKey'}
                  style={{}}
                  iconStyle={[appStyles.largeIcon]}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, payment: isOpen })}
                  title={'Payment'}
                  isOpen={dropDownState.payment}>
                  {PaymentSection()}
                </Accordion>

                {/* SE Dollars  */}
                {availableVouchers?.length || acceptedVouchers?.length ? (
                  <Accordion
                    key={'checkoutScreenSEDollarsContainerKey'}
                    style={{}}
                    iconStyle={[appStyles.largeIcon]}
                    setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, sedollars: isOpen })}
                    title={'SE Dollars'}
                    isOpen={dropDownState.sedollars}>
                    {SEDollarsSection()}
                  </Accordion>
                ) : (
                  <></>
                )}

                {/* Promo Code Section */}
                <View
                  key={'checkoutScreenPromoContainerKey'}
                  style={{ borderBottomWidth: 1, borderTopWidth: dropDownState?.payment ? 1 : 0, borderColor: colors.sectionBorder }}>
                  <View style={[styles.contentPad, utilityStyles.my3]}>
                    <Text testID="promoCodeText" style={[appStyles.fontMobileH5, utilityStyles.my3]}>
                      {t('promoCode')}
                    </Text>
                    {promoCodeList}
                    <LinkButton style={[appStyles.smallLink, utilityStyles.pt2]} onPress={() => doOptionsPress('promo')}>
                      {t('addPromoCode')}
                    </LinkButton>
                  </View>
                </View>

                {/* tip */}
                {isDelivery(cart) && TipSection()}

                {/* fff Section */}
                <View key={'checkoutScreenFFFContainerKey'} style={[{ borderBottomWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }]}>
                  <View style={[styles.contentPad, utilityStyles.mt1, { flexDirection: 'row' }]}>
                    <Image
                      testID="checkoutScreenImage"
                      resizeMode="contain"
                      style={[styles.fffImage]}
                      source={{
                        uri: fffJpgUrl,
                      }}
                    />
                    <Text testID="checkoutScreenText" style={[appStyles.bodyBoldLarge, styles.dontationText]}>
                      {t('fffHeader')}
                    </Text>
                  </View>
                  <View style={[styles.contentPad, utilityStyles.mb2]}>
                    <Text testID="authSeeMoreText" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                      {t('fffDescription', { preauth_amount: cart?.totalPrice ? moneyValue(cart?.totalPrice, 1.15) : '0:00' })}&nbsp;&nbsp;
                      {!fffMore && (
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setFffMore(!fffMore)}>
                          {t('authSeeMore')}
                        </LinkButton>
                      )}
                    </Text>
                    {fffDescriptionMore && fffMore && (
                      <Text testID="authSeeLessText" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                        {fffDescriptionMore}&nbsp;&nbsp;
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setFffMore(!fffMore)}>
                          {t('authSeeLess')}
                        </LinkButton>
                      </Text>
                    )}
                    <View style={[styles.contentPad, styles.flexrow, utilityStyles.my1, { justifyContent: 'space-evenly' }]}>
                      <Button
                        testID="setDonation1"
                        type={'secondary'}
                        size={'small'}
                        title={'$1'}
                        buttonStyle={[styles.dollarButton, fffSelected === '1' ? styles.fffSelected : {}]}
                        textStyle={[fffSelected === '1' ? styles.fffSelected : {}]}
                        onPress={() => (fffSelected === '1' ? setDonationAmount('0') : setDonationAmount('1.00'))}
                      />
                      <Button
                        testID="setDonation5"
                        type={'secondary'}
                        size={'small'}
                        title={'$5'}
                        buttonStyle={[styles.dollarButton, fffSelected === '5' ? styles.fffSelected : {}]}
                        textStyle={[fffSelected === '5' ? styles.fffSelected : {}]}
                        onPress={() => (fffSelected === '5' ? setDonationAmount('0') : setDonationAmount('5.00'))}
                      />
                      <Button
                        testID="setDonation10"
                        type={'secondary'}
                        size={'small'}
                        title={'$10'}
                        buttonStyle={[styles.dollarButton, fffSelected === '10' ? styles.fffSelected : {}]}
                        textStyle={[fffSelected === '10' ? styles.fffSelected : {}]}
                        onPress={() => (fffSelected === '10' ? setDonationAmount('0') : setDonationAmount('10.00'))}
                      />

                      <View style={[styles.flexrow, { justifyContent: 'space-evenly', alignContent: 'center' }]}>
                        <TouchableOpacity onPress={() => doOptionsPress('fff')} style={{ flexDirection: 'row' }}>
                          <Button
                            testID="appllyOptionsScreenButton"
                            type={'secondary'}
                            size={'small'}
                            title={fffValue}
                            buttonStyle={[styles.dollarButton, fffSelected === 'other' ? styles.fffSelected : {}]}
                            textStyle={[fffSelected === 'other' ? styles.fffSelected : {}]}
                            onPress={() => (fffSelected === 'other' ? setDonationAmount('0') : doOptionsPress('fff'))}
                          />
                          <Text style={[appStyles.smallLink, utilityStyles.pl2, { alignSelf: 'center' }]}>{t('edit')}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>

                {/* Payment Disclaimer and Invoice */}
                <DropShadow key={'checkout_Disclaimer_section'} style={[{ borderBottomWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }]}>
                  <View style={[styles.contentPad, utilityStyles.mt3]}>
                    <Text testID="payDsiclaimerAuthMore" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                      {t('paymentDisclaimer', { preauth_amount: preAuthValue(cart, getConfigValue<number>('OrderAuthAmountMultiplier'), '0.00') })}
                      &nbsp;&nbsp;
                      {!showMoreText && (
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setShowMoreText(!showMoreText)}>
                          {t('authSeeMore')}
                        </LinkButton>
                      )}
                    </Text>
                    {paymentDisclaimerMore && showMoreText && (
                      <Text testID="paymentDisclaimerAuthLess" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                        {paymentDisclaimerMore}&nbsp;&nbsp;
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setShowMoreText(!showMoreText)}>
                          {t('authSeeLess')}
                        </LinkButton>
                      </Text>
                    )}
                  </View>
                  <Invoice obj={cart} backgroundColor={colors.cream} />
                  <TouchableOpacity
                    onPress={() => navigation.navigate(CheckoutStackRoutes.OrderSummary)}
                    style={[{ flexDirection: 'row', alignSelf: 'center' }, utilityStyles.mb3]}>
                    <Text testID="viewOrderDetails" style={[appStyles.body, { textDecorationLine: 'underline' }]}>
                      {t('viewOrderDetails')}
                    </Text>
                    <Text testID="taxedGrossValue" numberOfLines={1} style={[appStyles.body, utilityStyles.px2, { maxWidth: '30%', alignSelf: 'flex-end' }]}>
                      {taxedGrossValue(cart) ?? ''}
                    </Text>
                    <Icon testID="viewOrderArrowIcon" style={[{ alignSelf: 'flex-end' }]} name="arrow-right" size={12} stroke={colors.darkText} />
                  </TouchableOpacity>
                </DropShadow>

                {/* Terms of Use */}
                <DropShadow key={'checkout_TOU_section'} style={[{ shadowOpacity: 0 }]}>
                  <View key={'mainScrollViewView2'} style={[styles.termsSection, utilityStyles.py2]}>
                    <Text testID="checkoutTOUText" style={[appStyles.bodySmallLight]}>
                      By placing your order, you agree to our
                    </Text>
                    <View style={[styles.flexrow, { alignItems: 'center' }]}>
                      <View>
                        <WebBrowserLink href={routes.TERMS_OF_USE} style={[appStyles.footerLink]}>
                          Terms of Use
                        </WebBrowserLink>
                      </View>
                      <Text testID="andText" style={[appStyles.bodySmallLight, utilityStyles.mx1]}>
                        and
                      </Text>
                      <View>
                        <WebBrowserLink href={routes.PRIVACY_POLICY} style={[appStyles.footerLink]}>
                          Privacy Policy
                        </WebBrowserLink>
                      </View>
                    </View>
                  </View>
                </DropShadow>
              </View>
            </Form>
          </View>
        </ScrollView>
      </View>
      <BottomBar onLayout={onButtonLayout}>
        <Button
          testID="placeOrderButton"
          size={'small'}
          buttonStyle={[appStyles.cartBottomButton]}
          textStyle={cart?.lineItems?.length ?? 0 ? {} : { opacity: 0.3 }}
          onPress={() => placeOrder()}
          disabled={vouchersState === 'loading' || loading || isOrdering || isBusy || !(cart?.lineItems?.length ?? 0)}
          title={`${t('placeOrder')}`}
          isButtonLoading={!!transitCart || loading || isOrdering || vouchersState === 'loading' || isBusy}
        />
      </BottomBar>
      <Modal
        location={modalData.location ?? 'top'}
        title={modalData.title}
        visible={modalData.showModal}
        cancelButtonOnPress={() => setModalData({ ...modalData, showModal: false })}>
        {modalData.description ? (
          <Text style={[appStyles.fontMobileBodySmallRegular]}>{modalData.description}</Text>
        ) : (
          <Text>This feature is planned for a future version and is not yet available.</Text>
        )}
      </Modal>
      {/* Cart Options Modal */}
      <Modal
        visible={showCartOptions !== ''}
        title={
          showCartOptions === 'tip'
            ? 'Delivery Tip'
            : showCartOptions === 'ptype'
            ? 'Pay at Pickup Options'
            : showCartOptions === 'promo'
            ? 'Add Promo Code'
            : 'Donation Options'
        }
        titleStyle={{}}
        showCancel={false}
        showCloseIcon={false}
        cancelButtonOnPress={handleOptionsCancelPress}
        buttonContainerStyle={{ marginTop: 0, marginBottom: 0, borderTopWidth: 1, backgroundColor: colors.white, borderColor: colors.navBorder }}
        cancelButtonType="secondary"
        cancelButtonText=""
        okButtonText="Apply"
        okButtonType="primary"
        okButtonStyle={[appStyles.cartBottomButton]}
        okButtonOnPress={handleOptionsApplyPress}
        style={[showCartOptions === 'tip' ? styles.tipOptionsModalHeight : styles.paymentOptionsModalHeight, { backgroundColor: colors.cream }]}
        subTextViewStyle={{}}>
        {cartOptionsData}
      </Modal>

      {showDeleteModal && (
        <Modal
          visible={showDeleteModal}
          title={'Remove Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowDeleteModal(false)}
          okButtonOnPress={handleRemovePaymentMethod}
          cancelButtonText="Go back"
          okButtonText="Remove">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="removeMsg">
            Please confirm you'd like to remove this Payment Method from your Wallet.
          </Text>
        </Modal>
      )}
      {showSetDefaultModal && (
        <Modal
          visible={showSetDefaultModal}
          title={'Set as Default Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowSetDefaultModal(false)}
          okButtonOnPress={handleSetDefaultPayment}
          cancelButtonText="Go back"
          okButtonText="Update">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="setDefaultMsg">
            Please confirm you'd like to set this Payment Method as default.
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  modalButton: {
    width: 150,
  },
  paymentOptionsModalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.4 : screenHeight * 0.5) : screenHeight > 640 ? screenHeight * 0.4 : screenHeight * 0.5,
  },
  tipOptionsModalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.6 : screenHeight * 0.65) : screenHeight > 640 ? screenHeight * 0.55 : screenHeight * 0.65,
  },
  screen: {
    flex: 1,
    flexDirection: 'column',
    width: screenWidth,
    height: screenHeight,
  },
  mainContainer: {
    paddingTop: 0,
    flex: 1,
  },
  scrollViewContent: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.cream,
  },
  biggerHitArea: {
    width: 32,
    paddingTop: 12,
    paddingLeft: 18,
    alignItems: 'center',
    position: 'absolute',
    right: 0,
  },
  buttonView: {
    backgroundColor: colors.white,
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: colors.sectionBorder,
    paddingTop: 8,
  },
  helpLinks: {},
  contentPad: {
    flexDirection: 'column',
    width: containerWidth,
    alignSelf: 'center',
    paddingBottom: 0,
  },
  fffImage: {
    minWidth: 100,
    flex: 1,
    aspectRatio: 1,
    alignSelf: 'center',
  },
  textAlignCenter: { textAlign: 'center' },
  dontationText: { textAlign: 'center', alignSelf: 'center', width: '75%' },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flexGrow: { flexGrow: 1 },
  flexrow: { flexDirection: 'row' },
  flexcolumn: { flexDirection: 'column' },
  marginLeftAuto: { marginLeft: 'auto' },
  marginTopAuto: { marginTop: 'auto' },
  infoPadEnabled: { borderColor: colors.enabledOutline },
  infoPadSelected: { borderColor: colors.selectedOutline, borderWidth: 2 },
  infoPad: {
    backgroundColor: colors.white,
    padding: scale(18),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    minHeight: scale(90),
  },
  termsSection: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  promoCodePad: {
    backgroundColor: colors.white,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    borderRadius: 10,
  },

  infoPadLight: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
  },
  infoPadSeparator: {
    height: 1,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    margin: scale(3),
  },
  helpLinkSeparators: {
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    width: 1,
    flex: 0,
  },
  productImage: {
    width: 72,
    height: 72,
    borderWidth: 1,
    borderColor: colors.medium,
  },
  headSeparator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.medium,
    marginTop: 15,
    marginBottom: 2,
  },
  dollarButton: {
    minWidth: 65,
  },
  dollarButtonOther: {
    minWidth: 85,
    paddingBottom: 4,
  },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationAddress: {
    alignItems: 'flex-start',
    flex: 1,
  },
  locationInfo: {
    paddingRight: 6,
    width: '60%',
  },
  locationText: {
    fontSize: scale(16),
    color: colors.darkText,
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitBold,
  },
  promoCodeX: {
    transform: [{ rotate: '45deg' }],
    marginLeft: 'auto',
    paddingLeft: 10,
    fontSize: scale(28),
    color: colors.darkText,
    lineHeight: lineHeight(28),
    fontFamily: FontFamily.Larsseit,
    marginTop: -10,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    paddingRight: 10,
  },
  homeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentInfo: {
    ...utilityStyles.mt4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 0,
    marginBottom: Platform.OS === 'android' ? 60 : 0,
  },
  title: {
    alignSelf: 'flex-start',
    marginLeft: 20,
    marginBottom: 15,
    marginTop: 25,
  },
  fffSelected: {
    backgroundColor: colors.red,
    color: colors.white,
  },
  modalContentStyle: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'center',
    width: screenWidth * 0.6,
  },
  radioRow: {
    width: '100%',
    flexDirection: 'row',
    height: lineHeight(24),
    alignItems: 'center',
  },
  alertBox: {
    bottom:
      screenHeight < 736
        ? screenHeight * 0.225
        : screenHeight > 812
        ? screenHeight > 896
          ? screenHeight * 0.21
          : screenHeight * 0.215
        : screenHeight < 740
        ? screenHeight * 0.225
        : screenHeight * 0.225,
  },
});

export default CheckoutScreen;

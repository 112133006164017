import Text from '@components/Text';
import colors from '@config/colors';
import { useNavigation } from '@react-navigation/core';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { containerWidth, screenWidth } from '@styles/constants';
import React, { PropsWithChildren } from 'react';
import { GestureResponderEvent, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import BackButton from './BackButton';
import DropShadow from './DropShadow';
import { IconProps } from './Icon';

interface NavigationHeaderProps {
  title?: string | JSX.Element;
  titleStyle?: StyleProp<TextStyle>;
  titleIcon?: JSX.Element;
  subTitle?: string | JSX.Element;
  subTitleStyle?: StyleProp<TextStyle>;
  icon?: JSX.Element;
  iconProps?: IconProps;
  next?: JSX.Element;
  onNext?: (e: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  subStyle?: StyleProp<ViewStyle>;
  link?: any;
  linkParams?: object;
  onPress?: (e: GestureResponderEvent) => void;
  testID?: string;
  backIconstyle?: StyleProp<ViewStyle>;
  showShadow?: boolean;
}

const NavigationHeader = ({
  title,
  icon,
  iconProps,
  style,
  titleStyle,
  titleIcon,
  subTitle,
  subTitleStyle,
  onPress,
  link,
  linkParams,
  onNext,
  next,
  children,
  subStyle,
  testID,
  backIconstyle,
  showShadow = true,
}: PropsWithChildren<NavigationHeaderProps>) => {
  const navigation = useNavigation();

  const content = children ? <View style={[styles.subContainer, subStyle]}>{children}</View> : null;
  let nextContent = <></>;
  let nextSecondaryContent = <></>;
  if (next) {
    if (onNext) {
      nextContent = (
        <TouchableOpacity onPress={(e: GestureResponderEvent) => onNext(e)} style={styles.nextIconWrapper} testID="next">
          {next}
        </TouchableOpacity>
      );
    } else {
      nextContent = <View style={styles.nextIconWrapper}>{next}</View>;
    }
  }

  const handlePress = () => {
    if (link) {
      navigation.navigate(link, { ...linkParams });
    } else {
      let parent = navigation;
      while (parent.getState()?.index === 0 && parent.getParent()) {
        parent = parent.getParent();
      }
      parent?.goBack();
    }
  };

  return showShadow ? (
    <DropShadow style={[styles.container, style]}>
      <>
        <View testID={testID} style={[styles.subContainer, subStyle, utilityStyles.my2]}>
          {icon || <BackButton onPress={onPress ?? handlePress} style={[styles.backIconWrapper, backIconstyle]} iconProps={iconProps} />}
          {nextContent}
          {nextSecondaryContent}
          {titleIcon}
          <Text style={[appStyles.h3B, titleStyle]} testID="title">
            {title}
          </Text>
        </View>
        {subTitle ? (
          <View style={[styles.subContainer, subStyle]}>
            <Text style={[appStyles.h3B, subTitleStyle]} testID="subTitle">
              {subTitle}
            </Text>
          </View>
        ) : null}
        {content}
      </>
    </DropShadow>
  ) : (
    <View style={[styles.container, style]}>
      <>
        <View testID={testID} style={[styles.subContainer, subStyle, utilityStyles.my2]}>
          {icon || <BackButton onPress={onPress ?? handlePress} style={[styles.backIconWrapper, backIconstyle]} iconProps={iconProps} />}
          {nextContent}
          {nextSecondaryContent}
          <Text style={[appStyles.h3B, titleStyle]} testID="title">
            {title}
          </Text>
        </View>
        {subTitle ? (
          <View style={[styles.subContainer, subStyle]}>
            <Text style={[appStyles.h3B, subTitleStyle]} testID="subTitle">
              {subTitle}
            </Text>
          </View>
        ) : null}
        {content}
      </>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.cream,
    borderBottomWidth: 0,
  },
  subContainer: {
    width: containerWidth,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 4,
  },
  backIconWrapper: {
    position: 'absolute',
    left: 0,
  },
  nextIconWrapper: {
    position: 'absolute',
    right: 0,
  },
  nextSecondaryIconWrapper: {
    position: 'absolute',
    right: 64,
  },
});

export default NavigationHeader;

import { LoadingScreen } from '@components';
import Text from '@components/Text';
import withShopNavigator from '@components/hoc/withShopNavigator';
import { Image } from '@components/image';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { FileType } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCancelToken } from '@hooks';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import productService from '@services/brandywine/productService';
import { useShopStore, useUserProfileStore } from '@store';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useQuery } from 'react-query';

export type ProductCollectionItem = {
  productCollectionID: number;
  fileURL: string;
  productsCount: number;
};

type CollectionsLandingScreenProps = ShopStackScreenProps<ShopStackRoutes.CollectionsLanding>;

const CollectionsLandingScreen = withShopNavigator(
  ({ navigation }: CollectionsLandingScreenProps) => {
    const { t } = useTranslation('productCollection');

    const { userProfile } = useUserProfileStore();
    const { selectedStore } = useShopStore();
    const cancelToken = useCancelToken();
    const { data, isLoading, refetch, isRefetching } = useQuery(
      ['productCollections'],
      async () => await productService.getProductCollections([3], selectedStore?.number!, String(userProfile?.sepiNumber ?? ''), cancelToken),
      {
        select: (productCollection) =>
          productCollection
            .sort((p1, p2) => (p1.SortOrder < p2.SortOrder ? -1 : 0))
            .map((p) => {
              let productCollectionID = p.ProductCollectionId;

              let bannerURL = p.ProductCollectionFiles.find((f) => Number(f.FileType.Id) === FileType.MAIN_LIST_IMAGE)?.FileUrl.replace('/75/', '/0/') ?? '';
              let productsCount = p.ProductCollectionItems.length;

              return {
                productCollectionID: productCollectionID,
                fileURL: bannerURL,
                productsCount: productsCount,
              } as ProductCollectionItem;
            })
            .flatMap((url) => url)
            .filter((prod) => prod.fileURL.length > 0 && prod.productsCount > 0),
      },
    );

    const navigateToDetailScreen = (item: ProductCollectionItem) => {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.CollectionListing,
          params: {
            productCollectionId: item.productCollectionID,
          },
        },
      });
    };

    const renderEmptyListComponent = useCallback(
      () => (
        <View style={styles.emptyListContainer}>
          <Text testID="noData" style={styles.emptyListText}>
            {t('noData')}
          </Text>
        </View>
      ),

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    return isLoading ? (
      <LoadingScreen />
    ) : (
      <>
        <View style={[styles.container]}>
          <FlatList
            keyExtractor={(_, i) => i.toString()}
            data={data}
            ListEmptyComponent={renderEmptyListComponent()}
            renderItem={({ item }) => {
              return (
                <TouchableOpacity
                  style={[styles.collectionCardWrapper]}
                  onPress={() => {
                    navigateToDetailScreen(item);
                  }}>
                  <View style={[styles.collectionCard]}>
                    {item.fileURL && item.fileURL.length > 0 && (
                      <Image
                        testID="collectionImage"
                        style={[styles.collectionImage]}
                        resizeMode={'contain'}
                        source={{ cache: 'web', uri: item.fileURL.indexOf('//') === 0 ? 'https:' + item.fileURL : item.fileURL }}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              );
            }}
            contentContainerStyle={{ paddingBottom: screenHeight * 0.26 }}
            onRefresh={refetch}
            refreshing={isRefetching}
          />
        </View>
      </>
    );
  },
  { screenName: 'collections', showTombstone: false },
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: screenHeight,
  },
  collectionCardWrapper: {
    width: screenWidth,
  },
  collectionCard: {
    marginBottom: 10,
  },
  titleText: {
    color: colors.white,
  },
  collectionImage: {
    aspectRatio: 1.99,
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 15,
    width: '75%',
    textAlign: 'center',
    paddingBottom: 20,
  },
});

export default CollectionsLandingScreen;

import { Icon, NavigationHeader, Screen, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import HeaderTitle from '@components/HeaderTitle';
import { IconName, IconProps } from '@components/Icon';
import ListItem from '@components/lists/ListItem';
import colors from '@config/colors';
import { fontSize } from '@config/typography';
import { TourKey } from '@fieldera-raleys/client-common';
import routes, {
  AccountStackRoutes,
  AccountStackScreenProps,
  AppStackRoutes,
  HelpCenterStackRoutes,
  RootTabRoutes,
  StoreFinderStackRoutes,
} from '@navigation/routes';
import { useFocusEffect } from '@react-navigation/native';
import {
  useAuthStore,
  useCartStore,
  useCustomizeStore,
  useOffersStore,
  usePurchaseHistoryFilterStore,
  useSearchStore,
  useShopStore,
  useUserProfileStore,
} from '@store';
import useTutorialStore from '@store/tutorialStore';
import useUserSettingsStore from '@store/userSettingsStore';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, ColorValue, FlatList, ListRenderItemInfo, StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import { getBuildNumber, getVersion } from 'react-native-device-info';
import { Shape, useTourGuideController } from 'rn-tourguide';
import { useCustomModalContext } from '../../contexts';
import Cache from '../../utils/cache';
import { queryClient } from '../../utils/reactQuery';

type MenuItem = {
  title: string;
  subTitle?: string;
  icon?: IconProps;
  activeIcon?: boolean;
  target?: AccountStackRoutes | string | { (setting: {} | number | boolean | string | undefined): void };
  visible: boolean;
  targetType?: 'link' | 'screen' | 'function' | 'settings' | 'header';
  data?: object;
  backgroundColor: ColorValue;
  separatorFullWidth: boolean;
  actionIcon?: string;
  fontBold: boolean;
  style?: StyleProp<TextStyle>;
  tourGuide?: {
    tip: string;
    zone: number;
    shape: Shape;
  };
  isLoading?: boolean;
};

type AccountScreenProps = AccountStackScreenProps<AccountStackRoutes.AccountHome>;

const AccountScreen = ({ navigation, route }: AccountScreenProps) => {
  const { username, setAuthToken } = useAuthStore();
  const { userProfile } = useUserProfileStore();
  const { clearAll: clearUserSetting, userSettings, updateSetting: updateUserSetting } = useUserSettingsStore();
  const { getVisitCount, clearAll: clearTutorialSetting } = useTutorialStore();
  const { clearPurchasesStore } = usePurchaseHistoryFilterStore();
  const { clearSavedItems } = useCustomizeStore();
  const { clearShopData } = useShopStore();
  const { clearOffersStore } = useOffersStore();
  const buildNumber = getBuildNumber();
  const version = getVersion();
  const flatListRef = useRef<FlatList>(null);
  const { clear: clearSearchStore } = useSearchStore();
  const { showAlertModal } = useCustomModalContext();
  const { reload } = route.params;
  const { clearUserProfile } = useUserProfileStore();
  const { clearMyCarts } = useCartStore();
  const { t } = useTranslation('tourGuide');

  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function  to stopping it
    TourGuideZone,
  } = useTourGuideController(TourKey.AccountScreen);

  const startTourGuide = useCallback(() => {
    if (canStart && (userSettings?.showTourGuide ?? false) && getVisitCount('Account') === 1) {
      start();
    }
  }, [canStart, getVisitCount, start, userSettings?.showTourGuide]);

  useEffect(() => {
    startTourGuide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStart]);

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', async () => {
      // startTourGuide();
    });
    const unsubscribeBlur = navigation.addListener('blur', async () => {
      stop();
    });
    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
    };
  }, [navigation, startTourGuide, canStart, stop]);

  useEffect(() => {
    if (reload) {
      flatListRef.current?.scrollToOffset({ offset: 0 });
      navigation.setParams({ reload: false });
    }
  }, [navigation, reload]);

  useFocusEffect(
    useCallback(() => {
      flatListRef?.current?.scrollToOffset({ offset: 0, animated: false });
    }, []),
  );

  const [clearingCache, setClearfingCache] = useState(false);
  const [clearingCart, setClearfingCart] = useState(false);
  const doClearCache = async () => {
    setClearfingCache(true);
    queryClient.clear();
    await Cache.flush();
    clearSearchStore();
    clearShopData();
    clearPurchasesStore();
    clearUserSetting();
    clearSavedItems();
    clearOffersStore();
    clearUserProfile();
    clearTutorialSetting();
    setClearfingCache(false);
  };

  const handleClearCacheAndCart = async () => {
    setClearfingCart(true);
    await clearMyCarts();
    setClearfingCart(false);
    handleSignOut();
  };

  const handleSignOut = async () => {
    await doClearCache();
    setAuthToken(username, undefined);
  };

  const menuItems: MenuItem[] = [
    {
      title: 'Purchase History',
      target: AccountStackRoutes.OrderHistory,
      targetType: 'screen',
      data: { clearFilter: true },
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: true,
      activeIcon: false,
      tourGuide: {
        tip: t('tgPurchaseHistoryTxt'),
        zone: 1,
        shape: 'rectangle',
      },
    },
    {
      title: 'My Lists',
      target: AccountStackRoutes.ManageShoppingList,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: true,
      actionIcon: 'arrow-right',
      fontBold: true,
    },
    {
      title: 'Message Center',
      targetType: 'header',
      visible: true,
      backgroundColor: colors.sectionHeader,
      separatorFullWidth: true,
      actionIcon: undefined,
      fontBold: true,
    },
    {
      title: 'Inbox',
      target: AccountStackRoutes.MessageCenter,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
      activeIcon: (userSettings?.unreadMessageCount ?? 0) > 0,
    },
    {
      title: 'Communication Preferences',
      target: AccountStackRoutes.CommunicationPreferences,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: true,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Your Account',
      targetType: 'header',
      visible: true,
      backgroundColor: colors.sectionHeader,
      separatorFullWidth: true,
      actionIcon: undefined,
      fontBold: true,
    },
    {
      title: 'Profile',
      target: AccountStackRoutes.EditProfile,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Address Book',
      target: AccountStackRoutes.AddressBook,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Payment Methods',
      target: AccountStackRoutes.PaymentMethods,
      data: { showBack: true },
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Change Password',
      target: AccountStackRoutes.ChangePassword,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Favorite Store Locations',
      target: AccountStackRoutes.StoreFinder,
      data: { screen: StoreFinderStackRoutes.StoreFinderScreen, params: { calledFrom: 'FavoriteStoreLocations' } },
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'App Settings',
      target: AccountStackRoutes.AppSettings,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: true,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Information',
      targetType: 'header',
      visible: true,
      backgroundColor: colors.sectionHeader,
      separatorFullWidth: true,
      actionIcon: undefined,
      fontBold: true,
    },
    {
      title: 'Store Finder',
      target: AccountStackRoutes.StoreFinder,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Help Center',
      target: AccountStackRoutes.HelpCenter,
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'Contact Customer Service',
      target: AccountStackRoutes.HelpCenter,
      data: { screen: HelpCenterStackRoutes.ContactCustomerScreen },
      targetType: 'screen',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'arrow-right',
      fontBold: false,
    },
    {
      title: 'About',
      target: routes.ABOUT,
      targetType: 'link',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: true,
      actionIcon: 'linkout-icon',
      fontBold: false,
    },
    {
      title: 'Terms & Policies',
      targetType: 'header',
      visible: true,
      backgroundColor: colors.sectionHeader,
      separatorFullWidth: true,
      actionIcon: undefined,
      fontBold: true,
    },

    {
      title: 'Privacy Policy',
      target: routes.PRIVACY_POLICY,
      targetType: 'link',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'linkout-icon',
      fontBold: false,
    },
    {
      title: 'Terms of Use',
      target: routes.TERMS_OF_USE,
      targetType: 'link',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'linkout-icon',
      fontBold: false,
    },
    {
      title: 'Something Extra Terms and Conditions',
      target: routes.SE_TERMS_OF_USE,
      targetType: 'link',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: 'linkout-icon',
      fontBold: false,
    },
    {
      title: 'Notice at Collection',
      target: routes.NOTICE_AT_COLLECTION,
      targetType: 'link',
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: true,
      actionIcon: 'linkout-icon',
      fontBold: false,
    },
    // {
    //   title: 'Chat',
    //   target: AppStackRoutes.LiveAgent,
    //   targetType: 'screen',
    //   visible: true,
    //   backgroundColor: colors.white,
    //   separatorFullWidth: false,
    //   actionIcon: 'arrow-right',
    //   fontBold: false,
    // },
    {
      title: 'View Tour Guide',
      targetType: 'function',
      target: () => {
        updateUserSetting('showTourGuide', true);
        navigation.navigate(RootTabRoutes.Home);
        // if (navigation.canGoBack()) {
        //   navigation.goBack();
        // } else {
        //   navigation.navigate(RootTabRoutes.Home);
        // }
      },
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: undefined,
      fontBold: false,
    },
    {
      title: 'Clear Cache and Cart',
      isLoading: clearingCache || clearingCart,
      targetType: 'function',
      target: handleClearCacheAndCart,
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: undefined,
      fontBold: false,
    },
    {
      title: 'Logout',
      subTitle: `${version} (${buildNumber})`,
      targetType: 'function',
      target: handleSignOut,
      visible: true,
      backgroundColor: colors.white,
      separatorFullWidth: false,
      actionIcon: undefined,
      fontBold: false,
      style: { color: colors.red },
    },
  ];

  // Custom list item separator to handle full and custom width separator
  const renderItemSeparator = useCallback(({ leadingItem }: any) => {
    return (
      <View style={[styles.separatorContainer, { backgroundColor: leadingItem.backgroundColor }]}>
        <View style={[styles.separatorStyle, { width: leadingItem.separatorFullWidth ? screenWidth : screenWidth * 0.92 }]} />
      </View>
    );
  }, []);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<MenuItem>) => {
      const handleClick = async (mi: MenuItem) => {
        if (mi.targetType === 'screen' && typeof mi.target === 'string') {
          navigation.push(mi.target as AccountStackRoutes, mi.data);
        } else if (mi.targetType === 'link' && typeof mi.target === 'string') {
          navigation.push(AppStackRoutes.WebBrowser, { title: mi.title, url: mi.target });
        } else if (mi.targetType === 'function' && typeof mi.target === 'function') {
          mi.target.call(this, mi.data);
        } else if (mi.targetType !== 'header') {
          showAlertModal();
        }
      };

      return item.visible ? (
        <TourGuideZone text={item.tourGuide?.tip} zone={item.tourGuide?.zone!} shape={item.tourGuide?.shape!}>
          <ListItem
            testID={item.title}
            title={item.title}
            titleStyle={[item.fontBold ? appStyles.bodySmallBold : appStyles.bodySmallLight, item.style ? item.style : null]}
            style={[styles.listItemContainer, utilityStyles.px2, { backgroundColor: item.backgroundColor }]}
            iconProps={item.icon}
            onPress={() => {
              item.targetType === null ? null : handleClick(item);
            }}>
            {item.title === 'Logout' && (
              <View>
                <Text style={[appStyles.bodySmallLeftRegular, { fontSize: fontSize.TertiaryText }]} numberOfLines={2} testID={'logoutText'}>
                  {item.subTitle}
                </Text>
              </View>
            )}
            {item.isLoading ?? false ? (
              <View style={styles.iconContainer}>
                <ActivityIndicator />
              </View>
            ) : (
              item.actionIcon && (
                <View style={styles.iconContainer}>
                  {item.activeIcon && <View style={styles.redDot} />}
                  <Icon style={appStyles.icon} name={item.actionIcon as IconName} size={20} />
                </View>
              )
            )}
          </ListItem>
        </TourGuideZone>
      ) : null;
    },
    [TourGuideZone, navigation, showAlertModal],
  );

  return (
    <Screen style={utilityStyles.pb0}>
      <CartInfoBanner />
      <NavigationHeader icon={<></>} subStyle={[{ alignItems: 'flex-start', flexDirection: 'row' }, utilityStyles.pt2]}>
        <HeaderTitle style={{ width: '60%' }} testID="account">
          Account
        </HeaderTitle>
        <Text numberOfLines={2} style={[appStyles.bodySmall, styles.userName, { width: '40%' }]} testID="userName">
          {userProfile ? (userProfile.firstName ?? '') + ' ' + (userProfile.lastName ?? '') : ''}
        </Text>
      </NavigationHeader>
      <>
        <FlatList
          scrollEnabled={true}
          testID="accountFlatList"
          ref={flatListRef}
          data={menuItems}
          keyExtractor={(menuItem) => menuItem.title}
          ItemSeparatorComponent={renderItemSeparator}
          bounces={false}
          contentContainerStyle={styles.cardContainer}
          renderItem={renderItem}
          maxToRenderPerBatch={5}
          initialNumToRender={25}
        />
      </>
    </Screen>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 16,
    borderBottomWidth: 1,
  },
  cardContainer: {
    overflow: 'hidden',
  },
  listItemContainer: {
    width: '100%',
    height: 56,
  },
  separatorContainer: {
    height: 1.5,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separatorStyle: {
    backgroundColor: colors.sectionBorder,
    height: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  userName: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    marginBottom: 4,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  redDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: colors.red,
    marginRight: 15,
  },
});

export default AccountScreen;

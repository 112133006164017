import { CloseButton, Icon, NavigationHeader, Screen } from '@components';
import CustomizeButton from '@components/CustomizeButton';
import Text from '@components/Text';
import { Image } from '@components/image';
import colors from '@config/colors';
import { AddLineItem, ShoppingList, ShoppingListLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, CheckoutStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { StackActions, useNavigation } from '@react-navigation/native';
import { shoppingListService } from '@services/commerceTools/me';
import { useCartStore, useListsStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, screenWidth } from '@styles/constants';
import { getProductAttributeValue } from '@utils/productHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Source } from 'react-native-fast-image';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useCustomModalContext } from '../../../contexts';

type CustomizeNeededScreenProps = AccountStackScreenProps<AccountStackRoutes.CustomizeNeeded>;

type CustomizeItemProps = {
  item: ShoppingListLineItem;
  removeItem: (productKey: string) => void;
  routeParams: CustomizeNeededScreenProps;
  customizedPressed: (value: string) => void;
  getImageSource: (item: ShoppingListLineItem) => number | Source | undefined;
};

const CustomizeItem = ({ item, removeItem, routeParams, customizedPressed, getImageSource }: CustomizeItemProps) => {
  var uom = getProductAttributeValue('unitOfMeasure', item.variant?.attributesRaw ?? []);
  var upp = getProductAttributeValue('unitsPerPackage', item.variant?.attributesRaw ?? []);

  return (
    <View style={styles.customContainer} testID="customContainer">
      <View style={styles.customInfoWrapper}>
        <View style={styles.customImageWrapper} testID="customInfoWrapper">
          <Image testID="image" source={getImageSource(item)} style={{ alignSelf: 'center', width: '100%', height: '100%' }} resizeMode="cover" />
        </View>
        <View style={styles.customTitlesWrapper}>
          <Text style={appStyles.bodySmallLeft} testID="name">
            {item.name}
          </Text>
          <Text style={appStyles.bodySmallLeftRegular} testID="uppuom">
            {+upp} {uom}
          </Text>
        </View>
        <View style={styles.customPricingWrapper}>
          <Text style={appStyles.bodySmallLeft} testID="startAt">
            Starts at
          </Text>
          {item?.variant?.price && !isNaN(item?.variant?.price.value.centAmount) ? (
            <Text testID="itemPrice" style={appStyles.bodySmallLeft}>
              ${item.variant?.price?.value.centAmount / 10 ** (item.variant?.price?.value?.fractionDigits ?? 0)}
            </Text>
          ) : (
            <Text style={appStyles.bodySmallLeft} testID="dollarText">
              $ --
            </Text>
          )}
        </View>
      </View>
      <View style={styles.customActionWrapper}>
        <TouchableOpacity onPress={() => removeItem(item.variant?.sku ?? '')} testID="dontIncludeClick">
          <Text style={appStyles.bodySmallLightLinkCenter} testID="dontIncludeTxt">
            Don't Include
          </Text>
        </TouchableOpacity>
        <CustomizeButton
          onCustomize={customizedPressed}
          productKey={item.variant?.sku ?? ''}
          buttonStyle={styles.customButton}
          textStyle={styles.customButtonText}
          size={'small'}
          type={'secondary'}
          prevPageData={{
            parentId: 'favoriteList',
            params: {
              ...routeParams,
              refetchList: false,
            },
          }}
        />
        {/* <Button
          buttonStyle={styles.customButton}
          textStyle={styles.customButtonText}
          size="small"
          type={'secondary'}
          title="Customize"
          onPress={() => navToDetails(item.variant?.sku ?? '')}
        /> */}
      </View>
    </View>
  );
};

const CustomizeNeededScreen = ({ route }: CustomizeNeededScreenProps) => {
  const navigation = useNavigation();
  const tabBarHeight = useBottomTabBarHeight();
  const { listId, customItemIds, listName, refetchList = true, standardItemSkus, action, listVersion, lineItemIds } = route.params;
  const { cart, addStandardLineItems } = useCartStore();
  const { removeItemsFromShoppingList } = useListsStore();
  const [items, setItems] = useState<ShoppingListLineItem[]>();
  const [itemsCustomized, setItemsCustomized] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const fallbackImage = require('../../../assets/images/image-coming-soon-temp.png');

  const getImageSource = (item: ShoppingListLineItem) => {
    var url;
    var currUrl = item.variant?.images[0]?.url;
    if (currUrl === '' || !currUrl) {
      url = fallbackImage;
    } else {
      url = { cache: 'web', uri: `${currUrl?.indexOf('//') === 0 ? 'https:' + currUrl : currUrl}` };
    }
    return url;
  };

  const { showAlertPopup } = useCustomModalContext();
  var itemsArr = JSON.stringify(items);
  var cartStr = JSON.stringify(cart);

  const removeItem = (productKey: string) => {
    var newItems = items;
    newItems = newItems?.filter((i) => i.variant?.sku !== productKey);
    setItems(newItems);
    // removeItemFromList(productKey, listName ?? '');
  };

  const customizedPressed = (value: string) => {
    var newCustomized = itemsCustomized;
    newCustomized.push(value);
    setItemsCustomized(newCustomized);
  };

  const fetchList = useCallback(async () => {
    //check for only checked custom items
    setLoading(true);
    const listInfo: ShoppingList = await shoppingListService.getShoppingList({ listName: listName });
    const customItems = listInfo?.lineItems.filter((li) => {
      if (customItemIds?.includes(li.id)) {
        return li;
      }
    });
    setItems(customItems);
    setLoading(false);
  }, [listName, customItemIds]);

  useEffect(() => {
    refetchList && fetchList();
    if (!refetchList) {
      if (!items || items?.length < 1) {
        let addItems: AddLineItem[] = standardItemSkus
          ?.map((si) => {
            return { sku: si, quantity: 1 };
          })
          .filter((ai) => ai !== undefined);
        addStandardLineItems(addItems);
        if (action === 'Move to Cart') {
          removeItemsFromShoppingList(listId, listVersion, lineItemIds ?? [], []);
        }
        if (addItems.length || itemsCustomized.length) {
          showAlertPopup({
            message: `Item(s) ${action?.includes('Move') ? 'Moved' : 'Copied'} to Cart`,
            containerStyle: { bottom: tabBarHeight },
            linkText: 'View',
            linkAction: navToCart,
          });
        }
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: {
            screen: AccountStackRoutes.ManageShoppingList,
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchList, listId, customItemIds, refetchList]);

  useEffect(() => {
    if (items && items?.length < 1) {
      let addItems: AddLineItem[] = standardItemSkus
        ?.map((si) => {
          return { sku: si, quantity: 1 };
        })
        .filter((ai) => ai !== undefined);
      addStandardLineItems(addItems);
      if (action === 'Move to Cart') {
        removeItemsFromShoppingList(listId, listVersion, lineItemIds ?? [], []);
      }
      if (addItems.length || itemsCustomized.length) {
        showAlertPopup({
          message: `Item(s) ${action?.includes('Move') ? 'Moved' : 'Copied'} to Cart`,
          containerStyle: { bottom: tabBarHeight },
          linkText: 'View',
          linkAction: navToCart,
        });
      }
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.ManageShoppingList,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsArr, navigation, items]);

  useEffect(() => {
    if (cart?.lineItems.some((cli) => items?.some((i) => i.variant?.sku === cli.variant?.sku))) {
      for (let i = 0; i < itemsCustomized.length; i++) {
        if (cart?.lineItems.some((cli) => cli.variant?.sku === itemsCustomized[i])) {
          removeItem(itemsCustomized[i]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartStr, cart]);

  const navToCart = () => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: {
          store: undefined,
        },
      }),
    );
  };

  return (
    <Screen style={styles.screenBackground}>
      <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container }}>
        <NavigationHeader
          testID="customizationHeader"
          style={[styles.chekoutHeaderStyle]}
          subStyle={styles.navHeader}
          titleStyle={[appStyles.fontMobileH6, { alignSelf: 'center' }]}
          title={'Customization Needed'}
          icon={<CloseButton />}
        />
        <View style={styles.instructionsText}>
          <Icon testID="warningcircleIcon" fill={colors.primary} fillSecondary={'transparent'} name={'warning-circle'} size={30} />
          <Text testID="instructionText" style={[appStyles.bodySmallLeft, { width: '75%', marginLeft: 16 }]}>
            The following items need to be customized before adding to your cart.
          </Text>
        </View>
        <ScrollView contentContainerStyle={styles.listContainer} showsVerticalScrollIndicator={true}>
          {items?.map((ci) => {
            return (
              <CustomizeItem
                getImageSource={getImageSource}
                customizedPressed={customizedPressed}
                removeItem={removeItem}
                item={ci}
                routeParams={{ listId: listId, customItemIds: customItemIds, listName: listName, standardItemSkus: standardItemSkus, listVersion: listVersion }}
              />
            );
          })}
        </ScrollView>
      </Animated.View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: colors.cream,
  },
  instructionsText: {
    paddingVertical: 32,
    paddingLeft: 24,
    // justifyContent: 'center',
    // alignItems: 'center',
    borderColor: colors.darkCream,
    borderBottomWidth: 1.5,
    width: screenWidth * 0.9,
    flexDirection: 'row',
  },
  container: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    flex: 1,
  },
  listContainer: {
    width: screenWidth,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  navHeader: {
    backgroundColor: colors.cream,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  customContainer: {
    borderColor: colors.darkCream,
    borderBottomWidth: 1.5,
    width: '100%',
    paddingVertical: 12,
  },
  customInfoWrapper: {
    flexDirection: 'row',
    width: '100%',
    // justifyContent: 'space-between',
    height: 65,
    marginBottom: 8,
  },
  customImageWrapper: {
    width: 65,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    marginRight: 12,
  },
  customTitlesWrapper: {
    textAlign: 'left',
    width: '55%',
  },
  customPricingWrapper: {
    textAlign: 'right',
    flex: 1,
    alignItems: 'flex-end',
  },
  customActionWrapper: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  customButton: {
    height: 30,
    marginLeft: 24,
    paddingBottom: 0,
    paddingTop: 0,
  },
  customButtonText: {
    lineHeight: Platform.OS === 'ios' ? lineHeight(12) : lineHeight(14),
    // lineHeight: lineHeight(12),
    // paddingBottom: Platform.OS === 'ios' ? 18 : 0,
    textAlignVertical: 'center',
    margin: 0,
  },
});

export default CustomizeNeededScreen;

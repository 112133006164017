import { Icon } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { Store } from '@fieldera-raleys/client-common';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight } from '@styles/constants';
import React, { FC } from 'react';
import { Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { formatPhone } from '../../utils/helpers';

interface IStoreLocationListItemProps {
  store: Store;
  index?: number;
  currentSelectedLocation: Store | undefined;
  favorites: Store[] | undefined;
  selectLocation: (location: Store) => void;
  setFavorite: (location: Store) => void;
  isOnMapScreen?: boolean;
  showDetails?: boolean;
  onViewDetailPress?: (location: Store) => void;
}

const StoreLocationItem: FC<IStoreLocationListItemProps> = ({
  store,
  currentSelectedLocation,
  selectLocation,
  favorites,
  setFavorite,
  isOnMapScreen,
  index,
  showDetails,
  onViewDetailPress,
}): JSX.Element => {
  var shortenedAddress = store.address.street;
  if (store.address.street.length > 18) {
    shortenedAddress = store.address.street.slice(0, 18).concat('...');
  }
  return (
    <TouchableOpacity
      onPress={() => selectLocation(store)}
      testID="storeLocationItem"
      style={[
        styles.locationItem,

        {
          marginLeft: isOnMapScreen ? '10%' : '5%',
          marginVertical: isOnMapScreen ? -32 : 2,
          borderColor: currentSelectedLocation?.address.street === store.address.street && !isOnMapScreen ? 'red' : colors.darkGray,
          justifyContent: 'space-around',
        },
      ]}>
      <View style={styles.addressImageView}>
        <View style={styles.brandIconView}>
          {store.brand.name === 'Bel-Air' && (
            <Icon
              fill={colors.belAirRed}
              fillSecondary={colors.belAirBrown}
              stroke={'none'}
              size={70}
              name="belair"
              style={styles.logoStyle}
              testID="Bel-Air"
            />
          )}
          {store.brand.name.includes('ONE') && (
            <Icon
              testID="raleysonemarket"
              fillSecondary={colors.raleysOneLightGreen}
              fill={colors.raleysOneDarkGreen}
              stroke={'none'}
              size={70}
              name="raleysonemarket"
              style={styles.logoStyle}
            />
          )}
          {store.brand.name === "Raley's" && <Icon fill={colors.raleysRed} stroke={'none'} size={70} name="raleys" style={styles.logoStyle} testID="raleys" />}
          {store.brand.name === 'Nob Hill Foods' && (
            <Icon fill={colors.nobHillRed} stroke={'none'} size={70} name="nobhillfoods" style={styles.logoStyle} testID="nobhillfoods" />
          )}
        </View>
        <View style={styles.locationData}>
          <Text style={styles.locationInfoText} numberOfLines={1} testID="shortenedAddress">
            {shortenedAddress}
          </Text>
          <Text style={styles.locationCityText} numberOfLines={1} testID="storeAddressCity">
            {store.address.city}, {store.address.state}
          </Text>
          {store?.phone && (
            <Text style={styles.locationPhoneText} testID="storePhone">
              {formatPhone(store.phone)}
            </Text>
          )}
          <View style={styles.distanceWrapper}>
            <Text style={styles.distanceInfoText} numberOfLines={1} testID="storeDistance">{`${store.distance.toFixed(1)} miles away`}</Text>
          </View>
          {showDetails && (
            <TouchableWithoutFeedback onPress={() => onViewDetailPress && onViewDetailPress(store)} testID="viewDetailButton">
              <Text style={[appStyles.bodySmallLeftRedLight, styles.viewDetailText]} testID="viewDetail">
                View Detail
              </Text>
            </TouchableWithoutFeedback>
          )}
        </View>
      </View>
      {isOnMapScreen && (
        <View style={styles.markerWrapper}>
          <Icon name="location-pin" fill={colors.secondary} stroke={colors.secondary} style={appStyles.largeIcon} size={30} testID="locationpin" />
          <Text style={styles.markerIconText} testID="markerText">
            {index}
          </Text>
        </View>
      )}

      <TouchableWithoutFeedback onPress={() => setFavorite(store)} testID="setFavouriteButton">
        <View style={styles.starWrapper}>
          {favorites?.some((favorite) => favorite.address.street === store.address.street) ? (
            <Icon name="star-icon" size={30} fill={colors.darkText} stroke={colors.darkText} style={appStyles.largeIcon} testID="favIcon" />
          ) : (
            <Icon name="star-icon" size={30} fill={'none'} stroke={colors.darkText} style={appStyles.largeIcon} testID="NonFavIcon" />
          )}
        </View>
      </TouchableWithoutFeedback>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  locationItem: {
    flexDirection: 'row',
    backgroundColor: 'white',
    width: '90%',
    height: 120,
    // height: screenHeight * 0.14,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1.5,
    borderRadius: 5,
    padding: 10,
    marginBottom: Platform.select({
      ios: scale(5),
      android: scale(16),
    }),
  },
  locationData: {
    width: screenHeight * 0.2,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  locationInfoText: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    lineHeight: lineHeight(18),
    textAlign: 'left',
    fontSize: scale(15),
  },
  locationCityText: {
    color: colors.darkText,
    marginTop: Platform.OS === 'ios' ? 4 : 2,
    fontSize: scale(15),
    fontFamily: 'Larsseit-Light',
    textAlign: 'left',
  },
  locationPhoneText: {
    color: colors.darkText,
    marginTop: Platform.OS === 'ios' ? 7 : 2,
    fontSize: scale(15),
    fontFamily: 'Larsseit-Light',
    textAlign: 'left',
  },
  distanceWrapper: {
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  distanceInfoText: {
    color: colors.darkText,
    marginTop: Platform.OS === 'ios' ? 7 : 2,
    fontSize: scale(15),
    fontFamily: 'Larsseit-Light',
    textAlign: 'left',
    width: '100%',
  },
  logoStyle: {
    width: 70,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  markerWrapper: {
    position: 'absolute',
    width: 50,
    height: 50,
    top: 0,
    right: 0,
    paddingRight: 5,
    paddingTop: 10,
    alignContent: 'center',
    alignItems: 'center',
  },
  markerIconText: {
    position: 'absolute',
    color: colors.white,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(13),
    top: 15,
    paddingEnd: 10,
  },
  starWrapper: {
    position: 'absolute',
    width: 50,
    height: 50,
    bottom: 0,
    right: 0,
    paddingRight: 10,
    paddingBottom: 5,
    zIndex: 99,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  brandIconView: {
    height: '100%',
    width: screenHeight * 0.12,
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: 0,
    marginTop: screenHeight > 667 ? 0 : -6,
    paddingRight: 16,
  },
  viewDetailText: {
    textDecorationLine: 'underline',
    fontSize: scale(14),
    lineHeight: lineHeight(18),
  },
  addressImageView: {
    width: '80%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginRight: '12%',
  },
});

export default StoreLocationItem;

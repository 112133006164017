import { AuthenticationType } from '@fieldera-raleys/client-common';
import { Platform } from 'react-native';
import { create } from 'zustand';
import { DeviceInfoStore } from './storeTypes';

const useDeviceInfoStore = create<DeviceInfoStore>()((set, get) => ({
  deviceInfo: {
    supportsBiometrics: false,
    biometricsType: [],
    hasLocationPermissions: false,
    hasNotificationPermissions: false,
    requestPermissions: false,
    hasCameraPermission: false,
  },
  initialize: (info) => {
    set((state) => ({ ...state, deviceInfo: info }));
  },
  updateSetting: (name, value) => {
    set((state) => ({ ...state, deviceInfo: { ...state.deviceInfo, [name]: value } }));
  },
  getBiometricType: () => {
    var biometricType = '';
    const deviceInfo = get().deviceInfo;
    if (deviceInfo && deviceInfo.biometricsType?.length) {
      if (deviceInfo.biometricsType.length > 1) {
        // Device has both Fingerprint/Face Unlock enabled
        biometricType = 'Biometrics';
      } else {
        switch (deviceInfo.biometricsType[0]) {
          case AuthenticationType.FINGERPRINT:
            biometricType = Platform.OS === 'ios' ? 'Touch ID' : 'Fingerprint Unlock';
            break;
          case AuthenticationType.FACIAL_RECOGNITION:
          case AuthenticationType.IRIS:
            biometricType = Platform.OS === 'ios' ? 'Face ID' : 'Face Unlock';
            break;
          default:
            biometricType = 'Not Defined';
            break;
        }
      }
    }
    return biometricType;
  },
}));

export default useDeviceInfoStore;

import { MessageCenterStackRoutes, MessageCenterStackScreenProps } from '@navigation/routes';
import React from 'react';
import { View } from 'react-native';
import styles from './styles';

type MessageScreenProps = MessageCenterStackScreenProps<MessageCenterStackRoutes.MessageDetails>;

const MessageScreen = ({}: MessageScreenProps) => {
  return <View style={styles.backgroundContainer}>{'Not Supported'}</View>;
};

export default MessageScreen;

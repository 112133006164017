import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useEffectOnce } from '@hooks';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import React, { useState } from 'react';
import { Image, ListRenderItemInfo, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Carousel from '../Carousel';
import { brxmNavigation } from './utils';

const SHELF_GUIDE_SIZE = 32;
const SHELF_GUIDE_PAD = 2;
const SLIDE_GAP = (screenWidth - containerWidth) / 2;
const CategoryComponent = ({ component, page }: BrProps) => {
  const navigation = useNavigation();
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const linkto = useLinkTo();
  const { showAlertModal } = useCustomModalContext();

  // slide size calculations based on container
  const [forgetConfig, setForgetConfig] = useState<{ slidesPerPage: number; nextSlidePartial: number; slideWidth: number; sgPerLine: number }>({
    slidesPerPage: 1,
    nextSlidePartial: containerWidth * 0.5,
    slideWidth: containerWidth,
    sgPerLine: 1,
  });

  const setSlidesPerPage = (n: number) => {
    const slideWidth = (containerWidth - (forgetConfig.nextSlidePartial ? forgetConfig.nextSlidePartial + 2 * SLIDE_GAP : SLIDE_GAP)) / n;
    setForgetConfig({ ...forgetConfig, slideWidth: slideWidth, sgPerLine: slideWidth / (SHELF_GUIDE_SIZE + 2 * SHELF_GUIDE_PAD), slidesPerPage: n });
  };

  useEffectOnce(() => {
    setSlidesPerPage(forgetConfig.slidesPerPage);
  });

  const handleNavigation = (item: any): void => {
    brxmNavigation(item.categoryId, item.navigationType, navigation, linkto, showAlertModal);
  };

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  let categoryData = document.getData().categoryCard;
  const { title } = document.getData();

  if (categoryData) {
    for (let i = 0; i < categoryData.length; i++) {
      categoryData[i].Image = page.getContent<ImageSet>(categoryData[i].categoryImage);
      categoryData[i].ImageUrl = categoryData[i].Image?.getOriginal()?.getUrl();
    }
  }

  const renderItem = ({ item, index }: ListRenderItemInfo<any>) => {
    return (
      <View key={'carousel_item_key_' + index} style={styles.carouselItem}>
        {index ? <View style={styles.carouselItemPadding} /> : null}
        <View style={[{ backgroundColor: colors.cream, width: forgetConfig.slideWidth }]}>
          <View style={[styles.catagoryImage, { width: forgetConfig.slideWidth }]}>
            <TouchableOpacity key={'image_key_index_' + index} activeOpacity={0.75} onPress={() => handleNavigation(item)}>
              <Image
                accessibilityLabel={item.categoryImageAlt ?? item.categoryName}
                source={{ cache: 'default', uri: item.ImageUrl }}
                style={{
                  alignSelf: 'center',
                  width: forgetConfig.slideWidth,
                  height: forgetConfig.slideWidth,
                  borderWidth: 1,
                  borderColor: colors.offerBorder,
                }}
                resizeMode="contain"
              />
              <Text style={[appStyles.bodySmallBold, utilityStyles.my2, utilityStyles.pb4, { textAlign: 'center' }]}>{item.categoryName}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      <View style={styles.categoryContainer} testID="GridComponent">
        <Text style={[appStyles.dropShadowTitle]} testID="GridComponetTitle">
          {title}
        </Text>
      </View>
      <View style={[styles.forgetContainer]}>
        <View style={[styles.forgetContent]}>
          <View style={[styles.forgetCarousel]}>
            <Carousel
              windwoSize={12}
              snapToInterval={forgetConfig.slideWidth + SLIDE_GAP}
              contentContainerStyle={{
                ...styles.carouselContainer,
                width: categoryData.length * (forgetConfig.slideWidth + SLIDE_GAP) - SLIDE_GAP,
                marginRight: 10,
              }}
              entities={categoryData || []}
              renderItem={renderItem}
              pagerStyle={styles.pagerStyle}
              pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
              pagerInactiveItemStyle={appStyles.carouselDotsSmall}
              showPager={false}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  categoryContainer: {
    paddingTop: 20,
    backgroundColor: colors.cream,
    flex: 1,
    width: containerWidth,
    alignSelf: 'center',
  },
  gridBox: {
    paddingTop: 10,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  categoryBox: {
    width: '50%',
    paddingTop: 15,
    paddingHorizontal: 5,
  },
  squareImage: {
    width: screenWidth * 0.45,
    aspectRatio: 1,
  },
  catagoryImage: {
    backgroundColor: colors.cream,
  },
  h2: {
    ...appStyles.h2,
    lineHeight: 40,
  },
  link: {
    textDecorationLine: 'none',
    textAlign: 'center',
  },
  h5: {
    color: colors.text,
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(26),
  },
  categoryLink: {
    textAlign: 'center',
    color: colors.red,
    fontSize: 20,
    fontFamily: FontFamily.AleoBold,
    lineHeight: 23,
    textDecorationLine: 'underline',
    textDecorationColor: colors.red,
    textDecorationStyle: 'solid',
    top: 40,
    left: 100,
  },
  pagerStyle: {
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  carouselItem: {
    flexDirection: 'row',
  },
  forgetCarousel: {
    borderWidth: 0,
    marginTop: scale(10),
    backgroundColor: colors.cream,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  forgetContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    width: screenWidth,
    backgroundColor: colors.cream,
  },
  forgetContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    width: containerWidth,
    backgroundColor: colors.cream,
  },
  carouselContainer: {
    marginTop: scale(10),
    borderWidth: 0,
  },
  carouselItemPadding: {
    borderWidth: 0,
    borderColor: 'blue',
    width: SLIDE_GAP,
  },
});

export default CategoryComponent;

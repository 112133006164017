import appConstants from '@config/appConstants';
import { OrderService } from '@fieldera-raleys/client-common';
import { DateRange, Order, OrderType, PagedArray, ShippingMethodType } from '@fieldera-raleys/client-common/types';
import Cache from '@utils/cache';
import { CancelToken } from 'apisauce';
import dayjs from 'dayjs';
import Config from 'react-native-config';
import { UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useQuery } from 'react-query';
import { getJwtToken } from '../authHelper';

const orderService = new OrderService({
  apiUrl: Config.BW_API_URL,
  authKey: Config.BW_AUTH_KEY,
  authUrl: Config.BW_AUTH_URL,
  authToken: getJwtToken,
  cache: Cache,
});

const useSearchOrders = (
  purchaseType: (keyof typeof OrderType)[] = ['In-store', 'Online'],
  shippingMethod: (keyof typeof ShippingMethodType)[] = ['Delivery', 'Pickup'],
  dateRange: DateRange = { startDate: dayjs().subtract(1, 'month').toDate() },
): UseInfiniteQueryResult<PagedArray<Order>, Error> => {
  return useInfiniteQuery<PagedArray<Order>, Error>(
    ['orders', shippingMethod, purchaseType, dateRange],
    async ({ pageParam, signal }) => {
      const cts = CancelToken.source();
      signal?.addEventListener('abort', () => {
        cts.cancel('Query was cancelled by React Query');
      });
      return orderService.fetchOrders(
        pageParam,
        appConstants.PAGE_SIZE,
        {
          orderType: purchaseType,
          fulfillmentType: shippingMethod,
          dateRange: dateRange,
        },
        cts.token,
      );
    },
    {
      keepPreviousData: false,
      getNextPageParam: (lastPage) => {
        if (lastPage.total > lastPage.offset + appConstants.PAGE_SIZE) {
          return lastPage.offset + appConstants.PAGE_SIZE;
        }
      },
    },
  );
};

const useGetOrder = (orderNumber: string): UseQueryResult<Order, Error> => {
  return useQuery<Order, Error>(['order', orderNumber], () => orderService.getOrder(orderNumber));
};

export default { useGetOrder, useSearchOrders, ...orderService };

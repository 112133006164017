import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { LoadingScreen, Screen } from '@components';
import { mapping } from '@components/bloomreach';
import { useBrxmPage } from '@hooks';
import * as Location from '@hooks/useLocation';
import * as Notifications from '@hooks/useNotifications';
import { AppStackRoutes, AppStackScreenProps, RootTabRoutes } from '@navigation/routes';
import useDeviceInfoStore from '@store/deviceInfoStore';
import logger from '@utils/logger';
import React, { useLayoutEffect } from 'react';
import { StyleSheet, View } from 'react-native';

type PermissionsProp = AppStackScreenProps<AppStackRoutes.Permissions>;

const PermissionsScreen = ({ navigation }: PermissionsProp) => {
  const { deviceInfo, updateSetting: updateDeviceInfoSetting } = useDeviceInfoStore();
  const { brxmConfiguration, brxmPage } = useBrxmPage();

  const handleSubmit = async () => {
    try {
      if (deviceInfo && !deviceInfo.hasLocationPermissions && (await Location.canRequestPermissions())) {
        deviceInfo.hasLocationPermissions = await Location.requestPermissions();
      }
    } catch (ex) {
      logger.error(ex);
    }

    try {
      if (deviceInfo && !deviceInfo.hasNotificationPermissions && (await Notifications.canRequestPermissions())) {
        const hasPermissions = await Notifications.requestPermissions();
        Notifications.enableUserNotifications(hasPermissions);
        deviceInfo.hasNotificationPermissions = hasPermissions;
      }
    } catch (ex) {
      logger.error(ex);
    }

    updateDeviceInfoSetting('requestPermissions', false);
    navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home, params: {} });
  };

  useLayoutEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !brxmPage || !brxmConfiguration ? (
    <LoadingScreen />
  ) : (
    // @ts-ignore
    <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
      <Screen>
        <View style={styles.brxmContainer}>
          <BrComponent path="main" />
        </View>
      </Screen>
    </BrPage>
  );
};

const styles = StyleSheet.create({
  brxmContainer: {
    height: '80%',
  },
  buttonContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 15,
  },
});
export default PermissionsScreen;

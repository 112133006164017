import { Button, DropShadow, Icon, ListAccordionItem, ListHeader, Screen, SearchHeader } from '@components';
import Text from '@components/Text';
import ProductCarousel from '@components/brsm/ProductCarousel';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product, ShoppingList, ShoppingListLineItem, TextLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AccountStackScreenProps } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useIsFocused } from '@react-navigation/native';
import { shoppingListService } from '@services/commerceTools/me';
import { useAppConfigStore, useListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import { getProductQuantityValue } from '@utils/orderHelpers';
import {
  getDisplayPriceBySellType,
  getProductAisleBayBinForListManagement,
  getProductAttributeValue,
  getProductsfromCommerceTools,
  getRootCategory,
} from '@utils/productHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../../contexts';

//Store attributes needed:
//name, aisle_bay, price, leadtime, image, unit, qty, sku

type ViewListScreenProps = AccountStackScreenProps<AccountStackRoutes.ViewList>;

const ViewListScreen = ({ route }: ViewListScreenProps) => {
  const { title, listName, listId } = route.params;
  const { showModal } = useCustomModalContext();
  const [data, setData] = useState<ShoppingList>();
  const [categories, setCategories] = useState<string[]>([]);
  const [showChecked, setShowChecked] = useState(true);
  const [unchecked, setUnchecked] = useState<(ShoppingListLineItem | TextLineItem)[]>([]);
  const [checked, setChecked] = useState<(ShoppingListLineItem | TextLineItem)[]>([]);
  const [checkedCategories, setCheckedCategories] = useState<string[]>([]);
  const [productData, setProductData] = useState<Product[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [prodCategories, setProdCategories] = useState<{ prodSku: string; category: string }[]>([]);
  const { lists, unCheckAll, clearList, removeItemsFromShoppingList, getListSelectedFilter } = useListsStore();
  const pageConfig = useAppConfigStore().getConfigGroup('ShoppingList');
  var listArr = JSON.stringify(lists);
  //TODO: Fetch list data using id (from cache?)
  // var estValue = sumProductPriceList(data?.lineItems ?? []);
  var value = 0;
  var numItems = 0;
  var numChecked = 0;
  const tabBarHeight = useBottomTabBarHeight();

  const displayPrice = useCallback(
    (item: ShoppingListLineItem) => {
      let sellType = getProductAttributeValue('unitSellType', item.variant?.attributesRaw ?? []) ?? { key: 'byEach', label: 'By Each' };

      const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', item.variant?.attributesRaw ?? []);

      const priceCustomValues = item.variant?.price?.custom?.customFieldsRaw ?? [];

      let onSale =
        (item.variant?.price?.value.centAmount ?? 0) < (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount ?? 0) ? true : false;

      let qty = 1;

      if (productData && productData.find((p) => p.masterData.current?.masterVariant.sku === item.variant?.sku ?? '')?.masterData?.current) {
        qty = getProductQuantityValue(
          productData.find((p) => p.masterData.current?.masterVariant.sku === item.variant?.sku ?? '')?.masterData.current!,
          item.quantity,
        );
      }

      return onSale
        ? getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty, false)
        : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount
          ? getDisplayPriceBySellType(sellType.key, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, unitAverageBuyWeight, qty, false)
          : getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty, false);
    },

    [productData],
  );

  data?.lineItems.forEach((li) => {
    value += Number(displayPrice(li) ?? 0) ?? 0;
    numItems += li.quantity;
    numChecked += li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true ? li.quantity : 0;
  });
  data?.textLineItems.forEach((ti) => {
    numItems += ti.quantity;
    numChecked += ti.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true ? ti.quantity : 0;
  });

  const getListData = useCallback(async () => {
    const listData: ShoppingList = await shoppingListService.getShoppingList({ listName: listName });
    var productList: Product[] | undefined;

    if (listData) {
      if (listData.lineItems.some((li) => !li.variant)) {
        let emptyItems = listData.lineItems.filter((li) => !li.variant);
        let itemIds = emptyItems?.map((fli) => fli.id);
        let itemNames = emptyItems?.map((fli) => fli.name);

        await removeItemsFromShoppingList(listData.id, listData.version, itemIds, []);

        showModal({
          title: 'Missing Items',
          children: (
            <>
              <Text style={appStyles.bodyLargeCenter} testID="problemMsg">
                Sorry, there was a problem loading the following list items:
              </Text>
              {itemNames.map((name) => {
                return (
                  <Text style={[appStyles.bodyLargeCenter, { marginVertical: 8, fontFamily: FontFamily.LarsseitBold }]} testID="name">
                    {name}
                  </Text>
                );
              })}
              <Text style={appStyles.bodyLargeCenter} testID="removeMsg">
                They have been removed from your list. Please re-add where applicable.
              </Text>
            </>
          ),
          // cancelButtonOnPress: () => logger.log('back'),
          cancelButtonText: 'OK',
          location: 'top',
          showCloseIcon: false,
          contentStyle: {
            paddingTop: 8,
          },
        });
      }

      productList = await getProductsfromCommerceTools(listData.lineItems.map((x) => x.variant?.sku as string));
      if (productList) {
        // mapProductCategories(productList);
        setProductData(productList);
      }
    }

    var uncheckedItems: (ShoppingListLineItem | TextLineItem)[] = listData.lineItems
      .filter(
        (li) =>
          li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === false ||
          !li.custom ||
          (li.custom && !li.custom.customFieldsRaw?.find((cf) => cf.name === 'listChecked')),
      )
      .sort((a, b) => {
        if (b.addedAt < a.addedAt) {
          return -1;
        }
        if (b.addedAt > a.addedAt) {
          return 1;
        }
        return 0;
      });

    uncheckedItems = uncheckedItems.concat(
      listData?.textLineItems
        ?.filter(
          (li) =>
            li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === false ||
            !li.custom ||
            (li.custom && !li.custom.customFieldsRaw?.find((cf) => cf.name === 'listChecked')),
        )
        .sort((a, b) => {
          if (b.addedAt < a.addedAt) {
            return -1;
          }
          if (b.addedAt > a.addedAt) {
            return 1;
          }
          return 0;
        }),
    );

    var checkedItems: (ShoppingListLineItem | TextLineItem)[] = listData.lineItems.filter(
      (li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true,
    );

    checkedItems = checkedItems.concat(
      listData?.textLineItems
        ?.filter((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true)
        .sort((a, b) => {
          if (b.addedAt < a.addedAt) {
            return -1;
          }
          if (b.addedAt > a.addedAt) {
            return 1;
          }
          return 0;
        }),
    );

    var prodCats: { prodSku: string; category: string }[] = [];
    var cats: string[] = [];

    uncheckedItems.forEach((s: ShoppingListLineItem | TextLineItem) => {
      let prod = 'variant' in s ? productList?.find((p) => p.key === s.variant?.sku || p.masterData.current?.masterVariant.sku === s.variant?.sku) : undefined;

      if (getListSelectedFilter().toLowerCase() === 'product location') {
        let location = prod && prod.masterData.current ? getProductAisleBayBinForListManagement(prod.masterData.current) ?? 'Other' : 'Other';

        if (location) {
          prodCats.push({ prodSku: prod?.masterData.current?.masterVariant.sku ?? prod?.key ?? '', category: location });
        }
        if (!cats.includes(location as string)) {
          cats.push(location as string);
        }
      } else {
        let category = prod ? getRootCategory(prod?.masterData?.current?.categories)?.name ?? 'Other' : 'Other';

        if (category) {
          prodCats.push({ prodSku: prod?.masterData.current?.masterVariant.sku ?? prod?.key ?? '', category: category });
        }

        if (!cats.includes(category as string)) {
          cats.push(category as string);
        }
      }
    });

    var latestItem: ShoppingListLineItem = uncheckedItems[0] as ShoppingListLineItem;
    uncheckedItems.forEach((i) => {
      if (i.addedAt > latestItem.addedAt && 'variant' in i) {
        latestItem = i;
      }
    });

    if (latestItem) {
      latestItem = 'variant' in latestItem ? productList?.find((p) => p.key === latestItem?.variant?.sku) : latestItem;
      var latestCat = latestItem ? getRootCategory(latestItem?.masterData?.current?.categories)?.name : '';
      cats = [...cats.filter((c) => c === latestCat), ...cats.filter((ca) => ca !== latestCat)];
    }

    setCategories(cats.sort((c1, c2) => c1.localeCompare(c2)));

    cats = [];
    checkedItems.forEach((s: ShoppingListLineItem | TextLineItem) => {
      let prod = 'variant' in s ? productList?.find((p) => p.key === s.variant?.sku || p.masterData.current?.masterVariant.sku === s.variant?.sku) : undefined;

      if (getListSelectedFilter().toLowerCase() === 'product location') {
        let location = prod && prod.masterData.current ? getProductAisleBayBinForListManagement(prod.masterData.current) ?? 'Other' : 'Other';

        if (location) {
          prodCats.push({ prodSku: prod?.masterData.current?.masterVariant.sku ?? prod?.key ?? '', category: location });
        }
        // let category = s.variant?.attributesRaw.find((att: CustomFieldInput) => att.name === 'fulfillmentDepartment')?.value.label ?? '';
        if (!cats.includes(location as string)) {
          cats.push(location as string);
        }
      } else {
        let category = prod ? getRootCategory(prod?.masterData?.current?.categories)?.name ?? 'Other' : 'Other';

        if (category) {
          prodCats.push({ prodSku: prod?.masterData.current?.masterVariant.sku ?? prod?.key ?? '', category: category });
        }
        // let category = s.variant?.attributesRaw.find((att: CustomFieldInput) => att.name === 'fulfillmentDepartment')?.value.label ?? '';
        if (!cats.includes(category as string)) {
          cats.push(category as string);
        }
      }
    });

    setProdCategories(prodCats.sort((pc1, pc2) => pc1.category.localeCompare(pc2.category)));
    setCheckedCategories(cats.sort((c1, c2) => c1.localeCompare(c2)));

    setData(listData);
    setUnchecked(uncheckedItems);
    setChecked(checkedItems);

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listName, getListSelectedFilter]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getListData();
    }
  }, [getListData, listArr, getListSelectedFilter, isFocused]);

  const findItems = (searchTerm: string | undefined) => {
    if (!searchTerm) {
      return;
    }
    setSearchText(searchTerm);
  };

  const unCheckAllItems = async () => {
    const lineItemIds = data?.lineItems
      .filter((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true)
      .map((li) => {
        return li.id;
      });
    const textLineItemIds = data?.textLineItems
      .filter((ti) => ti.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true)
      .map((ti) => {
        return ti.id;
      });
    await unCheckAll(listId, data?.version, lineItemIds ?? [], textLineItemIds ?? []);
  };

  const clearListItems = async () => {
    await clearList(listId);
  };

  const useListAgain = () => {
    showModal({
      title: 'Uncheck Your Items?',
      children: (
        <Text style={appStyles.bodyLargeCenter} testID="uncheckMsg">
          Are you sure you want to uncheck your list items?
        </Text>
      ),
      cancelButtonText: 'Back',
      okButtonOnPress: unCheckAllItems,
      okButtonText: 'Yes',
      location: 'top',
      showCloseIcon: false,
      contentStyle: {
        paddingTop: 8,
      },
    });
  };

  const clearAllListItems = () => {
    showModal({
      title: 'Clear List?',
      children: (
        <Text style={appStyles.bodyLargeCenter} testID="removeAllMsg">
          Are you sure that you want to remove all items on your list?
        </Text>
      ),
      // cancelButtonOnPress: () => logger.log('back'),
      cancelButtonText: 'Back',
      okButtonOnPress: clearListItems,
      okButtonText: 'Yes',
      location: 'top',
      showCloseIcon: false,
      contentStyle: {
        paddingTop: 8,
      },
    });
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <SearchHeader
        searchText={searchText}
        setSearchText={setSearchText}
        showAddToList={true}
        fromListName={listName}
        showBackButton
        placeHolder="+ Add List Items"
        tabBarHeight={tabBarHeight}
      />
      <DropShadow>
        <ListHeader count={numItems} listName={title} listData={data} />
      </DropShadow>
      {loading ? (
        <View style={styles.loadingWrapper}>
          <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} />
        </View>
      ) : (
        <ScrollView refreshControl={<RefreshControl refreshing={false} onRefresh={getListData} />}>
          {(data?.lineItems?.length ?? 0) + (data?.textLineItems?.length ?? 0) === 0 ? (
            <View style={styles.emptyContainer}>
              <Text style={appStyles.bodySmallBold} testID="addingItemsHeader">
                Start Adding Items to this List!
              </Text>
              <Text style={[appStyles.bodySmallLight, styles.emptySubText]} testID="instructions">
                Tap the search bar or barcode icon at the top of the screen to add items to this list.
              </Text>
              {pageConfig?.PastPurchases && (
                <ProductCarousel
                  title="Buy It Again"
                  titleStyle={utilityStyles.mt4}
                  widgetId={pageConfig.PastPurchases}
                  widgetType={'PastPurchases'}
                  addCartButtonStyle={styles.addCartButtonStyle}
                />
              )}
            </View>
          ) : (
            <>
              {(data?.lineItems?.length ?? 0) + (data?.textLineItems?.length ?? 0) === checked.length ? (
                <View style={styles.everythingContainer}>
                  <Text style={appStyles.bodySmall}>You've Got Everything On Your List!</Text>
                  <Button
                    buttonStyle={styles.useAgainButton}
                    type={'secondary'}
                    title={'Use List Again'}
                    textStyle={styles.useAgainButtonText}
                    onPress={useListAgain}
                  />
                  <TouchableOpacity onPress={clearAllListItems}>
                    <Text style={appStyles.bodySmallLightLinkCenter}>Clear List</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.uncheckedSection}>
                  {categories.map((cat, i) => {
                    var numItemsInCat = prodCategories.reduce((prev, curr) => {
                      if (curr.category === cat && !checked.some((ci) => 'variant' in ci && ci.variant?.sku === curr.prodSku)) {
                        prev += 1;
                      }
                      return prev;
                    }, 0);
                    const category = cat;
                    return (
                      <View key={`${cat}-${i}`} style={styles.catContainer}>
                        {cat && (
                          <View style={styles.catHeader}>
                            <Text style={appStyles.bodySmallLight}>{cat}</Text>
                          </View>
                        )}
                        {unchecked
                          ?.filter((uci) => {
                            let product =
                              'variant' in uci &&
                              productData?.find((p) => p.key === uci.variant?.sku || p.masterData.current?.masterVariant.sku === uci.variant?.sku);
                            let productCategory = 'variant' in uci ? prodCategories.find((pc) => pc.prodSku === uci?.variant?.sku ?? '')?.category : '';
                            if (productCategory === category && product) {
                              return uci;
                            }
                          })
                          ?.map((prod: ShoppingListLineItem | TextLineItem, idx) => {
                            let product = 'variant' in prod && productData?.find((p) => p.key === prod.variant?.sku);
                            return (
                              <>
                                <ListAccordionItem
                                  key={`${prod.id}-${i}`}
                                  listId={listId}
                                  item={prod}
                                  productInfo={product as Product}
                                  listName={listName ?? ''}
                                  checked={false}
                                />
                                {idx + 1 !== numItemsInCat && <View style={styles.itemSeparator} />}
                              </>
                            );
                          })}
                      </View>
                    );
                  })}
                  <View style={styles.catContainer}>
                    {data?.textLineItems.length &&
                      data.textLineItems.some((tli) => tli.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value !== true) ? (
                      <View style={styles.catHeader}>
                        <Text style={appStyles.bodySmallLight}>Misc Items</Text>
                      </View>
                    ) : null}
                    {data?.textLineItems.map((prod, i) => {
                      return prod.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value !== true ? (
                        <>
                          <ListAccordionItem
                            key={`${prod.id}`}
                            listId={listId}
                            item={prod as ShoppingListLineItem}
                            listName={listName ?? ''}
                            checked={false}
                            isTextItem
                            linkAction={() => findItems(prod.name ?? '')}
                          />
                          {i + 1 !== data?.textLineItems.length && <View style={styles.itemSeparator} />}
                        </>
                      ) : null;
                    })}
                  </View>
                </View>
              )}
              <View style={styles.checkedSection}>
                <View style={styles.checkedHeader}>
                  <Text style={appStyles.bodySmallLight}>
                    <Text style={[appStyles.bodySmallLeft]}>Checked</Text> {` (${numChecked} item${numChecked > 1 ? 's' : ''})`}
                  </Text>
                  <View style={styles.checkedRightHeader}>
                    <TouchableOpacity onPress={unCheckAllItems} style={{ marginRight: 24 }}>
                      <Text style={styles.uncheckLink}>Uncheck All</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setShowChecked(!showChecked)}>
                      <Icon size={18} name={showChecked ? 'arrow-up' : 'arrow-down'} />
                    </TouchableOpacity>
                  </View>
                </View>
                {showChecked && (
                  <View>
                    {checkedCategories.map((cat, i) => {
                      // const numItemsInCat = unchecked

                      return (
                        <View key={`${cat}-${i}`} style={styles.catContainer}>
                          {cat && (
                            <View style={styles.catHeader}>
                              <Text style={appStyles.bodySmallLight}>{cat}</Text>
                            </View>
                          )}
                          {checked
                            ?.filter((uci) => {
                              let product =
                                'variant' in uci
                                  ? productData?.find((p) => p.key === uci.variant?.sku || p.masterData.current?.masterVariant.sku === uci.variant?.sku)
                                  : undefined;
                              let productCategory = 'variant' in uci ? prodCategories.find((pc) => pc.prodSku === uci?.variant?.sku ?? '')?.category : '';
                              if (productCategory === cat && product) {
                                return uci;
                              }
                            })
                            ?.map((prod) => {
                              let product = 'variant' in prod ? productData?.find((p) => p.key === prod.variant?.sku) : undefined;
                              // let productCategory = product ? getRootCategory(product?.masterData?.current?.categories)?.name : '';
                              return (
                                <ListAccordionItem
                                  key={`${prod.id}-${i}`}
                                  listId={listId}
                                  item={prod}
                                  productInfo={product}
                                  listName={listName ?? ''}
                                  checked={true}
                                />
                              );
                            })}
                        </View>
                      );
                    })}
                  </View>
                )}
                <View style={styles.catContainer}>
                  {data?.textLineItems?.some((i) => i.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true) ? (
                    <View style={styles.catHeader}>
                      <Text style={appStyles.bodySmallLight}>Misc Items</Text>
                    </View>
                  ) : null}
                  {data?.textLineItems.map((prod, i) => {
                    return prod.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true ? (
                      <>
                        <ListAccordionItem
                          key={`${prod}-${i}`}
                          listId={listId}
                          item={prod as ShoppingListLineItem}
                          listName={listName ?? ''}
                          checked={true}
                          isTextItem
                          linkAction={() => findItems(prod.name ?? '')}
                        />
                        {i + 1 !== data?.textLineItems.length && <View style={styles.itemSeparator} />}
                      </>
                    ) : null;
                  })}
                </View>
              </View>
              {pageConfig?.PastPurchases && (
                <ProductCarousel
                  title="Buy It Again"
                  titleStyle={utilityStyles.mt4}
                  widgetId={pageConfig.PastPurchases}
                  widgetType={'PastPurchases'}
                  addCartButtonStyle={[styles.addCartButtonStyle, { width: screenWidth / 2.8 }]}
                />
              )}
              <View style={styles.estimateBar}>
                <Text style={[appStyles.smallBoldCenterRed, { color: colors.text }]}>Estimated Subtotal ${value.toFixed(2)}</Text>
              </View>
            </>
          )}
        </ScrollView>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  loadingWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemSeparator: {
    width: '92%',
    height: 1.5,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
  },
  everythingContainer: {
    width: '100%',
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
  },
  useAgainButton: {
    marginVertical: 16,
    height: 30,
    minWidth: 140,
    width: 140,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  useAgainButtonText: {
    fontSize: scale(16),
  },
  catContainer: {
    flexGrow: 1,
    width: '100%',
    paddingHorizontal: 0,
  },
  catHeader: {
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 16,
    backgroundColor: colors.tableLightRow,
    height: 35,
    justifyContent: 'center',
  },
  checkedRightHeader: {
    flexDirection: 'row',
  },
  uncheckLink: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    textDecorationLine: 'underline',
    color: colors.text,
    marginTop: 1,
  },
  uncheckedSection: {
    width: '100%',
  },
  checkedSection: {
    width: '100%',
  },
  checkedHeader: {
    backgroundColor: colors.darkCream,
    width: screenWidth,
    height: 45,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 48,
  },
  emptySubText: {
    width: '70%',
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 64,
  },
  estimateBar: {
    height: 50,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  estimateText: {},
  addCartButtonStyle: {
    backgroundColor: colors.darkCream,
  },
});

export default ViewListScreen;

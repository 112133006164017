import { Icon, LoadingScreen, NavigationHeader, Screen } from '@components';
import Text from '@components/Text';
import TopDealsCard from '@components/somethingExtra/TopDealsCard';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, RewardsStackRoutes, RewardsStackScreenProps } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useOffersStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type TopDealsScreenProps = RewardsStackScreenProps<RewardsStackRoutes.TopDeals>;

const TopDealsScreen: FC<TopDealsScreenProps> = ({ route }) => {
  const navigation = useNavigation();
  const { availableSomethingExtraOffers, availableWeeklyExclusiveOffers, offersState } = useOffersStore();
  const availableOffers = [...availableSomethingExtraOffers, ...availableWeeklyExclusiveOffers];
  const { title } = route.params;
  const { t } = useTranslation('topDeals');
  const insets = useSafeAreaInsets();

  const renderEmptyList = () => {
    return (
      <View style={styles.emptyListView}>
        <Text style={[styles.boldOrText, { fontSize: scale(15) }]}>{t('emptyListMsg')}</Text>
      </View>
    );
  };

  return (
    <>
      <Screen style={utilityStyles.pb0}>
        <NavigationHeader title={t('headerTitle')} subStyle={[utilityStyles.pt2]} titleStyle={styles.headerTitleStyle} />
        {offersState === 'loading' ? (
          <LoadingScreen />
        ) : (
          <View style={Platform.OS === 'web' ? {} : { marginBottom: 65 - insets.bottom }}>
            {availableOffers.length > 0 && (
              <View style={styles.header}>
                <View style={styles.titleMarginLeft}>
                  <Text style={styles.h2Left}>{title ? title : 'All'}</Text>
                </View>
                <View style={styles.subHeaderView}>
                  <TouchableOpacity
                    style={styles.buttonView}
                    onPress={() => {
                      navigation.navigate(AppStackRoutes.SearchFilter, {
                        title: t('filterAndSortTitle'),
                        text: 'sweet',
                        calledFrom: 'TopDeals',
                      });
                    }}>
                    <Icon name="sort-icon" size={28} style={[appStyles.mediumIcon]} />
                    {/* <View style={[styles.badge]}>
                  <Text style={styles.badgeText}>{5}</Text>
                </View> */}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.buttonView}
                    onPress={() =>
                      navigation.navigate(AppStackRoutes.AccordionScreen, {
                        title: 'All Categories',
                      })
                    }>
                    <Icon name="categories-icon" size={20} style={[appStyles.mediumIcon]} />
                  </TouchableOpacity>
                </View>
              </View>
            )}
            <View style={styles.topDealsView}>
              <FlatList
                keyExtractor={(_, i) => i.toString()}
                data={availableOffers}
                renderItem={({ item, index }) => {
                  return (
                    <View style={styles.topDealsCardView}>
                      <TopDealsCard item={item} index={index} />
                    </View>
                  );
                }}
                numColumns={2}
                contentContainerStyle={styles.topDealsListContentContainerStyle}
                ListEmptyComponent={renderEmptyList}
              />
            </View>
          </View>
        )}
      </Screen>
    </>
  );
};

const styles = StyleSheet.create({
  headerTitleStyle: {
    fontSize: scale(27),
    marginLeft: Platform.select({
      ios: scale(32),
      android: scale(30),
    }),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingTop: 10,
  },
  h2Left: {
    color: colors.primary,
    fontSize: scale(22),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(25),
    textAlign: 'left',
  },
  fontMobileBoldLinkLargeCenter: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'center',
  },
  topDealsView: {
    width: screenWidth,
    alignSelf: 'center',
    paddingLeft: Platform.select({
      android: screenWidth * 0.01,
    }),
  },
  buttonView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    borderRadius: 10,
    height: 12,
    width: 12,
    top: 0,
    right: 0,
    backgroundColor: colors.secondary,
    position: 'absolute',
  },
  badgeText: {
    fontSize: scale(10),
    lineHeight: lineHeight(10),
    color: colors.white,
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
  },
  topDealsListContentContainerStyle: {
    paddingBottom: screenHeight * 0.2,
  },
  subHeaderView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: screenHeight > 640 ? screenWidth * 0.029 : screenWidth * 0.012,
    width: screenHeight > 640 ? screenWidth * 0.2 : screenWidth * 0.18,
    height: scale(30),
  },
  titleMarginLeft: { marginLeft: 20 },
  topDealsCardView: {
    marginBottom: 16,
    marginLeft: Platform.select({
      ios: 12,
      android: 12,
    }),
  },
  boldOrText: {
    marginVertical: 10,
    color: colors.darkText,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitBold,
  },
  emptyListView: {
    marginVertical: '60%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default TopDealsScreen;

import { useRefetchOnFocus } from '@hooks';
import { userService } from '@services/brandywine';
import { useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, screenWidth } from '@styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { useCustomModalContext } from '../contexts';
import CartButton from './CartButton';
import Icon from './Icon';
import Text from './Text';

interface PointsHeaderProps {
  useInsets?: boolean;
}

const PointsHeader = ({ useInsets }: PointsHeaderProps) => {
  const { userProfile } = useUserProfileStore();
  const { data: pointsBalance, isLoading, refetch, isRefetching } = useQuery('points', userService.getPointsBalance);
  const insets = useSafeAreaInsets();
  const { showAlertModal } = useCustomModalContext();
  const { t } = useTranslation('pointsHeader');

  const handleOnPress = () => {
    showAlertModal({
      title: t('title'),
      message: t('message'),
      closeButtonText: t('okText'),
    });
  };

  useRefetchOnFocus(refetch);

  return (
    <View style={[styles.container, { marginTop: useInsets ? insets.top : 5 }]}>
      <View style={{ width: screenWidth - (56 + 16) }}>
        <Text style={appStyles.bodyLeftBold} testID="hiText">
          Hi{userProfile ? ', ' + userProfile.firstName : ''}
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {isLoading || isRefetching ? (
            <ActivityIndicator size={'small'} />
          ) : (
            <Text style={[appStyles.bodySmallLight, styles.pointsStyle]} testID="pointsText">
              {pointsBalance ?? 0}
              {pointsBalance === 1 ? t('point') : t('points')}
            </Text>
          )}
          <TouchableOpacity style={styles.touchableIcon} onPress={handleOnPress} hitSlop={{ bottom: 5, top: 5, left: 5, right: 55 }} testID="infoIconClick">
            <Icon name="info-icon" size={15} style={[appStyles.smallIcon]} testID="infoIcon" />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.cartContainer}>
        <CartButton />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'space-between',
    height: 45,
  },
  pointsStyle: {
    lineHeight: lineHeight(15),
  },
  touchableIcon: {
    paddingLeft: 8,
    alignContent: 'center',
  },
  cartContainer: {
    width: 56,
    height: 56,
    alignItems: 'flex-end',
  },
  modalContentText: {
    textAlign: 'center',
    fontWeight: 'normal',
  },
});

export default PointsHeader;

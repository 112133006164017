import { Icon } from '@components';
import withShopNavigator from '@components/hoc/withShopNavigator';
import { List, ListItem } from '@components/lists';
import colors from '@config/colors';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useIsFocused } from '@react-navigation/native';
import { brsmService } from '@services';
import { utilityStyles } from '@styles';
import React, { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ListRenderItemInfo, Platform, RefreshControl, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
type CategoriesScreenProps = ShopStackScreenProps<ShopStackRoutes.Categories>;

const CategoriesScreen = withShopNavigator(
  ({ navigation }: CategoriesScreenProps) => {
    const isFocused = useIsFocused();
    const {
      isLoading,
      data,
      refetch,
      isRefetching,
      error: categoriesLoadError,
    } = brsmService.useCategoriesQuery((res) => res?.filter((item) => !item.parentName), isFocused);
    useErrorHandler(categoriesLoadError);

    const baseImageUrl = `${Config.BRXM_IMAGE_URL}/consumer-app/category-image`;
    const insets = useSafeAreaInsets();

    const handleListItemOnPress = useCallback(
      (item: Category) => {
        navigation.push(ShopStackRoutes.Category, { categoryId: item.id });
      },
      [navigation],
    );
    // useRefetchOnStoreChange(refetch);
    const renderItem = useCallback(
      ({ item }: ListRenderItemInfo<Category>) => {
        // logger.log(JSON.stringify(item, null, 2));
        return (
          <ListItem
            style={[styles.listItem, utilityStyles.px3]}
            title={item.name}
            titleStyle={styles.title}
            image={{
              uri: `${baseImageUrl}/${item.imageName}`,
              cache: 'web',
            }}
            imageStyle={styles.image}
            onPress={() => handleListItemOnPress(item)}>
            <View>
              <Icon testID="arrowRight" name="arrow-right" size={20} />
            </View>
          </ListItem>
        );
      },
      [baseImageUrl, handleListItemOnPress],
    );

    return (
      <List
        isLoading={isLoading}
        style={{ marginBottom: Platform.OS === 'web' ? 36 : 70 - insets.bottom }}
        refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
        data={data}
        renderItem={renderItem}
      />
    );
  },
  { screenName: 'categories', showTombstone: true },
);

const styles = StyleSheet.create({
  listItem: {
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  image: {
    backgroundColor: colors.sectionPad,
    borderRadius: 100,
    width: 60,
  },
  title: {
    paddingLeft: 5,
  },
});
export default CategoriesScreen;

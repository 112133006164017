import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';

const CARD_WIDTH = screenWidth * 0.45;

type TopDealsCardProps = {
  item: any;
  index: number;
  calledFrom?: string;
};

const TopDealsCard: FC<TopDealsCardProps> = ({ item, index, calledFrom }): JSX.Element => {
  const { t } = useTranslation('topDeals');
  const PRODUCT_IMAGE_HOLDER = [Config.BRXM_IMAGE_URL, 'consumer-app/cartProductPlaceHolder.png'].join('/');
  const OFFER_IMG = item?.UserDefinedFields?.ImageUrl;
  const { showModal, hideModal, showAlertModal } = useCustomModalContext();

  var options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };

  const endDate = new Date(item && item.EndDate);
  const expirationDate = new Intl.DateTimeFormat('en-US', options).format(endDate);

  const navigation = useNavigation();

  const handleShowEligibleItems = () => {
    navigation.navigate(AppStackRoutes.OfferDetails, {
      offerId: Number(item.OfferId),
      calledFrom: 'topDeals',
    });
  };
  const showItemLocation = () => {
    showModal({
      style: { borderTopLeftRadius: 25, borderTopRightRadius: 25, backgroundColor: colors.cream },
      header: customHeader(),
      children: <>{buildContent()}</>,
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3 },
    });
  };
  const customHeader = () => (
    <View style={styles.handle}>
      <TouchableOpacity
        style={styles.closeButton}
        onPress={() => {
          hideModal();
        }}>
        <Icon name={'x-close'} size={20} />
      </TouchableOpacity>
      <Text style={appStyles.h6}>Item Location</Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]} />
    </View>
  );

  const buildContent = () => {
    return (
      <View style={[styles.container]}>
        <Icon name={'location-icon'} fill={colors.slateGray} stroke={colors.slateGray} strokeSecondary={colors.slateGray} size={20} />
        <Text style={[appStyles.fontMobileBodyMediumRegular, { paddingTop: 10 }]}>Aisle 10/Bay X/Bin 00</Text>
      </View>
    );
  };

  return (
    <View>
      <TouchableOpacity onPress={handleShowEligibleItems} style={[styles.cardContainer]}>
        <View style={[styles.cardView]}>
          {calledFrom !== 'rewards' && (
            <TouchableOpacity onPress={() => showAlertModal()} style={styles.bookmarkButtonView} activeOpacity={0.7}>
              <Icon name="bookmark-icon" stroke={colors.red} fill={false ? colors.secondary : 'none'} style={appStyles.largeIcon} size={24} />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.cardContainerView}>
          <View style={[styles.productImageView]}>
            <TouchableOpacity key={'image_key_index_' + index} activeOpacity={1} onPress={() => {}}>
              {<Image source={{ cache: 'default', uri: (OFFER_IMG && OFFER_IMG) || PRODUCT_IMAGE_HOLDER }} style={styles.image} resizeMode="contain" />}
            </TouchableOpacity>
          </View>
          <View style={[styles.bookmarkCardView]}>
            <View style={styles.priceView}>
              <Text style={appStyles.itemCardHeaderRed}>${item.Conditions[0].Amount} ea</Text>
            </View>
            <Text numberOfLines={2} style={[appStyles.smLightCenter]}>
              {item?.UserDefinedFields?.ConsumerDescription}
            </Text>
            {/* <Text numberOfLines={1} style={[styles.itemNameText, appStyles.bodySmallLeft]}>
              {item?.UserDefinedFields?.ConsumerDetails}
            </Text> */}
            <View style={styles.expView}>
              <Text style={appStyles.tinyLight}>
                {t('expiresTitle')} {expirationDate}
              </Text>
            </View>
            <TouchableOpacity onPress={handleShowEligibleItems} style={styles.eligibleItems}>
              <Text style={[appStyles.tinylightredlink]}>{t('showEligibleItems')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.locationWrapper}
              onPress={() => {
                showItemLocation();
              }}>
              <Icon name={'location-icon'} fill={colors.slateGray} stroke={colors.slateGray} strokeSecondary={colors.slateGray} size={12} />
              {item.Locations.length > 0 && calledFrom === 'rewards' ? (
                <Text style={[styles.locationText]}>{t('viewLocationsText')}</Text>
              ) : (
                <Text style={[styles.alislocationText]}>Aisle 10</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: CARD_WIDTH,
    height: 330,
    borderWidth: 0.5,
    borderColor: colors.red,
    borderRadius: 10,
    backgroundColor: colors.white,
    flexDirection: 'column',
    marginLeft: 0,
  },
  cardView: {
    width: 125,
    borderRadius: 10,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    paddingTop: 12,
  },
  activityIndicatorView: {
    position: 'absolute',
    top: 12,
    right: -2,
  },
  cardContainerView: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  itemLocationWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  bookmarkButtonView: {
    width: 125,
    borderRadius: 10,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    paddingTop: 0,
  },
  productImageView: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginVertical: 5,
  },
  image: {
    width: 100 - 5,
    height: 100,
  },
  bookmarkCardView: {
    top: -25,
    justifyContent: 'space-evenly',
    padding: 10,
  },
  eligibleItems: {
    flexDirection: 'row',
    justifyContent: 'center',
    top: 20,
  },
  locationWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    lineHeight: lineHeight(12),
    bottom: -40,
  },
  alislocationText: {
    paddingLeft: 5,
    textDecorationLine: 'underline',
  },
  expView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 13,
  },
  locationText: {
    paddingLeft: 5,
    textDecorationLine: 'underline',
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    lineHeight: lineHeight(12),
  },
  priceView: {
    marginTop: -2,
    marginBottom: 5,
  },
  itemNameText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    color: colors.text,
  },
  container: {
    paddingVertical: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 0,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
});

export default TopDealsCard;

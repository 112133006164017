import { BottomBar, Button, DropShadow, Modal, NavigationHeader, SavedForLater, Screen, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import Tombstone, { OpenTombstoneRequest } from '@components/Tombstone';
import { ProductCarousel } from '@components/brsm';
import ItemizedCart from '@components/cart/ItemizedCart';
import colors from '@config/colors';
import { LineItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { PersonalizedWidgetParams, ShopType } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { useNavigation, useRoute } from '@react-navigation/native';
import { storeService as bwStoreService } from '@services/brandywine';
import { useAnalyticsStore, useAppConfigStore, useCommerceToolsStore, useListsStore, useOffersStore, useShopStore } from '@store';
import { useCartStore } from '@store/cartStore';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, screenWidth } from '@styles/constants';
import { evaluateCart, getLineItemsToEvaluateCart } from '@utils/cartHelper';
import logger from '@utils/logger';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  getCartEndAvailabilityDate,
  getCartLeadTimeMinutes,
  getFulfilmentStore,
  getOriginalOrderNumber,
  getPromotions,
  getShippingMethod,
  getSubTotalItemPrice,
  getTimeSlot,
  invidLineItems,
  isDelivery,
  moneyValue,
} from '../../utils/orderHelpers';
import { getProductAvailablity, getProductsfromCommerceTools } from '../../utils/productHelper';

const CartScreen = () => {
  const { defaultStore } = useCommerceToolsStore();
  const navigation = useNavigation();
  const route = useRoute();
  const {
    transitCart,
    cart,
    initialize,
    getCartQuantity: getQuantityTotal,
    tombOpenTo,
    tombClear,
    tombIsOpen,
    setPromotions,
    getAgeVerificationRequired,
  } = useCartStore();
  const { previousRoute } = useAnalyticsStore();
  const { selectedShopType, selectedStore } = useShopStore();
  const {
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    availableVouchers,
    acceptedVouchers,
    redeemedVouchers,
    nonTargetedOffers,
    offersState,
    vouchersState,
    refreshingVouchers,
    digitalCouponsState,
  } = useOffersStore();
  const { lists } = useListsStore();
  const [modalData, setModalData] = useState<{ [key: string]: any }>({ showModal: false });
  const insets = useSafeAreaInsets();
  const { t } = useTranslation('cart');
  const [tsRequest, setTsRequest] = useState<OpenTombstoneRequest | undefined>();
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);
  const [lastEvalQuery, setLastEvalQuery] = useState<string>('');
  const [lastCartPromoSet, setLastCartPromoSet] = useState<string>('');
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [tombStoneBottom, setTombStoneBottom] = useState<number>(0);
  const pageConfig = useAppConfigStore().getConfigGroup('Cart');
  const svRef = useRef<ScrollView>(null);

  const extendedMenu = useCallback((): JSX.Element => {
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate(AppStackRoutes.CartAndCheckout, {
            screen: CheckoutStackRoutes.CartOptionsScreen,
          })
        }
        style={{ flexDirection: 'row', paddingTop: 8, alignItems: 'flex-end' }}>
        <Text testID="extendMenuDots" style={appStyles.bodyBoldLarge}>
          . . .
        </Text>
      </TouchableOpacity>
    );
  }, [navigation]);

  const [fetchingPromos, setFetchingPromos] = useState<boolean>(false);
  const changeShoppingOptions = (page?: string) => {
    setTsRequest({ ts: new Date().getTime(), page: page });
  };

  useEffect(() => {
    if (modalData && !modalData.showModal && modalData.onClose) {
      if (modalData.onClose) {
        modalData.onClose();
        setModalData({ ...modalData, onClose: undefined });
      }
    }
  }, [modalData]);

  const itemizedRef = React.useRef<React.ElementRef<typeof ItemizedCart>>(null);
  useEffect(() => {
    const current = itemizedRef.current;
    return () => {
      current?.flush();
    };
  }, [itemizedRef]);

  const promotionApplied = (p: Promotion) => {
    // waiting for extention for percent complete
    if ((p.AutoApply || p.IsAccepted) && +(p.PercentComplete ?? '100') === 100) {
      return true;
    }
    return false;
  };

  const triggerSavingReminderNavigation = useCallback(async () => {
    const cartPromos = JSON.parse(cart?.custom?.customFieldsRaw?.find((f) => f.name === 'promotions')?.value ?? '[]') as Promotion[];
    // if any promos to be reminded about exist
    if (
      cartPromos.find((p: Promotion) => {
        return !promotionApplied(p);
      })
    ) {
      navigation.navigate(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.SavingsReminder,
      });
    } else {
      getAgeVerificationRequired().then((ages) => {
        if (ages && ages.length > 0) {
          if (selectedStore && selectedStore.number) {
            bwStoreService.getStore(selectedStore?.number).then((bwStore) => {
              let allowAlcohol = true;
              if (!bwStore.canSellAlcohol) {
                allowAlcohol = false;
              }
              const cartShippingMethod = getShippingMethod(cart);
              if (ShopType.DELIVERY === cartShippingMethod && bwStore && !bwStore.canDeliverAlcohol) {
                allowAlcohol = false;
              }

              if (allowAlcohol) {
                navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.AgeRequirementScreen, params: { age: ages[0] } });
              } else {
                setModalData({
                  location: 'top',
                  title: 'Age Restricted Items',
                  description: 'Please check your cart products and substitution selections for alcohol containing items.',
                  showModal: true,
                  onClose: null,
                });
                setIsCheckingOut(false);
              }
            });
          }
        } else {
          navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.Checkout, params: {} });
        }
      });
    }
  }, [cart, getAgeVerificationRequired, navigation, setIsCheckingOut, selectedStore]);

  useEffect(() => {
    if (cart && tombOpenTo) {
      setTsRequest({ ts: new Date().getTime(), page: tombOpenTo });
      tombClear();
    }

    if (!fetchingPromos && isCheckingOut && isValidated) {
      triggerSavingReminderNavigation();
    }
  }, [cart, tombClear, tombOpenTo, setTsRequest, offersState, isCheckingOut, isValidated, triggerSavingReminderNavigation, fetchingPromos]);

  useEffect(() => {
    if (fetchingPromos) {
      return;
    }
    if (cart) {
      const promoSourceSet = [
        availableWeeklyExclusiveOffers,
        acceptedWeeklyExclusiveOffers,
        availableSomethingExtraOffers,
        acceptedSomethingExtraOffers,
        availableDigitalCoupons,
        acceptedDigitalCoupons,
        availableVouchers,
        acceptedVouchers,
        redeemedVouchers,
        nonTargetedOffers,
        vouchersState,
        refreshingVouchers,
        digitalCouponsState,
      ];
      const cartPromoSet = JSON.stringify(
        promoSourceSet.reduce((acc, pset) => {
          acc.push(JSON.stringify(pset));
          return acc;
        }, [] as string[]),
      );
      const cartEvalQuery = JSON.stringify(getLineItemsToEvaluateCart(cart.lineItems ?? []));
      if (cartEvalQuery !== lastEvalQuery || cartPromoSet !== lastCartPromoSet) {
        setFetchingPromos(true);
        evaluateCart(cart, selectedStore ? selectedStore.number : defaultStore?.key ?? '', selectedShopType === 'Pickup' ? 'pickup' : 'delivery')
          .then((result) => {
            setLastEvalQuery(cartEvalQuery);
            setLastCartPromoSet(cartPromoSet);
            const newPromos = JSON.stringify(result);
            if (newPromos !== getPromotions(cart)) {
              setPromotions(newPromos);
            }
          })
          .catch((reason) => {
            logger.log(reason);
          })
          .finally(() => {
            setFetchingPromos(false);
          });
      }
    }
    // do not trigger on fetchingPromos var (do not add to list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cart,
    defaultStore?.key,
    selectedShopType,
    selectedStore,
    setFetchingPromos,
    setPromotions,
    offersState,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    availableVouchers,
    acceptedVouchers,
    redeemedVouchers,
    nonTargetedOffers,
    vouchersState,
    refreshingVouchers,
    digitalCouponsState,
    setLastCartPromoSet,
    lastCartPromoSet,
  ]);

  const renderBottomView = useCallback((): JSX.Element | undefined => {
    const onBottomBarLayout = (le: LayoutChangeEvent) => {
      setTombStoneBottom(le.nativeEvent.layout.height - insets.bottom);
    };

    const itemSubtotal = getSubTotalItemPrice(cart);

    const gotoCheckout = async () => {
      let errorTitle = t('errorCheckoutTitle');
      setIsCheckingOut(true);
      setIsValidated(false);
      const latestCart = await initialize();
      if (!latestCart) {
        setIsCheckingOut(false);
        return false;
      }

      let onClose: any;
      const todo: string[] = [];
      if ((latestCart.lineItems?.length ?? 0) === 0) {
        todo.push(t('errorEmptyCart'));
      } else {
        const isDel = isDelivery(cart);
        if (undefined === isDel) {
          todo.push(t('errorShoppingMethod'));
          onClose = () => changeShoppingOptions('InitialShopOptions');
        } else if (selectedShopType !== ShopType.PICKUP && selectedShopType !== ShopType.DELIVERY) {
          todo.push(t('errorNotSupportedShoppingMethod', { selectedShopType }));
          onClose = () => changeShoppingOptions('InitialShopOptions');
        } else {
          if (
            !latestCart.shippingAddress ||
            !latestCart.shippingAddress?.streetName ||
            !latestCart.shippingAddress?.postalCode ||
            !latestCart.shippingAddress?.city ||
            !getFulfilmentStore(latestCart)
          ) {
            todo.push(isDel ? t('errorDeliveryAddress') : t('errorPickupStore'));
            onClose = () => changeShoppingOptions(isDel ? 'Delivery' : 'FindAStore');
          }
          const ts = getTimeSlot(latestCart);
          if (!ts) {
            todo.push(isDel ? t('errorDeliveryTimeslot') : t('errorPickupTimeslot'));
            onClose = () => changeShoppingOptions('TimeSlot');
          } else {
            let then = dayjs(ts.timeSlotDate);
            const now = dayjs();
            const leadTime = await getCartLeadTimeMinutes(cart);
            if (ts.timeSlots[0]?.timeSlotHourStart) {
              then = then.add(ts.timeSlots[0].timeSlotHourStart, 'hours');
            }

            if (then.diff(now.add(leadTime, 'minutes'), 'minutes') <= 0) {
              const originalOrder = getOriginalOrderNumber(cart);
              if (originalOrder) {
                errorTitle = t('modNoLongerAvailableTitle');
                todo.push(t('modNoLongerAvailable'));
              } else {
                const leadMessage =
                  // eslint-disable-next-line no-bitwise
                  leadTime > +(Config.LEAD_TIME_MAX_HOURS_TOSHOW ?? '2') * 60 ? `${(leadTime / 60 / 24 + 1) | 0} day` : `${(leadTime / 60) | 0}-hour`;
                todo.push(t('selectFutureTimeslot', { leadTime: leadMessage }));
                onClose = () => changeShoppingOptions('TimeSlot');
              }
            } else {
              const endTime: dayjs.Dayjs | undefined = await getCartEndAvailabilityDate(cart);
              if (endTime) {
                if (endTime.diff(ts.timeSlotDate, 'minutes') < 0) {
                  todo.push('Some items will no longer be available at the selected time');
                  onClose = () => changeShoppingOptions('TimeSlot');
                }
              }
            }
          }
        }
      }

      const getProdSet = async (skus: string[]): Promise<Product[]> => {
        return await getProductsfromCommerceTools(skus);
      };

      const skus: string[] = invidLineItems(latestCart.lineItems).reduce<string[]>((acc: string[], i: LineItem) => {
        if (i.variant?.sku && acc.findIndex((o) => o === i.variant!.sku)) {
          acc.push(i.variant.sku);
        }
        return acc;
      }, []);

      const prodSet: Product[] = await getProdSet(skus);
      prodSet.some((p: Product) => {
        const avail = getProductAvailablity(p.masterData);
        if (avail.availability !== 'Available' && avail.availability !== 'LowStock') {
          todo.push('Some of the products in the cart are not currently available');
          onClose = () => {};
          return true;
        }
        return false;
      });

      if (todo.length) {
        setModalData({
          location: 'top',
          title: errorTitle,
          description: todo.join('\n'),
          showModal: true,
          onClose: onClose,
        });
        setIsCheckingOut(false);
        return false;
      } else {
        itemizedRef.current?.flush();
        if (offersState !== 'loading') {
          triggerSavingReminderNavigation();
        } else {
          setIsValidated(true);
        }
      }
    };

    const count = cart ? getQuantityTotal() || 0 : 0;
    return (
      <View key="mainCartBottomBarWrapper" style={[styles.bottomBarWrapper]} testID="mainCartBottomBarWrapper">
        <BottomBar onLayout={onBottomBarLayout}>
          <View>
            <View style={[{ flexDirection: 'row', width: containerWidth }, utilityStyles.mt2]}>
              <Text style={appStyles.bodySmallRegular} testID="itemcount">
                {count} Item(s)
              </Text>
              {itemSubtotal && (
                <Text style={[appStyles.bodySmallRegular, { marginLeft: 'auto' }]} testID="estimatedSubtotal">
                  Estimated Subtotal {moneyValue(itemSubtotal)}
                </Text>
              )}
            </View>
            <Button
              testID="cartButtonTestID"
              size={'small'}
              buttonStyle={[appStyles.cartBottomButton]}
              textStyle={(cart?.lineItems?.length ?? 0) && !tombIsOpen ? {} : { opacity: 0.3 }}
              onPress={() => gotoCheckout()}
              disabled={!(cart?.lineItems?.length ?? 0) || tombIsOpen}
              title={t('goToCheckout')}
              isButtonLoading={!!transitCart || !cart || isCheckingOut || fetchingPromos}
            />
          </View>
        </BottomBar>
      </View>
    );
  }, [
    cart,
    getQuantityTotal,
    tombIsOpen,
    t,
    transitCart,
    isCheckingOut,
    fetchingPromos,
    insets.bottom,
    initialize,
    selectedShopType,
    offersState,
    triggerSavingReminderNavigation,
  ]);

  return (
    <>
      <Screen key="mainCartKey">
        <NavigationHeader titleStyle={{ top: 5 }} title="Cart" next={extendedMenu()} />
        <ScrollView
          ref={svRef}
          contentContainerStyle={[styles.scrollViewContent, { height: 'auto', backgroundColor: colors.sectionPad, paddingBottom: 75 }]}
          nestedScrollEnabled={true}
          bounces={true}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={false}>
          <CartInfoBanner key={'cInfobaner'} onTitlePress={() => null} />
          <ItemizedCart key={'s1'} scrollViewRef={svRef} ref={itemizedRef} readOnly={false} />
          {lists?.find((li) => li.name === 'Saved For Later')?.lineItems.length ? (
            <DropShadow key={'saveForLater'} style={{ borderBottomWidth: 0, borderTopWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }}>
              <SavedForLater key="s2" />
            </DropShadow>
          ) : null}
          {pageConfig?.PastPurchases && (
            <DropShadow key={'pastPurchasesShadow'} style={{ borderBottomWidth: 0, borderTopWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }}>
              <ProductCarousel
                key={'s3'}
                title="Buy It Again"
                widgetId={pageConfig?.PastPurchases ?? ''}
                widgetParams={{ url: route.name, ref_url: previousRoute?.name ?? '/' } as PersonalizedWidgetParams}
                widgetType={'PastPurchases'}
                addCartButtonStyle={styles.addCartButtonStyle}
              />
            </DropShadow>
          )}
        </ScrollView>
        <Modal
          location={modalData.location ?? 'top'}
          title={modalData.title}
          visible={modalData.showModal}
          cancelButtonOnPress={() => setModalData({ ...modalData, showModal: false })}
          children={
            modalData.description ? (
              <Text testID="modalDescription" style={[appStyles.fontMobileBodySmallRegular]}>
                {modalData.description}
              </Text>
            ) : (
              <Text testID="featureText">This feature is planned for a future version and is not yet available.</Text>
            )
          }
        />
      </Screen>
      <View key="mainCartTombstone" style={{ marginBottom: insets ? tombStoneBottom + insets.bottom : tombStoneBottom }}>
        <Tombstone openRequest={tsRequest} />
      </View>
      {renderBottomView()}
    </>
  );
};

const styles = StyleSheet.create({
  scrollViewContent: {},
  bottomBarWrapper: {
    zIndex: 1000,
  },
  addCartButtonStyle: {
    width: screenWidth / 2.8,
    backgroundColor: colors.darkCream,
  },
});

export default CartScreen;

//ref: https://github.com/notenoughneon/await-semaphore
import debug from 'debug';

export class Semaphore {
  private tasks: (() => void)[] = [];
  private count: number;
  private debugger = debug('semaphore');

  constructor(count: number) {
    this.count = count;
  }

  private sched() {
    if (this.count > 0 && this.tasks.length > 0) {
      this.count--;
      let next = this.tasks.shift();
      if (next === undefined) {
        throw 'Unexpected undefined value in tasks list';
      }
      next();
    }
  }

  public taskCount(): number {
    return this.tasks.length;
  }

  public acquire() {
    return new Promise<() => void>((res, _) => {
      let task = () => {
        let released = false;
        res(() => {
          if (!released) {
            released = true;
            this.count++;
            this.sched();
          }
        });
      };
      this.tasks.push(task);
      if (process && process.nextTick) {
        process.nextTick(this.sched.bind(this));
      } else {
        setImmediate(this.sched.bind(this));
      }
    });
  }

  public use<T>(fn: () => Promise<T>, name?: string) {
    name && this.debugger(`acquire ${name}`);
    return this.acquire().then((release) => {
      name && this.debugger(`run ${name}`);
      return fn()
        .then((res) => {
          name && this.debugger(`completed ${name}`);
          release();
          return res;
        })
        .catch((err) => {
          name && this.debugger(`error ${name}`);
          release();
          throw err;
        });
    });
  }
}

export const Mutex = (function () {
  const mutex = new Semaphore(1);

  return {
    withMutex: async <T>(fn: () => Promise<T>, name?: string) => {
      return await mutex.use(fn, name);
    },
    taskCount: (): number => {
      return mutex.taskCount();
    },
  };
})();

export const CartMutex = (function () {
  const mutex = new Semaphore(1);

  return {
    withMutex: async <T>(fn: () => Promise<T>, name?: string) => {
      return await mutex.use(fn, name);
    },
    taskCount: (): number => {
      return mutex.taskCount();
    },
  };
})();

import SponsoredIndicator from '@components/SponsoredIndicator';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import React, { memo, useCallback, useState } from 'react';
import { Platform, Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { Button, Image, Text, WebBrowserLink } from '../../';
import { useCustomModalContext } from '../../../contexts';
import { brxmNavigation } from '../utils';
import styles from './style';

export type SlideData = {
  id: number;
  subtitle?: string;
  flyerImageUrl?: string;
  imageUrl?: string;
  imageHeight?: number;
  imageWidth?: number;
  linkText?: string;
  title: string;
  bannerType?: string;
  content?: string;
  navigationType?: string;
  imageAlt?: string;
  flyerAlt?: string;
  url?: string;
  sponsored?: boolean;
};

interface SlideProps {
  data: SlideData;
}

//Default Slide
const DefaultSlide = memo(({ data }: SlideProps) => {
  const navigation = useNavigation();
  const { showAlertModal } = useCustomModalContext();
  const linkto = useLinkTo();
  const MAX_LINES = 10;
  const MIN_LINES = 4;
  const [numberOfLines, setNumberOfLines] = useState(0);

  const showMaxNumberOfLines = () => {
    setNumberOfLines(MAX_LINES);
  };

  const onTextLayout = useCallback((e: { nativeEvent: { lines: string | any[] } }) => {
    setNumberOfLines(e.nativeEvent.lines.length);
  }, []);

  const handleNavigation = (): void => {
    brxmNavigation(data.url, data.navigationType, navigation, linkto, showAlertModal);
  };

  return (
    <View style={styles.defaultSlideContainer}>
      {data.imageUrl && (
        <TouchableOpacity style={styles.defaultSlideImageLink} activeOpacity={0.75} onPress={() => handleNavigation()}>
          <Image
            accessibilityLabel={data.imageAlt}
            source={{ cache: 'web', uri: data.imageUrl }}
            style={[Platform.OS === 'web' ? styles.defaultSlideImageWeb : styles.defaultSlideImage]}
            resizeMode="cover"
          />
          {data.sponsored ? <SponsoredIndicator /> : null}
        </TouchableOpacity>
      )}
      <View style={styles.defaultSlideContent}>
        <Text style={[appStyles.h3B, { textAlign: 'center', marginTop: 10 }]} numberOfLines={2}>
          {data.title}
        </Text>

        <View style={[{ marginTop: 10, flex: 1 }]}>
          <ScrollView style={[{}]} nestedScrollEnabled={true} showsVerticalScrollIndicator={true} bounces={false} persistentScrollbar={true}>
            <Text
              style={[appStyles.bodyLargeCenter, {}]}
              onTextLayout={onTextLayout}
              numberOfLines={numberOfLines && numberOfLines > MIN_LINES ? MAX_LINES : MIN_LINES}
              onPress={showMaxNumberOfLines}
              suppressHighlighting={true}>
              {data.content}
            </Text>
          </ScrollView>
        </View>
      </View>
    </View>
  );
});

//Image Content Carousel
const ImageContentSlide = memo(({ data }: SlideProps) => {
  const navigation = useNavigation();
  const { showAlertModal } = useCustomModalContext();
  const linkto = useLinkTo();

  const handleNavigation = (): void => {
    brxmNavigation(data.url, data.navigationType, navigation, linkto, showAlertModal);
  };

  return (
    <>
      <View style={[styles.imageContentSlideContainer, data.title ? { paddingBottom: 0 } : { paddingBottom: 0 }]} testID="ImageContentSlide">
        {data.imageUrl && (
          <View style={styles.imageContainer}>
            <TouchableOpacity activeOpacity={0.75} onPress={() => handleNavigation()}>
              <Image
                accessibilityLabel={data.imageAlt}
                source={{ cache: 'web', uri: data.imageUrl }}
                style={[Platform.OS === 'web' ? styles.imageContentSlideImageWeb : styles.imageContentSlideImage]}
                testID="ImageContentSlideImage"
                resizeMode={'contain'}
              />
              {data.sponsored ? <SponsoredIndicator /> : null}
            </TouchableOpacity>
          </View>
        )}
        {data.title || data.subtitle || data.content || data.linkText ? (
          <View style={styles.imageContentSlideContent}>
            {data.title ? (
              <Text style={[appStyles.eyebrowCenterRed, { paddingVertical: 0, textTransform: 'uppercase', paddingTop: 20 }]} testID="ImageContentSlideTitle">
                {data.title}
              </Text>
            ) : (
              <></>
            )}

            <Text style={[appStyles.h1, { lineHeight: lineHeight(30), textAlign: 'center', padding: 10 }]} testID="ImageContentSlideSubtitle">
              {data.subtitle}
            </Text>
            <Text style={[appStyles.bodyLargeCenter, { padding: 10 }]} testID="ImageContentSlideDescription">
              {data.content}
            </Text>
            {/* TODO:// replace title and action with brxm link and linkText */}
            {data.linkText ? (
              <View style={{ top: 5, marginBottom: 20 }}>
                <Button type="secondary" size="small" title={data.linkText} onPress={() => handleNavigation()} textStyle={{ lineHeight: lineHeight(14) }} />
              </View>
            ) : (
              <></>
            )}
          </View>
        ) : null}
      </View>
    </>
  );
});

//Image Carousel
const ImageSlide = memo(({ data }: SlideProps) => {
  const linkto = useLinkTo();
  const handleOnPress = (route: string) => {
    if (route) {
      brxmNavigation(route, undefined, undefined, linkto, undefined);
    }
  };
  return (
    <Pressable onPress={() => data.url && handleOnPress(data.url)}>
      <View style={[styles.imageSlideContainer, utilityStyles.p3]} testID="ImageSlide">
        {data.imageUrl && (
          <View style={styles.squareImage}>
            <Image
              accessibilityLabel={data.imageAlt}
              source={{ cache: 'web', uri: data.imageUrl }}
              style={[Platform.OS === 'web' ? styles.imageSlideImageWeb : styles.imageSlideImage]}
              testID="ImageSlideImage"
            />
            {data.sponsored ? <SponsoredIndicator barStyle={{ bottom: 5 }} /> : null}
          </View>
        )}
        <Text style={styles.imageSlideText} testID="ImageSlideTitle">
          {data.title}
        </Text>
      </View>
    </Pressable>
  );
});

//OverlapSilde
const OverlapSilde = memo(({ data }: SlideProps) => {
  return (
    <>
      <View style={styles.overlapSlideContainer} testID="OverlapSilde">
        <View style={styles.overlapSlideImageContainer}>
          <Image
            accessibilityLabel={data.imageAlt}
            source={{ cache: 'web', uri: data.flyerImageUrl }}
            style={styles.overlapSlideFlyer}
            resizeMode="contain"
            testID="OverlapSlideImage"
          />
          <Image
            accessibilityLabel={data.imageAlt}
            source={{ cache: 'web', uri: data.imageUrl }}
            style={styles.overlapSlideImage}
            resizeMode="contain"
            testID="OverlapSlideFlyer"
          />
        </View>
        <View style={styles.overlapTextContainer}>
          <Text style={appStyles.h2Left} testID="OverlapSlideTitle">
            {data.title}
          </Text>
          <Text style={[appStyles.bodyLargeLeft, { marginTop: 10 }]} testID="OverlapSlideSubtitle">
            {data.subtitle}
          </Text>
          {/* TODO:// replace click event with link from bloomreach */}
          {data.linkText && data.url ? (
            <WebBrowserLink style={[{ ...appStyles.linkRedSmall, paddingVertical: 15, fontSize: scale(18) }]} href={data.url}>
              {data.linkText}
            </WebBrowserLink>
          ) : (
            <></>
          )}
        </View>
      </View>
    </>
  );
});

export { DefaultSlide, ImageContentSlide, ImageSlide, OverlapSilde };

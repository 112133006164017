import { FontSource } from 'expo-font';

export enum FontFamily {
  Aleo = 'Aleo',
  AleoBold = 'Aleo-Bold',
  // AleoBoldItalic= 'Aleo-Bold-Italic'
  // AleoItalic= 'Aleo-Italic'
  // AleoLight = 'Aleo-Light'
  // AleoLightItalic = 'Aleo-Light-Italic'
  Larsseit = 'Larsseit',
  LarsseitBold = 'Larsseit-Bold',
  LarsseitBoldItalic = 'Larsseit-Bold-Italic',
  LarsseitExtraBold = 'Larsseit-Extra-Bold',
  // LarsseitExtraBoldItalic = 'Larsseit-Extra-Bold-Italic',
  // LarsseitItalic =  'Larsseit-Italic',
  LarsseitLight = 'Larsseit-Light',
  // LarsseitLightItalic =  'Larsseit-Light-Italic',
  LarsseitMedium = 'Larsseit-Medium',
  // LarsseitMediumItalic =  'Larsseit-Medium-Italic',
  LarsseitThin = 'Larsseit-Thin',
  // LarsseitThinItalic =  'Larsseit-Thin-Italic',
}

export const customFonts: { [key in FontFamily]: FontSource } = {
  'Aleo': require('../assets/fonts/Aleo-Regular.ttf'),
  'Aleo-Bold': require('../assets/fonts/Aleo-Bold.ttf'),
  // 'Aleo-Bold-Italic': require('../assets/fonts/Aleo-BoldItalic.ttf'),
  // 'Aleo-Italic': require('../assets/fonts/Aleo-Italic.ttf'),
  // 'Aleo-Light': require('../assets/fonts/Aleo-Light.ttf'),
  // 'Aleo-Light-Italic': require('../assets/fonts/Aleo-LightItalic.ttf'),
  'Larsseit': require('../assets/fonts/Larsseit.otf'),
  'Larsseit-Bold': require('../assets/fonts/Larsseit-Bold.otf'),
  'Larsseit-Bold-Italic': require('../assets/fonts/Larsseit-BoldItalic.otf'),
  'Larsseit-Extra-Bold': require('../assets/fonts/Larsseit-ExtraBold.otf'),
  // 'Larsseit-Extra-Bold-Italic': require('../assets/fonts/Larsseit-ExtraBoldItalic.otf'),
  // 'Larsseit-Italic': require('../assets/fonts/Larsseit-Italic.otf'),
  'Larsseit-Light': require('../assets/fonts/Larsseit-Light.otf'),
  // 'Larsseit-Light-Italic': require('../assets/fonts/Larsseit-LightItalic.otf'),
  'Larsseit-Medium': require('../assets/fonts/Larsseit-Medium.otf'),
  // 'Larsseit-Medium-Italic': require('../assets/fonts/Larsseit-MediumItalic.otf'),
  'Larsseit-Thin': require('../assets/fonts/Larsseit-Thin.otf'),
  // 'Larsseit-Thin-Italic': require('../assets/fonts/Larsseit-ThinItalic.otf'),
};

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { ShopStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native';

interface CategoryProps {
  categoryId?: string;
  subCategories?: Category[];
  isRootCategory: boolean;
  onTabPress?: (item: Category) => void | undefined;
  selectedCatagoryName?: string;
}

const CategoryNavigation = ({ subCategories, isRootCategory = true, onTabPress, selectedCatagoryName }: CategoryProps) => {
  const navigation = useNavigation();

  const ListHeader = useCallback(() => {
    return !isRootCategory ? (
      <View style={styles.highlight}>
        <TouchableOpacity style={[styles.navItem]}>
          <Text style={[styles.itemText, { color: colors.red }]} testID="CategoryName">
            All
          </Text>
        </TouchableOpacity>
      </View>
    ) : (
      <></>
    );
  }, [isRootCategory]);

  const handleOnPress = (item: Category) => {
    if (isRootCategory) {
      if (onTabPress) {
        onTabPress(item);
      } else {
        navigation.dispatch(StackActions.push(ShopStackRoutes.Category, { categoryId: item.id }));
      }
    } else {
      navigation.dispatch(StackActions.push(ShopStackRoutes.ProductList, { rootCategoryId: item.rootId, categoryId: item.id }));
    }
  };

  const renderItem = ({ item }: ListRenderItemInfo<Category>) => {
    return (
      <TouchableOpacity
        onPress={() => handleOnPress(item)}
        testID={`catNav-${item.name}`}
        style={[
          styles.navItem,
          [item.name === selectedCatagoryName ? { borderBottomWidth: 2, borderBottomColor: colors.red } : { borderBottomWidth: 0, borderBottomColor: '' }],
        ]}>
        <Text
          style={[
            styles.itemText,
            {
              color: selectedCatagoryName === item.name ? colors.red : '#999999',
              fontFamily: FontFamily.LarsseitBold,
            },
          ]}
          testID="categoryText">
          {item.displayName ?? item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  return subCategories && subCategories.length ? (
    <View style={[styles.headerBox]} testID="CategoryNavigation">
      <View style={styles.categoriesBox}>
        <FlatList
          data={subCategories}
          contentContainerStyle={[styles.container]}
          horizontal
          bounces={false}
          renderItem={renderItem}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(_, indx) => indx.toString()}
          ListHeaderComponent={<ListHeader />}
        />
      </View>
      <View />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    minWidth: '100%',
    minHeight: 32,
    justifyContent: 'space-evenly',
    backgroundColor: colors.white,
  },
  navItem: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  arrowIconWrapper: {
    position: 'absolute',
    right: 10,
    top: 5,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  headerBox: {
    height: 32,
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  categoriesBox: {
    paddingHorizontal: 0,
    width: screenWidth,
  },
  highlight: {
    justifyContent: 'center',
    height: '100%',
    borderBottomWidth: 2,
    borderBottomColor: colors.red,
    borderStyle: 'solid',
  },
  itemText: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
    paddingHorizontal: 8,
  },
  icon: {
    marginHorizontal: 7,
    justifyContent: 'center',
  },
});

export default CategoryNavigation;

import { Icon, IconTextInput, Screen } from '@components';
import SaveBar from '@components/SaveBar';
import StoreLocationItem from '@components/store/StoreLocationItem';
import StoreMapView from '@components/store/StoreMapView';
import colors from '@config/colors';
import { Store } from '@fieldera-raleys/client-common';
import { useEffectOnce } from '@hooks';
import { getLastKnownLocation, hasPermissions as hasLocationPermissions } from '@hooks/useLocation';
import { AccountStackRoutes, AppStackRoutes, RootTabRoutes, StoreFinderStackRoutes } from '@navigation/routes';
import { useNavigation, useRoute } from '@react-navigation/native';
import { storeService } from '@services/brandywine';
import useShopStore from '@store/shopStore';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Alert, Linking, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import MapView from 'react-native-maps';
import { useCustomModalContext } from '../../contexts';
interface IMapStoreProps {
  toggleTombstone?: () => void;
  setCurrentPage?: Dispatch<SetStateAction<string>>;
  calledFrom?: string;
}

const MapScreen: FC<IMapStoreProps> = ({ setCurrentPage, calledFrom }): JSX.Element => {
  const {
    setSelectedStore: setSelectedLocation,
    setSelectedShopType,
    favorites,
    setFavorite,
    searchText,
    setSearchText,
    setSearchResult,
    searchResult,
    currentPageTab,
    tempShopType,
    setDeliveryAddress,
    setSelectedTimeSlot,
  } = useShopStore();
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState<Store>();
  const mapRef = React.useRef<MapView>(null);
  const [activeMarker, setActiveMarker] = useState<Store>();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { showAlertModal } = useCustomModalContext();
  const navigation = useNavigation();
  const route = useRoute();

  const handleOnMapPress = useCallback(() => {
    if (currentSelectedLocation || activeMarker) {
      setActiveMarker(undefined);
      setCurrentSelectedLocation(undefined);
    }
  }, [activeMarker, currentSelectedLocation]);

  useEffectOnce(() => {
    handleOnMapPress();
  });

  const searchForLocations = async (text: string) => {
    var locationData = await getLastKnownLocation();
    //API Call for location search will be here
    const searchResults = await storeService.searchStores(0, 25, {
      shippingMethod: 'pickup',
      searchString: text,
      latitude: locationData?.coords.latitude,
      longitude: locationData?.coords.longitude,
    });
    if (searchResults.data.length === 0) {
      Alert.alert('Location', 'Unfortunately we do not yet support this area. Please try finding a different city or zip');
    } else {
      setSearchText(text);
      setSearchResult(searchResults.data);
    }
  };

  const saveLocation = async () => {
    //Function will save address to redux/context
    setButtonLoading(true);
    setSelectedShopType(tempShopType);
    await setSelectedLocation(currentSelectedLocation);
    setButtonLoading(false);
    await setSelectedLocation(currentSelectedLocation);
    setDeliveryAddress(undefined);
    setSelectedTimeSlot(undefined);
    setCurrentPage && setCurrentPage('TimeSlot');
  };

  const showActiveMarkerInfo = () =>
    activeMarker && (
      <>
        <View style={[styles.markerInfo]}>
          <StoreLocationItem
            currentSelectedLocation={currentSelectedLocation}
            selectLocation={setCurrentSelectedLocation}
            favorites={favorites}
            setFavorite={setFavorite}
            store={activeMarker}
            index={activeMarker?.index}
            isOnMapScreen={true}
          />
        </View>
        <SaveBar onPress={saveLocation} showMapSave={true} isButtonLoading={buttonLoading} />
      </>
    );

  const navigateToMarker = async (marker: Store) => {
    const camera = await mapRef.current?.getCamera();
    if (camera) {
      if (camera.center.latitude === parseFloat(marker.coordinates.latitude) && camera.center.longitude === parseFloat(marker.coordinates.longitude)) {
        return;
      }
      camera.center.latitude = parseFloat(marker.coordinates.latitude);
      camera.center.longitude = parseFloat(marker.coordinates.longitude);
      camera.pitch = 0;
      camera.heading = 0;
      mapRef.current?.animateCamera(camera, { duration: 500 });
    }
  };

  const onMarkerPress = (marker: Store, index: number) => {
    marker.index = index + 1;
    setTimeout(() => {
      setActiveMarker(marker);
      navigateToMarker(marker);
      setCurrentSelectedLocation(marker);
    }, 250);
  };

  const useCurrentLocation = async () => {
    const permission = await hasLocationPermissions();
    if (!permission) {
      showAlertModal({
        title: 'Permissions Required',
        message: 'This feature requires location permissions to work properly.',
        onClose: () => {
          Platform.select({
            ios: Linking.openURL('app-settings:'),
            android: Linking.openSettings(),
          });
        },
      });
      return;
    }

    var locationData = await getLastKnownLocation();
    var res = await storeService.searchStores(0, 25, {
      shippingMethod: 'pickup',
      latitude: locationData?.coords.latitude,
      longitude: locationData?.coords.longitude,
    });
    res.data.length && setSearchResult(res.data);
  };

  return (
    <Screen headerVisible={true}>
      {route.name === StoreFinderStackRoutes.StoreFinderDetail && (
        <TouchableOpacity
          style={styles.mapCrossIcon}
          testID="x-close"
          onPress={() => {
            navigation.goBack();
            navigation.navigate(AppStackRoutes.RootTabs, {
              screen: RootTabRoutes.Account,
              params: {
                screen: AccountStackRoutes.StoreFinder,
                params: {
                  screen: StoreFinderStackRoutes.StoreFinderDetail,
                  params: { storeNumber: searchText },
                },
              },
            });
          }}>
          <Icon
            name="x-close"
            fill={colors.white}
            stroke={colors.sectionBorder}
            fillSecondary={colors.red}
            style={[styles.icon, { marginRight: 15 }]}
            size={20}
          />
        </TouchableOpacity>
      )}
      {!calledFrom && (
        <View style={styles.searchWrapper}>
          <IconTextInput
            testID="searchByStoreZipTextInput"
            width={'85%'}
            clearButtonMode="while-editing"
            placeholder="Search by store or zip"
            onSubmitEditing={({ nativeEvent: { text } }) => {
              if (!text) {
                return;
              }
              setSearchText(text);
              searchForLocations(text);
            }}
            fieldStyleEx={{ height: 42 }}
            onChangeText={setSearchText}
            value={searchText}
          />
          <TouchableOpacity onPress={useCurrentLocation} style={styles.locationIcon}>
            <Icon name="location-arrow" fill={colors.white} stroke={colors.darkText} style={styles.arrowIcon} testID="locationarrow" />
          </TouchableOpacity>
        </View>
      )}
      <StoreMapView
        ref={mapRef}
        containerStyle={{ marginTop: 20 }}
        data={currentPageTab !== 'Favorites' ? searchResult : favorites}
        activeMarker={activeMarker}
        onPress={handleOnMapPress}
        onMarkerPress={onMarkerPress}
        maxZoomLevel={15}
      />
      {currentSelectedLocation?.address?.street && showActiveMarkerInfo()}
    </Screen>
  );
};

const styles = StyleSheet.create({
  initialOptionsWrapper: {
    alignItems: 'center',
    height: '100%',
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    marginBottom: 10,
    marginTop: 100,
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListText: {
    fontFamily: 'Larsseit',
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 26,
    width: '75%',
    backgroundColor: 'red',
  },

  locationSearchBar: {
    width: '75%',
    height: 45,
    borderWidth: 1,
    borderRadius: 15,
    backgroundColor: 'white',
    borderColor: '#ecdcd1',
    paddingVertical: 0,
    paddingHorizontal: 10,
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    marginRight: Platform.OS === 'web' ? 0 : 7,
    color: colors.darkText,
    alignSelf: 'center',
  },
  locationIcon: {
    justifyContent: 'center',
  },
  searchWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '90%',
    left: 0,
    marginLeft: 35,
    position: 'absolute',
    top: -30,
    zIndex: 0,
    alignSelf: 'center',
  },
  arrowIcon: {
    width: 25,
    height: 25,
    transform: [{ translateY: -3 }, { translateX: -3 }],
  },
  mapContainer: {
    ...StyleSheet.absoluteFillObject,
    height: '93.5%',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  customView: {
    width: 300,
    borderRadius: 10,
  },
  markerInfo: {
    zIndex: 999,
    width: screenWidth,
    position: 'absolute',
    left: -20,
    bottom: screenHeight * 0.115,
  },
  markerIconText: {
    position: 'absolute',
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(12),
    paddingEnd: 5,
    top: Platform.OS === 'android' ? 3 : 6,
  },
  mapCrossIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    marginTop: scale(-30),
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 55,
    height: 45,
  },
});

export default MapScreen;

import { LoadingScreen } from '@components';
import Text from '@components/Text';
import OfferCard from '@components/somethingExtra/OfferCard';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import useCancelToken from '@hooks/useCancelToken';
import { useOffersStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, RefreshControl, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

interface DigitalCouponsScreenProps {
  style?: StyleProp<ViewStyle>;
  currTab?: string;
}

const DigitalCouponsScreen: FC<DigitalCouponsScreenProps> = ({ style, currTab }): JSX.Element => {
  const { availableDigitalCoupons, acceptedDigitalCoupons, fetchDigitalCoupons, offersState } = useOffersStore();
  const allOffers = availableDigitalCoupons.concat(acceptedDigitalCoupons);

  // const navigation = useNavigation();
  const [currToggle, setCurrToggle] = useState(currTab ?? 'All');
  const cancelToken = useCancelToken();
  const { t } = useTranslation('digitalCoupons');
  const toggleButtons = [
    {
      name: 'All',
    },
    {
      name: 'Clipped',
    },
  ];

  const refetchCoupons = () => {
    fetchDigitalCoupons(cancelToken);
  };

  const renderListHeaderComponent = () => {
    return (
      <>
        <View style={styles.toggleBar}>
          <View style={styles.toggleWrapper}>
            {toggleButtons.map((tab) => {
              return (
                <TouchableOpacity
                  key={tab.name}
                  onPress={() => setCurrToggle(tab.name)}
                  style={[styles.toggle, { backgroundColor: currToggle === tab.name ? colors.white : colors.darkCream }]}>
                  <Text style={styles.tabTitle}>
                    {tab.name} {tab.name === 'Clipped' && `(${acceptedDigitalCoupons.length ?? 0})`}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
        {/* <View style={styles.searchHeaderWrapper}>
          <View style={styles.searchBarWrapper}>
            <TouchableOpacity style={styles.iconWrapper}>
              <Icon testID="searchIcon" name="search-icon" size={20} style={appStyles.icon} />
            </TouchableOpacity>
            <TextInput testID="searchTextInput" style={[appStyles.formFieldText, styles.searchTextInput]} placeholder={t('placeholder')} />
          </View>
          <TouchableOpacity
            style={styles.sortIconWrapper}
            onPress={() => {
              navigation.navigate(AppStackRoutes.SearchFilter, {
                title: 'Filter & Sort',
                text: 'Sweet',
              });
            }}>
            <Icon testID="sortIcon" name="sort-icon" size={28} style={appStyles.icon} />
          </TouchableOpacity>
        </View> */}
      </>
    );
  };

  return offersState === 'loading' ? (
    <LoadingScreen />
  ) : allOffers.length > 0 ? (
    <>
      <View style={[styles.container, style]}>
        <FlatList
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={styles.dealsList}
          ListHeaderComponent={renderListHeaderComponent()}
          refreshControl={<RefreshControl refreshing={false} onRefresh={refetchCoupons} />}
          keyExtractor={(_, i) => i.toString()}
          data={currToggle === 'All' ? allOffers : acceptedDigitalCoupons}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.offerWrapper}>
                <OfferCard item={item} index={index} calledFrom={'DigitalCoupons'} />
              </View>
            );
          }}
          numColumns={2}
          directionalLockEnabled
          contentContainerStyle={[utilityStyles.pb5, styles.contentContainer]}
        />
      </View>
    </>
  ) : (
    <>
      <View style={styles.whitebox}>
        <Text testID="offersText" style={[appStyles.bodyLargeCenter, { fontFamily: FontFamily.LarsseitBold, width: offersState !== 'error' ? '70%' : '85%' }]}>
          {offersState === 'error' ? "We're sorry, there seems to be a technical problem." : 'There are currently no SE Digital Coupons available.'}
        </Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    width: screenWidth,
    alignItems: 'center',
    paddingBottom: Platform.select({
      // ios: 60,
      android: 15,
    }),
    paddingTop: Platform.select({ ios: 65, android: 10 }),
    marginLeft: 0,
    overflow: 'visible',
  },
  contentContainer: {
    // backgroundColor: 'red',
    // justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: -12,
  },
  whitebox: {
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    flex: 1,
    width: screenWidth,
    height: '100%',
    alignSelf: 'center',
  },
  dealsList: {
    // marginLeft: -12,
    // backgroundColor: 'gold',
    width: '100%',
  },
  toggleBar: {
    alignSelf: 'center',
    width: 240,
    height: 30,
    borderRadius: 5,
    backgroundColor: colors.darkCream,
    flexDirection: 'row',
    padding: 1,
    marginBottom: 30,
    borderColor: colors.darkCream,
    borderWidth: 0.2,
  },
  toggleWrapper: {
    flexDirection: 'row',
  },
  toggle: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  tabTitle: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
    opacity: 1,
    color: colors.darkText,
  },
  offerWrapper: {
    marginBottom: 16,
    marginLeft: 12,
  },
  searchHeaderWrapper: {
    width: screenWidth - 72,
    marginLeft: 12,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    // backgroundColor: 'gold',
  },
  searchBarWrapper: {
    flex: 1,
    // width: '85%',
    alignContent: 'center',
    // justifyContent: 'space-evenly',
    marginTop: 30,
    // marginLeft: 12,
    paddingLeft: 12,
    marginBottom: 30,
    backgroundColor: 'white',
    flexDirection: 'row',
    borderRadius: 12.5,
    borderWidth: 1,
    borderColor: colors.lineSeparator,
    borderStyle: 'solid',
    height: 48,
  },
  searchTextInput: {
    alignSelf: 'center',
    padding: 5,
    width: '70%',
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
  },
  iconWrapper: {
    padding: 5,
    paddingBottom: 5,
    alignSelf: 'center',
  },
  sortIconWrapper: {
    alignSelf: 'center',
    padding: 5,
    paddingLeft: 10,
    // backgroundColor: 'green',
  },
});

export default DigitalCouponsScreen;

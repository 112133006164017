import { appStyles } from '@styles';
import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Screen from './Screen';

interface LoadingScreenProps {
  headerVisible?: boolean;
}
const LoadingScreen = ({ headerVisible = false }: LoadingScreenProps) => {
  const insets = useSafeAreaInsets();
  var margins = !headerVisible
    ? { marginTop: insets.top, paddingLeft: insets.left, paddingBottom: insets.bottom, paddingRight: insets.right }
    : { marginTop: 0, paddingLeft: 0, paddingBottom: 0, paddingRight: 0 };
  return (
    <Screen
      style={[
        styles.container,
        {
          ...margins,
        },
      ]}>
      <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} testID="loading" />
    </Screen>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default LoadingScreen;

import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useEffectOnce } from '@hooks';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import logger from '@utils/logger';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Animated, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Button from './Button';
import Text from './Text';

const ErrorFallbackComponent = (props: FallbackProps) => {
  const insets = useSafeAreaInsets();
  const modalY = new Animated.Value(0);
  useEffectOnce(() => {
    logger.error(props.error);
  });

  return (
    <>
      <View style={[StyleSheet.absoluteFillObject, styles.overlay]} />
      <Animated.View style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }, { transform: [{ translateY: modalY }] }]}>
        <View style={styles.header}>
          <View style={{ alignItems: 'center', width: '100%' }}>
            <Text style={[styles.headerText]}>It's Not You, It's Me. </Text>
          </View>
        </View>
        <View style={[styles.content]}>
          {__DEV__ ? <Text style={styles.error}>{props.error.toString()}</Text> : <Text style={styles.text}>It seems there's a little technical problem.</Text>}
        </View>
        <View style={styles.buttonContainer}>
          <Button onPress={props.resetErrorBoundary} title={'Retry'} type={'secondary'} size="small" buttonStyle={{ width: 120 }} />
        </View>
      </Animated.View>
    </>
  );
};
const styles = StyleSheet.create({
  overlay: {
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 100,
  },
  container: {
    position: 'absolute',
    width: screenWidth,
    height: screenHeight * 0.45,
    marginTop: 'auto',
    backgroundColor: 'white',
    shadowColor: colors.white,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    bottom: 0,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    zIndex: 200,
  },
  content: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    paddingBottom: 0,
    alignItems: 'center',
    height: '70%',
  },
  header: {
    height: 56,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 25,
    textAlign: 'center',
  },
  headerText: {
    fontFamily: 'Aleo-Bold',
    fontSize: scale(28),
    color: colors.text,
    textAlign: 'center',
    paddingBottom: 35,
    paddingTop: 20,
    lineHeight: lineHeight(28),
    width: screenWidth * 0.8,
    alignSelf: 'center',
  },
  subTitle: {
    fontSize: 32,
    fontWeight: '800',
    color: colors.black,
  },
  error: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,

    fontSize: scale(18),
    lineHeight: scale(25),
    textAlign: 'center',
    bottom: 50,
  },
  buttonContainer: {
    flexDirection: 'row',
    width: screenWidth,
    justifyContent: 'space-evenly',
    position: 'absolute',
    alignSelf: 'center',
    bottom: 16,
  },
  text: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(18),
    lineHeight: scale(30),
    textAlign: 'center',
    bottom: 10,
    width: '72%',
  },
});

export default ErrorFallbackComponent;

import * as LocalAuthentication from 'expo-local-authentication';

const hasHardwareAsync = async () => {
  return false;
};
const supportedAuthenticationTypesAsync = async (): Promise<number[]> => {
  return [];
};

const isEnrolledAsync = async () => {
  return false;
};

const authenticateAsync = async (_options?: LocalAuthentication.LocalAuthenticationOptions | undefined) => {
  return undefined;
};

export { hasHardwareAsync, isEnrolledAsync, supportedAuthenticationTypesAsync, authenticateAsync };

import { ErrorFallbackComponent, HeaderTitle, NavigationHeader, Screen } from '@components';
import AddressForm from '@components/forms/AddressForm';
import { AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps } from '@navigation/routes';
import { utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

type AddAddressScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.AddAddress>;
const AddAddressScreen = ({ navigation, route }: AddAddressScreenProps) => {
  const { addressId, calledFrom } = route.params;

  const handleAfterSubmit = useCallback(
    (addrId: string | number): void => {
      if (calledFrom) {
        navigation.navigate({
          name: AppStackRoutes.CartAndCheckout,
          params: { screen: calledFrom, params: { addressId: addrId } },
          merge: true,
        });
      } else {
        navigation.goBack();
      }
    },
    [calledFrom, navigation],
  );

  return (
    <Screen>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID={`${addressId ? 'Edit' : 'Add'} AddressTitle`}>{`${addressId ? 'Edit' : 'Add'} Address`}</HeaderTitle>
      </NavigationHeader>
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
        <AddressForm mode={'billing'} addressId={addressId} onAfterSubmit={handleAfterSubmit} />
      </ErrorBoundary>
    </Screen>
  );
};

export default AddAddressScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { ShoppingList } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useCartStore, useListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import React, { FC, useCallback } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';

interface AddToListOptionsProps {
  productSku: string | string[];
  searchText: string;
  containerStyle?: StyleProp<ViewStyle>;
  navigation?: any;
}

const AddToListOptions: FC<AddToListOptionsProps> = ({ productSku, searchText, containerStyle, navigation }) => {
  const { lists, addItemToList, addTextLineItemToList, addItemsToShoppingList } = useListsStore();
  const { hideModal, showAlertPopup } = useCustomModalContext();
  const { emptyCart } = useCartStore();

  const onCloseClick = () => {
    navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.CartLanding, params: {} });
  };

  const doEmptyCart = useCallback(async () => {
    try {
      await emptyCart();
    } catch (e) {
      throw e;
    }
    onCloseClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewList = useCallback(
    (list: ShoppingList) => {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: { screen: AccountStackRoutes.ViewList, params: { title: list?.name ?? '', listName: list?.name ?? '', listId: list?.id ?? '' } },
      });
    },
    [navigation],
  );

  const handlePress = useCallback(
    async (li: ShoppingList) => {
      if (productSku && typeof productSku === 'string') {
        await addItemToList(productSku, li.name ?? '');
      } else if (productSku && Array.isArray(productSku)) {
        await addItemsToShoppingList(
          li?.id ?? '',
          li?.version,
          productSku.map((s) => {
            return {
              sku: s,
              quantity: 1,
            };
          }),
          [],
        );
        hideModal();
        doEmptyCart();
        if (productSku.length) {
          showAlertPopup({
            message: `Item(s) Moved to "${li?.name}"`,
            containerStyle: containerStyle,
            linkText: 'View',
            linkAction: () => viewList(li),
          });
        }
        return;
      } else if (searchText) {
        await addTextLineItemToList(searchText, '', li.name ?? '');
      }
      hideModal();
      showAlertPopup({ message: `Item Added to "${li.name}"`, containerStyle: containerStyle, linkText: 'View', linkAction: () => viewList(li) });
    },
    [addItemToList, hideModal, showAlertPopup, addTextLineItemToList, productSku, searchText, containerStyle, viewList, addItemsToShoppingList, doEmptyCart],
  );

  return (
    <>
      {lists
        ?.filter((l) => l.name !== 'Saved For Later')
        ?.map((list: ShoppingList) => {
          return (
            <View style={[styles.linkContainer]} key={list.id}>
              <TouchableOpacity
                onPress={() => {
                  handlePress(list);
                }}
                style={[styles.link, { flexDirection: 'row' }]}>
                {list.name === 'Favorites' ? <Icon name={'heart-icon'} fill={colors.darkText} stroke={colors.darkText} size={15} /> : null}
                <Text testID={list.name ?? 'listName'} style={[appStyles.bodySmallLeft, utilityStyles.ml3]}>
                  {list.name ?? ''}
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
    </>
  );
};

const styles = StyleSheet.create({
  linkContainer: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    justifyContent: 'center',
  },
  link: {
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
});

export default AddToListOptions;

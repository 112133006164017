import { Platform } from 'react-native';

const PAGE_SIZE = 10;

const CUSTOMER_SERVICE_PHONE = '1 (800) 925-9989';
const CUSTOMER_SERVICE_EMAIL = 'service@raleys.com';
const PICKUP_SHIPPING_ID = 7;
const DELIVERY_SHIPPING_ID = 8;
const ONLINE_ORDER_TYPE_ID = 1;
const INSTORE_ORDER_TYPE_ID = 7;
const USE_NATIVE_DRIVER = Platform.OS === 'web' ? false : true;

const MONTH_LIST: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const STATES_LIST = [
  { id: 1, name: 'Alabama', abbreviation: 'AL' },
  { id: 2, name: 'Alaska', abbreviation: 'AK' },
  { id: 4, name: 'Arizona', abbreviation: 'AZ' },
  { id: 5, name: 'Arkansas', abbreviation: 'AR' },
  { id: 6, name: 'California', abbreviation: 'CA' },
  { id: 7, name: 'Colorado', abbreviation: 'CO' },
  { id: 8, name: 'Connecticut', abbreviation: 'CT' },
  { id: 9, name: 'Delaware', abbreviation: 'DE' },
  { id: 10, name: 'District of Columbia', abbreviation: 'DC' },
  { id: 12, name: 'Florida', abbreviation: 'FL' },
  { id: 13, name: 'Georgia', abbreviation: 'GA' },
  { id: 15, name: 'Hawaii', abbreviation: 'HI' },
  { id: 16, name: 'Idaho', abbreviation: 'ID' },
  { id: 17, name: 'Illinois', abbreviation: 'IL' },
  { id: 18, name: 'Indiana', abbreviation: 'IN' },
  { id: 19, name: 'Iowa', abbreviation: 'IA' },
  { id: 20, name: 'Kansas', abbreviation: 'KS' },
  { id: 21, name: 'Kentucky', abbreviation: 'KY' },
  { id: 22, name: 'Louisiana', abbreviation: 'LA' },
  { id: 23, name: 'Maine', abbreviation: 'ME' },
  { id: 25, name: 'Maryland', abbreviation: 'MD' },
  { id: 26, name: 'Massachusetts', abbreviation: 'MA' },
  { id: 27, name: 'Michigan', abbreviation: 'MI' },
  { id: 28, name: 'Minnesota', abbreviation: 'MN' },
  { id: 29, name: 'Mississippi', abbreviation: 'MS' },
  { id: 30, name: 'Missouri', abbreviation: 'MO' },
  { id: 31, name: 'Montana', abbreviation: 'MT' },
  { id: 32, name: 'Nebraska', abbreviation: 'NE' },
  { id: 33, name: 'Nevada', abbreviation: 'NV' },
  { id: 34, name: 'New Hampshire', abbreviation: 'NH' },
  { id: 35, name: 'New Jersey', abbreviation: 'NJ' },
  { id: 36, name: 'New Mexico', abbreviation: 'NM' },
  { id: 37, name: 'New York', abbreviation: 'NY' },
  { id: 38, name: 'North Carolina', abbreviation: 'NC' },
  { id: 39, name: 'North Dakota', abbreviation: 'ND' },
  { id: 41, name: 'Ohio', abbreviation: 'OH' },
  { id: 42, name: 'Oklahoma', abbreviation: 'OK' },
  { id: 43, name: 'Oregon', abbreviation: 'OR' },
  { id: 45, name: 'Pennsylvania', abbreviation: 'PA' },
  { id: 47, name: 'Rhode Island', abbreviation: 'RI' },
  { id: 48, name: 'South Carolina', abbreviation: 'SC' },
  { id: 49, name: 'South Dakota', abbreviation: 'SD' },
  { id: 50, name: 'Tennessee', abbreviation: 'TN' },
  { id: 51, name: 'Texas', abbreviation: 'TX' },
  { id: 52, name: 'Utah', abbreviation: 'UT' },
  { id: 53, name: 'Vermont', abbreviation: 'VT' },
  { id: 55, name: 'Virginia', abbreviation: 'VA' },
  { id: 56, name: 'Washington', abbreviation: 'WA' },
  { id: 57, name: 'West Virginia', abbreviation: 'WV' },
  { id: 58, name: 'Wisconsin', abbreviation: 'WI' },
  { id: 59, name: 'Wyoming', abbreviation: 'WY' },
];

const PICKER_COLORS = [
  {
    name: 'White',
    color: '#FFFFFF',
  },
  {
    name: 'Black',
    color: '#000000',
  },
  {
    name: 'Orange',
    color: '#FA6400',
  },
  {
    name: 'Green',
    color: '#5EB700',
  },
  {
    name: 'Yellow',
    color: '#FFC72C',
  },
  {
    name: 'Red',
    color: '#E02020',
  },
  {
    name: 'Blue',
    color: '#2580EC',
  },
  {
    name: 'Purple',
    color: '#6236FF',
  },
  {
    name: 'Pink',
    color: '#FB95FF',
  },
  {
    name: 'Baby Blue',
    color: '#77D9FF',
  },
  {
    name: 'Lavender',
    color: '#C6B5FF',
  },
  {
    name: 'Light Yellow',
    color: '#FDFD5E',
  },
  {
    name: 'Light Green',
    color: '#AEFF58',
  },
];

const PRODUCT_FLAG_TYPES = ['5DAY', '5DM', 'BMSM%', 'BMSM', 'SPECSAV'];
const PRODUCT_BADGE_TYPES = ['somethingextra', 'weeklyexclusive', 'mfg'];

export default {
  PAGE_SIZE,
  CUSTOMER_SERVICE_PHONE,
  CUSTOMER_SERVICE_EMAIL,
  STATES_LIST,
  PICKUP_SHIPPING_ID,
  DELIVERY_SHIPPING_ID,
  ONLINE_ORDER_TYPE_ID,
  INSTORE_ORDER_TYPE_ID,
  PICKER_COLORS,
  MONTH_LIST,
  USE_NATIVE_DRIVER,
  PRODUCT_FLAG_TYPES,
  PRODUCT_BADGE_TYPES,
};

import { createStackNavigator } from '@react-navigation/stack';
import { ContactCustomerScreen, HelpCenterScreen, HelpTopicsDetailScreen, HelpTopicsListScreen } from '@screens/account';
import React from 'react';
import { HelpCenterStackParamList, HelpCenterStackRoutes } from './routes';

const HelpCenterNavigator = () => {
  const HelpCenterStack = createStackNavigator<HelpCenterStackParamList>();

  const verticalTransition = {
    cardStyleInterpolator: ({ current, layouts }) => {
      return {
        cardStyle: {
          transform: [
            {
              translateY: current.progress.interpolate({
                inputRange: [0, 1],
                outputRange: [-layouts.screen.height, 0],
              }),
            },
          ],
        },
      };
    },
  };

  return (
    <HelpCenterStack.Navigator initialRouteName={HelpCenterStackRoutes.HelpCenterScreen} screenOptions={{ headerShown: false, cardOverlayEnabled: true }}>
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.HelpCenterScreen} component={HelpCenterScreen} />
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.ContactCustomerScreen} component={ContactCustomerScreen} options={verticalTransition} />
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.HelpTopicsList} component={HelpTopicsListScreen} />
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.HelpTopicsDetailScreen} component={HelpTopicsDetailScreen} />
    </HelpCenterStack.Navigator>
  );
};

export default HelpCenterNavigator;

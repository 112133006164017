import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { NutritionLabels } from '@fieldera-raleys/client-common/services/brandywine/types';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import NutrientRow, { NutrientRowProps } from './NutrientRow';

type SeparatorBarProps = {
  height: number;
};
const SeparatorBar = ({ height }: SeparatorBarProps) => {
  return <View style={{ backgroundColor: colors.black, borderWidth: height }} />;
};

const RenderNutrientRow = ({ label, value, indent, dailyValue, unit, isBold, precisionCode, height }: NutrientRowProps) => {
  return (
    <View>
      <NutrientRow label={label} value={value} dailyValue={dailyValue} unit={unit} indent={indent} isBold={isBold} precisionCode={precisionCode} />
      <SeparatorBar height={height} />
    </View>
  );
};

export interface NutrientProps {
  nutritionFact: NutritionLabels;
}
const NutritionLabel = ({ nutritionFact }: NutrientProps) => {
  const { t } = useTranslation('nutritionFact');

  const isCalorioesFromFat =
    nutritionFact?.labelNutrients &&
    nutritionFact?.labelNutrients.length > 1 &&
    nutritionFact.labelNutrients[1].nutrientType?.displayName === 'Calories from Fat';

  const otherNutritionFact = isCalorioesFromFat
    ? nutritionFact?.labelNutrients && nutritionFact?.labelNutrients.slice(2)
    : nutritionFact?.labelNutrients && nutritionFact?.labelNutrients.slice(1);

  return (
    <View style={[styles.labelContainer]}>
      <Text testID="nutritionFactTitle" style={styles.title}>
        {t('nutritionFactTitle')}
      </Text>
      <SeparatorBar height={0.3} />
      {nutritionFact?.numberOfServings && (
        <Text testID="numberOfServings" style={[styles.servingInfo, styles.servingPerInfo]}>{`Servings Per Container ${nutritionFact?.numberOfServings}`}</Text>
      )}

      {(nutritionFact.servingSize !== 0 || nutritionFact.metricServingSize !== 0) && (
        <View style={styles.servingSizeView}>
          <View style={styles.servingSizeTitle}>
            <Text testID="servingSizetitle" style={styles.servingInfo}>{`${t('servingSizetitle')}`}</Text>
            {nutritionFact.metricServingSize !== 0 && (
              <Text
                testID="metricServingSizeUnit"
                style={styles.servingInfo}>{` ${nutritionFact.metricServingSize}${nutritionFact.metricServingSizeUnit}`}</Text>
            )}
          </View>
          {nutritionFact.servingSize !== 0 && (
            <View style={styles.servingSizeTitle}>
              <Text testID="servingSize" style={styles.servingInfo}>{`${nutritionFact.servingSize}`}</Text>
              {nutritionFact?.servingSizeUnit && <Text style={styles.servingInfo}>{nutritionFact?.servingSizeUnit}</Text>}
            </View>
          )}
        </View>
      )}

      <SeparatorBar height={3} />
      <Text testID="amountPerServing" style={styles.amountPerServing}>
        {t('amountPerServing')}
      </Text>

      {nutritionFact.labelNutrients && nutritionFact?.labelNutrients.length > 0 && nutritionFact.labelNutrients[0] && (
        <RenderNutrientRow
          label={nutritionFact.labelNutrients[0].nutrientType.displayName}
          value={''}
          height={isCalorioesFromFat ? 0.3 : nutritionFact.labelNutrients[0].nutrientType.height}
          isBold={nutritionFact.labelNutrients[0].nutrientType.isBold}
          indent={nutritionFact.labelNutrients[0].nutrientType.isIndent}
          dailyValue={nutritionFact.labelNutrients[0].value}
          unit={''}
          precisionCode={nutritionFact.labelNutrients[0].precisionCode}
        />
      )}

      {isCalorioesFromFat && (
        <RenderNutrientRow
          label={nutritionFact.labelNutrients[1].nutrientType.displayName}
          value={nutritionFact.labelNutrients[1].value}
          height={nutritionFact.labelNutrients[1].nutrientType.height}
          isBold={nutritionFact.labelNutrients[1].nutrientType.isBold}
          indent={nutritionFact.labelNutrients[1].nutrientType.isIndent}
          dailyValue={nutritionFact.labelNutrients[1].dailyValue}
          unit={''}
          precisionCode={nutritionFact.labelNutrients[1].precisionCode}
        />
      )}
      <Text testID="dailyValue" style={styles.percentDailyValueLabel}>
        {t('dailyValue')}
      </Text>
      <SeparatorBar height={0.3} />

      {otherNutritionFact &&
        otherNutritionFact.map(
          (
            item: {
              nutrientType: { displayName: string; height: number; isIndent: boolean; isBold: boolean };
              value: string;
              dailyValue: string;
              unit: string;
              precisionCode: string | undefined;
            },
            index: { toString: () => React.Key | null | undefined },
          ) => {
            return (
              <RenderNutrientRow
                label={item.nutrientType.displayName}
                value={item.value}
                dailyValue={item.dailyValue}
                unit={item.unit}
                height={item.nutrientType.height}
                indent={item.nutrientType.isIndent}
                isBold={item.nutrientType.isBold}
                key={index.toString()}
                precisionCode={item.precisionCode}
              />
            );
          },
        )}

      <Text testID="note" style={styles.dailyValues}>
        {t('note')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  labelContainer: {
    backgroundColor: colors.white,
    color: colors.black,
    textAlign: 'left',
    width: screenWidth,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    fontSize: scale(23),
    margin: 0,
    fontFamily: FontFamily.LarsseitExtraBold,
  },
  servingInfo: {
    lineHeight: lineHeight(14),
    fontSize: scale(14),
    margin: 1,
    marginLeft: 1,
    marginBottom: 2,
    fontFamily: FontFamily.LarsseitBold,
  },
  dailyValues: {
    lineHeight: lineHeight(10),
    fontSize: scale(10),
    textAlign: 'left',
    margin: 0,
    marginTop: 10,
    fontFamily: FontFamily.LarsseitLight,
  },
  amountPerServing: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitLight,
    margin: 1,
    marginBottom: 1,
    paddingVertical: 2,
  },
  percentDailyValueLabel: {
    lineHeight: scale(12),
    fontSize: scale(12),
    textAlign: 'right',
    margin: 0,
    paddingVertical: 5,
    fontFamily: FontFamily.LarsseitExtraBold,
  },
  servingSizeView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 3,
  },
  servingPerInfo: {
    fontFamily: FontFamily.LarsseitLight,
    paddingVertical: 4,
  },
  servingSizeTitle: { flexDirection: 'row' },
});

export default NutritionLabel;

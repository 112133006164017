import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document } from '@bloomreach/spa-sdk';
import colors from '@config/colors';
import appStyles from '@styles/appStyles';
import { containerWidth, screenWidth } from '@styles/constants';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import ImageContentBanner from './banner/ImageContentBanner';
import { BannerDocument } from './brxm';

const Banner = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.editContainer}>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { bannerType } = document.getData<BannerDocument>();

  if (bannerType === 'Image Content Sub Banner') {
    return <ImageContentBanner component={component} page={page} imageResizeMode={'contain'} />;
  } else if (bannerType === 'Image Content Banner') {
    return (
      <ImageContentBanner
        component={component}
        page={page}
        mainContainerStyle={styles.mainContainerStyle}
        imageContentSlideContainerStyle={styles.imageContentSlideContainerStyle}
        imageContainerStyle={styles.imageContainerStyle}
        imageContentSlideContentStyle={styles.imageContentSlideContentStyle}
        imageResizeMode={'contain'}
        subtitleStyle={appStyles.fontMobileH6Center}
        contentStyle={appStyles.bodySmallCenterRegular}
        buttonStyle={styles.buttonStyle}
      />
    );
  } else {
    return <></>;
  }
};

const styles = StyleSheet.create({
  editContainer: {
    width: containerWidth,
    minHeight: 100,
  },
  mainContainerStyle: {
    backgroundColor: colors.cream,
    marginTop: 0,
    marginBottom: 0,
  },
  imageContentSlideContainerStyle: {
    width: screenWidth,
    backgroundColor: colors.cream,
    marginVertical: 0,
  },
  imageContainerStyle: {
    width: screenWidth,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  imageContentSlideContentStyle: {
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    textAlign: 'center',
  },
  buttonStyle: {
    maxWidth: 400,
  },
});

export default Banner;

import { useTheme } from '@react-navigation/native';
import { appStyles } from '@styles';
import React, { forwardRef } from 'react';
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps } from 'react-native';

const TextInput = forwardRef<NativeTextInput, NativeTextInputProps>(
  ({ children, style, allowFontScaling = false, maxFontSizeMultiplier = 1, ...rest }: NativeTextInputProps, ref): JSX.Element => {
    const { colors } = useTheme();
    return (
      <NativeTextInput
        ref={ref}
        style={[appStyles.text, { color: colors.text }, style]}
        allowFontScaling={allowFontScaling}
        maxFontSizeMultiplier={maxFontSizeMultiplier}
        {...rest}>
        {children}
      </NativeTextInput>
    );
  },
);

export default TextInput;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { appStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import React from 'react';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

export type TabItem = {
  name: string;
  displayName?: string;
  onPress?: (item: TabItem) => void;
  onLongPress?: (item: TabItem) => void;
};

interface TabbarProps {
  tabs: TabItem[];
  selectedTabName: string;
  containerStyle?: StyleProp<ViewStyle>;
}

const Tabbar = ({ tabs, selectedTabName: selctedTabName, containerStyle }: TabbarProps) => {
  const renderItem = ({ item }: ListRenderItemInfo<TabItem>) => {
    return (
      <TouchableOpacity
        onPress={() => item.onPress && item.onPress(item)}
        onLongPress={() => {
          item.onLongPress && item.onLongPress(item);
        }}
        testID={`tab-${item.name}`}
        style={[
          styles.tabItem,
          [item.name === selctedTabName ? { borderBottomWidth: 2, borderBottomColor: colors.red } : { borderBottomWidth: 0, borderBottomColor: '' }],
        ]}>
        <Text
          testID={`tab-${selctedTabName}`}
          style={[
            styles.itemText,
            {
              color: item.name === selctedTabName ? colors.red : '#999999',
            },
          ]}>
          {item.displayName ?? item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <FlatList
      data={tabs}
      contentContainerStyle={[styles.container, containerStyle]}
      horizontal
      bounces={false}
      renderItem={renderItem}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(_, idx) => idx.toString()}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: '100%',
    minHeight: 32,
    justifyContent: 'space-evenly',
    backgroundColor: colors.white,
  },
  tabItem: {
    justifyContent: 'center',
    paddingHorizontal: 8,
  },
  itemText: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
  },
  icon: {
    ...appStyles.mediumIcon,
    marginHorizontal: 7,
    justifyContent: 'center',
  },
});

export default Tabbar;

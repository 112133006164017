export const defaultNamespace = 'common';
export const namespaces = [
  'appBenefits',
  // 'cart',
  'common',
  // 'customization',
  'login',
  // 'tombstone',
  // 'productDetails',
  // 'barcodeModal',
  'forgotEmail',
  //  'changePassword',
  'forgotPassword',
  'resetPassword',
  // 'verifyOTC',
  // 'editProfile',
  // 'searchBar',
  // 'savingsReminder',
  // 'storeFinder',
  // 'createAccount',
  // 'topDeals',
  // 'itemsYouMayLike',
  // 'digitalCoupons',
  // 'helpCenter',
  // 'purchaseHistory',
  // 'shelfGuide',
  // 'pointsHeader',
];

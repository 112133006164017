import { EventType } from '@fieldera-raleys/client-common/services/analytics/types';
import Analytics from 'appcenter-analytics';
import logger from './logger';

export const logToAppCenter = async (eventName: EventType, eventData: Record<string, string | undefined>) => {
  try {
    if (await Analytics.isEnabled()) {
      await Analytics.trackEvent(eventName, eventData as Record<string, string>);
    }
  } catch (ex) {
    logger.error(ex);
  }
};

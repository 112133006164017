import { Button, Icon, NavigationHeader, ProductCard, Screen, SearchHeader, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import { ProductCarousel } from '@components/brsm';
import ItemizedCart from '@components/cart/ItemizedCart';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product, SubstitutionType } from '@fieldera-raleys/client-commercetools/schema';
import { ItemWidgetParams, PagedArray, ProductType } from '@fieldera-raleys/client-common';
import { useAnalytics } from '@hooks';
import { AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import brsmService from '@services/brsm/brsmService';
import { useAnalyticsStore, useAppConfigStore, useSearchStore } from '@store';
import { useCartStore } from '@store/cartStore';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, ListRenderItemInfo, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { getSubstitutionType } from '../../utils/orderHelpers';

type BackupScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.BackupScreen>;

interface BackupSearchState {
  isLoading: boolean;
  isRefreshing: boolean;
  searchText?: string;
  didYouMean?: string[];
  correctedQuery?: string;
  filterBadgeCount: number;
  productSearchResults: PagedArray<Product>;
}

const EmptyListComponent = ({ searchText, onPress }: { searchText?: string; onPress?: () => void }): JSX.Element => {
  return (
    <View style={styles.noItemFoundView}>
      <View style={[styles.emptyListContainer]} testID="noSearchResults">
        <View style={styles.emptyListIcon}>
          <Icon testID="searchMissingIcon" name={'search-missing-icon'} size={68} stroke="none" />
        </View>
        <Text testID="searchText" numberOfLines={2} style={[appStyles.bodySmallRegular, { width: '42%', lineHeight: lineHeight(25) }]}>
          {searchText && searchText !== '*' ? `No items found for "${searchText}"` : 'No items found'}
        </Text>
      </View>
      <View style={[styles.noItemFoundView, { marginTop: 10 }]}>
        <Text testID="emptyListComponentText" style={[appStyles.fontMobileListSmallCenterRegular, { paddingHorizontal: 35 }]}>
          Try checking for typos or search for something more general
        </Text>
      </View>
      <Button
        testID="wayToShop"
        title={'Ways to Shop'}
        type="secondary"
        onPress={onPress}
        textStyle={[appStyles.secondaryButtonSmallText]}
        buttonStyle={[styles.button]}
      />
    </View>
  );
};

const SearchResultList = ({ query, itemId }: { query: string; itemId: string }): JSX.Element => {
  const isFocused = useIsFocused();
  const { previousRoute } = useAnalyticsStore();
  const router = useRoute();
  const { getSelectedFilters, getSelectedFiltersCount, sortQuery, searchType, setAvailableFilters, addRecentlySearched } = useSearchStore();
  const [state, setState] = useState<BackupSearchState>({
    filterBadgeCount: 0,
    isRefreshing: false,
    isLoading: true,
    productSearchResults: {
      data: [],
      offset: 0,
      limit: appConstants.PAGE_SIZE,
      total: 0,
    },
  });

  const loadProducts = useCallback(
    async (offset: number, searchTxt: string) => {
      searchTxt && searchTxt.trim() && addRecentlySearched(searchTxt);
      const res = await brsmService.searchProduct(offset, appConstants.PAGE_SIZE, {
        ref_url: previousRoute?.name,
        pathname: router.name,
        searchQuery: searchTxt ?? '',
        selectedFilters: getSelectedFilters(),
        sortQuery: sortQuery,
        searchType: searchType,
      });

      var dataRows = offset === 0 ? [] : state.productSearchResults.data;

      if (res.docs.total > 0) {
        setAvailableFilters(res.facetFilters);
      }
      let resData = (res?.docs.data ?? []).filter((p) => (p.productType?.name ?? '') === ProductType.STANDARD);
      setState((prev) => ({
        ...prev,
        isRefreshing: false,
        isLoading: false,
        searchText: searchTxt,
        didYouMean: res?.didYouMean,
        correctedQuery: res?.autoCorrectQuery,
        filterBadgeCount: getSelectedFiltersCount(),
        productSearchResults: {
          ...prev.productSearchResults,
          total: res?.docs.total ?? 0,
          offset: res?.docs.offset ?? 0,
          data: [...dataRows, ...resData],
        },
      }));
    },
    [
      addRecentlySearched,
      getSelectedFilters,
      getSelectedFiltersCount,
      previousRoute?.name,
      router.name,
      setAvailableFilters,
      sortQuery,
      searchType,
      state.productSearchResults.data,
    ],
  );

  useEffect(() => {
    if (isFocused && query) {
      loadProducts(0, query);
    }
    return () => {
      setState({
        filterBadgeCount: 0,
        isRefreshing: false,
        isLoading: true,
        productSearchResults: {
          data: [],
          offset: 0,
          limit: appConstants.PAGE_SIZE,
          total: 0,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, isFocused]);

  const handleRefresh = async () => {
    if (state.searchText) {
      setState((prev) => ({ ...prev, isRefreshing: true }));
      loadProducts(0, state.searchText);
    }
  };

  const handleLoadMore = async () => {
    if (state.productSearchResults.total > state.productSearchResults.offset + appConstants.PAGE_SIZE && state.searchText) {
      loadProducts(state.productSearchResults.offset + appConstants.PAGE_SIZE, state.searchText);
    }
  };

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<Product>) => {
      return (
        <ProductCard
          product={item}
          actionType={'substitute'}
          itemId={itemId}
          productCategory={{ parentId: 'searchLanding', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
          index={index}
        />
      );
    },
    [itemId],
  );

  const renderItemSepatator = useCallback(() => <View style={styles.separator} />, []);
  const navigation = useNavigation();
  return state.isLoading ? (
    <ActivityIndicator size={'large'} />
  ) : (
    <>
      <FlatList
        testID="productsList"
        data={state.productSearchResults.data}
        keyExtractor={(_, idx) => idx.toString()}
        renderItem={renderItem}
        numColumns={2}
        ItemSeparatorComponent={renderItemSepatator}
        refreshing={false}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        onRefresh={handleRefresh}
        nestedScrollEnabled={true}
        ListEmptyComponent={
          <EmptyListComponent
            searchText={query}
            onPress={() => {
              navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Shop,
                params: { screen: ShopStackRoutes.Categories },
              });
            }}
          />
        }
      />
    </>
  );
};

const BackupScreen = ({ route }: BackupScreenProps): JSX.Element => {
  const { cart, setItemSubType } = useCartStore();
  const { t } = useTranslation('cart');
  const navigation = useNavigation();
  const { itemId } = route.params ?? {};
  const cartConfig = useAppConfigStore().getConfigGroup('Cart');
  const { previousRoute } = useAnalyticsStore();
  const setSubType = (subType: SubstitutionType) => {
    if (itemId) {
      setItemSubType(itemId, subType);
      setChoice({ best: subType === 'Best-Available' ? true : false, dont: subType === 'None' ? true : false });
    }
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  const [choice, setChoice] = useState({ best: false, dont: false });
  const [productKey, setProductKey] = useState<string | undefined | null>();
  useEffect(() => {
    if (itemId && cart) {
      setChoice({ best: false, dont: false });
      const li = cart.lineItems.find((i) => i.id === itemId);
      if (li) {
        const subType = getSubstitutionType(li);
        if (subType === 'Best-Available') {
          setChoice({ best: true, dont: false });
        } else if (subType === 'None') {
          setChoice({ best: false, dont: true });
        }
        setProductKey(li?.variant?.sku);
      }
    }
  }, [itemId, cart]);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const { trackSearchSubmitEvent } = useAnalytics();
  const { addRecentlySearched, selectFilter, resetFilters } = useSearchStore();
  const [showSearchQuery, setShowSearchQuery] = useState<string>('');

  const onSearchSubmit = async (searchQuery: string, searchCategory?: string) => {
    setShowSearchQuery(searchQuery);
    onSearchSubmitCb(searchQuery, searchCategory);
  };

  const onSearchSubmitCb = useCallback(
    async (searchQuery: string, searchCategory?: string) => {
      resetFilters();
      searchCategory && selectFilter(searchCategory);
      searchQuery && searchQuery.trim() && addRecentlySearched(searchQuery);
      trackSearchSubmitEvent({ q: searchQuery });
    },
    [addRecentlySearched, resetFilters, selectFilter, trackSearchSubmitEvent],
  );

  return (
    <Screen>
      {searchOpen ? (
        <SearchHeader
          placeHolder={t('subSearchEligible')}
          showRecentOnly={true}
          showBackButton={true}
          onClose={() => setSearchOpen(false)}
          onSearchSubmit={onSearchSubmit}
          tabBarHeight={0}
          showCloseButton={true}
        />
      ) : (
        <NavigationHeader
          titleStyle={{ top: 5 }}
          title={t('backupTitle')}
          next={<Icon name="search-icon" style={appStyles.icon} stroke={colors.red} />}
          onNext={() => setSearchOpen(true)}
        />
      )}
      <View style={styles.mainContainer}>
        {searchOpen && showSearchQuery ? (
          <SearchResultList query={showSearchQuery} itemId={itemId ?? ''} />
        ) : (
          <View style={[styles.flex1, styles.flexrow]}>
            <ScrollView style={[styles.scrollView]}>
              <CartInfoBanner />
              <View style={[utilityStyles.my0]}>
                <ItemizedCart readOnly={true} itemId={itemId} />
              </View>
              <View style={[utilityStyles.my4]}>
                <Button
                  testID="setSubTypeButton"
                  type="secondary"
                  buttonStyle={[choice.best ? styles.selectedBackupButton : styles.selectBackupButton, { width: '50%' }]}
                  textStyle={[choice.best ? styles.selectedText : styles.selectText]}
                  title={t('subBestAvilableBtn')}
                  onPress={() => setSubType('Best-Available')}
                  lDecore={
                    choice.best ? (
                      <Icon size={23} name="circle-check" style={styles.substituteBtnIcon} stroke={colors.green} strokeSecondary={colors.white} />
                    ) : null
                  }
                />
                <Button
                  testID="setDontSubTypeButton"
                  type="secondary"
                  buttonStyle={[choice.dont ? styles.selectedBackupButton : styles.selectBackupButton, { width: '50%' }]}
                  textStyle={[choice.dont ? styles.selectedText : styles.selectText]}
                  title={t('subDontSubstituteBtn')}
                  onPress={() => setSubType('None')}
                  lDecore={
                    choice.dont ? (
                      <Icon size={23} name="circle-check" style={styles.substituteBtnIcon} stroke={colors.green} strokeSecondary={colors.white} />
                    ) : null
                  }
                />
              </View>
              <View style={[utilityStyles.my0]}>
                {cartConfig && productKey && (
                  <View style={{ alignSelf: 'center' }}>
                    <ProductCarousel
                      title={t('subSuggestedTitle')}
                      contentContainerStyle={[styles.contentContainer, styles.cream]}
                      addCartButtonStyle={styles.cartAddQty}
                      backgroundStyle={styles.cream}
                      widgetType={'SimilarProducts'}
                      widgetId={cartConfig.SimilarProducts}
                      widgetParams={{ item_ids: productKey, context_id: productKey, url: route.name, ref_url: previousRoute?.name } as ItemWidgetParams}
                      actionType="substitute"
                      itemId={itemId}
                    />
                  </View>
                )}
              </View>
            </ScrollView>
          </View>
        )}
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    flexDirection: 'column',
    width: screenWidth,
    height: screenHeight,
  },
  marginTop20: {
    marginTop: 20,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
  },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flexrow: { flexDirection: 'row' },
  flexcolumn: { flexDirection: 'column' },
  scrollView: {},
  textAlignCenter: { textAlign: 'center' },
  cancelText: {
    height: 30,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(23),
    color: colors.red,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectBackupButton: {
    width: '100%',
    height: scale(35),
    color: colors.primary,
    borderRadius: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedBackupButton: {
    width: '100%',
    height: scale(35),
    backgroundColor: colors.green,
    borderRadius: 28,
    borderColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectText: {
    height: scale(30),
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: Platform.select({ ios: lineHeight(23), android: lineHeight(26) }),
    color: colors.red,
    textAlign: 'center',
  },
  selectedText: {
    height: scale(30),
    lineHeight: Platform.select({ ios: lineHeight(23), android: lineHeight(26) }),
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.white,
    textAlign: 'center',
  },
  cream: {
    backgroundColor: colors.cream,
  },
  cartAddQty: {
    backgroundColor: colors.darkCream,
  },
  separator: {
    backgroundColor: colors.darkCream,
    height: 1.5,
    width: screenWidth * 0.92,
    transform: [{ translateX: (screenWidth * 0.08) / 2 }],
  },
  button: {
    minWidth: '50%',
    width: 130,
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  noItemFoundView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 45,
  },
  emptyListIcon: {
    width: 100,
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 18,
    paddingLeft: 22,
  },
  substituteBtnIcon: {
    marginTop: -2,
    height: scale(32),
    justifyContent: 'center',
    transform: [{ translateX: -5 }],
  },
});

export default BackupScreen;

const log = (...args: any[]) => {
  if (__DEV__) {
    console.log(...args);
  }
};

const debug = (...args: any[]) => {
  if (__DEV__) {
    console.debug(...args);
  }
};

const warn = (...args: any[]) => {
  if (__DEV__) {
    console.warn(...args);
  }
};

const error = (ex: any) => {
  if (__DEV__) {
    console.error(JSON.stringify(ex));
  }
};

export default { log, debug, warn, error };

import { KeyboardAvoidingScreen } from '@components';
import ErrorFallbackComponent from '@components/ErrorFallbackComponent';
import Icon from '@components/Icon';
import Text from '@components/Text';
import AddressForm from '@components/forms/AddressForm';
import colors from '@config/colors';
import useShopStore from '@store/shopStore';
import { utilityStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

interface AddDeliveryAddressScreenProps {
  toggleTombstone: () => void;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

export type CorrectedAddress = {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
};

const AddDeliveryAddressScreen: FC<AddDeliveryAddressScreenProps> = ({ setCurrentPage }) => {
  const { editAddressInfo } = useShopStore();

  return (
    <View style={styles.container}>
      <KeyboardAvoidingScreen keyboardVerticalOffset={100}>
        <Text style={styles.deliveryText} testID={`${!editAddressInfo?.id ? 'Add' : 'Edit'} Address`}>
          {!editAddressInfo?.id ? 'Add' : 'Edit'} Address
        </Text>
        <TouchableOpacity style={styles.backArrow} onPress={() => setCurrentPage('Delivery')} testID="arrowLeftButton">
          <Icon name={'arrow-left'} testID="arrowLeft" />
        </TouchableOpacity>
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <AddressForm
            mode={'shipping'}
            addressId={editAddressInfo?.id}
            onAfterSubmit={() => {
              setCurrentPage('Delivery');
            }}
          />
        </ErrorBoundary>
      </KeyboardAvoidingScreen>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: '90%',
    alignSelf: 'center',
    ...utilityStyles.pb3,
  },
  backArrow: {
    position: 'absolute',
    left: 0,
    top: Platform.OS === 'web' ? -20 : -45,
    paddingLeft: 20,
    paddingTop: 9,
    width: '20%',
    height: 50,
  },
  deliveryText: {
    fontFamily: 'Aleo-Bold',
    fontSize: 25,
    lineHeight: 30,
    color: colors.text,
    textAlign: 'center',
    width: '62%',
    position: 'absolute',
    top: Platform.OS === 'web' ? -20 : -35,
    zIndex: 0,
    alignSelf: 'center',
  },
});

export default AddDeliveryAddressScreen;

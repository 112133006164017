import Icon, { IconName } from '@components/Icon';
import LinkButton from '@components/LinkButton';
import RadioButton from '@components/RadioButton';
import Text from '@components/Text';
import colors from '@config/colors';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import appStyles from '@styles/appStyles';
import utilityStyles from '@styles/utilityStyles';
import { isCardExpired } from '@utils/helpers';
import dayjs from 'dayjs';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

type WalletItemProps = {
  item: PaymentProfile;
  readOnly?: boolean;
  index?: number;
  onPress?: (item: PaymentProfile) => void;
  onEdit?: (item: PaymentProfile) => void;
  onRemove?: (item: PaymentProfile) => void;
  onSetDefault?: (item: PaymentProfile) => void;
};

const WalletItem = ({ item, readOnly = false, onPress, onEdit, onRemove, onSetDefault, index }: WalletItemProps): JSX.Element => {
  const getFormattedCardNumber = () => {
    let iconName = item.CardType?.toLowerCase();
    let ccName = item.CardType;
    let ccNumber = item.CardNumber && item.CardNumber.substring(item.CardNumber.lastIndexOf('-') + 1);
    if (item.CardType?.toLowerCase() === 'american express') {
      iconName = 'amex';
      ccName = 'Amex';
      ccNumber = item.CardNumber && item.CardNumber.substring(item.CardNumber.lastIndexOf('-') + 2);
    }

    return (
      <View key={'cckey_' + item.CustomerPaymentMethodId} style={[styles.ccWrapper]}>
        <View style={[styles.ccNumberWrapper]} testID="cardNumberView">
          <Icon name={(iconName as IconName) ?? 'amex'} style={utilityStyles.mr4} size={18} stroke={'none'} fill={'#000000'} testID="" />
          <Text style={[styles.body]} testID="cardNameText">
            {`${ccName?.replace(/[A-Z]/g, (letter) => ` ${letter}`)} ...${ccNumber}`}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID={`walletItems${index}`}
        key={item.CustomerPaymentMethodId}
        style={[styles.walletItem, item.IsDefault ? styles.walletItemDefault : {}]}
        onPress={() => {
          onPress && onPress(item);
        }}>
        <View style={styles.walletCardNameView}>
          <View style={styles.nameCardFlex}>
            <Text style={appStyles.body} testID="cardNumber">
              {item.CardType !== 'payAtPickup' ? getFormattedCardNumber() : item.CardNumber}
            </Text>
            <Text style={[appStyles.bodyMediumLight, utilityStyles.mt2]} testID="expirationDate">
              {item.CardType !== 'payAtPickup' ? `Exp. ${dayjs(item.CardExpirationDate).format('MM/YYYY')}` : item.CardExpirationDate}
              {isCardExpired(item) && (
                <Text style={[styles.fontMobileBodySmallLeftRed]} testID="cardExpiredText">
                  {' '}
                  EXPIRED
                </Text>
              )}
            </Text>
          </View>
          {readOnly ? null : (
            <View style={styles.radioBtnFlex} testID="radioBtn">
              <RadioButton
                text={item.IsDefault ? 'Default ' : ''}
                textStyle={[appStyles.fontMobileBodyXSBoldRed, utilityStyles.m1]}
                value={item.IsDefault}
                id={item.CustomerPaymentMethodId}
                toggle={() => {
                  !isCardExpired(item) && onSetDefault && onSetDefault(item);
                }}
                disabled={isCardExpired(item)}
                key={item.CustomerPaymentMethodId}
                strokeColor={colors.secondary}
                fillColor={colors.white}
                secondaryStrokeColor={colors.secondary}
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
      {readOnly ? (
        isCardExpired(item) && (
          <View style={styles.optionWrapper}>
            <LinkButton style={[utilityStyles.ml3]} onPress={() => onEdit && onEdit(item)} testID="edit">
              <Text style={[appStyles.smallLink]}>Edit</Text>
            </LinkButton>
          </View>
        )
      ) : (
        <View style={styles.optionWrapper}>
          {!item.IsDefault && (
            <LinkButton style={[utilityStyles.ml3]} onPress={() => onRemove && onRemove(item)} testID="remove">
              <Text style={[appStyles.smallLink]}>Remove</Text>
            </LinkButton>
          )}
          <LinkButton style={[utilityStyles.ml3]} onPress={() => onEdit && onEdit(item)} testID="edit">
            <Text style={[appStyles.smallLink]}>Edit</Text>
          </LinkButton>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 100,
    marginBottom: 25,
    paddingHorizontal: 10,
  },
  walletItem: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'white',
    width: '100%',
    alignItems: 'center',
    borderColor: colors.sectionBorder,
    borderWidth: 1,
    borderRadius: 5,
    ...utilityStyles.pl4,
    ...utilityStyles.pt3,
    ...utilityStyles.pb3,
    ...utilityStyles.pr3,
  },
  walletItemDefault: {
    borderColor: colors.red,
    borderWidth: 2,
  },
  walletCardNameView: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  nameCardFlex: { flex: 0.77 },
  radioBtnFlex: {
    flex: 0.25,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  optionWrapper: {
    ...utilityStyles.pt1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  fontMobileBodySmallLeftRed: {
    ...appStyles.fontMobileBodySmallLeft,
    color: colors.saleRed,
  },
  ccWrapper: {},
  ccNumberWrapper: {
    flex: 1,
    flexDirection: 'row',
  },
  body: {
    ...appStyles.body,
    marginTop: 3,
  },
});

export default WalletItem;

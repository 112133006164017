import { LiveAgentService } from '@fieldera-raleys/client-common';
import Config from 'react-native-config';

const liveAgentService = new LiveAgentService({
  salesforceChatApiUrl: Config.SALESFORCE_CHAT_API_URL ?? '',
  availabilityId: Config.SALESFORCE_CHAT_AVAILABLITY_ID ?? '',
  chatDeploymentId: Config.SALESFORCE_CHAT_DEPLOYMENT_ID ?? '',
  chatOrgId: Config.SALESFORCE_CHAT_ORG_ID ?? '',
  apiVersion: Number(Config.SALESFORCE_API_VERSION ?? 0),
});

export default liveAgentService;

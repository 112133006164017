import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { defaultMoney } from '@fieldera-raleys/client-commercetools';
import { AddLineItem, Money, Product, ShoppingList, ShoppingListLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { ProductType } from '@fieldera-raleys/client-common';
import { useAnalytics } from '@hooks';
import { AppStackRoutes, CustomizeStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { shoppingListService } from '@services/commerceTools/me';
import { useCartStore, useListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Animated, GestureResponderEvent, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { Swipeable } from 'react-native-gesture-handler';
import { useCustomModalContext } from '../../contexts';
import { getAttributeValue } from '../../utils/categoryHelper';
import logger from '../../utils/logger';
import { getItemAttributeValue, getProductQuantityIndex, getProductQuantityValue, getRegularItemPrice, moneyValue } from '../../utils/orderHelpers';
import {
  getDisplayPriceBySellType,
  getProductAttributeValue,
  getProductAvailablity,
  getProductsfromCommerceTools,
  getRootCategory,
  getfulfillmentLeadTimeMinutes,
} from '../../utils/productHelper';
import DropShadow from '../DropShadow';
import Icon from '../Icon';
import LinkButton from '../LinkButton';
import QuantityBox from '../QuantityBox';
import ProductImage from './ProductImage';

const layout = { width: 106, imageWidth: 72, imageHeight: 72 };

type ProdDataType = {
  [key: string]: Product;
};

const SavedForLater = () => {
  const { lists, removeItemFromList, setLineItemQuantity, addItemToList, addItemsToShoppingList, removeItemsFromShoppingList, clearList } = useListsStore();
  const { addStandardLineItems, getMaxProductQuantity, addCustomizableLineItem, removeLineItem, emptyCart } = useCartStore();
  const [loading, setLoading] = useState(false);
  const { showAlertPopup } = useCustomModalContext();
  const { trackAddToCartEvent } = useAnalytics();
  var savedForLaterList = lists?.find((l) => l.name === 'Saved For Later');
  var readOnly = false;
  const navigation = useNavigation();
  const { t } = useTranslation('cart');
  const [savedForLaterListData, setSavedForLaterListData] = useState<ShoppingList>();
  const [productData, setProductData] = useState<ProdDataType | undefined>();
  const [itemState, setItemState] = useState<{
    [key: string]: {
      openNote?: boolean;
      remove?: boolean;
      save?: boolean;
      customizations?: boolean;
    };
  }>({});
  const itemStateRef = useRef(itemState);
  const UNIT_SPACER = null;
  const customizationsPressed = useCallback(
    (e: GestureResponderEvent, itemId: string) => {
      setItemState({ ...itemState, [itemId]: { ...(itemState[itemId] ?? {}), customizations: !(itemState[itemId]?.customizations ?? false) } });
    },
    [itemState],
  );

  const handleNavigation = useCallback(
    (item: ShoppingListLineItem): void => {
      if (!readOnly) {
        item.variant?.sku && navigation.navigate(AppStackRoutes.ProductDetails, { productKey: item.variant.sku });
      }
    },
    [navigation, readOnly],
  );

  useEffect(() => {
    itemStateRef.current = itemState;
  }, [itemState]);

  const getQuantityIndex = useCallback(
    (sku: string, quantity: number, estimatedTotalWeight?: number) => {
      if (productData) {
        if (productData[sku]?.masterData?.current) {
          return getProductQuantityIndex(productData[sku].masterData.current!, quantity, estimatedTotalWeight);
        }
      }
      return 0;
    },
    [productData],
  );

  const formatQuantityFromIndex = useCallback(
    (sku: string, index: number) => {
      if (productData) {
        let sellType: { key: string; label: string } = productData[sku]?.masterData.current?.masterVariant.attributesRaw.find((f) => f.name === 'unitSellType')
          ?.value ?? {
          key: 'byEach',
          label: 'By Each',
        };
        if (productData[sku]?.masterData?.current) {
          const val = getProductQuantityValue(productData[sku].masterData.current!, index);
          if (sellType.key === 'byWeight') {
            const unitOfMeasure = getProductAttributeValue('unitOfMeasure', productData[sku]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
            return `${val} ${unitOfMeasure}`;
          } else {
            return val;
          }
        }
      }
    },
    [productData],
  );

  const undoRemove = useCallback(
    async (item: ShoppingListLineItem, cartItemId: string | undefined) => {
      await removeLineItem(cartItemId ?? '');
      await addItemToList(item.variant?.sku ?? '', 'Saved For Later');
      setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: true } });
      showAlertPopup({
        message: `Item Moved to "${savedForLaterList?.name}"`,
        containerStyle: styles.alertBox,
        linkText: 'Edit',
        linkAction: () => logger.log('scroll to sfl'),
      });
    },
    [addItemToList, itemState, removeLineItem, savedForLaterList?.name, showAlertPopup],
  );

  const _removeLineItem = useCallback(
    (item: ShoppingListLineItem, cartItemId: string | undefined, toCart?: boolean | undefined) => {
      if (item.variant?.sku) {
        removeItemFromList(item.id ?? '', 'Saved For Later')
          .then(() => {
            setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: false } });
          })
          .finally(() => {
            showAlertPopup({
              message: toCart ? 'Item Moved to Cart' : `Item Removed from "${savedForLaterList?.name}"`,
              containerStyle: styles.alertBox,
              linkText: 'Undo',
              linkAction: () => undoRemove(item, cartItemId),
            });
          });
        setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: true } });
      }
      return false;
    },
    [itemState, removeItemFromList, savedForLaterList?.name, showAlertPopup, undoRemove],
  );

  // const handleQuantityChange = useCallback(
  //   (item: ShoppingListLineItem, q: number) => {
  //     if (q === 0) {
  //       showAlertPopup({
  //         message: `Item Removed from "${savedForLaterList?.name}"`,
  //         containerStyle: styles.alertBox,
  //         linkText: 'Undo',
  //         linkAction: () => undoRemove(item, undefined),
  //       });
  //       removeItemFromList(item.id ?? '', 'Saved For Later').then(() => {
  //         setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: false } });
  //       });
  //       setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: false } });
  //     }
  //     setLineItemQuantity(savedForLaterList?.id ?? '', item.id ?? '', q);
  //   },
  //   [setLineItemQuantity, savedForLaterList?.id, savedForLaterList?.name, showAlertPopup, removeItemFromList, itemState, undoRemove],
  // );

  const handleQuantityChange = useCallback(
    (item: ShoppingListLineItem, q: number) => {
      if (q === 0) {
        // const nameStr = item.name ?? item.productSlug ?? item.productId;

        showAlertPopup({
          message: `Item removed from "${savedForLaterList?.name}"`,
          containerStyle: styles.alertBox,
        });
      }
      setLineItemQuantity(savedForLaterList?.id ?? '', item.id ?? '', q);
    },
    [savedForLaterList?.id, savedForLaterList?.name, setLineItemQuantity, showAlertPopup],
  );

  const editCustomizedItem = useCallback(
    async (customizedItem: ShoppingListLineItem) => {
      navigation.dispatch(
        StackActions.push(AppStackRoutes.Customize, {
          screen: CustomizeStackRoutes.Initial,
          params: {
            productKey: customizedItem.variant?.sku ?? '',
            lineItemId: customizedItem.id,
            prevPageData: { parentId: 'cart' },
            editCustomize: false,
            editSavedForLater: true,
          },
        }),
      );
      // _removeLineItem(customizedItem, customizedItem.id);
    },
    [navigation],
  );

  const undoCustomRemoved = useCallback(
    async (addItems: AddLineItem[], customItemId: string) => {
      await removeLineItem(customItemId);
      await addItemsToShoppingList(savedForLaterListData?.id ?? '', savedForLaterListData?.version, addItems, []);
      // await removeLineItem(cart);
    },
    [addItemsToShoppingList, removeLineItem, savedForLaterListData?.id, savedForLaterListData?.version],
  );

  const moveItemToCart = useCallback(
    async (item: ShoppingListLineItem) => {
      const savedItem = savedForLaterListData?.lineItems?.find((li) => li.variant?.sku === item.variant?.sku);
      if (savedItem && 'productType' in savedItem) {
        if (savedItem.productType.name === 'configurable') {
          let addItems: AddLineItem[] = [];
          let productKeys: AddLineItem[] = [];
          let removeIds = [item.id];
          addItems.push({
            sku: item?.variant?.sku ?? '',
            quantity: item?.quantity ?? 1,
          });
          let selections = savedForLaterListData?.lineItems.filter(
            (sfli) => sfli.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId')?.value === item.id,
          );
          if (selections?.length) {
            selections?.forEach((ch) => {
              addItems.push({
                sku: ch?.variant?.sku ?? '',
                quantity: ch?.quantity ?? 1,
                parentLineItemId: item?.variant?.sku ?? '',
                customStepSort: String(ch.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value) ?? 0,
              });
            });
          }
          productKeys.push({
            sku: item?.variant?.sku ?? '',
            quantity: item.quantity ?? 1,
          });
          selections?.forEach((sel) => {
            productKeys.push({
              sku: sel?.variant?.sku ?? '',
              quantity: sel.quantity ?? 1,
              parentLineItemId: item.variant?.sku ?? '',
              itemNote: sel?.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value,
              customStepSort: sel?.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value,
            });
          });
          const customItemId = await addCustomizableLineItem(productKeys);

          removeItemsFromShoppingList(savedForLaterList?.id ?? '', savedForLaterList?.version, removeIds, []).then(() => {
            showAlertPopup({
              message: 'Item Moved to Cart"',
              // message: `Item Removed from "${savedForLaterList?.name}"`,
              containerStyle: styles.alertBox,
              linkText: 'Undo',
              linkAction: () => undoCustomRemoved(addItems, customItemId),
            });
          });
        } else {
          // logger.log('move to cart standard');
          const cartItemId = await addStandardLineItems([
            {
              sku: item.variant?.sku ?? '',
              quantity: item.quantity ?? 1,
              ...(productData?.[item.variant?.sku ?? ''] ? { product: productData[item.variant!.sku!] } : {}),
            },
          ]);
          _removeLineItem(item, cartItemId ?? '', true);
          trackAddToCartEvent({ sku: item.variant?.sku ?? '', prod_name: item.name ?? '', prod_id: item.variant?.sku ?? '' });
        }
      }
    },
    [
      savedForLaterListData?.lineItems,
      addCustomizableLineItem,
      removeItemsFromShoppingList,
      savedForLaterList?.id,
      savedForLaterList?.version,
      showAlertPopup,
      undoCustomRemoved,
      addStandardLineItems,
      productData,
      _removeLineItem,
      trackAddToCartEvent,
    ],
  );

  useEffect(() => {
    // logger.log('Saved For Later:', JSON.stringify(savedForLaterList, null, 2));
    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      const listData = await shoppingListService.getShoppingList({ listName: 'Saved For Later' });
      setSavedForLaterListData(listData);
      return await getProductsfromCommerceTools(skus);
    };
    if (savedForLaterList) {
      const skus = savedForLaterList?.lineItems?.map((li) => li.variant?.sku)?.filter((s) => s !== undefined) ?? [];
      getProdSet(skus as string[]).then((pd) => {
        const pData: ProdDataType = pd.reduce((acc, p: Product) => {
          acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
          p.masterData?.current?.variants.forEach((vi) => {
            acc[vi?.sku ?? ''] = p;
          });

          return acc;
        }, {} as ProdDataType);
        // logger.log('pData', JSON.stringify(pData, null, 2));
        setProductData(pData);
      });
    }
  }, [savedForLaterList]);

  const [removeOnSwipe, setRemoveOnSwipe] = useState<string>('');
  const renderRightActions = useCallback(
    (progress: Animated.AnimatedInterpolation, dragAnimatedValue: Animated.AnimatedInterpolation, item: ShoppingListLineItem) => {
      const opacity = dragAnimatedValue.interpolate({
        inputRange: [-40, 0],
        outputRange: [1, 0],
        extrapolate: 'clamp',
      });

      if (Number(JSON.stringify(progress)) > 3.5) {
        setRemoveOnSwipe(item.id);
      }

      return (
        <TouchableOpacity onPress={() => _removeLineItem(item, undefined)} style={styles.swipedRow}>
          <Animated.View style={[styles.deleteButton, { opacity }]}>
            <Icon testID="closeIcon" stroke={colors.white} name={'x-close'} size={25} />
          </Animated.View>
        </TouchableOpacity>
      );
    },
    [_removeLineItem],
  );

  const ActionLinksBottom = useCallback(
    ({ item }: { item: ShoppingListLineItem }): JSX.Element => {
      return (
        <View key={`actionLinksBottom_${item.id}`} style={[styles.actionLinksBottomContainer, utilityStyles.mt2]}>
          <LinkButton onPress={() => _removeLineItem(item, undefined)} style={[appStyles.fontMobileTinyLight, styles.actionLinksBottom, { flex: 0.5 }]}>
            {itemState[item.id]?.remove ? <ActivityIndicator style={{}} /> : t('removeItem')}
          </LinkButton>
          <LinkButton onPress={() => moveItemToCart(item)} style={[appStyles.fontMobileTinyLight, styles.actionLinksBottom, { marginLeft: '5%' }]}>
            Move to Cart
          </LinkButton>
        </View>
      );
    },
    [_removeLineItem, itemState, t, moveItemToCart],
  );

  const ProductInfo = useCallback(
    ({ lineItem }: { lineItem: ShoppingListLineItem }): JSX.Element[] => {
      // logger.log(JSON.stringify(savedForLaterList, null, 2));
      // must copy anything that will be modified for validation to not affect local state
      let item = { ...lineItem, price: { ...lineItem?.variant?.price, value: { ...(lineItem.variant?.price?.value ?? defaultMoney) } } };
      const descr: string[] = [];

      let sellType: { key: string; label: string } = { key: 'byEach', label: 'By Each' };
      sellType = getProductAttributeValue('unitSellType', productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? []) ?? {
        key: 'byEach',
        label: 'By Each',
      };

      const averageWeight =
        getProductAttributeValue('unitAverageBuyWeight', productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? []) ?? 1;

      let qty = 1;

      if (productData && productData[item.variant?.sku ?? '']?.masterData?.current) {
        qty = getProductQuantityValue(productData[item.variant?.sku ?? ''].masterData.current!, item.quantity);
      }
      const displayPrice = getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, averageWeight, qty);

      const regularPrice: Money | null = getRegularItemPrice(item);
      let totalPrice: Money = { ...(item.variant?.price?.value ?? defaultMoney) } ?? defaultMoney;
      const attrs: { [key: string]: string } = { name: item.name ?? item.productSlug ?? item.productId };
      (item.variant?.attributesRaw ?? []).forEach((field) => {
        attrs[field.name] = field.value;
      });

      let includeDetails: boolean = true;
      let showOldPrice: { price: string; subTotal: string } | null = null;
      const productHeaderInfo: TextStyle[] = [];
      const productDetails: JSX.Element[] = [];

      const regularPriceRow = [];

      const unitOfMeasure = getProductAttributeValue(
        'unitOfMeasure',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const prData = productData?.[item.variant?.sku ?? '']?.masterData.current;
      let regPrice = regularPrice ? `${moneyValue(regularPrice)} ea` : '-';

      const packageCount = getProductAttributeValue(
        'packageCount',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const unitsPerPackage = getProductAttributeValue(
        'unitsPerPackage',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      let unitTotalCount = item.quantity;
      let totalPriceValue = moneyValue(totalPrice as Money, item.quantity);
      if (sellType.key === 'weightByEach') {
        const val = (averageWeight ?? 1) * item.quantity;
        totalPriceValue = moneyValue(totalPrice as Money, val);
        totPrice = `${moneyValue(totalPrice)} / ${unitOfMeasure}`;
        regPrice = regularPrice ? `${moneyValue(regularPrice)} / ${unitOfMeasure}` : '-';
      } else if (sellType.key === 'byWeight') {
        totalPriceValue = moneyValue(totalPrice as Money, 1);
        totPrice = `${moneyValue(totalPrice)} / ${unitOfMeasure}`;
        regPrice = regularPrice ? `${moneyValue(regularPrice)} / ${unitOfMeasure}` : '-';
      } else {
        unitTotalCount = ((unitsPerPackage ?? 1) * (packageCount ?? 1)).toFixed(1);
      }

      if (includeDetails) {
        // additional ingreditents
        const customField = item.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
        if (customField) {
          const childLineItemIds = customField ? String(customField.value).split(',') : [];
          childLineItemIds.forEach((lineItemId, ctr) => {
            const childListLineItem = savedForLaterListData?.lineItems?.find((x: ShoppingListLineItem) => x.id === lineItemId);
            const childLineItem = productData?.[childListLineItem?.variant?.sku ?? '']?.masterData?.current?.masterVariant;
            if (childLineItem) {
              if (childLineItem?.price?.value.centAmount) {
                // this assumes all child line items are by each
                totalPrice.centAmount += childLineItem?.price.value.centAmount * (childListLineItem?.quantity ?? 0);
                productDetails.push(
                  <View key={`ingrKey_${item.id}_${ctr}`} style={[styles.flexrow]}>
                    <Text testID="nameText" numberOfLines={2} ellipsizeMode={'middle'} style={[appStyles.fontMobileTinyLight, styles.unit]}>
                      {childListLineItem?.name}&nbsp;
                      {(childListLineItem?.quantity ?? 0) > 1 ? [' (', childListLineItem?.quantity ?? 0, ')'].join('') : null}
                    </Text>
                    <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
                    <Text testID="priceValue" numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.price]}>
                      {moneyValue(childLineItem?.price.value, 1)} ea
                    </Text>
                    <Text style={[styles.unitSeparator]} />
                    <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
                    <Text testID="quantityValue" numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmall, styles.subtotal]}>
                      {moneyValue(childLineItem?.price.value, (childListLineItem?.quantity ?? 0) * item.quantity, '')}
                    </Text>
                  </View>,
                );
              } else {
                descr.push(
                  [childListLineItem?.name, (childListLineItem?.quantity ?? 0) > 1 ? [' (', childListLineItem?.quantity ?? 0, ')'].join('') : null].join(''),
                );
              }
            }
          });
        }
      }

      let totPrice = `${moneyValue(totalPrice)} ea`;

      let custLink = null;
      if (descr.length) {
        // if there is data for collapsible show it
        custLink = (
          <View key={`itemCustLink_${item.id}`}>
            <LinkButton key={'linkKey'} onPress={(e) => customizationsPressed(e, item.id)} style={[{ flexDirection: 'row' }]}>
              <View style={[styles.customizationsContainer]}>
                <View style={[styles.priceContainer]}>
                  <Text
                    testID={`actionCustomizations_${item.id}`}
                    style={[appStyles.fontMobileBodySmallBald, { marginRight: 8 }]}
                    key={`actionCustomizations_${item.id}`}>
                    Customizations&nbsp;
                  </Text>
                  <Icon
                    testID={`customization_${item.id}`}
                    key={`customization_${item.id}`}
                    fill={colors.black}
                    stroke={'none'}
                    strokeSecondary={colors.black}
                    size={15}
                    style={[appStyles.smallIcon, { alignSelf: 'center' }]}
                    name={itemState?.[item.id]?.customizations ?? false ? 'triangle-up' : 'triangle-down'}
                  />
                </View>
              </View>
            </LinkButton>
            {(itemState?.[item.id]?.customizations ?? false) && [
              <View key={'custViewKey'} style={[styles.priceContainer, { flexDirection: 'column', margin: 0 }]}>
                {descr.map((tt: string, ctr) => (
                  <Text
                    testID={`itemDescription_${ctr}`}
                    key={`itemDescription_${ctr}`}
                    numberOfLines={2}
                    ellipsizeMode={'tail'}
                    style={[appStyles.fontMobileTinyLight, { lineHeight: lineHeight(14) }]}>
                    {tt}
                  </Text>
                ))}
              </View>,
            ]}
          </View>
        );
      }

      // add separator at the top
      productDetails.unshift(<Text key={`topSeparator_${item.id}`} style={[styles.subseparator]} />);
      // product Price on top of that
      if (showOldPrice) {
        // ??? not used here?
        productDetails.unshift(
          <View key={`productPriceDetails_${item.id}`} style={utilityStyles.my3}>
            <View style={[styles.priceContainer]}>
              <Text testID="quantityEach" numberOfLines={1} style={[appStyles.fontMobileTinyLight, styles.unit]}>
                {t('quantityEach')}
              </Text>
              <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
              <Text
                testID="itemValue"
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.fontMobileTinyLight, styles.price, { color: colors.red }]}>
                {moneyValue(item.variant?.price?.value, 1)}
              </Text>
              <Text style={[styles.unitSeparator]} />
              <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
              <Text
                testID="itemQuantity"
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.bodySmallBold, styles.subtotal, { color: colors.red }]}>
                {moneyValue(item.variant?.price?.value, item.quantity)}
              </Text>
            </View>
            <View style={[styles.priceContainer]}>
              <Text numberOfLines={1} style={[appStyles.fontMobileTinyLight, styles.unit]} />
              <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
              <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.price]}>
                {'Was'}
              </Text>
              <Text style={[styles.unitSeparator]} />
              <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
              <Text
                testID="strikeThroughPrice "
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.bodySmallBold, styles.subtotal, { textDecorationLine: 'line-through' }]}>
                $99999
              </Text>
            </View>
          </View>,
        );
      }

      const leadTime = getfulfillmentLeadTimeMinutes(productData?.[item.variant?.sku ?? ''] ?? undefined);
      let leadMessage;
      if (leadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES) {
        leadMessage =
          // eslint-disable-next-line no-bitwise
          leadTime > +Config.LEAD_TIME_MAX_HOURS_TOSHOW * 60 ? `${(leadTime / 60 / 24 + 1) | 0} Day Lead Time` : `${(leadTime / 60) | 0}-Hour Lead Time`;
      }
      const availabilityData = getProductAvailablity(productData?.[item.variant?.sku ?? '']?.masterData);
      if (availabilityData.availability !== 'Available' && availabilityData.availability !== 'LowStock') {
        leadMessage = availabilityData.availabilityDate ? ['Available ', availabilityData.availabilityDate].join('') : availabilityData.availability.toString();
      }

      const leadTimeLabel = leadMessage ? (
        <View key={`lead_${item.id}`} style={[styles.leadWarningContainer]}>
          <Icon testID="flagRedIcon" name="flag-red-icon" stroke={'none'} style={[appStyles.smallIcon]} size={12} />
          <Text testID="leadMessage" style={[styles.leadWarningContainerText, utilityStyles.mx1]}>
            {leadMessage}
          </Text>
        </View>
      ) : null;
      // Product name and description on top

      if (regularPrice) {
        regularPriceRow.push(
          <Text
            testID="preg1"
            key={'preg1'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.subprice, { color: colors.red }]}>
            {totPrice}
          </Text>,
          <Text
            testID="preg2"
            key={'preg2'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.subRegprice, { textDecorationLine: 'line-through', textDecorationColor: colors.black }]}>
            {regPrice}
          </Text>,
        );
      } else {
        regularPriceRow.push(
          <Text testID="totalPrice" key={'totalPrice'} numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.subprice]}>
            {totPrice}
          </Text>,
        );
      }
      let approxLine = null;
      if ((sellType && (sellType.key ?? 'byEach')) === 'weightByEach') {
        approxLine = (
          <Text
            testID="approxTotalPrice"
            key={'approxTotalPrice'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.approxSubtotal]}>
            approx
          </Text>
        );
      }
      productDetails.unshift(
        <View key={`title_${item.id}`} style={{ flexDirection: 'row' }}>
          <View style={{ width: '78%' }}>
            <TouchableOpacity key={'infohead'} onPress={() => handleNavigation(item)} style={{}}>
              <Text numberOfLines={2} ellipsizeMode={'middle'} style={[appStyles.bodySmallBold, { lineHeight: lineHeight(16) }, ...productHeaderInfo]}>
                {attrs.name ?? ''}&nbsp;&nbsp;&nbsp;
                {!readOnly && item.productType?.name === ProductType.CONFIGURABLE ? (
                  <LinkButton style={[appStyles.fontMobileTinyLight, {}]} onPress={() => editCustomizedItem(item)}>
                    {t('edit')}
                  </LinkButton>
                ) : null}
              </Text>
              {sellType.key === 'weightByEach' && averageWeight ? (
                <Text
                  testID="unitOfMeasure"
                  key={'unit-of-measure'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.averageStyle]}>
                  {`Avg ${averageWeight} ${unitOfMeasure}`}
                </Text>
              ) : sellType.key === 'byEach' && unitsPerPackage ? (
                <Text
                  testID="unitOfMeasure"
                  key={'unit-of-measure'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.averageStyle]}>
                  {`${unitTotalCount} ${unitOfMeasure}`}
                </Text>
              ) : null}
            </TouchableOpacity>
            {leadTimeLabel}
            {custLink}
          </View>
          <View style={{ width: '22%', justifyContent: 'flex-start' }}>
            <View key={`productPriceDetails_${item.id}`} style={[]}>
              <Text testID="pricedetail" key={'pricedetail'} numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.subtotal]}>
                {moneyValue(totalPrice, qty)}
              </Text>
              {approxLine}
            </View>
          </View>
        </View>,
      );
      productDetails.push(
        <View key={`title2_${item.id}`} style={{ flexDirection: 'row' }}>
          <View style={{ width: '100%', justifyContent: 'flex-start' }}>
            <View key={`productPriceDetails_${item.id}`} style={[]}>
              {regularPriceRow}
            </View>
          </View>
        </View>,
      );

      return productDetails;
    },
    [customizationsPressed, editCustomizedItem, handleNavigation, itemState, productData, readOnly, savedForLaterListData?.lineItems, t],
  );

  let itemRowRefs = useMemo(() => new Map(), []);
  const RenderListItem = useCallback(
    (item: ShoppingListLineItem, index: number, arr: ShoppingListLineItem[]) => {
      const savedItem = savedForLaterListData?.lineItems?.find((li) => li.variant?.sku === item.variant?.sku);

      let catHeader = null;
      let sellType: { key: string; label: string } = { key: 'byEach', label: 'By Each' };
      let unitBuyIncrement = 1,
        unitBuyMinimum = 1;
      let addItemSeparator = false;
      if (productData) {
        const currData = getRootCategory(productData[item.variant?.sku ?? '']?.masterData?.current?.categories);
        const lastData = index ? getRootCategory(productData[arr[index - 1]?.variant?.sku ?? '']?.masterData?.current?.categories) : undefined;
        const nextData = index + 1 < arr.length ? getRootCategory(productData[arr[index + 1]?.variant?.sku ?? '']?.masterData?.current?.categories) : null;
        sellType = getProductAttributeValue(
          'unitSellType',
          productData[arr[index + 1]?.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
        ) ?? { key: 'byEach', label: 'By Each' };

        if ((sellType?.key ?? 'byEach') === 'byWeight') {
          unitBuyIncrement =
            getProductAttributeValue(
              'unitBuyIncrement',
              item.variant?.attributesRaw ?? productData[arr[index]?.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
            ) ?? unitBuyIncrement;
          unitBuyMinimum =
            getProductAttributeValue(
              'unitBuyMinimum',
              item.variant?.attributesRaw ?? productData[arr[index]?.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
            ) ?? unitBuyMinimum;
        }

        if (currData?.name && (lastData?.name ?? '') !== currData.name) {
          catHeader = (
            <View key={`catheader_${index}`} style={[styles.categoryHeader, index ? utilityStyles.mt3 : {}]}>
              <View style={[styles.itemFrame]}>
                <Text testID={`${currData.name}`} style={[appStyles.bodySmallRegular]}>
                  {currData.name}
                </Text>
              </View>
            </View>
          );
        }
        if (currData?.name && nextData?.name === currData?.name) {
          addItemSeparator = true;
        }
      }
      const key = item.id;
      return (
        <View key={`item_${item.id}`}>
          {catHeader}
          <Swipeable
            key={key}
            ref={(r2) => {
              if (r2) {
                itemRowRefs.set(key, r2);
              }
            }}
            renderRightActions={(progress, dragAnimatedValue) => renderRightActions(progress, dragAnimatedValue, item)}
            overshootRight={true}
            onSwipeableOpen={() => {
              [...itemRowRefs.entries()].forEach(([k, r1]) => {
                if (k !== key && r1) {
                  r1.close();
                }
              });
              if (removeOnSwipe === key) {
                _removeLineItem(item, undefined);
              }
              setRemoveOnSwipe('');
            }}>
            <TouchableOpacity key={`renderSavedItemViewKey_${item.id}`} onPress={() => handleNavigation(item)}>
              <View style={[styles.subContainerItem]}>
                <View style={[styles.itemFrame]}>
                  <View style={[styles.itemLeftCart]}>
                    <View>
                      {productData && item.variant?.sku && (
                        <ProductImage key={'productImageKey'} imageUrl={savedItem?.variant?.images[0]?.url ?? ''} style={styles.productImage} />
                      )}
                      <QuantityBox
                        key={'quantityKey'}
                        containerStyle={[styles.itemQuantity, utilityStyles.py1, { backgroundColor: colors.white, alignItems: 'center' }]}
                        countStyle={[appStyles.primaryButtonSmallText, { minWidth: scale(40), minHeight: lineHeight(18) }]}
                        initialValue={getQuantityIndex(
                          item.variant?.sku ?? '',
                          item.quantity,
                          getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []),
                        )}
                        onChange={(q) => handleQuantityChange(item, q)}
                        showCartText={false}
                        borderStyle={{ borderLeftWidth: 0, borderRightWidth: 0 }}
                        showToggle={false}
                        incrementBy={unitBuyIncrement}
                        maxLimit={getMaxProductQuantity(item.variant?.sku)}
                        minLimit={unitBuyMinimum}
                        qtyFormat={(idx) => formatQuantityFromIndex(item.variant?.sku ?? '', idx)}
                      />
                    </View>
                  </View>
                  <View style={[styles.itemRight]}>
                    {ProductInfo({ lineItem: item })}
                    <ActionLinksBottom key={`actionLinksBottomRoot_${item.id}`} item={item} />
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </Swipeable>
          {addItemSeparator && <View key={'separatorKey'} style={[styles.separator]} />}
        </View>
      );
    },
    [
      savedForLaterListData?.lineItems,
      productData,
      getQuantityIndex,
      getMaxProductQuantity,
      ProductInfo,
      ActionLinksBottom,
      itemRowRefs,
      renderRightActions,
      removeOnSwipe,
      _removeLineItem,
      handleNavigation,
      handleQuantityChange,
      formatQuantityFromIndex,
    ],
  );

  const undoRemoveAll = useCallback(
    async (items: AddLineItem[]) => {
      await addItemsToShoppingList(savedForLaterList?.id ?? '', savedForLaterList?.version, items, []);
      emptyCart();
      showAlertPopup({
        message: `Items Moved To "${savedForLaterList?.name}"`,
        containerStyle: styles.alertBox,
        linkText: 'Edit',
        linkAction: () => logger.log('scroll to sfl'),
      });
    },
    [addItemsToShoppingList, savedForLaterList?.id, savedForLaterList?.name, savedForLaterList?.version, showAlertPopup, emptyCart],
  );

  const addAllToCart = async () => {
    setLoading(true);
    var hasStandardItems = false;
    var hasConfigurableItems = false;
    try {
      let addItems: AddLineItem[] = savedForLaterListData?.lineItems
        .filter((li) => 'variant' in li && li.productType.name !== 'configurable')
        ?.filter((lii) => !getAttributeValue('parentLineItemId', lii.custom?.customFieldsRaw))
        ?.map((si) => {
          return {
            sku: si.variant?.sku,
            quantity: si.quantity,
          } as AddLineItem;
        })
        .filter((ai) => ai !== undefined) as AddLineItem[];
      let removeIds: string[] = savedForLaterListData?.lineItems
        .filter((li) => 'variant' in li && li.productType.name !== 'configurable')
        ?.filter((lii) => !getAttributeValue('parentLineItemId', lii.custom?.customFieldsRaw))
        ?.map((si) => {
          return si.id;
        })
        .filter((ai) => ai !== undefined) as string[];
      if (savedForLaterListData?.lineItems.length) {
        hasStandardItems = true;
      }
      if (addItems.length) {
        await addStandardLineItems(addItems);
      }

      if (savedForLaterListData?.lineItems.some((li) => li.productType.name === 'configurable')) {
        hasConfigurableItems = true;
        let productKeys: AddLineItem[] = [];
        const customizedItems = savedForLaterListData?.lineItems.filter((li) => li.productType.name === 'configurable');
        for (let elem of customizedItems) {
          try {
            removeIds.push(elem.id);
            addItems.push({ sku: elem.variant?.sku ?? '', quantity: elem.quantity ?? 1 });
            let selections = savedForLaterListData?.lineItems.filter((sfli) => getAttributeValue('parentLineItemId', sfli.custom?.customFieldsRaw) === elem.id);
            productKeys.push({
              sku: elem?.variant?.sku ?? '',
              quantity: elem.quantity || 1,
            });
            selections.forEach((sel) => {
              productKeys.push({
                sku: sel.variant?.sku ?? '',
                quantity: sel.quantity ?? 1,
                parentLineItemId: elem.variant?.sku ?? '',
                itemNote: getAttributeValue('itemNote', sel.custom?.customFieldsRaw),
                customStepSort: getAttributeValue('customStepSort', sel.custom?.customFieldsRaw),
              });
              // removeIds.push(sel.id);
              addItems.push({
                sku: sel?.variant?.sku ?? '',
                quantity: sel.quantity ?? 1,
                parentLineItemId: elem.variant?.sku ?? '',
                itemNote: getAttributeValue('itemNote', sel.custom?.customFieldsRaw),
                customStepSort: getAttributeValue('customStepSort', sel.custom?.customFieldsRaw),
              });
            });
            await addCustomizableLineItem(productKeys);
          } catch (e) {
            logger.log(e);
          }
        }
      }
      if (hasStandardItems || hasConfigurableItems) {
        // logger.log('has items', hasStandardItems, hasConfigurableItems);
        clearList(savedForLaterList?.id ?? '').then(() => {
          showAlertPopup({
            message: 'Items Moved to Cart',
            // message: `Items Removed from "${savedForLaterList?.name}"`,
            containerStyle: styles.alertBox,
            linkText: 'Undo',
            linkAction: () => undoRemoveAll(addItems),
          });
        });
      }
    } catch (e) {
      logger.log(e);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <View style={[styles.container]}>
      <View style={styles.header}>
        <Text style={appStyles.dropShadowTitle}>Saved for Later</Text>
        <View style={styles.headerInfo}>
          <Text style={appStyles.bodySmallLeftRegular}>
            {savedForLaterList?.lineItems?.filter((sfli) => !getAttributeValue('parentLineItemId', sfli.custom?.customFieldsRaw)).length} item(s)
          </Text>
          {loading ? (
            <ActivityIndicator style={{ marginRight: 40 }} />
          ) : (
            <TouchableOpacity onPress={addAllToCart}>
              <Text style={appStyles.boldLinkLargeCenter}>Move All to Cart</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <DropShadow
        key={'saveForLaterShadow'}
        style={[{ borderBottomWidth: 0, borderTopWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }, utilityStyles.mt3]}>
        <View style={styles.productListContainer}>
          {savedForLaterList?.lineItems
            ?.filter((lii) => !getAttributeValue('parentLineItemId', lii.custom?.customFieldsRaw))
            ?.sort((a, b) => {
              if (b.addedAt < a.addedAt) {
                return -1;
              }
              if (b.addedAt > a.addedAt) {
                return 1;
              }
              return 0;
            })
            .map((li, i, a) => RenderListItem(li, i, a))}
        </View>
      </DropShadow>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.promoCream,
  },
  alertBox: {
    bottom:
      screenHeight < 736
        ? screenHeight * 0.225
        : screenHeight > 812
        ? screenHeight > 896
          ? screenHeight * 0.21
          : screenHeight * 0.215
        : screenHeight < 740
        ? screenHeight * 0.225
        : screenHeight * 0.225,
  },
  header: {
    justifyContent: 'center',
    width: containerWidth,
    alignSelf: 'center',
    paddingTop: 25,
    backgroundColor: colors.promoCream,
  },
  headerInfo: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  productListContainer: {
    // width: '100%',
    backgroundColor: colors.cream,
    // padding: 16,
    // paddingVertical: 25,
  },
  separator: {
    height: 1,
    width: containerWidth,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  separatorLight: {
    height: 1,
    width: containerWidth,
    backgroundColor: colors.darkCream,
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.sectionHeader,
    alignSelf: 'center',
    width: screenWidth,
  },
  categoryHeader: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.promoCream,
    paddingTop: 10,
  },
  subContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingVertical: 16,
  },
  orderNoteContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  orderNoteSubContainer: {
    flexDirection: 'column',
    width: containerWidth,
    alignSelf: 'center',
  },
  orderNoteSubContent: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
  },
  leadWarningContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
  },
  leadWarningContainerText: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  subRegprice: {
    marginTop: -4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  noteContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 15,
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    paddingBottom: 5,
  },
  flex1: { flex: 1 },
  flexrow: { flexDirection: 'row' },
  flexcol: { flexDirection: 'column' },
  itemsHeader: {
    backgroundColor: colors.sectionPad,
    paddingBottom: 2,
    paddingTop: 6,
    marginBottom: 8,
  },
  itemLeftHeader: {
    minWidth: layout.width,
    width: layout.width,
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  empty: {
    width: containerWidth,
    textAlign: 'center',
    alignSelf: 'center',
  },
  flatPadContainer: {
    backgroundColor: colors.white,
    width: screenWidth,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  flatPad: {
    backgroundColor: colors.white,
    width: containerWidth,
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: scale(12),
    marginTop: scale(12),
    minHeight: scale(60),
  },

  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
  },
  itemLeft: {
    minWidth: layout.width,
    width: layout.width,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flex: 0,
    justifyContent: 'space-between',
  },
  itemLeft1: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
  },
  itemLeft2: {
    width: layout.imageWidth,
    minWidth: layout.imageWidth,
  },
  itemLeftCart: {
    minWidth: layout.imageWidth,
    width: layout.imageWidth,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    flex: 0,
    alignItems: 'flex-start',
  },
  headerQty: {
    minWidth: layout.width,
    width: layout.width,
    textAlign: 'left',
  },
  headerQtyCart: {
    minWidth: layout.width,
    width: layout.width,
    textAlign: 'left',
  },
  itemQuantity: {
    alignSelf: 'center',
    marginTop: 10,
    width: 50,
    height: 32,
    borderColor: colors.productBorder,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 1.5 },
    shadowOpacity: 0.2,
    shadowRadius: 1.5,
  },
  actionLinksBottom: {},
  actionLinksBottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flex: 1,
  },
  actionLinksLeft: {
    textAlign: 'center',
    marginTop: 3,
    marginBottom: 3,
  },
  actionLinksLeftContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 0,
  },
  productImage: {
    width: layout.imageWidth,
    alignSelf: 'center',
    height: layout.imageHeight,
    borderWidth: 1,
    borderColor: colors.productBorder,
  },
  itemRight: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 10,
  },
  priceContainer: {
    flexDirection: 'row',
  },
  customizationsContainer: {
    paddingTop: 8,
    flexDirection: 'column',
  },
  errorMessage: {
    flexDirection: 'row',
    ...appStyles.formErrorText,
    textAlign: 'left',
  },
  subseparator: {
    height: 0.33,
    width: '100%',
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.cream,
  },
  subtotal: {
    textAlign: 'right',
    flex: 0.29,
    alignSelf: 'flex-end',
    lineHeight: 26,
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    alignSelf: 'flex-end',
    lineHeight: 26,
  },
  subprice: {
    textAlign: 'right',
    alignSelf: 'flex-end',
    lineHeight: lineHeight(12 * 1.2),
  },
  newPrice: {
    color: colors.red,
  },
  unitHeader: {
    flex: 0.55,
    marginRight: 1,
  },
  unit: {
    flex: 0.55,
    marginRight: 1,
    alignSelf: 'flex-end',
    lineHeight: 26,
  },
  unitSeparator: {
    width: 0.03,
    height: '100%',
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.darkCream,
    alignSelf: 'flex-end',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  cartEmpty: {
    marginBottom: 25,
  },
  swipedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.sectionHeader,
  },
  deleteButton: {
    backgroundColor: colors.red,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: 20,
    marginLeft: 'auto',
  },
  menuOption: {
    padding: 0,
    margin: 0,
    height: 38,
  },
  menuOptions: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 200,
    height: 86,
    backgroundColor: colors.white,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    borderColor: colors.darkCream,
    borderWidth: 1,
    borderRadius: 8,
  },
  menuOptionText: {
    paddingLeft: 14,
    backgroundColor: colors.white,
    lineHeight: 38,
    textAlignVertical: 'top',
  },
  menuOptionWrapper: {
    backgroundColor: colors.sectionBorder,
    color: 'white',
  },
  menuOptionsTouchable: {
    activeOpacity: 0.75,
    textAlign: 'center',
  },
  accessory: {
    width: containerWidth,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    paddingHorizontal: 8,
  },
  approxSubtotal: {
    marginTop: -8,
    marginBottom: -8,
    textAlign: 'right',
    flex: 0.29,
    marginLeft: 'auto',
  },
  averageStyle: {
    marginTop: 2,
  },
});

export default SavedForLater;

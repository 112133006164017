import { enterpriseService } from '@services/brandywine';
import logger from '@utils/logger';
import { create } from 'zustand';
import { StoreFinderFilterStore } from './storeTypes';

export type StoreFinderFilters = {
  title: string;
  id: string;
  selected: boolean;
  departmentId: string;
};

export const defaultFilters = Object.freeze([
  {
    title: 'Favorite Store Location',
    id: '-1',
    departmentId: '-1',
    selected: false,
  },
  {
    title: 'Previously Shopped',
    id: '-2',
    departmentId: '-2',
    selected: false,
  },
]) as StoreFinderFilters[];

const useStoreFinderFilterStore = create<StoreFinderFilterStore>()((set, get) => ({
  departments: [],
  initialize: () => {
    Promise.all([
      enterpriseService.getStoreDepartments().catch((error) => {
        error.message !== 'CANCEL_ERROR' && logger.warn('failed to get departments: ', error);
        return error.message !== 'CANCEL_ERROR' ? 'error' : 'loading';
      }),
    ]).then((results) => {
      const storeFilters: StoreFinderFilters[] = [];

      const services = results[0] !== 'error' && results[0] !== 'loading' ? results[0] : [];
      if (results.length > 0) {
        services.forEach((s) => {
          if (s.customProperties.IsFilterable && !s.customProperties.IsInternal) {
            storeFilters.push({
              title: s.value,
              id: s.id,
              departmentId: s.id,
              selected: false,
            });
          }
        });
      }
      set((state) => ({
        ...state,
        departments: [...defaultFilters, ...storeFilters],
      }));
    });
  },
  selectFilter: (filterId: string, selected: boolean = true) => {
    const depts = get().departments;
    depts.forEach((c) => {
      c.selected = c.departmentId === filterId ? selected : c.selected;
    });
    set((state) => ({ ...state, departments: depts }));
  },
  getSelectedFilters: () => {
    const departmentFilters = get().departments;
    var result: StoreFinderFilters[] = [];
    departmentFilters.forEach((f) => {
      var selected = f.selected;
      if (selected) {
        result.push({ ...f, selected: selected });
      }
    });
    return result;
  },
  isFilterSelected: (filterId: string): boolean => {
    const filters = get().departments;
    const result = filters.find((f) => f.departmentId.toLowerCase() === filterId.toLowerCase());
    return result?.selected ?? false;
  },
  resetFilters: () => {
    const filters = get().departments;
    filters.forEach((c) => {
      c.selected = false;
    });
    set((state) => ({ ...state, departments: filters }));
  },
  getSelectedFiltersCount: () => {
    const selectedFilters = get().getSelectedFilters();
    var count = selectedFilters.length;
    return count;
  },
  getSelectedDepartmentIds: () => {
    const selectedFilters = get().getSelectedFilters();
    var result: number[] = [];
    selectedFilters
      .filter((f) => Number(f.id) > 0)
      .map((f) => {
        var departmentId = Number(f.departmentId);
        result.push(departmentId);
      });
    return result;
  },
}));

export default useStoreFinderFilterStore;

import { Button, DropShadow, HtmlView, Icon, Image, LinkButton, ProductCard, SearchHeader, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import SponsoredIndicator from '@components/SponsoredIndicator';
import Tabbar, { TabItem } from '@components/Tabbar';
import { OpenTombstoneRequest } from '@components/Tombstone';
import withTombstone from '@components/hoc/withTombstone';
import ShelfGuideDiscoverContent from '@components/shelfGuide/ShelfGuideDiscoverContent';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { CampaignResult, PagedArray } from '@fieldera-raleys/client-common';
import { useAnalytics, useEffectOnce } from '@hooks';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { StackActions, useFocusEffect } from '@react-navigation/native';
import { brsmService } from '@services';
import { useAnalyticsStore, useListsStore, useSearchStore, useShopStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { findTag, getHtmlAttribute, slug } from '@utils/helpers';
import { ProductList, getProductPromotions } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, ListRenderItemInfo, Platform, RefreshControl, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomModalContext } from '../../contexts';

type SearchLandingScreenProps = ShopStackScreenProps<ShopStackRoutes.SearchLanding> & { setTsRequest: (reqest: OpenTombstoneRequest) => void };

export type TabName = 'product' | 'on-sale' | 'purchased';

const AutoCorrectQuery = ({ searchedQuery, correctedQuery }: { searchedQuery: string; correctedQuery: string }): JSX.Element => (
  <View>
    <View style={styles.autoCorrectContainer}>
      <Text testID="noResultFor" style={appStyles.bodySmallLeftRedLight}>
        No result for:{' '}
      </Text>
      <Text testID="searchedQuery" style={[appStyles.bodySmallBold, styles.queryText]}>
        {' '}
        "{searchedQuery}"
      </Text>
    </View>
    <View style={styles.autoCorrectContainer}>
      <Text testID="searchedFor" style={appStyles.bodySmallLeftRedLight}>
        Searched for:{' '}
      </Text>
      <Text testID="correctedQuery" style={[appStyles.bodySmallBold, styles.queryText]}>
        {' '}
        "{correctedQuery}"
      </Text>
    </View>
  </View>
);

const DidYouMean = ({ items, onPress }: { items: string[]; onPress: (item: string) => void }): JSX.Element => (
  <View style={styles.didYouMeanContainer}>
    <Text testID="didYouMean" style={appStyles.bodySmallLeftRedLight}>
      Did you mean:{' '}
    </Text>
    {items.map((text) => (
      <TouchableOpacity key={text} onPress={() => onPress(text)}>
        <Text testID="didYouMeanText" style={[appStyles.boldLinkLargeCenter, styles.queryText]}>
          {text}
        </Text>
      </TouchableOpacity>
    ))}
  </View>
);

const EmptyListComponent = ({ searchText, onPress }: { searchText?: string; onPress?: () => void }): JSX.Element => {
  return (
    <View style={styles.noItemFoundView}>
      <View style={[styles.emptyListContainer]} testID="noSearchResults">
        <View style={styles.emptyListIcon}>
          <Icon testID="searchMissingIcon" name={'search-missing-icon'} size={68} stroke="none" />
        </View>
        <Text testID="searchText" numberOfLines={2} style={[appStyles.bodySmallRegular, { width: '42%', lineHeight: lineHeight(25) }]}>
          {searchText && searchText !== '*' ? `No items found for "${searchText}"` : 'No items found'}
        </Text>
      </View>
      <View style={[styles.noItemFoundView, { marginTop: 10 }]}>
        <Text testID="emptyListComponentText" style={[appStyles.fontMobileListSmallCenterRegular, { paddingHorizontal: 35 }]}>
          Try checking for typos or search for something more general
        </Text>
      </View>
      <Button
        testID="wayToShop"
        title={'Ways to Shop'}
        type="secondary"
        onPress={onPress}
        textStyle={[appStyles.secondaryButtonSmallText]}
        buttonStyle={[styles.button]}
      />
    </View>
  );
};

const ShelfGuideBanner = ({
  shelfGudide,
  imageUrl,
  onPress,
}: {
  shelfGudide: string;
  imageUrl: string;
  onPress: ((event: any) => void) | undefined;
}): JSX.Element => (
  <View style={[styles.shelfGuideBannerContainer]}>
    {imageUrl ? (
      <TouchableOpacity activeOpacity={0.75} onPress={onPress}>
        <Image
          testID="shelfGuideBannerImage"
          style={styles.image}
          accessibilityLabel={shelfGudide}
          source={{
            uri: `${imageUrl}`,
          }}
          resizeMode={'contain'}
        />
      </TouchableOpacity>
    ) : (
      <></>
    )}
    <View style={[styles.shelfGuideLinkContainer, {}]}>
      <TouchableOpacity onPress={onPress}>
        <Text testID="learnMore" style={[appStyles.tinyLight, styles.link]}>
          Learn More
        </Text>
      </TouchableOpacity>
    </View>
  </View>
);

interface ProductTabState {
  isLoading: boolean;
  isRefreshing: boolean;
  searchText?: string;
  selectedTabName: TabName;
  didYouMean?: string[];
  correctedQuery?: string;
  filterBadgeCount: number;
  productSearchResults: PagedArray<ProductList>;
  campaign?: CampaignResult;
}

const ProductTab = ({
  navigation,
  route,
  onSearchSubmit,
  setTsRequest,
}: SearchLandingScreenProps & { onSearchSubmit: (searchQuery: string, searchCategory?: string) => void }): JSX.Element => {
  const { query, showAddToList = false, shelfGuideId = '', fromListName = '' } = route?.params;
  const { getSelectedFilters, getSelectedFiltersCount, sortQuery, searchType, setAvailableFilters, addRecentlySearched } = useSearchStore();
  const { previousRoute } = useAnalyticsStore();
  const { selectedStore } = useShopStore();
  const baseImageUrl = Config.BRXM_IMAGE_URL;
  const imageUrl = `${baseImageUrl}/consumer-app/shelf-guide/headers/${shelfGuideId.replace(/\s/g, '').toLowerCase()}header.png`;
  const [state, setState] = useState<ProductTabState>({
    filterBadgeCount: 0,
    isRefreshing: false,
    isLoading: true,
    selectedTabName: 'product',
    productSearchResults: {
      data: [],
      offset: 0,
      limit: appConstants.PAGE_SIZE,
      total: 0,
    },
  });
  const { addTextLineItemToList } = useListsStore();
  const { showAlertPopup } = useCustomModalContext();

  const addToList = async () => {
    if (fromListName) {
      await addTextLineItemToList(query ?? '', '', fromListName ?? '');
      showAlertPopup({
        message: `Item Added to "${fromListName}"`,
        containerStyle: styles.alertBox,
      });
    }
  };

  const loadProducts = useCallback(
    async (offset: number, searchTxt: string) => {
      searchTxt && searchTxt.trim() && addRecentlySearched(searchTxt);
      const res = await brsmService.searchProduct(offset, appConstants.PAGE_SIZE, {
        searchQuery: searchTxt ?? '',
        pathname: route.name,
        ref_url: previousRoute?.name,
        selectedFilters: getSelectedFilters(),
        sortQuery: sortQuery,
        searchType: searchType,
        storeKey: selectedStore && selectedStore.id ? undefined : Config.BRSM_DEFAULT_STORE,
      });

      let updatedProductList: ProductList[] = [];
      if (res.docs.total > 0 && res?.docs.data.length > 0) {
        setAvailableFilters(res.facetFilters);

        const skus = res.docs.data.map((p) => p.masterData.current?.masterVariant.sku ?? '') ?? [];
        const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE, skus);
        updatedProductList = getProductPromotions(promotions, res?.docs.data);
      }

      setState((prev) => ({
        ...prev,
        isRefreshing: false,
        isLoading: false,
        searchText: searchTxt,
        didYouMean: res?.didYouMean,
        campaign: res?.campaign,
        correctedQuery: res?.autoCorrectQuery,
        filterBadgeCount: getSelectedFiltersCount(),
        productSearchResults: {
          ...prev.productSearchResults,
          total: res?.docs.total ?? 0,
          offset: res?.docs.offset ?? 0,
          data: [...(offset === 0 ? [] : prev.productSearchResults.data), ...(updatedProductList ?? [])],
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previousRoute?.name, route.name, searchType, selectedStore, sortQuery],
  );

  useEffect(() => {
    if (query) {
      loadProducts(0, query);
    }
    return () => {
      setState({
        filterBadgeCount: 0,
        isRefreshing: false,
        isLoading: true,
        selectedTabName: 'product',
        productSearchResults: {
          data: [],
          offset: 0,
          limit: appConstants.PAGE_SIZE,
          total: 0,
        },
      });
    };
  }, [query, loadProducts]);

  const handleRefresh = async () => {
    if (state.searchText) {
      setState((prev) => ({ ...prev, isRefreshing: true }));
      loadProducts(0, state.searchText);
    }
  };

  const handleLoadMore = async () => {
    if (state.productSearchResults.total > state.productSearchResults.offset + appConstants.PAGE_SIZE && state.searchText) {
      loadProducts(state.productSearchResults.offset + appConstants.PAGE_SIZE, state.searchText);
    }
  };

  const handleNavigation = () => {
    navigation.dispatch(StackActions.push(ShopStackRoutes.ShelfGuideCategoryDetails, { shelfGuide: shelfGuideId.replace(/\s/g, '').toLowerCase() }));
    navigation.setParams({ shelfGuideId: shelfGuideId });
  };

  const isSponsored = state.campaign?.name ? (findTag(state.campaign?.name, 'sponsored') ? true : false) : undefined;
  const isTile = state.campaign?.name ? (findTag(state.campaign?.name, 'tile') ? true : false) : undefined;

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<ProductList>) => {
      // const promotions = promotionProductList.map((pr) => pr.promotion);

      return (
        <>
          {state.campaign?.htmlText && isTile && index === 0 && (
            <View style={styles.campaignCardContainer}>
              <View style={styles.campaignCard}>
                <LinkButton
                  route={slug(getHtmlAttribute('href', state.campaign?.htmlText))}
                  accessibilityLabel={getHtmlAttribute('alt', state.campaign?.htmlText)}
                  style={{ paddingTop: Platform.select({ android: scale(260) }) }}>
                  <HtmlView text={state.campaign?.htmlText.replace('http:', 'https:') ?? ''} contentWidth={screenWidth * 0.5} />
                </LinkButton>
                {isSponsored ? <SponsoredIndicator /> : null}
              </View>
            </View>
          )}
          <ProductCard
            fromListName={fromListName}
            showAddToList={showAddToList}
            product={item.product}
            productCategory={{
              parentId: 'searchLanding',
              id: '',
              name: '',
              crumb: [],
              imageName: '',
              children: [],
              orderHint: 0.8,
            }}
            index={index}
            productPromotion={item.promotion}
            changeStore={setTsRequest}
          />
        </>
      );
    },

    [fromListName, isSponsored, isTile, setTsRequest, showAddToList, state.campaign?.htmlText],
  );

  const renderItemSepatator = useCallback(() => <View style={styles.separator} />, []);

  return state.isLoading ? (
    <ActivityIndicator size={'large'} style={[styles.activityIndicator]} />
  ) : (
    <>
      {showAddToList && (
        <DropShadow>
          <View style={styles.addToListContainer}>
            <Text numberOfLines={1} style={[appStyles.bodyLargeCenter, { width: '60%' }]} testID="queryText">{`"${query}"`}</Text>
            <Button size="small" onPress={addToList} title={'Add to List'} />
          </View>
        </DropShadow>
      )}
      {/* <View style={styles.bottomBorder} /> */}
      <FlatList
        testID="productsList"
        data={state.productSearchResults.data}
        keyExtractor={(_, idx) => idx.toString()}
        renderItem={renderItem}
        numColumns={2}
        ItemSeparatorComponent={renderItemSepatator}
        refreshing={false}
        ListHeaderComponent={
          <>
            {shelfGuideId ? <ShelfGuideBanner shelfGudide={shelfGuideId} imageUrl={imageUrl} onPress={handleNavigation} /> : <></>}
            {!!state.correctedQuery && <AutoCorrectQuery searchedQuery={state.searchText ?? ''} correctedQuery={state.correctedQuery} />}
            {!!state.didYouMean && state.didYouMean.length > 0 && <DidYouMean items={state.didYouMean} onPress={onSearchSubmit} />}
            {state.campaign?.htmlText && !isTile && (
              <>
                <LinkButton
                  style={{ paddingTop: Platform.select({ android: scale(80) }) }}
                  route={slug(getHtmlAttribute('href', state.campaign?.htmlText))}
                  accessibilityLabel={getHtmlAttribute('alt', state.campaign?.htmlText)}>
                  <HtmlView text={state.campaign?.htmlText.replace('http:', 'https:') ?? ''} contentWidth={screenWidth} />
                </LinkButton>
                {isSponsored ? <SponsoredIndicator /> : null}
              </>
            )}
          </>
        }
        refreshControl={<RefreshControl refreshing={state.isRefreshing} onRefresh={handleRefresh} />}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        onRefresh={handleRefresh}
        nestedScrollEnabled={true}
        ListEmptyComponent={
          <EmptyListComponent
            searchText={query}
            onPress={() => {
              navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Shop,
                params: { screen: ShopStackRoutes.Categories },
              });
            }}
          />
        }
        ListFooterComponent={shelfGuideId && !state.isLoading && !state.isRefreshing ? <ShelfGuideDiscoverContent /> : <></>}
      />
    </>
  );
};

const SearchLandingScreen = withTombstone(({ route, navigation, setTsRequest }: SearchLandingScreenProps): JSX.Element => {
  const { query, prevRoute = '', tabName = 'product' } = route?.params;
  const { addRecentlySearched, getSelectedFiltersCount, selectFilter, resetFilters, isFilterSelected } = useSearchStore();
  const [selectedTabName, setSelectedTabName] = useState<TabName>(tabName);
  const filterBadgeCount = getSelectedFiltersCount();
  const { trackSearchSubmitEvent, trackPageViewEvent } = useAnalytics();
  const insets = useSafeAreaInsets();
  const trackPageView = useRef<boolean>(true);
  const tabBarHeight = useBottomTabBarHeight();
  const tabItems: TabItem[] = [
    {
      name: 'product',
      displayName: 'Products',
      onPress: () => {
        selectFilter('onSale', false);
        selectFilter('previouslyPurchased', false);
        selectedTabName !== 'product' && setSelectedTabName('product');
      },
    },
    {
      name: 'on-sale',
      displayName: 'On Sale',
      onPress: () => {
        selectFilter('previouslyPurchased', false);
        selectFilter('onSale');
        selectedTabName !== 'on-sale' && setSelectedTabName('on-sale');
      },
    },
    {
      name: 'purchased',
      displayName: 'Purchased',
      onPress: () => {
        selectFilter('onSale', false);
        selectFilter('previouslyPurchased');
        selectedTabName !== 'purchased' && setSelectedTabName('purchased');
      },
    },
  ];

  useEffectOnce(() => {
    if (trackPageView.current) {
      trackPageViewEvent();
      trackPageView.current = false;
    }
    return () => {
      trackPageView.current = true;
    };
  });

  useFocusEffect(
    useCallback(() => {
      const isOnSaleSelected = isFilterSelected('onSale');
      const isPreviouslyPurchasedSelected = isFilterSelected('previouslyPurchased');
      if (isOnSaleSelected && isPreviouslyPurchasedSelected) {
        selectedTabName !== 'product' && setSelectedTabName('product');
      } else if (isOnSaleSelected) {
        selectedTabName !== 'on-sale' && setSelectedTabName('on-sale');
      } else if (isPreviouslyPurchasedSelected) {
        selectedTabName !== 'purchased' && setSelectedTabName('purchased');
      } else {
        selectedTabName !== 'product' && setSelectedTabName('product');
      }
    }, [isFilterSelected, selectedTabName]),
  );

  const onSearchSubmit = useCallback(async (searchQuery: string, searchCategory?: string) => {
    navigation.setParams({ ...route.params, query: searchQuery, shelfGuideId: '', tabName: 'product' });
    resetFilters();
    searchCategory && selectFilter(searchCategory);
    searchQuery && searchQuery.trim() && addRecentlySearched(searchQuery);
    trackSearchSubmitEvent({ q: searchQuery });
    setSelectedTabName('product');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackButtonPress = () => {
    resetFilters();
    if (prevRoute === ShopStackRoutes.Category) {
      navigation.canGoBack() && navigation.pop();
    } else {
      navigation.goBack();
    }
  };

  return (
    <View style={[{ flex: 1 }, Platform.OS === 'web' ? {} : { marginBottom: 65 - insets.bottom }]}>
      <CartInfoBanner />
      <SearchHeader
        onBackButtonPress={handleBackButtonPress}
        searchText={query}
        showBackButton={true}
        onSearchSubmit={onSearchSubmit}
        style={{ marginBottom: 0 }}
        tabBarHeight={tabBarHeight}
      />
      <DropShadow>
        <View style={{ flexDirection: 'row' }}>
          <View style={[styles.leftContainer]}>
            <Tabbar tabs={tabItems} selectedTabName={selectedTabName} containerStyle={{ height: scale(33.5) }} />
          </View>
          <TouchableOpacity
            style={styles.rightContainer}
            onPress={() =>
              navigation.push(AppStackRoutes.SearchFilter, {
                title: 'Filter & Sort',
                text: query,
              })
            }>
            <Icon testID="sortIcon" name="sort-icon" size={28} style={[appStyles.mediumIcon, styles.icon]} stroke={colors.darkText} />
            {filterBadgeCount ? (
              <View style={[styles.badge]}>
                <Text testID="filterBadgeCount" style={styles.badgeText}>
                  {filterBadgeCount}
                </Text>
              </View>
            ) : null}
          </TouchableOpacity>
        </View>
      </DropShadow>
      <>
        {selectedTabName === 'product' && (
          <>
            <ProductTab navigation={navigation} route={route} onSearchSubmit={onSearchSubmit} setTsRequest={setTsRequest} />
          </>
        )}
        {selectedTabName === 'on-sale' && (
          <>
            <View style={styles.bottomBorder} />
            <ProductTab navigation={navigation} route={route} onSearchSubmit={onSearchSubmit} setTsRequest={setTsRequest} />
          </>
        )}
        {selectedTabName === 'purchased' && (
          <>
            <ProductTab navigation={navigation} route={route} onSearchSubmit={onSearchSubmit} setTsRequest={setTsRequest} />
          </>
        )}
      </>
    </View>
  );
}, {});

const styles = StyleSheet.create({
  separator: {
    backgroundColor: colors.darkCream,
    height: 1.5,
    width: screenWidth * 0.92,
    transform: [{ translateX: (screenWidth * 0.08) / 2 }],
  },
  addToListContainer: {
    // flexDirection: 'row',
    backgroundColor: colors.white,
    height: 125,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 16,
    width: '100%',
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.17 : screenHeight > 812 ? screenHeight * 0.165 : screenHeight < 740 ? screenHeight * 0.165 : screenHeight * 0.175,
  },
  bottomBorder: {
    height: 1,
    backgroundColor: colors.darkCream,
    width: screenWidth - 24,
    alignSelf: 'center',
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 45,
  },
  emptyListIcon: {
    width: 100,
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 18,
    paddingLeft: 22,
  },
  autoCorrectContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 0,
    marginLeft: 15,
    alignItems: 'center',
  },
  queryText: {
    marginHorizontal: 5,
    lineHeight: lineHeight(25),
  },
  didYouMeanContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 15,
  },
  button: {
    minWidth: '50%',
    width: 130,
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  noItemFoundView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContainer: {
    width: '90%',
  },
  rightContainer: {
    width: '10%',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignSelf: 'center',
    height: scale(33.5),
  },
  icon: {
    alignSelf: 'center',
    marginVertical: scale(10),
    paddingTop: scale(1),
  },
  badge: {
    borderRadius: 10,
    height: 12,
    width: 12,
    top: scale(4),
    right: scale(6),
    backgroundColor: colors.secondary,
    position: 'absolute',
  },
  badgeText: {
    fontSize: scale(10),
    lineHeight: lineHeight(10),
    color: colors.white,
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  image: {
    width: screenWidth,
    height: 140,
    //aspectRatio: aspectRatio['3x2'],
  },
  link: {
    textDecorationLine: 'underline',
    paddingRight: 20,
  },
  shelfGuideBannerContainer: {
    marginTop: -5,
    marginBottom: 32,
  },
  shelfGuideLinkContainer: {
    marginTop: -32,
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    height: '70%',
  },
  campaignCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxHeight: screenHeight * 0.38,
    height: 'auto',
    overflow: 'hidden',
  },
  campaignCardContainer: {
    height: screenHeight * 0.42,
    width: screenWidth * 0.5,
    flexDirection: 'row',
    overflow: 'hidden',
    paddingTop: 19,
    paddingLeft: 7,
    paddingRight: 7,
  },
});

export default SearchLandingScreen;

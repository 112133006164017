import { Button, CloseButton, DropShadow, NavigationHeader, Screen } from '@components';
import CheckBox from '@components/Checkbox';
import Text from '@components/Text';
import ItemizedOrder from '@components/cart/ItemizedOrder';
import colors from '@config/colors';
import { AddLineItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { OrderItem } from '@fieldera-raleys/client-common/types/order';
import { AppStackRoutes, CheckoutStackRoutes, OrderHistoryStackRoutes, OrderHistoryStackScreenProps } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useCartStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight } from '@styles/constants';
import logger from '@utils/logger';
import { getProductQuantityIndex } from '@utils/orderHelpers';
import { AvailablityResult, getProductAvailablity, getProductsfromCommerceTools } from '@utils/productHelper';
import { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

type ReorderScreenProps = OrderHistoryStackScreenProps<OrderHistoryStackRoutes.Reorder>;

type AvailabilityType = {
  product: AvailablityResult[];
  unavailableCount: number;
};

type CustomizationSkus = {
  [key: string]: string[];
};
type CustomizationsAvailibility = {
  [key: string]: AvailablityResult[];
};
type ProdDataType = {
  [key: string]: Product;
};

const ReorderScreen = ({ route }: ReorderScreenProps) => {
  const { orderDetails } = route.params;
  const [availability, setAvailability] = useState<AvailabilityType>({ product: [], unavailableCount: 0 });
  const [unavailableIndex, setUnavailableIndex] = useState<number[]>([]);
  const [checkedItems, setCheckedItems] = useState<OrderItem[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const { addStandardLineItems, addCustomizableLineItem } = useCartStore();
  const navigation = useNavigation();

  const [productData, setProductData] = useState<ProdDataType | undefined>();
  useEffect(() => {
    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      return await getProductsfromCommerceTools(skus);
    };
    if (orderDetails) {
      const skus: string[] = orderDetails.orderItems.reduce<string[]>((acc: string[], i: OrderItem) => {
        if (i.productListing.customProperties?.ExtProductId && acc.findIndex((o) => o === i.productListing.customProperties?.ExtProductId)) {
          acc.push(i.productListing.customProperties?.ExtProductId);
        }
        return acc;
      }, []);
      getProdSet(skus)
        .then((pd) => {
          const pData: ProdDataType = pd.reduce((acc, p: Product) => {
            acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
            p.masterData?.current?.variants.forEach((vi) => {
              acc[vi?.sku ?? ''] = p;
            });

            return acc;
          }, {} as ProdDataType);
          setProductData(pData);
        })
        .catch(() => {
          setProductData({});
        });
    }
  }, [orderDetails]);

  const handleSubmit = async () => {
    setIsButtonLoading(true);

    if (!checkedItems.length) {
      setIsButtonLoading(false);
      return;
    }

    // Add Items to the cart
    let standardItems: AddLineItem[] = checkedItems
      .filter((ci) => ci.orderItemSubItems?.length === 0)
      .filter((ci) => !ci.excludeForReorder)
      .map((ci) => ({
        sku: ci.productListing!.customProperties!.ExtProductId,
        quantity: productData?.[ci.productListing!.customProperties!.ExtProductId]?.masterData?.current
          ? getProductQuantityIndex(productData[ci.productListing!.customProperties!.ExtProductId].masterData.current!, ci.qty, ci.estimatedTotalWeight)
          : ci.qty,
        itemNote: ci.orderItemNote,
      }));

    if (standardItems.length) {
      await addStandardLineItems(standardItems);
    }

    if (checkedItems.some((ci) => !!ci.orderItemSubItems?.length)) {
      checkedItems
        .filter((ci) => ci.orderItemSubItems?.length !== 0)
        .filter((ci) => !ci.excludeForReorder)
        .forEach(async (ci) => {
          let customizableItems: AddLineItem[] = [];
          customizableItems.push({
            sku: ci.productListing!.customProperties!.ExtProductId,
            quantity: ci.qty,
            itemNote: ci.orderItemNote,
          });
          ci.orderItemSubItems?.forEach((oisi) => {
            let subItemCustomStep = oisi.productListing.value?.split('-')[0].trim().split(':').join('|');
            customizableItems.push({
              sku: oisi.extProductId,
              quantity: oisi.qty,
              itemNote: oisi.orderItemSubItemNote,
              parentLineItemId: ci.productListing!.customProperties!.ExtProductId,
              customStepSort: subItemCustomStep,
            });
          });

          await addCustomizableLineItem(customizableItems);
        });
    }

    // Navigate to Cart
    navigation.dispatch(StackActions.pop(1));
    navigation.navigate(AppStackRoutes.CartAndCheckout, {
      screen: CheckoutStackRoutes.Cart,
    });

    setIsButtonLoading(false);
  };

  const validateAvailability = useCallback(async () => {
    let customizationSkus: CustomizationSkus = {};
    let customizationAvailability: CustomizationsAvailibility = {};

    const skus = orderDetails.orderItems.map((oi) => {
      let oiSku = oi.productListing!.customProperties!.ExtProductId;
      // Check if item has customizations in it (for availability check)
      if (oi.orderItemSubItems) {
        customizationSkus[oiSku] = oi.orderItemSubItems.map((oisi) => {
          return oisi.extSubItemId;
        });
      }
      return oiSku;
    });

    const products = await getProductsfromCommerceTools(skus);
    const productAvailability = products.map((product) => {
      return getProductAvailablity(product.masterData);
    });

    for (const productSku in customizationSkus) {
      // Check customizations availability
      let customizations = await getProductsfromCommerceTools(customizationSkus[productSku]);
      let customizationsAvail = customizations.map((cust) => {
        return getProductAvailablity(cust.masterData);
      });
      customizationAvailability[productSku] = customizationsAvail;
    }
    logger.log(productAvailability);

    let unavailableNum = 0;
    const availabilityObj = productAvailability.map((avail, idx) => {
      let productSku = products[idx].masterData.current!.masterVariant!.sku;
      if (avail.availability === 'Unavailable' || avail.availability === 'OutOfStock' || avail.availability === 'Discontinued') {
        unavailableNum++;
      } else {
        if (productSku! in customizationAvailability) {
          // Additional availability validation for customization options
          for (const custAvail of customizationAvailability[productSku!]) {
            if (custAvail.availability === 'Unavailable' || custAvail.availability === 'OutOfStock' || custAvail.availability === 'Discontinued') {
              unavailableNum++;
              return { ...avail, productSku: productSku, availability: custAvail.availability };
            }
          }
        }
      }
      return { ...avail, productSku: productSku };
    }) as AvailablityResult[];

    let unavailableIdx: number[] = [];
    const filteredItems = orderDetails.orderItems.filter((oi, idx) => {
      let oiSku = oi.productListing.customProperties!.ExtProductId;
      let productAvailabilityInfo = availabilityObj.find((avail) => avail.productSku === oiSku);
      if (
        productAvailabilityInfo?.availability === 'Unavailable' ||
        productAvailabilityInfo?.availability === 'OutOfStock' ||
        productAvailabilityInfo?.availability === 'Discontinued'
      ) {
        unavailableIdx.push(idx);
      }
      return productAvailabilityInfo?.availability === 'Available';
    });

    setAvailability({ product: availabilityObj, unavailableCount: unavailableNum });
    setCheckedItems(filteredItems);
    setUnavailableIndex(unavailableIdx);
  }, [orderDetails.orderItems]);

  const handleToggle = useCallback(
    (oi: OrderItem) => {
      if (checkedItems.some((ci) => ci.orderItemId === oi.orderItemId)) {
        let filteredItems = checkedItems.filter((ci) => ci.orderItemId !== oi.orderItemId);
        setCheckedItems && setCheckedItems(filteredItems);
        return;
      }

      setCheckedItems && setCheckedItems([...checkedItems, oi]);
    },
    [checkedItems, setCheckedItems],
  );

  const handleToggleAll = useCallback(() => {
    // Toggle all items when not all items are checked
    if (orderDetails.orderItems.length - availability.unavailableCount !== checkedItems.length) {
      let uncheckedItems = orderDetails.orderItems.filter((oi, idx) => {
        // Exclude unavailable items
        if (unavailableIndex.includes(idx)) {
          return false;
        }

        return !checkedItems.some((ci) => {
          return ci.orderItemId === oi.orderItemId;
        });
      });

      setCheckedItems((prev) => [...prev, ...uncheckedItems]);
    } else {
      // Uncheck all the items
      setCheckedItems([]);
    }
  }, [availability.unavailableCount, checkedItems, orderDetails.orderItems, unavailableIndex]);

  useEffect(() => {
    validateAvailability();
  }, [validateAvailability]);

  return (
    <Screen style={styles.reorderModalStyle}>
      <NavigationHeader
        style={[styles.reorderHeaderStyle]}
        subStyle={styles.navigatorSubStyle}
        titleStyle={[appStyles.fontMobileH6, styles.titleStyle]}
        icon={<CloseButton />}
        title={'Reorder Items'}
      />
      <DropShadow>
        <View style={[styles.flexRow, styles.selectAllContainer]}>
          <View style={[styles.flexRow, styles.checkboxView]}>
            <CheckBox value={orderDetails.orderItems.length - availability.unavailableCount === checkedItems.length} onValueChange={() => handleToggleAll()} />
            <Text style={[appStyles.fontMobileBodySmallLeft]}>Select All</Text>
          </View>
          <Text style={[appStyles.fontMobileBodyLeftRegular, styles.selectAllText]}>You'll be able to edit any item after adding it to the cart</Text>
        </View>
      </DropShadow>
      <ScrollView>
        <ItemizedOrder order={orderDetails} reorder={true} checkedItems={checkedItems} setCheckedItems={handleToggle} availability={availability} />
      </ScrollView>
      <View style={styles.buttonContainer}>
        <Text>{checkedItems.length} item(s) selected</Text>
        <Button isButtonLoading={isButtonLoading} title="Add to Cart" type="primary" onPress={() => handleSubmit()} />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  reorderModalStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  reorderHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  navigatorSubStyle: {
    backgroundColor: colors.cream,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  titleStyle: {
    alignSelf: 'center',
    marginLeft: 20,
  },
  selectAllContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    backgroundColor: colors.sectionPad,
  },
  checkboxView: {
    marginLeft: 15,
    alignItems: 'center',
  },
  selectAllText: {
    flex: 0.9,
    lineHeight: lineHeight(16),
  },
  buttonContainer: {
    bottom: 0,
    borderTopWidth: 1,
    borderColor: '#707070',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
});

export default ReorderScreen;

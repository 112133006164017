import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import colors from '@config/colors';
import { appStyles } from '@styles';
import React from 'react';
import { ImageBackground, Platform, StyleSheet, View } from 'react-native';
import { Image } from '../image';
import Text from '../Text';

const MobileHeroComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { title, image: imageRef, subtitle, content, flyer: flyerRef, flyerAlt, ImageAlt } = document.getData();
  const image = imageRef && page.getContent<ImageSet>(imageRef);
  const flyer = flyerRef && page.getContent<ImageSet>(flyerRef);

  return (
    <View style={styles.container} testID="MobileHeroComponent">
      {image && (
        <ImageBackground
          accessibilityLabel={ImageAlt}
          source={{ cache: 'reload', uri: image?.getOriginal()?.getUrl() }}
          style={styles.image}
          resizeMode="contain">
          <View style={styles.textContanier}>
            <Text style={appStyles.eyebrowCenterWhite} testID="HeroComponentTitle">
              {title}
            </Text>
            <Text style={styles.h1CenterWhite} testID="HeroComponentSubtitle">
              {subtitle}
            </Text>
            <Text style={styles.h1CenterWhite} testID="HeroComponentContent">
              {content}
            </Text>
          </View>
        </ImageBackground>
      )}
      {flyer && (
        <View style={styles.flyerBox} testID="HeroComponentFlyer">
          <Image accessibilityLabel={flyerAlt} source={{ cache: 'web', uri: flyer?.getOriginal()?.getUrl() }} style={styles.flyer} resizeMode="contain" />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: colors.cream,
  },
  image: {
    height: 750,
    width: '100%',
  },
  flyerBox: {
    top: -20,
  },
  flyer: {
    width: '100%',
    height: 50,
  },
  textContanier: {
    position: 'absolute',
    top: 150,
    paddingHorizontal: 35,
    overflow: 'visible',
  },
  h1CenterWhite: {
    ...appStyles.h1CenterWhite,
    marginTop: 15,
  },
});

export default MobileHeroComponent;

import { Icon, LoadingScreen, Modal, NavigationHeader, Screen } from '@components';
import AddressItem from '@components/AddressItem';
import HeaderTitle from '@components/HeaderTitle';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address } from '@fieldera-raleys/client-common/types';
import { useRefetchOnFocus } from '@hooks';
import { AccountStackRoutes, AccountStackScreenProps } from '@navigation/routes';
import { userService } from '@services/brandywine';
import appStyles from '@styles/appStyles';
import { lineHeight, scale } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import logger from '@utils/logger';
import { queryClient } from '@utils/reactQuery';
import React, { useCallback, useRef, useState } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';

type AddressBookScreenProps = AccountStackScreenProps<AccountStackRoutes.AddressBook>;

const AddressBookScreen = ({ navigation }: AddressBookScreenProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { data: userAddresses, isLoading, refetch, isRefetching } = useQuery('address-list', async () => await userService.getAddressList());
  const addressRef = useRef<Address | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  useRefetchOnFocus(refetch);

  const handleRemove = useCallback((address: Address) => {
    addressRef.current = address;
    setShowDeleteModal(true);
  }, []);

  const handleEdit = (item: Address) => {
    navigation.navigate(AccountStackRoutes.AddEditAddress, { addressId: item.id, calledFrom: AccountStackRoutes.AddressBook });
  };

  const removeAddress = async (): Promise<void> => {
    if (addressRef.current && addressRef.current.id) {
      setIsDeleting(true);
      const addressId = addressRef.current.id;
      queryClient.executeMutation({
        mutationKey: ['address', addressRef.current.id],
        mutationFn: async () => userService.deleteAddress(addressId),
        onSuccess: async () => {
          await queryClient.invalidateQueries('address-list');
          await refetch();
        },
        onError: (e) => {
          logger.error(e);
        },
        onSettled: () => {
          setIsDeleting(false);
        },
      });
    }
    setShowDeleteModal(false);
  };

  const renderAddress = ({ item, index }: ListRenderItemInfo<Address>) => <AddressItem item={item} onEdit={handleEdit} onRemove={handleRemove} index={index} />;

  const EmptyList = useCallback(() => {
    return (
      <View style={[styles.emptyList, utilityStyles.py4]}>
        <Text style={[styles.description]} testID="addInfo">
          You have no saved addresses on your account.
        </Text>
      </View>
    );
  }, []);

  const ListFooter = useCallback(() => {
    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.footerContainer}
        onPress={() => {
          navigation.navigate(AccountStackRoutes.AddEditAddress, { calledFrom: AccountStackRoutes.AddressBook });
        }}
        testID={'addAddressClick'}>
        <Icon
          testID="circlePlusIcon"
          name={'circle-plus'}
          fill={colors.white}
          stroke={colors.red}
          strokeSecondary={colors.darkCream}
          size={35}
          style={{ width: 35, height: 35 }}
        />
        <Text style={styles.addText} testID={'addAddressText'}>
          Add Address
        </Text>
      </TouchableOpacity>
    );
  }, [navigation]);

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader
        // link={RootTabRoutes.Account}
        // linkParams={{ screen: AccountStackRoutes.AccountHome }}
        subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]}
        backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID="headerTitle">Address Book</HeaderTitle>
      </NavigationHeader>
      {isLoading || isDeleting ? (
        <LoadingScreen />
      ) : (
        <>
          <FlatList
            contentContainerStyle={[styles.container, utilityStyles.pt3, utilityStyles.px2]}
            data={userAddresses}
            testID={'address-book'}
            keyExtractor={(_, i) => i.toString()}
            renderItem={renderAddress}
            ListEmptyComponent={<EmptyList />}
            ListFooterComponent={<ListFooter />}
            onRefresh={refetch}
            refreshing={isRefetching}
          />
        </>
      )}
      {showDeleteModal && (
        <Modal
          visible={showDeleteModal}
          title={'Remove Address?'}
          location="top"
          cancelButtonOnPress={() => setShowDeleteModal(false)}
          okButtonOnPress={removeAddress}
          cancelButtonText="Go back"
          okButtonText="Remove">
          <Text style={[appStyles.bodyMediumLight, styles.modalContentText]} testID="removeMsg">
            Please confirm you'd like to remove this address from your Address Book.
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {},
  description: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    textAlign: 'center',
  },
  emptyList: {
    justifyContent: 'center',
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  addText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    color: colors.darkText,
    marginLeft: 8,
  },
  modalContentText: {
    textAlign: 'center',
  },
});

export default AddressBookScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useCancelToken } from '@hooks';
import { useOffersStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { FlatList, Platform, RefreshControl, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import LoadingScreen from '../LoadingScreen';
import OfferCard from './OfferCard';

interface OffersListProps {
  //TODO: offer type
  style?: StyleProp<ViewStyle>;
  offers?: any[] | undefined;
  currTab?: string;
}

const OffersList: FC<OffersListProps> = ({ currTab }): JSX.Element => {
  const { availableSomethingExtraOffers, acceptedSomethingExtraOffers, fetchSomethingExtraOffers, offersState } = useOffersStore();
  const allOffers = availableSomethingExtraOffers.concat(acceptedSomethingExtraOffers);
  const cancelToken = useCancelToken();

  const [currToggle, setCurrToggle] = useState(currTab ?? 'All');
  const toggleButtons = [
    {
      name: 'All',
    },
    {
      name: 'Clipped',
    },
  ];

  const refetchOffers = () => {
    fetchSomethingExtraOffers(cancelToken);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const EmptyListComponent = (): JSX.Element => {
    return allOffers ? (
      <View style={styles.noItemFoundView}>
        <View style={[styles.emptyListContainer]} testID="noSearchResults">
          <Text testID="noItemsFound" numberOfLines={2} style={[appStyles.bodySmallRegular, styles.emptyList]}>
            There are currently no SE Personalized Offers available.
          </Text>
        </View>
      </View>
    ) : (
      <></>
    );
  };

  return offersState === 'loading' ? (
    <LoadingScreen />
  ) : allOffers.length > 0 ? (
    <View style={[styles.scrollView]}>
      <View style={styles.offersWrapper}>
        <FlatList
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={
            <View style={styles.toggleBar}>
              <View style={styles.toggleWrapper}>
                {toggleButtons.map((tb) => {
                  return (
                    <TouchableOpacity
                      key={tb.name}
                      onPress={() => setCurrToggle(tb.name)}
                      style={[styles.toggle, { backgroundColor: currToggle === tb.name ? colors.white : colors.darkCream }]}>
                      <Text style={[styles.tabTitle, appStyles['bodySmall-50gray'], { opacity: 1, color: colors.darkText }]}>
                        {tb.name} {tb.name === 'Clipped' && acceptedSomethingExtraOffers.length > 0 ? `(${acceptedSomethingExtraOffers.length ?? 0})` : ''}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          }
          style={styles.listView}
          keyExtractor={(_, i) => i.toString()}
          data={currToggle === 'All' ? allOffers : acceptedSomethingExtraOffers}
          ListEmptyComponent={<EmptyListComponent />}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.cardView} key={`${item.ExtPromotionId}`}>
                <OfferCard item={item} index={index} key={index} />
              </View>
            );
          }}
          numColumns={2}
          refreshControl={<RefreshControl refreshing={false} onRefresh={refetchOffers} />}
        />
      </View>
    </View>
  ) : (
    <View style={styles.whitebox}>
      <Text style={[appStyles.bodyLargeCenter, { fontFamily: FontFamily.LarsseitBold, width: offersState !== 'error' ? '70%' : '85%' }]}>
        {offersState === 'error' ? "We're sorry, there seems to be a technical problem." : 'There are currently no SE Personalized Offers available.'}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollViewContentContainer: {
    flexGrow: 2,
    paddingBottom: Platform.select({
      ios: 20,
    }),
  },
  scrollView: {
    paddingTop: Platform.select({ ios: 50, android: 10 }),
  },
  toggleBar: {
    alignSelf: 'center',
    width: 240,
    height: 30,
    borderRadius: 5,
    backgroundColor: colors.darkCream,
    flexDirection: 'row',
    marginBottom: 30,
    borderColor: colors.darkCream,
    borderWidth: 1,
  },
  toggleWrapper: {
    flexDirection: 'row',
  },
  toggle: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  offersWrapper: {
    width: screenWidth,
    flexGrow: 1,
    minHeight: 100,
    marginTop: 16,
    paddingBottom: Platform.select({ android: 32 }),
    alignSelf: 'center',
    alignItems: 'center',
  },
  tabTitle: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
  },
  listView: { marginLeft: -12 },
  cardView: {
    marginBottom: 16,
    marginLeft: 12,
  },
  whitebox: {
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    flex: 1,
    width: screenWidth,
    height: '100%',
    alignSelf: 'center',
  },
  noItemFoundView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 45,
  },
  emptyList: {
    textAlign: 'center',
    width: '80%',
    lineHeight: lineHeight(25),
  },
});

export default OffersList;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import Barcode from './Barcode';

const UPCA_NUM_LENGTH = 11;

const AMSBarcode = () => {
  const { userProfile } = useUserProfileStore();
  const { t } = useTranslation('barcodeModal');

  const getBarcodeValue = () => {
    if (userProfile?.amsCardId) {
      return String(userProfile.amsCardId).padStart(UPCA_NUM_LENGTH, '0');
    }

    return '';
  };

  const isValid = userProfile?.amsCardId && getBarcodeValue().length === UPCA_NUM_LENGTH;

  return (
    <View style={[styles.barcodeContainerStyle]}>
      <View style={styles.barcodeImageView}>
        {isValid && (
          <Barcode value={getBarcodeValue()} text={userProfile?.loyaltyId ?? ''} height={70} maxWidth={screenWidth} textStyle={styles.barcodeTextStyle} />
        )}
      </View>
      <View style={styles.messageBodyView}>
        <Text style={[styles.headerText]}>{t('barcodeModalTitle')}</Text>
        <Text style={[appStyles.bodyMediumLight, styles.textCenter]}>{t('barcodeModalInstructions')}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  barcodeContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screenWidth,
  },
  barcodeImageView: {
    width: '100%',
    height: Platform.select({
      android: 100,
    }),
    marginTop: Platform.select({ ios: -20, android: -50 }),
  },
  headerText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(18),
    color: colors.text,
    textAlign: 'center',
    paddingBottom: 5,
    lineHeight: lineHeight(28),
    width: screenWidth * 0.68,
    marginTop: 10,
  },

  barcodeTextStyle: {
    margin: 20,
    color: colors.darkText,
  },
  barcodeContainerStyle: {
    paddingVertical: Platform.select({ android: scale(30), ios: scale(8) }),
    paddingHorizontal: 30,
    width: screenWidth,
    backgroundColor: colors.white,
  },
  textCenter: {
    textAlign: 'center',
  },
  messageBodyView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Platform.select({ android: scale(40) }),
  },
});

export default AMSBarcode;

import { BrManageContentButton } from '@bloomreach/react-sdk';
import { Component, Document, ImageSet, Page } from '@bloomreach/spa-sdk';
import SponsoredIndicator from '@components/SponsoredIndicator';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { aspectRatio, scale, screenHeight } from '@styles/constants';
import React from 'react';
import { Platform, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ResizeMode } from 'react-native-fast-image';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import colors from '../../../config/colors';
import { FontFamily } from '../../../config/fonts';
import { useCustomModalContext } from '../../../contexts';
import HtmlView from '../../HtmlView';
import { Button, Image, Text } from '../../index';
import { BannerDocument } from '../brxm';
import { brxmNavigation } from '../utils';
import styles from './style';

interface ImageContentBannerProps<T extends Component = Component> {
  component: T;
  page: Page;
  backgroundStyle?: StyleProp<ViewStyle>;
  mainContainerStyle?: StyleProp<ViewStyle>;
  imageContentSlideContainerStyle?: StyleProp<ViewStyle>;
  imageContainerStyle?: StyleProp<ViewStyle>;
  imageContentSlideContentStyle?: StyleProp<ViewStyle>;
  imageResizeMode?: ResizeMode;
  subtitleStyle?: StyleProp<TextStyle>;
  contentStyle?: StyleProp<TextStyle>;
  contentHtmlStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
}

const ImageContentBanner = ({
  component,
  page,
  mainContainerStyle,
  imageContentSlideContainerStyle,
  imageContainerStyle,
  imageContentSlideContentStyle,
  imageResizeMode,
  subtitleStyle,
  contentStyle,
  contentHtmlStyle,
  buttonStyle,
}: ImageContentBannerProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const navigation = useNavigation();
  const { showAlertModal } = useCustomModalContext();
  const linkto = useLinkTo();
  const insets = useSafeAreaInsets();

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.editContainer}>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { content, contentHtml, image: imageRef, ImageAlt, sponsored, title, linkText, subtitle, navigationType, url } = document.getData<BannerDocument>();

  const imageUrl = imageRef && page.getContent<ImageSet>(imageRef);
  const source = { html: contentHtml?.value };

  const width = imageUrl?.getOriginal() ? imageUrl?.getOriginal()?.getWidth() : undefined;
  const height = imageUrl?.getOriginal() ? imageUrl?.getOriginal()?.getHeight() : undefined;
  let ratio;
  if (width && height) {
    ratio = Math.round((width / height + Number.EPSILON) * 100) / 100;
  }

  const handleNavigation = (): void => {
    brxmNavigation(url, navigationType, navigation, linkto, showAlertModal);
  };

  return (
    <View style={[styles.mainContainer, mainContainerStyle, utilityStyles.my2]} testID="ImageContentSlide">
      <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      <View style={[styles.imageContentSlideContainer, imageContentSlideContainerStyle]} testID="ImageContentSlide">
        {imageUrl && (
          <View
            style={[
              styles.imageContainer,
              !(title || subtitle || content || contentHtml?.value) ? styles.imageContainerBorderBottom : null,
              imageContainerStyle,
            ]}>
            {linkText ? (
              <>
                <Image
                  accessibilityLabel={ImageAlt}
                  source={{ cache: 'web', uri: imageUrl?.getOriginal()?.getUrl() }}
                  style={[{ aspectRatio: ratio ? ratio : aspectRatio['5x4'] }]}
                  testID="ImageContentSlideImage"
                  resizeMode={imageResizeMode}
                />
                {sponsored ? <SponsoredIndicator /> : null}
              </>
            ) : (
              <TouchableOpacity activeOpacity={0.75} onPress={() => handleNavigation()}>
                <Image
                  accessibilityLabel={ImageAlt}
                  source={{ cache: 'web', uri: imageUrl?.getOriginal()?.getUrl() }}
                  style={[{ aspectRatio: ratio ? ratio : aspectRatio['5x4'] }]}
                  testID="ImageContentSlideImage"
                  resizeMode={imageResizeMode}
                />
                {sponsored ? <SponsoredIndicator /> : null}
              </TouchableOpacity>
            )}
          </View>
        )}
        {title || subtitle || content || contentHtml?.value ? (
          <View
            style={[
              styles.imageContentSlideContent,
              utilityStyles.px2,
              imageContentSlideContentStyle,
              {
                paddingBottom: screenHeight < 740 ? 10 + insets.bottom : insets.bottom,
              },
            ]}>
            {title ? (
              <Text style={[appStyles.eyebrowCenterRed, utilityStyles.my0]} testID="ImageContentSlideTitle">
                {title}
              </Text>
            ) : (
              <></>
            )}

            {subtitle ? (
              <Text style={[appStyles.h5, utilityStyles.my1, utilityStyles.px1, subtitleStyle, !title ? utilityStyles.my3 : null]}>{subtitle}</Text>
            ) : (
              <></>
            )}

            {content ? (
              <Text
                style={[appStyles.bodySmallLeftRegular, utilityStyles.my1, utilityStyles.pb2, utilityStyles.px1, contentStyle]}
                testID="ImageContentSlideDescription">
                {content}
              </Text>
            ) : (
              <></>
            )}

            {source ? (
              <View style={[contentHtmlStyle, { marginRight: 20, marginLeft: 20 }]}>
                <HtmlView
                  text={source.html ?? ''}
                  fontFamily={FontFamily.LarsseitLight}
                  textAlign={'center'}
                  lineHeight={25}
                  textSize={scale(16)}
                  textColor={colors.text}
                />
              </View>
            ) : (
              <></>
            )}

            {/* TODO:// replace title and action with brxm link and linkText */}
            {linkText ? (
              <Button
                type="secondary"
                size="small"
                title={linkText}
                onPress={() => handleNavigation()}
                textStyle={styles.linkTextStyle}
                buttonStyle={buttonStyle}
              />
            ) : (
              <></>
            )}
          </View>
        ) : null}
      </View>
    </View>
  );
};

export default ImageContentBanner;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { DropShadow, LoadingScreen, SearchHeader } from '@components';
import { mapping } from '@components/bloomreach';
import { ProductCarousel } from '@components/brsm';
import CartInfoBanner from '@components/CartInfoBanner';
import withTombstone from '@components/hoc/withTombstone';
import Icon, { IconName } from '@components/Icon';
import { ListData, ListItem } from '@components/lists';
import colors from '@config/colors';
import { useBrxmPage } from '@hooks';
import { AccountStackRoutes, AppStackRoutes, RewardsStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useAppConfigStore, useSearchStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type ShopScreenProps = ShopStackScreenProps<ShopStackRoutes.ShopLanding>;

const ShopScreen = withTombstone(({ navigation, route }: ShopScreenProps) => {
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [refreshing, setRefreshing] = useState(false);
  const insets = useSafeAreaInsets();
  const pageConfig = useAppConfigStore().getConfigGroup('Shop');
  const { t } = useTranslation('topDeals');
  const { resetFilters, selectFilter } = useSearchStore();
  const scrollViewRef = useRef<ScrollView>(null);
  const { reload } = route.params;
  const tabBarHeight = useBottomTabBarHeight();
  useEffect(() => {
    if (reload) {
      scrollViewRef.current?.scrollTo({ x: 0, y: 0 });
      navigation.setParams({ reload: false });
    }
  }, [navigation, reload]);

  const menuItems: ListData[] = [
    {
      id: 'featured',
      title: 'Featured',
      itemStyle: {
        ...styles.listItemContainer,
        ...utilityStyles.px2,
        backgroundColor: colors.sectionHeader,
      },
      titleStyle: { ...appStyles.bodySmallBold },
    },
    {
      id: 'weekly-ad',
      title: 'Weekly Ad',
      itemStyle: {
        ...styles.listItemContainer,
        ...utilityStyles.px2,
        backgroundColor: colors.cream,
      },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      imageUrl: 'weekly-ad',
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.WeeklyAds } });
      },
    },
    {
      id: 'my-favorites',
      title: 'My Lists',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      imageUrl: 'list-page',
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Account, params: { screen: AccountStackRoutes.ManageShoppingList } });
      },
    },
    {
      id: 'shop-by',
      title: 'Shop By',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.sectionHeader },
      titleStyle: { ...appStyles.bodySmallBold },
    },
    {
      id: 'categories',
      title: 'Categories',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      onPress: () => {
        resetFilters();
        navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } });
      },
      imageUrl: 'categories',
    },
    {
      id: 'deals',
      title: 'Products On Sale',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      onPress: () => {
        loadOnSaleProducts();
      },
      imageUrl: 'deals-icon',
    },
    {
      id: 'something-Extra-offers',
      title: 'Something Extra Offers',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
        });
      },
      imageUrl: 'se-offers',
    },
    {
      id: 'previously-purchased',
      title: 'Previously Purchased',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      onPress: () => {
        loadPreviouslyPurchasedProducts();
      },
      imageUrl: 'previously-purchased',
    },
    {
      id: 'shelf-guide',
      title: 'Shelf Guide',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      onPress: () => {
        resetFilters();
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.ShelfGuide } });
      },
      imageUrl: 'shelf-guide',
    },
    {
      id: 'collections-landing',
      title: 'Collections',
      itemStyle: { ...styles.listItemContainer, ...utilityStyles.px2, backgroundColor: colors.cream },
      iconProps: { name: 'arrow-right', style: appStyles.icon, size: 20 },
      onPress: () => {
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.CollectionsLanding } });
      },
      imageUrl: 'featured-collections',
    },
  ];

  const loadPreviouslyPurchasedProducts = () => {
    resetFilters();
    selectFilter('previouslyPurchased');

    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Shop,
      params: {
        screen: ShopStackRoutes.SearchLanding,
        params: {
          query: '*',
          tabName: 'purchased',
        },
      },
    });
  };

  const loadOnSaleProducts = () => {
    resetFilters();
    selectFilter('onSale');

    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Shop,
      params: {
        screen: ShopStackRoutes.SearchLanding,
        params: {
          query: '*',
          tabName: 'on-sale',
        },
      },
    });
  };

  const renderItem = useCallback((item: ListData, idx: number) => {
    return (
      <View key={`listItemView_${item.id}_${idx}`}>
        <ListItem
          testID={item.title}
          title={item.title}
          titleStyle={[item.titleStyle]}
          style={item.itemStyle}
          imageStyle={{ width: 40 }}
          key={item.id}
          iconProps={{ name: (item.imageUrl as IconName) ?? '', size: 40 }}
          onPress={item.onPress}>
          {item.iconProps && (
            <View key={`listItem_${item.id}_${idx}`} style={styles.iconContainer}>
              <Icon {...item.iconProps} />
            </View>
          )}
        </ListItem>
        <View style={[styles.separatorContainer]} key={`${idx}-separator`}>
          <View style={[styles.separatorStyle, { width: ['Featured', 'My Lists', 'Shop By'].includes(item.title) ? screenWidth : screenWidth * 0.92 }]} />
        </View>
      </View>
    );
  }, []);

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  return (
    <>
      <CartInfoBanner />
      <DropShadow style={styles.dropShadow}>
        <SearchHeader tabBarHeight={tabBarHeight} />
      </DropShadow>
      {!brxmPage || !brxmConfiguration ? (
        <LoadingScreen />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          <ScrollView
            ref={scrollViewRef}
            style={Platform.OS === 'web' ? {} : { marginBottom: 65 - insets.bottom }}
            nestedScrollEnabled={true}
            showsVerticalScrollIndicator={false}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
            <BrComponent path="hero" />
            <View style={[styles.separatorStyle]} />
            <View style={(styles.white, utilityStyles.px0, utilityStyles.mt0)}>{menuItems.map(renderItem)}</View>
            <View style={(styles.white, utilityStyles.my1)}>
              {pageConfig?.TopDeals && (
                <ProductCarousel
                  title="Top Deals for You"
                  widgetId={pageConfig.TopDeals}
                  widgetType={'TopDeals'}
                  contentContainerStyle={styles.contentContainer}
                  addCartButtonStyle={styles.addCartButtonStyle}
                  emptyListMsg={t('emptyListMsg')}
                />
              )}
            </View>
            <BrComponent path="main" />
            {pageConfig?.ItemsYouMayLike && (
              <ProductCarousel
                title="Items You Might Like"
                widgetId={pageConfig.ItemsYouMayLike}
                widgetType={'ItemsYouMayLike'}
                contentContainerStyle={styles.contentContainer}
                addCartButtonStyle={styles.addCartButtonStyle}
              />
            )}
            <View style={[{ backgroundColor: colors.white }, utilityStyles.my1]} />
            <BrComponent path="body" />
            <View style={[{ backgroundColor: colors.white }, utilityStyles.py3]} />
            <BrComponent path="bottom" />
          </ScrollView>
        </BrPage>
      )}
    </>
  );
}, {});

const styles = StyleSheet.create({
  darkSection: {
    backgroundColor: colors.white,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  white: {
    backgroundColor: colors.white,
  },
  cardContainer: {
    overflow: 'hidden',
    width: screenWidth,
    borderWidth: 0,
  },
  listItemContainer: {
    width: '100%',
    height: 60,
  },
  separatorStyle: {
    backgroundColor: colors.sectionBorder,
    height: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  separatorContainer: {
    height: 1.5,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  cartAddQty: {
    left: -10,
    height: 36,
    backgroundColor: colors.darkCream,
    width: '80%',
  },
  dropShadow: {
    width: screenWidth,
    justifyContent: 'center',
    backgroundColor: colors.cream,
  },
  addCartButtonStyle: {
    backgroundColor: colors.darkCream,
    maxWidth: 400,
  },
});

export default ShopScreen;

import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useShopStore } from '@store';
import { useCartStore } from '@store/cartStore';
import { appStyles } from '@styles';
import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from './Icon';
import Text from './Text';

const CartButton = () => {
  const navigation = useNavigation();
  const { selectedStore } = useShopStore();

  const handleClick = () => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: {
          store: undefined,
          storeNumber: selectedStore?.number ?? undefined,
        },
      }),
    );
  };
  //  TODO: read cart / store context to get qty

  const { getCartQuantity: getTotalQuantity } = useCartStore();
  const cartQuantity = getTotalQuantity();
  return (
    <TouchableOpacity onPress={handleClick} style={styles.button} hitSlop={{ bottom: 8, top: 8, left: 8, right: 8 }} testID="cartQuantityBtnClick">
      <Icon name="cart-icon" size={36} stroke={'#b43b3c'} style={styles.cart} testID="cartIcon" />
      <View style={styles.textWrapper}>
        <Text style={[appStyles.cartIconText, styles.text]} testID="cartQuantity">
          {cartQuantity > 99 ? '99*' : cartQuantity}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    margin: 8,
    flex: 1,
    flexDirection: 'row',
  },
  textWrapper: {
    position: 'absolute',
    width: 36, // same as icon size
    height: 36, // same as icon size
  },
  text: {
    padding: 8,
    marginTop: Platform.select({ android: -2, default: 2 }),
    marginLeft: 5,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  cart: {
    width: 36, // same as icon size
    height: 36, // same as icon size
  },
});

export default CartButton;

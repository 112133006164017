import { NavigationHeader, Screen } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import { IconProps } from '@components/Icon';
import { ListItemSeparator } from '@components/lists';
import ListItem from '@components/lists/ListItem';
import Switch from '@components/Switch';
import colors from '@config/colors';
import { useEffectOnce } from '@hooks';
import useAppState from '@hooks/useAppState';
import * as LocalAuthentication from '@hooks/useLocalAuthentication';
import * as Location from '@hooks/useLocation';
import { useDeviceInfoStore, useUserSettingsStore } from '@store';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { scale } from '@styles/constants';
import React, { useCallback, useState } from 'react';
import { FlatList, Linking, ListRenderItemInfo, StyleSheet, View } from 'react-native';

type MenuItem = {
  title: string;
  icon?: IconProps;
  target: string;
  visible: boolean;
  targetType: 'link' | 'screen' | 'function' | 'settings';
  data?: {} | number | boolean | string | undefined;
  clickHandler?: (setting: {} | number | boolean | string | undefined) => void;
  disabled?: boolean;
};

const AppSettingsScreen = () => {
  const { userSettings, updateSetting: updateUserSetting } = useUserSettingsStore();
  const { deviceInfo, getBiometricType } = useDeviceInfoStore();
  const [hasLocationPermission, setHasLocationPermission] = useState(false);
  const [isDeviceLocationEnabled, setIsDeviceLocationEnabled] = useState(false);

  // TODO: Uncomment for dark mode
  // const darkMode = useSettingsStore((state) => state.userSettings?.darkMode);
  // const mode = useColorScheme();
  // const isDarkMode = darkMode ? darkMode : mode === 'dark' ? true : false;

  useAppState({
    onForeground: async () => {
      const locationPermission = await Location.hasPermissions();
      setHasLocationPermission(locationPermission);
    },
  });

  useEffectOnce(() => {
    (async () => {
      const deviceLocationEnabled = await Location.isDeviceLocationEnabled();
      setIsDeviceLocationEnabled(deviceLocationEnabled);
      const locationPermission = await Location.hasPermissions();
      setHasLocationPermission(locationPermission);
    })();
  });

  const locationServicesHandler = async () => {
    if (hasLocationPermission) {
      Linking.openSettings();
      return;
    }

    const canRequest = await Location.canRequestPermissions();
    if (canRequest) {
      const response = await Location.requestPermissions();
      setHasLocationPermission(response);
    } else {
      if (isDeviceLocationEnabled) {
        Linking.openSettings();
      } else {
        Linking.openURL('App-Prefs:Privacy&path=LOCATION');
      }
    }
  };

  const menuItems: MenuItem[] = [
    {
      title: 'Location Services',
      data: hasLocationPermission,
      target: '',
      targetType: 'function',
      clickHandler: locationServicesHandler,
      visible: true,
    },
    {
      title: `Use ${getBiometricType()}`,
      target: '',
      clickHandler: async () => {
        if (!userSettings?.localAuthentication) {
          const result = await LocalAuthentication.authenticateAsync({
            promptMessage: 'Please authenticate',
            disableDeviceFallback: true,
            cancelLabel: 'Cancel',
          });
          if (result.success) {
            updateUserSetting('localAuthentication', true);
            return;
          }
        }
        updateUserSetting('localAuthentication', false);
      },
      targetType: 'function',
      data: userSettings?.localAuthentication ?? false,
      visible: deviceInfo?.supportsBiometrics ?? false,
    },
    // {
    //   title: 'Dark Mode',
    //   data: false,
    //   target: '',
    //   targetType: 'function',
    //   clickHandler: () => {
    //     return;
    //   },
    //   visible: true,
    //   disabled: true,
    // },
    // TODO: Uncomment when dark mode is ready
    // {
    //   title: 'Dark Mode',
    //   data: isDarkMode,
    //   target: '',
    //   targetType: 'function',
    //   clickHandler: () => {
    //     updateUserSetting('darkMode', !darkMode);
    //   },
    //   visible: true,
    // },
  ];

  const renderItem = useCallback(({ item }: ListRenderItemInfo<MenuItem>) => {
    return item.visible ? (
      <View style={styles.listItemView}>
        <ListItem
          testID={`listItem-${item.title}`}
          title={item.title}
          iconProps={item.icon}
          style={item.disabled ? styles.listGrayMargin : styles.listMargin}
          titleStyle={{ color: item.disabled ? 'gray' : colors.primary }}>
          <Switch
            testID={`switchItem-${item.title}`}
            disabled={item.disabled}
            value={typeof item.data === 'boolean' ? item.data : false}
            onValueChange={item.clickHandler}
          />
        </ListItem>
      </View>
    ) : null;
  }, []);

  return (
    <Screen>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID="appSettingsTitle">App Settings</HeaderTitle>
      </NavigationHeader>
      <View style={appStyles.container}>
        <FlatList
          testID="appSettingFlatList"
          data={menuItems}
          keyExtractor={(menuItem) => menuItem.title}
          ItemSeparatorComponent={ListItemSeparator}
          bounces={false}
          contentContainerStyle={styles.cardContainer}
          renderItem={renderItem}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  header: { paddingHorizontal: 15 },
  cardContainer: {
    overflow: 'hidden',
  },
  listMargin: {
    marginLeft: -10,
    ...appStyles.bodySmallLeftRegular,
  },
  listGrayMargin: {
    ...appStyles.bodySmallLeftRegular,
    marginLeft: -10,
  },
  listItemView: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
});

export default AppSettingsScreen;

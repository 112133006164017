import Text from '@components/Text';
import colors from '@config/colors';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

export type AlertMessageProps = {
  message: string | JSX.Element;
  linkText?: string;
  linkAction?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  ellipsizeMode?: 'tail' | 'head' | 'middle' | 'clip' | undefined;
};

const AlertMessage: FC<AlertMessageProps> = ({ message, linkText = '', linkAction, containerStyle, textStyle, ellipsizeMode = 'tail' }) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <Text
        numberOfLines={1}
        ellipsizeMode={ellipsizeMode}
        style={[appStyles.actionNotice, textStyle, linkText ? styles.textWidth : styles.textWidth100]}
        testID="message">
        {message}
      </Text>
      <TouchableOpacity
        testID="linkClick"
        style={styles.link}
        onPress={() => {
          linkAction && linkAction();
        }}>
        <Text style={[appStyles.actionNoticeLink]} testID="linkText">
          {linkText}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 18,
    paddingHorizontal: 16,
    width: screenWidth,
    backgroundColor: colors.alertBoxGreen,
    // height: 50,
    zIndex: 900,
  },
  textWidth: {
    width: '80%',
  },
  textWidth100: {
    width: '100%',
  },
  link: {
    width: '20%',
    alignItems: 'center',
  },
});

export default AlertMessage;

import { BottomBar, Button, Checkbox, CloseButton, Icon, NavigationHeader, RadioButton, Screen, Switch } from '@components';
import Text from '@components/Text';
import { AccordionListItem, List } from '@components/lists';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Filter, FilterItem } from '@fieldera-raleys/client-common/types/common';
import { useEffectOnce } from '@hooks';
import { AppStackRoutes, AppStackScreenProps } from '@navigation/routes';
import { useIsFocused } from '@react-navigation/native';
import { useSearchStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { aspectRatio, scale, screenHeight, screenWidth } from '@styles/constants';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Image, LayoutChangeEvent, ListRenderItemInfo, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

const defaultSortOptions: FilterItem[] = [
  {
    id: 'bestMatch',
    default: true,
    selected: true,
    title: 'Relevance',
    controlType: 'radio',
    value: '',
  },
  {
    id: 'bestSeller',
    default: false,
    selected: false,
    title: 'Best Seller',
    controlType: 'radio',
    value: '',
  },
  {
    id: 'priceHighToLow',
    default: false,
    selected: false,
    title: 'Price: High to Low',
    controlType: 'radio',
    value: 'sale_price desc',
  },
  {
    id: 'priceLowToHigh',
    default: false,
    selected: false,
    title: 'Price: Low to High',
    controlType: 'radio',
    value: 'sale_price asc',
  },
  {
    id: 'alphabetical',
    default: false,
    selected: false,
    title: 'Name',
    controlType: 'radio',
    value: 'title asc',
  },
];

const SortOptions = () => {
  const { setSortQuery, sortQuery, setSearchType, searchType } = useSearchStore();
  const [sortOptions, setSortOptions] = useState([...defaultSortOptions]);

  useEffectOnce(() => {
    const updatedOptions = sortOptions.map((x) => {
      x.selected = x.id === 'bestSeller' ? (searchType === 'bestseller' ? true : false) : searchType !== 'bestseller' && x.value === sortQuery;
      return x;
    });
    setSortOptions(updatedOptions);
  });

  const onPress = (id: string) => {
    setSortQuery(sortOptions.find((x) => x.id === id)?.value as string);
    setSearchType(id === 'bestSeller' ? 'bestseller' : 'keyword');
    const updatedOptions = sortOptions.map((x) => {
      x.selected = x.id === id;
      return x;
    });
    setSortOptions(updatedOptions);
  };

  return (
    <AccordionListItem
      title={<Text style={[appStyles.h5, { width: '80%' }]}>Sort</Text>}
      style={styles.sortHeader}
      expanded={true}
      itemContainerStyle={{ paddingHorizontal: 16 }}>
      {sortOptions.map((o, idx) => (
        <RadioButton text={o.title} value={o.selected} toggle={onPress} id={o.id} key={o.id} showLastDivider={idx !== sortOptions.length - 1} />
      ))}
    </AccordionListItem>
  );
};

const SelectedFiltersHorizontalList = () => {
  const { getSelectedFilters, selectFilter } = useSearchStore();
  const renderHorizontalListItem = useCallback(
    (selectedItem: Filter) => {
      return (
        <View style={{ flexDirection: 'row' }}>
          {selectedItem.children.map((opt, idx) => (
            <TouchableOpacity style={[styles.selectedFilterView]} key={idx} onPress={() => selectFilter(opt.id, false)} testID={`horizontalItem-${opt.id}`}>
              <Icon testID="closeIcon" name="x-close" size={10} style={[appStyles.filterPills2Close, styles.alignTextCenter]} />
              <Text testID={`selectedFilter-${idx}`} style={[appStyles.filterPills2, styles.horizantalListLineHeight]}>
                {opt.title}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      );
    },
    [selectFilter],
  );

  return getSelectedFilters() ? (
    <View style={[styles.horizantalListView]} testID="horizontalList">
      <FlatList
        data={getSelectedFilters()}
        contentContainerStyle={styles.paddingRightListItem}
        horizontal
        bounces={false}
        renderItem={({ item }) => renderHorizontalListItem(item)}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(_item, idx) => idx.toString()}
      />
    </View>
  ) : null;
};

type SearchFilterScreenProps = AppStackScreenProps<AppStackRoutes.SearchFilter>;

const SearchFilterScreen = ({ route, navigation }: SearchFilterScreenProps) => {
  const { title, calledFrom } = route?.params ?? {};
  const [scrollViewBottomPadding, setScrollViewBottomPadding] = useState<number>(0);
  const { getSelectedFilters, availableFilters, resetFilters, selectFilter } = useSearchStore();
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);

  const isFocused = useIsFocused();

  const getFiltersToDisplay = () => {
    const catIds = selectedFilters.flatMap((sf: Filter) => {
      if (sf.id === 'category') {
        return sf.children.map((sfc: FilterItem) => sfc.id);
      } else {
        return '';
      }
    });

    let afs = _.cloneDeep<Filter[]>(availableFilters);
    let categories = afs.find((af) => af.id === 'category');

    if (categories) {
      const childrens =
        catIds.length === 0
          ? categories.children.filter((afc) => afc.parentId === undefined)
          : categories.children.filter((afc) => catIds.includes(afc.id) || catIds.includes(afc.parentId ?? ''));
      categories = { ...categories, children: childrens };

      const idx = afs.findIndex((af) => af.id === 'category');
      afs[idx] = categories;
    }

    if (calledFrom === 'WeeklyAds') {
      return afs.filter((f) => {
        return f.id !== 'loyalty_promo_ids';
      });
    } else {
      return afs;
    }
  };

  useEffect(() => {
    if (isFocused) {
      setSelectedFilters(getSelectedFilters());
    }
  }, [isFocused, getSelectedFilters]);

  const onBottomBarLayout = (le: LayoutChangeEvent) => {
    setScrollViewBottomPadding(le.nativeEvent.layout.height);
  };

  const applyOnClick = () => {
    navigation.goBack();
  };

  const resetOnClick = () => {
    setSelectedFilters([]);
    resetFilters();
  };

  const closeOnClick = () => {
    resetFilters();

    selectedFilters.map((sf) => {
      sf.children.map((f) => {
        selectFilter(f.id);
      });
    });

    setSelectedFilters([]);
    navigation.goBack();
  };

  const renderFilters = ({ item, index }: ListRenderItemInfo<Filter>) => {
    const collapsible = !!item.title;

    return collapsible ? (
      <AccordionListItem
        title={<Text style={[appStyles.fontMobileListSmallLeft, { width: '80%' }]}>{item.title}</Text>}
        expanded={true}
        itemContainerStyle={{ paddingHorizontal: 0 }}
        key={item.id}
        style={{ paddingHorizontal: 0, borderColor: colors.sectionBorder, borderBottomWidth: index < availableFilters.length - 1 ? 1 : 0 }}>
        {item.children.map((opt, idx) => (
          <View key={opt.id} style={[styles.filterItemWrapper, styles.checkboxItemWrapper, { borderBottomWidth: idx < item.children.length - 1 ? 1 : 0 }]}>
            <View style={[styles.checkboxLeftView]}>
              {item.imageUrl && (
                <View style={[styles.squareImage]}>
                  <Image source={{ uri: item.imageUrl }} style={styles.image} />
                </View>
              )}
              <Text style={[appStyles.fontMobileSmallLeftRegular, utilityStyles.pl1]}>{opt.title}</Text>
            </View>
            <Checkbox
              testID={`filterOption-${idx}`}
              value={opt.selected}
              onValueChange={(selected) => {
                selectFilter(opt.id, selected);
              }}
              key={opt.id}
            />
          </View>
        ))}
      </AccordionListItem>
    ) : (
      <>
        {item.children.map((opt, idx) => (
          <View key={opt.id} style={[styles.filterItemWrapper, styles.subViewStyle, { flex: 1, right: 0, borderBottomWidth: 1 }]}>
            <View style={styles.switchLabel}>
              <Text testID={`filterOptionTitle-${idx}`} style={[appStyles.fontMobileListSmallLeft]}>
                {opt.title}
              </Text>
            </View>
            <Switch
              key={opt.id}
              value={opt.selected}
              onValueChange={(selected) => {
                selectFilter(opt.id, selected);
              }}
              style={styles.switch}
              testID={`switchItem-${opt.id}`}
            />
          </View>
        ))}
      </>
    );
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container, ...styles.screenBackground }}>
        <NavigationHeader
          style={[styles.chekoutHeaderStyle]}
          subStyle={{ backgroundColor: colors.cream }}
          titleStyle={appStyles.fontMobileH6}
          title={title || 'Filter & Sort'}
          icon={<CloseButton size={20} onPress={closeOnClick} />}
        />
        <>
          <View testID="sort" style={{ width: screenWidth }}>
            <SortOptions />
          </View>
          <View style={[styles.filterView]} testID="filters">
            <View style={styles.filterTextView}>
              <Text style={[appStyles.h5, { paddingTop: scale(5) }]}>Filters </Text>
            </View>
            <SelectedFiltersHorizontalList />
          </View>
          <List
            contentContainerStyle={[styles.listContainer, { paddingBottom: scrollViewBottomPadding }]}
            showsVerticalScrollIndicator={false}
            testID="filterScrollView"
            data={getFiltersToDisplay()}
            renderItem={renderFilters}
            isLoading={false}
            bounces={false}
          />
          <BottomBar onLayout={onBottomBarLayout}>
            <TouchableOpacity activeOpacity={0.7} onPress={resetOnClick} testID="clearFilterButton">
              <Text testID="resetText" style={[appStyles.smallLink, styles.resetLink]}>
                Reset
              </Text>
            </TouchableOpacity>
            <Button
              testID="applyButton"
              size={'small'}
              buttonStyle={[appStyles.primaryButtonSmall, styles.button]}
              textStyle={[appStyles.primaryButtonSmallText]}
              onPress={applyOnClick}
              title={'Apply'}
            />
          </BottomBar>
        </>
      </Animated.View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: '#F9F2ED',
  },
  container: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 8,
  },
  filterItemWrapper: {
    paddingVertical: 5,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    height: scale(58),
  },
  checkboxItemWrapper: {
    borderColor: colors.navBorder,
  },
  sortHeader: {
    borderColor: colors.sectionBorder,
    backgroundColor: colors.white,
  },
  topLevelText: {
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(16),
    color: colors.darkText,
  },
  subFilterContainer: {
    flexGrow: 1,
  },
  chekoutHeaderStyle: {
    paddingLeft: screenWidth * 0.01,
  },
  subViewStyle: {},
  selectedFilterView: {
    flexDirection: 'row',
    borderWidth: 0.5,
    height: Platform.OS === 'ios' ? 25 : 28,
    borderRadius: 5,
    backgroundColor: colors.darkCream,
    borderColor: colors.darkCream,
    padding: 2,
    marginBottom: 14,
    marginLeft: 10,
    paddingRight: 8,
  },
  horizantalListText: {
    fontFamily: FontFamily.LarsseitLight,
    marginLeft: 3,
    marginRight: 3,
  },
  horizantalListIcon: {
    width: 18,
    height: 18,
    marginLeft: 3,
    marginTop: Platform.OS === 'ios' ? 2 : 3,
  },
  paddingRightListItem: { paddingRight: 35 },
  button: {
    minWidth: '45%',
  },
  buttonText: {
    height: scale(40),
  },
  resetLink: {
    color: colors.darkText,
    fontFamily: FontFamily.AleoBold,
  },
  sizeItemContainer: {
    height: screenHeight * 0.1,
    flexDirection: 'row',
    marginBottom: 5,
    justifyContent: 'space-around',
  },
  sizeInfoTextWrapper: {
    marginLeft: 5,
    marginTop: 5,
  },
  itemSizeText: {
    fontSize: scale(18),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    lineHeight: 25,
  },
  checkButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
  },
  switch: {
    right: 0,
    flex: 1,
  },
  switchLabel: {
    flex: 3,
    borderWidth: 0,
    justifyContent: 'center',
  },
  accordianArrow: {
    marginTop: 10,
    marginRight: 14,
  },
  filterView: {
    width: screenWidth,
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderColor: colors.sectionBorder,
    borderBottomWidth: 1,
  },
  filterTextView: {
    justifyContent: 'space-between',
    color: colors.darkText,
    backgroundColor: 'white',
    paddingLeft: screenWidth * 0.04,
    paddingRight: screenWidth * 0.04,
    paddingVertical: 12,
  },
  horizantalListView: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
  },
  checkboxLeftView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  squareImage: {
    width: 50,
    aspectRatio: aspectRatio.square,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '70%',
    width: '70%',
  },
  alignTextCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  horizantalListLineHeight: {
    lineHeight: 21,
  },
});

export default SearchFilterScreen;

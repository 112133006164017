import { HeaderTitle, Modal, NavigationHeader, Screen, Switch, Text } from '@components';
import { ListItem } from '@components/lists';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { SectionListItem } from '@fieldera-raleys/client-common';
import { useEffectOnce } from '@hooks';
import * as Notifications from '@hooks/useNotifications';
import { useDeviceInfoStore, useUserSettingsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight } from '@styles/constants';
import React, { useState } from 'react';
import { Linking, Platform, RefreshControl, SectionList, SectionListData, SectionListRenderItemInfo, StyleSheet } from 'react-native';
import { Item } from 'urbanairship-preference-center-react-native/lib/typescript/PreferenceCenter';
import { UrbanAirship } from 'urbanairship-react-native';

const CommunicationPreferencesScreen = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { userSettings } = useUserSettingsStore();
  const { deviceInfo, updateSetting: updateDeviceInfoSetting } = useDeviceInfoStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [activeChannelSubscriptions, setActiveChannelSubscriptions] = useState<string[]>([]);
  const [preferenceCenterData, setPreferenceCenterData] = useState<SectionListData<Item, SectionListItem<Item>>[]>([]);

  useEffectOnce(() => {
    const init = async () => {
      await loadSubscriptionLists();
      await loadPreferenceCenterConfig();
    };

    setIsFetching(true);
    init();
  });

  const loadSubscriptionLists = async () => {
    const subscriptionLists = await UrbanAirship.getSubscriptionLists(['channel']);
    setActiveChannelSubscriptions(subscriptionLists.channel ?? []);
  };

  const loadPreferenceCenterConfig = async () => {
    const config = await Notifications.fetchPreferenceCenterConfig();
    setPreferenceCenterData(config ?? []);

    setIsFetching(false);
  };

  const enableAllNotifications = async () => {
    if (!deviceInfo.hasNotificationPermissions) {
      const canRequestPermissions = await Notifications.canRequestPermissions();
      if (canRequestPermissions) {
        const permissions = await Notifications.requestPermissions();
        Notifications.enableUserNotifications(permissions);
        permissions && Notifications.subscribeAll('allowAllNotifications');
        updateDeviceInfoSetting('hasNotificationPermissions', permissions);
      } else {
        Linking.openSettings();
      }
    } else {
      Notifications.enableUserNotifications(true);
      Notifications.subscribeAll('allowAllNotifications');
    }
  };

  const onPreferenceChannelItemToggled = (subscriptionId: string, subscribe: boolean) => {
    var editor = UrbanAirship.editChannelSubscriptionLists();
    var updatedArray = activeChannelSubscriptions;
    if (subscribe) {
      editor.subscribe(subscriptionId);
      updatedArray = updatedArray.concat(subscriptionId);
    } else {
      editor.unsubscribe(subscriptionId);
      updatedArray = updatedArray.filter((item) => item !== subscriptionId);
    }
    editor.apply();
    setActiveChannelSubscriptions(updatedArray);
  };

  const toggleNotification = async (_sectionId: string, subscribe: boolean) => {
    if (subscribe && !deviceInfo.hasNotificationPermissions) {
      setShowModal(true);
    } else if (subscribe) {
      await Notifications.enableUserNotifications(true);
    } else {
      await Notifications.enableUserNotifications(false);
    }
    await loadSubscriptionLists();
  };

  const enableButtonClick = () => {
    enableAllNotifications();
    setShowModal(false);
  };

  const cancelOnPress = () => {
    setShowModal(false);
  };

  const onRefresh = () => {
    setIsFetching(true);
    loadPreferenceCenterConfig();
  };

  const isSubscribedChannelSubscription = (subscriptionId: string) => {
    if (activeChannelSubscriptions != null) {
      return activeChannelSubscriptions.includes(subscriptionId);
    }
    return false;
  };

  const renderItem = ({ item, index }: SectionListRenderItemInfo<Item, SectionListItem<Item>>) => {
    if (item.type === 'channel_subscription' || item.type === 'contact_subscription') {
      switch (item.subscription_id) {
        case 'marketing_sales_and_promotions':
          item.display.name = 'Marketing Updates';
          item.display.description = 'Receive push notifications about offers, sales and more.';

          break;
        case 'customer_order_transactions':
          item.display.name = 'Order Updates';
          item.display.description = 'Receive push notifications about your pickup and delivery orders.';
          break;
        default:
          break;
      }
      const disabled = !(userSettings?.notificationsEnabled ?? false);
      return (
        <ListItem
          testID={`listItem-${index}`}
          title={item.display.name}
          subTitle={item.display.description}
          style={[disabled ? styles.listGrayMargin : styles.listMargin, utilityStyles.ml3, { borderTopWidth: 1, borderColor: colors.navBorder }]}
          titleStyle={[appStyles.bodySmallLeft, { color: disabled ? 'gray' : colors.primary }]}
          subTitleStyle={[styles.subTitleStyle, utilityStyles.mt2]}
          subTextNumberOfLines={0}>
          <Switch
            testID={`switchItem-${index}`}
            disabled={disabled}
            value={isSubscribedChannelSubscription(item.subscription_id)}
            onValueChange={(value) => {
              onPreferenceChannelItemToggled(item.subscription_id, value);
            }}
          />
        </ListItem>
      );
    } else {
      return <></>;
    }
  };

  const renderSectionHeader = ({ section }: { section: SectionListData<Item, SectionListItem<Item>> }) => (
    <ListItem
      testID={`listItem-${section.id}`}
      title={section.name}
      subTitle={section.description}
      style={styles.listMargin}
      titleStyle={appStyles.bodySmallLeft}
      subTitleStyle={[styles.subTitleStyle, utilityStyles.mt2]}
      subTextNumberOfLines={5}>
      <Switch testID={`switchItem-${section.id}`} value={userSettings?.notificationsEnabled} onValueChange={(value) => toggleNotification(section.id, value)} />
    </ListItem>
  );

  return (
    <Screen>
      <NavigationHeader subStyle={[styles.navigationHeader, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle style={{ fontSize: scale(26) }} testID="communicationPreferenceTitle">
          Communication Preferences
        </HeaderTitle>
      </NavigationHeader>
      <SectionList
        sections={preferenceCenterData}
        testID="communicationPreferencesFlatList"
        scrollEnabled={true}
        renderItem={renderItem}
        refreshControl={<RefreshControl refreshing={isFetching} onRefresh={onRefresh} />}
        contentContainerStyle={[styles.container, utilityStyles.mt2, utilityStyles.px3]}
        renderSectionHeader={renderSectionHeader}
      />
      {showModal && (
        <Modal
          visible={showModal}
          title="Allow Notifications"
          cancelButtonOnPress={cancelOnPress}
          cancelButtonType="secondary"
          cancelButtonText="Back"
          okButtonText="Enable"
          okButtonOnPress={enableButtonClick}
          location="top"
          style={{
            height:
              Platform.OS === 'ios'
                ? screenHeight > 740
                  ? screenHeight * 0.32
                  : screenHeight * 0.36
                : screenHeight > 640
                ? screenHeight * 0.3
                : screenHeight * 0.4,
          }}
          titleStyle={utilityStyles.pb0}
          subTextViewStyle={styles.subTextStyle}>
          <Text testID="allowNotificationText" style={styles.textInfo}>
            To receive notifications, please enable the settings on your device.
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  navigationHeader: {
    alignItems: 'flex-start',
    FontFamily: FontFamily.AleoBold,
    lineHeight: scale(32),
  },
  subTitleStyle: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'left',
  },
  listMargin: {},
  listGrayMargin: {},
  container: {},
  textInfo: {
    fontSize: scale(18),
    color: colors.primary,
    textAlign: 'center',
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitLight,
  },
  subTextStyle: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
});

export default CommunicationPreferencesScreen;

type Typography =
  | 'Title'
  | 'ParagraphText'
  | 'Links'
  | 'SecondaryText'
  | 'TertiaryText'
  | 'Captions'
  | 'SegmentedButtons'
  | 'FormButtons'
  | 'FormTextInputs'
  | 'TabBarText';

export const fontSize: { [key in Typography]: number } = {
  Title: 34,
  ParagraphText: 17,
  Links: 17,
  SecondaryText: 15,
  TertiaryText: 13,
  Captions: 13,
  SegmentedButtons: 13,
  FormButtons: 17,
  FormTextInputs: 17,
  TabBarText: 10,
};

import { BrComponent, BrPage } from '@bloomreach/react-sdk';

import { Button, LinkButton, LoadingScreen, Screen, Text } from '@components';
import { mapping } from '@components/bloomreach';
import { FontFamily } from '@config/fonts';
import { useBrxmPage, useEffectOnce } from '@hooks';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useUserSettingsStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';

type AppBenefitsScreenProp = AuthStackScreenProps<AuthStackRoutes.AppBenefits>;

const AppBenefitsScreen = ({ navigation }: AppBenefitsScreenProp) => {
  const { t } = useTranslation('appBenefits');
  const { brxmConfiguration, brxmPage } = useBrxmPage();
  const { updateSetting: updateUserSetting } = useUserSettingsStore();

  const handleSignIn = async () => {
    await updateUserSetting('showAppBenefits', false);
    navigation.navigate(AuthStackRoutes.SignIn);
  };

  const {
    stop, // a function  to stopping it
  } = useTourGuideController();

  useEffectOnce(() => {
    stop();
  });

  return !brxmPage || !brxmConfiguration ? (
    <LoadingScreen />
  ) : (
    // @ts-ignore
    <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
      <Screen testID="appBenefitsScreen">
        <View style={styles.brxmContainer} testID={'brComonent'}>
          <BrComponent path="main" />
        </View>
        <View style={[styles.buttonContainer]}>
          <Button title={t('createAccountButton')} onPress={() => navigation.navigate(AuthStackRoutes.CreateAccountInitial, {})} testID="createAccountBtn" />
          <Text style={[appStyles.bodySmall, appStyles.mt20]} testID={'signInText'}>
            {t('signInText')}
          </Text>
          <LinkButton onPress={handleSignIn} style={[appStyles.boldLinkLargeCenterB]} testID={'signInLink'}>
            {t('signInLink')}
          </LinkButton>
        </View>
      </Screen>
    </BrPage>
  );
};
const styles = StyleSheet.create({
  brxmContainer: {
    height: '75%',
  },
  buttonContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 15,
  },
  textInfo: {
    fontSize: scale(18),
    textAlign: 'center',
    lineHeight: lineHeight(18),
    marginBottom: 45,
    fontFamily: FontFamily.LarsseitLight,
    width: screenWidth * 0.8,
    alignSelf: 'center',
  },
});

export default AppBenefitsScreen;

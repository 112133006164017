import { createStackNavigator, StackCardInterpolationProps } from '@react-navigation/stack';
import { OrderDetailScreen, OrderHistoryScreen, PurchaseFilterScreen } from '@screens/account';
import ReorderScreen from '@screens/account/orderHistory/ReorderScreen';
import React from 'react';
import { OrderHistoryStackParamList, OrderHistoryStackRoutes } from './routes';

const OrderHistoryNavigator = () => {
  const OrderHistoryStack = createStackNavigator<OrderHistoryStackParamList>();

  const verticalTransition = {
    cardStyleInterpolator: ({ current, layouts }: StackCardInterpolationProps) => {
      return {
        cardStyle: {
          transform: [
            {
              translateY: current.progress.interpolate({
                inputRange: [0, 1],
                outputRange: [-layouts.screen.height, 0],
              }),
            },
          ],
        },
      };
    },
  };

  return (
    <OrderHistoryStack.Navigator
      initialRouteName={OrderHistoryStackRoutes.RecentOrders}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.RecentOrders} component={OrderHistoryScreen} />
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.OrderDetails} component={OrderDetailScreen} />
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.PurchaseFilter} component={PurchaseFilterScreen} options={verticalTransition} />
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.Reorder} component={ReorderScreen} options={{ presentation: 'modal' }} />
    </OrderHistoryStack.Navigator>
  );
};

export default OrderHistoryNavigator;

import { TYPE_CONTAINER_BOX, TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';
import Banner from './Banner';
import CategoryComponent from './CategoryComponent';
import CategoryCustomContent from './CategoryCustomContent';
import ComponentContainer from './ComponentContainer';
import ContentCardComponent from './ContentCardComponent';
import FallbackContainer from './FallbackContainer';
import GridComponent from './GridComponent';
import HelpTopicComponent from './HelpTopicComponent';
import HeroComponent from './HeroComponent';
import LinkListComponent from './LinkListComponent';
import Logo from './Logo';
import ProductDetailCustomContent from './ProductDetailCustomContent';
import ProductListingCustomContent from './ProductListingCustomContent';
import PromoCardComponent from './PromoCardComponent';
import RichTextComponent from './RichTextComponent';
import { Carousel } from './carousel';

export const mapping = {
  Banner,
  Carousel,
  [TYPE_CONTAINER_BOX]: ComponentContainer,
  [TYPE_CONTAINER_ITEM_UNDEFINED]: FallbackContainer,
  LogoComponent: Logo,
  ContentCardComponent,
  HeroComponent,
  GridComponent,
  ProductDetailCustomContent,
  ProductListingCustomContent,
  CategoryCustomContent,
  PromoCardComponent,
  CategoryComponent,
  RichTextComponent,
  LinkListComponent,
  HelpTopicComponent,
};

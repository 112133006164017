import { HeaderTitle, Icon, IconTextInput, NavigationHeader, Screen } from '@components';
import Picker from '@components/Picker2';
import Text from '@components/Text';
import StoreLocationItem from '@components/store/StoreLocationItem';
import StoreMapView from '@components/store/StoreMapView';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { fontSize } from '@config/typography';
import { PagedArray, Store } from '@fieldera-raleys/client-common/types';
import { useEffectOnce } from '@hooks';
import { getLastKnownLocation, hasPermissions as hasLocationPermissions } from '@hooks/useLocation';
import { StoreFinderStackRoutes, StoreFinderStackScreenProps } from '@navigation/routes';
import { useFocusEffect } from '@react-navigation/native';
import { storeService } from '@services/brandywine';
import { useShopStore, useStoreFinderFilterStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { formatPhone } from '@utils/helpers';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Keyboard, Linking, Platform, StyleSheet, TouchableHighlight, TouchableOpacity, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import MapView from 'react-native-maps';
import { useCustomModalContext } from '../../../contexts';

export type PickerItem = { id: string; text: string; value: number };
const milesFilter: PickerItem[] = [
  {
    id: '0',
    value: 10,
    text: '10 Miles',
  },
  {
    id: '1',
    value: 25,
    text: '25 Miles',
  },
  {
    id: '2',
    value: 50,
    text: '50 Miles',
  },
  {
    id: '3',
    value: 100,
    text: '100 Miles',
  },
  {
    id: '4',
    value: 99999,
    text: 'Any',
  },
];

type StoreFinderScreenProps = StoreFinderStackScreenProps<StoreFinderStackRoutes.StoreFinderScreen>;

const StoreFinderScreen = ({ navigation, route }: StoreFinderScreenProps) => {
  const { calledFrom } = route.params ?? {};
  const { t } = useTranslation('storeFinderScreen');
  const { selectFilter, isFilterSelected, resetFilters, getSelectedFiltersCount, getSelectedDepartmentIds } = useStoreFinderFilterStore();

  const mapRef = React.useRef<MapView>(null);
  const [activeMarker, setActiveMarker] = useState<Store>();
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState<Store>();
  const { showAlertModal } = useCustomModalContext();
  const [showMap, setShowMap] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [distance, setDistance] = useState(milesFilter[milesFilter.length - 1].value);
  const { favorites, setFavorite, previous } = useShopStore();
  const [showFavorites, setShowFavorites] = useState<boolean>(calledFrom === 'FavoriteStoreLocations');
  const [showPrevious, setShowPrevious] = useState<boolean>(false);
  const [showApiData, setShowApiData] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<PagedArray<Store>>({
    data: [],
    offset: 0,
    limit: appConstants.PAGE_SIZE,
    total: 0,
  });

  useEffectOnce(() => {
    if (calledFrom) {
      selectFilter('-1', true);
    } else {
      searchForCurrentLocation();
    }
    if (getSelectedDepartmentIds().length > 0) {
      resetFilters();
    }
  });

  const loadStore = useCallback(
    async (offset: number, text?: string, maxMilesDistance?: number) => {
      var locationData = await getLastKnownLocation();
      var lat = locationData?.coords.latitude;
      var lon = locationData?.coords.longitude;
      var depts = getSelectedDepartmentIds();

      var res = await storeService.searchStores(offset, appConstants.PAGE_SIZE, {
        shippingMethod: 'pickup',
        searchString: text,
        latitude: lat,
        longitude: lon,
        maxMiles: maxMilesDistance,
        departmentIds: depts.length > 0 ? depts : undefined,
      });

      var dataRows = offset === 0 ? [] : dataSource.data;

      setDataSource({
        ...dataSource,
        total: res?.total ?? 0,
        offset: res?.offset ?? 0,
        data: [...dataRows, ...(res?.data ?? [])],
      });

      setSearchText(text ?? '');

      setLoading(false);
    },
    [dataSource, getSelectedDepartmentIds],
  );

  useFocusEffect(
    useCallback(() => {
      const isFavSelected = isFilterSelected('-1');
      const isPreviouslyPurchasedSelected = isFilterSelected('-2');

      setShowFavorites(isFavSelected);
      setShowPrevious(isPreviouslyPurchasedSelected);
      setShowApiData(!(isFavSelected || isPreviouslyPurchasedSelected) || getSelectedDepartmentIds().length > 0);

      setLoading(true);
      loadStore(0, searchText.trim(), distance);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distance, getSelectedDepartmentIds, isFilterSelected]),
  );

  const handleLoadMore = async () => {
    if (dataSource.total > dataSource.offset) {
      await loadStore(dataSource.offset, searchText, distance);
    }
  };

  const handleRefresh = async () => {
    await loadStore(0, searchText, distance);
  };

  const getSearchStore = useCallback(
    async (maxMilesDistance: number) => {
      Keyboard.dismiss();
      setDataSource({ ...dataSource, total: 0, offset: 0, data: [] });

      setLoading(true);
      loadStore(0, searchText.trim(), maxMilesDistance);

      setShowApiData(true);
      setShowPrevious(false);
    },
    [dataSource, loadStore, searchText],
  );

  const searchForCurrentLocation = useCallback(async () => {
    const permission = await hasLocationPermissions();
    if (!permission) {
      showAlertModal({
        title: t('PermissionsRequireTitle'),
        message: t('PermissionsRequireText'),
        onClose: () => {
          Platform.select({
            ios: Linking.openURL('app-settings:'),
            android: Linking.openSettings(),
          });
        },
      });
      return;
    }
    setShowFavorites(false);
    resetFilters();
    getSearchStore(distance);
  }, [distance, getSearchStore, resetFilters, showAlertModal, t]);

  const navigateToMarker = async (marker: Store) => {
    const camera = await mapRef.current?.getCamera();
    if (camera) {
      if (camera.center.latitude === parseFloat(marker.coordinates.latitude) && camera.center.longitude === parseFloat(marker.coordinates.longitude)) {
        return;
      }
      camera.center.latitude = parseFloat(marker.coordinates.latitude);
      camera.center.longitude = parseFloat(marker.coordinates.longitude);
      camera.pitch = 0;
      camera.heading = 0;
      mapRef.current?.animateCamera(camera, { duration: 500 });
    }
  };

  const onMarkerPress = (marker: Store) => {
    setTimeout(() => {
      setActiveMarker(marker);
      navigateToMarker(marker);
      setCurrentSelectedLocation(marker);
    }, 250);
  };

  const showActiveMarkerInfo = () =>
    activeMarker && (
      <>
        <View style={[styles.markerInfo]}>
          <StoreLocationItem
            currentSelectedLocation={currentSelectedLocation}
            selectLocation={setCurrentSelectedLocation}
            favorites={favorites}
            setFavorite={setFavorite}
            store={activeMarker}
            index={activeMarker?.index}
            isOnMapScreen={true}
            showDetails={true}
            onViewDetailPress={() => handleViewDetails(activeMarker)}
          />
        </View>
      </>
    );

  const handleViewDetails = (item: Store) => {
    return navigation.navigate(StoreFinderStackRoutes.StoreFinderDetail, {
      storeNumber: item.number,
    });
  };

  const getCommonStore = (stores: Store[][] = []) => {
    var allStores = [];
    var newStores;

    newStores = stores;

    for (var i = 0; i < newStores.length; i++) {
      var currentList = newStores[i];
      for (var y = 0; y < currentList.length; y++) {
        var currentValue = currentList[y];
        if (allStores.map((r) => r.number).indexOf(currentValue.number) === -1) {
          if (newStores.filter((obj) => obj.length > 0 && obj.map((r) => r.number).indexOf(currentValue.number) === -1).length === 0) {
            allStores.push(currentValue);
          }
        }
      }
    }

    return allStores;
  };

  const getStoreData = () => {
    const allFavorites = showFavorites ? favorites : [];
    const allPrevious = showPrevious ? previous : [];
    const apiData = showApiData ? dataSource.data : [];

    const commonStores = getCommonStore([apiData, allFavorites ?? [], allPrevious ?? []]);

    const stores = [...new Map(commonStores.map((item) => [item.number, item])).values()];

    return stores;
  };

  const renderMap = () => {
    const data = getStoreData();
    return data.length > 0 ? (
      <View style={styles.middleViewConatiner}>
        <StoreMapView
          testID="storeFinderMap"
          ref={mapRef}
          containerStyle={styles.mapContainerStyle}
          data={data}
          activeMarker={activeMarker}
          onPress={handleOnMapPress}
          onMarkerPress={onMarkerPress}
          maxZoomLevel={15}
        />
        {currentSelectedLocation?.address?.street && showActiveMarkerInfo()}
      </View>
    ) : (
      <View>{showInitialMessage(t('emptyListMsg'), 'pin-questionmark-solid')}</View>
    );
  };

  const handleOnMapPress = useCallback(() => {
    if (currentSelectedLocation || activeMarker) {
      setActiveMarker(undefined);
      setCurrentSelectedLocation(undefined);
    }
  }, [activeMarker, currentSelectedLocation]);

  useEffectOnce(() => {
    handleOnMapPress();
  });

  const showInitialMessage = useCallback(
    (infoMsg: string, icon: any): JSX.Element => {
      return (
        <View>
          <View style={styles.emptyList}>
            <View style={styles.emptyListIcon}>
              <Icon name={icon} size={68} stroke={colors.darkCream} testID="icon" />
            </View>
            <View style={styles.emptyListMsgView}>
              <Text style={appStyles.fontMobileSmallLeftRegular} testID="infoMsg">
                {infoMsg}
              </Text>
            </View>
          </View>
          <TouchableOpacity testID="searchNearMeIconClick" onPress={searchForCurrentLocation} style={[styles.emptyLocationIcon, styles.searchNearbyView]}>
            <Text style={styles.showMapText} testID="searchNearText">
              {t('searchNearText')}
            </Text>
            <Icon testID="locationArrow" name="location-arrow" fill={colors.white} stroke={colors.darkText} style={styles.searchNearIcon} />
          </TouchableOpacity>
        </View>
      );
    },
    [searchForCurrentLocation, t],
  );

  const handleBorderColor = () => (dataSource && dataSource.data?.length > 0 ? colors.sectionBorder : 'transparent');
  const handleBorderWidth = () => (dataSource && dataSource.data?.length > 0 ? 1 : 0);

  const renderList = () => {
    const data = getStoreData();
    return (
      <>
        {calledFrom && !showFavorites && !showApiData && data.length === 0 && !loading ? (
          showInitialMessage(t('searchMsgForScreen'), 'search-large')
        ) : (
          <FlatList
            style={[
              styles.locationList,
              {
                borderColor: handleBorderColor(),
                borderTopWidth: handleBorderWidth(),
              },
            ]}
            data={data}
            extraData={data}
            keyExtractor={(item, _) => item.id.toString()}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.1}
            onRefresh={handleRefresh}
            ListEmptyComponent={() => {
              return getStoreData().length !== 0
                ? showInitialMessage(t('searchMsgForScreen'), 'search-large')
                : showInitialMessage(t('emptyListMsg'), 'pin-questionmark-solid');
            }}
            renderItem={({ item }: { item: Store }) => {
              return (
                <TouchableHighlight
                  underlayColor={colors.light}
                  style={styles.listItemView}
                  onPress={() => {
                    return navigation.navigate(StoreFinderStackRoutes.StoreFinderDetail, {
                      storeNumber: item.number,
                    });
                  }}
                  testID="storeListItemClick">
                  <View style={[styles.itemContainer, styles.listItem]}>
                    <View>
                      <View style={styles.item}>
                        <View style={styles.storeNameView}>
                          <Text style={[appStyles.bodyLeftBold, styles.storeNameTxt]} numberOfLines={1} testID="storeName">
                            {item.name}
                          </Text>

                          <TouchableOpacity
                            testID={favorites?.some((favorite) => favorite.number === item.number) ? 'favIcon' : 'nonFavIcon'}
                            onPress={() => {
                              setLoading(true);
                              setFavorite(item);
                              setLoading(false);
                            }}
                            style={[styles.listIcon]}>
                            {favorites?.some((favorite) => favorite.number === item.number) ? (
                              <Icon testID="favIcon" name="star-icon" size={22} fill={colors.darkText} stroke={colors.darkText} style={appStyles.smallIcon} />
                            ) : (
                              <Icon testID="nonFavIcon" name="star-icon" size={22} fill={'none'} stroke={colors.darkText} style={appStyles.smallIcon} />
                            )}
                          </TouchableOpacity>
                        </View>
                        <View style={styles.addressView}>
                          <Text style={styles.addressText} numberOfLines={2} testID="addressText">
                            {`${item.address.street}`}
                          </Text>
                          <Text
                            testID="addressCity"
                            style={styles.addressText}
                            numberOfLines={2}>{`${item.address.city} ${item.address.state}, ${item.address.zip}`}</Text>
                        </View>
                        <View style={styles.timeDistanceView}>
                          {item?.storeHours && (
                            <Text style={styles.timeText} testID="hrsText">
                              TODAY:{' '}
                              {item?.storeHours
                                ?.replace(/between/gi, '')
                                .replace(/and/gi, '-')
                                .replace(/:00/gi, '')
                                .replace(/\s +/g, '')}
                            </Text>
                          )}
                          <Text style={[styles.distanceText, { marginLeft: item?.storeHours ? 25 : 0 }]} testID="distanceText">{`${item.distance.toFixed(
                            1,
                          )} mi`}</Text>
                        </View>
                        <View style={styles.shopNumberView}>
                          <TouchableOpacity
                            testID="phoneText"
                            onPress={() => {
                              Linking.openURL(`tel:${item.phone}`);
                            }}
                            style={styles.phoneView}>
                            <Text style={[appStyles.bodySmallLeftRegular, styles.phoneText]} numberOfLines={2}>
                              {formatPhone(item.phone)}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <Icon testID="arrowIcon" name="arrow-right" size={20} style={{ marginRight: scale(14) }} />
                  </View>
                </TouchableHighlight>
              );
            }}
            contentContainerStyle={styles.searchListContentContainerStyle}
          />
        )}
      </>
    );
  };

  const setDistanceValue = (data: any) => {
    setDistance(data);
  };

  const renderTabView = () => {
    return (
      <View style={styles.tabContainer}>
        <View style={styles.iconView}>
          <TouchableOpacity
            testID="tabMapIconClick"
            onPress={() => {
              setShowMap(!showMap);
              Keyboard.dismiss();
              setActiveMarker(undefined);
            }}
            style={styles.mapTouchView}
            disabled={getStoreData().length > 0 ? false : true}>
            <Icon testID="mapIcon" name={!showMap ? 'map-hallow' : 'map-list-icon'} size={!showMap ? 22 : 20} style={appStyles.mediumIcon} />
          </TouchableOpacity>
        </View>
        <View style={styles.dropDownView}>
          <Picker
            testID="distancePicker"
            containerStyle={{ height: screenHeight * 0.46 }}
            initialValue={milesFilter[milesFilter.length - 1]}
            placeholder={'Select'}
            options={milesFilter}
            onValueChange={(selectedVal) => {
              setDistanceValue(selectedVal.value);
              getSearchStore(selectedVal.value);
              setShowFavorites(false);
              resetFilters();
            }}
            headerTitle={
              <View style={styles.pickerHeaderView}>
                <Text style={styles.headerTitle} testID="miles">
                  {t('miles')}
                </Text>
                <Text style={styles.headerSubTitle} testID="within">
                  {t('within')}
                </Text>
              </View>
            }
            style={styles.pickerViewStyle}
            textStyle={styles.pickerText}
          />
        </View>
        <View style={styles.showAllView}>
          <TouchableOpacity
            testID="showAllClick"
            onPress={() => {
              getSearchStore(distance);
              setShowFavorites(false);
              resetFilters();
            }}>
            <Text style={styles.showMapText} testID="showAllText">
              {t('showAllText')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.filterView}>
          <View>
            <TouchableOpacity testID="filterClick" onPress={() => navigation.navigate(StoreFinderStackRoutes.StoreFinderFilter, { calledFrom: calledFrom })}>
              <Icon testID="filterIcon" name="sort-icon" size={28} style={[appStyles.mediumIcon, styles.icon]} />

              {getSelectedFiltersCount() > 0 && (
                <View style={[styles.badge]}>
                  <Text testID="filterBadgeText" style={styles.badgeText}>
                    {getSelectedFiltersCount()}
                  </Text>
                </View>
              )}
            </TouchableOpacity>
          </View>
        </View>
        {getStoreData().length > 0 ? (
          <View style={styles.showingView}>
            <Text testID="storeTotalMsg" style={[appStyles.tiny, { fontFamily: FontFamily.LarsseitLight }]}>
              Showing {getStoreData().length} stores
            </Text>
          </View>
        ) : (
          <View />
        )}
      </View>
    );
  };

  return (
    <Screen style={[utilityStyles.pb0]}>
      <NavigationHeader subStyle={[styles.alignLeft, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID="navigationHeaderTitle">{t('navigationHeaderTitle')}</HeaderTitle>
      </NavigationHeader>

      <View style={[appStyles.container, styles.screenContainerView]}>
        <View style={styles.searchWrapper}>
          {/*  eslint-disable-next-line react-native/no-inline-styles */}
          <View style={[styles.headerView, { width: '70%' }]}>
            <IconTextInput
              testID="searchStoreTextInput"
              width={'100%'}
              clearButtonMode="while-editing"
              placeholder={t('placeholder')}
              onSubmitEditing={({ nativeEvent: { text } }) => {
                if (!text) {
                  return;
                }
                getSearchStore(distance);
              }}
              fieldStyleEx={styles.inputTextField}
              onChangeText={setSearchText}
              value={searchText}
            />
          </View>
          <TouchableOpacity onPress={searchForCurrentLocation} style={styles.locationIcon} testID="liveLoactionButtonClick">
            <Icon testID="locationIcon" name="location-arrow" fill={colors.white} stroke={colors.darkText} style={styles.arrowIcon} />
          </TouchableOpacity>
          <>
            <TouchableOpacity
              testID={!showFavorites ? 'favIconSearchFilter' : 'nonFavIconFilter'}
              onPress={() => {
                setShowApiData(false);
                setShowFavorites(!showFavorites);
                if (!showFavorites) {
                  selectFilter('-1', true);
                  const isFavSelected = isFilterSelected('-1');
                  const isPreviouslyPurchasedSelected = isFilterSelected('-2');
                  setShowFavorites(isFavSelected);
                  setShowPrevious(isPreviouslyPurchasedSelected);
                  setShowApiData(getSelectedDepartmentIds().length > 0);
                } else {
                  selectFilter('-1', false);
                  setShowApiData(getSelectedDepartmentIds().length > 0);
                }
                if (getSelectedDepartmentIds().length > 0) {
                  loadStore(0, searchText.trim(), distance);
                }
              }}
              style={styles.favoritesIcon}>
              <Icon name="star-icon" size={30} fill={!showFavorites ? 'none' : colors.darkText} stroke={colors.darkText} style={appStyles.largeIcon} />
            </TouchableOpacity>
          </>
        </View>
        {renderTabView()}
      </View>
      {loading ? (
        <View style={styles.activityIndicatorView}>
          <ActivityIndicator color={appStyles.primaryButton.color} size={'small'} style={styles.loading} />
        </View>
      ) : (
        <>{showMap && getStoreData().length > 0 ? renderMap() : renderList()}</>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicatorView: {
    width: screenWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  middleViewConatiner: {
    height: screenHeight * 0.6,
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  searchWrapper: {
    flexDirection: 'row',
    width: containerWidth,
    position: 'absolute',
    top: 30,
    alignSelf: 'center',
    height: 50,
  },
  inputTextField: {
    height: 42,
  },
  locationIcon: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginLeft: 2,
    width: '10%',
  },
  showMapText: {
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(14),
    color: colors.darkText,
    textDecorationLine: 'underline',
  },
  locationList: {
    width: '100%',
    borderTopWidth: 0,
  },
  arrowIcon: {
    width: 25,
    height: 25,
  },
  loading: {
    width: screenWidth,
    top: 20,
  },
  emptyList: {
    ...utilityStyles.py4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 30,
  },
  marginVertical: {
    marginVertical: 12,
  },

  searchListContentContainerStyle: {
    paddingBottom: screenHeight > 740 ? screenHeight * 0.09 : screenHeight * 0.15,
  },
  badge: {
    borderRadius: 10,
    height: 12,
    width: 12,
    top: -8,
    bottom: 20,
    backgroundColor: colors.secondary,
    position: 'absolute',
    marginLeft: 25,
  },
  badgeText: {
    fontSize: scale(10),
    lineHeight: lineHeight(10),
    color: colors.white,
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
  },
  icon: {
    marginHorizontal: 7,
  },
  emptyListIcon: {
    width: 100,
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 18,
    paddingLeft: 22,
  },
  tabContainer: {
    top: 86,
    flexDirection: 'row',
    marginLeft: 5,
    width: containerWidth,
    height: '30%',
  },
  iconView: {
    paddingTop: scale(7),
    width: '10%',
  },
  dropDownView: {
    paddingTop: 8,
    width: '30%',
    height: '100%',
  },
  pickerViewStyle: {
    height: 30,
    backgroundColor: colors.cream,
    borderWidth: 0.5,
    borderRadius: 2,
    borderColor: colors.text,
  },
  pickerText: {
    fontSize: scale(14),
    width: 70,
    color: colors.text,
    fontFamily: FontFamily.AleoBold,
  },
  showAllView: {
    height: '100%',
    width: '26%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterView: {
    height: '100%',
    width: '12%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: scale(8),
    paddingTop: scale(5),
  },
  showingView: {
    height: '100%',
    width: '21%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: scale(13),
    paddingTop: scale(5),
    // borderWidth: 1,
  },
  listItem: {
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  tinyLightLeft: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    lineHeight: lineHeight(12),
    textAlign: 'left',
  },
  searchNearbyView: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  emptyListMsgView: { width: '65%' },
  markerInfo: {
    zIndex: 999,
    width: screenWidth,
    position: 'absolute',
    left: -20,
    bottom: screenHeight * 0.06,
    paddingBottom: Platform.select({ ios: 20, android: 50 }),
  },
  searchNearView: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  searchNearIcon: {
    width: 20,
    height: 20,
    marginLeft: 8,
  },
  mapContainerStyle: {
    marginTop: Platform.select({ ios: -5, android: -15 }),
  },
  mapTouchView: { paddingTop: 5 },
  screenContainerView: { height: '25%' },
  pickerHeaderView: { flexDirection: 'row' },
  headerTitle: {
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    lineHeight: lineHeight(28),
  },
  headerSubTitle: {
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.dark,
    lineHeight: lineHeight(28),
  },
  favoritesIcon: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: '15%',
    marginLeft: '2%',
  },
  headerView: { alignSelf: 'flex-start' },
  emptyLocationIcon: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginLeft: -2,
    width: screenWidth * 0.9,
  },
  listFavIconView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: screenWidth * 0.2,
    height: screenHeight * 0.09,
  },
  listIcon: {
    height: 30,
    alignContent: 'center',
    paddingTop: 1,
    width: '15%',
  },
  item: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  itemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 18,
  },
  listItemView: {
    width: screenWidth,
    paddingHorizontal: 5,
  },
  storeNameView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timeDistanceView: {
    width: screenWidth * 0.5,
    flexDirection: 'row',
    marginVertical: 5,
  },
  timeText: {
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
  },
  distanceText: {
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
  },
  shopNumberView: {
    width: screenWidth * 0.5,
    flexDirection: 'row',
  },
  shopTextView: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitLight,
    marginLeft: 30,
  },
  phoneText: {
    fontSize: fontSize.TertiaryText,
    textDecorationLine: 'underline',
  },
  phoneView: {
    height: 30,
    alignContent: 'center',
    paddingTop: 1,
  },
  storeNameTxt: { width: '75%' },
  addressText: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(18),
    color: colors.darkText,
  },
  addressView: { width: '70%' },
  permissionText: { textAlign: 'center' },
});

export default StoreFinderScreen;

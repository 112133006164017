import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import {
  CategoryWidgetParams,
  ItemWidgetParams,
  KeywordWidgetParams,
  PersonalizedWidgetParams,
  ProductType,
  ShopType,
  WidgetEventParams,
  WidgetParams,
  WidgetType,
} from '@fieldera-raleys/client-common';
import { useAnalytics } from '@hooks';
import { AppStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useCartStore, useShopStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { getAttributeName, getSubstitutionItems, getSubstitutionType } from '@utils/orderHelpers';
import {
  getDisplayPriceBySellTypeAndPriceMessage,
  getFormattedPrice,
  getProductAttributeValue,
  getProductAvailablity,
  getProductsfromCommerceTools,
  getQuantityFromPriceMessage,
  getfulfillmentLeadTimeMinutes,
} from '@utils/productHelper';
import { isNumber } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListRenderItemInfo, Platform, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Config from 'react-native-config';
import BookmarkButton from './BookmarkButton';
import Button from './Button';
import Carousel from './Carousel';
import CustomizeButton from './CustomizeButton';
import CustomizeCartInfo from './CustomizeCartInfo';
import Icon from './Icon';
import ProductImage from './ProductImage';
import { CartQuantityButton } from './cart/CartQuantityButton';

export type ProductCarouselProps = {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  linkText?: string;
  linkTextStyle?: StyleProp<TextStyle>;
  linkAction?: () => void;
  contentContainerStyle?: StyleProp<TextStyle>;
  addCartButtonStyle?: StyleProp<TextStyle>;
  backgroundStyle?: StyleProp<ViewStyle>;
  data: ProductVariant[];
  actionType?: string;
  itemId?: string;
  widgetEventParams?: WidgetEventParams;
  count?: number;
  emptyListMsg?: string;
  widgetType: keyof typeof WidgetType;
  widgetId: string;
  widgetParams?: WidgetParams | ItemWidgetParams | KeywordWidgetParams | CategoryWidgetParams | PersonalizedWidgetParams;
};

type ProdDataType = {
  [key: string]: Product;
};

const SLIDE_GAP = 12;
const CARD_WIDTH = screenWidth * 0.45;

const ProductCarousel = memo(
  ({
    title,
    titleStyle,
    linkText,
    linkTextStyle,
    linkAction,
    contentContainerStyle,
    addCartButtonStyle,
    backgroundStyle = {},
    data,
    actionType = 'cart',
    itemId,
    widgetEventParams,
    count,
    emptyListMsg,
    widgetType,
    widgetId,
    widgetParams,
  }: ProductCarouselProps): JSX.Element => {
    const navigation = useNavigation();
    const { selectedShopType } = useShopStore();
    const { t } = useTranslation('itemsYouMayLike');
    const { setItemBackup, cart, setItemSubType } = useCartStore();
    // slide size calculations based on container

    const carouselConfig = {
      slidesPerPage: 1,
      nextSlidePartial: CARD_WIDTH * 0.44,
      slideWidth: CARD_WIDTH,
      sgPerLine: 1,
    };

    const [productData, setProductData] = useState<ProdDataType | undefined>();
    const { trackWidgetClickEvent } = useAnalytics();
    useEffect(() => {
      const getProdSet = async (skus: string[]): Promise<Product[]> => {
        return await getProductsfromCommerceTools(skus);
      };
      if (data) {
        const skus: string[] = data.reduce<string[]>((acc: string[], i: ProductVariant) => {
          if (i.sku) {
            acc.push(i.sku);
          }
          return acc;
        }, []);

        getProdSet(skus).then((pd) => {
          const pData: ProdDataType = pd.reduce((acc, p: Product) => {
            acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
            p.masterData?.current?.variants.forEach((vi) => {
              acc[vi?.sku ?? ''] = p;
            });

            return acc;
          }, {} as ProdDataType);
          setProductData(pData);
        });
      }
    }, [data]);

    const handleNavigation = (item: ProductVariant): void => {
      widgetEventParams &&
        trackWidgetClickEvent({
          wrid: widgetEventParams?.wrid,
          wid: widgetEventParams.wid,
          wty: widgetEventParams.wty,
          item_id: item.id.toString(),
        });
      navigation.dispatch(StackActions.push(AppStackRoutes.ProductDetails, { productKey: item.sku }));
      item.sku && navigation.navigate(AppStackRoutes.ProductDetails, { productKey: item.sku });
    };

    const handleLinkAction = () => {
      if (linkAction) {
        linkAction();
      } else {
        navigation.navigate(AppStackRoutes.ProductCarouselList, { widgetId, widgetType, widgetParams, actionType, itemId });
      }
    };

    const [choice, setChoice] = useState<String>('');
    useEffect(() => {
      setChoice('');
      if (cart && itemId) {
        const li = cart.lineItems.find((i) => i.id === itemId);
        if (li) {
          const subType = getSubstitutionType(li, 'Best-Available');
          if (subType === 'Specific-Items') {
            const subItems = li ? getSubstitutionItems(li) : [];
            setChoice(subItems.length > 0 ? subItems[0] : '');
          }
        }
      }
    }, [cart, itemId]);

    const setBackupChoice = (id?: string) => {
      if (cart && itemId) {
        if (choice !== id) {
          setItemBackup(itemId, id ? [id] : []);
          if (navigation.canGoBack()) {
            navigation.goBack();
          }
        } else {
          setItemSubType(itemId, 'Best-Available');
        }
      }
    };

    const renderItem = ({ item, index }: ListRenderItemInfo<ProductVariant>) => {
      const sellType = getProductAttributeValue('unitSellType', item.attributesRaw) ?? { key: 'byEach', label: 'By Each' };
      const unitsPerPackage = getProductAttributeValue('unitsPerPackage', item.attributesRaw);
      const unitOfMeasure = getProductAttributeValue('unitOfMeasure', item.attributesRaw);
      let price = item.price?.value && getFormattedPrice(item.price?.value);
      const packageCount = getProductAttributeValue('packageCount', item.attributesRaw); // getProductAttributeValue('packageCount', productData[item.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? []);
      const unitBuyIncrement = getProductAttributeValue('unitBuyIncrement', item.attributesRaw);
      const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', item.attributesRaw);
      const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', item.attributesRaw);
      const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', item.attributesRaw);
      var priceCustomValues = item.price?.custom?.customFieldsRaw ?? [];
      let priceMessage = '';
      priceMessage =
        priceCustomValues.length &&
        priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value &&
        priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value;

      priceMessage = isNumber(priceMessage) ? '' : priceMessage;
      const priceMessageQty = getQuantityFromPriceMessage(priceMessage);
      price = priceMessageQty > 1 ? getDisplayPriceBySellTypeAndPriceMessage(sellType.key, priceMessage, item.price?.value, unitAverageBuyWeight) : price;

      const leadTime = getfulfillmentLeadTimeMinutes(item);
      let leadMessageEx;
      if (leadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES) {
        leadMessageEx = 'This item requires advance ordering. Would you like to continue?';
      }

      let cartButton = <></>;
      let customizeCartInfo = <></>;
      let productInfo = [];
      let productType = 'standard';
      if (productData && item.sku && productData[item.sku]) {
        let product = productData[item.sku];
        productType = productData[item.sku].productType?.name ?? 'standard';
        // let avgBuyWeight = product.masterData.current?.masterVariant.attributesRaw.find((a) => a.name === 'unitAverageBuyWeight')?.value;
        if (productType === ProductType.CONFIGURABLE) {
          if (actionType !== 'substitute') {
            cartButton = (
              <>
                <CustomizeButton
                  disabled={!item.price}
                  productKey={item.sku ?? ''}
                  productLeadTime={leadMessageEx}
                  size={'small'}
                  buttonStyle={[appStyles.primaryButtonSmall, styles.customizeCartAddQty]}
                  testID={`productAddToCartBtn-${index}`}
                  availabilityResult={getProductAvailablity(productData[item.sku].masterData)}
                />
              </>
            );
            customizeCartInfo = (
              <>
                <CustomizeCartInfo
                  boxStyle={{ position: 'relative', bottom: 10, width: '77%', height: 'auto', marginBottom: 0 }}
                  productKey={item.sku ?? ''}
                  textSize={12}
                />
              </>
            );
          } else {
            // customizable in substitution screen
            // this product should not be present in substitution view, nothing to display for button in this case
          }
        } else {
          cartButton =
            actionType === 'substitute' ? (
              <>
                <Button
                  testID={choice === item.sku ? t('subSelected') : t('subSelect')}
                  size={'small'}
                  type="secondary"
                  buttonStyle={[choice === item.sku ? styles.selectedBackupButton : styles.selectBackupButton, { width: '88%' }]}
                  textStyle={[choice === item.sku ? styles.selectedText : styles.selectText]}
                  title={choice === item.sku ? t('subSelected') : t('subSelect')}
                  onPress={() => setBackupChoice(item.sku ?? '')}
                />
                {choice === item.sku ? (
                  <Icon
                    testID="circleCheckIcon"
                    size={27}
                    name="circle-check"
                    stroke={colors.green}
                    strokeSecondary={colors.white}
                    style={{ position: 'absolute', top: 12, left: 25 }}
                  />
                ) : null}
              </>
            ) : (
              <>
                <CartQuantityButton
                  productSellType={sellType}
                  unitsPerPackage={unitsPerPackage}
                  unitBuyIncrement={unitBuyIncrement}
                  unitBuyMaximum={unitBuyMaximum}
                  unitBuyMinimum={unitBuyMinimum}
                  unitOfMeasure={unitOfMeasure}
                  disabled={item.price ? false : true}
                  size={'small'}
                  variantKey={item.sku ?? ''}
                  productKey={item.sku ?? ''}
                  product={productData[item.sku ?? '']}
                  productName={getAttributeName(item, '')}
                  productLeadTime={leadMessageEx}
                  showCartText={true}
                  cartTextStyle={styles.inCartText}
                  qtyContainerStyle={[styles.cartQty]}
                  buttonStyle={[styles.cartAddQty, addCartButtonStyle]}
                  availabilityResult={getProductAvailablity(productData[item.sku].masterData)}
                  widgetEventParams={widgetEventParams}
                />
              </>
            );
        }

        let pricePerUnit =
          product?.masterData?.current?.masterVariant.price?.value && getFormattedPrice(product.masterData.current.masterVariant.price?.value, unitsPerPackage);

        priceCustomValues = productData[item.sku]?.masterData.current?.masterVariant?.price?.custom?.customFieldsRaw ?? [];
        let regularPrice = getFormattedPrice(priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value);
        let onSale =
          (product?.masterData.current?.masterVariant?.price?.value?.centAmount ?? 0) <
            (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value?.centAmount ?? 0) &&
          priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value
            ? true
            : false;

        const colorStyle = { color: onSale ? colors.saleRed : colors.text };
        if (sellType.key === 'byEach') {
          productInfo.push(
            <View key={'pInfo-byEachBlock'} style={styles.productPricing}>
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text testID="unitsPerPackage" style={[appStyles.fontMobileBodySmallBald, colorStyle]}>
                    <Text testID="productPrice" style={[appStyles.fontMobileBodyXS, { paddingTop: Platform.select({ android: 15, default: 0 }) }]}>
                      {productType === ProductType.CONFIGURABLE ? 'Starts at ' : ''}
                    </Text>
                    {price}
                    {actionType !== 'substitute' &&
                      (priceMessageQty > 1 ? null : (
                        <Text style={[appStyles.fontMobileTinyLight, { paddingTop: Platform.select({ android: 15, default: 0 }) }]}> ea</Text>
                      ))}
                  </Text>
                  {onSale && actionType !== 'substitute' && (
                    <Text style={[appStyles.fontMobileTinyLight, { textDecorationLine: 'line-through', marginLeft: 'auto' }]}>
                      {regularPrice}
                      <Text style={[appStyles.fontMobileTinyLight]}> ea</Text>
                    </Text>
                  )}
                </View>
              </View>
            </View>,
            <TouchableOpacity key={'pInfo-byEachButton'} onPress={() => handleNavigation(item)} style={styles.itemNameText}>
              <Text style={[appStyles.fontMobileBodySmallBald]} numberOfLines={2}>
                {getAttributeName(item, '') + ' '}
              </Text>
            </TouchableOpacity>,
          );
          let unitTotalCount = '1';
          unitTotalCount = ((unitsPerPackage ?? 1) * (packageCount ?? 1)).toFixed(1);

          if (actionType !== 'substitute') {
            productInfo.push(
              <View key={'pInfo-subBLock'} style={{}}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Text testID="unitsPerPackage2" style={[appStyles.fontMobileTinyLight]}>
                      {`${pricePerUnit} / ${unitOfMeasure}`}
                    </Text>
                    {sellType.key === 'byEach' && unitsPerPackage ? (
                      <Text
                        testID="unitOfMeasure"
                        key={'unit-of-measure'}
                        numberOfLines={1}
                        adjustsFontSizeToFit={true}
                        style={[appStyles.fontMobileTinyLight, { marginLeft: '20%' }]}>
                        {`${unitTotalCount} ${unitOfMeasure}`}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </View>,
            );
          }
        } else if (sellType.key === 'weightByEach') {
          productInfo.push(
            <View key={'pInfo-weightByEachBlock'} style={{}}>
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text testID="unitsPerPackage2" style={[appStyles.fontMobileBodySmallBald, colorStyle]}>
                    <Text testID="productPrice" style={[appStyles.fontMobileBodyXS, { paddingTop: Platform.select({ android: 15, default: 0 }) }]}>
                      {'approx '}
                    </Text>
                    {product.masterData.current?.masterVariant.price
                      ? getFormattedPrice(product.masterData.current?.masterVariant.price.value, 1 / unitAverageBuyWeight)
                      : pricePerUnit}
                    {actionType !== 'substitute' && (
                      <Text testID="productPrice" style={[appStyles.fontMobileTinyLight, { paddingTop: Platform.select({ android: 15, default: 0 }) }]}>
                        {' ea'}
                      </Text>
                    )}
                  </Text>
                  {actionType === 'substitute' ? (
                    // <Text
                    //   testID="unitsPerPackage2"
                    //   style={[appStyles.fontMobileTinyLight, colorStyle, { marginLeft: 'auto', paddingTop: Platform.select({ android: 15, default: 0 }) }]}>
                    //   {`${pricePerUnit} / ${unitOfMeasure}`}
                    // </Text>
                    <></>
                  ) : (
                    <Text style={[appStyles.fontMobileTinyLight, { marginLeft: 'auto' }]}>
                      Avg. {unitAverageBuyWeight} {unitOfMeasure}
                    </Text>
                  )}
                </View>
              </View>
            </View>,
            <TouchableOpacity key={'pInfo-byWeightButton'} onPress={() => handleNavigation(item)} style={styles.itemNameText}>
              <Text style={[appStyles.fontMobileBodySmallBald]} numberOfLines={2}>
                {getAttributeName(item, '') + ' '}
              </Text>
            </TouchableOpacity>,
          );
          if (actionType !== 'substitute') {
            productInfo.push(
              <View key={'pInfo-notSubBlock'} style={{}}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Text testID="unitsPerPackage2" style={[appStyles.fontMobileTinyLight]}>
                      {`${pricePerUnit} / ${unitOfMeasure}`}
                    </Text>
                    {onSale && (
                      <Text style={[appStyles.fontMobileTinyLight, { textDecorationLine: 'line-through', marginLeft: 20 }]}>
                        {regularPrice} / {unitOfMeasure}
                      </Text>
                    )}
                  </View>
                </View>
              </View>,
            );
          }
        } else if (sellType.key === 'byWeight') {
          productInfo.push(
            <View key={'pInfo-byWeightBlock'} style={{}}>
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text testID="unitsPerPackage2" style={[appStyles.fontMobileBodySmallBald, colorStyle]}>
                    {pricePerUnit}
                    {actionType !== 'substitute' && (
                      <Text testID="productPrice" style={[appStyles.fontMobileTinyLight, { paddingTop: Platform.select({ android: 15, default: 0 }) }]}>
                        {` / ${unitOfMeasure}`}
                      </Text>
                    )}
                  </Text>
                  {onSale && actionType !== 'substitute' && (
                    <Text style={[appStyles.fontMobileTinyLight, { textDecorationLine: 'line-through', marginLeft: 'auto' }]}>
                      {regularPrice} / {unitOfMeasure}
                    </Text>
                  )}
                </View>
              </View>
            </View>,
            <TouchableOpacity key={'pInfo-byWeightButton'} onPress={() => handleNavigation(item)} style={styles.itemNameText}>
              <Text style={[appStyles.fontMobileBodySmallBald]} numberOfLines={2}>
                {getAttributeName(item, '') + ' '}
              </Text>
            </TouchableOpacity>,
          );
        }

        return (
          <View key={'carousel_item_key_' + index} style={styles.carouselItem}>
            {index ? <View style={styles.carouselItemPadding} /> : null}
            <View style={[{ backgroundColor: colors.sectionPad, width: carouselConfig.slideWidth }, backgroundStyle]}>
              <View style={{ backgroundColor: colors.sectionPad, width: carouselConfig.slideWidth }}>
                <TouchableOpacity key={'image_key_index_' + index} activeOpacity={1} onPress={() => handleNavigation(item)} testID="itemClick">
                  {productData && item.sku && productData[item.sku] && (
                    <ProductImage
                      key={item?.images[0]?.url ?? ''}
                      imageUrl={item?.images[0]?.url ?? ''}
                      product={item}
                      disabled={true}
                      imageStyle={{ alignSelf: 'center', width: carouselConfig.slideWidth, height: carouselConfig.slideWidth }}
                      resizeMode="contain"
                      testID="productImage"
                    />
                  )}
                  {/*
                  <View style={styles.extraBar}>
                    <Icon name="something-extra" fill={colors.seRed} size={26} stroke={'transparent'} strokeSecondary={colors.white} />
                  </View>
                */}
                </TouchableOpacity>
              </View>
              <View style={[styles.cartButtonContainer, utilityStyles.px1]}>
                {cartButton}
                {actionType !== 'substitute' && selectedShopType !== ShopType.IN_STORE ? (
                  <BookmarkButton productSku={item.sku ?? ''} wrapperStyle={styles.heartWrapper} />
                ) : null}
              </View>
              <View style={[styles.customizeCartInfoContainer, utilityStyles.px1]}>{customizeCartInfo}</View>
              <View style={[styles.infoWrapper]}>{productInfo}</View>
            </View>
          </View>
        );
      }
      return <></>;
    };

    return (data?.length ?? 0) > 0 ? (
      <>
        <View style={[styles.productCarouselContainer, backgroundStyle]}>
          <View style={[styles.carouselContent, utilityStyles.mt4, contentContainerStyle]}>
            <Text adjustsFontSizeToFit={true} numberOfLines={1} style={[appStyles.dropShadowTitle, { width: '85%' }, titleStyle]} testID="title">
              {title}
            </Text>
          </View>
          {count && count > 10 ? (
            <View style={{ width: containerWidth }}>
              <TouchableOpacity style={[styles.linkContainer]} onPress={handleLinkAction} testID="countClick">
                <Text style={[styles.link, linkTextStyle]} testID="count">
                  {linkText ? linkText : 'See All ' + (count < 100 ? count : '')}
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        <View style={[styles.productCarouselContainer, backgroundStyle]}>
          <View style={[styles.carouselContent, backgroundStyle]}>
            <View style={[styles.productCarousel, backgroundStyle]}>
              <Carousel
                windwoSize={21}
                snapToInterval={carouselConfig.slideWidth + 13}
                contentContainerStyle={{
                  ...styles.carouselContainer,
                  width: (data?.length ?? 0) * (carouselConfig.slideWidth + SLIDE_GAP) - SLIDE_GAP,
                  marginRight: 10,
                }}
                entities={data || []}
                renderItem={renderItem}
                pagerStyle={styles.pagerStyle}
                pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
                pagerInactiveItemStyle={appStyles.carouselDotsSmall}
                showPager={true}
              />
            </View>
          </View>
        </View>
      </>
    ) : (
      <>
        <View style={[styles.productCarouselContainer, backgroundStyle]}>
          <View style={[styles.carouselContent, utilityStyles.mt4, contentContainerStyle, { flexDirection: 'column' }]}>
            <View>
              <Text style={[appStyles.dropShadowTitle, titleStyle]} testID="title">
                {title}
              </Text>
            </View>
            <View style={styles.noDataBox}>
              <Text style={[appStyles.bodyLargeCenter, { fontFamily: FontFamily.LarsseitBold }]} testID="emptyListMsg">
                {emptyListMsg ? emptyListMsg : t('emptyListMsg')}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  },
);

const styles = StyleSheet.create({
  productCarouselContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
  },
  carouselContent: {
    flex: 1,
    width: containerWidth,
    backgroundColor: colors.sectionPad,
  },
  carouselContainer: {
    borderWidth: 0,
    overflow: 'visible',
  },
  inCartText: {
    top: 0,
    fontSize: scale(12),
  },
  borderStyle: {
    width: '40%',
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: '#c69480',
    height: 34,
    top: -5,
  },
  productCarousel: {
    borderWidth: 0,
    marginTop: scale(10),
    marginBottom: scale(10),
    backgroundColor: colors.sectionPad,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  pagerStyle: {
    marginTop: 34,
    marginBottom: 10,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  carouselItem: {
    flexDirection: 'row',
  },
  carouselItemPadding: {
    borderWidth: 0,
    borderColor: 'blue',
    width: SLIDE_GAP,
  },
  heartWrapper: {
    width: 20,
    marginLeft: 'auto',
    justifyContent: 'center',
    paddingTop: 8,
  },
  itemNameText: {
    marginTop: scale(8),
    marginBottom: scale(5),
  },
  itemUnitText: {
    textAlign: 'right',
  },
  itemPriceText: {
    marginTop: 3,
    lineHeight: lineHeight(18),
  },
  cartButtonContainer: {
    flexDirection: 'row',
    marginTop: 3,
    marginBottom: 3,
  },
  customizeCartInfoContainer: {
    flexDirection: 'row',
    marginTop: 3,
    marginBottom: 3,
  },
  cartQty: {
    left: -2,
    height: 36,
    backgroundColor: colors.white,
    width: '80%',
    maxWidth: 146,
  },
  cartAddQty: {
    left: -11,
    height: 36,
    backgroundColor: colors.cream,
    width: '80%',
    maxWidth: 146,
  },
  customizeCartAddQty: {
    left: -11,
    height: 36,
    backgroundColor: colors.darkCream,
    width: '80%',
    maxWidth: 146,
  },
  link: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    fontSize: 16,
    textAlign: 'right',
    lineHeight: lineHeight(24),
  },
  linkContainer: {
    marginLeft: 'auto',
  },
  noDataBox: {
    height: 150,
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  infoWrapper: {
    paddingHorizontal: 5,
    flex: 1,
    justifyContent: screenHeight > 740 ? undefined : 'flex-start',
  },
  pricePerUnit: {
    textDecorationLine: 'none',
  },
  noAvailText: {
    fontSize: scale(14),
    color: colors.text,
    lineHeight: scale(16),
    fontFamily: FontFamily.LarsseitLight,
  },
  cartLink: {
    fontSize: scale(14),
    lineHeight: scale(14),
    color: colors.red,
    top: 2,
    textAlign: 'auto',
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'underline',
  },
  extraBar: {
    position: 'absolute',
    top: -2,
    right: 0,
    alignItems: 'center',
  },
  selectBackupButton: {
    height: 35,
    color: colors.primary,
    borderRadius: 28,
  },
  selectedBackupButton: {
    height: 35,
    backgroundColor: colors.green,
    borderRadius: 28,
    borderColor: colors.green,
    paddingLeft: 20,
  },
  selectText: {
    height: 30,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(23),
    color: colors.red,
  },
  selectedText: {
    lineHeight: lineHeight(23),
    flexDirection: 'row',
    width: '100%',
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.white,
  },
  productPricing: {
    // flex: 1,
    alignItems: 'baseline',
    flexDirection: 'row',
    alignContent: 'space-between',
  },
});

export default ProductCarousel;

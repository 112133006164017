import { Icon, LoadingScreen, Modal, NavigationHeader, Screen, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import HeaderTitle from '@components/HeaderTitle';
import Tabbar, { TabItem } from '@components/Tabbar';
import WalletItem from '@components/WalletItem';
import ClippedSavings from '@components/somethingExtra/ClippedSavings';
import SEDollars from '@components/somethingExtra/SEDollars';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useRefetchOnFocus } from '@hooks';
import { AccountStackRoutes, RootTabRoutes, RootTabScreenProps } from '@navigation/routes';
import { userService } from '@services/brandywine';
import { useOffersStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import { queryClient } from '@utils/reactQuery';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { useCustomModalContext } from '../../../contexts';

type WalletScreenProps = RootTabScreenProps<RootTabRoutes.Wallet>;

const WalletScreen = ({ navigation, route }: WalletScreenProps) => {
  const { isLoading, data, refetch, isRefetching } = useQuery<PaymentProfile[]>('wallet', async () => await userService.getPaymentProfiles());
  const { availableVouchers, acceptedVouchers, vouchersState } = useOffersStore();
  const { showBack, link, linkParams, toDollarsTab } = route.params;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [currTab, setCurrTab] = useState<string>('');
  const paymentProfileRef = useRef<PaymentProfile | undefined>(undefined);
  const handleError = useErrorHandler();
  const { showAMSCard } = useCustomModalContext();
  const flatListRef = useRef<FlatList>(null);
  const tabItems: TabItem[] = [
    {
      name: 'se-dollars',
      displayName: 'SE Dollars',
      onPress: () => setCurrTab('se-dollars'),
    },
    {
      name: 'clippedSavings',
      displayName: 'Clipped Savings',
      onPress: () => setCurrTab('clippedSavings'),
    },
    {
      name: 'payment-methods',
      displayName: 'Payment Methods',
      onPress: () => setCurrTab('payment-methods'),
    },
  ];

  useRefetchOnFocus(refetch);

  const handleEdit = (item: PaymentProfile) => {
    navigation.navigate(RootTabRoutes.Account, {
      screen: AccountStackRoutes.AddEditPaymentMethod,
      params: { paymentMethodId: item.CustomerPaymentMethodId, calledFrom: showBack ? 'PaymentMethods' : 'Wallet' },
    });
  };

  const handleSetDefault = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowSetDefaultModal(true);
  };

  const handleSetDefaultPayment = () => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.updatePaymentProfile({ ...paymentProfileRef.current, IsDefault: true }),
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetch();
        },
        onError: (e) => {
          handleError(e);
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowSetDefaultModal(false);
  };

  const handleAddPaynmentMethod = useCallback(() => {
    navigation.navigate(RootTabRoutes.Account, {
      screen: AccountStackRoutes.AddEditPaymentMethod,
      params: { paymentMethodId: undefined, calledFrom: showBack ? 'PaymentMethods' : 'Wallet' },
    });
  }, [navigation, showBack]);

  const handleRemove = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowDeleteModal(true);
  };

  const handleRemovePaymentMethod = async (): Promise<void> => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.deletePaymentProfile({ ...paymentProfileRef.current }),
        onError: (e) => {
          handleError(e);
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetch();
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowDeleteModal(false);
  };

  const renderItem = ({ item, index }: ListRenderItemInfo<PaymentProfile>) => (
    <WalletItem onEdit={handleEdit} item={item} onRemove={handleRemove} onSetDefault={!item.IsDefault ? handleSetDefault : undefined} index={index + 1} />
  );

  const ListHeader = useCallback(() => (data?.length ? <Text style={[appStyles.bodyLeftBold, styles.title]}>Payment Methods</Text> : <></>), [data?.length]);

  const EmptyList = useCallback(
    () => (
      <View testID="noPaymentMethod" style={styles.emptyList}>
        <Text style={styles.description} numberOfLines={2} testID="noPaymentSavedMsg">
          You have no saved payment methods on your account.
        </Text>
      </View>
    ),
    [],
  );

  const ListFooter = useCallback(() => {
    return (
      <TouchableOpacity activeOpacity={0.7} style={styles.footerContainer} onPress={handleAddPaynmentMethod}>
        <Icon
          name={'circle-plus'}
          fill={colors.white}
          stroke={colors.red}
          strokeSecondary={colors.darkCream}
          size={35}
          style={{ width: 35, height: 35 }}
          testID="circleplus"
        />
        <Text style={styles.addText} testID="addPaymentText">
          Add Payment Method
        </Text>
      </TouchableOpacity>
    );
  }, [handleAddPaynmentMethod]);

  useEffect(() => {
    const unsubscribeTabPress = navigation.addListener('tabPress', () => {
      flatListRef.current?.scrollToOffset({ offset: 0 });
    });
    return () => {
      unsubscribeTabPress();
    };
  }, [flatListRef, navigation]);

  useEffect(() => {
    if (vouchersState !== 'loading') {
      if (toDollarsTab) {
        setCurrTab('se-dollars');
        return;
      }
      if (!acceptedVouchers?.length && !availableVouchers?.length && !toDollarsTab) {
        setCurrTab('payment-methods');
      }
      if (!currTab && (acceptedVouchers?.length || availableVouchers?.length)) {
        setCurrTab('se-dollars');
      }
      if (currTab === 'se-dollars') {
        setCurrTab('se-dollars');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedVouchers, availableVouchers, vouchersState, toDollarsTab]);

  return (
    <Screen testID="WalletScreen" style={utilityStyles.pb0}>
      <CartInfoBanner />
      <NavigationHeader
        showShadow={false}
        style={{ shadowOpacity: 0 }}
        link={link}
        linkParams={linkParams}
        title={showBack ? undefined : 'Wallet'}
        icon={showBack ? undefined : <></>}
        next={<Icon name="se-barcode-icon" size={40} stroke={'rgba(0,0,0,0.1)'} />}
        onNext={showAMSCard}
        subStyle={[{ alignItems: showBack ? 'flex-start' : 'center' }, utilityStyles.pt2]}>
        {showBack && <HeaderTitle>Wallet</HeaderTitle>}
      </NavigationHeader>
      {isBusy || isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <View style={styles.tabWrapper}>
            <Tabbar tabs={tabItems} selectedTabName={currTab} />
          </View>
          {currTab === 'payment-methods' && (
            <FlatList
              ref={flatListRef}
              contentContainerStyle={styles.container}
              data={data}
              testID={'payment-method-list'}
              keyExtractor={(_: PaymentProfile, idx: number) => idx.toString()}
              renderItem={renderItem}
              onRefresh={refetch}
              refreshing={isRefetching}
              ListHeaderComponent={<ListHeader />}
              ListFooterComponent={<ListFooter />}
              ListEmptyComponent={<EmptyList />}
            />
          )}
          {currTab === 'se-dollars' && <SEDollars />}
          {currTab === 'clippedSavings' && <ClippedSavings style={{ paddingTop: 24 }} />}
        </>
      )}
      {showDeleteModal && (
        <Modal
          visible={showDeleteModal}
          title={'Remove Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowDeleteModal(false)}
          okButtonOnPress={handleRemovePaymentMethod}
          cancelButtonText="Go back"
          okButtonText="Remove">
          <Text testID="removePaymentMethod" style={[appStyles.bodyMediumLight, { textAlign: 'center' }]}>
            Please confirm you'd like to remove this Payment Method from your Wallet.
          </Text>
        </Modal>
      )}
      {showSetDefaultModal && (
        <Modal
          visible={showSetDefaultModal}
          title={'Set as Default Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowSetDefaultModal(false)}
          okButtonOnPress={handleSetDefaultPayment}
          cancelButtonText="Go back"
          okButtonText="Update">
          <Text testID="updatePaymentMethod" style={[appStyles.bodyMediumLight, { textAlign: 'center' }]}>
            Please confirm you'd like to set this Payment Method as default.
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    ...utilityStyles.px2,
  },
  title: {
    alignSelf: 'flex-start',
    marginLeft: 10,
    marginBottom: 15,
    marginTop: 25,
  },
  emptyList: {
    paddingVertical: scale(48),
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'center',
    width: '70%',
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  addText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    color: colors.darkText,
    marginLeft: 8,
  },
  tabWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.borderGray,
    width: screenWidth,
    transform: [{ translateX: -5 }],
  },
});

export default WalletScreen;

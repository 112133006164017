import TextInput from '@components/TextInput';
import colors from '@config/colors';
import { appStyles } from '@styles';
import React, { forwardRef } from 'react';
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, Platform, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon, { IconProps } from './Icon';

export interface IconTextInputProps extends NativeTextInputProps {
  width?: string | number | undefined;
  icon?: IconProps;
  iconPress?: () => void;
  errorStyle?: StyleProp<ViewStyle>;
  fieldStyleEx?: StyleProp<ViewStyle>;
}

const IconTextInput = forwardRef<NativeTextInput, IconTextInputProps>(({ icon, width, iconPress, errorStyle, fieldStyleEx, ...otherProps }, ref) => {
  return (
    <View style={[appStyles.formField, errorStyle, { width }, { flexDirection: 'row' }, fieldStyleEx]}>
      <TextInput
        testID="inputTxt"
        keyboardType={
          otherProps.keyboardType ??
          Platform.select({
            ios: 'ascii-capable',
            android: 'email-address',
          })
        }
        placeholderTextColor={colors.medium}
        style={[
          appStyles.formFieldText,
          otherProps.multiline ? appStyles.formFieldMultilineText : {},
          { flex: 1, alignSelf: 'stretch', color: colors.darkText },
        ]}
        ref={ref}
        {...otherProps}
      />
      {icon && (
        <TouchableOpacity style={{ paddingHorizontal: 5 }} onPress={() => iconPress && iconPress()} testID={icon.testID ?? 'iconClick'}>
          <Icon {...icon} testID={icon.testID ?? 'icon'} />
        </TouchableOpacity>
      )}
    </View>
  );
});

export default IconTextInput;

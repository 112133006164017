import Text from '@components/Text';
import { FontFamily } from '@config/fonts';
import { lineHeight, scale } from '@styles/constants';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

export interface NutrientRowProps {
  label: string;
  value: string;
  indent: boolean;
  dailyValue: string;
  unit: string;
  isBold: boolean;
  height: number;
  precisionCode: string | undefined;
}

const NutrientRow = ({ label, value, unit, dailyValue, indent, isBold, precisionCode }: Omit<NutrientRowProps, 'height'>) => {
  return (
    <View style={styles.rowContainer}>
      <View style={styles.labelView}>
        <View style={styles.valueContainer}>
          <Text style={[styles.textstyle, { fontFamily: isBold ? FontFamily.LarsseitExtraBold : FontFamily.LarsseitLight, marginLeft: indent ? '5%' : 0 }]}>
            {label}
          </Text>
          {precisionCode && (
            <View style={styles.precisionCodeView}>
              <Text style={styles.precisionCodeText}>{precisionCode}</Text>
            </View>
          )}
          {value && (
            <Text style={[styles.textValuestyle, { marginLeft: precisionCode ? 0 : '2%' }]}>
              {value}
              {unit}
            </Text>
          )}
        </View>

        {dailyValue && <Text style={[styles.value]}>{dailyValue}</Text>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  textstyle: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: Platform.select({ ios: scale(16) }),
    marginLeft: 2,
  },
  rowContainer: {
    marginTop: 0,
    marginLeft: 0,
    padding: 0,
    paddingVertical: 4,
  },
  textValuestyle: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
  },
  value: {
    fontFamily: FontFamily.LarsseitExtraBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
  },
  labelView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  valueContainer: {
    flexDirection: 'row',
    width: '70%',
  },
  precisionCodeView: {
    marginLeft: '2%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  precisionCodeText: {
    textAlign: 'center',
    lineHeight: Platform.select({ ios: scale(20) }),
    fontSize: scale(20),
  },
});

export default NutrientRow;

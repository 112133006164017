/* eslint-disable no-bitwise */
import {
  AddLineItem,
  AddressInput,
  Cart,
  CartCustomFieldName,
  CustomerContact,
  FulfillmentStore,
  InventoryMode,
  LineItem,
  LineItemChanges,
  LineItemCustomFieldName,
  Money,
  MyCartUpdateAction,
  OfflinePaymentType,
  Order,
  Product,
  ProductVariant,
  ProductVariantAvailability,
  RawProductAttribute,
  ShippingMethodName,
  SubstitutionType,
} from '@fieldera-raleys/client-commercetools/schema';
import { defaultMoney } from '@fieldera-raleys/client-commercetools/utils';
import { Address, CartValidationResult, Order as OrderCommon, ProductType, ShopType, TimeSlot, ValidationError } from '@fieldera-raleys/client-common';
import { storeService as bwStoreService } from '@services/brandywine';
import { Me, productService, storeService } from '@services/commerceTools';
import { getCustomerContact, getDefaultShipping } from '@utils/cartHelper';
import logger from '@utils/logger';
import {
  getAllowSubstitution,
  getCalculatedTipMoney,
  getCartCustomerContact,
  getDeliveryTip,
  getFulfillmentInstructions,
  getItemAttributeValue,
  getOfflinePayment,
  getOrderNote,
  getOriginalOrderNumber,
  getPaymentAuthorization,
  getPickupPersonName,
  getProductQuantityIndex,
  getPromotions,
  getSelectedPayment,
  getShippingMethod,
  getSubstitutionItems,
  getTimeSlot,
  invidLineItems,
  lineItemChildren,
  parseDeliveryTip,
  setAllowSubstitution,
  setFulfillmentInstructions,
  setPickupPersonName,
  setSubstitutionItems,
  setSubstitutionType,
} from '@utils/orderHelpers';
import { getProductAttributeValue, getProductAvailablity, getProductsfromCommerceTools } from '@utils/productHelper';
import { CartMutex as Mutex } from '@utils/semaphore';
import _ from 'lodash';
import Config from 'react-native-config';
import { create } from 'zustand';
import useCommerceToolsStore from './commerceToolsStore';
import { CartStoreType, ProductKey } from './storeTypes';

type ProdDataType = {
  [key: string]: Product;
};

type AttrDataType = {
  [key: string]: RawProductAttribute[];
};

export const estimateProductWeight = (product: Product, quantity: number) => {
  let sellType = getProductAttributeValue('unitSellType', product?.masterData?.current?.masterVariant?.attributesRaw ?? []);
  if (!sellType) {
    sellType = { key: 'byEach' };
  }
  const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', product?.masterData?.current?.masterVariant?.attributesRaw ?? []);
  const unitBuyIncrement = getProductAttributeValue('unitBuyIncrement', product?.masterData?.current?.masterVariant?.attributesRaw ?? []);

  const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', product?.masterData?.current?.masterVariant?.attributesRaw ?? []);
  let estimatedWeight;
  if (sellType && sellType.key === 'byWeight') {
    estimatedWeight = quantity > 0 ? unitBuyIncrement * --quantity! + unitBuyMinimum : 0;
  } else if (sellType && sellType.key === 'weightByEach') {
    estimatedWeight = (unitAverageBuyWeight ?? 1) * quantity;
  }

  return estimatedWeight;
};

const validateLineItem = (lineItem: LineItem, productVariant: ProductVariant, storeNumber?: string, useDefaultStore?: boolean): ValidationError | undefined => {
  let result: ValidationError | undefined;
  let defaultStoreAvailability;
  const lineItemVariant = lineItem.variant;
  const priceChanged =
    (useDefaultStore ? lineItem?.price?.value.centAmount ?? 0 : lineItemVariant?.price?.value.centAmount ?? 0) !==
    (productVariant?.price?.value.centAmount ?? 0);
  const availablity = getAvailablity(productVariant, storeNumber ?? lineItem.supplyChannel?.key);
  if (!availablity?.isOnStock && useDefaultStore) {
    defaultStoreAvailability = getAvailablity(productVariant, Config.CT_DEFAULT_STORE);
  }
  let isInStock = availablity?.isOnStock ?? defaultStoreAvailability?.isOnStock ?? false;

  if (priceChanged || !isInStock || (availablity?.availableQuantity ?? defaultStoreAvailability?.availableQuantity ?? 0) < lineItem.quantity) {
    if (priceChanged && (!productVariant?.price || !lineItemVariant?.price)) {
      isInStock = false;
    }
    result = {
      id: lineItem.id,
      sku: useDefaultStore ? lineItem.productKey : lineItemVariant?.sku ?? undefined,
      isInStock: isInStock,
      priceChanged: priceChanged,
      updatedPrice: productVariant?.price?.value.centAmount,
      availableQuantity: availablity?.availableQuantity ?? defaultStoreAvailability?.availableQuantity ?? 0,
    } as ValidationError;
  }
  return result;
};

const getOrderFields = (order: OrderCommon) => {
  const cc: CustomerContact = {
    sepiNumber: order.customer.sepiNumber,
    firstName: order.customer.firstName,
    lastName: order.customer.lastName,
    email: order.customer.email,
    phone: order.customer.phone,
    allowText: order.alternateContactAllowText ?? false,
    loyaltyId: order.customer.loyaltyId,
  };

  const fields: { name: CartCustomFieldName; value?: string | string[] | number | Money | boolean | null }[] = [
    { name: 'customerContact', value: JSON.stringify(cc) },
  ];
  fields.push({ name: 'deliveryTip', value: { ...defaultMoney, centAmount: order.tipAmount * 100 } });
  if (order.tipPercent) {
    fields.push({ name: 'deliveryTipPercent', value: order.tipPercent });
  }
  if (order.orderNote) {
    fields.push({ name: 'orderNote', value: order.orderNote });
  }

  return fields;
};

const findProduct = (products: Product[], sku: string): Product | undefined => {
  return products.find((p) => {
    return p.masterData.current?.masterVariant.sku === sku || (p.masterData.current?.variants ?? []).findIndex((v) => v.sku === sku) > -1;
  });
};

const findProductVariant = (product: Product, sku: string): ProductVariant | undefined => {
  if (product.masterData.current?.masterVariant.sku === sku) {
    return product.masterData.current?.masterVariant;
  } else {
    return product.masterData.current?.variants.find((p) => p.sku === sku);
  }
};

const getAvailablity = (masterVariant: ProductVariant, storeNumber?: string): ProductVariantAvailability | undefined => {
  if (!storeNumber) {
    return undefined;
  }
  const priceChannel = masterVariant?.price?.channel;
  if (!priceChannel) {
    return undefined;
  }
  const pricingAttributes = masterVariant?.price?.custom?.customFieldsRaw;
  const inventoryMode = pricingAttributes?.find((x) => x.name === 'inventoryMode')?.value ?? InventoryMode.None;
  const discontinued = Boolean(pricingAttributes?.find((x) => x.name === 'discontinued')?.value) ?? false;
  const availability = masterVariant?.availability?.channels.results.find((c) => c.channel?.key === storeNumber)?.availability;
  return {
    isOnStock: !discontinued || (inventoryMode === InventoryMode.ReserveOnOrder && !availability),
    availableQuantity: discontinued ? 0 : inventoryMode !== InventoryMode.None ? availability?.availableQuantity : 9999,
  };
};

const toAddressInput = (address?: Address, country: string = 'US') => {
  if (!address) {
    return undefined;
  }
  const addr = [address.address1];
  if (address.address2) {
    addr.push(', ', address.address2);
  }
  if (address.address3) {
    addr.push(', ', address.address3);
  }

  return {
    key: String(address.id),
    streetName: addr.join(''),
    lastName: address.lastName,
    firstName: address.firstName,
    city: address.city,
    state: address.state,
    postalCode: address.postalCode,
    company: address.companyName ?? null,
    phone: address.phone,
    country: address.country ?? country,
    email: address.email,
    custom: {
      type: {
        key: 'raleys-address-custom-fields',
      },
      fields: [
        { name: 'addressType', value: JSON.stringify(address.addressType ?? 'home') },
        { name: 'isValidated', value: JSON.stringify(address.isValidated ?? false) },
        { name: 'validatedDate', value: JSON.stringify(address.isValidated && address.validatedDate ? address.validatedDate : new Date()) },
      ],
    },
  } as AddressInput;
};

type CartStoreTypePrivate = CartStoreType & {
  postSync: (newCart?: Cart) => void;
};

export const useCartStore = create<CartStoreTypePrivate>()((set, get) => ({
  cart: undefined,
  tombOpenTo: undefined,
  tombIsOpen: false,
  transitCart: undefined,
  cartData: {},

  setCartData: (data: { [name: string]: string | boolean | undefined | null }) => {
    const cData = get().cartData;
    set((state: CartStoreType) => ({ ...state, cartData: { ...cData, ...data } }));
  },

  tombOpen: (newState: boolean) => {
    set((state: CartStoreType) => ({ ...state, tombIsOpen: newState }));
  },

  tombClear: () => {
    set((state: CartStoreType) => ({ ...state, tombOpenTo: undefined }));
  },

  tombSet: (page: string | undefined) => {
    set((state: CartStoreType) => ({ ...state, tombOpenTo: page }));
  },

  getMaxProductQuantity: async (sku?: string | null, reserved: number = 0): Promise<number> => {
    const { store } = useCommerceToolsStore.getState();
    if (!store || !sku) {
      return Promise.resolve(+(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
    }
    const priceChannelIds = store?.distributionChannels?.map((x) => x.id)[0] ?? undefined;
    const availablityChannelIds = store?.supplyChannels?.map((x) => x.id) ?? undefined;
    const products = await productService.getProducts({ skus: [sku] }, priceChannelIds, availablityChannelIds);
    const product = findProduct(products, sku); // products[0]
    if (!product || !product.masterData.current?.masterVariant) {
      return +(Config.MAX_LINE_ITEM_QUANTITY ?? '0');
    }

    const availability = getAvailablity(product.masterData.current?.masterVariant, store.key);
    const availableQuantity = (availability ? availability : { availableQuantity: 0 }).availableQuantity + reserved;

    let variant = product.masterData.current.variants.find((x) => x.sku === sku);
    if (!variant) {
      variant = product.masterData.current.masterVariant;
    }
    if (variant) {
      const maxQtyAttr = getProductAttributeValue('maxCartQty', variant.attributesRaw);
      const unitsPerPackage = getProductAttributeValue('unitsPerPackage', variant.attributesRaw);
      const sellType = getProductAttributeValue('unitSellType', variant.attributesRaw);
      // const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', variant.attributesRaw);
      const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', variant.attributesRaw);
      if (sellType) {
        switch (sellType?.key) {
          case 'weightByEach':
            // logger.log('AQ:', availableQuantity, 'UBM:', unitBuyMaximum, 'CFM:', Config.MAX_LINE_ITEM_QUANTITY);
            // logger.log(Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +Config.MAX_LINE_ITEM_QUANTITY));
            return Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
          case 'byWeight':
            // logger.log(Math.min(availableQuantity, +unitBuyMaximum ?? +Config.MAX_LINE_ITEM_QUANTITY * +unitsPerPackage));
            return Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +(Config.MAX_LINE_ITEM_QUANTITY ?? '0') * +unitsPerPackage);
          default:
            return Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
        }
      }
      if (unitBuyMaximum) {
        return Math.min(+unitBuyMaximum, availableQuantity);
      }
      if (maxQtyAttr) {
        return Math.min(+maxQtyAttr, availableQuantity);
      }
    }
    return Math.min(availableQuantity, +(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
  },

  initialize: async (): Promise<Cart> => {
    return await Mutex.withMutex(async () => {
      var cart = await Me.cartService.getCart();
      if (!cart) {
        cart = await Me.cartService.createCart({
          customerContact: getCustomerContact(),
          deliveryTipDefault: +(Config.DEFAULT_DELIVERY_TIP_PERCENT ?? '2'),
          shippingAddress: getDefaultShipping(),
        });
      } else {
        const orphans = cart.lineItems.filter((li, indx, arr) => {
          const cust = li.custom?.customFieldsRaw?.find((x) => x.name === 'parentLineItemId');
          if (cust) {
            if (!arr.find((li2) => li2.id === cust.value)) {
              return true;
            }
          }
          return false;
        });
        if (orphans.length) {
          cart = await Me.cartService.removeLineItem(
            cart.id,
            cart.version,
            orphans.map((x) => x.id),
            [],
          );
        }
      }
      //updatedCart = await addLineItem(updatedCart?.id, updatedCart?.version, children);
      set((state: CartStoreType) => ({ ...state, cart, transitCart: undefined, cartData: {} }));
      return cart;
    }, 'initialize');
  },

  clearMyCarts: async (): Promise<void> => {
    return await Mutex.withMutex(async () => {
      for (let retry = 3; ; --retry) {
        const transitCart = get().transitCart;
        try {
          let cart = transitCart ? { ...transitCart } : get().cart;
          for (;;) {
            if (!cart) {
              cart = await Me.cartService.getCart();
              if (!cart) {
                break;
              }
            } else {
              await Me.cartService.deleteCart(cart.id, cart.version);
              cart = undefined;
            }
          }
        } catch (e) {
          if (retry < 0) {
            break;
          }
        }
      }
      set((state: CartStoreType) => ({ ...state, cart: undefined, transitCart: undefined, cartData: { cartReset: true } }));
    }, 'resetCart');
  },

  resetMyCart: async (): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      let cart = transitCart ? { ...transitCart } : get().cart;
      for (;;) {
        if (!cart) {
          cart = await Me.cartService.getCart();
          if (!cart) {
            break;
          }
        } else {
          const originalOrder = getOriginalOrderNumber(cart);
          if (originalOrder) {
            try {
              await Me.cartService.deleteCart(cart.id, cart.version);
            } catch (e) {}
            cart = undefined;
          } else {
            break;
          }
        }
      }
      set((state: CartStoreType) => ({ ...state, cart: undefined, transitCart: undefined, cartData: { cartReset: true } }));
    }, 'resetCart');
  },

  postSync: (newCart?: Cart) => {
    if (Mutex.taskCount()) {
      // ok to check for 0 within the mutex
      if (newCart) {
        set((state: CartStoreType) => ({ ...state, transitCart: newCart }));
      }
    } else {
      if (newCart) {
        set((state: CartStoreType) => ({ ...state, cart: newCart, transitCart: undefined }));
      } else {
        const transitCart = get().transitCart;
        let cart = get().cart;
        if (!cart || (transitCart && transitCart.version > cart.version)) {
          cart = transitCart;
        }
        set((state: CartStoreType) => ({ ...state, cart: cart, transitCart: undefined }));
      }
    }
  },

  emptyCart: async (): Promise<void> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('emptyCart: Cart not initialized');
    }
    if (!tc) {
      tc = { ...c };
    }

    c.lineItems = [];
    // update local state
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      let newCart: Cart | undefined;
      try {
        if (cart) {
          const itemIds = (cart.lineItems ?? []).map((li) => li.id); // remove all items
          const actions: MyCartUpdateAction[] = [];
          if (cart.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryTipPercent')) {
            actions.push(
              { setCustomField: { name: 'deliveryTipPercent', value: JSON.stringify(0) } },
              { setCustomField: { name: 'deliveryTip', value: JSON.stringify(defaultMoney) } },
            );
          }
          newCart = await Me.cartService.removeLineItem(transitCart?.id ?? cart.id, transitCart?.version ?? cart.version, itemIds, actions);
        }
        get().postSync(newCart);
      } catch (e) {
        get().postSync();
        throw e;
      }

      return;
    }, 'removeLineItem');

    return Promise.resolve();
  },

  removeLineItem: async (lineItemId: string): Promise<string> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('emptyCart: Cart not initialized');
    }
    if (!tc) {
      tc = { ...c };
    }

    // update local state
    const pl = c.lineItems.find((x) => x.id === lineItemId);
    if (pl) {
      const customField = pl.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
      const childLineItemIds = customField ? String(customField.value).split(',') : [];
      childLineItemIds.push(pl.id);
      c.lineItems = c.lineItems.filter((i) => childLineItemIds.find((id) => id !== i.id));
    }

    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    // queue up the server remove
    Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        if (!cart) {
          throw new Error('removeLineItem: Cart not initialized');
        }
        let newCart: Cart | undefined;
        const parentLineItem = cart.lineItems.find((x) => x.id === lineItemId);
        if (parentLineItem) {
          const customField = parentLineItem.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
          let childLineItemIds = customField ? String(customField.value).split(',') : [];
          childLineItemIds = childLineItemIds.filter((cid) => cart.lineItems.some((li) => li.id === cid));
          childLineItemIds.push(parentLineItem.id);
          newCart = await Me.cartService.removeLineItem(cart?.id, cart?.version, childLineItemIds);
        }
        get().postSync(newCart);
      } catch (e) {
        get().postSync();
      }
      return lineItemId;
    }, 'removeLineItem');

    return Promise.resolve(lineItemId);
  },

  addCustomizableLineItem: async (lineItems: AddLineItem[]): Promise<string> => {
    let tc = get().transitCart;
    let c = get().cart;
    const defaultStore = Config.CT_DEFAULT_STORE;
    if (!c) {
      throw new Error('addCustomizableLineItem: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // TODO: simulate add, but wait for now for it to appear after sync
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    return await Mutex.withMutex(async () => {
      try {
        const transitCart = get().transitCart;
        const cart = transitCart ? { ...transitCart } : get().cart;
        if (!cart) {
          throw new Error('addCustomizableLineItem: Cart not initialized (async)');
        }
        //some crazy logic need to re-think
        // 1. add the parent to the cart
        // 2. get id of the added parent
        // 3. set parent-id on children
        // 4. save children
        // 5. update parent custom attrubute with pipe seperated child-ids -- forn now saving the child product keys to the parent on step 1
        // hope everything saved as its not wrapped in a transaction
        // also pull product info and add attributes to each line item

        // build local productData from params, fetch only the missing ones
        let productData: ProdDataType = {};
        const skus = lineItems.reduce((acc, li): string[] => {
          if (li.product) {
            productData[li.sku] = li.product;
          } else if (!productData[li.sku]) {
            acc.push(li.sku);
          }
          return acc;
        }, [] as string[]);

        if (skus.length) {
          // fetch missing products if any
          logger.warn('addCustomizableLineItem called without product info, having to make an API call to fetch');
          const pData = await getProductsfromCommerceTools(skus);
          if (!pData || !pData.length) {
            throw new Error('addCustomizableLineItem: unable to get product information');
          }
          pData.forEach((p: Product) => {
            if (p.masterData?.current?.masterVariant.sku) {
              productData[p.masterData.current.masterVariant.sku] = p;
              p.masterData.current.variants.forEach((vi) => {
                if (vi.sku && !productData[vi.sku]) {
                  productData[vi.sku] = p;
                }
              });
            }
          });
        }

        let fields: { name: LineItemCustomFieldName; value?: string | number | string[] | Money | undefined }[] = [];
        const parent = lineItems.find((x) => !x.parentLineItemId);
        let lineItemWeight, priceFields, sellType, qty;
        if (parent) {
          priceFields = productData[parent.sku]?.masterData?.current?.masterVariant.price?.custom?.customFieldsRaw;
          sellType = getProductAttributeValue('unitSellType', productData[parent.sku]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
          if (!sellType) {
            sellType = { key: 'byEach', label: 'By Each' };
          }
          fields = [{ name: 'unitSellType', value: sellType.key }];
          qty = sellType.key === 'byWeight' ? 1 : parent.quantity;
          lineItemWeight = estimateProductWeight(productData[parent.sku], qty);
          if (!lineItemWeight) {
            if (getProductAttributeValue('estimatedTotalWeight', productData[parent.sku]?.masterData?.current?.masterVariant?.attributesRaw ?? [])) {
              fields.push({ name: 'estimatedTotalWeight', value: undefined });
            }
          } else {
            fields.push({ name: 'estimatedTotalWeight', value: lineItemWeight });
          }
          if (priceFields) {
            let val = priceFields.find((pcv) => pcv.name === 'regularPrice')?.value;
            if (val) {
              fields.push({ name: 'regularPrice', value: val });
            }
          }
          parent.fields?.forEach((f) => {
            if (!fields.find((f1) => f1.name === f.name)) {
              fields.push(f);
            }
          });
          parent.fields = fields;
          let updatedCart: Cart = await Me.cartService.addLineItem(cart.id, cart.version, [parent]);

          // find the new item, operation is versioned so new items should be guaranteed
          const parentLineItem = (updatedCart?.lineItems ?? []).find((x) => (cart?.lineItems ?? []).findIndex((cc) => cc.id === x.id) < 0);
          if (!parentLineItem) {
            // this should never be the case on success, but checking to make sure
            throw new Error('addLineItem: Cannot idenitfy newly added item');
          }

          // NOTE: after this, need to come up with how to handle erros as it will break the custom product
          if ((parentLineItem?.productType?.name ?? ProductType.UNCKNOWN) === ProductType.CONFIGURABLE) {
            const children = lineItems.filter((li) => li.parentLineItemId && li.parentLineItemId === parent?.sku);
            children.forEach((li, idx, lis) => {
              priceFields = productData[li.sku]?.masterData?.current?.masterVariant.price?.custom?.customFieldsRaw;
              sellType = getProductAttributeValue('unitSellType', productData[li.sku]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
              if (!sellType) {
                sellType = { key: 'byEach', label: 'By Each' };
              }
              fields = [{ name: 'unitSellType', value: sellType.key }];
              qty = sellType.key === 'byWeight' ? 1 : li.quantity;
              lineItemWeight = estimateProductWeight(productData[li.sku], qty);
              if (!lineItemWeight) {
                if (getProductAttributeValue('estimatedTotalWeight', productData[li.sku]?.masterData?.current?.masterVariant?.attributesRaw ?? [])) {
                  fields.push({ name: 'estimatedTotalWeight', value: undefined });
                }
              } else {
                fields.push({ name: 'estimatedTotalWeight', value: lineItemWeight });
              }
              if (priceFields) {
                let val = priceFields.find((pcv) => pcv.name === 'regularPrice')?.value;
                if (val) {
                  fields.push({ name: 'regularPrice', value: val });
                }
              }
              lis[idx].fields?.forEach((f) => {
                if (!fields.find((f1) => f1.name === f.name)) {
                  fields.push(f);
                }
              });
              lis[idx].fields = fields;
              li.parentLineItemId = parentLineItem!.id;
            });

            updatedCart = await Me.cartService.addCustomLineItem(updatedCart?.id, updatedCart?.version, children, [], 'en-US', 'USD', 'US', defaultStore);
            // find all children for given parent id
            const childLineItems = updatedCart.lineItems.filter((x) => {
              const customField = x.custom?.customFieldsRaw?.find((a) => a.name === 'parentLineItemId');
              return customField?.value === parentLineItem!.id;
            });

            updatedCart = await Me.cartService.setLineItemCustomField(updatedCart.id, updatedCart.version, parentLineItem!.id, [
              { name: 'childLineItems', value: childLineItems.map((ct) => ct.id) },
            ]);
          }

          get().postSync(updatedCart);
          return parentLineItem.id;
        } else {
          throw new Error('cannot idenitfy primary sku');
        }
      } catch (e: any) {
        //TODO: rollback logic
        get().postSync();
        logger.error(e);
        throw e;
      }
    }, 'addCustomizableLineItem');
  },

  addStandardLineItems: async (inItems: AddLineItem[]): Promise<void | string> => {
    let tc = get().transitCart;
    let c = get().cart;
    var lineItemId: string | undefined;
    if (!c) {
      throw new Error('addStandardLineItems: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // TODO: simulate add, but wait for now for it to appear after sync
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    return await Mutex.withMutex(async () => {
      try {
        const transitCart = get().transitCart;
        const cart = transitCart ? { ...transitCart } : get().cart;
        if (!cart) {
          throw new Error('addStandardLineItems: Cart not initialized (async)');
        }

        // build local productData from params, fetch only the missing ones
        let productData: ProdDataType = {};
        const skus = inItems.reduce((acc, li): string[] => {
          if (li.product) {
            productData[li.sku] = li.product;
          } else if (!productData[li.sku]) {
            acc.push(li.sku);
          }
          return acc;
        }, [] as string[]);

        if (skus.length) {
          // fetch missing products if any
          logger.warn('addStandardLineItems called without product info, having to make an API call to fetch');
          const pData = await getProductsfromCommerceTools(skus);
          if (!pData || !pData.length) {
            throw new Error('addStandardLineItems: unable to get product information');
          }
          pData.forEach((p: Product) => {
            if (p.masterData?.current?.masterVariant.sku) {
              productData[p.masterData.current.masterVariant.sku] = p;
              p.masterData.current.variants.forEach((vi) => {
                if (vi.sku && !productData[vi.sku]) {
                  productData[vi.sku] = p;
                }
              });
            }
          });
        }

        let lineItems: AddLineItem[] = inItems.filter((li) => {
          return !get().getLineItemQuantity({ sku: li.sku }); // if already in cart, standard items can't be added this way to prevent CT confusion
        });

        lineItems.forEach((li, idx, lis) => {
          if (!productData[li.sku]) {
            // can throw here if found item without product, otherwise it will get treated as byEach?
            logger.error('Missing Product information in addStandardLineItems: ' + li.sku);
          }
          const priceFields = productData[li.sku]?.masterData?.current?.masterVariant.price?.custom?.customFieldsRaw;
          let sellType = getProductAttributeValue('unitSellType', productData[li.sku]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
          if (!sellType) {
            sellType = { key: 'byEach', label: 'By Each' };
          }
          let fields: { name: LineItemCustomFieldName; value?: string | number | string[] | Money | undefined }[] = [
            { name: 'unitSellType', value: sellType.key },
          ];
          const lineItemWeight = estimateProductWeight(productData[li.sku], li.quantity);
          if (sellType.key === 'byWeight') {
            lis[idx].quantity = 1;
          }
          if (!lineItemWeight) {
            if (getProductAttributeValue('estimatedTotalWeight', productData[li.sku]?.masterData?.current?.masterVariant?.attributesRaw ?? [])) {
              fields.push({ name: 'estimatedTotalWeight', value: undefined });
            }
          } else {
            fields.push({ name: 'estimatedTotalWeight', value: lineItemWeight });
          }
          if (priceFields) {
            let val = priceFields.find((pcv) => pcv.name === 'regularPrice')?.value;
            if (val) {
              fields.push({ name: 'regularPrice', value: val });
            }
          }
          lis[idx].fields?.forEach((f) => {
            if (!fields.find((f1) => f1.name === f.name)) {
              fields.push(f);
            }
          });
          lis[idx].fields = fields;
        });

        let updatedCart: Cart = await Me.cartService.addLineItem(cart.id, cart.version, lineItems);
        if (lineItems.length === 1) {
          lineItemId = (updatedCart?.lineItems ?? []).find((x) => (cart?.lineItems ?? []).findIndex((cc) => cc.id === x.id) === -1)?.id;
        }

        get().postSync(updatedCart);
        if (lineItemId && lineItems.length === 1) {
          return lineItemId;
        }
      } catch (e: any) {
        get().postSync();
        logger.error(e);
        throw e;
      }
    }, 'addStandardLineItems');
  },

  getLineItemQuantityIndex: (product: Product) => {
    const cart: Cart | undefined = get().cart;
    if (!cart) {
      return 0;
    }

    if (product) {
      var qty = 0;
      const sku = product.masterData.current?.masterVariant.sku;

      if (sku) {
        const lineItem = cart.lineItems.find((x) => x.variant?.sku === sku);

        const quantity = lineItem?.quantity ?? 0;
        const estimatedTotalWeight = getItemAttributeValue('estimatedTotalWeight', lineItem?.custom?.customFieldsRaw ?? []) ?? 0;

        qty = getProductQuantityIndex(product.masterData.current!, quantity, estimatedTotalWeight);

        return qty;
      } else {
        return 0;
      }
    } else {
      throw new Error('product undefined');
    }
  },

  getLineItemQuantity: (productKey: ProductKey) => {
    const cart: Cart | undefined = get().cart;
    if (!cart) {
      return 0;
    }
    if (productKey) {
      var qty = 0;
      if (productKey.lineItemId) {
        const lineItem = cart.lineItems.find((x) => x.id === productKey.lineItemId);
        return lineItem?.quantity;
      } else if (productKey.sku) {
        for (var i = 0; i < cart.lineItems.length; i++) {
          if (cart.lineItems[i].variant?.sku === productKey.sku) {
            qty += cart.lineItems[i]?.quantity;
          }
          // const lineItem = cart.lineItems.find((x) => x.variant?.sku === productKey.sku);
        }
        return qty;
      } else {
        return 0;
      }
    } else {
      throw new Error('productKey undefined');
    }
  },

  getLineItemEstimatedWeight: (productKey: ProductKey) => {
    const cart: Cart | undefined = get().cart;
    if (!cart) {
      return 0;
    }
    if (productKey) {
      var qty = 0;
      if (productKey.lineItemId) {
        const lineItem = cart.lineItems.find((x) => x.id === productKey.lineItemId);
        return lineItem?.custom?.customFieldsRaw?.find((x) => x.name === 'estimatedTotalWeight')?.value;
      } else if (productKey.sku) {
        for (var i = 0; i < cart.lineItems.length; i++) {
          if (cart.lineItems[i].variant?.sku === productKey.sku) {
            qty += cart.lineItems[i]?.custom?.customFieldsRaw?.find((x) => x.name === 'estimatedTotalWeight')?.value;
          }
          // const lineItem = cart.lineItems.find((x) => x.variant?.sku === productKey.sku);
        }
        return qty;
      } else {
        return 0;
      }
    } else {
      throw new Error('productKey undefined');
    }
  },

  setLineItemQuantity: async (productKey: ProductKey, quantity: number): Promise<boolean> => {
    let c = get().cart;
    let tc = get().transitCart;
    if (!c) {
      throw new Error('setLineItemQuantity: Cart not initialized');
    }
    if (!productKey.sku && !productKey.lineItemId) {
      throw new Error('setLineItemQuantity: Invalid productKey');
    }
    const li: LineItem | undefined = productKey.lineItemId
      ? c.lineItems.find((x: LineItem) => x.id === productKey.lineItemId)
      : c.lineItems.find((x: LineItem) => x.variant?.sku === productKey.sku);

    if (!quantity) {
      // if remove requested
      if (li) {
        get().removeLineItem(li.id);
      } // and if we have the item
      return Promise.resolve(true);
    }

    if (li) {
      // because item can now be calculate by weight and we may not have the product data at the moment will wait for now to update local version
      //
      // li.quantity = quantity;
    }
    // local update first

    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    // remote update queue
    Mutex.withMutex(async () => {
      try {
        const transitCart = get().transitCart;
        const cart = transitCart ? { ...transitCart } : get().cart;
        if (!cart) {
          throw new Error('setLineItemQuantity: Cart not initialized');
        }

        const lineItem: LineItem | undefined = productKey.lineItemId
          ? cart.lineItems.find((x: LineItem) => x.id === productKey.lineItemId)
          : cart.lineItems.find((x: LineItem) => x.variant?.sku === productKey.sku);

        if (!lineItem && !productKey.sku) {
          throw new Error('invalid productKey, either lineItemId or sku is required');
        }
        const productData = await getProductsfromCommerceTools([lineItem?.variant?.sku ?? productKey?.sku ?? '']);
        if (!productData || productData.length !== 1) {
          throw new Error('setLineItemQuantity: unable to get product information');
        }
        const priceFields = productData[0]?.masterData?.current?.masterVariant.price?.custom?.customFieldsRaw;
        let sellType = getProductAttributeValue('unitSellType', productData[0]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
        if (!sellType) {
          sellType = { key: 'byEach', label: 'By Each' };
        }
        let fields: { name: LineItemCustomFieldName; value: string | number | string[] | Money | undefined }[] = [
          { name: 'unitSellType', value: sellType.key },
        ];
        const qty = sellType.key === 'byWeight' ? 1 : quantity;
        if (priceFields) {
          let val = priceFields.find((pcv) => pcv.name === 'regularPrice')?.value;
          if (val) {
            fields.push({ name: 'regularPrice', value: val });
          }
        }
        let updatedCart: Cart | undefined;
        const lineItemWeight = estimateProductWeight(productData[0], quantity);
        if (lineItem) {
          if (!lineItemWeight) {
            if (getProductAttributeValue('estimatedTotalWeight', productData[0]?.masterData?.current?.masterVariant?.attributesRaw ?? [])) {
              fields.push({ name: 'estimatedTotalWeight', value: undefined });
            }
          } else {
            fields.push({ name: 'estimatedTotalWeight', value: lineItemWeight });
          }
          updatedCart = await Me.cartService.setLineItemQuantity(cart.id, cart.version, lineItem.id, qty, fields);
        } else if (productKey.sku) {
          if (lineItemWeight) {
            fields.push({ name: 'estimatedTotalWeight', value: lineItemWeight });
          }
          updatedCart = await Me.cartService.addLineItem(cart.id, cart.version, [{ sku: productKey.sku, quantity: qty, fields: fields }]);
        }

        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
      }
      return true;
    }, 'setLineItemQuantity');

    return Promise.resolve(true);
  },

  getCartQuantity: () => {
    const cart = get().cart;
    if (cart) {
      return invidLineItems(cart.lineItems).reduce((qty, li) => {
        const sellType = getProductAttributeValue('unitSellType', li.variant?.attributesRaw ?? []) ?? { key: 'byEach', label: 'Each' };
        // const sellType: string = li.custom?.customFieldsRaw?.find((f) => f.name === 'unitSellType')?.value ?? 'byEach';
        return qty + (sellType.key === 'byWeight' ? 1 : li.quantity);
      }, 0);
    }
    return 0;
  },

  setPickupPerson: async (name: string): Promise<void> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('setPickupPerson: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // update local
    setPickupPersonName(c, name);
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    // update remote
    Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setPickupPerson: Cart not initialized');
        }
        const pickupPersonName = getPickupPersonName(cart);
        if (!pickupPersonName || !_.isEqual(pickupPersonName, name)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          updatedCart = await _setCustomField(cart?.id, cart?.version, [{ name: 'pickupPersonName', value: name }]); //JSON encoded during map
          if (!updatedCart) {
            // throw should have taken care of it but to close eslint logic
            return;
          }
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setPickupPerson');
  },

  setItemBackup: async (lineItemId: string, backupList: string[]): Promise<void> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('setItemBackup: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // update local
    const localLi = c.lineItems.find((x) => x.id === lineItemId);
    if (!localLi) {
      throw new Error('setItemBackup: invalid lineItemId');
    }
    setSubstitutionItems(localLi, backupList);
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setSubstitutionItems: Cart not initialized');
        }
        const lineItem = cart.lineItems.find((x) => x.id === lineItemId);
        if (!lineItem) {
          throw new Error('setSubstitutionItems: invalid lineItemId');
        }
        //TODO: add compare before call
        const setField = lineItem.custom ? Me.cartService.setLineItemCustomField : Me.cartService.setLineItemCustomType;
        updatedCart = await setField(cart.id, cart.version, lineItem.id, [
          { name: 'substitutionType', value: 'Specific-Items' },
          { name: 'substitutionItems', value: backupList },
        ]);
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setItemSubType');
  },

  setItemSubType: async (lineItemId: string, subType: SubstitutionType = 'Best-Available'): Promise<void> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('setItemSubType: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // update local
    const localLi = c.lineItems.find((x) => x.id === lineItemId);
    if (!localLi) {
      throw new Error('setItemSubType: invalid lineItemId');
    }
    setSubstitutionItems(localLi, []);
    setSubstitutionType(localLi, subType);
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setSubstitutionType: Cart not initialized');
        }
        const lineItem = cart.lineItems.find((x) => x.id === lineItemId);
        if (!lineItem) {
          throw new Error('setSubstitutionType: invalid lineItemId');
        }
        //TODO: add compare before call
        const setField = lineItem.custom ? Me.cartService.setLineItemCustomField : Me.cartService.setLineItemCustomType;
        updatedCart = await setField(cart.id, cart.version, lineItem.id, [
          { name: 'substitutionType', value: subType },
          { name: 'substitutionItems', value: [] },
        ]);
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setItemSubType');
  },

  setSubstAllow: async (allow: boolean = true): Promise<void> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('setAllowSubstitution: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // update local
    setAllowSubstitution(c, allow);
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setAllowSubstitution: Cart not initialized');
        }
        //TODO: add compare before call
        const setField = cart.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
        updatedCart = await setField(cart.id, cart.version, [{ name: 'allowSubstitution', value: allow }]);
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setItemSubType');
  },

  setModifyOrder: async (order?: OrderCommon): Promise<void> => {
    if (!order || !order.extOrderId) {
      return Promise.resolve();
    }

    return await Mutex.withMutex(async () => {
      let cart = await Me.cartService.createCart({});
      const storeNumber = order?.deliverySite?.Facility?.Value?.replace(/(^[0-9]*)[^0-9]*/g, '$1') ?? '1';
      const setField = cart.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
      const fields: { name: CartCustomFieldName; value?: string | string[] | number | Money | boolean | null }[] = [
        { name: 'OriginalOrderNumber', value: order.extOrderId },
        { name: 'OriginalStore', value: storeNumber ?? '' },
      ];
      fields.push(...getOrderFields(order));
      cart = await setField(cart.id, cart.version, fields);

      set((state: CartStoreType) => ({ ...state, cart, transitCart: undefined }));
      return;
    }, 'setModifyOrder');
  },

  setFulfillmentInstructions: async (text: string): Promise<void> => {
    let tc = get().transitCart;
    let c = get().cart;
    if (!c) {
      throw new Error('setFulfillmentInstructions: Cart not initialized');
    }
    if (!tc) {
      tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    }

    // update local
    setFulfillmentInstructions(c, text);
    set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));

    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setFulfillmentInstructions: Cart not initialized');
        }
        const instructions = getFulfillmentInstructions(cart);
        if (!_.isEqual(text, instructions)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          updatedCart = await _setCustomField(cart?.id, cart?.version, [{ name: 'fulfillmentInstruction', value: text }]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setFulfillmentInstructions');
  },

  setOrderNote: async (text: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setOrderNote: Cart not initialized');
        }
        const orderNote = getOrderNote(cart);
        if (!orderNote || !_.isEqual(orderNote, text)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          //TODO: add compare before call
          updatedCart = await _setCustomField(cart?.id, cart?.version, [{ name: 'orderNote', value: text }]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setOrderNote');
  },

  setPaymentProfile: async (customerPaymentMethodId: number, paymentType: OfflinePaymentType = 'Credit-Card-On-Pickup'): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      let fields: { name: CartCustomFieldName; value?: string | string[] | number | Money | null | boolean }[] = [];
      let needUpdate: boolean = false;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setPaymentProfile: Cart not initialized');
        }
        const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
        if (customerPaymentMethodId === 0) {
          const paymentMethodId = getSelectedPayment(cart);
          const offLinePayment = getOfflinePayment(cart);
          if (offLinePayment !== paymentType || paymentMethodId !== 0) {
            needUpdate = true;
            fields = [
              { name: 'customerPaymentMethodId', value: '0' },
              { name: 'offlinePaymentMethodType' as CartCustomFieldName, value: paymentType },
            ];
          }
        } else {
          const paymentMethodId = getSelectedPayment(cart);
          const offLinePayment = getOfflinePayment(cart);
          if (offLinePayment || paymentMethodId !== customerPaymentMethodId) {
            fields = [{ name: 'customerPaymentMethodId', value: '' + customerPaymentMethodId }];
            if (offLinePayment) {
              fields.push({ name: 'offlinePaymentMethodType' as CartCustomFieldName, value: null });
            }
            needUpdate = true;
          }
        }
        if (needUpdate) {
          updatedCart = await _setCustomField(cart?.id, cart?.version, fields);
          get().postSync(updatedCart);
        } else {
          get().postSync();
        }
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setPaymentProfile');
  },

  setPaymentAuthorization: async (authorizationCode: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setPaymentAuthorization: Cart not initialized');
        }
        const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
        const selectedPayment = getPaymentAuthorization(cart);
        if (selectedPayment !== authorizationCode) {
          updatedCart = await _setCustomField(cart?.id, cart?.version, [{ name: 'customerPaymentMethodAuthCode', value: authorizationCode }]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setPaymentAuthorization');
  },

  setDonationAmount: async (amount: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setDonationAmount: Cart not initialized' + amount);
        }

        let value: number = parseFloat(amount.replace(/[^0-9\.]/g, ''));
        value = isNaN(value) ? 0 : value;
        const valueMoney: Money = {
          ...defaultMoney,
          centAmount: Math.round(value * 100),
          fractionDigits: 2,
        };

        const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
        updatedCart = await _setCustomField(cart?.id, cart?.version, [{ name: 'donationAmount', value: valueMoney }]);
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setDonationAmount');
  },

  setDeliveryTip: async (amount?: string): Promise<void> => {
    // let tc = get().transitCart;
    // let c = get().cart;
    // if (!c) {
    //   throw new Error('setDeliveryTip: Cart not initialized');
    // }
    // if (!tc) {
    //   tc = JSON.parse(JSON.stringify(c)); // spread is shallow, some changes are nested
    // }

    // // update local cart with tip
    // set((state: CartStoreType) => ({ ...state, transitCart: tc, cart: c }));
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setDeliveryTip: Cart not initialized');
        }

        // calculate tip
        const currTip = getDeliveryTip(cart);
        const tip = amount ? parseDeliveryTip(amount) : currTip;
        const calculatedTip = getCalculatedTipMoney(cart, tip);

        // skip if it is already correct
        let dTP = cart?.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryTipPercent');
        let dT = cart?.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryTip');
        let needUpdate = false;
        if (tip.tipType === '%') {
          needUpdate = !(dT && dT.value?.centAmount === calculatedTip.centAmount && dTP && dTP.value === tip.tipAmount);
        } else {
          needUpdate = !(dT && dT.value?.centAmount === calculatedTip.centAmount && !dTP);
        }
        if (needUpdate) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          const tipF: {
            name: CartCustomFieldName;
            value?: string | string[] | number | Money | boolean | null;
          }[] = [{ name: 'deliveryTip', value: calculatedTip }];

          if (tip.tipType === '$' || (tip.tipType === '%' && !tip.tipAmount)) {
            if (cart.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryTipPercent')) {
              tipF.push({ name: 'deliveryTipPercent', value: 0 });
            }
          } else {
            tipF.push({ name: 'deliveryTipPercent', value: tip.tipAmount });
          }
          try {
            // try to always update tip, but without good recovery, do not throw on failure
            updatedCart = await _setCustomField(cart.id, cart?.version, tipF);
          } catch (e) {
            logger.log('Unable to recalculate delivery tip in addCustomizableLineItem');
          }
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setDeliveryTip');
  },

  getLineItemNote: (lineItemId: string): string | undefined => {
    const cart = get().cart;
    if (!cart) {
      throw new Error('getLineItemNote: Cart not initialized');
    }
    const lineItem = cart.lineItems.find((x) => x.id === lineItemId);
    if (lineItem) {
      return lineItem.custom?.customFieldsRaw?.find((x) => x.name === 'itemNote')?.value;
    }
  },

  setLineItemNote: async (lineItemId: string, text: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setLineItemNote: Cart not initialized');
        }
        const lineItem = cart.lineItems.find((x) => x.id === lineItemId);
        if (!lineItem) {
          throw new Error('setLineItemNote: invalid lineItemId');
        }
        //TODO: add compare before call
        const setField = lineItem.custom ? Me.cartService.setLineItemCustomField : Me.cartService.setLineItemCustomType;
        updatedCart = await setField(cart.id, cart.version, lineItem.id, [{ name: 'itemNote', value: text }]);
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setLineItemNote');
  },

  getPromoCodeList: (): string[] => {
    const cart = get().cart;
    let codes: string[] = [];
    if (cart) {
      codes = cart.discountCodes.reduce((list, x) => {
        if (x.discountCode) {
          list.push(x.discountCode?.code);
        }
        return list;
      }, codes);
    }
    return codes;
  },

  addPromoCode: async (code: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('addPromoCode: Cart not initialized');
        }
        if (cart.discountCodes.findIndex((x) => x.discountCode?.code === code) < 0) {
          updatedCart = await Me.cartService.addPromoCode(cart.id, cart.version, code);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'addPromoCode');
  },

  removePromoCode: async (code: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('removePromoCode: Cart not initialized');
        }
        const discountCode = cart.discountCodes.find((x) => x.discountCode?.code === code);
        if (discountCode) {
          updatedCart = await Me.cartService.removePromoCode(cart.id, cart.version, discountCode.discountCodeRef.id);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'removePromoCode');
  },

  setBillingAddress: async (address: Address | undefined): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const cart = get().cart;
      if (!cart) {
        throw new Error('setBillingAddress: Cart not initialized');
      }
      const updatedCart: Cart | undefined = await Me.cartService.setBillingAddress(cart.id, cart.version, toAddressInput(address));
      if (!updatedCart) {
        return;
      }
      set((state: CartStoreType) => ({ ...state, cart: updatedCart }));
    }, 'setBillingAddress');
  },

  setShippingAddress: async (
    address: Address | undefined,
    shippingMethod: ShippingMethodName,
    fulfillmentStore?: FulfillmentStore,
    fulfillmentInstruction?: string,
  ): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setShippingAddress: Cart not initialized');
        }
        const { store, defaultStore } = useCommerceToolsStore.getState();
        const miscActions: { [key: string]: string[] } = { channel: [] };
        if (store?.id !== defaultStore?.id) {
          miscActions.channel = cart.lineItems.reduce((acc, i) => {
            // update those that do not match
            if (store?.supplyChannels.length && i.supplyChannel?.id !== store?.supplyChannels[0].id) {
              acc.push(i.id);
            }
            return acc;
          }, [] as string[]);
        }

        updatedCart = await Me.cartService.setShippingAddress(
          cart.id,
          cart.version,
          toAddressInput(address),
          shippingMethod,
          fulfillmentStore,
          fulfillmentInstruction,
          miscActions,
        );

        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setShippingAddress');
  },

  setShippingMethod: async (shippingMethod: ShippingMethodName): Promise<void> => {
    console.warn('setShippingMethod delete, or update, not supported path');
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setShippingMethod: Cart not initialized');
        }
        if (shippingMethod !== cart.shippingInfo?.shippingMethod?.key) {
          updatedCart = await Me.cartService.setShippingMethod(cart.id, cart.version, shippingMethod);
        }

        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setShippingMethod');
  },

  setTimeSlot: async (timeSlot: TimeSlot | undefined): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setTimeSlot: Cart not initialized');
        }
        const currentTimeSlot = getTimeSlot(cart);
        if (!currentTimeSlot || !_.isEqual(currentTimeSlot, timeSlot)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          updatedCart = await _setCustomField(cart.id, cart.version, [
            { name: 'timeSlot', value: JSON.stringify(timeSlot ?? '') },
            {
              name: 'serviceFee',
              value: {
                ...defaultMoney,
                centAmount: timeSlot && timeSlot.timeSlots && timeSlot.timeSlots.length ? ~~(timeSlot.timeSlots[0].timeSlotPrice * 100 + 0.5) : 0,
              },
            },
            {
              name: 'deliveryFee',
              value: {
                ...defaultMoney,
                centAmount: timeSlot && timeSlot.timeSlots && timeSlot.timeSlots.length ? ~~(timeSlot.timeSlots[0].timeSlotDeliveryFee * 100 + 0.5) : 0,
              },
            },
          ]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setTimeSlot');
  },

  saveForLater: async (itemId: string): Promise<void> => {
    logger.log('Save for later Not Implemented', itemId);
  },

  createOrderFromCart: async (): Promise<Order> => {
    return await Mutex.withMutex(async () => {
      const cart = get().cart;
      if (!cart) {
        throw new Error('createOrderFormCart: Cart not initialized');
      }
      const order = await Me.orderService.createOrderFromCart(cart.id, cart.version);
      set((state: CartStoreType) => ({ ...state, cart: undefined }));
      return order;
    }, 'createOrderFromCart');
  },

  applyValidation: async (validationResult: CartValidationResult): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;

        if (!cart) {
          throw new Error('checkAvailablity: Cart not initialized');
        }
        const { store, defaultStore } = useCommerceToolsStore.getState();
        const changes = invidLineItems(cart.lineItems).reduce(
          (act, li) => {
            let updateChannel: boolean = true;
            const errItem = validationResult.errors.find((xi) => xi.id === li.id);
            if (errItem) {
              let inStock = errItem.isInStock;
              (errItem.children ?? []).some((c) => {
                if (!c.isInStock) {
                  inStock = false;
                  return true;
                }
                return false;
              });

              if (!inStock) {
                const customField = li.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
                const chItems = (customField ? String(customField.value).split(',') : []).reduce((acc, cid) => {
                  if (cart.lineItems.find((i: LineItem) => i.id === cid) && !act.remove.find((i) => i === cid)) {
                    acc.push(cid);
                  }
                  return acc;
                }, [] as string[]);
                act.remove.push(li.id, ...chItems);
                updateChannel = false;
              } else {
                if (errItem.availableQuantity < li.quantity) {
                  act.reduce.push({ li: li.id, qty: errItem.availableQuantity });
                }
              }
            }

            if (updateChannel && store?.id !== defaultStore?.id && store?.supplyChannels.length && li.supplyChannel?.id !== store?.supplyChannels[0].id) {
              act.channel.push(li.id);
            }

            return act;
          },
          { remove: [], reduce: [], channel: [] } as LineItemChanges,
        );

        updatedCart = await Me.cartService.applyContextChanges(cart?.id, cart?.version, changes);
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'applyValidation');
  },

  validateCart: async (storeNumber?: string): Promise<CartValidationResult> => {
    const result: CartValidationResult = { isValid: true, errors: [] } as CartValidationResult;
    return await Mutex.withMutex(async () => {
      try {
        const transitCart = get().transitCart;
        const cart = transitCart ? { ...transitCart } : get().cart;
        if (!cart) {
          throw new Error('checkAvailablity: Cart not initialized');
        }
        if (!storeNumber) {
          const { store, defaultStore } = useCommerceToolsStore.getState();
          if (!store || store.id === defaultStore?.id) {
            get().postSync();
            return result;
          }
        }
        let allowAlcohol = true;
        const store = storeNumber ? await storeService.getStore(storeNumber) : undefined;
        const { defaultStore } = useCommerceToolsStore.getState();
        if (storeNumber && !store) {
          throw new Error(`checkAvailablity: invalid storeNumber ${storeNumber}`);
        }
        if (storeNumber && store) {
          const bwStore = await bwStoreService.getStore(storeNumber);
          if (bwStore && !bwStore.canSellAlcohol) {
            allowAlcohol = false;
          }
          const cartShippingMethod = getShippingMethod(cart);
          if (ShopType.DELIVERY === cartShippingMethod && bwStore && !bwStore.canDeliverAlcohol) {
            allowAlcohol = false;
          }
        }
        const productKeys = cart.lineItems.reduce((list: string[], x) => {
          if (x.variant && x.variant.sku) {
            list.push(x.variant.sku);
          }
          return list;
        }, []);
        const nonSellableProductKeys = cart.lineItems.reduce((list: string[], x) => {
          if (x.variant && x.variant.sku && x.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId')) {
            list.push(x.variant.sku);
          }
          return list;
        }, []);
        result.storeNumber = storeNumber;
        const priceChannelIds = store?.distributionChannels?.map((x) => x.id)[0] ?? undefined;
        const availablityChannelIds = store?.supplyChannels?.map((x) => x.id) ?? undefined;
        let pCount = 0,
          uCount = 0,
          maxLimit = 50;
        const productSet = [],
          unsellableSet = [];
        while (pCount < productKeys.length || uCount < nonSellableProductKeys.length) {
          productSet.push(
            productService.getProducts({ skus: productKeys.slice(pCount, pCount + maxLimit), limit: maxLimit }, priceChannelIds, availablityChannelIds),
          );
          unsellableSet.push(
            productService.getProducts(
              { skus: nonSellableProductKeys.slice(uCount, uCount + maxLimit), limit: maxLimit },
              defaultStore?.distributionChannels?.map((x) => x.id)[0],
              defaultStore?.supplyChannels?.map((x) => x.id),
            ),
          );
          pCount += maxLimit;
          uCount += maxLimit;
        }
        const products = (await Promise.all(productSet)).flat(1);
        const nonSellableProducts = (await Promise.all(unsellableSet)).flat(1);

        nonSellableProducts.forEach((nsp) => {
          if (
            products.some(
              (p) => p.masterData.current?.masterVariant.price === null && p.masterData.current.masterVariant.sku === nsp.masterData.current?.masterVariant.sku,
            )
          ) {
            const pi = products.findIndex(
              (pr) =>
                pr.masterData.current?.masterVariant.price === null && pr.masterData.current.masterVariant.sku === nsp.masterData.current?.masterVariant.sku,
            );
            products[pi] = nsp;
          }
        });
        invidLineItems(cart.lineItems).forEach((li) => {
          const product = li.variant?.sku ? findProduct(products, li.variant?.sku) : undefined;
          if (!product) {
            result.errors.push({
              availableQuantity: 0,
              id: li.id,
              sku: li.variant?.sku ?? undefined,
              isInStock: false,
              priceChanged: false,
              updatedPrice: 0,
            });
          } else {
            const ltInfo = getProductAvailablity(product.masterData);
            if (!allowAlcohol) {
              const ageData = getProductAttributeValue('requiredMinimumAge', li.variant?.attributesRaw ?? []);
              if (ageData) {
                result.errors.push({
                  availableQuantity: 0,
                  id: li.id,
                  sku: li.variant?.sku ?? undefined,
                  isInStock: false,
                  priceChanged: false,
                  updatedPrice: 0,
                });
              }
            } else if (ltInfo?.availability !== 'Available' && ltInfo?.availability !== 'LowStock') {
              const originalOrder = getOriginalOrderNumber(cart);
              const originalQuantity = getItemAttributeValue('originalQuantity', li.custom?.customFieldsRaw ?? []) ?? 0;
              let missingQty = 0;
              if (originalOrder && ltInfo.availability === 'OutOfStock' && originalQuantity) {
                missingQty = (ltInfo.quantity ?? 0) + originalQuantity - li.quantity;
              }
              if (missingQty < 0) {
                result.errors.push({
                  availableQuantity: li.quantity + missingQty,
                  id: li.id,
                  sku: li.variant?.sku ?? undefined,
                  isInStock: false,
                  priceChanged: false,
                  updatedPrice: 0,
                });
              }
            } else {
              let error: ValidationError | undefined;
              const productVariant = li.variant?.sku ? findProductVariant(product, li.variant?.sku) : undefined;
              if (!productVariant) {
                throw new Error('product data invalid');
              } else {
                error = validateLineItem(li, productVariant, storeNumber);
                if (li.productType?.name === ProductType.CONFIGURABLE) {
                  let customErrors: ValidationError[] = [];
                  const aChildren = li.custom?.customFieldsRaw?.find((x) => x.name === 'parentLineItemId');
                  let lChildren = lineItemChildren(cart.lineItems, li.id);
                  if (aChildren?.value) {
                    if (aChildren?.value.some((cId: string) => cart.lineItems.findIndex((l) => l.id === cId) === -1)) {
                      customErrors.push({
                        availableQuantity: 0,
                        id: li.id,
                        sku: li.variant?.sku ?? undefined,
                        isInStock: false,
                        priceChanged: false,
                        updatedPrice: 0,
                      });
                    }
                  } else {
                    lChildren.forEach((cli: LineItem) => {
                      const customProduct = cli.variant?.sku ? findProduct(products, cli.variant?.sku) : undefined;
                      if (!customProduct) {
                        customErrors.push({
                          availableQuantity: 0,
                          id: li.id,
                          sku: li.variant?.sku ?? undefined,
                          isInStock: false,
                          priceChanged: false,
                          updatedPrice: 0,
                        });
                      } else {
                        const customProductVariant = cli.variant?.sku ? findProductVariant(customProduct, cli.variant?.sku) : undefined;
                        if (customProductVariant) {
                          const customError = validateLineItem(cli, customProductVariant, storeNumber, true);
                          if (customError) {
                            customErrors.push(customError);
                          }
                        }
                      }
                    });
                  }
                  if (customErrors.length) {
                    if (error) {
                      error.children = customErrors;
                    } else {
                      error = {
                        id: li.id,
                        sku: li.variant?.sku ?? undefined,
                        isInStock: customErrors.findIndex((ce) => !ce.isInStock) >= 0,
                        priceChanged: customErrors.findIndex((ce) => !ce.priceChanged) >= 0,
                        updatedPrice: productVariant?.price?.value.centAmount,
                        availableQuantity: getAvailablity(productVariant, storeNumber ?? li.supplyChannel?.key)?.availableQuantity ?? 0,
                        children: customErrors,
                      };
                    }
                  }
                }
                if (error) {
                  result.errors.push(error);
                }
              }
            }
          }
        });
      } catch (e) {
        get().postSync();
        throw e;
      }
      result.isValid = !(result.errors.length > 0);
      get().postSync();
      return result;
    }, 'validateCart');
  },

  setContactFirstName: async (name: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setContactFirstName: Cart not initialized');
        }
        let customerContact = getCartCustomerContact(cart);
        if (!customerContact) {
          customerContact = getCustomerContact();
        }
        if (customerContact && !_.isEqual(customerContact.firstName, name)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;

          updatedCart = await _setCustomField(cart?.id, cart?.version, [
            { name: 'customerContact', value: JSON.stringify({ ...customerContact, firstName: name }) },
          ]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setContactFirstName');
  },

  setContactLastName: async (name: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setContactLastName: Cart not initialized');
        }
        let customerContact = getCartCustomerContact(cart);
        if (!customerContact) {
          customerContact = getCustomerContact();
        }
        if (customerContact && !_.isEqual(customerContact.lastName, name)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;

          updatedCart = await _setCustomField(cart?.id, cart?.version, [
            { name: 'customerContact', value: JSON.stringify({ ...customerContact, lastName: name }) },
          ]);
          if (!updatedCart) {
            // throw should have taken care of it but to close eslint logic
            return;
          }
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setContactLastName');
  },

  setContactPhone: async (phone: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setContactPhone: Cart not initialized');
        }
        let customerContact = getCartCustomerContact(cart);
        if (!customerContact) {
          customerContact = getCustomerContact();
        }
        if (customerContact && !_.isEqual(customerContact.phone, phone)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          updatedCart = await _setCustomField(cart?.id, cart?.version, [
            { name: 'customerContact', value: JSON.stringify({ ...customerContact, phone: phone }) },
          ]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setContactPhone');
  },

  setContactAllowText: async (allow: boolean | any): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setContactAllowText: Cart not initialized');
        }
        let customerContact = getCartCustomerContact(cart);
        if (!customerContact) {
          customerContact = getCustomerContact();
        }
        if (customerContact && !_.isEqual(customerContact.allowText, allow)) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          updatedCart = await _setCustomField(cart?.id, cart?.version, [
            { name: 'customerContact', value: JSON.stringify({ ...customerContact, allowText: allow }) },
          ]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setContactAllowText');
  },

  setPromotions: async (promos?: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('setPromotions: Cart not initialized');
        }

        const promotions = getPromotions(cart);
        if (promotions !== promos) {
          const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
          updatedCart = await _setCustomField(cart?.id, cart?.version, [{ name: 'promotions', value: promos }]);
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'setPromotions');
  },

  initializeCartContact: async (): Promise<void> => {
    return await Mutex.withMutex(async () => {
      const transitCart = get().transitCart;
      const cart = transitCart ? { ...transitCart } : get().cart;
      try {
        let updatedCart: Cart | undefined;
        if (!cart) {
          throw new Error('initializeCartContact: Cart not initialized');
        }
        let customerContact = getCartCustomerContact(cart);
        if (!customerContact) {
          customerContact = getCustomerContact();
          if (customerContact) {
            const _setCustomField = cart?.custom ? Me.cartService.setCustomField : Me.cartService.setCustomType;
            updatedCart = await _setCustomField(cart?.id, cart?.version, [
              { name: 'customerContact', value: JSON.stringify(customerContact) },
              { name: 'pickupPersonName', value: [customerContact.firstName ?? '', customerContact.lastName ?? ''].join(' ') }, // JSON encoded in the call during mapping
            ]);
          }
        }
        get().postSync(updatedCart);
      } catch (e) {
        get().postSync();
        throw e;
      }
    }, 'initializeCartContact');
  },

  getAgeVerificationRequired: async (): Promise<number[]> => {
    const cart = get().cart;
    let result: number[] = [];
    let attributes: AttrDataType = {};
    if (cart) {
      const isSubOk = getAllowSubstitution(cart);
      if (isSubOk) {
        const skus = cart.lineItems.reduce((acc, li): string[] => {
          if (li.variant?.sku) {
            acc.push(li.variant.sku);
            if (li.variant?.attributesRaw) {
              attributes[li.variant.sku] = li.variant?.attributesRaw;
            }
            const subItems = getSubstitutionItems(li);
            if (subItems && subItems.length) {
              acc.push(...subItems);
            }
          }
          return acc;
        }, [] as string[]);
        const pData = await getProductsfromCommerceTools(skus);
        if (!pData || !pData.length) {
          throw new Error('addCustomizableLineItem: unable to get product information');
        }
        pData.reduce((acc, p: Product) => {
          if (p.masterData.current?.masterVariant.attributesRaw) {
            acc[p.masterData?.current?.masterVariant.sku ?? ''] = p.masterData.current?.masterVariant.attributesRaw;
          }
          p.masterData?.current?.variants.forEach((vi) => {
            if (p.masterData.current?.masterVariant.attributesRaw) {
              acc[vi?.sku ?? ''] = p.masterData.current?.masterVariant.attributesRaw;
            }
          });

          return acc;
        }, attributes);

        result = Object.values(attributes)
          .map((vsku: RawProductAttribute[]) => {
            return getProductAttributeValue('requiredMinimumAge', vsku ?? []);
          })
          .filter(Number)
          .sort((a, b) => b - a);
        return result;
      } else {
        result = invidLineItems(cart.lineItems)
          .map((li) => {
            return getProductAttributeValue('requiredMinimumAge', li.variant?.attributesRaw ?? []);
          })
          .filter(Number)
          .sort((a, b) => b - a);
        return Promise.resolve(result);
      }
    }
    return Promise.resolve([]);
  },
}));

export default useCartStore;

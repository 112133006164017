import colors from '@config/colors';
import React from 'react';
import { Animated, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import Icon from '../Icon';

const ListItemDeleteAction: React.FC<{
  progress: Animated.AnimatedInterpolation;
  dragValue: Animated.AnimatedInterpolation;
  onPress?: (event: any) => void;
}> = ({ onPress, dragValue }) => {
  const scale = dragValue.interpolate({
    inputRange: [-80, 0],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <Animated.View style={[styles.container, { transform: [{ scale }] }]}>
        <Icon name="delete-icon" size={35} stroke={'none'} fill={colors.red} />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ListItemDeleteAction;

import { useTheme } from '@react-navigation/native';
import { appStyles } from '@styles';
import React from 'react';
import { Keyboard, KeyboardAvoidingView, KeyboardAvoidingViewProps, Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface KeyboardAvoidingScreenProps extends KeyboardAvoidingViewProps {
  headerVisible?: boolean;
}

const KeyboardAvoidingScreen: React.FC<KeyboardAvoidingScreenProps> = ({
  children,
  style,
  behavior = 'padding',
  keyboardVerticalOffset = 0,
  headerVisible = true,
  ...props
}) => {
  const insets = useSafeAreaInsets();
  const { colors } = useTheme();
  var margins = !headerVisible ? { paddingTop: insets.top, paddingLeft: insets.left, paddingRight: insets.right, paddingBottom: insets.bottom } : {};
  return (
    <KeyboardAvoidingView
      style={[
        appStyles.screen,
        {
          backgroundColor: colors.background,
          ...margins,
        },
        style,
      ]}
      behavior={behavior}
      contentContainerStyle={styles.container}
      keyboardVerticalOffset={keyboardVerticalOffset}
      {...props}>
      <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? () => Keyboard.dismiss() : undefined}>
        <>{children}</>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
export default KeyboardAvoidingScreen;

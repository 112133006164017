import Text from '@components/Text';
import colors from '@config/colors';
import { appStyles } from '@styles';
import React, { forwardRef } from 'react';
import { Switch as NativeSwitch, SwitchProps as NativeSwitchProps, Platform, StyleSheet, View } from 'react-native';

export type SwitchProps = Omit<NativeSwitchProps, 'thumbColor' | 'trackColor'> & {
  label?: string;
};

const Switch = forwardRef<NativeSwitch, SwitchProps>(({ label, style, ...otherProps }, ref): JSX.Element => {
  return (
    <View style={[styles.container, style]}>
      <Text style={appStyles.fontMobileListSmallLeft}>{label}</Text>
      <NativeSwitch
        ref={ref}
        trackColor={{
          false: Platform.OS === 'android' ? '#d3d3d3' : colors.primary, // To show gray color on Android
          true: colors.secondary,
        }}
        thumbColor={colors.white}
        {...otherProps}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default Switch;

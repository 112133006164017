import { HtmlView, NavigationHeader, Screen } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { AppStackRoutes, HelpCenterStackRoutes, HelpCenterStackScreenProps } from '@navigation/routes';
import appStyles from '@styles/appStyles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, Platform, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomModalContext } from '../../../contexts';
import { ChatLiveAgentIcon } from './HelpCenterScreen';

type HelpTopicsDetailScreenProps = HelpCenterStackScreenProps<HelpCenterStackRoutes.HelpTopicsDetailScreen>;
const HelpTopicsDetailScreen = ({ route, navigation }: HelpTopicsDetailScreenProps) => {
  const { t } = useTranslation('helpCenter');
  const { question, answer } = route?.params;
  const insets = useSafeAreaInsets();
  const { showAlertModal } = useCustomModalContext();
  const [scrollViewBottomPadding, setScrollViewBottomPadding] = useState<number>(0);

  const onBottomBarLayout = (le: LayoutChangeEvent) => {
    setScrollViewBottomPadding(le.nativeEvent.layout.height - insets.bottom);
  };

  const onButtonPress = () => {
    navigation.navigate(AppStackRoutes.LiveAgent);
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader next={<ChatLiveAgentIcon onPress={onButtonPress} />} subStyle={[styles.navSubHeader, utilityStyles.pt2]} style={{}} />
      <ScrollView contentContainerStyle={[appStyles.container, styles.paddingHorizantal, { paddingBottom: scrollViewBottomPadding }]}>
        <View style={styles.queAnsView}>
          <Text style={[appStyles.fontMobileListSmallLeft, styles.questionText]} testID="question" allowFontScaling={true} maxFontSizeMultiplier={4}>
            {question}
          </Text>

          <HtmlView text={answer ?? ''} textAlign="left" lineHeight={25} />
        </View>
      </ScrollView>

      {/* TODO -Reenable and send responses.
      <BottomBar onLayout={onBottomBarLayout} useInsets={false} testID="helpCenterBottom">
        <View style={styles.bottomBarView}>
          <View style={styles.helpTextView}>
            <Text style={[appStyles.fontMobileListSmallLeft]} testID="helpfulText">
              {t('helpfulText')}
            </Text>
          </View>
          <View style={styles.bottomBtnView}>
            <Button
              testID="buttonYesTxt"
              title={t('buttonYesTxt')}
              type={'secondary'}
              size="small"
              buttonStyle={styles.bottomButton}
              textStyle={styles.textStyle}
              onPress={() => showAlertModal()}
            />
            <Button
              testID="buttonNoTxt"
              title={t('buttonNoTxt')}
              type={'secondary'}
              size="small"
              buttonStyle={styles.bottomButton}
              textStyle={styles.textStyle}
              onPress={() => showAlertModal()}
            />
          </View>
        </View>
      </BottomBar> */}
    </Screen>
  );
};

const styles = StyleSheet.create({
  navSubHeader: {
    alignItems: 'flex-start',
    minHeight: screenHeight * 0.06,
  },
  queAnsView: { marginTop: 14 },
  paddingHorizantal: {
    paddingHorizontal: 20,
  },
  questionText: { marginVertical: 10 },
  subView: {
    lineHeight: lineHeight(25),
    marginTop: 10,
  },
  bottomButton: {
    borderWidth: 1,
    height: 32,
    minWidth: 130,
    maxWidth: 350,
  },
  textStyle: {
    fontSize: scale(14),
    lineHeight: Platform.select({
      android: lineHeight(14.2),
    }),
    height: scale(16),
  },
  bottomBarView: {
    backgroundColor: colors.cream,
    height: screenHeight > 744 ? screenHeight * 0.1 : screenHeight * 0.12,
    width: screenWidth,
  },
  helpTextView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  bottomBtnView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 5,
  },
});

export default HelpTopicsDetailScreen;

import colors from '@config/colors';
import { AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import Icon, { IconProps } from './Icon';

type CloseButtonProps = {
  style?: StyleProp<ViewStyle>;
  iconProps?: Omit<IconProps, 'name' | 'stroke' | 'size'>;
  size?: number;
  onPress?: (event: any) => void;
};

const CloseButton = ({ onPress, style, iconProps, size = 25 }: CloseButtonProps) => {
  const navigation = useNavigation();
  const handleClick = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
  };

  return (
    <TouchableOpacity
      testID="closeButton"
      onPress={onPress ?? handleClick}
      style={[styles.closeButton, style]}
      hitSlop={{ bottom: 15, top: 15, left: 15, right: 15 }}>
      <Icon name="x-close" size={size} stroke={colors.darkText} {...iconProps} testID="closeIcon" />
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    left: 0,
    paddingRight: 10,
    zIndex: 999,
  },
});
export default CloseButton;

import { RedeemProgressBar } from '@components';
import Text from '@components/Text';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { TourKey } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types/offer';
import { useCancelToken } from '@hooks';
import { AppStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useOffersStore, useUserSettingsStore } from '@store';
import useTutorialStore from '@store/tutorialStore';
import { appStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, GestureResponderEvent, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';
import DropShadow from '../DropShadow';
import Icon from '../Icon';
const CARD_WIDTH = 165;

type OfferCardProps = {
  item: Promotion;
  index: number;
  calledFrom?: string;
  onShowEligibleItems?: (e: GestureResponderEvent) => void;
};

const OfferCard: FC<OfferCardProps> = ({ item, index, calledFrom, onShowEligibleItems }): JSX.Element => {
  const OFFER_IMG = item.QualifiedImageUrl ?? '';
  var options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
  };
  const EXPIRATION_DATE = new Date(item.EndDate);
  const EXP_MONTH = new Intl.DateTimeFormat('en-US', options).format(EXPIRATION_DATE);
  const EXP_DAY = EXPIRATION_DATE.getDate();
  const EXP_YEAR = EXPIRATION_DATE.getFullYear().toString().substring(2, 4);
  const { acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons, acceptOffer, unacceptOffer, acceptCoupon } = useOffersStore();
  const [clipping, setClipping] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigation = useNavigation();
  // const [productLocation, setProductLocation] = useState('');
  const { t } = useTranslation('tourGuide');

  const { getVisitCount } = useTutorialStore();
  const { userSettings } = useUserSettingsStore();
  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    TourGuideZone,
  } = useTourGuideController(TourKey.SEHomeScreen);

  const acceptedOffers = acceptedSomethingExtraOffers.concat(acceptedWeeklyExclusiveOffers).concat(acceptedDigitalCoupons);

  // useEffect(() => {
  //   const fetchProductLocation = async (sku: string) => {
  //     const prod = await productService.getProductBySku(sku);
  //     if (prod.masterData.current) {
  //       const aisleBayBin = getProductAisleBayBin(prod.masterData.current);

  //       setProductLocation(aisleBayBin);
  //     }
  //   };
  //   if (item?.ProductList && item.ProductList.length === 1) {
  //     fetchProductLocation(item.ProductList[0]);
  //   }
  // }, [item.ProductList]);

  const handleShowEligibleItems = (e: GestureResponderEvent) => {
    if (onShowEligibleItems) {
      onShowEligibleItems(e);
    }

    navigation.navigate(AppStackRoutes.OfferDetails, {
      offerId: Number(item.ExtPromotionId),
      percentComplete: item.PercentComplete ?? 0,
      percentCompleteMessage: item.PercentCompleteMessage ?? '',
      // isClipped: item.IsAccepted,
      isClipped: acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId),
      calledFrom: calledFrom,
    });
  };

  const cancelToken = useCancelToken();
  const handleClip = async () => {
    setClipping(true);
    setDisabled(true);

    const isDigitalCoupon = (item.PromotionCode ?? '').toUpperCase().startsWith('INMAR');
    if (acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId)) {
      if (!isNotAllowUnclip) {
        if (!(isDigitalCoupon || calledFrom?.toLowerCase() === 'digitalcoupons')) {
          await unacceptOffer(+item.ExtPromotionId);
        }
      }
    } else {
      if (isDigitalCoupon || calledFrom?.toLowerCase() === 'digitalcoupons') {
        await acceptCoupon(+item.ExtPromotionId);
      } else {
        await acceptOffer(+item.ExtPromotionId, cancelToken);
      }
    }

    setClipping(false);
    setDisabled(false || (calledFrom?.toLowerCase() === 'digitalcoupons' && acceptedOffers.some((o) => +o.ExtPromotionId === +item.ExtPromotionId)));
  };

  const fallbackImage = assets.imageComingSoon;
  const getImageSource = () => {
    let url;
    if (OFFER_IMG && OFFER_IMG) {
      url = { cache: 'default', uri: `${OFFER_IMG.indexOf('//') === 0 ? 'https:' + OFFER_IMG : OFFER_IMG}` };
    } else {
      url = fallbackImage;
    }
    return url;
  };

  const isNotAllowUnclip = item.ExtPromotionTypeCode === 'DFEE' || item.ExtPromotionTypeCode === 'SFEE';
  const showAsAccepted = (p: Promotion) => {
    if (p.AutoApply || (p.IsPromoCodePromo && p.IsAccepted)) {
      return true;
    }
    return false;
  };

  const startTourGuide = useCallback(() => {
    if (canStart && (userSettings?.showTourGuide ?? false) && getVisitCount('Rewards') === 1) {
      start();
    }
  }, [canStart, getVisitCount, start, userSettings?.showTourGuide]);

  useEffect(() => {
    startTourGuide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStart]);

  return (
    <DropShadow style={styles.shadow}>
      <TouchableOpacity
        onPress={handleShowEligibleItems}
        style={[
          styles.productContainer,
          {
            marginLeft: 0,
            borderStyle: acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId) ? 'solid' : 'dashed',
            // borderColor: item.IsAccepted ? colors.greyBorder : colors.offerBorder,
            // borderWidth: item.IsAccepted ? 0.5 : 1.7,
            borderColor: acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId) ? colors.greyBorder : colors.offerBorder,
            borderWidth: acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId) ? 0.5 : 1.7,
          },
        ]}>
        <View style={{ width: 125, borderRadius: 10, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', paddingTop: 12 }}>
          {(isNotAllowUnclip && item.IsAccepted) || showAsAccepted(item) ? (
            <View style={styles.clipped} />
          ) : acceptedOffers?.some((o) => +o.ExtPromotionId === +item.ExtPromotionId) ? (
            <>
              {clipping ? (
                <View style={styles.clipIndicator}>
                  <ActivityIndicator />
                </View>
              ) : (
                <TouchableOpacity disabled={disabled} style={styles.clipped} onPress={handleClip}>
                  <View style={styles.clippedWrapper}>
                    <Text style={styles.clippedText}>Clipped!</Text>
                    <Icon name={'circle-check'} fill={colors.clippedGreen} stroke={colors.white} strokeSecondary={colors.white} size={32} />
                  </View>
                </TouchableOpacity>
              )}
            </>
          ) : (
            <>
              {clipping ? (
                <View style={styles.clipIndicator}>
                  <ActivityIndicator />
                </View>
              ) : (
                <TouchableOpacity onPress={handleClip} style={styles.addButton}>
                  <TourGuideZone text={t('tgOfferCardTxt')} zone={1} shape={'circle'} isTourGuide={index === 0}>
                    <Icon name={'circle-scissor'} stroke={'none'} size={46} />
                  </TourGuideZone>
                </TouchableOpacity>
              )}
            </>
          )}
          <TouchableOpacity key={'image_key_index_' + index} activeOpacity={1} onPress={handleShowEligibleItems} style={styles.imgWrapper}>
            <Image testID="eligibleItems" resizeMode={'contain'} source={getImageSource()} style={styles.image} />
          </TouchableOpacity>
        </View>
        <View style={styles.itemInfo}>
          {item.Headline && (
            <Text numberOfLines={2} style={[styles.itemOfferText]}>
              {item.Headline}
            </Text>
          )}
          {item.SubHeadline && (
            <Text numberOfLines={2} style={[styles.itemNameText]}>
              {item.SubHeadline}
            </Text>
          )}
          <Text style={[styles.itemDateText]}>{`Expires ${EXP_MONTH}/${EXP_DAY}/${EXP_YEAR}`}</Text>
          <TouchableOpacity onPress={handleShowEligibleItems}>
            <Text style={[styles.itemEligibleText, appStyles.tinylightredlink]}>Show eligible items</Text>
          </TouchableOpacity>
          {/* {calledFrom?.toLowerCase() !== 'digitalcoupons' && item?.ProductList && item?.ProductList.length === 1 && productLocation ? (
            <View style={styles.itemLocationWrapper}>
              <Icon name={'location-pin'} fill={colors.text} size={15} />
              <Text style={styles.itemLocationText}>{productLocation}</Text>
            </View>
          ) : null} */}
          {(item?.PercentComplete ?? -1) > -1 ? (
            <View style={styles.progressBarContainer}>
              <View>
                <RedeemProgressBar progress={(item?.PercentComplete ?? -1) >= 100 ? 100 : item?.PercentComplete ?? -1} maxValue={100} />
              </View>
              <Text numberOfLines={1} style={[appStyles.fontMobileSmallLeftRegular, styles.progressText]}>
                {item.PercentCompleteMessage ?? 'Add more items to Complete!'}
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </TouchableOpacity>
    </DropShadow>
  );
};

const styles = StyleSheet.create({
  productContainer: {
    width: CARD_WIDTH,
    height: 335,
    borderWidth: 1.7,
    borderColor: colors.offerBorder,
    borderRadius: 15,
    borderStyle: 'dashed',
    backgroundColor: colors.white,
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1.41,
    elevation: 2,
  },
  clipIndicator: {
    alignItems: 'flex-end',
    width: '100%',
    marginRight: -8,
    height: 30,
  },
  clippedWrapper: {
    flexDirection: 'row',
  },
  clippedText: {
    color: colors.clippedGreen,
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(16),
    lineHeight: lineHeight(22),
    marginRight: 4,
    paddingTop: 4,
  },
  addButton: {
    alignItems: 'flex-end',
    width: '100%',
    marginRight: -24,
    height: 30,
  },
  clipped: {
    alignItems: 'flex-end',
    width: '100%',
    marginRight: -10,
    height: 30,
  },
  itemInfo: {
    justifyContent: 'space-evenly',
    height: 150,
    paddingHorizontal: 12,
  },
  itemButtonWrapper: {
    alignItems: 'center',
    paddingBottom: 12,
  },
  itemOfferText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.primary,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'center',
    marginVertical: -6,
  },
  itemNameText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(12),
    textAlign: 'center',
    lineHeight: lineHeight(16),
    marginVertical: -6,
  },
  itemDateText: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(12),
    lineHeight: lineHeight(16),
    textAlign: 'center',
    marginVertical: -6,
  },
  itemEligibleText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.red,
    fontSize: scale(14),
    textAlign: 'center',
    textDecorationLine: 'underline',
    marginVertical: -6,
  },
  itemLocationText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(15),
    textAlign: 'center',
    marginTop: 1,
    marginLeft: 5,
  },
  itemLocationWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: -4,
  },
  clipButton: {
    marginTop: 0,
    minWidth: 125,
    maxWidth: 150,
    height: 34,
    alignSelf: 'center',
    paddingBottom: 0,
    paddingTop: -4,
  },
  progressBarContainer: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 8,
    marginBottom: -24,
  },
  progressText: {
    fontSize: scale(12),
    lineHeight: lineHeight(14),
    textAlign: 'center',
    marginTop: 2,
  },
  imgWrapper: {
    width: 114,
    height: 114,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  image: {
    width: 100 - 5,
    height: 100,
  },
});

export default OfferCard;

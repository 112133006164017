import { EnterpriseService } from '@fieldera-raleys/client-common';
import Cache from '@utils/cache';
import Config from 'react-native-config';

const enterpriseService = new EnterpriseService({
  apiUrl: Config.BW_API_URL,
  authKey: Config.BW_AUTH_KEY,
  authUrl: Config.BW_AUTH_URL,
  cache: Cache,
});

export default enterpriseService;

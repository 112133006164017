import colors from '@config/colors';
import { useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, screenWidth } from '@styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from '../index';

const ShelfGuideDiscoverContent = () => {
  const { t } = useTranslation('shelfGuide');
  const navigation = useNavigation();
  const handleOnPress = () => {
    navigation.goBack();
  };

  return (
    <View style={[styles.mainContainer]}>
      <View style={[styles.imageContentSlideContainer, utilityStyles.my3]}>
        <View style={[styles.imageContentSlideContent, utilityStyles.px2]}>
          <Text style={[appStyles.eyebrowCenterRed, utilityStyles.my0]}>{t('discoverTitle')}</Text>
          <Text style={[appStyles.h5, utilityStyles.my1, utilityStyles.px1, appStyles.fontMobileH6Center]} allowFontScaling={true} maxFontSizeMultiplier={4}>
            {t('discoverSubtitle')}
          </Text>

          <Text
            style={[appStyles.bodySmallLeftRegular, utilityStyles.my1, utilityStyles.pb2, utilityStyles.px1, appStyles.bodySmallCenterRegular]}
            testID="ImageContentSlideDescription"
            allowFontScaling={true}
            maxFontSizeMultiplier={4}>
            {t('discoverContent')}
          </Text>

          <Button
            type="secondary"
            size="small"
            title={t('discoverTextLink')}
            onPress={handleOnPress}
            textStyle={{ lineHeight: lineHeight(14) }}
            buttonStyle={styles.buttonStyle}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    alignSelf: 'center',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
    marginTop: 20,
  },
  imageContentSlideContainer: {
    textAlign: 'center',
    alignSelf: 'center',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
    marginVertical: 0,
  },
  imageContentSlideContent: {
    flexDirection: 'column',
    width: '100%',
    borderColor: '#C9974E',
    height: 'auto',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    textAlign: 'center',
  },
  buttonStyle: {
    marginBottom: 50,
    maxWidth: 400,
  },
});

export default ShelfGuideDiscoverContent;

import { Icon } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import colors from '@config/colors';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { FC, useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ChosenItem } from '../../models/customize';

interface ISizeScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Size> {
  sizes?: ChosenItem[];
  route: any;
}

interface ISizeItemProps {
  item: ChosenItem;
  index?: number | string;
  selectedSize?: ChosenItem;
  setSelectedSize: (size: ChosenItem) => void;
  calculateSubtotal: () => void;
}

const SizeItem: FC<ISizeItemProps> = ({ item, selectedSize, setSelectedSize, calculateSubtotal }): JSX.Element => {
  return (
    <View style={styles.sizeItemContainer}>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.checkButton}
        onPress={() => {
          setSelectedSize(item);
          calculateSubtotal();
        }}>
        {item?.itemName === selectedSize?.itemName ? (
          <Icon
            testID="checkCircleIcon"
            name="checked-circle"
            fill={colors.secondary}
            stroke={'none'}
            strokeSecondary={colors.white}
            fillSecondary={'none'}
            style={appStyles.mediumIcon}
            size={25}
          />
        ) : (
          <Icon testID="unCheckedCircleIcon" name="unchecked-circle" fill={colors.white} stroke={colors.darkText} style={appStyles.mediumIcon} size={25} />
        )}
      </TouchableOpacity>
      <View style={styles.sizeInfoTextWrapper}>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => {
            setSelectedSize(item);
            calculateSubtotal();
          }}>
          <Text testID="sizeItemName" style={[styles.itemSizeText, appStyles.customizeLabelBold]}>
            {item?.itemName}
          </Text>
          {item.description && <Text style={[styles.servesText, appStyles.customizeLabelSm]}>{`serves ${item.description}`}</Text>}
          <Text testID="sizeItemCost" style={[styles.servesText, appStyles.customizeLabelSm]}>
            ${item.cost}
          </Text>
        </TouchableOpacity>
      </View>
      {item?.itemName?.includes('Quarter') && (
        <View style={styles.ml}>
          <View style={{ backgroundColor: colors.navBorder, width: 36, height: 24, marginTop: 0 }} />
          <Text testID="quarterSize" style={[styles.servesText, { alignSelf: 'flex-start', marginTop: 2 }]}>
            8 x 12"
          </Text>
        </View>
      )}
      {item?.itemName?.includes('Half') && (
        <View style={styles.ml}>
          <View style={{ backgroundColor: colors.navBorder, width: 46.8, height: 36, marginTop: 0 }} />
          <Text testID="halfSize" style={[styles.servesText, { alignSelf: 'flex-start', marginTop: 2 }]}>
            12 x 16"
          </Text>
        </View>
      )}
      {item?.itemName?.includes('Full') && (
        <View style={styles.ml}>
          <View style={{ backgroundColor: colors.navBorder, width: 72, height: 48, marginTop: 0 }} />
          <Text testID="fullSize" style={[styles.servesText, { alignSelf: 'flex-start', marginTop: 2 }]}>
            16 x 24"
          </Text>
        </View>
      )}
    </View>
  );
};

const SizeScreen: FC<ISizeScreenProps> = (): JSX.Element => {
  const navigation = useNavigation();
  const { selectedSize, setSelectedSize, steps, calculateSubtotal, currStep } = useCustomizeStore();

  useEffect(() => {
    var forwardEnabled = selectedSize?.itemName ? true : false;
    navigation.setParams({ forwardNavEnabled: forwardEnabled });
    calculateSubtotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize?.itemName, currStep]);

  return (
    <View style={styles.container}>
      <ScrollView bounces={false} style={styles.body}>
        <View style={styles.bodySection}>
          {/* <Text style={styles.sizeText}>SIZE</Text> */}
          <Text testID="selectSizeText" style={[styles.chooseYourSizeText, appStyles.customizeH2]}>
            Select Size
          </Text>
          <View style={styles.listContainer}>
            {steps[0]?.choices
              ?.sort((c1, c2) => Number(c1.cost) - Number(c2.cost) || Number(c1.id) - Number(c2.id))
              .map((size, index) => {
                return (
                  <SizeItem
                    key={index.toString()}
                    item={size}
                    selectedSize={selectedSize}
                    setSelectedSize={setSelectedSize}
                    calculateSubtotal={calculateSubtotal}
                  />
                );
              })}
          </View>
        </View>
        <View style={{ height: 50 }} />
      </ScrollView>
      <View style={styles.footerWrapper}>
        <CustomizeFooter disabled={selectedSize?.itemName ? false : true} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    paddingVertical: 25,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
  },
  body: {
    flex: 0.65,
    width: '100%',
    paddingHorizontal: 16,
    paddingTop:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.01 : screenHeight * 0.01) : screenHeight > 640 ? screenHeight * 0.01 : screenHeight * 0.01,
  },
  bodySection: {
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: 20,
    paddingHorizontal: 25,
    paddingTop: 25,
    paddingBottom: 25,
    // overflow: 'scroll',
  },
  sizeText: {
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 12,
  },
  chooseYourSizeText: {
    marginBottom: 16,
  },
  listContainer: {
    width: '100%',
    paddingBottom: 0,
  },
  contentContainer: {
    justifyContent: 'space-between',
  },
  sizeItemContainer: {
    width: '100%',
    minHeight: screenHeight * 0.02,
    flexGrow: 1,
    // alignItems: 'center',
    flexDirection: 'row',
    marginVertical: screenHeight > 740 ? 12 : 8,
    // backgroundColor: 'red',
  },
  sizeInfoTextWrapper: {
    // justifyContent: 'center',
    // marginLeft: 5,
    // marginTop: 5,
    // backgroundColor: 'gold',
    width: '75%',
  },
  itemSizeText: {
    fontSize: scale(18),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    lineHeight: lineHeight(14),
    width: '90%',
  },
  servesText: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: 'Larsseit',
    color: colors.darkText,
  },
  checkButton: {
    marginRight: 12,
    // justifyContent: 'center',
    // marginBottom: 12,
    // marginTop: -28,
  },
  footerWrapper: {
    // backgroundColor: 'gold',
    // paddingTop: 45,
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -55,
  },
  ml: {
    marginLeft: -20,
  },
});

export default SizeScreen;

import { Category as CTCategory, ProductCategorySearch, RawCustomField } from '@fieldera-raleys/client-commercetools/schema';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { categoryService } from '@services/commerceTools';

export const searchCategoryTree = (list: Array<Category>, cb: (item: Category) => boolean) => {
  let result: Category | undefined;
  for (var value of list) {
    if (cb(value)) {
      result = value;
      break;
    } else if (value.children != null) {
      var i;
      for (i = 0; result === undefined && i < value.children.length; i++) {
        result = searchCategoryTree(value.children, cb);
      }
      if (result) {
        break;
      }
    }
  }
  return result;
};

export const getCategoriesfromCommerceTools = async (categoryIds: string[]): Promise<CTCategory[]> => {
  const search: ProductCategorySearch = { key: categoryIds, limit: categoryIds.length };
  return await categoryService.getCategories({ search });
};

export const getAttributeValue = (name: string, attributes?: RawCustomField[] | null) => {
  const f = attributes?.find((x) => x.name === name);
  return f?.value;
};

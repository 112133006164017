import { CloseButton, ErrorFallbackComponent, NavigationHeader, Screen, Text } from '@components';
import CardPaymentForm from '@components/forms/CardPaymentForm';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

type AddEditPaymentMethodScreenProps = AccountStackScreenProps<AccountStackRoutes.AddEditPaymentMethod>;

const AddEditPaymentMethodScreen = ({ navigation, route }: AddEditPaymentMethodScreenProps) => {
  const { paymentMethodId, calledFrom, addressId } = route.params;
  const handleOnAddressAdd = () => {
    navigation.navigate(RootTabRoutes.Account, {
      screen: AccountStackRoutes.AddEditAddress,
      params: { mode: 'billing', calledFrom: AccountStackRoutes.AddEditPaymentMethod },
    });
  };

  const handleOnAddressEdit = (id: string | number) => {
    navigation.navigate(AccountStackRoutes.AddEditAddress, { addressId: id, calledFrom: AccountStackRoutes.AddressBook });
  };

  const handleRedirect = useCallback(() => {
    navigation.setParams({ paymentMethodId: undefined });

    if (calledFrom === 'Wallet') {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: false } });
    } else {
      navigation.canGoBack() ? navigation.goBack() : navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
    }
  }, [navigation, calledFrom]);

  return (
    <Screen key={'payment_key_screen'} style={utilityStyles.pb0}>
      <NavigationHeader
        testID={`${paymentMethodId ? 'Edit' : 'Add'} Payment`}
        title={`${paymentMethodId ? 'Edit' : 'Add'} Payment`}
        subTitle={'Method'}
        icon={<CloseButton size={20} onPress={handleRedirect} />}
        titleStyle={{ lineHeight: lineHeight(24) }}
        subTitleStyle={{ marginTop: scale(-7), lineHeight: lineHeight(24) }}
      />
      <View key={'header1_key'} style={[styles.header]}>
        <View style={[styles.subHeader]}>
          <TouchableOpacity style={[styles.filterPills, styles.filterPillsActive]} testID="CreditDebitBtnClick">
            <Text style={[styles.filterPillsLabel, styles.filterPillsLabelActive]} testID="CreditDebitText">
              Credit/Debit
            </Text>
          </TouchableOpacity>
          {/* TODO: Uncomment at the time of Gift Card implementation */}
          {/* <TouchableOpacity style={[styles.filterPills, styles.filterPillsDisabled]} onPress={() => setShowFutureFeatureModal(true)}>
            <Text style={[styles.filterPillsLabel]}>GiftCard</Text>
          </TouchableOpacity> */}
        </View>
      </View>
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
        <CardPaymentForm
          onAddAddress={handleOnAddressAdd}
          onAfterSubmit={handleRedirect}
          paymentMethodId={paymentMethodId}
          addressId={addressId}
          onEditAddress={handleOnAddressEdit}
        />
      </ErrorBoundary>
    </Screen>
  );
};
const styles = StyleSheet.create({
  header: {
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
    zIndex: 1,
  },
  subHeader: {
    height: scale(56),
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
    justifyContent: 'flex-start',
    zIndex: 1,
    paddingHorizontal: '2%',
  },
  filterPillsLabelActive: {
    color: colors.white,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'center',
  },
  filterPillsLabel: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'center',
    ...utilityStyles.px2,
  },
  filterPills: {
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#707070',
    height: scale(24),
    ...utilityStyles.mx2,
    justifyContent: 'center',
    alignContent: 'center',
  },
  filterPillsActive: {
    color: colors.white,
    backgroundColor: colors.secondary,
    borderColor: colors.white,
  },
  filterPillsDisabled: {
    opacity: 0.4,
  },
});

export default AddEditPaymentMethodScreen;

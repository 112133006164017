import AlertMessage, { AlertMessageProps } from '@components/AlertMessage';
import AMSBarcode from '@components/AMSBarcode';
import Modal, { ModalProps } from '@components/Modal';
import Text from '@components/Text';
import appConstants from '@config/appConstants';
import { useCartStore } from '@store';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { createContext, ReactElement, useCallback, useContext, useRef, useState } from 'react';
import { Animated, StyleSheet } from 'react-native';

type AlertModalProps = Omit<
  ModalProps,
  | 'style'
  | 'location'
  | 'headerStyle'
  | 'buttonContainerStyle'
  | 'subTextViewStyle'
  | 'okButtonOnPress'
  | 'okButtonText'
  | 'okButtonType'
  | 'okButtonStyle'
  | 'showCancel'
  | 'showScrollHint'
  | 'cancelButtonText'
  | 'cancelButtonType'
  | 'cancelButtonStyle'
  | 'showCancelIcon'
  | 'children'
  | 'cancelButtonOnPress'
  | 'contentStyle'
  | 'header'
  | 'headerTextStyle'
  | 'bottomPanel'
> & {
  message?: string | JSX.Element;
  closeButtonText?: string;
  onClose?: () => void;
  ellipsizeMode?: 'tail' | 'head' | 'middle' | 'clip' | undefined;
};

type CustomModalContext = {
  showModal: (modalProps?: Omit<ModalProps, 'visible'>) => void;
  hideModal: (e?: any) => void;
  showAlertModal: (modalProps?: Omit<AlertModalProps, 'visible'>) => void;
  hideAlertModal: (e?: any) => void;
  showAlertPopup: (props: AlertMessageProps) => void;
  showAMSCard: () => void;
};

const initalState: CustomModalContext = {
  showModal: () => {},
  hideModal: () => {},
  showAlertModal: () => {},
  hideAlertModal: () => {},
  showAlertPopup: () => {},
  showAMSCard: () => {},
};

const CustomModalContext = createContext(initalState);

export const useCustomModalContext = () => useContext(CustomModalContext);

export const CustomModalProvider: React.FC<{ children: ReactElement }> = ({ children }): JSX.Element => {
  const [state, setState] = useState<Omit<ModalProps, 'visible'> & { visible: boolean }>({ visible: false });
  const animatedValue = useRef(new Animated.Value(0)).current;
  const { tombIsOpen } = useCartStore();

  const [alertPopup, setAlertPopup] = useState<AlertMessageProps & { visible: boolean }>({
    message: '',
    visible: false,
    containerStyle: undefined,
    linkText: '',
    linkAction: undefined,
  });

  const showModal = (modalProps: Omit<ModalProps, 'visible'> = {}) => {
    setState((prev) => ({
      ...prev,
      ...modalProps,
      visible: true,
    }));
  };

  const fade = useCallback(
    (toValue: number) => {
      return Animated.timing(animatedValue, {
        toValue,
        duration: 600,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
      });
    },
    [animatedValue],
  );

  const fadeOpacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const handleCancelOnPress = useCallback(
    (e: any) => {
      const { cancelButtonOnPress } = state;
      setState(() => ({ visible: false }));
      cancelButtonOnPress && cancelButtonOnPress(e);
    },
    [state],
  );

  const handleOkOnPress = useCallback(
    (e: any) => {
      const { okButtonOnPress } = state;
      setState(() => ({ visible: false }));
      okButtonOnPress && okButtonOnPress(e);
    },
    [state],
  );

  const showAlertModal = (modalProps: Omit<AlertModalProps, 'visible'> = {}) => {
    const { title, message, onClose, closeButtonText, ...rest } = modalProps;
    setState((prev) => ({
      ...prev,
      ...rest,
      location: 'top',
      cancelButtonOnPress: onClose,
      cancelButtonText: closeButtonText,
      title: title ?? 'Future Feature',
      children: (
        <Text style={[appStyles.bodyMediumLight, styles.modalText]} testID={'alertMsg'}>
          {message ?? 'This feature is planned for a future version and is not yet available.'}
        </Text>
      ),
      visible: true,
    }));
  };

  const showAMSCard = () => {
    showModal({
      location: 'top',
      cancelButtonText: 'Done',
      contentStyle: styles.barcodeContentContainer,
      children: <AMSBarcode />,
    });
  };

  const showAlertPopup = (props: AlertMessageProps) => {
    fade(1).start();
    setAlertPopup({ visible: true, ...props });
    setTimeout(() => {
      fade(0).start();
      setTimeout(() => {
        setAlertPopup({ visible: false, ...props });
      }, 800);
    }, 3000);
  };

  const renderComponent = () => {
    const { visible, children: modalContent, ...rest } = state;
    return (
      <Modal {...rest} visible={visible} cancelButtonOnPress={handleCancelOnPress} okButtonOnPress={handleOkOnPress}>
        <>{modalContent}</>
      </Modal>
    );
  };

  return (
    <CustomModalContext.Provider
      value={{
        showModal,
        hideModal: handleCancelOnPress,
        showAlertModal,
        showAlertPopup,
        hideAlertModal: handleCancelOnPress,
        showAMSCard,
      }}>
      {children}
      {alertPopup.visible && (
        <Animated.View
          style={{
            opacity: tombIsOpen ? 0 : fadeOpacity,
          }}>
          <AlertMessage
            containerStyle={alertPopup.containerStyle}
            message={alertPopup.message}
            linkText={alertPopup.linkText}
            linkAction={alertPopup.linkAction}
            ellipsizeMode={alertPopup.ellipsizeMode}
          />
        </Animated.View>
      )}
      {renderComponent()}
    </CustomModalContext.Provider>
  );
};

const styles = StyleSheet.create({
  barcodeContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screenWidth,
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'normal',
  },
});

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon } from '../';

type SEDollarsAlertProps = {
  closeAction: () => void;
};

const SEDollarsAlert: FC<SEDollarsAlertProps> = ({ closeAction }) => {
  const navigation = useNavigation();
  const viewWallet = () => {
    navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: false } });
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.confettiLeft}>
          <Icon size={48} name={'confetti-group-1'} />
        </View>
        <View style={styles.iconWrapper}>
          <Icon size={48} name={'se-dollars-wallet'} />
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.mainText}>You've Earned Something Extra Dollars!{'  '}</Text>
          <View style={styles.walletLink}>
            <Text style={[styles.mainText, styles.walletText]} onPress={viewWallet}>
              View Wallet
              <Icon stroke={'orange'} name={'arrow-right'} size={8} style={styles.rightArrow} />
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.confettiRight}>
        <Icon size={48} name={'confetti-group-2'} />
      </View>
      <View style={styles.leftBottomBorder} />
      <View style={styles.rightBottomBorder} />
      <View style={styles.closeWrapper}>
        <TouchableOpacity style={styles.closeButton} onPress={closeAction}>
          <Icon name={'x-close'} size={14} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: 60,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  rightArrow: {
    alignSelf: 'center',
    transform: [{ translateY: Platform.OS === 'ios' ? 9 : 12 }, { translateX: 2 }],
  },
  walletLink: {
    position: 'absolute',
    bottom: Platform.OS === 'ios' ? 0 : 2,
    right: Platform.OS === 'ios' ? -3 : -screenWidth * 0.015,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeWrapper: {
    position: 'absolute',
    right: 8,
    top: 6,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 30,
    // backgroundColor: 'red',
  },
  closeButton: {
    width: 50,
    height: 40,
    paddingTop: 8,
    paddingLeft: 16,
  },
  closeIcon: {
    // position: 'absolute',
    // right: 8,
    // top: 8,
  },
  iconWrapper: {
    width: 50,
    height: '100%',
    paddingTop: 1,
    marginLeft: -16,
    // backgroundColor: 'gold',
  },
  textWrapper: {
    width: '50%',
    marginLeft: 8,
  },
  confettiLeft: {
    height: 50,
    width: 50,
    position: 'absolute',
    left: 16,
    top: 0,
    // backgroundColor: 'black',
  },
  confettiRight: {
    position: 'absolute',
    right: 35,
    top: -2,
    height: 50,
    width: 50,
  },
  leftBottomBorder: {
    position: 'absolute',
    bottom: -2,
    left: 0,
    height: 2,
    backgroundColor: colors.red,
    width: screenWidth * 0.04,
  },
  rightBottomBorder: {
    position: 'absolute',
    bottom: -2,
    right: 0,
    height: 2,
    backgroundColor: colors.red,
    width: screenWidth * 0.8,
  },
  mainText: {
    fontFamily: FontFamily.LarsseitBold,
    color: '#f58357',
    fontSize: scale(15),
    lineHeight: lineHeight(16),
  },
  walletText: {
    color: 'orange',
    fontSize: scale(14),
  },
});

export default SEDollarsAlert;

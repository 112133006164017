import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Money, Product, ProductData, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import { Category, ProductType, ShopType } from '@fieldera-raleys/client-common';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { brsmService } from '@services';
import { useCartStore, useShopStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { getSubstitutionItems, getSubstitutionType } from '@utils/orderHelpers';
import {
  ProductPromotion,
  getDisplayPriceBySellType,
  getDisplayPriceBySellTypeAndPriceMessage,
  getFormattedPrice,
  getProductAttributeValue,
  getProductAvailablity,
  getQuantityFromPriceMessage,
  getfulfillmentLeadTimeMinutes,
} from '@utils/productHelper';
import { isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import Config from 'react-native-config';
import BookmarkButton from './BookmarkButton';
import Button from './Button';
import CustomizeButton from './CustomizeButton';
import CustomizeCartInfo from './CustomizeCartInfo';
import Icon from './Icon';
import ProductImage from './ProductImage';
import Text from './Text';
import { OpenTombstoneRequest } from './Tombstone';
import { CartQuantityButton } from './cart/CartQuantityButton';
import { Image } from './image';

interface ProductCardProps {
  product: Product;
  productCategory?: Category;
  showAddToList?: boolean;
  fromListName?: string;
  actionType?: string;
  itemId?: string;
  index?: number;
  productPromotion?: ProductPromotion;
  changeStore?: (reqest: OpenTombstoneRequest) => void;
  infoBox?: StyleProp<ViewStyle>;
}

const ShelfGuideIcons = (props: { shelfGuide: any }) => {
  const baseImageUrl = Config.BRXM_IMAGE_URL;

  return props.shelfGuide.map((sg: string, idx: any) => {
    return (
      <View key={`sgView_${sg}_${idx}`}>
        <Image
          style={styles.sgImage}
          source={{
            uri: `${baseImageUrl}/consumer-app/shelf-guide/icons/${sg.replace(/\s/g, '').toLowerCase()}.png`,
          }}
          resizeMode={'contain'}
        />
      </View>
    );
  });
};
const ProductCard = ({
  product,
  productCategory,
  showAddToList = false,
  fromListName,
  actionType,
  itemId,
  index,
  productPromotion,
  changeStore,
  infoBox,
}: ProductCardProps) => {
  const navigation = useNavigation();
  const { selectedShopType } = useShopStore();
  const productData: ProductData = product.masterData.current as ProductData;
  const masterVariant: ProductVariant = product.masterData.current?.masterVariant as ProductVariant;
  const unitsPerPackage = getProductAttributeValue('unitsPerPackage', masterVariant.attributesRaw);
  const unitOfMeasure = getProductAttributeValue('unitOfMeasure', masterVariant.attributesRaw);
  const packageCount = getProductAttributeValue('packageCount', masterVariant.attributesRaw) ?? 1;
  const price = masterVariant.price?.value && getFormattedPrice(masterVariant.price?.value);
  let pricePerUnit = masterVariant.price?.value && getFormattedPrice(masterVariant.price?.value, unitsPerPackage);
  const salePricePerUnit = masterVariant.price?.discounted?.value && getFormattedPrice(masterVariant.price?.discounted?.value, unitsPerPackage);
  const shelfGuide = getProductAttributeValue('shelfGuideIndicators', masterVariant.attributesRaw);
  brsmService.sortShelfGuideArrayData(shelfGuide);
  const priceCustomValues = masterVariant.price?.custom?.customFieldsRaw ?? [];
  const salePrice = masterVariant.price?.discounted?.value && getFormattedPrice(masterVariant.price?.discounted?.value);

  const sellType = getProductAttributeValue('unitSellType', masterVariant.attributesRaw) ?? { key: 'byEach', label: 'By Each' };
  const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', masterVariant.attributesRaw);

  const unitBuyIncrement = getProductAttributeValue('unitBuyIncrement', masterVariant.attributesRaw);
  const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', masterVariant.attributesRaw);
  const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', masterVariant.attributesRaw);

  const unitSize = parseFloat((Number(packageCount) * Number(unitsPerPackage)).toFixed(1));
  if (sellType.key === 'byEach') {
    const currencyCode = 'USD';
    const fractionDigits = 2;
    const centAmount =
      (masterVariant.price?.value.centAmount ?? (priceCustomValues.length && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount)) /
      unitSize;
    let money: Money = {
      type: 'centPrecision',
      currencyCode: currencyCode,
      centAmount: centAmount,
      fractionDigits: fractionDigits,
    };

    pricePerUnit = getFormattedPrice(money);
  }

  const { cart, setItemBackup, setItemSubType } = useCartStore();
  const { t } = useTranslation('cart');
  let regularPrice =
    priceCustomValues.length &&
    priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value &&
    getFormattedPrice(priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value);
  // regularPrice = regularPrice === price ? 0 : +regularPrice;
  let onSale =
    (masterVariant.price?.value.centAmount ?? 0) < (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount ?? 0) ? true : false;

  let priceMessage = '';
  priceMessage =
    (priceCustomValues.length &&
      priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value &&
      priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value) ??
    '';

  priceMessage = isNumber(priceMessage) ? '' : priceMessage;
  const priceMessageQty = getQuantityFromPriceMessage(priceMessage);

  const displayPrice = onSale
    ? priceMessage.length && priceMessage.includes(' for $')
      ? priceMessage
      : priceMessageQty > 1
        ? getDisplayPriceBySellTypeAndPriceMessage(sellType.key, priceMessage, masterVariant.price?.value, unitAverageBuyWeight)
        : getDisplayPriceBySellType(sellType.key, masterVariant.price?.value, unitAverageBuyWeight)
    : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount
      ? getDisplayPriceBySellType(sellType.key, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, unitAverageBuyWeight)
      : getDisplayPriceBySellType(sellType.key, masterVariant.price?.value, unitAverageBuyWeight);

  const productKey = product.key as string;
  const leadTime = getfulfillmentLeadTimeMinutes(product);
  let leadMessageEx;
  if (leadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES) {
    leadMessageEx = 'This item requires advance ordering. Would you like to continue?';
  }

  const { tombSet } = useCartStore();

  const navigateToDetailsPage = (showMore?: boolean) => {
    navigation.navigate(AppStackRoutes.ProductDetails, {
      root: productCategory?.rootId,
      productKey: productKey,
      showMoreFlag: showMore,
    });
  };

  const changeShoppingOptions = (page?: string) => {
    if (changeStore) {
      changeStore({ ts: new Date().getTime(), page: page });
    } else {
      tombSet(page);
      navigation.navigate(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: { storeNumber: undefined },
      });
    }
  };

  const [choice, setChoice] = useState<String>('');
  useEffect(() => {
    setChoice('');
    if (cart && itemId) {
      const li = cart.lineItems.find((i) => i.id === itemId);
      if (li) {
        const subType = getSubstitutionType(li);
        if (subType === 'Specific-Items') {
          const subItems = li ? getSubstitutionItems(li) : [];
          setChoice(subItems.length > 0 ? subItems[0] : '');
        }
      }
    }
  }, [cart, itemId]);

  const setBackupChoice = (id?: string) => {
    if (cart && itemId) {
      if (choice !== id) {
        setItemBackup(itemId, id ? [id] : []);
        navigation.navigate(AppStackRoutes.CartAndCheckout, {
          screen: CheckoutStackRoutes.CartLanding,
          params: { storeNumber: undefined },
        });
      } else {
        setItemSubType(itemId, 'Best-Available');
        setItemBackup(itemId, []);
      }
    }
  };

  let cartButton = <></>;
  let customizeCartInfo = <></>;
  if (showAddToList) {
    cartButton = (
      <>
        <CartQuantityButton
          product={product}
          showAddToList={showAddToList}
          fromListName={fromListName}
          productSellType={sellType}
          unitsPerPackage={unitsPerPackage}
          unitBuyIncrement={unitBuyIncrement}
          unitBuyMaximum={unitBuyMaximum}
          unitBuyMinimum={unitBuyMinimum}
          unitOfMeasure={unitOfMeasure}
          disabled={masterVariant.price ? false : true}
          size={'small'}
          variantKey={productKey || ''}
          productKey={masterVariant.sku ?? ''}
          productName={productData.name ?? ''}
          productLeadTime={leadMessageEx}
          showCartText={true}
          cartTextStyle={styles.inCartText}
          qtyContainerStyle={[styles.quantityBox]}
          buttonStyle={selectedShopType === ShopType.IN_STORE ? styles.cartAddList : styles.cartAddQty}
          availabilityResult={getProductAvailablity(product.masterData)}
          testID={(index ?? 0).toString()}
        />
      </>
    );
  } else if (product.productType?.name === ProductType.CONFIGURABLE && !showAddToList) {
    cartButton = (
      <>
        <CustomizeButton
          disabled={!masterVariant.price}
          prevPageData={productCategory}
          productKey={productKey}
          productLeadTime={leadMessageEx}
          size={'small'}
          buttonStyle={[appStyles.primaryButtonSmall, styles.cartAddQty]}
          testID={`productCustomizeBtn-${index}`}
          availabilityResult={getProductAvailablity(product.masterData)}
        />
      </>
    );
    customizeCartInfo = (
      <>
        <CustomizeCartInfo
          boxStyle={{ position: 'relative', bottom: 5, width: '65%', height: 'auto', marginBottom: 0 }}
          productKey={productKey}
          textSize={12}
        />
      </>
    );
  } else {
    cartButton =
      actionType === 'substitute' ? (
        <>
          <Button
            testID="SubstituteButton"
            size={'small'}
            type="secondary"
            buttonStyle={[choice === productKey ? styles.selectedBackupButton : styles.selectBackupButton, { width: '95%' }]}
            textStyle={[choice === productKey ? styles.selectedText : styles.selectText]}
            title={choice === productKey ? t('subSelected') : t('subSelect')}
            onPress={() => setBackupChoice(productKey ?? '')}
          />
          {choice === productKey ? (
            <Icon size={27} name="circle-check" stroke={colors.green} strokeSecondary={colors.white} style={{ position: 'absolute', top: 12, left: 20 }} />
          ) : null}
        </>
      ) : (
        <>
          <CartQuantityButton
            product={product}
            showAddToList={showAddToList}
            productSellType={sellType}
            unitsPerPackage={unitsPerPackage}
            unitBuyIncrement={unitBuyIncrement}
            unitBuyMaximum={unitBuyMaximum}
            unitBuyMinimum={unitBuyMinimum}
            unitOfMeasure={unitOfMeasure}
            disabled={masterVariant.price ? false : true}
            size={'small'}
            variantKey={productKey || ''}
            productKey={masterVariant.sku ?? ''}
            productName={productData.name ?? ''}
            productLeadTime={leadMessageEx}
            showCartText={true}
            cartTextStyle={styles.inCartText}
            qtyContainerStyle={[styles.quantityBox]}
            buttonStyle={selectedShopType === ShopType.IN_STORE ? styles.cartAddList : styles.cartAddQty}
            availabilityResult={getProductAvailablity(product.masterData)}
            testID={`productAddToCartBtn-${index}`}
          />
        </>
      );
  }

  return (
    <View style={styles.productCard}>
      <TouchableOpacity onPress={() => navigateToDetailsPage()}>
        <ProductImage
          product={product}
          imageUrl={masterVariant.images[0]?.url ?? ''}
          disabled={true}
          testID={`productImage-${index}`}
          productPromotion={productPromotion}
        />
      </TouchableOpacity>
      <View style={[styles.actionWrapper, selectedShopType === ShopType.IN_STORE ? styles.actionWrapperAddtToList : null]}>
        <View style={[{ flexDirection: 'column', marginBottom: -8 }, actionType === 'substitute' ? { width: '88%' } : null]}>{cartButton}</View>
        {actionType !== 'substitute' ? (
          <BookmarkButton
            productSku={masterVariant.sku ?? productKey}
            wrapperStyle={styles.heartWrapper}
            alertContainerStyle={(styles.alertBox, infoBox)}
            iconStyle={{ position: 'absolute', right: -13, top: 13 }}
            size={26}
            disabled={masterVariant.price ? false : true}
          />
        ) : null}
      </View>
      <View style={[styles.customizeCartInfoContainer, utilityStyles.px1]}>{customizeCartInfo}</View>
      <View style={styles.infoWrapper}>
        <View>
          <TouchableOpacity onPress={() => navigateToDetailsPage()}>
            <Text
              testID={`productPrice-${index}`}
              style={[
                onSale
                  ? styles.itemDiscountText
                  : unitOfMeasure !== 'Each' && product.productType?.name !== ProductType.CONFIGURABLE
                    ? styles.itemPriceText
                    : styles.itemPriceTextUnit,
                {
                  marginBottom: Platform.select({
                    ios: salePrice ?? price ? scale(-20) : scale(-10),
                    android: salePrice ?? price ? scale(-40) : scale(-10),
                  }),
                },
              ]}>
              {product.productType?.name !== 'standard' && masterVariant.price ? 'Starts at ' : ''}
              {(salePrice ?? price) && sellType.key === 'weightByEach' ? (
                <Text testID="approxText" style={styles.approxText}>
                  {'approx '}
                </Text>
              ) : null}
              {priceMessage && priceMessage.length > 0 && priceMessage.includes(' for $')
                ? priceMessage
                : !itemId
                  ? displayPrice
                  : product.masterData.current?.masterVariant.price?.value
                    ? getFormattedPrice(product.masterData.current?.masterVariant.price.value, unitAverageBuyWeight ? 1 / unitAverageBuyWeight : 1)
                    : pricePerUnit}
              {displayPrice ? (
                <Text testID="priceUnit" style={[appStyles.tinyLight]}>
                  {' '}
                  {priceMessageQty > 1 ? null : !priceMessage.includes(' for $') && (sellType.key === 'byWeight' ? `/ ${unitOfMeasure} ` : 'ea ')}
                  {itemId ? (
                    sellType.key === 'weightByEach' ? (
                      onSale && (
                        <Text style={[styles.discounted, { paddingLeft: 20 }]} testID={`byEach-${index}`}>
                          {regularPrice} {sellType.key === 'byWeight' || sellType.key === 'weightByEach' ? `/ ${unitOfMeasure} ` : 'ea'}
                        </Text>
                      )
                    ) : (
                      onSale && (
                        <Text
                          testID="unitsPerPackage1"
                          style={[
                            appStyles.fontMobileTinyLight,
                            { color: colors.saleRed, textDecorationLine: 'none', marginLeft: 'auto', paddingTop: Platform.select({ android: 15, default: 0 }) },
                          ]}>
                          {'   '}
                          {`${pricePerUnit} / ${unitOfMeasure}`}
                        </Text>
                      )
                    )
                  ) : sellType.key === 'weightByEach' ? (
                    unitAverageBuyWeight && (
                      <Text style={[styles.itemUnitText, Platform.select({ android: styles.avgPriceText })]} testID="averageBuyWeight">
                        {'  '}Avg. {unitAverageBuyWeight} {unitOfMeasure}
                      </Text>
                    )
                  ) : (
                    <Text testID="regularPrice" style={styles.regularText}>
                      {'   '}
                      {onSale && (
                        <Text testID="discountedPrice" style={[styles.discounted]}>
                          {regularPrice} {sellType.key === 'byWeight' ? `/ ${unitOfMeasure} ` : 'ea'}
                        </Text>
                      )}
                    </Text>
                  )}
                </Text>
              ) : (
                <Text testID="currentlyUnavailableText" style={[styles.noAvailText]} numberOfLines={2}>
                  Currently unavailable at selected
                  <TouchableOpacity onPress={() => changeShoppingOptions('FindAStore')} style={{ left: 10 }} testID="shopTypeButton">
                    <Text testID="shopType" style={[styles.cartLink, { paddingLeft: 10, paddingTop: 10 }]}>
                      {selectedShopType ? (selectedShopType === ShopType.DELIVERY ? 'delivery' : 'store') : 'store/delivery'}
                    </Text>
                  </TouchableOpacity>
                </Text>
              )}
              {salePrice && regularPrice` /${unitsPerPackage}`}
            </Text>
            {/* <Text style={styles.regularText}>{onSale && <Text style={[styles.discounted]}>{regularPrice} ea</Text>}</Text> */}
          </TouchableOpacity>
        </View>
        <TouchableOpacity onPress={() => navigateToDetailsPage()}>
          <Text
            testID={`productName-${index}`}
            style={[
              regularPrice ? styles.itemNameText : styles.itemNameTextWithoutDiscount,
              {
                marginTop: Platform.select({
                  android: salePrice ?? price ? scale(24) : scale(25),
                  ios: salePrice ?? price ? scale(24) : scale(23),
                }),
              },
            ]}
            numberOfLines={2}
            ellipsizeMode="tail">
            {productData.name}
          </Text>
        </TouchableOpacity>
        {!itemId ? (
          <TouchableOpacity onPress={() => navigateToDetailsPage()}>
            {/* {(unitOfMeasure || unitsPerPackage) && <Text style={styles.itemUnitText}>{getUnitPrice(unitsPerPackage, unitOfMeasure)}</Text>} */}
            <View style={[styles.uppAndAvgWeight, sellType.key === 'byEach' ? { justifyContent: 'space-between' } : null]}>
              {sellType.key === 'byWeight' ? null : sellType.key === 'byEach' ? (
                <Text testID={`byEach-${index}`} style={styles.itemUnitText}>
                  ({pricePerUnit + ` / ${unitOfMeasure}`})
                </Text>
              ) : (
                (salePricePerUnit || pricePerUnit) &&
                unitOfMeasure !== 'Each' && (
                  <Text style={styles.itemUnitText} testID={`salePricePerUnit-${index}`}>
                    {`${salePricePerUnit ?? pricePerUnit} / ${unitOfMeasure}`}
                  </Text>
                )
              )}
              {/* {sellType.key === 'weightByEach' && unitAverageBuyWeight && (
              <Text style={styles.itemUnitText}>
                Avg. {unitAverageBuyWeight} {unitOfMeasure}
              </Text>
            )} */}
              {sellType.key === 'weightByEach' && onSale && (
                <Text style={[styles.discounted, { paddingLeft: 20 }]} testID={`byEach-${index}`}>
                  {regularPrice} {sellType.key === 'byWeight' || sellType.key === 'weightByEach' ? `/ ${unitOfMeasure} ` : 'ea'}
                </Text>
              )}
              {sellType.key === 'byEach' && (
                <Text style={[styles.itemUnitText]} testID={`byEach-${index}`}>
                  {unitSize} {sellType.key === 'byEach' ? `${unitOfMeasure} ` : 'ea'}
                </Text>
              )}
            </View>
          </TouchableOpacity>
        ) : null}
        {/* maps the shelf guide icons...may need to be changed to match data object names */}
        {!itemId ? (
          <View style={[styles.shelfGuideContainer]}>
            <View style={styles.shelfGuideWrapper}>
              {shelfGuide && (
                <ShelfGuideIcons
                  {...{
                    shelfGuide: shelfGuide.slice(0, 3),
                  }}
                />
              )}
            </View>
            {shelfGuide && shelfGuide.length > 3 ? (
              <View style={styles.showMoreWrapper}>
                <TouchableOpacity onPress={() => navigateToDetailsPage(true)} activeOpacity={0.7} testID="moreButton">
                  <Text testID="moreText" style={[appStyles.fontSmallLinkSanSerif, styles.moreText]}>
                    + More
                  </Text>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  customizeCartQtyBox: {
    marginBottom: 0,
    marginTop: 10,
    width: '73%',
  },
  shelfGuideImage: {
    width: '100%',
    height: '100%',
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.17 : screenHeight > 812 ? screenHeight * 0.165 : screenHeight < 740 ? screenHeight * 0.165 : screenHeight * 0.175,
  },
  productCard: {
    ...utilityStyles.p2,
    minHeight: screenHeight * 0.35,
    width: screenWidth * 0.5,
    paddingBottom: 20,
    paddingTop: 20,
  },
  promoBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '75%',
    borderTopRightRadius: 15,
    backgroundColor: 'gold',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 7,
  },
  promoText: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(13),
  },
  customizeCartInfoContainer: {
    flexDirection: 'row',
    marginTop: 3,
    marginBottom: 3,
  },
  infoWrapper: {
    paddingTop: 5,
    paddingHorizontal: 5,
    flex: 1,
    justifyContent: screenHeight > 740 ? undefined : 'flex-start',
    // marginLeft: 15,
  },
  itemNameText: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    lineHeight: scale(16),
    marginBottom: scale(5),
    marginTop: Platform.select({ android: scale(30), default: scale(20) }),
  },
  itemNameTextWithoutDiscount: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    lineHeight: scale(16),
  },
  itemUnitText: {
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.darkText,
    // marginBottom: -5,
  },
  shelfGuideContainer: {
    flexDirection: 'row',
    width: 'auto',
    marginTop: 5,
  },
  shelfGuideWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'auto',
  },
  iconStyle: {
    marginBottom: 5,
    marginRight: 3,
  },
  quantityBox: {
    height: 36,
    // height: screenHeight < 740 ? '100%' : '80%',
    marginTop: 3,
  },
  quantityCountText: {
    fontSize: scale(20),
  },
  inCartText: {
    fontSize: scale(12),
    marginTop: -2,
  },
  itemPriceText: {
    fontSize: scale(17),
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    marginBottom: Platform.select({
      ios: scale(-20),
      android: scale(-40),
    }),
    lineHeight: lineHeight(17),
  },
  itemPriceTextUnit: {
    fontSize: scale(17),
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    marginBottom: 12,
  },
  discounted: {
    fontSize: scale(12),
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'line-through',
  },
  itemDiscountText: {
    fontSize: scale(17),
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    marginBottom: Platform.select({ android: scale(-30), ios: scale(-15) }),
    lineHeight: lineHeight(17),
  },
  itemDescription: {
    marginBottom: 10,
    marginTop: Platform.select({
      ios: -40,
      android: -45,
    }),
  },
  actionWrapper: {
    paddingVertical: 10,
    // paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    // backgroundColor: 'gold',
  },
  actionWrapperAddtToList: {
    alignSelf: 'flex-start',
  },
  heartWrapper: {
    // marginLeft: 16,
    paddingTop: 15,
    paddingLeft: 16,
    paddingRight: 0,
    // alignSelf: 'flex-start',
    width: 45,
    height: 45,
    // backgroundColor: 'green',
  },
  cartLink: {
    fontSize: scale(14),
    lineHeight: scale(14),
    color: colors.red,
    top: 2,
    textAlign: 'auto',
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'underline',
  },
  noAvailText: {
    fontSize: scale(14),
    color: colors.text,
    lineHeight: scale(16),
    fontFamily: FontFamily.LarsseitLight,
  },
  leadTimeBar: {
    backgroundColor: colors.teal,
    position: 'absolute',
    bottom: -1,
    left: 0,
    width: 150,
    borderTopRightRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 7,
    paddingVertical: 7,
  },
  leadTimeText: {
    color: colors.white,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(13),
  },
  productAttribute: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  productAttributeText: {
    fontFamily: 'Larsseit-Light',
    fontSize: scale(15),
  },
  productLocationIcon: {
    width: 15,
    height: 15,
    marginRight: 4,
    transform: [{ translateY: -3 }],
  },
  cartAddQty: {
    height: 36,
    width: '100%',
    paddingHorizontal: 4,
    // backgroundColor: 'red',
    // flex: 1,
    // paddingHorizontal: 0,
  },
  cartAddList: {
    height: 36,
    marginLeft: 2,
  },
  sgImage: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  showMoreWrapper: {
    marginTop: 1,
  },
  moreText: {
    marginLeft: 10,
  },
  regularText: {
    paddingTop: 10,
  },
  selectBackupButton: {
    height: 35,
    color: colors.primary,
    borderRadius: 28,
  },
  selectedBackupButton: {
    height: 35,
    backgroundColor: colors.green,
    borderRadius: 28,
    borderColor: colors.green,
    paddingLeft: 25,
  },
  selectText: {
    height: 30,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(23),
    color: colors.red,
  },
  selectedText: {
    lineHeight: lineHeight(23),
    flexDirection: 'row',
    width: '100%',
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.white,
  },
  uppAndAvgWeight: {
    flex: 1,
    flexDirection: 'row',
  },
  approxText: {
    ...appStyles.fontMobileBodyXS,
    lineHeight: lineHeight(14),
  },
  avgPriceText: {
    fontSize: scale(11),
  },
});

export default ProductCard;

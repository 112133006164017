import colors from '@config/colors';
import React, { FC, PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

let BAR_HEIGHT = 3;

type ProgressBarProps = PropsWithChildren<{
  maxValue: number;
  progress: number;
  height?: number;
  progressBarStyleStyle?: StyleProp<ViewStyle>;
}>;

const RedeemProgressBar: FC<ProgressBarProps> = ({ progress, height, progressBarStyleStyle, maxValue, children }) => {
  BAR_HEIGHT = height ?? BAR_HEIGHT;
  return (
    <>
      <View style={[styles(progress).track, progressBarStyleStyle]}>
        <View style={[styles(progress).progress, { width: `${(progress / maxValue) * 100}%` }]} />
      </View>
      {children}
    </>
  );
};

const styles = (props?: any) =>
  StyleSheet.create({
    track: {
      width: '100%',
      height: BAR_HEIGHT,
      borderRadius: BAR_HEIGHT,
      backgroundColor: colors.medium,
      alignSelf: 'center',
    },
    progress: {
      height: BAR_HEIGHT,
      borderRadius: BAR_HEIGHT,
      backgroundColor: props === 100 ? '#09BF88' : colors.raleysRed,
    },
  });

export default RedeemProgressBar;

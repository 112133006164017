import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address } from '@fieldera-raleys/client-common';
import { BottomSheetBackdropProps, BottomSheetModal } from '@gorhom/bottom-sheet';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import Icon from './Icon';
import { CustomBackdrop } from './bottomSheet';

interface PickerProps {
  placeholder: string;
  options?: any[];
  initialValue?: any;
  headerTitle: string;
  onChange: (value: Address) => void;
  disabled?: boolean;
  pickerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  pickerTextStyle?: StyleProp<TextStyle>;
  onBlur?: () => void;
}
const ITEM_HEIGHT = 42;

const Picker: FC<PickerProps> = ({
  onChange,
  onBlur,
  initialValue = 0,
  options = [],
  containerStyle,
  headerTitle,
  pickerStyle,
  pickerTextStyle,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const [showToggle, setShowToggle] = useState(false);
  const bottomSheetRef = useRef<BottomSheetModal>(null);
  const snapPoints = useMemo(() => ['50%', '50%'], []);
  const animatedIndex = useSharedValue(1);

  // refs
  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (showToggle) {
      bottomSheetRef.current!.present();
    } else {
    }
  }, [showToggle]);

  const handleSelect = useCallback(
    (item: any) => {
      if (item === 'Close') {
        bottomSheetRef.current!.dismiss();
        // setTimeout(() => {
        //   setSelectedValue(item);
        //   onChange(item);
        // }, 50);
        return;
      }
      setSelectedValue(item);
      onChange ? onChange(item) : null;
      onBlur && onBlur();
      bottomSheetRef.current!.dismiss();
    },
    [onBlur, onChange],
  );
  const Backdrop = useCallback((props: BottomSheetBackdropProps) => <CustomBackdrop {...props} animatedIndex={animatedIndex} />, [animatedIndex]);

  // const CustomHandle = useCallback((props) => <CustomHeaderHandle title="Location Filter" {...props} />, []);
  const CustomHandle = useCallback(
    () => (
      <View style={styles.handle}>
        <Text style={styles.headerTitle}>{headerTitle}</Text>
        <TouchableOpacity
          onPress={() => {
            setShowToggle(false);
            onBlur && onBlur();
            bottomSheetRef.current!.dismiss();
          }}>
          <Icon name={'x-close'} size={20} />
        </TouchableOpacity>
      </View>
    ),
    [headerTitle, onBlur],
  );

  const renderItem = ({ item, index }: ListRenderItemInfo<any>) => {
    return (
      <View style={[styles.quantityItemContainer, selectedValue === item ? styles.selected : null]}>
        <TouchableOpacity onPress={() => handleSelect(item)}>
          <Text numberOfLines={1} style={selectedValue === item ? styles.selectedOption : styles.option}>
            {index === -1 ? (
              <Text style={styles.removeText}>Close</Text>
            ) : item?.masterData?.current?.name ? (
              `${item?.masterData?.current?.name}`
            ) : item.name ? (
              `${item.name}`
            ) : (
              `${item}`
            )}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <>
      <TouchableOpacity onBlur={onBlur} onPress={() => setShowToggle(!showToggle)} activeOpacity={1} style={[styles.pickerWrapper, pickerStyle]}>
        <Text numberOfLines={1} style={[appStyles.h5L, pickerTextStyle, { maxWidth: '85%' }]}>
          {selectedValue?.abbreviation
            ? selectedValue?.abbreviation
            : selectedValue?.masterData?.current?.name
            ? selectedValue?.masterData?.current?.name
            : selectedValue
            ? selectedValue
            : placeholder}
        </Text>
        <Icon name={'triangle-down'} fill={colors.darkText} size={20} />
      </TouchableOpacity>
      <View style={[containerStyle]}>
        <BottomSheetModal
          ref={bottomSheetRef}
          snapPoints={snapPoints}
          backdropComponent={Backdrop}
          handleComponent={CustomHandle}
          enablePanDownToClose={true}
          enableDismissOnClose={true}
          backgroundStyle={{ borderRadius: 0 }}>
          <View style={{ width: screenWidth }}>
            <FlatList
              data={options}
              snapToInterval={ITEM_HEIGHT}
              initialScrollIndex={0}
              keyExtractor={(_, index) => String(index)}
              renderItem={renderItem}
              maxToRenderPerBatch={5}
              bounces={false}
              windowSize={2}
              decelerationRate="fast"
              showsVerticalScrollIndicator={false}
              getItemLayout={(_, index) => ({ length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index })}
            />
          </View>
        </BottomSheetModal>
      </View>
    </>
  );
};
export default Picker;

const styles = StyleSheet.create({
  pickerWrapper: {
    width: '100%',
    height: 50,
    backgroundColor: colors.white,
    borderRadius: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  headerTitle: {
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    lineHeight: lineHeight(28),
  },
  option: {
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(28),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  handle: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  selectedOption: {
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(28),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: colors.darkCream,
  },
  removeText: {
    fontSize: scale(18),
    fontFamily: 'Larsseit-Bold',
  },
  quantityItemContainer: {
    width: '100%',
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  saveBarWrapper: {
    width: screenWidth,
    position: 'absolute',
    bottom: 0,
  },
});

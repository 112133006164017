import { ErrorFallbackComponent, LoadingScreen, OfflineNotice } from '@components';
import withModalProvider from '@components/hoc/withModalProvider';
import initI18next from '@config/i18n/i18next';
import { useAppState, useEffectOnce } from '@hooks';
import * as LocalAuthentication from '@hooks/useLocalAuthentication';
import * as Location from '@hooks/useLocation';
import * as Notifications from '@hooks/useNotifications';
import { AppNavigation, AuthNavigation } from '@navigation';
import { AppUpgradeScreen, MaintenanceScreen } from '@screens/maintenance';
import {
  useAppConfigStore,
  useAuthStore,
  useCustomizeStore,
  useDeviceInfoStore,
  useOffersStore,
  usePurchaseHistoryFilterStore,
  useShopStore,
  useUserProfileStore,
  useUserSettingsStore,
} from '@store';
import { DefaultTheme } from '@styles';
import Cache from '@utils/cache';
import logger from '@utils/logger';
import { queryClient } from '@utils/reactQuery';
import dayjs from 'dayjs';
import * as Linking from 'expo-linking';
import * as SplashScreen from 'expo-splash-screen';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Config from 'react-native-config';
import { getBuildNumber, getVersion } from 'react-native-device-info';
import 'react-native-gesture-handler';
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';
import { QueryClientProvider, useQueryErrorResetBoundary } from 'react-query';
import { useTourGuideController } from 'rn-tourguide';

SplashScreen.preventAutoHideAsync().catch(() => {
  /* reloading the app might trigger some race conditions, ignore them */
});

const App = () => {
  const [appIsReady, setIsAppReady] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  const [queryParams, setQueryParams] = useState<Partial<object | undefined>>();
  const { reset: resetQueryErrorBoundary } = useQueryErrorResetBoundary();
  const { initialize: initializeDeviceInfo, deviceInfo } = useDeviceInfoStore();
  const { initialize: initializeAppConfig, appMode, upgradeMode } = useAppConfigStore();
  const { username, authToken, isAuthenticated, setAuthToken, refreshAuthToken } = useAuthStore();
  const { userSettings, updateSetting: updateUserSetting, initialize: initializeUserSettings } = useUserSettingsStore();
  const { clearPurchasesStore } = usePurchaseHistoryFilterStore();
  const { clearSavedItems } = useCustomizeStore();
  const { clearShopData } = useShopStore();
  const { clearOffersStore } = useOffersStore();
  const { clearUserProfile } = useUserProfileStore();
  const { eventEmitter } = useTourGuideController();
  const prefix = Linking.createURL('/');
  // const mode = useColorScheme();
  // const theme = mode === 'dark' ? DarkTheme : DefaultTheme;
  const theme = DefaultTheme;
  const buildNumber = getBuildNumber();
  const version = getVersion();

  if (__DEV__) {
    require('debug').enable('commercetools,semaphore,api:*');
  }
  const handleOpenURL = useCallback(({ url }: Linking.EventType) => {
    if (url) {
      setQueryParams(Linking.parse(url).queryParams ?? undefined);
    }
  }, []);
  const clearCacheAndLogout = useCallback(async () => {
    clearShopData();
    clearPurchasesStore();
    clearSavedItems();
    clearOffersStore();
    clearUserProfile();
    setAuthToken(username, undefined);
    queryClient.clear();
    await Cache.flush();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    const handleAppLoad = async () => {
      try {
        //initialize app settings
        await initializeAppConfig(version);
        //initialize UrbanAirship Notification service
        await Notifications.initialize();
        //flush expired cache items
        await Cache.flushExpired();
        queryClient.clear();
        initI18next((err) => {
          if (err) {
            logger.error(err);
          }
        });

        const url = await Linking.getInitialURL();
        if (url) {
          setQueryParams(Linking.parse(url).queryParams ?? undefined);
        }

        const deviceData = deviceInfo || {
          supportsBiometrics: false,
          hasLocationPermissions: false,
          hasNotificationPermissions: false,
          requestPermissions: false,
        };

        // checks device to see if it has biometric hardware
        const compatible = await LocalAuthentication.hasHardwareAsync();
        if (compatible) {
          deviceData.biometricsType = await LocalAuthentication.supportedAuthenticationTypesAsync();
          const records = await LocalAuthentication.isEnrolledAsync();
          if (records) {
            deviceData.supportsBiometrics = true;
          }
        }

        //check if we have permissions for location and notification etc
        deviceData.hasLocationPermissions = await Location.hasPermissions();
        deviceData.hasNotificationPermissions = await Notifications.hasPermissions();
        deviceData.requestPermissions =
          (!deviceData.hasLocationPermissions && (await Location.canRequestPermissions())) ||
          (!deviceData.hasNotificationPermissions && (await Notifications.canRequestPermissions()));
        initializeDeviceInfo(deviceData);
        if (!userSettings) {
          initializeUserSettings({
            showAppBenefits: true,
            showTourGuide: true,
            rememberMe: false,
            localAuthentication: false,
            buildNumber: buildNumber,
            version: version,
          });
          await clearCacheAndLogout();
        } else if (version !== userSettings?.version || buildNumber !== userSettings.buildNumber) {
          updateUserSetting('showAppBenefits', true);
          updateUserSetting('showTourGuide', true);
          updateUserSetting('lastCkecked', dayjs().toDate());
        }
        if (userSettings?.rememberMe && authToken && !isAuthenticated()) {
          const updatetJwt = await refreshAuthToken();
          if (!updatetJwt) {
            setAuthToken(username, undefined);
          }
        }
        // local auth is disabled and user token has timed out log out user
        else if (authToken && !isAuthenticated() && !(userSettings?.localAuthentication ?? false)) {
          await clearCacheAndLogout();
        }
      } finally {
        await SplashScreen.hideAsync();
        setIsAppReady(true);
      }
    };
    const eventListner = Linking.addEventListener('url', handleOpenURL);
    handleAppLoad();
    return () => {
      eventListner.remove();
    };
  });

  useAppState({
    onForeground: async () => {
      //flush expired cache items
      await Cache.flushExpired();
      queryClient.clear();
      var appTimeout = (await Cache.getItem<boolean>('app-timeout')) ?? true;
      //sign out user as the app has timed out and local auth is disabled
      if ((userSettings?.rememberMe ?? false) && authToken && !isAuthenticated()) {
        const updatetJwt = await refreshAuthToken();
        if (!updatetJwt) {
          setAuthToken(username, undefined);
        }
      } else if (appTimeout && !(userSettings?.localAuthentication ?? false)) {
        await clearCacheAndLogout();
      }
      // verify / update appconfig
      await initializeAppConfig(version);
    },
    onBackground: async () => {
      await Cache.setItem('app-timeout', false, Number(Config.APP_TIMEOUT_MINUTES ?? 30));
    },
  });

  useEffectOnce(() => {
    const handleOnStop = () => {
      updateUserSetting('showTourGuide', false);
    };

    eventEmitter?.on('stop', handleOnStop);

    return () => {
      eventEmitter?.off('stop', handleOnStop);
    };
  });

  useEffect(() => {
    if (!maintenanceMode && !!appMode) {
      setMaintenanceMode(true);
    } else if (maintenanceMode && !appMode) {
      setMaintenanceMode(false);
    }
  }, [appMode, maintenanceMode]);

  useEffect(() => {
    if (!requiresUpgrade && upgradeMode === 'RequiresUpgrade') {
      clearCacheAndLogout().then(() => {
        setRequiresUpgrade(true);
      });
    } else if (requiresUpgrade && upgradeMode !== 'RequiresUpgrade') {
      setRequiresUpgrade(false);
    }
  }, [requiresUpgrade, upgradeMode, clearCacheAndLogout]);

  return !appIsReady ? (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <LoadingScreen />
    </SafeAreaProvider>
  ) : (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <OfflineNotice />
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary onReset={resetQueryErrorBoundary} FallbackComponent={ErrorFallbackComponent}>
          <Suspense fallback={<LoadingScreen />}>
            {appMode ? (
              <MaintenanceScreen mode={appMode} />
            ) : requiresUpgrade ? (
              <AppUpgradeScreen />
            ) : authToken && isAuthenticated() ? (
              <AppNavigation queryParams={queryParams} theme={theme} prefix={prefix} />
            ) : (
              <AuthNavigation queryParams={queryParams} theme={theme} prefix={prefix} />
            )}
          </Suspense>
        </ErrorBoundary>
      </QueryClientProvider>
    </SafeAreaProvider>
  );
};
export default withModalProvider(App);

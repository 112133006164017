import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { PropsWithChildren, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Animated,
  GestureResponderEvent,
  Keyboard,
  LayoutChangeEvent,
  Modal as NativeModal,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Button from './Button';
import Icon from './Icon';
import LinkButton from './LinkButton';
import Text from './Text';

export type ModalProps = PropsWithChildren<{
  visible: boolean;
  style?: StyleProp<ViewStyle>;
  location?: 'top' | 'bottom';
  header?: JSX.Element;
  headerStyle?: StyleProp<ViewStyle>;
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;
  subTextViewStyle?: StyleProp<ViewStyle>;
  okButtonOnPress?: (e: any) => void;
  okButtonText?: string;
  okButtonType?: 'primary' | 'secondary';
  okButtonStyle?: StyleProp<ViewStyle>;
  showCancel?: boolean;
  showScrollHint?: boolean;
  cancelButtonOnPress?: (e: any) => void;
  onBlur?: (e: any) => void;
  cancelButtonText?: string;
  cancelLoading?: boolean;
  okLoading?: boolean;
  cancelButtonType?: 'primary' | 'secondary';
  cancelButtonStyle?: StyleProp<ViewStyle>;
  showCloseIcon?: boolean;
  bottomPanel?: JSX.Element;
  showLinkButton?: boolean;
  linkButtonText?: string;
}>;

const Modal = forwardRef<NativeModal, ModalProps>(
  (
    {
      visible,
      children,
      style,
      location = 'bottom',
      title,
      titleStyle,
      contentStyle,
      cancelButtonText = 'OK',
      cancelButtonOnPress,
      onBlur,
      cancelButtonType = 'secondary',
      okButtonText,
      okButtonOnPress,
      okButtonType = 'secondary',
      okButtonStyle,
      headerStyle,
      buttonContainerStyle,
      subTextViewStyle,
      showCancel = true,
      cancelLoading = false,
      okLoading = false,
      showScrollHint = false,
      cancelButtonStyle,
      showCloseIcon = location !== 'top',
      header,
      showLinkButton = false,
      bottomPanel,
      linkButtonText = '',
    },
    forwardedRef,
  ) => {
    const insets = useSafeAreaInsets();
    const [showModal, setShowModal] = useState(false);
    const [openHeight, setOpenHeight] = useState<number>(0);
    const [scrollHintVisible, setScrollHintVisible] = useState(true);
    const animatedOpacity = useMemo(() => new Animated.Value(screenHeight), []);
    const animatedContent = useMemo(() => (location === 'top' ? new Animated.Value(-openHeight) : new Animated.Value(openHeight)), [location, openHeight]);
    const updateHeight = (lce: LayoutChangeEvent) => {
      setOpenHeight(lce.nativeEvent.layout.height);
    };

    const [keyboardHeight, setKeyboardHeight] = useState(0);
    useEffect(() => {
      const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
        setKeyboardHeight(e.endCoordinates.height);
      });
      const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardHeight(0);
      });

      return () => {
        keyboardDidHideListener.remove();
        keyboardDidShowListener.remove();
      };
    }, []);

    useEffect(() => {}, [keyboardHeight]);

    const closeModal = useCallback(() => {
      Animated.timing(animatedContent, {
        toValue: location === 'top' ? -openHeight : openHeight,
        duration: 100,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
      }).start(() => setShowModal(false));

      Animated.timing(animatedOpacity, {
        toValue: 0,
        duration: 250,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
      }).start();
    }, [animatedOpacity, location, animatedContent, openHeight]);

    const openModal = useCallback(() => {
      Animated.timing(animatedOpacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
      }).start(() => setShowModal(true));
      Animated.timing(animatedContent, {
        toValue: 0,
        duration: 250,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
      }).start();
    }, [animatedOpacity, animatedContent]);

    useEffect(() => {
      if (visible) {
        animatedContent.setValue(location === 'top' ? -openHeight : openHeight);
        openModal();
      } else {
        animatedContent.setValue(location === 'top' ? 0 : openHeight);
        closeModal();
      }
    }, [closeModal, openModal, visible, animatedContent, location, openHeight]);

    const onBlurPressed = (e: GestureResponderEvent) => {
      if (onBlur) {
        onBlur(e);
      } else {
        cancelButtonOnPress && cancelButtonOnPress(e);
      }
    };

    return (
      <NativeModal transparent={true} visible={showModal} ref={forwardedRef} hardwareAccelerated={true} animationType={'fade'}>
        <TouchableWithoutFeedback onPress={onBlurPressed}>
          <Animated.View
            style={[
              styles.overlay,
              {
                opacity: animatedOpacity.interpolate({
                  inputRange: [0, screenHeight],
                  outputRange: [1, 0],
                }),
              },
            ]}
          />
        </TouchableWithoutFeedback>
        <Animated.View
          onLayout={updateHeight}
          style={[
            styles.modal,
            location === 'top' ? styles.topModal : [styles.bottomModal, { bottom: keyboardHeight }],
            style,
            { ...(location === 'top' ? { paddingTop: insets.top } : { paddingBottom: insets.bottom }) },
            { transform: [{ translateY: animatedContent }] },
          ]}>
          {header ? (
            header
          ) : (
            <>
              {showCloseIcon && location !== 'top' && (
                <TouchableOpacity onPress={cancelButtonOnPress}>
                  <View style={styles.xClose}>
                    <Icon name={'x-close'} testID="closeBtn" />
                  </View>
                </TouchableOpacity>
              )}
              <View style={[styles.header, headerStyle, {}]}>
                {showCloseIcon && location === 'top' && (
                  <TouchableOpacity onPress={cancelButtonOnPress}>
                    <View style={styles.xCloseTop}>
                      <Icon name={'x-close'} testID="closeBtn" />
                    </View>
                  </TouchableOpacity>
                )}
                <Text allowFontScaling={false} style={[styles.headerText, titleStyle]} testID="title">
                  {title}
                </Text>
              </View>
            </>
          )}
          <ScrollView
            onScroll={() => {
              if (scrollHintVisible) {
                setScrollHintVisible(false);
              }
            }}
            scrollEventThrottle={16}
            style={Platform.OS === 'ios' ? { ...utilityStyles.py0 } : null}>
            <View style={[styles.childHeader, subTextViewStyle]}>
              <View style={[styles.modalContent, contentStyle]} testID="childrenView">
                {children}
              </View>
            </View>
          </ScrollView>
          {showScrollHint && scrollHintVisible ? (
            <View style={styles.scrollHintWrapper}>
              <Icon size={18} name="triangle-down" fill={colors.red} stroke={colors.red} testID="triangledown" />
            </View>
          ) : null}
          <View />
          <View style={[styles.buttonContainer, buttonContainerStyle]}>
            {showCancel && (
              <Button
                disabled={okLoading || null}
                isButtonLoading={cancelLoading}
                onPress={cancelButtonOnPress}
                title={cancelButtonText}
                type={cancelButtonType}
                size="small"
                buttonStyle={[okButtonText ? styles.dualButton : { minWidth: 205, maxWidth: 350 }, cancelButtonStyle]}
                textStyle={styles.textStyle}
                testID={'cancel'}
              />
            )}
            {okButtonText && (
              <Button
                disabled={cancelLoading || null}
                isButtonLoading={okLoading}
                onPress={okButtonOnPress}
                title={okButtonText}
                type={okButtonType}
                size="small"
                buttonStyle={[showCancel ? styles.okButton : undefined, okButtonStyle]}
                textStyle={styles.textStyle}
                testID={'ok'}
              />
            )}
            {bottomPanel}
          </View>
          {showLinkButton && (
            <View style={[{ ...utilityStyles.my3, marginTop: 'auto' }]}>
              <LinkButton
                style={[appStyles.boldLinkLargeCenter, { marginBottom: scale(30), fontFamily: FontFamily.LarsseitLight }]}
                onPress={cancelButtonOnPress}
                testID="remindMe">
                {linkButtonText}
              </LinkButton>
            </View>
          )}
        </Animated.View>
      </NativeModal>
    );
  },
);

export default Modal;

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  modal: {
    position: 'absolute',
    width: screenWidth,
    minHeight: screenHeight > 740 ? screenHeight * 0.35 : screenHeight * 0.4,
    marginTop: 'auto',
    backgroundColor: 'white',
    shadowColor: colors.white,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalContent: {
    width: screenWidth * 0.75,
    alignSelf: 'center',
  },
  bottomModal: {
    bottom: 0,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  topModal: {
    top: 0,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
  },
  header: {
    ...utilityStyles.my3,
    alignItems: 'center',
    paddingHorizontal: 25,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerText: {
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(28),
    color: colors.text,
    textAlign: 'center',
    lineHeight: lineHeight(28),
    width: screenWidth * 0.8,
  },
  childHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: Platform.select({ android: 7 }),
    paddingHorizontal: 50,
  },

  buttonContainer: {
    flexDirection: 'row',
    width: screenWidth,
    alignContent: 'space-between',
    justifyContent: 'space-evenly',
    ...utilityStyles.my3,
    marginTop: 'auto',
  },
  dualButton: {
    minWidth: 100,
    maxWidth: 150,
    borderWidth: 1,
  },
  okButton: {
    minWidth: 100,
    maxWidth: 150,
    borderWidth: 2.5,
  },
  xClose: {
    marginLeft: 16,
    marginTop: 32,
    backgroundColor: 'transparent',
  },
  xCloseTop: {
    marginLeft: 16,
    backgroundColor: 'transparent',
  },
  scrollHintWrapper: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{ translateY: 10 }],
  },
  textStyle: {
    lineHeight: Platform.select({
      android: lineHeight(13),
    }),
  },
});

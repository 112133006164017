//ref: https://github.com/Kichiyaki/react-native-barcode-generator
import Text from '@components/Text';
import colors from '@config/colors';
import barcodes, { Barcode as BarcodeGenerator } from 'jsbarcode/src/barcodes';
import React, { useCallback, useMemo } from 'react';
import { ColorValue, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import Svg, { Color, Path } from 'react-native-svg';

type Format =
  | 'CODE39'
  | 'CODE128'
  | 'CODE128A'
  | 'CODE128B'
  | 'CODE128C'
  | 'EAN13'
  | 'EAN8'
  | 'EAN5'
  | 'EAN2'
  | 'UPC'
  | 'UPCE'
  | 'ITF14'
  | 'ITF'
  | 'MSI'
  | 'MSI10'
  | 'MSI11'
  | 'MSI1010'
  | 'MSI1110'
  | 'pharmacode'
  | 'codabar';

type BarcodeProps = {
  value: string;
  format?: Format;
  width?: number;
  maxWidth?: number;
  height?: number;
  lineColor?: Color;
  background?: ColorValue;
  text?: React.ReactNode;
  textColor?: ColorValue;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  onError?: (err: any) => void;
};

const Barcode = ({
  value,
  format = 'UPC',
  width = 3,
  height = 100,
  text,
  lineColor = colors.black,
  background = colors.white,
  onError,
  textStyle,
  style,
  maxWidth,
}: BarcodeProps) => {
  const drawRect = (x: number, y: number, w: number, h: number) => {
    return `M${x},${y}h${w}v${h}h-${w}z`;
  };

  const buildSvg = useCallback(
    (barcode: { data: string; text: string }) => {
      const rects = [];
      const { data } = barcode;

      const barCodeWidth = data.length * width;
      const singleBarWidth = typeof maxWidth === 'number' && barCodeWidth > maxWidth ? maxWidth / data.length : width;
      let barWidth = 0;
      let x = 0;
      let yFrom = 0;

      for (let b = 0; b < data.length; b++) {
        x = b * singleBarWidth;
        if (data[b] === '1') {
          barWidth++;
        } else if (barWidth > 0) {
          rects[rects.length] = drawRect(x - singleBarWidth * barWidth, yFrom, singleBarWidth * barWidth, height);
          barWidth = 0;
        }
      }

      if (barWidth > 0) {
        rects[rects.length] = drawRect(x - singleBarWidth * (barWidth - 1), yFrom, singleBarWidth * barWidth, height);
      }

      return rects;
    },
    [height, maxWidth, width],
  );

  const generateBarcode = useCallback(
    (code: string, generator: BarcodeGenerator): { data: string; text: string } => {
      // If text is not a non-empty string, throw error.
      if (typeof code !== 'string' || code.length === 0) {
        throw new Error('Barcode value must be a non-empty string');
      }

      var encoder;

      try {
        encoder = new generator(code, {
          width,
          format,
          height,
          lineColor,
          background,
          flat: true,
        });
      } catch (error) {
        // If the encoder could not be instantiated, throw error.
        throw new Error('Invalid barcode format.');
      }

      // If the input is not valid for the encoder, throw error.
      if (!encoder.valid()) {
        throw new Error('Invalid barcode for selected format.');
      }

      return encoder.encode();
    },
    [background, format, height, lineColor, width],
  );

  const { bars, barCodeWidth } = useMemo(() => {
    try {
      const encoder = barcodes[format];
      if (!encoder) {
        throw new Error('Invalid barcode format.');
      }
      const encoded = generateBarcode(value, encoder);
      const barcodeWidth = encoded.data.length * width;
      return {
        bars: buildSvg(encoded),
        barCodeWidth: typeof maxWidth === 'number' && barcodeWidth > maxWidth ? maxWidth : barcodeWidth,
      };
    } catch (error: any) {
      if (__DEV__) {
        console.error(error.message);
      }
      if (onError) {
        onError(error);
      }
    }
    return {
      bars: [],
      barCodeWidth: 0,
    };
  }, [format, generateBarcode, value, width, buildSvg, maxWidth, onError]);

  return (
    <View style={[{ backgroundColor: background, alignItems: 'center' }, style]}>
      <Svg height={height} width={barCodeWidth} fill={lineColor}>
        <Path d={bars.join(' ')} />
      </Svg>
      {text && (
        <Text style={[{ textAlign: 'center' }, textStyle]} testID="barcodetext">
          {text}
        </Text>
      )}
    </View>
  );
};

export default Barcode;

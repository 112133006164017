import { CacheProvider } from '@fieldera-raleys/client-common/types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import dayjs from 'dayjs';
import logger from './logger';

const CACHE_PREFIX = 'cache';

type CacheItem = {
  value: any;
  timestamp: number;
  expiresInMinutes: number;
};

const NoCache: CacheProvider = (function () {
  return {
    setItem: async (_key: string, _value: any, _expiresInMinutes = 5) => {
      await Promise.resolve();
    },

    getItem: async <T>(_key: string, callback?: () => Promise<T>, _expiresInMinutes: number = 5, _forceRelaod: boolean = false): Promise<T | undefined> => {
      if (typeof callback === 'function') {
        return await callback();
      }
    },

    removeItem: async (_key: string): Promise<void> => {
      await Promise.resolve();
    },

    flush: async () => {
      await Promise.resolve();
    },

    flushExpired: async () => {
      await Promise.resolve();
    },
  };
})();

const Cache: CacheProvider = (function () {
  const _isExpired = (item: CacheItem) => {
    if (item.expiresInMinutes === -1) {
      return false;
    }

    const now = dayjs();
    const storedTime = dayjs(item.timestamp);
    return now.diff(storedTime, 'minute') > item.expiresInMinutes;
  };

  const _setItem = async (key: string, value: any, expiresInMinutes = 5) => {
    try {
      if (!(expiresInMinutes === -1 || expiresInMinutes > 0)) {
        throw `Key: ${key} - invalid value for expiresInMinutes`;
      }
      const item = {
        value,
        timestamp: Date.now(),
        expiresInMinutes,
      } as CacheItem;
      await AsyncStorage.setItem(`${CACHE_PREFIX}-${key}`, JSON.stringify(item));
    } catch (ex) {
      logger.error(ex);
    }
  };

  const _removeItem = async (key: string): Promise<void> => {
    try {
      await AsyncStorage.removeItem(`${CACHE_PREFIX}-${key}`);
    } catch (ex) {
      logger.error(ex);
    }
  };

  return {
    setItem: async (key: string, value: any, expiresInMinutes = 5) => {
      await _setItem(key, value, expiresInMinutes);
    },

    getItem: async <T>(key: string, callback?: () => Promise<T>, expiresInMinutes: number = 5, forceRelaod: boolean = false): Promise<T | undefined> => {
      try {
        const value = await AsyncStorage.getItem(`${CACHE_PREFIX}-${key}`);
        const item = value?.length ? (JSON.parse(value) as CacheItem) : undefined;
        let expired = false;
        if (forceRelaod && typeof callback === 'function') {
          const data = await callback();
          _setItem(key, data, expiresInMinutes);
          return data;
        } else {
          if (!item || (expired = _isExpired(item) === true)) {
            if (expired) {
              await _removeItem(key);
            }
            if (typeof callback === 'function') {
              const data = await callback();
              _setItem(key, data, expiresInMinutes);
              return data;
            } else {
              return undefined;
            }
          }
          return item.value;
        }
      } catch (ex) {
        logger.error(ex);
      }
    },

    removeItem: async (key: string): Promise<void> => {
      await _removeItem(key);
    },

    flush: async () => {
      const keys = (await AsyncStorage.getAllKeys()).filter((key) => key.startsWith(CACHE_PREFIX));
      var cacheItems = await AsyncStorage.multiGet(keys);
      cacheItems.forEach(async (item) => {
        try {
          const key = item[0];
          await AsyncStorage.removeItem(key);
        } catch (ex) {}
      });
    },

    flushExpired: async () => {
      const keys = (await AsyncStorage.getAllKeys()).filter((key) => key.startsWith(CACHE_PREFIX));
      var cacheItems = await AsyncStorage.multiGet(keys);
      cacheItems.forEach(async (item) => {
        try {
          const key = item[0];
          const value = item[1];
          if (value == null || _isExpired(JSON.parse(value))) {
            await AsyncStorage.removeItem(key);
          }
        } catch (ex) {}
      });
    },
  };
})();

export default Cache;
export { NoCache };
export const UUID_CACHE_KEY = 'uuid';
export const CATEGORIES_CACHE_KEY = 'categories';
export const ALL_FILTERS_CACHE_KEY = 'all_filters';
export const SELECTED_FILTER_CACHE_KEY = 'selected_filters';
export const POINTS_BALANCE_CACHE_KEY = 'points_balance';

import Text from '@components/Text';
import colors from '@config/colors';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { BarCodeEvent, BarCodeScanner } from 'expo-barcode-scanner';
import React, { FC, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from './Icon';

interface BarcodeScannerProps {
  closeScanner?: (hideScanner: boolean) => void;
  setSearchText?: (clearText: string) => void;
  handleDataAfterBarcodeScan?: (text: string, categoryType?: string) => void;
}

const BarcodeScanner: FC<BarcodeScannerProps> = ({ closeScanner, handleDataAfterBarcodeScan, setSearchText }) => {
  const [scanned, setScanned] = useState(false);

  const isURL = (barcodeText: string) => {
    var pattern =
      '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
      '[a-zA-Z]{2,13})' + // extension
      '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
      '|localhost)' + // OR localhost
      '(\\:\\d{1,5})?' + // port
      '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
      '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
      '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
    const regex = new RegExp(pattern);
    return regex.test(barcodeText);
  };

  const handleBarCodeScanned = ({ type, data }: BarCodeEvent) => {
    setScanned(true);

    let barcodeNumber;
    let categoryType;

    const isCorrectURL = isURL(data); //Note:  For product URL under category

    if (isCorrectURL) {
      const url = new URL(data);

      var barcodeArr = url.pathname.split('/');
      barcodeNumber = barcodeArr.pop();

      barcodeArr = barcodeArr.slice(-1);
      categoryType = barcodeArr.pop();
    } else {
      barcodeNumber = data.padStart(14, '0');
    }
    handleDataAfterBarcodeScan && handleDataAfterBarcodeScan(barcodeNumber ?? '', categoryType);
  };

  const handleCloseBtn = () => {
    setSearchText && setSearchText('');
    closeScanner && closeScanner(false);
  };

  return (
    <View style={styles.flex1}>
      <View style={[styles.container]}>
        <BarCodeScanner type={'back'} onBarCodeScanned={scanned ? undefined : handleBarCodeScanned} style={StyleSheet.absoluteFillObject}>
          <TouchableOpacity style={styles.mapCrossIcon} onPress={handleCloseBtn} hitSlop={{ bottom: 8, top: 8, left: 8, right: 8 }}>
            <Icon name="x-close" fill={colors.white} stroke={colors.white} size={20} />
          </TouchableOpacity>
        </BarCodeScanner>
      </View>
      <View style={styles.scanMsgView}>
        <Text style={styles.scanText}>Scan Product Barcode</Text>
        <Text style={styles.subText}>Center barcode within frame to check product availablity, view price and add to cart or list.</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: screenHeight * 0.15,
  },
  container: {
    height: screenHeight * 0.475,
    width: screenWidth * 0.8,
    backgroundColor: 'black',
  },
  mapCrossIcon: {
    position: 'absolute',
    right: Platform.select({ android: 25, ios: 25 }),
    top: 25,
    zIndex: 100,
  },
  scanMsgView: {
    marginTop: scale(12),
    width: screenWidth * 0.8,
  },
  scanText: {
    ...appStyles.bodyCenterBold,
    color: colors.black,
    fontSize: scale(15),
    lineHeight: scale(24),
  },
  subText: {
    ...appStyles.fontMobileBodySmallRegular,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    color: colors.black,
  },
});
export default BarcodeScanner;

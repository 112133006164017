//TODO: find replacement for expo

const initialize = () => {};

const requestPermissions = async () => Promise.resolve(false);

const canRequestPermissions = async () => Promise.resolve(false);

const hasPermissions = async () => Promise.resolve(false);

const useNotification = () => {
  // TODO:// write hooks to listen to notifications
};
const updateNamedUser = async (): Promise<void> => Promise.resolve();

const enableUserNotifications = (_: boolean) => {};

const subscribeAll = async (_: string): Promise<string[]> => Promise.resolve([]);

const unsubscribeAll = async (_: string) => {};

const fetchPreferenceCenterConfig = async () => Promise.resolve(undefined);

export {
  canRequestPermissions,
  enableUserNotifications,
  fetchPreferenceCenterConfig,
  hasPermissions,
  initialize,
  requestPermissions,
  subscribeAll,
  unsubscribeAll,
  updateNamedUser,
  useNotification,
};

import { AppConfig } from '@fieldera-raleys/client-common/services/brandywine/types';
import { enterpriseService } from '@services/brandywine';
import { Platform } from 'react-native';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { AppConfiguration } from './storeTypes';

const defaultValues: AppConfig[] = require('../../appConfig.json');

const useAppConfigStore = create<AppConfiguration>()(
  persist(
    (set, get) => ({
      appConfig: [],
      shelfGuideSortOrder: {},
      appMode: undefined,
      getConfigValue: <T>(name: string) => {
        const configValue = get().appConfig.find((c) => c.ConfigName === name)?.ConfigValue;
        if (configValue) {
          try {
            return JSON.parse(get().appConfig.find((c) => c.ConfigName === name)?.ConfigValue ?? '') as T;
          } catch (e) {
            return configValue as unknown as T;
          }
        }
        return undefined;
      },
      getConfigGroup: (groupName: string): Record<string, string> | undefined => {
        const configValue = get().appConfig.reduce((result, value) => {
          if (value.GroupName === groupName) {
            result[value.ConfigName] = value.ConfigValue;
          }
          return result;
        }, {} as Record<string, string>);

        return configValue;
      },
      initialize: async (currentVersion?: string) => {
        try {
          const appConfig = await enterpriseService.getAppConfig();
          var keys = appConfig.map((d) => `${d.GroupName}_${d.ConfigName}`);
          const sortOrder = await enterpriseService.getShelfGuideSortOrder();
          const appMode = Number(appConfig.find((x) => x.ConfigName === 'MaintenanceMode')?.ConfigValue);
          const latestVersion = appConfig.find((x) => x.ConfigName === 'LatestVersionNumber')?.ConfigValue;
          const minRequiredVersion = appConfig.find((x) => x.ConfigName === 'MinimumVersionNumberRequired')?.ConfigValue;
          set((state) => ({
            ...state,
            shelfGuideSortOrder: sortOrder,
            upgradeMode:
              Platform.OS === 'ios' || Platform.OS === 'android'
                ? (currentVersion ?? '') !== minRequiredVersion
                  ? 'RequiresUpgrade'
                  : (currentVersion ?? '') !== latestVersion
                  ? 'SuggestUpgrade'
                  : undefined
                : undefined,
            appMode:
              Platform.OS === 'ios' || Platform.OS === 'android'
                ? appMode === 1
                  ? 'PlannedMaintenance'
                  : appMode === 2
                  ? 'UnplannedMaintenance'
                  : undefined
                : undefined,
            appConfig: [...appConfig, ...defaultValues.filter((d) => !keys.includes(`${d.GroupName}_${d.ConfigName}`))],
          }));
        } catch {
          set((state) => ({
            ...state,
            appConfig: [...defaultValues],
          }));
        }
      },
    }),
    {
      name: 'uc-appConfig', // unique name
      storage: createJSONStorage<AppConfiguration>(() => asyncStorage), // (optional) by default the 'localStorage' is used
    },
  ),
);

export default useAppConfigStore;

import colors from '@config/colors';
import { aspectRatio } from '@styles/constants';
import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ListData } from './types';

type ImageListItemPropTypes = {
  data: ListData;
};

const ImageHorizontalList = ({ data }: ImageListItemPropTypes) => {
  return data.imageUrl ? (
    <TouchableOpacity onPress={() => data.onPress && data.onPress(data)}>
      <View style={styles.contentBox}>
        <View style={styles.squareImage}>
          {<Image source={{ cache: 'default', uri: `${data.imageUrl.indexOf('//') === 0 ? 'https:' + data.imageUrl : data.imageUrl}` }} style={styles.image} />}
        </View>
      </View>
    </TouchableOpacity>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  contentBox: {
    alignItems: 'flex-start',
    width: 90,
    marginRight: 8,
  },
  squareImage: {
    width: 80,
    aspectRatio: aspectRatio.square,
    padding: 5,
    borderWidth: 1,
    borderColor: '#eaddd3',
    backgroundColor: colors.white,
  },
  image: {
    height: '100%',
    width: '100%',
  },
});

export default ImageHorizontalList;

import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import Text from '@components/Text';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { ListRenderItemInfo, Platform, View, ViewStyle } from 'react-native';
import { Carousel } from '../..';
import { getCarouselItem } from '../utils';
import { DefaultSlide, ImageContentSlide, ImageSlide, OverlapSilde, SlideData } from './Slide';
import styles from './style';

const BrCarousel = ({ component, page }: BrProps) => {
  const { pageable } = component.getModels();
  const { title: carouselTitle } = component.getParameters();
  const renderItem = useCallback(({ item }: ListRenderItemInfo<SlideData>) => {
    if (item.bannerType === 'Image Content Carousel') {
      return <ImageContentSlide data={item} />;
    } else if (item.bannerType === 'Image Carousel') {
      return <ImageSlide data={item} />;
    } else if (item.bannerType === 'Content Overlap Carousel') {
      return <OverlapSilde data={item} />;
    } else {
      return <DefaultSlide data={item} />;
    }
  }, []);

  const getInterval = useCallback((slide: SlideData): number | undefined => {
    if (slide.bannerType === 'Image Content Carousel') {
      return screenWidth;
    } else if (slide.bannerType === 'Image Carousel') {
      return styles.imageSlideContainer.width;
    } else if (slide.bannerType === 'Content Overlap Carousel') {
      return styles.overlapCarouselContainer.width;
    } else {
      return screenWidth;
    }
  }, []);

  const getPaginationStyle = useCallback((slide: SlideData): ViewStyle | undefined => {
    if (slide.bannerType === 'Image Content Carousel') {
      return styles.pagination;
    } else if (slide.bannerType === 'Image Carousel') {
      return styles.pagination;
    } else if (slide.bannerType === 'Content Overlap Carousel') {
      return styles.overlapCarouselPagination;
    } else {
      return styles.defaultPagination;
    }
  }, []);

  const getCarouselStyle = useCallback((slide: SlideData): ViewStyle | undefined => {
    if (slide.bannerType === 'Image Content Carousel') {
      return styles.imageContentCarouselContainer;
    } else if (slide.bannerType === 'Image Carousel') {
      return styles.imageCarouselContainer;
    } else if (slide.bannerType === 'Content Overlap Carousel') {
      return styles.overlapCarouselContainer;
    } else {
      return styles.defaultCarouselContainer;
    }
  }, []);

  const getPaginationActiveDotStyle = useCallback((slide: SlideData): ViewStyle | undefined => {
    if (slide.bannerType === 'Image Content Carousel') {
      return styles.paginationDotActive;
    } else if (slide.bannerType === 'Image Carousel') {
      return styles.imagePaginationDot;
    } else if (slide.bannerType === 'Content Overlap Carousel') {
      return styles.overlapCarouselPaginationDot;
    } else {
      return styles.defaultPaginationDotsActive;
    }
  }, []);

  const getPaginationInactiveDotStyle = useCallback((slide: SlideData): ViewStyle | undefined => {
    if (slide.bannerType === 'Image Content Carousel') {
      return styles.paginationDot;
    } else if (slide.bannerType === 'Image Carousel') {
      return styles.imagePaginationDotActive;
    } else if (slide.bannerType === 'Content Overlap Carousel') {
      return styles.overlapCarouselPaginationDotActive;
    } else {
      return styles.defaultPaginationDots;
    }
  }, []);

  if (!pageable) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.brxmCarouselContainer}>
        <BrManageContentButton />
      </View>
    ) : null;
  }
  const carouselDocuments = getCarouselItem(page, pageable.items, pageable.items.length);

  var slideList = carouselDocuments.map<SlideData>((item, idx) => {
    const {
      content,
      image: imageRef,
      linkText,
      link: linkRef,
      title,
      bannerType,
      subtitle,
      flyer: flyerRef,
      flyerAlt,
      navigationType,
      imageAlt,
      sponsored,
      url,
    } = item.getData();
    const image = imageRef && page.getContent<ImageSet>(imageRef);
    const flyer = flyerRef && page.getContent<ImageSet>(flyerRef);
    const link = linkRef && page.getContent<Document>(linkRef);
    const width = image?.getOriginal()?.getWidth();
    const height = image?.getOriginal()?.getHeight();
    const imageWidth = width ? width * (screenWidth / width) : 0;
    const imageHeight = width && height ? height * (screenWidth / width) : 0;

    return {
      id: idx + 1,
      bannerType,
      navigationType,
      subtitle,
      imageUrl: image?.getOriginal()?.getUrl(),
      imageWidth,
      imageHeight,
      linkText,
      url,
      link,
      title,
      content,
      imageAlt,
      sponsored,
      flyerAlt,
      flyerImageUrl: flyer?.getOriginal()?.getUrl(),
    };
  });

  return (
    <View>
      <BrManageContentButton />
      {slideList && slideList.length > 0 && (
        <>
          {!!carouselTitle && <Text style={[styles.carouselTitle, appStyles.dropShadowTitle]}>{carouselTitle}</Text>}
          <Carousel
            entities={slideList}
            snapToInterval={getInterval(slideList[0])}
            renderItem={renderItem}
            showPager={slideList && slideList.length > 1 ? true : false}
            pagerStyle={getPaginationStyle(slideList[0])}
            pagerActiveItemStyle={getPaginationActiveDotStyle(slideList[0])}
            pagerInactiveItemStyle={getPaginationInactiveDotStyle(slideList[0])}
            contentContainerStyle={styles.defaultCarouselContainer}
            carouselstyle={getCarouselStyle(slideList[0])}
          />
        </>
      )}
    </View>
  );
};

export default BrCarousel;

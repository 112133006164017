import { FacetType, Filter, SearchType } from '@fieldera-raleys/client-common';
import _ from 'lodash';
import Config from 'react-native-config';
import { create } from 'zustand';
import useOffersStore from './offersStore';
import useShopStore from './shopStore';
import { SearchStore } from './storeTypes';
import useUserProfileStore from './userProfileStore';

export const defaultFilters = Object.freeze([
  {
    id: 'past_purchase_customer_ids',
    children: [
      {
        default: false,
        id: 'previouslyPurchased',
        selected: false,
        title: FacetType.PreviouslyPurchased,
        value: () => useUserProfileStore.getState().userProfile?.sepiNumber,
        controlType: 'radio',
      },
    ],
  },
  {
    id: 'on_sale_store_ids',
    children: [
      {
        default: false,
        id: 'onSale',
        selected: false,
        title: FacetType.OnSale,
        controlType: 'radio',
        value: () => `"${useShopStore.getState().selectedStore?.number ?? Config.BRSM_DEFAULT_STORE}"`,
      },
    ],
  },
  {
    id: 'loyalty_promo_ids',
    children: [
      {
        default: false,
        id: 'somethingExtraOffers',
        selected: false,
        title: FacetType.SomethingExtraOffers,
        controlType: 'radio',
        value: () =>
          [...useOffersStore.getState().acceptedSomethingExtraOffers, ...useOffersStore.getState().availableSomethingExtraOffers].map((x) => x.ExtPromotionId),
      },
      {
        default: false,
        id: 'weeklyExclusiveOffers',
        selected: false,
        title: FacetType.WeeklyExclusiveOffers,
        controlType: 'radio',
        value: () =>
          [...useOffersStore.getState().acceptedWeeklyExclusiveOffers, ...useOffersStore.getState().availableWeeklyExclusiveOffers].map(
            (x) => x.ExtPromotionId,
          ),
      },
      {
        default: false,
        id: 'digitalDeals',
        selected: false,
        title: FacetType.DigitalDeals,
        controlType: 'radio',
        value: () => [...useOffersStore.getState().availableDigitalCoupons, ...useOffersStore.getState().acceptedDigitalCoupons].map((x) => x.ExtPromotionId),
      },
    ],
  },
]) as Filter[];

const useSearchStore = create<SearchStore>()((set, get) => ({
  recentlyViewed: [] as string[],
  recentlySearched: [] as string[],
  availableFilters: _.cloneDeep<Filter[]>(defaultFilters),
  sortQuery: '',
  searchType: 'keyword',
  addRecentlyViewed: (productKey: string) => {
    var skus = get().recentlyViewed;
    skus = skus.filter((text: string) => text !== productKey);
    skus = [productKey, ...skus];
    set((state) => ({ ...state, recentlyViewed: skus.slice(0, 5) }));
  },
  addRecentlySearched: (searchText: string) => {
    var searchList = get().recentlySearched;
    searchList = searchList.filter((text: string) => text !== searchText);
    searchList = [searchText, ...searchList];
    set((state) => ({ ...state, recentlySearched: searchList.slice(0, 5) }));
  },
  removeRecentlySearched: (searchText: string) => {
    const searchList = get().recentlySearched;
    set((state) => ({ ...state, recentlySearched: searchList.filter((text) => text !== searchText) }));
  },
  clear: () => {
    set((state) => ({ ...state, recentlySearched: [], recentlyViewed: [], availableFilters: _.cloneDeep<Filter[]>(defaultFilters) }));
  },
  setAvailableFilters: (filters: Filter[]) => {
    set((state) => ({ ...state, availableFilters: filters }));
  },
  getSelectedFilters: () => {
    const availableFilters = get().availableFilters;
    var result: Filter[] = [];
    availableFilters.forEach((f) => {
      var selected = f.children.filter((c) => c.selected);
      if (selected.length) {
        result.push({ ...f, children: [...selected] });
      }
    });
    return result;
  },
  getSelectedFiltersCount: () => {
    const selectedFilters = get().getSelectedFilters();
    var count = 0;
    selectedFilters.forEach((f) => {
      var selected = f.children.filter((c) => c.selected);
      count = count + (f.title ? 1 : selected.length);
    });
    return count;
  },
  setSortQuery: (sortBy: string) => {
    set((state) => ({ ...state, sortQuery: sortBy }));
  },
  resetFilters: () => {
    const filters = get().availableFilters;
    filters
      .flatMap((f) => f.children)
      .forEach((c) => {
        c.selected = false;
      });
    set((state) => ({ ...state, availableFilters: filters, sortQuery: '' }));
  },
  selectFilter: (filterId: string, selected: boolean = true) => {
    const filters = get().availableFilters;
    filters
      .flatMap((f) => f.children)
      .forEach((c) => {
        c.selected = c.id === filterId ? selected : c.selected;
      });
    set((state) => ({ ...state, availableFilters: filters }));
  },
  isFilterSelected: (filterId: string): boolean => {
    const filters = get().availableFilters;
    const result = filters.flatMap((f) => f.children).find((c) => c.id.toLowerCase() === filterId.toLowerCase());
    return result?.selected ?? false;
  },
  setSearchType: (searchType: SearchType) => {
    set((state) => ({ ...state, searchType }));
  },
}));

export default useSearchStore;

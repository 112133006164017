/* eslint-disable no-bitwise */

import Text from '@components/Text';
import colors from '@config/colors';
import { BottomSheetBackdropProps, BottomSheetModal } from '@gorhom/bottom-sheet';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import { decimalFormat, hapticNotify } from '@utils/helpers';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListRenderItemInfo, Platform, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Config from 'react-native-config';
import { FlatList } from 'react-native-gesture-handler';
import { useSharedValue } from 'react-native-reanimated';
import { CustomBackdrop, CustomHeaderHandle } from './bottomSheet';

interface QuantityBoxProps {
  maxLimit?: Promise<number>;
  onPress?: (event: any) => void;
  initialValue?: number;
  onChange: (value: number) => void;
  showCartText?: boolean;
  showToggle?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  countStyle?: StyleProp<TextStyle>;
  cartStyle?: StyleProp<TextStyle>;
  borderStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  incrementBy?: number;
  minLimit?: number;
  qtyFormat?: (index: number) => string | undefined;
  sellType?: { key: string; label: string };
}
const ITEM_HEIGHT = 42;

type QuantityButton = 'increase' | 'decrease';

const QuantityBox: FC<QuantityBoxProps> = ({
  onChange,
  initialValue = 0,
  maxLimit,
  showToggle = true,
  showCartText = false,
  containerStyle,
  countStyle,
  cartStyle,
  borderStyle,
  disabled,
  incrementBy = 1,
  minLimit = 1,
  qtyFormat,
}) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [quantityArray, setQuantityArray] = useState<number[]>([]);

  useEffect(() => {
    if (maxLimit) {
      maxLimit?.then((L) => {
        const count = (L - minLimit) / incrementBy;
        setQuantityArray(Array.from({ length: count + 2 }, (_, i) => i));
      });
    } else {
      const count = (~~Config.MAX_LINE_ITEM_QUANTITY - minLimit) / incrementBy;
      setQuantityArray(Array.from({ length: count + 2 }, (_, i) => i));
    }
  }, [maxLimit, incrementBy, minLimit, qtyFormat]);
  const bottomSheetRef = useRef<BottomSheetModal>(null);
  const snapPoints = useMemo(() => ['50%', '50%'], []);
  const animatedIndex = useSharedValue(1);

  // refs
  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleButtonClick = async (button: QuantityButton) => {
    hapticNotify('notificationSuccess');
    if (disabled) {
      return;
    }
    var currentValue = selectedValue;
    var triggerEvent = false;
    if (button === 'decrease') {
      if (currentValue > 0) {
        currentValue -= 1;
        triggerEvent = true;
      }
    } else {
      if (currentValue < quantityArray.length - 1) {
        if (quantityArray?.length - 1 === 0) {
          return;
        }
        currentValue += 1;
        triggerEvent = true;
      }
    }
    if (triggerEvent) {
      // logger.log('INCREASE QUANTITY:', 'CV', currentValue, 'incBy', incrementBy);
      setSelectedValue(currentValue);
      onChange(currentValue);
    }
  };

  const handleTextOnPress = () => !disabled && bottomSheetRef.current!.present();

  const handleSelect = useCallback(
    (item: any) => {
      hapticNotify('notificationSuccess');
      if (item < incrementBy) {
        bottomSheetRef.current!.dismiss();
        setTimeout(() => {
          setSelectedValue(0);
          onChange(0);
        }, 50);
        return;
      }
      setSelectedValue(item);
      onChange(item);
      bottomSheetRef.current!.dismiss();
    },
    [incrementBy, onChange],
  );
  const Backdrop = useCallback(
    (props: BottomSheetBackdropProps) => <CustomBackdrop {...props} onPress={() => bottomSheetRef.current?.dismiss()} animatedIndex={animatedIndex} />,
    [animatedIndex],
  );

  const CustomHandle = useCallback((props) => <CustomHeaderHandle title={'Select Quantity'} {...props} />, []);

  const renderItem = ({ item }: ListRenderItemInfo<number>) => {
    return (
      <View key={`itemInfo_${item}`} style={styles.quantityItemContainer}>
        <TouchableOpacity onPress={() => handleSelect(item)}>
          <Text style={selectedValue === item ? styles.selectedOption : styles.option} testID="item">
            {item <= 0 ? (
              <Text testID="removeText" style={styles.removeText}>
                Remove
              </Text>
            ) : qtyFormat ? (
              qtyFormat(item) ?? ''
            ) : (
              '' + item
            )}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <View style={[showToggle ? styles.quantityBox : {}, containerStyle]}>
      {showToggle && (
        <TouchableOpacity activeOpacity={0.7} onPress={() => handleButtonClick('decrease')} style={styles.decQuantityButton} testID="decreaseClick">
          <Text testID="decreaseText" style={[styles.quantityToggleButton, { textAlign: 'right' }]}>
            -
          </Text>
        </TouchableOpacity>
      )}
      <View style={[styles.quantityTextWrapper, borderStyle]}>
        <TouchableOpacity onPress={handleTextOnPress} style={{ alignItems: 'center' }}>
          <Text adjustsFontSizeToFit={true} numberOfLines={1} style={[styles.quantityText, countStyle]} testID="selectedValueText">
            {qtyFormat ? qtyFormat(decimalFormat(selectedValue)) ?? '' : '' + decimalFormat(selectedValue)}
          </Text>
          {showCartText && (
            <Text style={[styles.quantityCartText, cartStyle]} testID="inCartText">
              In Cart
            </Text>
          )}
        </TouchableOpacity>
      </View>

      {showToggle && (
        <TouchableOpacity activeOpacity={0.7} onPress={() => handleButtonClick('increase')} style={styles.incQuantityButton} testID="increaseClick">
          <Text style={styles.quantityToggleButton} testID="increase">
            +
          </Text>
        </TouchableOpacity>
      )}
      <BottomSheetModal
        ref={bottomSheetRef}
        snapPoints={snapPoints}
        backdropComponent={Backdrop}
        handleComponent={CustomHandle}
        enablePanDownToClose={true}
        enableDismissOnClose={true}>
        <View style={{ width: screenWidth }}>
          {quantityArray.length && (
            <FlatList
              data={quantityArray}
              snapToInterval={ITEM_HEIGHT}
              initialScrollIndex={quantityArray.length === 1 || selectedValue > quantityArray.length ? 0 : selectedValue}
              keyExtractor={(_, index) => String(index)}
              renderItem={renderItem}
              maxToRenderPerBatch={5}
              bounces={false}
              windowSize={2}
              decelerationRate="fast"
              showsVerticalScrollIndicator={false}
              getItemLayout={(_, index) => ({ length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index })}
            />
          )}
        </View>
      </BottomSheetModal>
    </View>
  );
};
export default QuantityBox;

const styles = StyleSheet.create({
  quantityBox: {
    flexDirection: 'row',
    width: '100%',
    height: 50,
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: 0,
    marginBottom: 5,
    marginLeft: 7,
    borderRadius: 100,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  decQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  incQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{ translateY: 2 }],
    width: '30%',
  },
  quantityToggleButton: {
    marginTop: Platform.select({ android: -7 }),
    fontSize: scale(28),
    fontFamily: 'Larsseit',
    color: colors.dark,
    width: '50%',
  },
  quantityTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '40%',
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: '#c69480',
    height: 38,
    top: -5,
  },
  quantityText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit-Bold',
    color: colors.dark,
  },
  quantityCartText: {
    fontSize: scale(12),
    fontFamily: 'Larsseit-Bold',
    color: colors.dark,
  },
  option: {
    fontSize: scale(20),
    fontFamily: 'Larsseit-Light',
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(24),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedOption: {
    fontSize: scale(20),
    fontFamily: 'Larsseit-Bold',
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(24),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.cream,
  },
  modalContainer: {
    borderWidth: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    // marginTop: 0,
    // paddingTop: 0,
    // backgroundColor: 'red',
  },
  modalContent: {
    margin: 0,
    borderRadius: 0,
    padding: 0,
  },
  modalHeader: {
    height: 60,
    // backgroundColor: 'gold',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 25,
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
  },
  removeText: {
    fontSize: scale(18),
    lineHeight: lineHeight(24),
    fontFamily: 'Larsseit-Bold',
  },
  chooseQuantityText: {
    color: colors.darkText,
    fontSize: scale(21),
    fontFamily: 'Larsseit-Bold',
    marginTop: 7.5,
  },
  quantityItemContainer: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
  },
});

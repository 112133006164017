import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Step } from '@fieldera-raleys/client-common';
import { CustomizeStackRoutes } from '@navigation/routes';
import { CommonActions, useNavigation } from '@react-navigation/native';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { useCustomModalContext } from '../contexts';
import Icon from './Icon';
import Modal from './Modal';

interface StepNavigatorProps {
  steps?: Step[];
  currStep: number;
  forwardNavEnabled: boolean;
}

const StepNavigator: FC<StepNavigatorProps> = ({ currStep, forwardNavEnabled }): JSX.Element => {
  const navigation = useNavigation();
  const { setCurrStep, steps, inCart, configType, productLeadTime } = useCustomizeStore();
  const [showInfo, setShowInfo] = useState(false);
  const { showAlertModal } = useCustomModalContext();
  let leadMessageEx =
    productLeadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ? (
      <Text style={styles.leadTimeText}>
        This item requires at least{' '}
        <Text style={[styles.leadTimeText, { textDecorationLine: 'underline' }]}>
          {productLeadTime > +Config.LEAD_TIME_MAX_HOURS_TOSHOW * 60 ? `${productLeadTime / 60 / 24 + 1} day` : `${productLeadTime / 60}-hour`}
        </Text>{' '}
        advance notice.
      </Text>
    ) : (
      <Text style={styles.leadTimeText}>
        This item requires at least <Text style={[styles.leadTimeText, { textDecorationLine: 'underline' }]}>24-hour</Text> advance notice.
      </Text>
    );

  const alert = () => {
    showAlertModal({ title: 'Missing Selections', message: 'Select more choices for your customized item' });
  };

  const backAlert = () => {
    showAlertModal({ title: 'First step', message: 'Select your first choice and continue' });
  };

  const navBack = () => {
    if (currStep - 2 < 0) {
      backAlert();
      return;
    }
    var navPage = steps[currStep - 2];
    setCurrStep(currStep - 1);
    let specializedPages = ['theme', 'size', 'filling', 'container', 'decoration'];

    if (
      navPage.pageItem?.toLowerCase().includes('cake flavor') ||
      navPage.pageItem?.toLowerCase().includes('icing') ||
      navPage.pageItem?.toLowerCase().includes('donut type') ||
      navPage.pageItem?.toLowerCase().includes('border') ||
      (navPage.selectionOption === 'select' && !specializedPages.some((pi) => navPage?.pageItem?.toLowerCase().includes(pi)))
    ) {
      const navAction = CommonActions.navigate({
        name: configType === 'cakeBuilder' ? CustomizeStackRoutes.BasicSelection : CustomizeStackRoutes.Selection,
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (navPage.pageItem?.includes('Theme')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Theme,
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (navPage.pageItem?.includes('Filling')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Filling,
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (navPage.selectionOption || navPage.pageItem?.toLowerCase().includes('decoration')) {
      const navAction = CommonActions.navigate({
        name: 'Selection',
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }
    if (navPage.pageItem) {
      navigation.navigate(navPage.pageItem as CustomizeStackRoutes, navPage);
    }
  };

  const navForward = () => {
    if (currStep + 1 > steps.length || !forwardNavEnabled) {
      if (!forwardNavEnabled) {
        alert();
      }
      return;
    }
    var navPage = steps[currStep];
    setCurrStep(currStep + 1);
    let specializedPages = ['theme', 'size', 'filling', 'container', 'decoration'];

    if (
      navPage.pageItem?.toLowerCase().includes('cake flavor') ||
      navPage.pageItem?.toLowerCase().includes('icing') ||
      (navPage.pageItem?.toLowerCase().includes('decoration') && navPage.slug?.includes('donut')) ||
      navPage.pageItem?.toLowerCase().includes('border') ||
      (navPage.selectionOption === 'select' && !specializedPages.some((pi) => navPage?.pageItem?.toLowerCase().includes(pi)))
    ) {
      const navAction = CommonActions.navigate({
        name: configType === 'cakeBuilder' ? CustomizeStackRoutes.BasicSelection : CustomizeStackRoutes.Selection,
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (navPage.pageItem?.includes('Theme')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Theme,
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (navPage.pageItem?.includes('Filling')) {
      const navAction = CommonActions.navigate({
        name: CustomizeStackRoutes.Filling,
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }

    if (navPage.selectionOption || navPage.pageItem?.toLowerCase().includes('decoration')) {
      const navAction = CommonActions.navigate({
        name: 'Selection',
        params: navPage,
        key: navPage.pageItem,
      });
      navigation.dispatch(navAction);
      return;
    }
    navigation.navigate(navPage.pageItem, navPage);
  };

  return (
    <>
      <Modal
        style={{ minHeight: screenHeight * 0.4, maxHeight: screenHeight * 0.8, padding: 0, flexGrow: 1 }}
        contentStyle={styles.modalContent}
        title={'Customization\nSteps'}
        titleStyle={{ lineHeight: lineHeight(25) }}
        headerStyle={{
          height: 100,
          marginTop: 0,
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 0,
        }}
        location={'top'}
        visible={showInfo}
        okButtonStyle={[appStyles.secondaryButtonSmall, { width: 130, bottom: 0 }]}
        buttonContainerStyle={{ height: 80, marginBottom: 0, backgroundColor: colors.white, borderRadius: 25 }}
        cancelButtonType={'secondary'}
        cancelButtonOnPress={() => setShowInfo(false)}
        cancelButtonText={'Close'}>
        {/* <View style={{ flexGrow: 1, maxHeight: screenHeight * 0.8 }}> */}
        <View style={styles.modalScrollView}>
          {steps?.map((step, index) => {
            return (
              <View>
                <Text style={[appStyles.bodyLargeLeft, styles.modalText, index === 0 ? { marginTop: 0 } : null]} key={index.toString()}>
                  <Text style={appStyles.bodyLeftBold}>Step {index + 1}: </Text>{' '}
                  {step.pageItem === 'Message' ? 'Add' : step.pageItem === 'Notes' ? 'Add' : step.pageItem === 'Review' ? 'Review' : 'Select'}{' '}
                  {step.pageItem === 'Message' ? 'Message' : step.pageItem === 'Review' ? 'Order Details' : step.pageItem}
                </Text>
              </View>
            );
          })}
        </View>
        {/* </View> */}
      </Modal>
      <View style={styles.container}>
        {currStep === 1 && +productLeadTime > +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES && <View style={styles.leadTimeContainer}>{leadMessageEx}</View>}
        <View style={styles.stepNumberWrapper}>
          <Text style={appStyles.bodySmallRed}>
            Step {currStep} of {steps.length}
          </Text>
          <TouchableOpacity onPress={() => setShowInfo(!showInfo)}>
            <Icon name="info-icon" size={18} style={{ marginLeft: 12 }} />
          </TouchableOpacity>
        </View>
        <View style={styles.stepActions}>
          {currStep - 2 < 0 || inCart ? (
            <View style={{ flex: 0.33 }} />
          ) : (
            <View style={{ flex: 0.33 }}>
              <TouchableOpacity onPress={navBack}>
                <Icon name={'arrow-left'} size={22} />
              </TouchableOpacity>
            </View>
          )}
          <View style={{ flex: 1 }}>
            <Text style={[appStyles.bodySmallBold, styles.title]}>{steps[currStep - 1]?.pageItem}</Text>
          </View>
          {currStep + 1 > steps.length ? (
            <View style={{ flex: 0.33 }} />
          ) : (
            <View style={{ flex: 0.33, alignItems: 'flex-end' }}>
              <TouchableOpacity onPress={navForward}>
                <Icon name={'arrow-right'} size={22} />
              </TouchableOpacity>
            </View>
          )}
        </View>
        <View style={styles.stepProgress}>
          <View style={styles.outterProgress}>
            <View
              style={{
                height: '100%',
                width: `${(currStep / steps.length) * 100}%`,
                backgroundColor: colors.secondary,
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -3,
  },
  leadTimeContainer: {
    width: screenWidth,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
    backgroundColor: '#328c81',
  },
  leadTimeText: {
    color: colors.white,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
  },
  underlineLeadTimeText: {
    textDecorationLine: 'underline',
    color: colors.white,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
  },
  modalScrollView: {
    // minHeight: screenHeight < 740 ? screenHeight * 0.02 : screenHeight * 0.05,
    flexGrow: 1,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 16,
  },
  stepNumberWrapper: {
    flexDirection: 'row',
  },
  stepActions: {
    flexDirection: 'row',
    width: '70%',
    alignSelf: 'center',
    // justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginTop: 5,
  },
  title: {
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(17),
    alignSelf: 'center',
    textAlign: 'center',
  },
  outterProgress: {
    width: '100%',
    height: 4,
    borderRadius: 10,
    backgroundColor: colors.navBorder,
  },
  stepProgress: {
    width: '70%',
    marginTop: 16,
  },
  modalContent: {
    // height: screenHeight < 740 ? '88%' : '91%',
    height: '100%',
    width: screenWidth,
    margin: 0,
    paddingHorizontal: 30,
    paddingBottom: 16,
    paddingTop: 0,
    alignItems: 'flex-start',
  },
  modalText: {
    marginVertical: 12,
  },
});

export default StepNavigator;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { BackButton, Button, Icon, KeyboardAvoidingScreen, LinkButton, LoadingScreen, WebBrowserLink } from '@components';
import Text from '@components/Text';
import { mapping } from '@components/bloomreach';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import FormCheckbox from '@components/forms/FormCheckBox';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { fontSize } from '@config/typography';
import { AuthToken } from '@fieldera-raleys/client-common';
import { useBrxmPage, useEffectOnce } from '@hooks';
import * as LocalAuthentication from '@hooks/useLocalAuthentication';
import routes, { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { authService } from '@services/brandywine';
import { useAuthStore, useDeviceInfoStore, useUserSettingsStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import helpers, { hapticNotify } from '@utils/helpers';
import React, { useCallback, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Keyboard, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { getBuildNumber, getVersion } from 'react-native-device-info';
import { useTourGuideController } from 'rn-tourguide';
import * as Yup from 'yup';
import { useCustomModalContext } from '../contexts';

type LoginScreenProp = AuthStackScreenProps<AuthStackRoutes.SignIn>;
type LoginForm = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const LoginScreen = ({ navigation }: LoginScreenProp) => {
  const { t } = useTranslation('login');
  const { deviceInfo, getBiometricType } = useDeviceInfoStore();
  const [loginScreenState, setLoginScreenState] = useState<{
    loginFailed: boolean;
    useBiometricLogin: boolean;
    biometricLogin: boolean;
    showPassword: boolean;
    isLoading: boolean;
  }>({
    loginFailed: false,
    useBiometricLogin: deviceInfo?.supportsBiometrics || false,
    biometricLogin: false,
    showPassword: false,
    isLoading: false,
  });
  const { showModal, hideModal } = useCustomModalContext();
  const { brxmConfiguration, brxmPage } = useBrxmPage();
  const passwordRef = React.createRef<TextInput>();
  const { username, authToken, setAuthToken, refreshAuthToken } = useAuthStore();
  const { userSettings, updateSetting: updateUserSetting } = useUserSettingsStore();
  const buildNumber = getBuildNumber();
  const version = getVersion();
  const errorHandler = useErrorHandler();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .test('email-or-se_number', function (value) {
        if (value) {
          const emailRegex = helpers.EMAIL_REGEX;
          const seNumberRegex = helpers.SENUMBER_REGEX; // Change this regex based on requirement
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = seNumberRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
        return false;
      })
      .label('Email'),
    password: Yup.string().required().min(4).label('Password'),
  });

  const {
    stop, // a function  to stopping it
  } = useTourGuideController();

  useEffectOnce(() => {
    stop();
  });

  const handleSignIn = async (email: string, updatedAuthToken: AuthToken, enableLocalAuthentication: boolean) => {
    updateUserSetting('localAuthentication', enableLocalAuthentication);
    setAuthToken(email, updatedAuthToken);
  };

  const onSubmit = async ({ email, password, rememberMe }: LoginForm) => {
    Keyboard.dismiss();
    setLoginScreenState((prevState) => ({
      ...prevState,
      loginFailed: false,
      isLoading: true,
    }));
    try {
      updateUserSetting('rememberMe', rememberMe);
      const loginToken = await authService.login(email, password);
      if (!loginToken) {
        setLoginScreenState((prevState) => ({
          ...prevState,
          loginFailed: true,
          isLoading: false,
        }));
        hapticNotify('notificationError');
      } else {
        updateUserSetting('showTourGuide', true);

        if (deviceInfo && deviceInfo.supportsBiometrics && deviceInfo.biometricsType?.length) {
          let biometricsName = getBiometricType();
          let biometricsMessage = biometricsName !== 'Biometrics' ? biometricsName : 'face or fingerprint recognition';

          showModal({
            location: 'top',
            children: (
              <View style={[styles.biometricModalContainer]} testID={'biometricsView'}>
                {Platform.select({
                  android: (
                    <Icon
                      testID="biometricsAndroidIcon"
                      style={biometricsName === 'Biometrics' ? styles.iconStyleBiometrics : styles.iconStyle}
                      name={
                        biometricsName === 'Biometrics'
                          ? 'android-biometric'
                          : biometricsName === 'Fingerprint Unlock'
                          ? 'finger-biometric'
                          : 'android-face-biometric'
                      }
                      size={biometricsName === 'Biometrics' ? 200 : 90}
                    />
                  ),
                  ios: (
                    <Icon
                      testID="biometricIcon"
                      style={[styles.iconStyle]}
                      name={biometricsName === 'Touch ID' ? 'finger-biometric' : 'face-biometric'}
                      size={90}
                    />
                  ),
                })}
                <Text style={styles.headerText}>{t('biometricsMessage', { biometricsName: biometricsMessage })}</Text>
                <Text style={[appStyles.bodyMediumLight, styles.textCenter, styles.modalText]}>
                  {t('biometricsSubMessage', { biometricsName: biometricsMessage })}
                </Text>
                <Button
                  testID="biometricsButton"
                  title={t('biometricsButton', { biometricsName: biometricsMessage })}
                  type="secondary"
                  size="small"
                  buttonStyle={styles.buttonStyle}
                  textStyle={[]}
                  onPress={async () => {
                    hideModal();
                    await handleSignIn(email, loginToken, true);
                  }}
                />
                <TouchableOpacity
                  testID="notNowView"
                  onPress={async () => {
                    hideModal();
                    await handleSignIn(email, loginToken, false);
                  }}>
                  <Text testID="notNowText" style={[appStyles.fontMobileBodyCenterRegular, styles.link]}>
                    {t('notNow')}
                  </Text>
                </TouchableOpacity>
              </View>
            ),
            contentStyle: biometricsName === 'Biometrics' ? styles.biometricsModalContent : {},
            style: {},
            showCancel: false,
          });
          setLoginScreenState((prevState) => ({ ...prevState, isLoading: false }));
        } else {
          await handleSignIn(email, loginToken, true);
        }
      }
    } catch (error) {
      errorHandler(error);
      setLoginScreenState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleBiometricLogin = useCallback(async () => {
    if (!authToken || !authToken.refreshToken) {
      return;
    }
    if (userSettings && userSettings.localAuthentication) {
      const result = await LocalAuthentication.authenticateAsync({
        promptMessage: 'Please authenticate',
        disableDeviceFallback: true,
        cancelLabel: 'Cancel',
      });
      if (result.success) {
        try {
          setLoginScreenState((prevState) => ({
            ...prevState,
            biometricLogin: true,
          }));
          // use api to validate stored credentials and set context
          const updatetJwt = await refreshAuthToken();
          if (!updatetJwt) {
            setLoginScreenState((prevState) => ({
              ...prevState,
              useBiometricLogin: false,
              biometricLogin: false,
              loginFailed: true,
            }));
          }
        } catch (ex) {
          setLoginScreenState((prevState) => ({
            ...prevState,
            useBiometricLogin: false,
            biometricLogin: false,
          }));
          errorHandler(ex);
        }
      } else if (result.error === 'user_cancel') {
        setLoginScreenState((prevState) => ({
          ...prevState,
          useBiometricLogin: false,
        }));
      }
    }
  }, [authToken, userSettings, refreshAuthToken, errorHandler]);

  useEffectOnce(() => {
    if (loginScreenState.useBiometricLogin) {
      handleBiometricLogin();
    }
  });

  const handleBackButtonPress = () => navigation.navigate(AuthStackRoutes.AppBenefits);
  const toggleShowPassword = () => setLoginScreenState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
  return !brxmPage || !brxmConfiguration ? (
    <LoadingScreen />
  ) : (
    // @ts-ignore
    <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
      <KeyboardAvoidingScreen keyboardVerticalOffset={-screenHeight * 0.25} headerVisible={false} testID={'loginView'}>
        <View style={styles.brxmContainer} testID={'brComponent'}>
          <BackButton style={styles.backButton} onPress={handleBackButtonPress} />
          <BrComponent path="main" />
        </View>
        <ScrollView style={styles.loginForm} testID={'loginForm'}>
          <Form
            initialValues={{ email: username, password: '', rememberMe: userSettings?.rememberMe ?? false }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}>
            <ErrorMessage
              error="Invalid email and/or password."
              visible={loginScreenState.loginFailed}
              style={{ textAlign: 'center' }}
              testID={'loginErrorMsg'}
            />
            <FormField
              testID="email"
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="email-address"
              name="email"
              textContentType="emailAddress"
              returnKeyType="next"
              onSubmitEditing={() => passwordRef?.current?.focus()}
            />
            <FormField
              testID="password"
              autoCapitalize="none"
              autoCorrect={false}
              name="password"
              secureTextEntry={!loginScreenState.showPassword}
              textContentType="password"
              ref={passwordRef}
              icon={{
                name: loginScreenState.showPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
                testID: 'eyeIcon',
              }}
              iconPress={toggleShowPassword}
              keyboardType={Platform.select({ android: 'default' })}
            />
            <FormCheckbox name="rememberMe" testID="rememberMe">
              <Text style={styles.labelText}>Keep me logged in</Text>
            </FormCheckbox>
            <SubmitButton title="Log In" isButtonLoading={loginScreenState.isLoading} testID={'SignIn'} />
            <View style={styles.forgotContainer}>
              <LinkButton testID="forgotEmail" onPress={() => navigation.navigate(AuthStackRoutes.ForgotEmail)} style={appStyles.boldLinkLargeCenterB}>
                Forgot Email?
              </LinkButton>
              <LinkButton testID="forgotPassword" onPress={() => navigation.navigate(AuthStackRoutes.ForgotPassword)} style={appStyles.boldLinkLargeCenterB}>
                Forgot Password?
              </LinkButton>
            </View>
            <View style={styles.createContainer}>
              <LinkButton
                onPress={() => navigation.navigate(AuthStackRoutes.CreateAccountInitial, {})}
                style={appStyles.bodyCenterRegular}
                testID="createAccount">
                Create Account
              </LinkButton>
            </View>
            <View style={styles.versionContainer} testID={'verionView'}>
              <Text testID="versionText" style={[appStyles.bodySmall, { fontSize: fontSize.TertiaryText }]}>{`v. ${version} (${buildNumber})`}</Text>
            </View>
          </Form>
          <View style={styles.footer}>
            <View style={styles.footerView}>
              <WebBrowserLink style={[appStyles.footerLink, styles.footers]} href={routes.PRIVACY_POLICY} testID={'privacyPolicy'}>
                Privacy Policy
              </WebBrowserLink>
              <WebBrowserLink style={[appStyles.footerLink, styles.footers]} href={routes.TERMS_OF_USE} testID={'termsOfUse'}>
                Terms of Use
              </WebBrowserLink>
            </View>
            <View style={styles.footerView}>
              <WebBrowserLink style={[appStyles.footerLink, styles.footers]} href={routes.NOTICE_AT_COLLECTION} testID={'noticeAtCollection'}>
                Notice at Collection
              </WebBrowserLink>
              <WebBrowserLink style={[appStyles.footerLink, styles.footers]} href={routes.TERMS_AND_CONDITIONS} testID={'termsAndConditions'}>
                Terms & Conditions
              </WebBrowserLink>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingScreen>
      {loginScreenState.biometricLogin && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" />
        </View>
      )}
    </BrPage>
  );
};

const styles = StyleSheet.create({
  brxmContainer: {
    height: '33%',
  },
  textCenter: {
    textAlign: 'center',
  },
  backButton: {
    position: 'absolute',
    left: 15,
    zIndex: 999,
    top: 20,
  },
  loginForm: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    flex: 1,
  },
  forgotContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 15,
  },
  createContainer: {
    flex: 1,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  versionContainer: {
    flex: 1,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    flexWrap: 'wrap',
    bottom: 0,
  },
  footerView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexGrow: 1,
    textAlign: 'center',
    marginBottom: 10,
  },
  footers: {
    textAlign: 'center',
    width: scale(132),
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  biometricModalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  biometricsModalContent: {
    width: screenWidth,
  },
  headerText: {
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(28),
    color: colors.text,
    textAlign: 'center',
    lineHeight: lineHeight(28),
    width: screenWidth * 0.8,
    marginTop: 24,
  },
  modalText: {
    marginVertical: 24,
  },
  iconStyle: {
    width: 90,
    height: 90,
  },
  iconStyleBiometrics: {
    width: 200,
    height: 90,
  },
  buttonStyle: {
    paddingVertical: 0,
    maxWidth: 500,
  },
  link: {
    fontFamily: FontFamily.LarsseitBold,
    marginTop: 24,
    marginBottom: 40,
  },
  labelText: {
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
  },
});
export default LoginScreen;

import colors from '@config/colors';
import { OfflinePaymentType } from '@fieldera-raleys/client-commercetools/schema';
import { offerService } from '@services/brandywine';
import { useCartStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, screenHeight, screenWidth } from '@styles/constants';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { useCustomModalContext } from '../../contexts';
import { formatValue } from '../../utils/helpers';
import {
  DeliveryTip,
  getCalculatedTip,
  getDeliveryTip,
  getDonationAmount,
  getOfflinePayment,
  getSelectedPayment,
  parseDeliveryTip,
} from '../../utils/orderHelpers';
import RadioButton from '../RadioButton';
import Text from '../Text';

const PERCENT0: DeliveryTip = { tipAmount: 0, tipType: '%' };
const PERCENT2: DeliveryTip = { tipAmount: 2, tipType: '%' };
const PERCENT5: DeliveryTip = { tipAmount: 5, tipType: '%' };
const PERCENT10: DeliveryTip = { tipAmount: 10, tipType: '%' };
const PERCENT15: DeliveryTip = { tipAmount: 15, tipType: '%' };

type CartViewOptionsProps = {
  containerStyle?: StyleProp<ViewStyle>;
  optionType?: 'tip' | 'fff' | 'ptype' | 'promo';
  infoBox?: StyleProp<ViewStyle>;
};

type CartViewOptionsHandles = {
  doApply: () => void;
};

const CartViewOptions = forwardRef(({ containerStyle, optionType = 'ptype', infoBox }: CartViewOptionsProps, ref: React.Ref<CartViewOptionsHandles>) => {
  const [tipValue, setTipValue] = useState<DeliveryTip | undefined>();
  const [tipValueStr, setTipValueStr] = useState<string>('');
  const { cart, setDeliveryTip, setDonationAmount, setPaymentProfile } = useCartStore();
  const [donationValue, setDonationValue] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [paymentType, setPaymentType] = useState<OfflinePaymentType>('Credit-Card-On-Pickup');
  const { t } = useTranslation('cart');
  const { showAlertPopup } = useCustomModalContext();

  React.useImperativeHandle(ref, () => ({
    doApply() {
      doApply();
    },
  }));

  const [otherTip, setOtherTip] = useState('');
  const setOtherTipValue = (val: string) => {
    const fVal = val.replace(/[^0-9.]*([0-9]+([.][0-9]*)?|[.][0-9]+).*$/, '$1');
    setTipValueStr(fVal);
    setTipValue(parseDeliveryTip(`${fVal}|$`));
    setOtherTip(val);
  };

  useEffect(() => {
    if (cart) {
      if (optionType === 'tip') {
        setTipValue(getDeliveryTip(cart));
      } else if (optionType === 'ptype') {
        const pt = getSelectedPayment(cart);
        const olm = getOfflinePayment(cart);
        if (!pt && olm) {
          setPaymentType(olm);
        }
      } else {
        setDonationValue(getDonationAmount(cart) ?? '0.00');
      }
    }
  }, [cart, optionType, setPaymentProfile]);

  const [showDollar, setShowDollar] = useState<boolean>(true);

  const getTipValue = (): string => {
    return !tipValue || tipValue.tipType !== '$' ? '' : showDollar ? formatValue(tipValue?.tipAmount ?? '') : '' + tipValueStr ?? '';
  };

  const getDonationValue = (): string => {
    const val = showDollar ? formatValue(donationValue) : donationValue;

    return isNaN(+donationValue) ? '$' : val;
  };

  const bottomSheetInputRef = useRef<TextInput>(null);

  const getPromoOptions = () => (
    <View key={'tipSelectionKey1'} style={[utilityStyles.my3, styles.viewStyle]}>
      <View style={utilityStyles.my1}>
        <Text testID="promoCode" style={appStyles.formLabels}>
          Promo Code
        </Text>
      </View>
      <TextInput
        returnKeyType="done"
        onChange={(e) => setPromoCode(e.nativeEvent.text.replace(/[^a-z0-9A-Z]/g, '').toUpperCase())}
        value={promoCode}
        keyboardType="ascii-capable"
        onFocus={() => {
          setPromoCode('');
        }}
        style={appStyles.formField}
        selectTextOnFocus={true}
      />
    </View>
  );

  const getDonationOptions = () => (
    <View key={'tipSelectionKey1'} style={[utilityStyles.my3, styles.viewStyle]}>
      <View style={utilityStyles.my1}>
        <Text testID="donationAmount" style={appStyles.formLabels}>
          Donation Amount
        </Text>
      </View>
      <TextInput
        returnKeyType="done"
        onChange={(e) => setDonationValue(e.nativeEvent.text)}
        value={getDonationValue()}
        keyboardType="decimal-pad"
        onFocus={() => {
          setDonationValue('');
          setShowDollar(false);
        }}
        onBlur={() => setShowDollar(true)}
        style={appStyles.formField}
        selectTextOnFocus={true}
      />
    </View>
  );

  const getTipOptions = () => (
    <View key={'tipSelectionKey2'} style={[utilityStyles.my1, styles.viewStyle]}>
      <TouchableOpacity onPress={() => setTipValue(PERCENT2)} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioTip2'}
          value={tipValue && tipValue.tipType === '%' && tipValue.tipAmount === 2}
          toggle={() => {
            setTipValueStr('');
            setTipValue(PERCENT2);
          }}
          key={'radioTip2'}
        />
        <Text testID="twoPrecent" style={appStyles.bodySmallBold}>
          2%
        </Text>
        <Text testID="twoPrecentTip" style={{ marginLeft: 'auto' }}>
          {getCalculatedTip(cart, PERCENT2)}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setTipValue(PERCENT5)} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          buttonStyle={{}}
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioTip5'}
          value={tipValue && tipValue.tipType === '%' && tipValue.tipAmount === 5}
          toggle={() => {
            setTipValueStr('');
            setTipValue(PERCENT5);
          }}
          key={'radioTip5'}
        />
        <Text testID="fivePrecent" style={appStyles.bodySmallBold}>
          5%
        </Text>
        <Text testID="fivePrecentTip" style={{ marginLeft: 'auto' }}>
          {getCalculatedTip(cart, PERCENT5)}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setTipValue(PERCENT10)} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          textStyle={appStyles.bodySmallBold}
          id={'radioTip10'}
          value={tipValue && tipValue.tipType === '%' && tipValue.tipAmount === 10}
          toggle={() => {
            setTipValueStr('');
            setTipValue(PERCENT10);
          }}
          key={'radioTip10'}
        />
        <Text testID="tenPrecent" style={appStyles.bodySmallBold}>
          10%
        </Text>
        <Text testID="tenPrecentTip" style={{ marginLeft: 'auto' }}>
          {getCalculatedTip(cart, PERCENT10)}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setTipValue(PERCENT15)} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioTip15'}
          value={tipValue && tipValue.tipType === '%' && tipValue.tipAmount === 15}
          toggle={() => {
            setTipValueStr('');
            setTipValue(PERCENT15);
          }}
          key={'radioTip15'}
        />
        <Text testID="fifteenPrecent" style={appStyles.bodySmallBold}>
          15%
        </Text>
        <Text testID="fifteenPrecentTip" style={{ marginLeft: 'auto' }}>
          {getCalculatedTip(cart, PERCENT15)}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setTipValue({ tipAmount: 0, tipType: '%' })} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioNoTip'}
          value={tipValue && tipValue.tipType === '%' && tipValue.tipAmount === 0}
          toggle={() => {
            setTipValueStr('');
            setTipValue(PERCENT0);
          }}
          key={'radioNoTip'}
        />
        <Text testID="noTip" style={appStyles.bodySmallBold}>
          No Tip
        </Text>
        <Text testID="noCalculatedTip" style={{ marginLeft: 'auto' }}>
          {getCalculatedTip(cart, PERCENT0)}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setOtherTipValue(otherTip);
          bottomSheetInputRef.current && bottomSheetInputRef.current.focus();
        }}
        activeOpacity={1}
        style={[styles.radioRow, utilityStyles.my2, utilityStyles.mb1]}>
        <RadioButton
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioTipOther'}
          value={tipValue && tipValue.tipType === '$'}
          toggle={() => {
            setOtherTipValue(otherTip);
            bottomSheetInputRef.current && bottomSheetInputRef.current.focus();
          }}
          key={'radioTipCustom'}
        />
        <Text testID="otherAmount" style={appStyles.bodySmallBold}>
          Other Amount
        </Text>
        <Text style={{ marginLeft: 'auto' }}>{''}</Text>
      </TouchableOpacity>
      <TextInput
        testID="otherAmountInput"
        ref={bottomSheetInputRef}
        returnKeyType="done"
        value={getTipValue()}
        onChange={(e) => setOtherTipValue(e.nativeEvent.text)}
        onFocus={() => {
          setShowDollar(false);
        }}
        onBlur={() => setShowDollar(true)}
        keyboardType="decimal-pad"
        style={appStyles.formField}
        selectTextOnFocus={true}
        placeholder="$0.00"
      />
    </View>
  );

  const getPaymentType = () => (
    <View key={'tipSelectionKey1'} style={[utilityStyles.my2, styles.viewStyle]}>
      <TouchableOpacity onPress={() => setPaymentType('Credit-Card-On-Pickup')} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioTipPickupCredit'}
          value={!!(paymentType && paymentType === 'Credit-Card-On-Pickup')}
          toggle={() => {
            setPaymentType('Credit-Card-On-Pickup');
          }}
          key={'radioTipPickupCredit'}
        />
        <Text testID="creditDebit" style={appStyles.customizeLabelBold}>
          {'Credit/Debit'}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setPaymentType('Cash-On-Pickup')} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          buttonStyle={{}}
          textStyle={appStyles.bodySmallLeftRegular}
          id={'radioTipCashOnPickup'}
          value={!!(paymentType && paymentType === 'Cash-On-Pickup')}
          toggle={() => {
            setPaymentType('Cash-On-Pickup');
          }}
          key={'radioTipCashOnPickup'}
        />
        <Text testID="cash" style={appStyles.customizeLabelBold}>
          {'Cash'}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setPaymentType('EBT-SNAP-On-Pickup')} activeOpacity={1} style={[styles.radioRow, utilityStyles.my2]}>
        <RadioButton
          textStyle={[appStyles.bodySmallBold]}
          id={'radioTipsnap'}
          value={!!(paymentType && paymentType === 'EBT-SNAP-On-Pickup')}
          toggle={() => {
            setPaymentType('EBT-SNAP-On-Pickup');
          }}
          key={'radioTipsnap'}
        />
        <View>
          <Text testID="ebtSNAP" style={appStyles.customizeLabelBold}>
            {t('EBT/SNAP')}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );

  const doApply = async () => {
    if (optionType === 'ptype') {
      try {
        paymentType && (await setPaymentProfile(0, paymentType));
      } catch (e) {
        throw e;
      }
    } else if (optionType === 'fff') {
      try {
        if (donationValue || donationValue === '') {
          if (donationValue === '') {
            await setDonationAmount('0.00');
          } else {
            await setDonationAmount(donationValue.replace(/[^0-9\.]/g, ''));
          }
        }
      } catch (e) {
        throw e;
      }
    } else if (optionType === 'promo') {
      try {
        if (promoCode.length > 0) {
          const res = await offerService.acceptPromo(promoCode);
          if (cart) {
            showAlertPopup({
              message: res?.ReturnMessage ?? 'Processed',
              containerStyle: [styles.alertBox, infoBox],
              ellipsizeMode: 'head',
            });
          }
        }
      } catch (e) {
        throw e;
      }
    } else {
      try {
        tipValue && (await setDeliveryTip(`${tipValue.tipAmount}|${tipValue.tipType}`));
      } catch (e) {
        throw e;
      }
    }
  };

  const getOptions = () => {
    if (optionType === 'fff') {
      return getDonationOptions();
    } else if (optionType === 'ptype') {
      return getPaymentType();
    } else if (optionType === 'promo') {
      return getPromoOptions();
    } else {
      return getTipOptions();
    }
  };

  return <View style={[styles.viewStyle, containerStyle]}>{getOptions()}</View>;
});

const styles = StyleSheet.create({
  viewStyle: {
    flexDirection: 'column',
    width: screenWidth * 0.8,
    alignSelf: 'center',
    paddingBottom: 0,
  },
  scrollHintWrapper: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{ translateY: 10 }],
  },
  buttonContainer: {
    width: screenWidth,
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderTopColor: colors.black,
    borderTopWidth: 1 / 3,
    alignSelf: 'center',
    elevation: 8,
    paddingBottom: 0,
    height: '100%',
  },
  radioRow: {
    width: '100%',
    flexDirection: 'row',
    height: lineHeight(24),
    alignItems: 'center',
  },
  handle: {
    width: '100%',
    minHeight: 50,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertBox: {
    bottom:
      screenHeight < 736
        ? screenHeight * 0.225
        : screenHeight > 812
        ? screenHeight > 896
          ? screenHeight * 0.21
          : screenHeight * 0.215
        : screenHeight < 740
        ? screenHeight * 0.225
        : screenHeight * 0.225,
  },
});

export default CartViewOptions;

import colors from '@config/colors';
import { utilityStyles } from '@styles';
import { useField } from 'formik';
import React, { PropsWithChildren, forwardRef, useEffect } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
//@ts-ignore
import { CheckBox, CheckBoxProps } from 'react-native-web';
import ErrorMessage from './ErrorMessage';

interface FormCheckBoxProps extends Omit<CheckBoxProps, 'onValueChange'> {
  name: string;
  reserveSpace?: boolean;
  disable?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  checkboxWrapperStyle?: StyleProp<ViewStyle>;
  errorStyle?: StyleProp<TextStyle>;
}

const FormCheckBox = forwardRef<CheckBox, PropsWithChildren<FormCheckBoxProps>>(
  ({ name, disable, children, reserveSpace, containerStyle, checkboxWrapperStyle, errorStyle, ...otherProps }, ref): JSX.Element => {
    const [field, meta, helpers] = useField<boolean>(name);

    useEffect(() => {
      helpers.setValue(disable || field.value);
      helpers.setTouched(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disable]);

    return (
      <View style={[utilityStyles.mt3, containerStyle]}>
        <View style={[styles.checkBoxWrapper, checkboxWrapperStyle]}>
          <CheckBox
            ref={ref}
            value={field.value}
            onValueChange={helpers.setValue}
            disabled={false}
            style={styles.checkBoxView}
            boxType="square"
            onCheckColor={colors.white}
            onFillColor={colors.red}
            onTintColor={colors.red}
            tintColors={{ true: colors.red, false: colors.slateGray }}
            {...otherProps}
          />
          {children}
        </View>
        {meta.touched && !meta.value ? <ErrorMessage reserveSpace={reserveSpace} error={meta.error} visible={meta.touched} style={errorStyle} /> : <></>}
      </View>
    );
  },
);
const styles = StyleSheet.create({
  checkBoxWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkBoxView: {
    alignItems: 'center',
    transform: [{ scaleX: 0.75 }, { scaleY: 0.75 }],
  },
});

export default FormCheckBox;

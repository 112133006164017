import { Icon, LoadingScreen, Modal, NavigationHeader, Screen, Text } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import WalletItem from '@components/WalletItem';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useRefetchOnFocus } from '@hooks';
import { AccountStackRoutes, AccountStackScreenProps, RootTabRoutes } from '@navigation/routes';
import { userService } from '@services/brandywine';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import { queryClient } from '@utils/reactQuery';
import React, { useCallback, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';

type PaymentMethodsScreenProps = AccountStackScreenProps<AccountStackRoutes.PaymentMethods>;

const PaymentMethodsScreen = ({ navigation, route }: PaymentMethodsScreenProps) => {
  const { showBack } = route.params;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const paymentProfileRef = useRef<PaymentProfile | undefined>(undefined);
  const { isLoading, data, refetch, isRefetching, error } = useQuery<PaymentProfile[]>('payment-methods', async () => await userService.getPaymentProfiles());
  const handleError = useErrorHandler(error);

  useRefetchOnFocus(refetch);

  const handleEdit = (item: PaymentProfile) => {
    navigation.navigate(AccountStackRoutes.AddEditPaymentMethod, {
      paymentMethodId: item.CustomerPaymentMethodId,
      calledFrom: showBack ? 'PaymentMethods' : 'Wallet',
    });
  };

  const handleSetDefault = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowSetDefaultModal(true);
  };

  const handleSetDefaultPayment = () => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.updatePaymentProfile({ ...paymentProfileRef.current, IsDefault: true }),
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetch();
        },
        onError: (e) => {
          handleError(e);
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowSetDefaultModal(false);
  };

  const handleAddPaynmentMethod = useCallback(() => {
    navigation.navigate(RootTabRoutes.Account, {
      screen: AccountStackRoutes.AddEditPaymentMethod,
      params: { paymentMethodId: undefined, calledFrom: showBack ? 'PaymentMethods' : 'Wallet' },
    });
  }, [navigation, showBack]);

  const handleRemove = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowDeleteModal(true);
  };

  const handleRemovePaymentMethod = async (): Promise<void> => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.deletePaymentProfile({ ...paymentProfileRef.current }),
        onError: (e) => {
          handleError(e);
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetch();
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowDeleteModal(false);
  };

  const renderItem = ({ item, index }: ListRenderItemInfo<PaymentProfile>) => (
    <WalletItem onEdit={handleEdit} item={item} onRemove={handleRemove} onSetDefault={!item.IsDefault ? handleSetDefault : undefined} index={index + 1} />
  );

  const EmptyList = useCallback(
    () => (
      <View testID="noPaymentMethod" style={styles.emptyList}>
        <Text style={styles.description} testID="noPaymentMethodText" numberOfLines={2}>
          You have no saved payment methods on your account.
        </Text>
      </View>
    ),
    [],
  );

  const ListFooter = useCallback(() => {
    return (
      <TouchableOpacity activeOpacity={0.7} style={styles.footerContainer} onPress={handleAddPaynmentMethod} testID="addPaymentClick">
        <Icon
          name={'circle-plus'}
          testID="circleplusIcon"
          fill={colors.white}
          stroke={colors.red}
          strokeSecondary={colors.darkCream}
          size={35}
          style={{ width: 35, height: 35 }}
        />
        <Text style={styles.addText} testID="addPaymentText">
          Add Payment Method
        </Text>
      </TouchableOpacity>
    );
  }, [handleAddPaynmentMethod]);

  return (
    <Screen testID="PaymentMethodsScreen" style={utilityStyles.pb0}>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        {showBack && <HeaderTitle testID="PaymentMethods">Payment Methods</HeaderTitle>}
      </NavigationHeader>
      {isBusy || isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <FlatList
            contentContainerStyle={[styles.container, utilityStyles.pt3, utilityStyles.px2]}
            data={data}
            testID={'payment-method-list'}
            keyExtractor={(_: PaymentProfile, idx: number) => idx.toString()}
            renderItem={renderItem}
            onRefresh={refetch}
            refreshing={isRefetching}
            ListFooterComponent={<ListFooter />}
            ListEmptyComponent={<EmptyList />}
          />
        </>
      )}
      {showDeleteModal && (
        <Modal
          visible={showDeleteModal}
          title={'Remove Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowDeleteModal(false)}
          okButtonOnPress={handleRemovePaymentMethod}
          cancelButtonText="Go back"
          okButtonText="Remove">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="removeMsg">
            Please confirm you'd like to remove this Payment Method from your Wallet.
          </Text>
        </Modal>
      )}
      {showSetDefaultModal && (
        <Modal
          visible={showSetDefaultModal}
          title={'Set as Default Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowSetDefaultModal(false)}
          okButtonOnPress={handleSetDefaultPayment}
          cancelButtonText="Go back"
          okButtonText="Update">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="setDefaultMsg">
            Please confirm you'd like to set this Payment Method as default.
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    ...utilityStyles.px2,
  },
  title: {
    alignSelf: 'flex-start',
    marginLeft: 20,
    marginBottom: 15,
    marginTop: 25,
  },
  emptyList: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: scale(48),
  },
  description: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'center',
    width: '70%',
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  addText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    color: colors.darkText,
    marginLeft: 8,
  },
});

export default PaymentMethodsScreen;

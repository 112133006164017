import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { CommonActions, useNavigation } from '@react-navigation/native';
import useCustomizeStore from '@store/customizeStore';
import { lineHeight, scale } from '@styles/constants';
import React, { FC } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
const { width } = Dimensions.get('window');
const PROGRESS_HEIGHT = 6.5;

type step = {
  pageItem?: string;
  title?: string | number;
  selectionOption?: string;
  limit?: number;
};

interface IProgressNodesProps {
  steps: step[];
  currStep: number;
  progress: number | undefined;
}

const ProgressNodes: FC<IProgressNodesProps> = ({ steps, currStep, progress }): JSX.Element => {
  const navigation = useNavigation();
  const { setCurrStep } = useCustomizeStore();

  const checkNavigation = (index: number) => {
    if (index + 1 < currStep) {
      var navPage = steps[index];
      setCurrStep(index + 1);
      if (navPage.selectionOption) {
        // navigation.navigate('Selection', navPage);
        const navAction = CommonActions.navigate({
          name: 'Selection',
          params: navPage,
          key: navPage.pageItem,
        });
        navigation.dispatch(navAction);
        return;
      }
      navigation.navigate(navPage.pageItem, navPage);
    }
  };

  return (
    <View style={styles.progressOuter}>
      <View
        style={[
          styles.progressInner,
          // { width: currStep === steps.length ? `${(currStep / steps.length) * 100}%` : 0 },
          { width: `${(progress / steps.length) * 100 + 8}%` },
        ]}
      />
      <View style={styles.progressContainer}>
        <View style={styles.progressNodesWrapper}>
          <View
            style={[
              styles.currentProgress,
              {
                width:
                  currStep === 1 ? 0 : currStep === steps.length ? `${(currStep / steps.length) * 100}%` : `${((currStep - 1) / (steps.length - 1)) * 100}%`,
              },
            ]}
          />
          {steps.map((stepItem, index) => {
            return (
              <TouchableOpacity
                onPress={() => checkNavigation(index)}
                activeOpacity={1}
                key={index.toString()}
                style={[
                  styles.progressNode,
                  { backgroundColor: currStep >= index + 1 ? colors.red : colors.medium },
                  { width: currStep === index + 1 ? PROGRESS_HEIGHT * 2.2 : PROGRESS_HEIGHT * 2 },
                  { height: currStep === index + 1 ? PROGRESS_HEIGHT * 2.2 : PROGRESS_HEIGHT * 2 },
                  { borderRadius: currStep === index + 1 ? PROGRESS_HEIGHT * 2.2 : PROGRESS_HEIGHT * 2 },
                  {
                    transform: [
                      {
                        translateY: currStep === index + 1 ? -PROGRESS_HEIGHT / 1.8 : -PROGRESS_HEIGHT / 2,
                      },
                    ],
                  },
                ]}>
                {/* {currStep === index + 1 ? <Text style={styles.progressText}>{index + 1}</Text> : null} */}
                <Text
                  style={[
                    styles.stepTitle,
                    { color: currStep === index + 1 ? colors.darkText : colors.darkGray },
                    { fontWeight: currStep === index + 1 ? '700' : '500' },
                    { fontFamily: currStep === index + 1 ? FontFamily.LarsseitBold : FontFamily.LarsseitLight },
                    { bottom: currStep === index + 1 ? -PROGRESS_HEIGHT * 3.5 : -PROGRESS_HEIGHT * 3.5 },
                  ]}>
                  ${stepItem.title || stepItem}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  progressOuter: {
    width: '95%',
    height: PROGRESS_HEIGHT,
    borderRadius: PROGRESS_HEIGHT,
    backgroundColor: colors.medium,
    alignSelf: 'center',
  },
  progressInner: {
    flexDirection: 'row',
    height: PROGRESS_HEIGHT,
    borderRadius: PROGRESS_HEIGHT,
    backgroundColor: colors.red,
    paddingHorizontal: 15,
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  progressContainer: {
    width: '100%',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingHorizontal: 15,
    // backgroundColor: 'red',
  },
  progressNodesWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  currentProgress: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: PROGRESS_HEIGHT,
    borderRadius: PROGRESS_HEIGHT,
    backgroundColor: colors.red,
    position: 'absolute',
  },
  progressNode: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },
  progressText: {
    color: 'white',
    fontFamily: 'Aleo-Bold',
    fontSize: scale(14),
  },
  stepTitle: {
    position: 'absolute',
    width: width * 0.2,
    textAlign: 'center',
    fontSize: scale(14),
    lineHeight: lineHeight(14),
  },
});

export default ProgressNodes;

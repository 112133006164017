import appConstants from '@config/appConstants';
import React, { PropsWithChildren, useRef, useState } from 'react';
import { Animated, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { HandlerStateChangeEvent, PanGestureHandler, PinchGestureHandler, PinchGestureHandlerEventPayload, State } from 'react-native-gesture-handler';

type ZoomBoxProps = {
  style?: StyleProp<ViewStyle>;
};

const ZoomBox = ({ style, children }: PropsWithChildren<ZoomBoxProps>) => {
  const panRef = React.createRef();
  const pinchRef = React.createRef();

  const [panEnabled, setPanEnabled] = useState(false);

  const scale = useRef(new Animated.Value(1)).current;
  const translateX = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;

  const onPinchEvent = Animated.event(
    [
      {
        nativeEvent: { scale },
      },
    ],
    { useNativeDriver: appConstants.USE_NATIVE_DRIVER },
  );

  const onPanEvent = Animated.event(
    [
      {
        nativeEvent: {
          translationX: translateX,
          translationY: translateY,
        },
      },
    ],
    { useNativeDriver: appConstants.USE_NATIVE_DRIVER },
  );

  const handlePinchStateChange = ({ nativeEvent }: HandlerStateChangeEvent<PinchGestureHandlerEventPayload>) => {
    // enabled pan only after pinch-zoom
    if (nativeEvent.state === State.ACTIVE) {
      setPanEnabled(true);
    }

    // when scale < 1, reset scale back to original (1)
    const nScale = nativeEvent.scale;
    if (nativeEvent.state === State.END) {
      if (nScale < 1) {
        Animated.spring(scale, {
          toValue: 1,
          useNativeDriver: appConstants.USE_NATIVE_DRIVER,
        }).start();
        Animated.spring(translateX, {
          toValue: 0,
          useNativeDriver: appConstants.USE_NATIVE_DRIVER,
        }).start();
        Animated.spring(translateY, {
          toValue: 0,
          useNativeDriver: appConstants.USE_NATIVE_DRIVER,
        }).start();

        setPanEnabled(false);
      }
    }
  };

  return (
    <>
      <PanGestureHandler
        onGestureEvent={onPanEvent}
        ref={panRef}
        simultaneousHandlers={[pinchRef]}
        enabled={panEnabled}
        failOffsetX={[-1000, 1000]}
        shouldCancelWhenOutside>
        <Animated.View style={[styles.wrapper]}>
          <PinchGestureHandler ref={pinchRef} onGestureEvent={onPinchEvent} simultaneousHandlers={[panRef]} onHandlerStateChange={handlePinchStateChange}>
            <Animated.View
              style={[
                style,
                {
                  transform: [{ scale }, { translateX }, { translateY }],
                },
              ]}>
              {children}
            </Animated.View>
          </PinchGestureHandler>
        </Animated.View>
      </PanGestureHandler>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    overflow: 'hidden',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  wrapper: {
    flex: 1,
  },
});

export default ZoomBox;

import { AuthStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles } from '@styles';
import React from 'react';
import { GestureResponderEvent, TextProps } from 'react-native';
import Text from './Text';

interface WebLinkProps extends Omit<TextProps, 'onLongPress'> {
  href: string;
}

const WebBrowserLink = ({ href, onPress, children, style, ...rest }: WebLinkProps) => {
  const navigator = useNavigation();
  const handlePress = (link: string) => {
    // WebBrowser.openBrowserAsync(link);
    navigator.navigate(AuthStackRoutes.WebBrowser, { title: typeof children === 'string' ? children : '', url: link });
  };

  const onLinkPress = (event: GestureResponderEvent) => {
    handlePress(href);
    onPress && onPress(event);
  };

  return (
    <Text testID="WebBrowserLink" style={[appStyles.link, style]} {...rest} onPress={onLinkPress}>
      {children}
    </Text>
  );
};

export default WebBrowserLink;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { scale } from '@styles/constants';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
const PROGRESS_HEIGHT = 6.5;

type ProgressBarProps = {
  progress: number;
  progressText?: string | number;
  max: number;
  maxTitle?: string;
  showMax: boolean;
  showProgress?: boolean;
};

const ProgressBar: FC<ProgressBarProps> = ({ progress, max, maxTitle, showMax = false, showProgress = false, progressText }) => {
  return (
    <View style={[styles.outer, { paddingRight: showMax ? 8 : 0 }]}>
      <View style={[styles.inner, { width: `${(progress / max) * 100}%` }]}>
        {showProgress && (progress / max) * 100 < 100 ? <Text style={styles.progressText}>{progressText || progress}</Text> : null}
      </View>
      {showMax ? (
        <View style={styles.maxNode}>
          <Text style={styles.maxText}>{maxTitle}</Text>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  outer: {
    width: '95%',
    height: PROGRESS_HEIGHT,
    borderRadius: PROGRESS_HEIGHT,
    backgroundColor: colors.medium,
    alignSelf: 'center',
  },
  inner: {
    height: PROGRESS_HEIGHT,
    borderRadius: PROGRESS_HEIGHT,
    backgroundColor: colors.red,
  },
  progressText: {
    position: 'absolute',
    top: -24,
    right: -16,
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    fontSize: scale(17),
  },
  maxNode: {
    height: PROGRESS_HEIGHT * 4,
    width: PROGRESS_HEIGHT * 4,
    borderRadius: (PROGRESS_HEIGHT * 4) / 2,
    backgroundColor: colors.medium,
    position: 'absolute',
    right: 4,
    top: -PROGRESS_HEIGHT * 1.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  maxText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.text,
    fontSize: scale(12),
  },
});

export default ProgressBar;

import { CloseButton, DropShadow, Icon, NavigationHeader, Screen, Text } from '@components';
import { IconName } from '@components/Icon';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Schedule } from '@fieldera-raleys/client-common/services/brandywine/types';
import { AccountStackRoutes, AppStackRoutes, RewardsStackRoutes, RewardsStackScreenProps, RootTabRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useAppConfigStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { getPointsStartDates, parseSchedule } from '../../utils/helpers';

type DataObject = {
  icon?: IconName;
  title: string;
  text: string;
};

type EarningPeriodObject = {
  startDate: string;
  endDate: string;
  arrivalDate?: string;
};

type SEBenefitsScreenProps = RewardsStackScreenProps<RewardsStackRoutes.SEBenefits>;

const SEBenefitsScreen = ({}: SEBenefitsScreenProps) => {
  const { getConfigValue } = useAppConfigStore();
  const navigation = useNavigation();
  const endSchedule = getConfigValue<{ VoucherEarningEndSchedule: Schedule[] }>('VoucherEarningEndSchedule') ?? undefined;
  const endDates = parseSchedule(endSchedule?.VoucherEarningEndSchedule ?? []);

  const issueSchedule = getConfigValue<{ VoucherIssueSchedule: Schedule[] }>('VoucherIssueSchedule') ?? undefined;
  const arrivalDates = parseSchedule(issueSchedule?.VoucherIssueSchedule ?? [], endDates ?? []);

  const startDates = getPointsStartDates(endDates ?? []);
  // logger.log('TYPEOF:', typeof endDates[0]);
  // logger.log('ENDDATES:', JSON.stringify(endDates, null, 2));
  // logger.log('ARRIVES:', JSON.stringify(arrivalDates, null, 2));
  const tabs = ['Overview', 'Earn Rewards', 'Redeem'];
  const overviewDataObjects: DataObject[] = [
    {
      icon: 'birthday-hat',
      title: 'Birthday Club Offers',
      text: 'Birthday club members are eligible for one set of birthday offers per month.',
    },
    {
      icon: 'offers-icon',
      title: 'Personalized Offers',
      text: 'Hand-picked savings on the items you buy most.',
    },
    {
      icon: 'gather-rooms',
      title: 'Gather Rooms',
      text: 'A conference space where you can share ideas and collaborate.',
    },
    {
      icon: 'gas-pump',
      title: 'Pump Rewards',
      text: 'Save at an Aisle 1 gas pump whenever you shop for groceries.',
    },
    {
      icon: 'health-icon',
      title: 'Health',
      text: 'Get free 1-on-1 access to our virtual registered dietitian, nutrition coaching, plus cook-alongs and more.',
    },
  ];
  const rewardsDataObjects: DataObject[] = [
    {
      title: 'Earn as You Shop',
      text: 'Enter your Something Extra number at checkout to earn personalized savings on the items you buy most, along with points that add up to Something Extra Dollars.',
    },
    {
      title: 'Something Extra Dollars Schedule',
      text: 'At the end of each points earning period, your points will automatically be converted into Something Extra Dollars. Depending on the contract preferences you indicated on your account, you will receive your Something Extra Dollars via mail or email.',
    },
  ];
  const redeemDataObjects: DataObject[] = [
    {
      title: 'Points Add Up to Something Extra Dollars',
      text: 'At the end of each points earning period (3 months), your points will automatically be converted into Something Extra Dollars. Depending on the contract preferences you indicated on your account, you will receive your Something Extra Dollars via mail or email.',
    },
  ];
  const currEarningPeriods: EarningPeriodObject[] = [
    {
      startDate: dayjs(startDates?.length && startDates[0]).format('M/D/YY'),
      endDate: dayjs(endDates?.length && endDates[0]).format('M/D/YY'),
      //MMMM D, YYYY
      arrivalDate: dayjs(arrivalDates?.length && arrivalDates[0]).format('M/D/YY'),
    },
    {
      startDate: dayjs(startDates?.length && startDates[1]).format('M/D/YY'),
      endDate: dayjs(endDates?.length && endDates[1]).format('M/D/YY'),
      arrivalDate: dayjs(arrivalDates?.length && arrivalDates[1]).format('M/D/YY'),
    },
    {
      startDate: dayjs(startDates?.length && startDates[2]).format('M/D/YY'),
      endDate: dayjs(endDates?.length && endDates[2]).format('M/D/YY'),
      arrivalDate: dayjs(arrivalDates?.length && arrivalDates[2]).format('M/D/YY'),
    },
    {
      startDate: dayjs(startDates?.length && startDates[3]).format('M/D/YY'),
      endDate: dayjs(endDates?.length && endDates[3]).format('M/D/YY'),
      arrivalDate: dayjs(arrivalDates?.length && arrivalDates[3]).format('M/D/YY'),
    },
  ];
  const headerTitles: string[] = ['Earning Periods', 'End\nDate', 'SE Dollars Arrive'];
  const [currTab, setCurrTab] = useState('Overview');
  var currYear = dayjs().year();

  const handleNav = (page: string) => {
    if (!page) {
      return;
    }
    switch (page) {
      case 'wallet':
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: false } });
        break;
      case 'help':
        navigation.dispatch(
          StackActions.push(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Account,
            params: { screen: AccountStackRoutes.HelpCenter, params: {} },
          }),
        );
        break;
      default:
        return;
    }
  };

  return (
    <>
      <Screen style={[utilityStyles.pb0, { paddingTop: 8 }]}>
        <NavigationHeader
          style={[styles.chekoutHeaderStyle]}
          subStyle={{ backgroundColor: colors.cream, justifyContent: 'center', flexDirection: 'row' }}
          titleStyle={[appStyles.fontMobileH6, { alignSelf: 'center' }]}
          titleIcon={<Icon name="se-badge" stroke={'none'} size={36} style={styles.headerIcon} />}
          title={'Benefits'}
          icon={<CloseButton style={{ transform: [{ translateY: -3 }] }} />}
        />

        <View style={styles.container}>
          <DropShadow style={styles.tabShadow}>
            <View style={styles.tabWrapper}>
              {tabs.map((t) => {
                return (
                  <TouchableOpacity
                    onPress={() => setCurrTab(t)}
                    style={[styles.tab, { borderBottomWidth: currTab === t ? 1 : 0, borderBottomColor: colors.red }]}>
                    <Text style={[styles.tabTitle, currTab === t ? appStyles.bodySmallRed : appStyles['bodySmall-50gray'], { fontSize: scale(14) }]}>{t}</Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </DropShadow>

          {currTab === 'Overview' && (
            <ScrollView style={styles.page}>
              <Text style={[styles.pageTitle, appStyles.bodyLargeCenter]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                Something Extra: The Savings Program That Rewards You For However You Eat
              </Text>
              <View style={{ paddingBottom: 100 }}>
                {overviewDataObjects.map((obj) => {
                  return (
                    <View style={styles.opDataObject}>
                      <View style={styles.opIconWrapper}>{obj.icon && <Icon stroke={'none'} name={obj.icon} size={56} />}</View>
                      <View style={styles.opDataContent}>
                        <Text style={[styles.opDataTitle, appStyles.bodySmallLeft]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                          {obj.title}
                        </Text>
                        <Text style={[styles.opDataText, appStyles.bodySmallLight, styles.reduceLineHeight]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                          {obj.text}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </ScrollView>
          )}

          {currTab === 'Earn Rewards' && (
            <ScrollView style={styles.page}>
              <Text style={[styles.pageTitle, appStyles.bodyLargeCenter]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                Receive one point for every dollar that you spend on eligible purchases.
              </Text>
              <View style={{ paddingBottom: 100 }}>
                <View style={styles.erIcon}>
                  {/* <Image resizeMode={'contain'} source={require('../../assets/images/money-collage.png')} style={styles.moneyCollageImg} /> */}
                  <Icon name="earn" stroke={'none'} size={160} />
                  {/* <Icon name={'info-icon'} size={100} style={{ transform: [{ translateX: -35 }] }} /> */}
                </View>
                {rewardsDataObjects.map((obj) => {
                  return (
                    <View style={[styles.opDataObject, { marginBottom: 32 }]}>
                      {obj.icon ? (
                        <View style={styles.opIconWrapper}>
                          <Icon name={obj.icon} size={20} />
                        </View>
                      ) : null}
                      <View style={[styles.opDataContent, { width: obj.icon ? '80%' : '100%' }]}>
                        <Text style={[styles.opDataTitle, appStyles.bodySmallLeft]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                          {obj.title}
                        </Text>
                        <Text style={[styles.opDataText, appStyles.bodySmallLight, styles.reduceLineHeight]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                          {obj.text}
                        </Text>
                      </View>
                    </View>
                  );
                })}
                <View style={[styles.opDataObject]}>
                  <View style={[styles.opDataContent, { width: '100%' }]}>
                    <Text style={[styles.opDataTitle, appStyles.bodySmallLeft, { marginBottom: 4 }]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                      {currYear} - Points Earning Periods
                    </Text>
                    <View style={styles.pointsTable}>
                      <View style={styles.tableHeader}>
                        {headerTitles.map((title) => {
                          return (
                            <View style={styles.headerTitleContainer}>
                              <Text style={[appStyles.tableHeaderText, { fontSize: scale(15), textAlign: 'left' }]}>{title}</Text>
                            </View>
                          );
                        })}
                      </View>
                      {currEarningPeriods.map((row, i) => {
                        return (
                          <View style={[styles.tableRow, { backgroundColor: i % 2 !== 0 ? colors.darkCream : colors.tableLightRow, paddingHorizontal: 12 }]}>
                            <View style={styles.rowDataContainer}>
                              <Text style={[appStyles.bodySmallLight]}>{i + 1}</Text>
                            </View>
                            {/* <View style={styles.rowDataContainer}>
                              <Text style={[appStyles.bodySmallLight]}>{row.startDate}</Text>
                            </View> */}
                            <View style={styles.rowDataContainer}>
                              <Text style={[appStyles.bodySmallLight]}>{row.endDate}</Text>
                            </View>
                            <View style={styles.rowDataContainer}>
                              <Text style={[appStyles.bodySmallLight]}>{row.arrivalDate}</Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
                {/* <View style={[styles.opDataObject]}>
                  <View style={[styles.opDataContent, { width: '100%' }]}>
                    <Text style={[styles.opDataTitle, appStyles.bodySmallLeft, { marginBottom: 4 }]}>{currYear} - Points Earning Periods</Text>
                    <View style={styles.pointsTable}>
                      <View style={styles.tableHeader}>
                        {headerTitles.map((title) => {
                          return (
                            <View style={styles.headerTitleContainer}>
                              <Text style={[appStyles.tableHeaderText, { fontSize: scale(15) }]}>{title}</Text>
                            </View>
                          );
                        })}
                      </View>
                      {nextEarningPeriods.map((row, i) => {
                        return (
                          <View style={[styles.tableRow, { backgroundColor: i % 2 !== 0 ? colors.darkCream : colors.tableLightRow }]}>
                            <View style={styles.rowDataContainer}>
                              <Text style={appStyles.bodySmallLight}>{i + 1}</Text>
                            </View>
                            <View style={styles.rowDataContainer}>
                              <Text style={appStyles.bodySmallLight}>{row.startDate}</Text>
                            </View>
                            <View style={styles.rowDataContainer}>
                              <Text style={appStyles.bodySmallLight}>{row.endDate}</Text>
                            </View>
                            <View style={styles.rowDataContainer}>
                              <Text style={appStyles.bodySmallLight}>{row.arrivalDate}</Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View> */}
                <Text onPress={() => handleNav('help')} style={styles.erFAQLink} allowFontScaling={true} maxFontSizeMultiplier={4}>
                  Help Center
                </Text>
              </View>
            </ScrollView>
          )}

          {currTab === 'Redeem' && (
            <ScrollView style={styles.page}>
              <Text style={[styles.pageTitle, appStyles.bodyLargeCenter]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                You can spend your Something Extra Dollars in-store or online.
              </Text>
              <View style={{ paddingBottom: 150 }}>
                <View style={styles.erIcon}>
                  {/* <Image resizeMode={'contain'} source={require('../../assets/images/certificate1.png')} style={styles.redeemImg} /> */}
                  <Icon name="redeem" stroke={'none'} size={160} />
                  {/* <View style={styles.sampleBox}>
                    <Text style={styles.sampleText}>THIS IS A SAMPLE</Text>
                  </View> */}
                </View>
                {redeemDataObjects.map((obj) => {
                  return (
                    <View style={styles.opDataObject}>
                      {obj.icon ? (
                        <View style={styles.opIconWrapper}>
                          <Icon name={obj.icon} size={20} />
                        </View>
                      ) : null}
                      <View style={[styles.opDataContent, { width: obj.icon ? '80%' : '100%' }]}>
                        <Text style={[styles.opDataTitle, appStyles.bodySmallLeft]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                          {obj.title}
                        </Text>
                        <Text style={[styles.opDataText, appStyles.bodySmallLight, styles.reduceLineHeight]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                          {obj.text}
                        </Text>
                      </View>
                    </View>
                  );
                })}
                <Text style={[styles.opDataText, appStyles.bodySmallLight, styles.reduceLineHeight]} allowFontScaling={true} maxFontSizeMultiplier={4}>
                  {'\n'}Your Something Extra Dollars will be loaded onto your{' '}
                  <Text onPress={() => handleNav('wallet')} style={styles.erFAQLink} allowFontScaling={true} maxFontSizeMultiplier={4}>
                    Wallet
                  </Text>
                  . From there you can activate them to pay for your groceries.
                </Text>
                <Text onPress={() => handleNav('help')} style={styles.erFAQLink} allowFontScaling={true} maxFontSizeMultiplier={4}>
                  Help Center
                </Text>
              </View>
            </ScrollView>
          )}
        </View>
      </Screen>
    </>
  );
};

const styles = StyleSheet.create({
  pointsTable: {
    backgroundColor: colors.cream,
  },
  reduceLineHeight: {
    lineHeight: lineHeight(16),
  },
  tableHeader: {
    width: '100%',
    // height: 53,
    flexDirection: 'row',
    backgroundColor: colors.tableHeaderRed,
    padding: 12,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  headerTitleContainer: {
    // width: 60,
    flex: 1 / 3,
    height: 50,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  headerIcon: {
    height: 31,
    width: 36,
    marginRight: 13,
    transform: [{ translateX: Platform.OS === 'android' ? -13 : 0 }],
  },
  rowDataContainer: {
    flex: 1 / 3,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  tableRow: {
    // width: '100%',
    // flex: 0.25,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // height: 30,
    paddingVertical: 12,
  },
  container: {
    flex: 1,
    // justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
    shadowOpacity: 0,
  },
  tabShadow: {
    position: 'absolute',
    top: 0,
  },
  tabWrapper: {
    // flexDirection: 'row',
    // justifyContent: 'space-evenly',
    // alignItems: 'center',
    // width: screenWidth,
    // // position: 'absolute',
    // // top: 0,
    // height: 40,
    // backgroundColor: colors.white,
    flexDirection: 'row',
    minWidth: '100%',
    minHeight: 32,
    maxHeight: 50,
    justifyContent: 'space-evenly',
    backgroundColor: colors.white,
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    // backgroundColor: 'gold',
  },
  tabTitle: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(15),
  },
  page: {
    paddingVertical: 32,
    paddingHorizontal: 24,
    paddingTop: Platform.select({ ios: 50, android: 10 }),
  },
  pageTitle: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(18),
    lineHeight: lineHeight(22),
    textAlign: 'center',
    marginBottom: 16,
    marginTop: 16,
  },
  opDataObject: {
    // backgroundColor: 'red',
    width: '100%',
    minHeight: 100,
    marginBottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    // overflow: 'visible',
  },
  opIconWrapper: {
    minHeight: 100,
    width: '20%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 16,
    marginRight: 8,
  },
  opDataContent: {
    width: '79%',
  },
  opDataTitle: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    fontSize: scale(17),
    lineHeight: lineHeight(17),
  },
  opDataText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(15),
    lineHeight: lineHeight(17),
  },
  erIcon: {
    width: '100%',
    // justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    marginBottom: 24,
  },
  erFAQLink: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.red,
    fontSize: scale(15),
    alignSelf: 'center',
    marginTop: 32,
    textDecorationLine: 'underline',
  },
  moneyCollageImg: {
    width: 150,
    height: 150,
  },
  redeemImg: {
    width: 300,
    height: 120,
  },
  sampleBox: {
    backgroundColor: 'rgba(0,0,0, 0.6)',
    width: 125,
    paddingHorizontal: 8,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 50,
    right: 40,
  },
  sampleText: {
    color: colors.white,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(12),
  },
});

export default SEBenefitsScreen;

import { UserService } from '@fieldera-raleys/client-common';
import Cache from '@utils/cache';
import Config from 'react-native-config';
import { getJwtToken } from '../authHelper';

const userService = new UserService({
  apiUrl: Config.BW_API_URL,
  authKey: Config.BW_AUTH_KEY,
  authUrl: Config.BW_AUTH_URL,
  authToken: getJwtToken,
  cache: Cache,
});

export default userService;

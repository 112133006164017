import CustomizeHeader from '@components/CustomizeHeader';
import { createStackNavigator } from '@react-navigation/stack';
import { OrderSummaryScreen } from '@screens/cartAndCheckout';
import {
  BasicSelectionScreen,
  ContainerScreen,
  FillingScreen,
  InitialCustomizeScreen,
  MessageScreen,
  NotesScreen,
  ReviewScreen,
  SelectionScreen,
  SizeScreen,
  ThemeScreen,
} from '@screens/customizeProduct';
import React from 'react';
import { CheckoutStackParamList, CheckoutStackRoutes, CustomizeStackParamList, CustomizeStackRoutes } from './routes';

const CustomizeNavigator = () => {
  const CustomizeStack = createStackNavigator<CustomizeStackParamList & CheckoutStackParamList>();

  return (
    <CustomizeStack.Navigator
      screenOptions={{
        gestureEnabled: false,
        header: ({ route }) => <CustomizeHeader forwardNavEnabled={route?.params?.forwardNavEnabled} />,
        headerMode: 'float',
        animationEnabled: true,
      }}>
      <CustomizeStack.Screen options={{ headerShown: false }} name={CustomizeStackRoutes.Initial} component={InitialCustomizeScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Size} component={SizeScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Selection} component={SelectionScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Container} component={ContainerScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Notes} component={NotesScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Review} component={ReviewScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.BasicSelection} component={BasicSelectionScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Theme} component={ThemeScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Message} component={MessageScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Filling} component={FillingScreen} />
      <CustomizeStack.Screen name={CheckoutStackRoutes.OrderSummary} component={OrderSummaryScreen} options={{ headerShown: false }} />
    </CustomizeStack.Navigator>
  );
};

export default CustomizeNavigator;

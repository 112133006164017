import { NavigationHeader, Screen } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import Text from '@components/Text';
import Invoice from '@components/cart/Invoice';
import ItemizedCart from '@components/cart/ItemizedCart';
import colors from '@config/colors';
import { useCartStore } from '@store/cartStore';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { getOrderNote } from '../../utils/orderHelpers';
// type OrderSummaryScreenProps = CompositeScreenProps<
//   BottomTabScreenProps<BottomTabParamList>,
//   StackScreenProps<AppStackParamList & ShopStackParamList, AppStackRoutes.Cart>
// >;

const OrderSummaryScreen = () => {
  //const siluetteUrl = [Config.BRXM_IMAGE_URL, 'consumer-app/cart-screen/guaranteesecurity.png'].join('/');
  const { cart } = useCartStore();
  const { t } = useTranslation('cart');

  const OrderNote = useCallback((): JSX.Element | null => {
    const orderNote = getOrderNote(cart);
    return orderNote ? (
      <View style={styles.OrderNoteContainer}>
        <View style={{ flexDirection: 'column', width: containerWidth, alignSelf: 'center' }}>
          <View style={{ flexDirection: 'column', width: containerWidth }}>
            <Text testID="orderNode" style={[appStyles.body, appStyles.bodySmallRegular]}>
              {t('orderNote')}
            </Text>
            <View style={styles.notePad}>
              <Text testID="notePadOrderNote" style={appStyles.bodySmallRegular}>
                {orderNote}
              </Text>
            </View>
          </View>
        </View>
      </View>
    ) : null;
  }, [cart, t]);

  return (
    <Screen>
      <NavigationHeader titleStyle={{ top: 5 }} title={t('orderDetails')} />
      <View style={styles.mainContainer}>
        <View style={[styles.flex1, styles.flexrow]}>
          <ScrollView style={[styles.scrollView]}>
            <CartInfoBanner onTitlePress={() => null} />
            <View style={[utilityStyles.my0]}>
              <Invoice obj={cart} />
            </View>
            <View style={[utilityStyles.my4]}>
              <ItemizedCart />
            </View>
            <View style={[utilityStyles.my0]}>{OrderNote && OrderNote()}</View>
          </ScrollView>
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    flexDirection: 'column',
    width: screenWidth,
    height: screenHeight,
  },
  OrderNoteContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  marginTop20: {
    marginTop: 20,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
  },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flexrow: { flexDirection: 'row' },
  flexcolumn: { flexDirection: 'column' },
  scrollView: {},
  textAlignCenter: { textAlign: 'center' },
  productList: {
    margin: 18,
    paddingBottom: 60,
  },
  productFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    alignSelf: 'center',
  },
  infoPadLight: {
    backgroundColor: colors.cream,
    padding: 10,
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 0,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
  },
  infoPadSeparator: {
    height: 1,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    margin: 20,
  },
  headSeparator: {
    height: 1,
    backgroundColor: colors.dark,
    marginTop: 15,
    marginBottom: 2,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.medium,
    marginTop: 10,
    marginBottom: 10,
  },
  subSeparator: {
    flex: 1,
    height: 0.33,
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.medium,
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    marginRight: 20,
  },
  subtotal: {
    textAlign: 'right',
    borderRightWidth: 1,
    borderRightColor: colors.white,
    flex: 0.3,
  },
  unit: {
    flex: 0.65,
  },
  unitSeparator: {
    width: 0.33,
    marginRight: 5,
    backgroundColor: colors.medium,
  },
  subtotalsUnit: {
    flex: 0.75,
  },
  subtotalsPrice: { flex: 0.25, textAlign: 'right' },
  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
    borderRadius: 7,
  },
});

export default OrderSummaryScreen;

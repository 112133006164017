//https://github.com/abhiram2600/keyboard-aware-view
import { useEffectOnce } from '@hooks';
import React, { useRef, useState } from 'react';
import { Dimensions, Keyboard, KeyboardEvent, Platform, ScrollView, ScrollViewProps, TextInput } from 'react-native';

interface KeyboardAvoidingScrollViewProps extends ScrollViewProps {
  /**
   * Add extra keyboard offset to move the view up.
   * @type number
   * @default 16
   */
  extraKeyboardOffset?: number;
}
const { height: windowHeight } = Dimensions.get('screen');

const KeyboardAvoidingScrollView: React.FC<KeyboardAvoidingScrollViewProps> = ({ children, extraKeyboardOffset = 16, scrollEnabled = false, ...restProps }) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const viewHeight = useRef(0);
  const lastPosition = useRef(0);
  const currentTopPosition = useRef(0);
  const lastHeightPosition = useRef(Infinity);

  const computeKeyboardHeight = (e: KeyboardEvent) => {
    return e.endCoordinates.height;
  };

  const moveScrollView = (inputLocation: number, keyboardHeight: number, inputHeight: number) => {
    scrollViewRef.current?.scrollTo({
      y: -(inputLocation - (windowHeight - keyboardHeight) + inputHeight + extraKeyboardOffset),
      x: 0,
      animated: true,
    });
  };

  const onKeyboardDidShow = (e: KeyboardEvent) => {
    const keyboardHeight = computeKeyboardHeight(e);
    const focusedTextInputRef = TextInput.State.currentlyFocusedInput();
    if (focusedTextInputRef) {
      focusedTextInputRef.measure((x, y, w, height, px, py) => {
        const minimumPositionKeyboardAvoidView = windowHeight - keyboardHeight - height;
        lastPosition.current = y;
        if (py >= minimumPositionKeyboardAvoidView) {
          moveScrollView(y, keyboardHeight, height);
        }
      });
    }
  };

  const onKeyboardDidHide = () => {
    if (lastHeightPosition.current !== Infinity) {
      scrollViewRef.current?.scrollTo({
        y: lastHeightPosition.current,
        x: 0,
        animated: true,
      });
    }
    if (TextInput.State.currentlyFocusedInput() === null) {
      lastHeightPosition.current = Infinity;
    }
    setIsKeyboardOpen(false);
  };

  const keyboardWillShow = () => {
    setIsKeyboardOpen(true);
  };

  useEffectOnce(() => {
    const removeKeyboardWillShow = Keyboard.addListener('keyboardWillShow', keyboardWillShow);
    const removeKeyboardDidShow = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow);
    const removeKeyboardDidHide = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide);
    return () => {
      removeKeyboardDidShow.remove();
      removeKeyboardDidHide.remove();
      removeKeyboardWillShow.remove();
    };
  });

  if (Platform.OS === 'android') {
    return (
      <ScrollView scrollEnabled={scrollEnabled} {...restProps}>
        {children}
      </ScrollView>
    );
  }
  return (
    <ScrollView
      ref={scrollViewRef}
      scrollEnabled={scrollEnabled}
      {...restProps}
      scrollToOverflowEnabled={true}
      scrollEventThrottle={200}
      onMomentumScrollEnd={(e) => {
        currentTopPosition.current = e.nativeEvent.contentOffset.y;
      }}
      onLayout={(e) => {
        viewHeight.current = e.nativeEvent.layout.height;
      }}
      onScroll={(e) => {
        if (lastHeightPosition.current === Infinity && isKeyboardOpen) {
          lastHeightPosition.current = e.nativeEvent.contentOffset.y;
        }
      }}>
      {children}
    </ScrollView>
  );
};
export default KeyboardAvoidingScrollView;

import { AddLineItem, LineItemCustomFieldName, ShoppingList, ShoppingListLineItem, TextLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { ProductType } from '@fieldera-raleys/client-common';
import { shoppingListService } from '@services/commerceTools/me';
import logger from '@utils/logger';
import { getProductsfromCommerceTools } from '@utils/productHelper';
import { Mutex } from '@utils/semaphore';
import { create } from 'zustand';
import { ListsStore } from './storeTypes';

const FAVORITES = 'Favorites';
const MSL = 'My Shopping List';
const MFL = 'My Favorites';
const SFL = 'Saved For Later';
const useListsStore = create<ListsStore>()((set, get) => ({
  lists: undefined,
  listFilter: 'Category',
  listsLoading: true,
  initialize: async (priceChannelId?: string, availablityChannelIds?: string[], signal?: AbortSignal): Promise<void> => {
    //TODO:// add logic to verify the channel and only load if channel changes
    set((state) => ({ ...state, listsLoading: true }), true);
    let shoppingLists: ShoppingList[] = [];
    let newList: ShoppingList;
    let shoppingListArr = await shoppingListService.getShoppingLists({ priceChannelId, availablityChannelIds }, signal);
    //filter duplicates
    shoppingLists = shoppingListArr.filter((sl, i, arr) => {
      return arr.map((s) => s.name).indexOf(sl.name) === i;
    });
    shoppingLists = shoppingLists.filter((shl) => shl.name);
    if (shoppingLists.length) {
      if (shoppingLists.some((li) => li.name === FAVORITES)) {
        const fl = shoppingLists.find((li) => li.name === FAVORITES);
        newList = await shoppingListService.updateListName(fl?.id ?? '', fl?.version, MFL);

        let newLists = shoppingLists;
        newLists[newLists.findIndex((li: ShoppingList) => li.id === fl?.id)] = newList;
        // set((state) => ({ ...state, lists: newLists }), true);
        shoppingLists = newLists;
      }
      if (!shoppingLists.some((li) => li.name === MFL)) {
        newList = await shoppingListService.createShoppingList({ listName: MFL }, signal);
        shoppingLists.push(newList);
      }
      if (!shoppingLists.some((li) => li.name === MSL)) {
        newList = await shoppingListService.createShoppingList({ listName: MSL }, signal);
        shoppingLists.push(newList);
      }
      if (!shoppingLists.some((li) => li.name === SFL)) {
        newList = await shoppingListService.createShoppingList({ listName: SFL }, signal);
        shoppingLists.push(newList);
      }
      set((state) => ({ ...state, lists: shoppingLists, listsLoading: false }), true);
      return;
    }
    newList = await shoppingListService.createShoppingList({ listName: MFL }, signal);
    shoppingLists.push(newList);
    newList = await shoppingListService.createShoppingList({ listName: MSL }, signal);
    shoppingLists.push(newList);
    newList = await shoppingListService.createShoppingList({ listName: SFL }, signal);
    shoppingLists.push(newList);

    if (shoppingLists.length) {
      set((state) => ({ ...state, lists: shoppingLists, listsLoading: false }), true);
      return;
    }
    if (!signal?.aborted) {
      throw new Error('useListsStore: failed to initialize');
    }
  },
  setListsLoading: (loading: boolean) => {
    set((state) => ({ ...state, listsLoading: loading }), true);
  },
  isInList: (productSku: string) => {
    const lists = get().lists;
    if (lists) {
      return lists.some((list: ShoppingList) => list.lineItems.some((li) => li.variant?.sku === productSku));
    } else {
      return false;
    }
  },
  setListFilter: (filter: string) => {
    set((state) => ({ ...state, listFilter: filter }), true);
  },
  getListSelectedFilter: () => {
    const listFilters = get().listFilter;
    return listFilters;
  },
  deleteList: async (id: string, storeKey?: string, key?: string) => {
    return await Mutex.withMutex(async () => {
      if (!id) {
        return;
      }
      const current = get().lists;
      if (!current) {
        return;
      }
      let updated = current?.find((l) => l?.id === id);
      if (updated) {
        let newLists = current;
        await shoppingListService.deleteShoppingList(updated.version, id, storeKey, key);
        let indx = newLists.findIndex((li: ShoppingList) => li.id === id);
        newLists.splice(indx, 1);
        set((state) => ({ ...state, lists: newLists }), true);
      } else {
        logger.warn('Lists not initialized');
      }
    }, 'deleteList');
  },
  updateListName: async (listId: string, name: string) => {
    return await Mutex.withMutex(async () => {
      if (!listId || !name) {
        return;
      }
      const current = get().lists;
      if (!current) {
        return;
      }
      let updated = current?.find((l) => l?.id === listId);
      if (updated) {
        updated = await shoppingListService.updateListName(updated?.id ?? '', updated?.version, MFL);
        if (updated) {
          let newLists = current;
          newLists[newLists.findIndex((li: ShoppingList) => li.id === listId)] = updated;
          set((state) => ({ ...state, lists: newLists }), true);
        } else {
          logger.warn('Lists undefined');
        }
      } else {
        logger.warn('Lists not initialized');
      }
    }, 'addToList');
  },
  addTextLineItemToList: async (title: string, description: string = '', listName: string) => {
    return await Mutex.withMutex(async () => {
      if (!title || !listName) {
        return;
      }
      const current = get().lists;
      if (!current) {
        return;
      }
      let updated = current?.find((l) => l?.name === listName);
      if (updated) {
        const lineitem = updated?.textLineItems?.find((x: TextLineItem) => (x?.name ?? '') === title);
        if (!lineitem) {
          updated = await shoppingListService.addTextLineItemToShoppingList(updated.id, updated.version, title, description);
        } else {
          return;
        }
        if (updated) {
          let newLists = current;
          newLists[newLists.findIndex((li: ShoppingList) => li.name === listName)] = updated;
          set((state) => ({ ...state, lists: newLists }), true);
        } else {
          logger.warn('Lists undefined');
        }
      } else {
        logger.warn('Lists not initialized');
      }
    }, 'addToList');
  },
  addItemToList: async (productKey: string, listName: string) => {
    return await Mutex.withMutex(async () => {
      if (!productKey || !listName) {
        return;
      }
      const current = get().lists;
      if (!current) {
        return;
      }
      let updated = current?.find((l) => l?.name === listName);
      if (updated) {
        const lineitem = updated.lineItems.find((x: ShoppingListLineItem) => x.variant?.sku === productKey);
        if (!lineitem) {
          updated = await shoppingListService.addToShoppingList(updated.id, updated.version, productKey);
        } else {
          return;
        }
        if (updated) {
          let newLists = current;
          newLists[newLists.findIndex((li: ShoppingList) => li.name === listName)] = updated;
          set((state) => ({ ...state, lists: newLists }), true);
        } else {
          logger.warn('Lists undefined');
        }
      } else {
        logger.warn('Lists not initialized');
      }
    }, 'addToList');
  },
  addCustomizableListLineItem: async (lineItems: AddLineItem[], listName: string): Promise<string> => {
    let lists = get().lists;
    if (!lists || !listName) {
      throw new Error('lists not found');
    }

    return await Mutex.withMutex(async () => {
      try {
        const list = lists?.find((li) => li.name === listName);
        if (!list) {
          throw new Error('no list found');
        }
        //some crazy logic need to re-think
        // 1. add the parent to the cart
        // 2. get id of the added parent
        // 3. set parent-id on children
        // 4. save children
        // 5. update parent custom attrubute with pipe seperated child-ids -- forn now saving the child product keys to the parent on step 1
        // hope everything saved as its not wrapped in a transaction
        const parent = lineItems.find((x) => !x.parentLineItemId);
        if (parent) {
          let updatedList: ShoppingList | undefined;
          updatedList = await shoppingListService.addToShoppingList(list.id, list.version, parent.sku);

          // find the new item, operation is versioned so new items should be guaranteed
          const parentLineItem = (updatedList?.lineItems ?? []).find((x) => (list?.lineItems ?? []).findIndex((cc) => cc.id === x.id) === -1);
          if (!parentLineItem) {
            // this should never be the case on success, but checking to make sure
            throw new Error('addCustomizableListLineItem: Cannot idenitfy newly added item');
          }

          // NOTE: after this, need to come up with how to handle erros as it will break the custom product
          if ((parentLineItem?.productType?.name ?? ProductType.UNCKNOWN) === ProductType.CONFIGURABLE) {
            const children = lineItems.filter((x) => x.parentLineItemId && x.parentLineItemId === parent?.sku);
            children.forEach((x) => {
              x.parentLineItemId = parentLineItem!.id;
            });
            updatedList = await shoppingListService.addItemsToList(list.id, list.version, children, []);
            // find all children for given parent id
            const childLineItems = updatedList?.lineItems.filter((x) => {
              const customField = x.custom?.customFieldsRaw?.find((a) => a.name === 'parentLineItemId');
              return customField?.value === parentLineItem!.id;
            });
            if (updatedList) {
              updatedList = await shoppingListService.setListLineItemCustomField(updatedList.id, updatedList.version, parentLineItem!.id, [
                { name: 'childLineItems', value: childLineItems?.map((ct) => ct.id) },
              ]);
            }
            if (updatedList) {
              let newLists = lists ?? [];
              newLists[newLists.findIndex((li: ShoppingList) => li.name === listName)] = updatedList;
              set((state) => ({ ...state, lists: newLists }), true);
            } else {
              logger.warn('Lists undefined');
            }
          }

          return parentLineItem.id;
        } else {
          throw new Error('cannot idenitfy primary sku');
        }
      } catch (e: any) {
        //TODO: rollback logic
        logger.error(e);
        throw e;
      }
    }, 'addListLineItem');
  },
  removeItemFromList: async (lineItemId: string, listName: string) => {
    if (!lineItemId || !listName) {
      return;
    }
    const current = get().lists;
    if (!current) {
      return;
    }
    let updated = current?.find((l) => l?.name === listName);
    if (updated) {
      const lineitem = updated.lineItems.find((x: ShoppingListLineItem) => x.id === lineItemId);
      if (lineitem) {
        updated = await shoppingListService.removeFromShoppingList(updated.id, updated.version, lineitem.id);
      } else {
        return;
      }
      if (updated) {
        let newLists = current;
        newLists[newLists.findIndex((li: ShoppingList) => li.name === listName)] = updated;
        set((state) => ({ ...state, lists: newLists }), true);
      } else {
        logger.warn('Lists undefined');
      }
    } else {
      logger.warn('Lists not initialized');
    }
  },
  removeTextItemFromList: async (name: string, listName: string) => {
    if (!name || !listName) {
      return;
    }
    const current = get().lists;
    if (!current) {
      return;
    }
    let updated = current?.find((l) => l?.name === listName);
    if (updated) {
      const lineitem = updated.textLineItems.find((x: TextLineItem) => x.name === name);
      if (lineitem) {
        updated = await shoppingListService.removeTextItemFromShoppingList(updated.id, updated.version, lineitem.id);
      } else {
        return;
      }
      if (updated) {
        let newLists = current;
        newLists[newLists.findIndex((li: ShoppingList) => li.name === listName)] = updated;
        set((state) => ({ ...state, lists: newLists }), true);
      } else {
        logger.warn('Lists undefined');
      }
    } else {
      logger.warn('Lists not initialized');
    }
  },
  setLineItemQuantity: async (listId: string, lineItemId: string, quantity: number): Promise<boolean> => {
    return await Mutex.withMutex(async () => {
      const listArr = get().lists;
      if (!listArr) {
        return false;
      }
      const list = listArr?.find((li) => li.id === listId);
      if (!list) {
        throw new Error('setShoppingListLineItemQuantity: List does not exist');
      }

      if (!lineItemId) {
        throw new Error('invalid lineItemId');
      }

      if (!quantity) {
        return true;
        // const lineItem: ShoppingListLineItem | undefined = productKey.lineItemId
        //   ? list.lineItems.find((x) => x.id === productKey.lineItemId)
        //   : list.lineItems.find((x) => x.variant?.sku === productKey.sku);

        // if (lineItem) {
        //   get().removeLineItem(lineItem.id);
        // }

        // return true;
      }

      const lineItem: ShoppingListLineItem | undefined = list.lineItems.find((x) => x.id === lineItemId);
      if (!lineItem) {
        throw new Error('no lineItem found');
      }
      const productData = await getProductsfromCommerceTools([lineItem?.variant?.sku ?? '']);
      if (!productData || productData.length !== 1) {
        throw new Error('setLineItemQuantity: unable to get product information');
      }
      // const priceFields = productData[0]?.masterData?.current?.masterVariant.price?.custom?.customFieldsRaw;
      // let sellType = getProductAttributeValue('unitSellType', productData[0]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
      // if (!sellType) {
      //   sellType = { key: 'byEach', label: 'By Each' };
      // }
      // let fields: { name: LineItemCustomFieldName; value: string | number | string[] | Money | undefined }[] = [{ name: 'unitSellType', value: sellType.key }];
      // const qty = sellType.key === 'byWeight' ? 1 : quantity;
      // if (priceFields) {
      //   let val = priceFields.find((pcv) => pcv.name === 'regularPrice')?.value;
      //   if (val) {
      //     fields.push({ name: 'regularPrice', value: val });
      //   }
      // }
      // const lineItemWeight = estimateProductWeight(productData[0], quantity);
      let updatedList: ShoppingList | undefined;
      try {
        if (lineItem) {
          // if (!lineItemWeight) {
          //   if (getProductAttributeValue('estimatedTotalWeight', productData[0]?.masterData?.current?.masterVariant?.attributesRaw ?? [])) {
          //     fields.push({ name: 'estimatedTotalWeight', value: undefined });
          //   }
          // } else {
          //   fields.push({ name: 'estimatedTotalWeight', value: lineItemWeight });
          // }
          updatedList = await shoppingListService.setListLineItemQuantity(list.id, list.version, lineItem.id, quantity, []); //fields
        }
      } catch (e) {
        logger.error(e);
      }
      if (!updatedList) {
        // throw should have taken care  of it but to close eslint logic
        return false;
      }

      let newLists = listArr;
      newLists[newLists.findIndex((li: ShoppingList) => li.id === listId)] = updatedList;
      set((state) => ({ ...state, lists: newLists }), true);
      return true;
    }, 'setShoppingListLineItemQuantity');
  },
  setTextLineItemQuantity: async (listId: string, productKey: { id: string; name: string }, quantity: number): Promise<boolean> => {
    return await Mutex.withMutex(async () => {
      const listArr = get().lists;
      if (!listArr) {
        return false;
      }
      const list = listArr?.find((li) => li.id === listId);
      if (!list) {
        throw new Error('setShoppingListLineItemQuantity: List does not exist');
      }

      if (!productKey.id && !productKey.name) {
        throw new Error('invalid productKey');
      }

      if (!quantity) {
        return true;
      }

      const lineItem: TextLineItem | undefined = productKey.name
        ? list.textLineItems.find((x) => x.id === productKey.id)
        : list.textLineItems.find((x) => x.name === productKey.name);

      if (!lineItem && !productKey.name) {
        throw new Error('invalid productKey, either name or id is required');
      }
      let updatedList: ShoppingList | undefined;
      try {
        if (lineItem) {
          updatedList = await shoppingListService.setListTextLineItemQuantity(list.id, list.version, lineItem.id, quantity);
        } else if (productKey.name) {
          updatedList = await shoppingListService.addTextLineItemToShoppingList(list.id, list.version, productKey.name);
        }
      } catch (e) {
        logger.error(e);
      }
      if (!updatedList) {
        // throw should have taken care of it but to close eslint logic
        return false;
      }

      let newLists = listArr;
      newLists[newLists.findIndex((li: ShoppingList) => li.id === listId)] = updatedList;
      set((state) => ({ ...state, lists: newLists }), true);
      return true;
    }, 'setShoppingListLineItemQuantity');
  },
  setLineItemCustomField: async (listId: string, lineItemId: string, field: { name: LineItemCustomFieldName; value: string | boolean }): Promise<boolean> => {
    return await Mutex.withMutex(async () => {
      const listArr = get().lists;
      if (!field.name || !listArr) {
        return false;
      }
      const list = listArr?.find((li) => li.id === listId);
      if (!list) {
        throw new Error('setShoppingListLineItemChecked: List does not exist');
      }

      if (!lineItemId) {
        throw new Error('invalid lineItemId');
      }

      const lineItem: ShoppingListLineItem | undefined = list.lineItems.find((x) => x.id === lineItemId);
      const setField = lineItem?.custom ? shoppingListService.setListLineItemCustomField : shoppingListService.setListLineItemCustomType;
      //setListLineItemCustomType

      if (!lineItemId) {
        throw new Error('invalid lineItemId');
      }
      let updatedList: ShoppingList | undefined;
      try {
        if (lineItem) {
          updatedList = await setField(list.id, list.version, lineItem.id, [field]);
        } else if (!lineItem) {
          updatedList = list;
        }
      } catch (e) {
        logger.error(e);
      }
      if (!updatedList) {
        // throw should have taken care of it but to close eslint logic
        return false;
      }

      let newLists = listArr;
      newLists[newLists.findIndex((li: ShoppingList) => li.id === listId)] = updatedList;
      set((state) => ({ ...state, lists: newLists }), true);
      return true;
    }, 'setShoppingListLineItemChecked');
  },
  setTextLineItemCustomField: async (
    listId: string,
    productKey: { id: string; name: string },
    field: { name: LineItemCustomFieldName; value: string | boolean },
  ): Promise<boolean> => {
    return await Mutex.withMutex(async () => {
      const listArr = get().lists;
      if (!field.name || !listArr) {
        return false;
      }
      const list = listArr?.find((li) => li.id === listId);
      if (!list) {
        throw new Error('setShoppingListTextLineItemChecked: List does not exist');
      }

      if (!productKey.name && !productKey.id) {
        throw new Error('invalid productKey');
      }

      const lineItem: TextLineItem | undefined = productKey.id
        ? list.textLineItems.find((x) => x.id === productKey.id)
        : list.textLineItems.find((x) => x.name === productKey.name);
      const setField = lineItem?.custom ? shoppingListService.setListTextLineItemCustomField : shoppingListService.setListTextLineItemCustomType;
      //setListLineItemCustomType

      if (!lineItem && !productKey.name) {
        throw new Error('invalid productKey, either lineItemId or sku is required');
      }
      let updatedList: ShoppingList | undefined;
      try {
        if (lineItem) {
          updatedList = await setField(list.id, list.version, lineItem.id, [field]);
        } else if (productKey.name) {
          updatedList = list;
        }
      } catch (e) {
        logger.error(e);
      }
      if (!updatedList) {
        // throw should have taken care of it but to close eslint logic
        return false;
      }

      let newLists = listArr;
      newLists[newLists.findIndex((li: ShoppingList) => li.id === listId)] = updatedList;
      set((state) => ({ ...state, lists: newLists }), true);
      return true;
    }, 'setShoppingListLineItemChecked');
  },
  getLineItemCustomField: (listId: string, lineItemId: string, fieldName: string): string | undefined => {
    const lists = get().lists;
    const list = lists?.find((li) => li.id === listId);
    if (!lists || !list) {
      throw new Error('getLineItemCustomField: List not initialized');
    }
    const lineItem = list.lineItems.find((i) => i.id === lineItemId);
    if (lineItem) {
      return lineItem.custom?.customFieldsRaw?.find((x) => x.name === fieldName)?.value;
    }
  },
  getTextLineItemCustomField: (listId: string, lineItemId: string, fieldName: string): string | undefined => {
    const lists = get().lists;
    const list = lists?.find((li) => li.id === listId);
    if (!lists || !list) {
      throw new Error('getTextLineItemCustomField: List not initialized');
    }
    const lineItem = list.textLineItems.find((i) => i.id === lineItemId);
    if (lineItem) {
      return lineItem.custom?.customFieldsRaw?.find((x) => x.name === fieldName)?.value;
    }
  },
  getLineItemQuantity: (listId: string, lineItemId: string) => {
    const lists = get().lists;
    if (!lists) {
      return 0;
    }
    const list = lists.find((li) => li.id === listId);
    if (!list) {
      return 0;
    }
    if (lineItemId) {
      if (lineItemId) {
        const lineItem = list.lineItems.find((x) => x.id === lineItemId);
        return lineItem?.quantity;
      } else {
        return 0;
      }
    } else {
      throw new Error('productKey undefined');
    }
  },
  unCheckAll: async (id: string, version: number, lineItemIds: string[], textLineItemIds: string[]): Promise<void> => {
    return await Mutex.withMutex(async () => {
      try {
        const listArr = get().lists;
        if (!listArr) {
          return;
        }
        const list = listArr?.find((li) => li.id === id);
        if (!list) {
          throw new Error('setShoppingListLineItemChecked: List does not exist');
        }
        let updatedList: ShoppingList;
        try {
          updatedList = await shoppingListService.unCheckAllItems(id, version, lineItemIds, textLineItemIds);
          let newLists = listArr;
          newLists[newLists.findIndex((li: ShoppingList) => li.id === id)] = updatedList;
          set((state) => ({ ...state, lists: newLists }), true);
        } catch (e) {
          logger.error(e);
        }
      } catch (err) {
        logger.error(err);
      }
    }, 'uncheckItems');
  },
  addItemsToShoppingList: async (id: string, version: number, lineItems: AddLineItem[], textLineItemTitles: string[]): Promise<void> => {
    return await Mutex.withMutex(async () => {
      try {
        const listArr = get().lists;
        if (!listArr) {
          return;
        }
        const list = listArr?.find((li) => li.id === id);
        if (!list) {
          throw new Error('addItemsToShoppingList: List does not exist');
        }
        let updatedList: ShoppingList;
        try {
          if (lineItems.some((li) => li?.parentLineItemId)) {
            const parent = lineItems.find((x) => !x.parentLineItemId);
            if (parent) {
              // let updatedList: Cart | undefined;
              updatedList = await shoppingListService.addItemsToList(list.id, list.version, [parent], []);

              // find the new item, operation is versioned so new items should be guaranteed
              const parentLineItem = (updatedList?.lineItems ?? []).find((x) => (list?.lineItems ?? []).findIndex((cc) => cc.id === x.id) === -1);
              if (!parentLineItem) {
                // this should never be the case on success, but checking to make sure
                throw new Error('addLineItem: Cannot idenitfy newly added item');
              }
              // NOTE: after this, need to come up with how to handle erros as it will break the custom product
              if ((parentLineItem?.productType?.name ?? ProductType.UNCKNOWN) === ProductType.CONFIGURABLE) {
                const children = lineItems.filter((x) => x.parentLineItemId && x.parentLineItemId === parent?.sku);
                children.forEach((x) => {
                  x.parentLineItemId = parentLineItem!.id;
                });
                updatedList = await shoppingListService.addItemsToList(updatedList?.id, updatedList?.version, children, []);
                // find all children for given parent id
                const childLineItems = updatedList.lineItems.filter((x) => {
                  const customField = x.custom?.customFieldsRaw?.find((a) => a.name === 'parentLineItemId');
                  return customField?.value === parentLineItem!.id;
                });

                updatedList = await shoppingListService.setListLineItemCustomField(updatedList.id, updatedList.version, parentLineItem!.id, [
                  { name: 'childLineItems', value: childLineItems.map((ct) => ct.id) },
                ]);
              }
              const standardItems = lineItems.filter((li) => !li.parentLineItemId && li.sku !== parentLineItem.variant?.sku);
              if (standardItems) {
                updatedList = await shoppingListService.addItemsToList(updatedList?.id, updatedList?.version, standardItems, []);
              }
              // return parentLineItem.id;
            } else {
              throw new Error('cannot idenitfy primary sku');
            }
          } else {
            updatedList = await shoppingListService.addItemsToList(
              id,
              version,
              lineItems.map((lis: AddLineItem) => {
                return {
                  sku: lis.sku ?? '',
                  quantity: lis.quantity ?? 1,
                  parentLineItemId: lis.parentLineItemId,
                  childLineItems: lis.childLineItems,
                  customStepSort: lis.customStepSort,
                  itemNote: lis.itemNote,
                };
              }),
              textLineItemTitles,
            );
          }
          let newLists = listArr;
          newLists[newLists.findIndex((li: ShoppingList) => li.id === id)] = updatedList;
          set((state) => ({ ...state, lists: newLists }), true);
        } catch (e) {
          logger.error(e);
        }
      } catch (err) {
        logger.error(err);
      }
    }, 'addItemsToShoppingList');
  },
  removeItemsFromShoppingList: async (id: string, version: number, lineItemIds: string[], textLineItemIds: string[]): Promise<void> => {
    return await Mutex.withMutex(async () => {
      try {
        const listArr = get().lists;
        if (!listArr) {
          return;
        }
        const list = listArr?.find((li) => li.id === id);
        if (!list) {
          throw new Error('addItemsToShoppingList: List does not exist');
        }
        let updatedList: ShoppingList;
        try {
          if (list.lineItems?.some((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId'))) {
            const selections = list.lineItems?.filter((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId'));
            selections.forEach((sel) => {
              if (lineItemIds.includes(sel.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId')?.value)) {
                lineItemIds.push(sel.id);
              }
            });
          }
          updatedList = await shoppingListService.removeItemsFromList(id, version, lineItemIds, textLineItemIds);
          let newLists = listArr;
          newLists[newLists.findIndex((li: ShoppingList) => li.id === id)] = updatedList;
          set((state) => ({ ...state, lists: newLists }), true);
        } catch (e) {
          logger.log(e);
        }
      } catch (err) {
        logger.log(err);
      }
    }, 'removeItemsFromShoppingList');
  },
  clearList: async (listId: string): Promise<void> => {
    return await Mutex.withMutex(async () => {
      try {
        const listArr = get().lists;
        if (!listArr) {
          return;
        }
        const list = listArr?.find((li) => li.id === listId);
        if (!list) {
          throw new Error('setShoppingListLineItemChecked: List does not exist');
        }
        let updatedList: ShoppingList;
        try {
          updatedList = await shoppingListService.clearShoppingList(
            list.id,
            list.version,
            list.lineItems.map((li) => li.id),
            list.textLineItems.map((ti) => ti.id),
          );
          let newLists = listArr;
          newLists[newLists.findIndex((li: ShoppingList) => li.id === listId)] = updatedList;
          set((state) => ({ ...state, lists: newLists }), true);
        } catch (e) {
          logger.log(e);
        }
      } catch (err) {
        logger.log(err);
      }
    }, 'uncheckItems');
  },
}));

export default useListsStore;

import { containerWidth } from '@styles/constants';
import React from 'react';
import { Button, InputAccessoryView, Keyboard, Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface KeyboardCloseButtonProps {
  title?: string;
  buttonStyles?: StyleProp<ViewStyle>;
}

const KeyboardCloseButton = ({ title = 'Close', buttonStyles }: KeyboardCloseButtonProps) => {
  return (
    <View>
      {Platform.select({
        ios: (
          <InputAccessoryView nativeID={title}>
            <View style={[styles.accessory, buttonStyles]}>
              <Button title={title} onPress={() => Keyboard.dismiss()} />
            </View>
          </InputAccessoryView>
        ),
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  accessory: {
    width: containerWidth,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    paddingHorizontal: 8,
  },
});

export default KeyboardCloseButton;

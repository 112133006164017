import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { HtmlView, Icon, LinkButton, LoadingScreen, ProductCard, SearchHeader, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import SponsoredIndicator from '@components/SponsoredIndicator';
import { mapping } from '@components/bloomreach';
import { CategoryHeader, CategoryNavigation } from '@components/category';
import withTombstone from '@components/hoc/withTombstone';
import colors from '@config/colors';
import { CampaignResult, PagedArray } from '@fieldera-raleys/client-common';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { useAnalytics, useBrxmPage, useEffectOnce } from '@hooks';
import { ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { StackActions } from '@react-navigation/native';
import { brsmService } from '@services';
import { utilityStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import { findTag, getHtmlAttribute, slug } from '@utils/helpers';
import { ProductList, getProductPromotions } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, ListRenderItemInfo, Platform, RefreshControl, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
// import { Icon, Text } from '../../components';
import { OpenTombstoneRequest } from '@components/Tombstone';
import appConstants from '../../config/appConstants';
import { AppStackRoutes } from '../../navigation/routes';
import { useAnalyticsStore, useSearchStore, useShopStore } from '../../store';
import { appStyles } from '../../styles';
import { lineHeight } from '../../styles/constants';
import { searchCategoryTree } from '../../utils/categoryHelper';

interface ProductListState {
  isLoading: boolean;
  isRefreshing: boolean;
  productSearchResults: PagedArray<ProductList>;
  campaign?: CampaignResult;
}

type ProductListScreenProps = ShopStackScreenProps<ShopStackRoutes.ProductList> & { setTsRequest: (reqest: OpenTombstoneRequest) => void };

const EmptyListComponent = (): JSX.Element => {
  return (
    <View style={styles.noItemFoundView}>
      <View style={[styles.emptyListContainer]} testID="noSearchResults">
        <View style={styles.emptyListIcon}>
          <Icon testID="searchMissingIcon" name={'search-missing-icon'} size={68} stroke="none" />
        </View>
        <Text testID="noItemsFound" numberOfLines={2} style={[appStyles.bodySmallRegular, { width: '42%', lineHeight: lineHeight(25) }]}>
          No items found
        </Text>
      </View>
      <View style={[styles.noItemFoundView, styles.filterText]}>
        <Text testID="adjustingFilter" style={[appStyles.fontMobileListSmallCenterRegular]}>
          Adjusting the filter setting may display products.
        </Text>
      </View>
    </View>
  );
};

const ProductListScreen = withTombstone(({ route, navigation, setTsRequest }: ProductListScreenProps): JSX.Element => {
  const { brxmConfiguration, brxmPage } = useBrxmPage();
  const [category, setCategory] = useState<Category>();
  const { rootCategoryId, categoryId } = route.params;
  const { isLoading: isLoadingCategories, data: categoryData } = brsmService.useCategoriesQuery();
  const { resetFilters, getSelectedFilters, sortQuery, setAvailableFilters } = useSearchStore();
  const { selectedStore } = useShopStore();
  const tabBarHeight = useBottomTabBarHeight();
  const { previousRoute } = useAnalyticsStore();
  const { trackPageViewEvent } = useAnalytics();
  const trackPageView = useRef<boolean>(true);

  const [state, setState] = useState<ProductListState>({
    isRefreshing: false,
    isLoading: true,
    productSearchResults: {
      data: [],
      offset: 0,
      limit: appConstants.PAGE_SIZE,
      total: 0,
    },
  });

  const loadProducts = useCallback(
    async (offset: number) => {
      const selectedFilters = getSelectedFilters();
      const res = await brsmService.getProductsByCategoryId(categoryId, offset, appConstants.PAGE_SIZE, {
        sortQuery,
        pathname: route.name,
        ref_url: previousRoute?.name,
        selectedFilters,
        storeKey: selectedStore && selectedStore.id ? undefined : Config.BRSM_DEFAULT_STORE,
      });

      let updatedProductList: ProductList[] = [];
      if (res.docs.total > 0 && res?.docs.data.length > 0) {
        setAvailableFilters(res.facetFilters);

        const skus = res.docs.data.map((p) => p.masterData.current?.masterVariant.sku ?? '') ?? [];
        const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE, skus);
        updatedProductList = getProductPromotions(promotions, res?.docs.data);
      }

      setState((prev) => ({
        ...prev,
        isRefreshing: false,
        isLoading: false,
        campaign: res?.campaign,
        productSearchResults: {
          ...prev.productSearchResults,
          total: res?.docs.total ?? 0,
          offset: res?.docs.offset ?? 0,
          data: [...(offset === 0 ? [] : prev.productSearchResults.data), ...(updatedProductList ?? [])],
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryId, previousRoute?.name, route.name, selectedStore, sortQuery],
  );

  useEffect(() => {
    loadProducts(0);

    return () => {
      setState({
        isRefreshing: false,
        isLoading: true,
        productSearchResults: {
          data: [],
          offset: 0,
          limit: appConstants.PAGE_SIZE,
          total: 0,
        },
      });
    };
  }, [loadProducts, selectedStore?.number]);

  const handleRefresh = async () => {
    setState((prev) => ({ ...prev, isRefreshing: true }));
    loadProducts(0);
  };

  const handleLoadMore = async () => {
    if (state.productSearchResults.total > state.productSearchResults.offset + appConstants.PAGE_SIZE) {
      loadProducts(state.productSearchResults.offset + appConstants.PAGE_SIZE);
    }
  };

  useEffectOnce(() => {
    resetFilters();

    if (trackPageView.current) {
      trackPageViewEvent();
      trackPageView.current = false;
    }
    return () => {
      trackPageView.current = true;
    };
  });

  useEffect(() => {
    const navigate = () => {
      navigation.dispatch({
        ...StackActions.replace(ShopStackRoutes.Categories),
        source: route.key,
        target: navigation.getState().key,
      });
    };
    const setCurrentCategory = () => {
      if (categoryData && categoryData.length) {
        let rootCategory = searchCategoryTree(categoryData, (item) => {
          return item.id === rootCategoryId;
        });
        let currentCategory = searchCategoryTree(rootCategory?.children ?? categoryData, (item) => {
          return item.id === categoryId;
        });
        if (currentCategory) {
          setCategory(currentCategory);
        } else {
          navigate();
        }
      }
    };

    if (!isLoadingCategories) {
      setCurrentCategory();
    }
  }, [categoryData, categoryId, isLoadingCategories, navigation, rootCategoryId, route.key]);

  const isSponsored = state.campaign?.name ? (findTag(state.campaign?.name, 'sponsored') ? true : false) : undefined;
  const isTile = state.campaign?.name ? (findTag(state.campaign?.name, 'tile') ? true : false) : undefined;

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<ProductList>) => {
      return (
        <>
          {state.campaign?.htmlText && isTile && index === 0 && (
            <View style={styles.campaignCardContainer}>
              <View style={styles.campaignCard}>
                <LinkButton
                  style={{ paddingTop: Platform.select({ android: scale(260) }) }}
                  route={slug(getHtmlAttribute('href', state.campaign?.htmlText))}
                  accessibilityLabel={getHtmlAttribute('alt', state.campaign?.htmlText)}>
                  <HtmlView text={state.campaign?.htmlText.replace('http:', 'https:') ?? ''} contentWidth={screenWidth * 0.5} />
                </LinkButton>
                {isSponsored ? <SponsoredIndicator /> : null}
              </View>
            </View>
          )}
          <ProductCard product={item.product} productCategory={category} index={index} productPromotion={item.promotion} changeStore={setTsRequest} />
        </>
      );
    },
    [category, isSponsored, isTile, setTsRequest, state.campaign?.htmlText],
  );

  const itemSeperator = useCallback(() => <View style={styles.separator} />, []);

  return (
    <>
      <CartInfoBanner />
      <SearchHeader tabBarHeight={tabBarHeight} />
      <View style={styles.tabWrapper}>
        {!!category && (
          <View style={{ height: category.children?.length ? 80 : 40 }}>
            <CategoryHeader
              categoryName={category.name}
              isRootCategory={false}
              onFilterPress={() =>
                navigation.push(AppStackRoutes.SearchFilter, {
                  title: 'Filter & Sort',
                  text: '*',
                })
              }
            />
            <CategoryNavigation subCategories={category.children} isRootCategory={false} categoryId={category.id} />
          </View>
        )}
      </View>
      {isLoadingCategories || !brxmPage || !brxmConfiguration || state.isLoading ? (
        <ActivityIndicator size={'large'} style={[styles.activityIndicator]} />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          {isLoadingCategories || state.isLoading ? (
            <View style={{ height: screenHeight * 0.7 }}>
              <LoadingScreen />
            </View>
          ) : (
            <FlatList
              data={state.productSearchResults.data}
              extraData={selectedStore}
              keyExtractor={(_, idx) => idx.toString()}
              renderItem={renderItem}
              numColumns={2}
              ItemSeparatorComponent={itemSeperator}
              onRefresh={handleRefresh}
              refreshing={false}
              onEndReached={handleLoadMore}
              refreshControl={<RefreshControl refreshing={state.isRefreshing} onRefresh={handleRefresh} />}
              onEndReachedThreshold={0.3}
              contentContainerStyle={{ paddingBottom: 75 }}
              ListEmptyComponent={state.isLoading || state.isRefreshing ? <></> : <EmptyListComponent />}
              ListHeaderComponent={
                <>
                  <BrComponent path="main" />
                  {state.campaign?.htmlText && !isTile && (
                    <>
                      <LinkButton
                        style={{ paddingTop: Platform.select({ android: scale(80) }) }}
                        route={slug(getHtmlAttribute('href', state.campaign?.htmlText))}
                        accessibilityLabel={getHtmlAttribute('alt', state.campaign?.htmlText)}>
                        <HtmlView text={state.campaign?.htmlText.replace('http:', 'https:') ?? ''} contentWidth={screenWidth} />
                      </LinkButton>
                      {isSponsored ? <SponsoredIndicator /> : null}
                    </>
                  )}
                </>
              }
              ListFooterComponent={
                !state.isLoading &&
                !state.isRefreshing &&
                state.productSearchResults.data.length > 0 &&
                state.productSearchResults.total > 0 &&
                state.productSearchResults.data.length < state.productSearchResults.total ? (
                  <ActivityIndicator />
                ) : (
                  <></>
                )
              }
            />
          )}
        </BrPage>
      )}
    </>
  );
}, {});

const styles = StyleSheet.create({
  separator: {
    backgroundColor: colors.darkCream,
    height: 1.5,
    width: '95%',
    alignSelf: 'center',
    ...utilityStyles.mt2,
  },
  noItemFoundView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 45,
  },
  emptyListIcon: {
    width: 100,
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 18,
    paddingLeft: 22,
  },
  filterText: {
    marginTop: 10,
    width: '70%',
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    height: '70%',
  },
  campaignCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxHeight: screenHeight * 0.38,
    height: 'auto',
    overflow: 'hidden',
  },
  campaignCardContainer: {
    height: screenHeight * 0.42,
    width: screenWidth * 0.5,
    flexDirection: 'row',
    overflow: 'hidden',
    paddingTop: 19,
    paddingLeft: 7,
    paddingRight: 7,
  },
  tabWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.borderGray,
    width: screenWidth,
    // transform: [{ translateX: -5 }],
  },
});

export default ProductListScreen;

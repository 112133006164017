import React from 'react';

type HtmlViewProps = {
  contentWidth?: number;
  text: string;
};

const HtmlView = (props: HtmlViewProps) => {
  return <div style={{ width: props.contentWidth ?? '100%' }} dangerouslySetInnerHTML={{ __html: props.text }} />;
};
export default HtmlView;

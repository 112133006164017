import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import { appStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { LogoDocument } from './brxm';

const Logo = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.editContainer}>
        <BrManageContentButton content={document} documentTemplateQuery="new-Logo-document" folderTemplateQuery="new-Logo-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { imagelink: imageRef, ImageAlt } = document.getData<LogoDocument>();
  const image = imageRef && page.getContent<ImageSet>(imageRef);

  // set height to keep container from collapsing in native
  const imageHeight = (screenWidth / image?.getOriginal()?.getWidth() || 0.75) * (image?.getOriginal()?.getHeight() || 1);

  return (
    <View style={[appStyles.topLogoImg, { minHeight: imageHeight }]}>
      <BrManageContentButton content={document} documentTemplateQuery="new-Logo-document" folderTemplateQuery="new-Logo-folder" parameter="document" />
      {image && (
        <Image
          accessibilityLabel={ImageAlt}
          style={[styles.image, { height: imageHeight }]}
          source={{ cache: 'reload', uri: image?.getOriginal()?.getUrl() }}
          resizeMode="cover"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  editContainer: {
    minHeight: 100,
  },
  image: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
  },
});

export default Logo;

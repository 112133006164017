import { BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { appStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import { Image, Text } from '../index';

const CategoryCustomContent = ({ page, component }: BrProps) => {
  const { CategoryCustomContent: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const { showAlertModal } = useCustomModalContext();

  if (!document) {
    return null;
  }

  // Get the document data
  const { image: imageRef, subTitle, description, flyer: headerText } = document.getData();
  const image = imageRef && page.getContent<ImageSet>(imageRef);
  const categoryData = document.getData().categoryCard;

  if (categoryData) {
    for (let i = 0; i < categoryData.length; i++) {
      categoryData[i].Image = page.getContent<ImageSet>(categoryData[i].categoryImage);
      categoryData[i].ImageUrl = categoryData[i].Image?.getOriginal()?.getUrl();
    }
  }

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.fontMobileH2Left}>{headerText}</Text>
        <View style={styles.squareImage}>
          {image && <Image style={styles.image} source={{ cache: 'web', uri: image?.getOriginal()?.getUrl() }} resizeMode="contain" />}
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.fontMobileEyebrowLeftDarkSm}>{subTitle}</Text>
          <Text style={styles.description}>{description}</Text>
          <View style={styles.gridBox}>
            {categoryData &&
              categoryData.map((item: any, idx: number) => {
                return (
                  <View style={styles.categoryBox} key={idx}>
                    <TouchableOpacity onPress={() => showAlertModal()}>
                      {item.ImageUrl && (
                        <View style={[styles.squareBox, { height: screenHeight > 667 ? screenHeight * 0.21 : screenHeight * 0.25 }]}>
                          <Image
                            source={{
                              uri: item.ImageUrl,
                            }}
                            style={styles.catagoryImage}
                          />
                        </View>
                      )}
                      <Text style={styles.video}>VIDEO</Text>
                      <Text style={styles.h5}>{item.categoryName}</Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
          </View>
        </View>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    height: 'auto',
    flex: 1,
  },

  gridBox: {
    marginTop: 10,
    paddingTop: 0,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  categoryBox: {
    width: '50%',
    paddingTop: 10,
    paddingHorizontal: 5,
  },
  description: {
    ...appStyles.h5,
    paddingTop: 5,
    left: 10,
  },
  squareImage: {
    width: screenWidth,
    height: 300,
    aspectRatio: 1,
  },
  squareBox: {
    width: screenWidth * 0.4,
    aspectRatio: 1,
    height: screenHeight * 0.25,
  },
  catagoryImage: {
    top: 0,
    width: '95%',
    height: '95%',
  },
  video: {
    marginTop: 5,
    fontSize: scale(16),
    fontWeight: 'bold',
    fontFamily: FontFamily.LarsseitBold,
  },
  image: {
    left: 15,
    width: screenWidth > 389 ? '118%' : '113%',
    height: '120%',
  },
  h5: {
    ...appStyles.h5,
    lineHeight: 24,
    paddingTop: 10,
    fontFamily: FontFamily.LarsseitBold,
  },
  contentContainer: { height: 660, paddingHorizontal: 10, top: 30 },
  fontMobileH2Left: {
    marginTop: 10,
    color: colors.text,
    fontSize: scale(30),
    fontFamily: FontFamily.AleoBold,
    lineHeight: 39,
    textAlign: 'center',
  },
  fontMobileEyebrowLeftDarkSm: {
    left: 10,
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.text,
    letterSpacing: 0.5,
    lineHeight: 18,
    textTransform: 'uppercase',
    paddingTop: 30,
  },
});

export default CategoryCustomContent;

import { QueryCache, QueryClient } from 'react-query';
import logger from './logger';

const queryCache = !__DEV__
  ? new QueryCache({
      onError: (error, query) => {
        logger.warn('queryClient  queryCache error:', error, query);
      },
      onSuccess: (_, query) => {
        logger.debug('queryClient reading from cache queryCache:', query.queryKey);
      },
    })
  : undefined;

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: { queries: { useErrorBoundary: true, retry: 3 } },
});

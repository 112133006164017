import Text from '@components/Text';
import colors from '@config/colors';
import { defaultMoney } from '@fieldera-raleys/client-commercetools';
import { Money, Product } from '@fieldera-raleys/client-commercetools/schema';
import { Order, OrderItem, OrderItemSubItem } from '@fieldera-raleys/client-common/types/order';
import { AppStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@styles/appStyles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, StyleSheet, TouchableOpacity, View } from 'react-native';
import { getProductQuantityIndex, getProductQuantityValue, moneyValue } from '../../utils/orderHelpers';
import { AvailablityResult, getProductAttributeValue, getProductsfromCommerceTools } from '../../utils/productHelper';
import CheckBox from '../Checkbox';
import Icon from '../Icon';
import LinkButton from '../LinkButton';
import { ItemSubstitutionOrder } from './ItemSubstitutionOrder';
import ProductImage from './ProductImage';

// const CART_IMAGE_SIZE = '-large.jpg';
const layout = { width: 106, imageWidth: 72, imageHeight: 72 };
let orderStatus = 'ACTIVE';
type ProdDataType = {
  [key: string]: Product;
};

export type ItemizedOrderProps = {
  order?: Order;
  reorder?: boolean;
  checkedItems?: OrderItem[];
  setCheckedItems?: (item: OrderItem) => void;
  availability?: {
    product: AvailablityResult[];
    unavailableCount: number;
  };
};

const getItemPrice = (item: OrderItem | OrderItemSubItem) => {
  const currencyCode = 'USD';
  const fractionDigits = 2;

  const centAmount = item.unitPrice * 100;

  let money: Money = {
    type: 'centPrecision',
    currencyCode: currencyCode,
    centAmount: centAmount,
    fractionDigits: fractionDigits,
  };

  return money;
};

const LineHeader = ({ isPaid = false }: { isPaid: boolean }): JSX.Element => {
  const { t } = useTranslation('cart');
  return (
    <View key={'lineLineItemHeaderKey'} style={[styles.subContainerItem, styles.itemsHeader]}>
      <View style={[styles.itemFrame]}>
        <View style={[styles.itemLeftHeader]}>
          <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.headerQty]}>
            {t('headerQty')}
          </Text>
        </View>
        <View style={[styles.itemRight]}>
          <View style={[styles.flexrow]}>
            <View style={[styles.unit]}>
              <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold]}>
                {t('headerItem')}
              </Text>
            </View>
            <Text style={[appStyles.bodySmallBold]}>&nbsp;</Text>
            {/* <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.price]}>
              {t('headerEach')}
            </Text> */}
            <Text style={[styles.unitSeparator]} />
            <Text style={[appStyles.bodySmallBold]}>&nbsp;</Text>
            <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.subtotal]}>
              {isPaid ? t('headerPaid') : t('headerPrice')}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const LineItemNote = ({ item }: { item: OrderItem }): JSX.Element | null => {
  const { t } = useTranslation('cart');

  const itemNote = item.orderItemNote;

  return itemNote ? (
    <View style={{ flexDirection: 'column', width: containerWidth }}>
      <Text style={[appStyles.body, appStyles.bodySmallRegular]}>{t('itemNote')}</Text>
      <View style={styles.notePad}>
        <Text style={appStyles.bodySmallRegular}>{itemNote}</Text>
      </View>
    </View>
  ) : null;
};

const ProductInfo = ({
  item,
  productData,
  isUnavailable,
  isDiscontinued,
}: {
  item: OrderItem;
  isUnavailable?: boolean;
  isDiscontinued?: boolean;
  onPress: (item: OrderItem) => void;
  productData?: ProdDataType;
}): JSX.Element => {
  const UNIT_SPACER = null;
  const [itemState, setItemState] = useState<{
    [key: string]: {
      customizations?: boolean;
    };
  }>({});
  const descr: string[] = [];

  let totalPrice: Money = getItemPrice(item);

  const customizationsPressed = useCallback(
    (e: GestureResponderEvent, oiId: number) => {
      setItemState({ ...itemState, [oiId]: { ...(itemState[oiId] ?? {}), customizations: !(itemState[oiId]?.customizations ?? false) } });
    },
    [itemState],
  );

  const productDetails: JSX.Element[] = [];
  productDetails.push(<Text key={`topSeparator_${item.orderItemId}`} style={[styles.subseparator]} />);

  // additional ingreditents
  const subItems = item.orderItemSubItems;
  if (subItems && subItems.length) {
    subItems.forEach((subItem, ctr) => {
      if (subItem.unitPrice > 0) {
        totalPrice.centAmount += subItem.unitPrice * 100;
        productDetails.push(
          <View key={`ingrKey_${item.orderItemId}_${ctr}`} style={[styles.flexrow]}>
            <Text numberOfLines={2} ellipsizeMode={'middle'} style={[appStyles.fontMobileTinyLight, styles.unit]}>
              {subItem.productListing.value}&nbsp;
              {subItem.qty > 1 ? [' (', subItem.qty, ')'].join('') : null}
            </Text>
            <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
            <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.price]}>
              {moneyValue(getItemPrice(subItem))}
            </Text>
            <Text style={[styles.unitSeparator]} />
            <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
            <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmall, styles.subtotal]}>
              {moneyValue(getItemPrice(subItem), subItem.qty * item.qty, '')}
            </Text>
          </View>,
        );
      } else {
        if ((subItem.productListing.value?.split(':').length ?? 1) > 1) {
          descr.push([subItem.productListing.value?.split(':')[1], subItem.qty > 1 ? [' (', subItem.qty, ')'].join('') : null].join(''));
        }
      }
    });
  }

  // Product name and description on top
  if (subItems && subItems.length) {
    productDetails.unshift(
      <View key={`itemDescr_${item.orderItemId}`}>
        <LinkButton onPress={(e) => customizationsPressed(e, item.orderItemId)} style={[{ flexDirection: 'row' }]}>
          <View style={[styles.customizationsContainer]}>
            <View style={[styles.priceContainer]}>
              <Text style={[appStyles.fontMobileBodySmallBald, { marginRight: 8 }]} key={`actionCustomizations_${item.orderItemId}`}>
                Customizations&nbsp;
              </Text>
              <Icon
                key={`customization_${item.orderItemId}`}
                fill={colors.black}
                stroke={'none'}
                strokeSecondary={colors.black}
                size={15}
                style={[appStyles.smallIcon, { alignSelf: 'center' }]}
                name={itemState?.[item.orderItemId]?.customizations ?? false ? 'triangle-up' : 'triangle-down'}
              />
            </View>
          </View>
        </LinkButton>
        {(itemState?.[item.orderItemId]?.customizations ?? false) && (
          <View key={`title_${item.orderItemId}`}>
            <View style={[styles.priceContainer]}>
              <View style={styles.unit}>
                {descr.map((tt: string, ctr) => (
                  <Text
                    key={`itemDescription_${ctr}`}
                    numberOfLines={2}
                    ellipsizeMode={'tail'}
                    style={[appStyles.fontMobileTinyLight, { lineHeight: lineHeight(14) }]}>
                    {tt}
                  </Text>
                ))}
              </View>
            </View>
            <Text key={`bottomSubseparator_${item.orderItemId}`} style={[styles.subseparator]} />
          </View>
        )}
      </View>,
    );
  }

  let sellTypeRow = null;
  let eachPriceRow: ReactElement | ReactElement[] = (
    <Text
      key={'prRowKey'}
      testID="prRowKey"
      numberOfLines={1}
      adjustsFontSizeToFit={true}
      style={[appStyles.fontMobileTinyLight, styles.subprice]}>{`${moneyValue(getItemPrice(item))} ea`}</Text>
  );
  if (item.unitRegularPrice && item.unitRegularPrice !== item.unitPrice) {
    eachPriceRow = [
      <Text
        testID="preg1"
        key={'preg1'}
        numberOfLines={1}
        adjustsFontSizeToFit={true}
        style={[appStyles.fontMobileTinyLight, styles.subprice, { color: colors.red }]}>
        {`${moneyValue(getItemPrice(item))} ea`}
      </Text>,
      <Text
        testID="preg2"
        key={'preg2'}
        numberOfLines={1}
        adjustsFontSizeToFit={true}
        style={[appStyles.fontMobileTinyLight, styles.subRegprice, { textDecorationLine: 'line-through', textDecorationColor: colors.black }]}>
        {`${item.unitRegularPrice} ea`}
      </Text>,
    ];
  }
  let sellType = item.unitSellType;
  if ((sellType && (sellType.value ?? 'byEach')) === 'Weight By Each' && !item.actualTotalWeight) {
    eachPriceRow = [
      <Text
        testID="approxTotalPrice"
        key={'approxTotalPrice'}
        numberOfLines={1}
        adjustsFontSizeToFit={true}
        style={[appStyles.fontMobileTinyLight, styles.approxSubtotal]}>
        approx
      </Text>,
    ];
  }

  if (item.unitSellType && item.unitSellType.value === 'Weight By Each') {
    sellTypeRow = (
      <View key={'sellTypeRowKey'} style={[{ flexDirection: 'row', width: '100%' }]}>
        <Text key={'price-per'} numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.subprice, { marginRight: 'auto' }]}>
          {`${moneyValue({ ...defaultMoney, centAmount: item.unitPrice * 100 })} ea`}
        </Text>

        <Text
          key={'price-per2'}
          numberOfLines={2}
          adjustsFontSizeToFit={true}
          style={[appStyles.fontMobileTinyLight, styles.subRegprice, { marginLeft: 'auto' }]}>
          {[
            item.actualTotalWeight ? `Total ${item.actualTotalWeight} ` : `Estimated ${item.estimatedTotalWeight} `,
            item.unitType?.customProperties?.Abbreviation ?? item.unitType?.value ?? 'ea',
          ].join('')}
        </Text>
      </View>
    );
  } else if (item.unitSellType && item.unitSellType.value === 'By Weight') {
    eachPriceRow = [
      <Text key={'eaprRowKey'} style={[appStyles.fontMobileTinyLight, styles.subtotal]}>{`${moneyValue({
        ...defaultMoney,
        centAmount: (item.unitPrice * 100) / (item.actualTotalWeight ? item.actualTotalWeight : item.estimatedTotalWeight),
      })}/${item.unitType.value ?? 'ea'} `}</Text>,
    ];
  } else {
    const unitsPerPackage = getProductAttributeValue(
      'unitsPerPackage',
      productData?.[item?.productListing?.customProperties?.ExtProductId ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
    );
    const packageCount = getProductAttributeValue(
      'packageCount',
      productData?.[item?.productListing?.customProperties?.ExtProductId ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
    );

    if (unitsPerPackage && item?.productListing?.customProperties?.ExtProductId !== '970002203') {
      // && !donation
      const unitTotalCount = ((unitsPerPackage ?? 1) * (packageCount ?? 1)).toFixed(1);
      const unitOfMeasure = getProductAttributeValue(
        'unitOfMeasure',
        productData?.[item?.productListing?.customProperties?.ExtProductId ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      sellTypeRow = [
        <Text
          key={'unitCount'}
          testID="byEachunitTotalCount"
          numberOfLines={1}
          adjustsFontSizeToFit={true}
          style={[appStyles.fontMobileTinyLight, styles.subprice, { marginRight: 'auto' }]}>
          {`${unitTotalCount} ${unitOfMeasure}`}
        </Text>,
      ];
    }
  }

  productDetails.unshift(
    <View key={`title_${item.orderItemId}`} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <View style={{ width: '60%' }}>
        <Text
          numberOfLines={2}
          ellipsizeMode={'tail'}
          style={[appStyles.bodySmallBold, isUnavailable || isDiscontinued ? styles.unavailable : null, { lineHeight: lineHeight(16) }]}>
          {item.productListing.value ?? ''}
        </Text>
        {(item.orderItemStatus.value ?? '').toUpperCase() === 'CANCELED' && orderStatus.toUpperCase() !== 'CANCELED' && (
          <Text numberOfLines={1} style={[appStyles.smallRed, styles.orderItemStatus]}>
            {item.orderItemStatus.value ?? ''}
          </Text>
        )}
        <Text numberOfLines={1} style={[appStyles.smallRed, styles.orderItemStatus]}>
          {isUnavailable && 'Unavailable'}
          {isDiscontinued && 'Discontinued'}
        </Text>
        {sellTypeRow}
      </View>

      <View style={[isUnavailable || isDiscontinued ? styles.unavailable : null, { width: '40%' }]}>
        <View key={`productPriceDetails_${item.orderItemId}`}>
          <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.subtotal]}>
            {moneyValue(getItemPrice(item), item.qty)}
          </Text>
          {eachPriceRow}
        </View>
      </View>
    </View>,
  );
  return <>{productDetails}</>;
};

export const ItemizedOrder = ({
  order,
  reorder = false,
  checkedItems = [],
  setCheckedItems,
  availability = { product: [], unavailableCount: 0 },
}: ItemizedOrderProps): JSX.Element => {
  const navigation = useNavigation();
  const [productData, setProductData] = useState<ProdDataType | undefined>();
  useEffect(() => {
    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      return await getProductsfromCommerceTools(skus);
    };
    if (order) {
      const skus: string[] = order.orderItems.reduce<string[]>((acc: string[], i: OrderItem) => {
        if (i.productListing.customProperties?.ExtProductId && acc.findIndex((o) => o === i.productListing.customProperties?.ExtProductId)) {
          acc.push(i.productListing.customProperties?.ExtProductId);
        }
        return acc;
      }, []);
      getProdSet(skus).then((pd) => {
        const pData: ProdDataType = pd.reduce((acc, p: Product) => {
          acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
          p.masterData?.current?.variants.forEach((vi) => {
            acc[vi?.sku ?? ''] = p;
          });

          return acc;
        }, {} as ProdDataType);
        setProductData(pData);
      });
    }
  }, [order]);

  const formatQuantity = useCallback(
    (productKey: { lineItemId: string }, q: number) => {
      if (!productData) {
        return;
      }

      const item = order?.orderItems.find((x) => x.productListing.customProperties?.ExtProductId === productKey.lineItemId);
      if (item) {
        const sellType = getProductAttributeValue(
          'unitSellType',
          productData[item.productListing.customProperties?.ExtProductId ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
        );

        const unitOfMeasure = getProductAttributeValue(
          'unitOfMeasure',
          productData[item.productListing.customProperties?.ExtProductId ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
        );

        const currentProductData = productData[item.productListing.customProperties?.ExtProductId ?? '']?.masterData?.current;
        if (currentProductData) {
          let val = getProductQuantityValue(
            currentProductData,
            getProductQuantityIndex(currentProductData, q, item.actualTotalWeight ? item.actualTotalWeight : item.estimatedTotalWeight),
          );
          if (sellType && sellType.key === 'byWeight') {
            return `${val}${'\n'}${unitOfMeasure}`;
          } else if (item.productListing.customProperties?.ExtProductId) {
            return val;
          }
        }
      }
    },
    [order, productData],
  );

  const handleOnPress = useCallback(
    (oi: OrderItem): void => {
      oi.productListing.customProperties?.ExtProductId &&
        navigation.navigate(AppStackRoutes.ProductDetails, { productKey: oi.productListing.customProperties.ExtProductId });
    },
    [navigation],
  );

  const isPaid = order?.orderStatus.id === '10' || order?.orderStatus.id === '11';

  const renderOrderItem = useCallback(
    (item: OrderItem) => {
      const isItemCanceled = (item.orderItemStatus.value ?? '').toUpperCase() === 'CANCELED' && orderStatus.toUpperCase() !== 'CANCELED';

      let sku = item.productListing!.customProperties!.ExtProductId;
      const productAvailabilityInfo = availability.product.find((availObj) => availObj.productSku === sku);
      const isUnavailable = productAvailabilityInfo?.availability === 'Unavailable' || productAvailabilityInfo?.availability === 'OutOfStock';
      const isDiscontinued = productAvailabilityInfo?.availability === 'Discontinued';

      const itemQuantity: JSX.Element[] = [];
      itemQuantity.push(
        <Text key={'s1'} style={[appStyles.bodySmallBold, { textAlign: 'center' }]} adjustsFontSizeToFit={true} numberOfLines={2}>
          {formatQuantity({ lineItemId: item.productListing.customProperties?.ExtProductId ?? '' }, item.qty)}
        </Text>,
      );

      return (
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => {
            if (!isUnavailable || !isDiscontinued) {
              handleOnPress(item);
            }
          }}
          key={item.orderItemId}>
          <View key={`renderROLineItemViewKey_${item.orderItemId}`} style={[styles.subContainerItem]}>
            <View key={`lineItemNoteROComponentViewKey_${item.orderItemId}`} style={[styles.itemFrame]}>
              <View style={[styles.itemLeft, isUnavailable || isDiscontinued ? styles.unavailable : null]}>
                <View style={[styles.itemLeft1]}>
                  {reorder && (!isUnavailable || !isDiscontinued) ? (
                    <TouchableOpacity>
                      <CheckBox
                        value={checkedItems.some((c) => c.orderItemId === item.orderItemId)}
                        onValueChange={() => setCheckedItems && setCheckedItems(item)}
                        disabled={isUnavailable || isDiscontinued}
                      />
                    </TouchableOpacity>
                  ) : null}
                  <View style={[styles.itemLeft1]}>{itemQuantity}</View>
                </View>
                <View style={[styles.itemLeft2]}>
                  <ProductImage imageUrl={item.productImageUrl} style={[styles.productImage, { opacity: isItemCanceled ? 0.5 : 1 }]} />
                </View>
              </View>
              <View style={[styles.itemRight]}>
                <ProductInfo item={item} productData={productData} onPress={handleOnPress} isUnavailable={isUnavailable} isDiscontinued={isDiscontinued} />
                <ItemSubstitutionOrder itemId={item.orderItemId.toString()} obj={order!} />
              </View>
            </View>
            <LineItemNote key={`lineItemNoteROComponentKey_${item.orderItemId}`} item={item} />
            <View style={[styles.separator]} />
          </View>
        </TouchableOpacity>
      );
    },
    [availability.product, checkedItems, formatQuantity, handleOnPress, order, productData, reorder, setCheckedItems],
  );

  const OrderItems = useCallback((): JSX.Element | null => {
    if (order) {
      orderStatus = order.orderStatus.value!;
      const lItems = order?.orderItems;
      return <>{lItems.map((li) => renderOrderItem(li))}</>;
    }

    return null;
  }, [order, renderOrderItem]);

  return (
    <TouchableOpacity activeOpacity={1} style={[styles.mainContainer]}>
      <View style={[styles.flex1]}>
        <LineHeader isPaid={isPaid} />
        <OrderItems key={'orderItems'} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: colors.sectionBorder,
    marginTop: 15,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  subContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 15,
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    paddingBottom: 5,
  },
  flex1: { flex: 1 },
  flexrow: { flexDirection: 'row' },
  flexcol: { flexDirection: 'column' },
  itemsHeader: {
    backgroundColor: colors.sectionPad,
    paddingBottom: 2,
    paddingTop: 5,
    marginBottom: 8,
  },
  itemLeftHeader: {
    minWidth: layout.width,
    width: layout.width,
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  empty: {
    width: containerWidth,
    textAlign: 'center',
    alignSelf: 'center',
  },
  flatPadContainer: {
    backgroundColor: colors.white,
    width: screenWidth,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  flatPad: {
    backgroundColor: colors.white,
    width: containerWidth,
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: scale(12),
    marginTop: scale(12),
    minHeight: scale(60),
  },

  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
  },

  itemLeft: {
    minWidth: layout.width,
    width: layout.width,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flex: 0,
    justifyContent: 'space-between',
  },
  itemLeft1: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
  },
  itemLeft2: {
    width: layout.imageWidth,
    minWidth: layout.imageWidth,
  },
  headerQty: {
    minWidth: layout.width,
    width: layout.width,
    textAlign: 'left',
    paddingTop: 4,
  },
  headerQtyCart: {
    minWidth: layout.width,
    width: layout.width,
    textAlign: 'left',
  },
  productImage: {
    width: layout.imageWidth,
    alignSelf: 'center',
    height: layout.imageHeight,
    borderWidth: 1,
    borderColor: colors.productBorder,
  },
  itemRight: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 10,
  },
  priceContainer: {
    flexDirection: 'row',
  },
  customizationsContainer: {
    paddingTop: 8,
    flexDirection: 'column',
  },
  itemsSeparator: {
    height: scale(40),
    backgroundColor: colors.cream,
  },
  subseparator: {
    height: 0.33,
    width: '100%',
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.cream,
  },
  subtotal: {
    textAlign: 'right',
    flex: 0.29,
    alignSelf: 'flex-end',
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    alignSelf: 'flex-end',
  },
  oldPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  newPrice: {
    color: colors.red,
  },
  unit: {
    flex: 0.75,
    marginRight: 1,
    alignSelf: 'flex-end',
  },
  unitSeparator: {
    width: 0.03,
    height: '100%',
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.cream,
    alignSelf: 'flex-end',
  },
  itemDescription: {
    fontSize: scale(14),
  },
  headerRow: {
    flexDirection: 'row',
    color: colors.navBorder,
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  cartEmpty: {
    marginBottom: 25,
  },
  orderItemStatus: {
    lineHeight: lineHeight(14),
    paddingTop: scale(12),
  },
  unavailable: {
    opacity: 0.3,
  },
  approxSubtotal: {
    marginTop: scale(-6),
    marginBottom: scale(2),
    textAlign: 'right',
    flex: 0.29,
    marginLeft: 'auto',
  },
  subRegprice: {
    marginTop: -4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  subprice: {
    marginTop: 4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
});

export default ItemizedOrder;

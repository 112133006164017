import { useShopStore } from '@store';
import React, { useEffect } from 'react';
import Config from 'react-native-config';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import useIsMounted from './useIsMounted';

const useRefetchOnStoreChange = <TData, TError>(
  refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<TData, TError>>,
) => {
  const { selectedStore } = useShopStore();
  const defalutStoreId = parseInt(Config.BRSM_DEFAULT_STORE, 10);
  const currentStoreRef = React.useRef(selectedStore?.id ?? defalutStoreId);
  const isMounted = useIsMounted();
  useEffect(() => {
    if ((isMounted() && currentStoreRef.current !== selectedStore?.id) ?? defalutStoreId) {
      currentStoreRef.current = selectedStore?.id ?? defalutStoreId;
      refetch();
    }
  }, [defalutStoreId, isMounted, refetch, selectedStore?.id]);
};
export default useRefetchOnStoreChange;

import { appStyles } from '@styles';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { StyleProp, TextStyle } from 'react-native';
import Text from '../Text';

interface Props {
  error: string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined;
  visible: boolean | FormikTouched<any>[] | FormikTouched<any> | undefined;
  style?: StyleProp<TextStyle>;
  reserveSpace?: boolean;
  testID?: string;
}

const ErrorMessage: React.FC<Props> = ({ error, visible, style, reserveSpace, testID }) => {
  //  if (!visible || !error) return null;
  if (!visible || !error) {
    return reserveSpace ? (
      <Text style={[appStyles.formErrorText, style]} testID={testID ?? 'error'}>
        &nbsp;
      </Text>
    ) : null;
  }

  return (
    <Text style={[appStyles.formErrorText, style]} testID={testID ?? 'error'}>
      {error}
    </Text>
  );
};

export default ErrorMessage;

import Text from '@components/Text';
import colors from '@config/colors';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { ShopType, WidgetEventParams } from '@fieldera-raleys/client-common';
import { useAnalytics } from '@hooks';
import { useNavigation } from '@react-navigation/native';
import { useCartStore, useListsStore, useShopStore } from '@store';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { getProductQuantityIndex, getProductQuantityValue } from '@utils/orderHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import { hapticNotify } from '../../utils/helpers';
import { AvailablityResult } from '../../utils/productHelper';
import Button from '../Button';
import Icon from '../Icon';
import QuantityBox from '../QuantityBox';
import AddToListOptions from '../listManagement/AddToListOptions';

interface CartQuantityButtonProps extends TouchableOpacityProps {
  product: Product;
  productKey: string;
  productName?: string;
  variantKey?: string;
  productLeadTime?: string;
  productSellType?: { key: string; label: string };
  unitsPerPackage?: number;
  unitBuyMinimum?: number;
  unitBuyMaximum?: number;
  unitBuyIncrement?: number;
  unitOfMeasure?: string;
  type?: 'primary' | 'secondary';
  title?: string;
  textStyle?: StyleProp<TextStyle>;
  size?: 'regular' | 'small';
  disabled?: boolean;
  rDecore?: string | JSX.Element | null;
  showCartText?: boolean;
  cartTextStyle?: StyleProp<TextStyle>;
  qtyContainerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  borderStyle?: StyleProp<ViewStyle>;
  availabilityResult?: AvailablityResult;
  widgetEventParams?: WidgetEventParams;
  fromListName?: string;
  showAddToList?: boolean;
  testID?: string;
  infoBox?: StyleProp<ViewStyle>;
}

const CartQuantityButton: React.FC<CartQuantityButtonProps> = ({
  product,
  productKey,
  productName,
  variantKey,
  title = 'Add to Cart',
  textStyle,
  type = 'primary',
  disabled = false,
  rDecore = null,
  size = 'regular',
  showCartText = false,
  cartTextStyle,
  qtyContainerStyle,
  buttonStyle,
  borderStyle,
  productLeadTime,
  availabilityResult,
  productSellType = { key: 'byEach', label: 'Each' },
  unitBuyMinimum,
  unitBuyIncrement,
  unitOfMeasure,
  widgetEventParams,
  fromListName,
  showAddToList = false,
  testID,
  infoBox,
}: CartQuantityButtonProps) => {
  const { getLineItemQuantity, setLineItemQuantity, addStandardLineItems, getMaxProductQuantity, getLineItemEstimatedWeight } = useCartStore();
  const { addItemToList } = useListsStore();
  const { showAlertPopup } = useCustomModalContext();
  const { selectedShopType } = useShopStore();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [incVal, setIncVal] = useState<number>(1);
  //TODO: Add ternary to check for byWeight sellType...if byWeight then qty can we the weight custom
  const qty = getLineItemQuantity({ sku: productKey });
  const weight = getLineItemEstimatedWeight({ sku: productKey });
  const handleError = useErrorHandler();
  const { showModal, hideModal } = useCustomModalContext();
  const { trackAddToCartEvent, trackWidgetAddToCartEvent } = useAnalytics();
  const navigation = useNavigation();

  const handleAddToCart = () => {
    if (productLeadTime) {
      showModal({
        title: 'Longer Lead Time Required',
        location: 'top',
        cancelButtonText: 'Back',
        cancelButtonOnPress: hideModal,
        okButtonOnPress: addToCart,
        okButtonText: 'Yes',
        children: <Text style={appStyles.bodyLargeCenter}>{productLeadTime}</Text>,
      });
    } else {
      addToCart();
    }
  };

  const customHeader = () => (
    <View style={styles.handle}>
      <TouchableOpacity
        testID="closeClick"
        style={styles.closeButton}
        onPress={() => {
          hideModal();
        }}>
        <Icon name={'x-close'} size={20} />
      </TouchableOpacity>
      <Text style={appStyles.h6} testID="AddToList">
        Add to List
      </Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]} testID="instruction">
        Which list would you like to use?
      </Text>
    </View>
  );

  const openList = () => {
    showModal({
      style: { borderTopLeftRadius: 25, borderTopRightRadius: 25, backgroundColor: colors.cream },
      header: customHeader(),
      children: <AddToListOptions productSku={productKey} searchText={''} containerStyle={styles.alertBox} navigation={navigation} />,
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3 },
    });
  };

  const addToCart = () => {
    setButtonLoading(true);

    addStandardLineItems([{ sku: productKey, quantity: 1, product }])
      .then(() => hapticNotify('notificationSuccess'))
      .catch((ex) => {
        hapticNotify('notificationError');
        handleError(ex);
      })
      .finally(() => {
        setButtonLoading(false);
      });
    showWarningModal && setShowWarningModal(false);
    widgetEventParams &&
      trackWidgetAddToCartEvent({
        wrid: widgetEventParams?.wrid,
        wid: widgetEventParams.wid,
        wty: widgetEventParams.wty,
        sku: variantKey,
        item_id: productKey,
      });
    trackAddToCartEvent({ sku: variantKey, prod_name: productName, prod_id: productKey });
  };

  const getIncrement = useCallback(async () => {
    if ((productSellType?.key ?? '') === 'byWeight') {
      setIncVal(+(unitBuyIncrement ?? 0) ?? 0);
    } else {
      setIncVal(1);
    }
  }, [productSellType?.key, unitBuyIncrement]);

  const getMaxLimit = useCallback(async () => {
    if ((productSellType?.key ?? '') === 'byWeight') {
      return (await getMaxProductQuantity(productKey)) ?? 1;
    } else {
      return getMaxProductQuantity(productKey);
    }
  }, [getMaxProductQuantity, productKey, productSellType?.key]);

  useEffect(() => {
    getIncrement();
  }, [getIncrement, productKey]);

  const addToList = async () => {
    if (fromListName) {
      await addItemToList(productKey, fromListName);
      showAlertPopup({
        message: `Item Added to "${fromListName}"`,
        containerStyle: [styles.alertBox, infoBox],
      });
    }
  };

  const handleQuantityChange = (q: number) => {
    if (q === 0) {
      // let prName = productName ? productName : 'Item';
      showAlertPopup({
        message: 'Item removed from cart',
        containerStyle: [styles.alertBox, infoBox],
      });
    }
    setLineItemQuantity({ sku: productKey }, q);
  };

  let qtyTxtStyle: StyleProp<TextStyle>[] = [
    size !== 'small' ? { fontSize: scale(18), lineHeight: lineHeight(18) } : { fontSize: scale(22), lineHeight: lineHeight(22) },
  ];
  if (type === 'primary') {
    qtyTxtStyle = [size === 'small' ? appStyles.primaryButtonSmallText : appStyles.primaryButtonText];
  }

  const unAvailable = ['Unavailable', 'OutOfStock', 'Discontinued'].some((i) => i === availabilityResult?.availability);

  const buttonTitle = () => {
    if (unAvailable) {
      if (availabilityResult?.availability === 'Discontinued') {
        // Show Discontinued for all In-Store, Pickup and Delivery type shopping
        return 'Discontinued';
      } else if (selectedShopType === ShopType.IN_STORE || showAddToList) {
        // For all other statuses, show In-Store for in store type shopping
        return 'Add to List';
      } else if (availabilityResult?.availability === 'OutOfStock') {
        // Show Out of Stock for Pickup and Devlivery type shopping
        return 'Out Of Stock';
      } else if (availabilityResult?.availability === 'Unavailable') {
        // Show Unavailable for Pickup and Devlivery type shopping
        return 'Unavailable';
      } else {
        return title;
      }
    } else if (selectedShopType === ShopType.IN_STORE || showAddToList) {
      return 'Add to List';
    } else {
      return title;
    }
  };

  const getQuantityIndex = useCallback(
    (sku: string, quantity: number, estimatedTotalWeight?: number) => {
      if (product) {
        if (product?.masterData?.current) {
          return getProductQuantityIndex(product.masterData.current!, quantity, estimatedTotalWeight);
        }
      }
      return 0;
    },
    [product],
  );

  const formatQuantityFromIndex = useCallback(
    (sku: string, index: number) => {
      if (product) {
        if (product?.masterData?.current) {
          let val = getProductQuantityValue(product.masterData.current!, index);
          return productSellType.key === 'byWeight' ? `${val} ${unitOfMeasure}` : val;
        }
      }
    },
    [product, productSellType.key, unitOfMeasure],
  );

  return (
    <>
      {qty ? ( // TODO: add debounce logic
        <QuantityBox
          containerStyle={[...(size === 'small' ? [styles.quantityBoxSmall, buttonStyle] : [styles.quantityBox, buttonStyle]), qtyContainerStyle]}
          countStyle={[qtyTxtStyle, textStyle]}
          initialValue={getQuantityIndex(product.masterData.current?.masterVariant.sku ?? '', qty, weight)}
          //onChange={(q) => setLineItemQuantity({ sku: productKey }, q)}
          onChange={(q) => handleQuantityChange(q)}
          showCartText={showCartText}
          cartStyle={cartTextStyle}
          borderStyle={borderStyle}
          incrementBy={incVal}
          maxLimit={getMaxLimit()}
          minLimit={unitBuyMinimum}
          qtyFormat={(idx) => formatQuantityFromIndex(product.masterData.current?.masterVariant.sku ?? '', idx)}
          sellType={productSellType}
        />
      ) : (
        // Not in cart
        <Button
          testID={testID ? `${buttonTitle().toLowerCase().replace(/ /g, '')}-${testID}` : 'productAddToCartBtn'}
          disabled={(disabled || !(selectedShopType === ShopType.IN_STORE || showAddToList)) && unAvailable}
          onPress={showAddToList ? addToList : selectedShopType === ShopType.IN_STORE ? openList : handleAddToCart}
          rDecore={rDecore}
          type={type}
          title={buttonTitle()}
          isButtonLoading={buttonLoading}
          size={size}
          buttonStyle={[buttonStyle]}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  quantityBox: {
    minWidth: 205,
    maxWidth: 350,
    height: 56,
    alignSelf: 'center',
    padding: 5,
  },
  quantityBoxSmall: {
    marginTop: 8,
    marginBottom: 8,
    minWidth: 126,
    maxWidth: 126,
    height: 34,
    alignSelf: 'center',
    padding: 5,
    marginLeft: -2,
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.17 : screenHeight > 812 ? screenHeight * 0.165 : screenHeight < 740 ? screenHeight * 0.165 : screenHeight * 0.175,
  },
  disabled: {
    opacity: 0.75,
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    // height: 50,
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  linkContainer: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    justifyContent: 'center',
  },
  link: {
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
});

export { CartQuantityButton };

import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { offerService } from '@services/brandywine';
import useOffersStore from '@store/offersStore';

export const getAvailableOffers = (upc: string): Promotion[] => {
  const { availableSomethingExtraOffers, availableWeeklyExclusiveOffers, availableDigitalCoupons } = useOffersStore.getState();
  const availableOffers = availableSomethingExtraOffers.concat(availableWeeklyExclusiveOffers).concat(availableDigitalCoupons);

  // return availableOffers?.filter((off) => off.Conditions.some((co) => co.Product.Include.includes(upc))) ?? [];
  return (
    availableOffers?.filter((off) => {
      off.ProductList?.some((sku) => sku === upc);
    }) ?? []
  );
  // TODO: add logic for offer by category
};

export const getAcceptedOffers = (upc: string): Promotion[] => {
  const { acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons } = useOffersStore.getState();
  const acceptedOffers = acceptedSomethingExtraOffers.concat(acceptedWeeklyExclusiveOffers).concat(acceptedDigitalCoupons);

  // return acceptedOffers?.filter((off) => off.Conditions.some((co) => co.Product.Include.includes(upc))) ?? [];
  return (
    acceptedOffers?.filter((off) => {
      off.ProductList?.some((sku) => sku === upc);
    }) ?? []
  );
  // TODO: add logic for offer by category
};

export const getOffersByProductList = async (
  storeNumber: string,
  productList: string[],
): Promise<{
  AvailablePromotions: Promotion[];
  AcceptedPromotions: Promotion[];
}> => {
  return offerService.getOffersByProductList('app', storeNumber, productList);
};

export const getCouponsByProductList = async (
  storeNumber: string,
  productList: string[],
): Promise<{
  AvailableCoupons: Promotion[];
  AcceptedCoupons: Promotion[];
}> => {
  return offerService.getCouponsByProductList('app', storeNumber, productList);
};

export const getOffersAndCouponsByProductList = async (
  storeNumber: string,
  productList: string[],
): Promise<{
  AvailablePromotions: Promotion[];
  AcceptedPromotions: Promotion[];
}> => {
  const { AvailablePromotions, AcceptedPromotions } = await offerService.getOffersByProductList('app', storeNumber, productList);
  const { AvailableCoupons, AcceptedCoupons } = await offerService.getCouponsByProductList('app', storeNumber, productList);

  return {
    AvailablePromotions: (AvailablePromotions ?? []).concat(AvailableCoupons ?? []),
    AcceptedPromotions: (AcceptedPromotions ?? []).concat(AcceptedCoupons ?? []),
  };
};

export const evaluateProduct = async (
  storeNumber: string,
  shippingMethod: 'pickup' | 'delivery',
  product: { ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number },
  cart?: { ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number }[],
): Promise<Promotion[]> => {
  return await offerService.evaluateProduct('app', storeNumber, shippingMethod, product, cart);
};

export const evaluateCart = async (
  storeNumber: string,
  shippingMethod: 'pickup' | 'delivery',
  cart: { LineItemId: string; ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number }[],
): Promise<Promotion[]> => {
  return await offerService.evaluateCart('app', storeNumber, shippingMethod, cart ?? []);
};

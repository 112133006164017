import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
  AppBenefitsScreen,
  CreateAccountInitialScreen,
  CreateAccountScreen,
  ForgotEmailScreen,
  ForgotPasswordScreen,
  LoginScreen,
  ResetPasswordScreen,
  VerifyOTCScreen,
  WebBrowserScreen,
} from '@screens';

import UpgradeAppModal from '@components/UpgradeModal';
import { useAppConfigStore, useUserSettingsStore } from '@store';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CustomModalProvider } from '../contexts';
import { NavigationProps } from './declarations';
import { AuthStackParamList, AuthStackRoutes } from './routes';

const AuthNavigation = ({ queryParams, prefix, theme }: NavigationProps) => {
  const navigationRef = useNavigationContainerRef();
  const Stack = createStackNavigator<AuthStackParamList>();
  const { userSettings, updateSetting } = useUserSettingsStore();
  const [showAppUpgradeModal, setShowAppUpgradeModal] = useState(false);
  const { upgradeMode } = useAppConfigStore();
  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        AppBenefits: 'app-benefits',
        SignIn: 'sign-in',
        ForgotPassword: 'forgot-password',
        ResetPassword: {
          path: 'reset-password/:email/:code',
          parse: {
            email: String,
            code: String,
          },
        },
        CreateAccountInitial: 'create-account',
      },
    },
  };

  useEffect(() => {
    if (
      !showAppUpgradeModal &&
      (upgradeMode ?? '') === 'SuggestUpgrade' &&
      (userSettings?.lastCkecked === undefined || dayjs().diff(userSettings.lastCkecked, 'days') > 0)
    ) {
      setShowAppUpgradeModal(true);
      updateSetting('lastCkecked', dayjs().toDate());
    } else if (showAppUpgradeModal && upgradeMode !== 'SuggestUpgrade') {
      setShowAppUpgradeModal(false);
    }
  }, [showAppUpgradeModal, updateSetting, upgradeMode, userSettings?.lastCkecked]);

  return (
    <NavigationContainer ref={navigationRef} theme={theme} linking={linking}>
      <CustomModalProvider>
        <Stack.Navigator
          screenOptions={{
            headerBackTitleVisible: false,
            headerShown: false,
          }}
          initialRouteName={userSettings?.showAppBenefits ? AuthStackRoutes.AppBenefits : AuthStackRoutes.SignIn}>
          <Stack.Screen name={AuthStackRoutes.AppBenefits} component={AppBenefitsScreen} options={{ title: 'App Benefits' }} initialParams={queryParams} />
          <Stack.Screen name={AuthStackRoutes.SignIn} component={LoginScreen} options={{ title: 'Sign In' }} initialParams={queryParams} />
          <Stack.Screen
            name={AuthStackRoutes.ForgotPassword}
            component={ForgotPasswordScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
            }}
            initialParams={queryParams}
          />
          <Stack.Screen
            name={AuthStackRoutes.ForgotEmail}
            component={ForgotEmailScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
            }}
            initialParams={queryParams}
          />
          <Stack.Screen
            name={AuthStackRoutes.VerifyOTC}
            component={VerifyOTCScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
            }}
            initialParams={queryParams}
          />
          <Stack.Screen
            name={AuthStackRoutes.ResetPassword}
            component={ResetPasswordScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
            }}
            initialParams={queryParams}
          />
          <Stack.Screen
            name={AuthStackRoutes.CreateAccountInitial}
            component={CreateAccountInitialScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
            }}
            initialParams={queryParams}
          />
          <Stack.Screen
            name={AuthStackRoutes.CreateAccount}
            component={CreateAccountScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
            }}
            initialParams={queryParams}
          />
          <Stack.Screen
            name={AuthStackRoutes.WebBrowser}
            component={WebBrowserScreen}
            options={{
              cardOverlayEnabled: true,
              presentation: 'modal',
            }}
          />
        </Stack.Navigator>
      </CustomModalProvider>
      <UpgradeAppModal shoModal={showAppUpgradeModal} onDismiss={() => setShowAppUpgradeModal(false)} />
    </NavigationContainer>
  );
};

export default AuthNavigation;

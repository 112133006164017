import AsyncStorage from '@react-native-async-storage/async-storage';
import logger from './logger';

const getItem = async <T>(key: string): Promise<T | undefined> => {
  try {
    var token = await AsyncStorage.getItem(key);
    return token ? JSON.parse(token) : undefined;
  } catch (ex) {
    logger.error(ex);
    return undefined;
  }
};

const setItem = async <T>(key: string, value: T): Promise<void> => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (ex) {
    logger.error(ex);
  }
};

const removeItem = async (key: string): Promise<void> => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (ex) {
    logger.error(ex);
  }
};
export default { getItem, setItem, removeItem };

import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import LinkButton from '../LinkButton';

const GridComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const navigation = useNavigation();

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  let categoryData = document.getData().categoryCard;
  const { title } = document.getData();

  if (categoryData) {
    for (let i = 0; i < categoryData.length; i++) {
      categoryData[i].Image = page.getContent<ImageSet>(categoryData[i].categoryImage);
      categoryData[i].ImageUrl = categoryData[i].Image?.getOriginal()?.getUrl();
    }
  }

  return (
    <View style={styles.categoryContainer} testID="GridComponent">
      <Text style={styles.h2} testID="GridComponetTitle">
        {title}
      </Text>
      <View style={styles.gridBox}>
        {categoryData &&
          categoryData.map((item: any, idx: number) => {
            return (
              <View style={styles.categoryBox} key={idx}>
                <LinkButton route={item.link} style={styles.link}>
                  {item.ImageUrl && (
                    <View style={styles.squareImage}>
                      <Image
                        accessibilityLabel={item.ImageAlt}
                        source={{
                          uri: item.ImageUrl,
                        }}
                        style={styles.catagoryImage}
                      />
                    </View>
                  )}
                  <Text style={styles.h5}>{item.categoryName}</Text>
                </LinkButton>
              </View>
            );
          })}
        <TouchableOpacity
          onPress={() => navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } })}>
          <Text style={styles.categoryLink} testID="GridComponetLink">
            See All Categories
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  categoryContainer: {
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 10,
    backgroundColor: '#F0E5DF',
    flex: 1,
    // height: 725,
  },
  gridBox: {
    paddingTop: 25,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  categoryBox: {
    width: '50%',
    paddingTop: 15,
    paddingHorizontal: 5,
  },
  squareImage: {
    width: screenWidth * 0.45,
    aspectRatio: 1,
  },
  catagoryImage: {
    bottom: -5,
    width: '100%',
    height: '95%',
  },
  h2: {
    ...appStyles.h2,
    lineHeight: 40,
  },
  link: {
    textDecorationLine: 'none',
  },
  h5: {
    color: colors.text,
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(26),
  },
  categoryLink: {
    textAlign: 'center',
    color: colors.red,
    fontSize: 20,
    fontFamily: FontFamily.AleoBold,
    lineHeight: 23,
    textDecorationLine: 'underline',
    textDecorationColor: colors.red,
    textDecorationStyle: 'solid',
    top: 40,
    left: 100,
  },
});

export default GridComponent;

import { Configuration } from '@bloomreach/spa-sdk';
import axios from 'axios';
import Config from 'react-native-config';

const getBrxmConfiguration = (path: string | undefined) => {
  return {
    endpoint: Config.BRXM_API_URL,
    endpointQueryParameter: 'endpoint',
    origin: Config.BRXM_CMS_ENDPOINT,
    // debug: __DEV__ ? true : false,
    httpClient: axios,
    path: `${path}`,
  } as Configuration;
};

export const BrxmChannel: Record<'Mobile' | 'Web', string> = {
  Mobile: 'mobile',
  Web: 'web',
};

export default getBrxmConfiguration;

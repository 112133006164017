import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import { useField } from 'formik';
import React, { forwardRef, useEffect } from 'react';
import { Switch as NativeSwitch, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Switch, { SwitchProps } from '../Switch';
import Text from '../Text';
import ErrorMessage from './ErrorMessage';

interface FormSwitchProps extends Omit<SwitchProps, 'onValueChange'> {
  name: string;
  label?: string;
  preLabel?: string;
  reserveSpace?: boolean;
  hidden?: boolean;
  initialValue?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
}

const FormSwitch = forwardRef<NativeSwitch, FormSwitchProps>(
  ({ name, label, preLabel, initialValue, reserveSpace, hidden, style, containerStyle, labelStyle, ...otherProps }, ref): JSX.Element => {
    const [field, meta, helpers] = useField<boolean>(name);
    useEffect(() => {
      helpers.setValue(initialValue || field.value);
      helpers.setTouched(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);
    return (
      <View style={[utilityStyles.mt3, containerStyle, { display: hidden ? 'none' : 'flex' }]}>
        <View style={[styles.switchWrapper]}>
          {preLabel && <Text style={styles.swicthInfoText}>{preLabel}</Text>}
          <Switch ref={ref} style={[styles.switch, style]} onValueChange={helpers.setValue} value={field.value} {...otherProps} />
          {label && <Text style={[styles.swicthInfoText, labelStyle]}>{label}</Text>}
        </View>
        {meta.touched && !meta.value ? <ErrorMessage reserveSpace={reserveSpace} error={meta.error} visible={meta.touched} /> : <></>}
      </View>
    );
  },
);
const styles = StyleSheet.create({
  switch: { flex: 0, transform: [{ scaleX: 0.75 }, { scaleY: 0.75 }] },
  switchWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  swicthInfoText: {
    marginLeft: 5,
    fontFamily: FontFamily.Larsseit,
    color: colors.darkText,
    fontSize: scale(15),
    lineHeight: scale(20),
  },
});
export default FormSwitch;

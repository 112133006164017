import { KeyboardAvoidingScreen, Modal, NavigationHeader, Screen, TextInput } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import Text from '@components/Text';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import { ChangePassword } from '@fieldera-raleys/client-common';
import { userService } from '@services/brandywine';
import { useCustomizeStore, useOffersStore, usePurchaseHistoryFilterStore, useSearchStore, useShopStore, useUserSettingsStore } from '@store';
import useAuthStore from '@store/authStore';
import { appStyles, utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet } from 'react-native';
import * as Yup from 'yup';
import Cache from '../../utils/cache';
import helpers from '../../utils/helpers';
import { queryClient } from '../../utils/reactQuery';

const ChangePasswordScreen = () => {
  const changePasswordFormValues = useRef<ChangePassword>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const { t } = useTranslation('changePassword');
  const [loadingButton, setLoadingButton] = useState(false);
  const [changePasswordFailed, setChangePasswordFailed] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showChangePasswordSuccessfullModal, setShowChangePasswordSuccessfullModal] = useState(false);
  const { username, setAuthToken } = useAuthStore();
  const { clearAll: clearUserSetting } = useUserSettingsStore();
  const { clear: clearSearchStore } = useSearchStore();
  const { clearPurchasesStore } = usePurchaseHistoryFilterStore();
  const { clearSavedItems } = useCustomizeStore();
  const { clearShopData } = useShopStore();
  const { clearOffersStore } = useOffersStore();
  const errorMessageRef = useRef<string>();

  const newPasswordRef = React.createRef<TextInput>();
  const confirmPasswordRef = React.createRef<TextInput>();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required().label('Current password'),
    // .length(8, t('passwordLengthValidation'))
    // .matches(helpers.PASSWORD_REGEX, t('passwordNumCharValidation')),

    newPassword: Yup.string()
      .required()
      .notOneOf([Yup.ref('currentPassword'), null], t('currentNewPasswordValidationText'))
      .label('New password')
      .min(8, t('passwordLengthValidation'))
      .matches(helpers.PASSWORD_REGEX, t('passwordNumCharValidation')),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('newPassword'), null], t('newConfirmPasswordValidationText'))
      .label('Confirm password')
      .min(8, t('passwordLengthValidation'))
      .matches(helpers.PASSWORD_REGEX, t('passwordNumCharValidation')),
  });

  const handleSignOut = async () => {
    queryClient.clear();
    await Cache.flush();
    clearSearchStore();
    clearShopData();
    clearPurchasesStore();
    clearUserSetting();
    clearSavedItems();
    clearOffersStore();
    setAuthToken(username, undefined);
  };

  const onSubmit = async ({ currentPassword, newPassword, confirmPassword }: ChangePassword) => {
    setChangePasswordFailed(false);
    setLoadingButton(true);
    try {
      const response = await userService.changePassword(currentPassword, newPassword, confirmPassword);
      if (response) {
        setChangePasswordFailed(true);
      } else {
        setShowChangePasswordSuccessfullModal(true);
      }
    } catch (error) {
      if ((error as Error).message.indexOf('Incorrect Credentials') >= 0) {
        errorMessageRef.current = t('currentPasswordError');
      } else {
        errorMessageRef.current = t('apiErrorText');
      }
      setChangePasswordFailed(true);
    } finally {
      setLoadingButton(false);
    }
  };

  const showChangePasswordSuccessModal = () => {
    return (
      <Modal
        visible={showChangePasswordSuccessfullModal}
        title={t('changePassword')}
        location="top"
        cancelButtonOnPress={handleSignOut}
        cancelButtonText={t('okText')}>
        <Text style={[appStyles.bodyMediumLight, styles.textAlignCenter]} testID="successMessage">
          {t('successMessage')}
        </Text>
      </Modal>
    );
  };

  return (
    <Screen>
      <NavigationHeader subStyle={[styles.alignItemFlex, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID="changePassword">{t('changePassword')}</HeaderTitle>
      </NavigationHeader>
      <KeyboardAvoidingScreen behavior="padding">
        <ScrollView bounces={false} contentContainerStyle={appStyles.container}>
          <Form initialValues={changePasswordFormValues.current} onSubmit={onSubmit} validationSchema={validationSchema}>
            <ErrorMessage error={errorMessageRef.current} visible={changePasswordFailed} style={styles.errorText} testID="changePasswordError" />
            <FormField
              testID="currentPassword"
              autoCapitalize="none"
              autoCorrect={false}
              name="currentPassword"
              label={t('currentPasswordLabelText')}
              secureTextEntry={!showCurrentPassword}
              textContentType="password"
              returnKeyType="next"
              onSubmitEditing={() => {
                newPasswordRef?.current?.focus();
              }}
              icon={{
                testID: 'currentPasswordIcon',
                name: showCurrentPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={() => {
                setShowCurrentPassword(!showCurrentPassword);
              }}
              keyboardType={Platform.select({ android: 'default' })}
            />
            <FormField
              testID="newPassword"
              autoCapitalize="none"
              autoCorrect={false}
              name="newPassword"
              label={t('newPasswordLabelText')}
              secureTextEntry={!showNewPassword}
              textContentType="password"
              ref={newPasswordRef}
              returnKeyType="next"
              onSubmitEditing={() => {
                confirmPasswordRef?.current?.focus();
              }}
              icon={{
                testID: 'newPasswordIcon',
                name: showNewPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={() => {
                setShowNewPassword(!showNewPassword);
              }}
              keyboardType={Platform.select({ android: 'default' })}
            />
            <FormField
              testID="confirmPassword"
              autoCapitalize="none"
              autoCorrect={false}
              name="confirmPassword"
              label={t('confirmPasswordLabelText')}
              secureTextEntry={!showConfirmPassword}
              textContentType="password"
              ref={confirmPasswordRef}
              returnKeyType="go"
              icon={{
                testID: 'confirmPasswordIcon',
                name: showConfirmPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              keyboardType={Platform.select({ android: 'default' })}
            />
            <SubmitButton title="Update" isButtonLoading={loadingButton} testID="changePwdUpdateBtn" />
          </Form>
        </ScrollView>
      </KeyboardAvoidingScreen>
      {showChangePasswordSuccessfullModal && showChangePasswordSuccessModal()}
    </Screen>
  );
};

const styles = StyleSheet.create({
  errorText: {
    textAlign: 'center',
    padding: 16,
  },
  textAlignCenter: { textAlign: 'center' },
  alignItemFlex: { alignItems: 'flex-start' },
});

export default ChangePasswordScreen;

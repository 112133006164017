import { BottomSheetBackdropProps } from '@gorhom/bottom-sheet';
import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from 'react-native-reanimated';
type CustomBackdropProps = Pick<BottomSheetBackdropProps, 'animatedIndex' | 'style'> & { onPress?: (event: any) => void };

const CustomBackdrop = ({ animatedIndex, style, onPress }: CustomBackdropProps) => {
  // animated variables
  const containerAnimatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animatedIndex.value, [0, 1], [0, 1], Extrapolate.CLAMP),
  }));

  // styles
  const containerStyle = useMemo(
    () => [
      style,
      {
        backgroundColor: 'rgba(0,0,0,.6)',
      },
      containerAnimatedStyle,
    ],
    [style, containerAnimatedStyle],
  );

  const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);
  const more = onPress ? { onPress } : { acttiveOpacity: 1 };
  return <AnimatedTouchable {...more} style={containerStyle} />;
};

export default CustomBackdrop;

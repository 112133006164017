import TextInput from '@components/TextInput';
import { useTheme } from '@react-navigation/native';
import { appStyles } from '@styles';
import React, { forwardRef, useState } from 'react';
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, StyleProp, TextStyle, View } from 'react-native';

interface NumericTextProps extends NativeTextInputProps {
  textStyle?: StyleProp<TextStyle>;
  minNumber?: number;
  maxNumber?: number;
  initialValue?: number;
}

const NumericTextInput = forwardRef<NativeTextInput, NumericTextProps>(
  ({ style, textStyle, initialValue = 0, minNumber = 0, maxNumber = 99999, onChangeText, ...rest }, ref) => {
    const { colors } = useTheme();
    const [myNumber, setNumber] = useState<string>('' + initialValue);
    const _onChanged = (text: string) => {
      let num = text.replace(/[^0-9.]/gi, '');
      num = '' + Math.min(Math.max(Number(num), minNumber), maxNumber);
      setNumber(num);
      onChangeText && onChangeText(num);
    };
    return (
      <View style={[appStyles.quantityField, style]}>
        <TextInput
          placeholderTextColor={colors.text}
          style={[{ color: colors.text }, textStyle]}
          ref={ref}
          textAlign="center"
          keyboardType="numeric"
          onChangeText={(text) => _onChanged(text)}
          value={myNumber}
          {...rest}
        />
      </View>
    );
  },
);

export default NumericTextInput;

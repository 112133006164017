import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import routes, { AppStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { appStyles } from '@styles';
import { scale } from '@styles/constants';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Button from '../Button';

const FeedBack = () => {
  const navigation = useNavigation();

  const handleOnPress = () => {
    navigation.dispatch(StackActions.push(AppStackRoutes.WebBrowser, { url: routes.GIVE_FEEDBACK, title: 'Feedback' }));
  };

  return (
    <View style={styles.container}>
      <Text style={[appStyles.bodyLargeCenter, styles.text]}>We'd love to hear what you think!</Text>
      <Button type="secondary" size="small" title="Give Feedback" onPress={handleOnPress} buttonStyle={styles.button} />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    bottom: 30,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    backgroundColor: colors.cream,
    paddingVertical: 0,
  },
  text: {
    paddingBottom: 20,
    alignItems: 'center',
    alignSelf: 'center',
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
  },
  button: {
    minWidth: 175,
    maxHeight: Platform.select({ android: 45, default: 40 }),
    height: Platform.select({ android: 45, default: 40 }),
  },
});
export default FeedBack;

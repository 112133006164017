import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document } from '@bloomreach/spa-sdk';
import { Icon } from '@components';
import { ListItem } from '@components/lists';
import colors from '@config/colors';
import { AccountStackRoutes, AppStackRoutes, HelpCenterStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@styles/appStyles';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

const LinkListComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const navigation = useNavigation();

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.editContainer}>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { links } = document.getData();

  return (
    <View style={[styles.mainContainer]}>
      {links.map(function (d: any, index: any) {
        const title = d.linkTitle;
        const topic = page?.getContent<Document>(d.documentUrl)?.getData().name;

        return (
          <ListItem
            key={index}
            style={[styles.listItem]}
            title={title}
            titleStyle={appStyles.fontMobileSmallLeftRegular}
            onPress={() => {
              navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Account,
                params: {
                  screen: AccountStackRoutes.HelpCenter,
                  params: {
                    screen: HelpCenterStackRoutes.HelpTopicsList,
                    params: { topic: topic },
                  },
                },
              });
            }}>
            <View>
              <Icon name="arrow-right" size={20} />
            </View>
          </ListItem>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  editContainer: {
    minHeight: 100,
  },
  mainContainer: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  listItem: {
    borderBottomWidth: 0.5,
    borderColor: colors.sectionBorder,
    paddingVertical: 3,
    paddingHorizontal: 20,
  },
});

export default LinkListComponent;

import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { appStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { ForwardedRef, Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Keyboard, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../contexts';
import Icon from './Icon';
import Text from './Text';

export type PickerItem<T> = { id: string | number; text: string; value: T };

export interface PickerProps<T> {
  placeholder: string;
  options: PickerItem<T>[] | undefined;
  initialValue?: PickerItem<T>;
  headerTitle: string | JSX.Element;
  enabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  testID?: string;
  onValueChange?: (value: PickerItem<T>) => void;
  onSelect?: (value: PickerItem<T>) => void;
  onBlur?: () => void;
}

export type PickerComponent<T> = {
  focus: () => void;
  getSelected: () => PickerItem<T> | undefined;
  reset: () => void;
};

const Picker = forwardRef(
  <T,>(
    { onValueChange, onSelect, initialValue, options = [], style, textStyle, placeholder, testID, headerTitle, containerStyle, enabled = true }: PickerProps<T>,
    forwardedRef?: ForwardedRef<PickerComponent<T>>,
  ) => {
    const selectedValueRef = useRef<PickerItem<T>>();
    const [selectedVal, setSelecteVal] = useState<PickerItem<T>>();
    const scrollIndexRef = useRef<number>(0);
    const { showModal, hideModal } = useCustomModalContext();

    useImperativeHandle(forwardedRef, () => ({
      focus() {
        Keyboard.dismiss();
        hideModal();
      },
      getSelected() {
        return selectedValueRef.current;
      },
      reset() {
        setSelecteVal(undefined);
        selectedValueRef.current = undefined;
      },
    }));

    useEffect(() => {
      if (initialValue) {
        var idx = options.findIndex((o) => o.id === initialValue.id);
        scrollIndexRef.current = idx;
        selectedValueRef.current = initialValue;
        setSelecteVal(initialValue);
      }
    }, [initialValue, options]);

    const handleSelect = useCallback(
      (item: PickerItem<T>) => {
        selectedValueRef.current = item;
        hideModal();
        onValueChange && onValueChange(item);
        setTimeout(() => {
          onSelect && onSelect(item);
        }, 50);
      },
      [hideModal, onValueChange, onSelect],
    );

    const showPickerModal = () => {
      showModal({
        style: containerStyle,
        header: (
          <View style={styles.handle}>
            {headerTitle && typeof headerTitle === 'string' ? <Text style={styles.headerTitle}>{headerTitle}</Text> : headerTitle}
            <TouchableOpacity onPress={hideModal} testID="closeBtn">
              <Icon name={'x-close'} size={20} testID="closeIcon" />
            </TouchableOpacity>
          </View>
        ),
        children: (
          <View style={{ marginTop: 9 }}>
            {options?.map((item, index) => {
              return (
                <View style={[styles.quantityItemContainer, selectedValueRef?.current?.id === item.id ? styles.selected : null]} key={index.toString()}>
                  <TouchableOpacity onPress={() => handleSelect(item)}>
                    <Text testID={'pickerItem'} numberOfLines={1} style={selectedValueRef?.current?.id === item.id ? styles.selectedOption : styles.option}>
                      {item.text}
                    </Text>
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
        ),
        buttonContainerStyle: { display: 'none' },
        contentStyle: { width: screenWidth, marginTop: -16 },
      });
    };

    return (
      <>
        <TouchableOpacity
          onPress={() => {
            Keyboard.dismiss();
            showPickerModal();
          }}
          activeOpacity={1}
          disabled={!enabled}
          style={[styles.pickerWrapper, style, { opacity: enabled ? 1 : 0.5 }]}
          testID={testID}>
          <Text numberOfLines={1} style={[appStyles.h5L, textStyle, { maxWidth: '85%' }]} testID={'pickerText'}>
            {selectedValueRef?.current?.text ?? (selectedVal?.text || placeholder)}
          </Text>
          <Icon name={'triangle-down'} fill={colors.darkText} size={20} testID="triangleDown" />
        </TouchableOpacity>
      </>
    );
  },
) as <T>(p: PickerProps<T> & { ref?: Ref<PickerComponent<T>> }) => JSX.Element | null;

export default Picker;

const styles = StyleSheet.create({
  pickerWrapper: {
    width: '100%',
    height: 50,
    backgroundColor: colors.white,
    borderRadius: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingRight: 20,
    marginBottom: 5,
  },
  headerTitle: {
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    lineHeight: lineHeight(28),
  },
  option: {
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(28),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  handle: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  selectedOption: {
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(28),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: colors.darkCream,
  },
  quantityItemContainer: {
    width: '100%',
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
});

import { Icon, LoadingScreen, Modal, Picker, RadioButton } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { customizeService } from '@services/commerceTools';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import { filterUnavailableProducts } from '@utils/helpers';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { ChosenItem } from '../../../models/customize';

interface FillingScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Filling> {
  sizes?: ChosenItem[];
}

interface FillingSelectionProps {
  item: Product;
  limit: number;
  index?: number | string;
  selectedItems: ChosenItem[];
  itemCount: number;
  setSelectedItems: Dispatch<SetStateAction<ChosenItem[]>>;
  setItemCount: Dispatch<SetStateAction<number>>;
}

const FillingSelection: FC<FillingSelectionProps> = ({ selectedItems, item, itemCount, setItemCount, setSelectedItems }): JSX.Element => {
  const setSelection = () => {
    var price: number | null = null;
    var priceDigits: number | null = null;
    if (item?.masterData?.current?.masterVariant?.price) {
      price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
      priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
    }
    if (selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)) {
      setSelectedItems(selectedItems.filter((selection) => selection.itemName !== item?.masterData?.current?.name));
      setItemCount(itemCount - 1);
    } else {
      setSelectedItems([
        {
          id: item?.id,
          sku: item?.masterData?.current?.masterVariant?.sku ?? '',
          itemName: item?.masterData?.current?.name ?? '',
          cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
        },
      ]);
      setItemCount(itemCount + 1);
    }
  };

  return (
    <View style={styles.sizeItemContainer}>
      <TouchableOpacity activeOpacity={0.7} style={styles.checkButton} onPress={setSelection}>
        {selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name) ? (
          <Icon
            testID="checkedCircleIcon"
            name="checked-circle"
            fill={colors.secondary}
            stroke={'none'}
            strokeSecondary={colors.white}
            fillSecondary={'none'}
            style={appStyles.mediumIcon}
            size={25}
          />
        ) : (
          <Icon name="unchecked-circle" fill={colors.white} stroke={colors.darkText} style={appStyles.mediumIcon} size={25} />
        )}
      </TouchableOpacity>
      <View style={styles.sizeInfoTextWrapper}>
        <TouchableOpacity activeOpacity={0.7} onPress={setSelection}>
          <Text
            testID="fillingItemName"
            numberOfLines={2}
            style={
              selectedItems?.some((selection) => selection.itemName.split('-')[0].trim() === item?.masterData?.current?.name)
                ? appStyles.customizeLabelSmBold
                : appStyles.customizeLabelSm
            }>
            {item?.masterData?.current?.name}
          </Text>
          {/* <Text style={styles.servesText}>serves {item.description[`${locale}`]}</Text> */}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const FillingScreen: FC<FillingScreenProps> = ({ route }): JSX.Element => {
  const navigation = useNavigation();
  const { pageItem = '', catIds, limit } = route.params;
  const { selectedIngredients, setSelectedIngredients, calculateSubtotal, currStep, selectedSize } = useCustomizeStore();
  const [selectedItems, setSelectedItems] = useState<ChosenItem[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [showInfo, setShowInfo] = useState(false);
  const [fillingApplication, setFillingApplication] = useState('');
  // const [choices, setChoices] = useState<Product[]>([]);
  const [scrollIndicatorVisible, setScrollIndicatorVisible] = useState(false);
  const {
    data: choices,
    status,
    refetch,
  } = useQuery('filling-selections-list', () => customizeService.getNonsellableProducts({ catId: catIds }), {
    staleTime: 0,
    cacheTime: 0,
    initialData: undefined,
    select: (d) => {
      d?.sort(
        (a, b) =>
          +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds[0])?.orderHint || 0.8) -
          +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds[0])?.orderHint || 0.8),
      );
      return filterUnavailableProducts(d);
    },
  });
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    if (status === 'success') {
      if ((choices?.length ?? 0) > 4) {
        setScrollIndicatorVisible(true);
      }
      setIsLoadingProducts(false);
    } else {
      setIsLoadingProducts(true);
    }
  }, [choices, status]);

  useEffect(() => {
    refetch();
    setSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItem]);

  useEffect(() => {
    //!selectedItems[0] || itemCount < (fillingApplication === 'Split' ? 2 : 1) ? true : false
    var forwardEnabled = !selectedItems[0] || itemCount < (fillingApplication === 'Split' ? 2 : 1) ? false : true;
    navigation.setParams({ forwardNavEnabled: forwardEnabled });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCount, pageItem, currStep, fillingApplication]);

  //Sets the local selectedItems
  const setSelected = () => {
    if (selectedIngredients.length > 0) {
      // console.log('setting', JSON.stringify(selectedIngredients, null, 3));
      if (selectedIngredients.some((ingr) => ingr.ingredientName === pageItem)) {
        var tempArr = selectedIngredients;
        var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

        if (tempArr[ingredientIndex].selections.length === 2) {
          setFillingApplication('Split');
        } else if (tempArr[ingredientIndex].selections.length === 1) {
          setFillingApplication('Whole');
        }

        if (tempArr[ingredientIndex].selections.length === 2 && selectedSize.itemName.includes('Quarter')) {
          tempArr[ingredientIndex].selections.pop();
        }

        setSelectedItems(tempArr[ingredientIndex].selections);

        setItemCount(tempArr[ingredientIndex].selections.length);
      }
    }
  };

  useEffect(() => {
    if (selectedIngredients.some((ing) => ing.ingredientName === pageItem)) {
      var tempArr = selectedIngredients;
      var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

      tempArr[ingredientIndex] = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients(tempArr);
      // console.log('set tsx.239 selectionscreen: ', JSON.stringify(selectedIngredients));
    } else if (selectedItems.length) {
      var newIngredient = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients([...selectedIngredients, newIngredient]);
    } else if (selectedItems.length === 0) {
      var tempArr = selectedIngredients;
      var filteredArr = tempArr.filter((ingr) => ingr.ingredientName !== pageItem);

      setSelectedIngredients(filteredArr);
    }
    calculateSubtotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const firstPickerFlavorChange = (flavor: any) => {
    var tempSelected = selectedItems;
    var newItem = {
      id: flavor.id,
      sku: flavor?.masterData?.current?.masterVariant?.sku,
      itemName: flavor?.masterData?.current?.name,
      cost: flavor?.masterData?.current?.masterVariant?.price
        ? flavor?.masterData?.current?.masterVariant?.price?.value?.centAmount ** flavor?.masterVariant?.price?.value?.fractionDigits
        : 0,
    };
    tempSelected[0] = newItem;
    setItemCount(tempSelected.length);
    setSelectedItems(tempSelected);
    setIngredients();
  };

  const secondPickerFlavorChange = (flavor: any) => {
    var tempSelected = selectedItems;

    var newItem = {
      id: flavor.id,
      sku: flavor?.masterData?.current?.masterVariant?.sku,
      itemName: flavor?.masterData?.current?.name,
      cost: flavor?.masterData?.current?.masterVariant?.price
        ? flavor?.masterData?.current?.masterVariant?.price?.value?.centAmount ** flavor?.masterVariant?.price?.value?.fractionDigits
        : 0,
    };
    tempSelected[1] = newItem;
    setItemCount(tempSelected.length);
    setSelectedItems(tempSelected);
    setIngredients();
  };

  const setIngredients = () => {
    if (selectedIngredients.some((ing) => ing.ingredientName === pageItem)) {
      var tempArr = selectedIngredients;
      var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

      tempArr[ingredientIndex] = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients(tempArr);
    } else if (selectedItems.length) {
      var newIngredient = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients([...selectedIngredients, newIngredient]);
    } else if (selectedItems.length === 0) {
      var tempArr = selectedIngredients;
      var filteredArr = tempArr.filter((ingr) => ingr.ingredientName !== pageItem);

      setSelectedIngredients(filteredArr);
    }
    setItemCount(selectedItems.length);
    calculateSubtotal();
  };

  const switchApplicationType = (type: string) => {
    setFillingApplication(type);
    if (type === 'Whole' && selectedItems.length > 1) {
      var tempArr = selectedItems;
      tempArr.pop();
      setSelectedItems(tempArr);
    }
  };

  const FlavorDescriptionLink = () => {
    return (
      <TouchableOpacity onPress={() => setShowInfo(true)}>
        <Text style={[appStyles.fontShowMoreLink, styles.flavorDescriptionLink]}>Filling Descriptions</Text>
      </TouchableOpacity>
    );
  };

  return isLoadingProducts ? (
    <LoadingScreen />
  ) : (
    <>
      <Modal
        style={{ maxHeight: screenHeight * 0.8, padding: 0, flexGrow: 1 }}
        contentStyle={styles.modalContent}
        title={'Filling Descriptions'}
        titleStyle={{ lineHeight: lineHeight(15), paddingTop: 10 }}
        headerStyle={{ height: 30, alignItems: 'center', justifyContent: 'center' }}
        location={'top'}
        visible={showInfo}
        okButtonStyle={[appStyles.secondaryButtonSmall, { width: 130, bottom: 0 }]}
        buttonContainerStyle={{ alignItems: 'center', marginVertical: 0, height: 80 }}
        cancelButtonType={'secondary'}
        cancelButtonOnPress={() => setShowInfo(false)}
        cancelButtonText={'Close'}
        showScrollHint={(choices?.length ?? 0) > 4 ? true : false}>
        <View style={{ width: screenWidth, paddingHorizontal: 8 }}>
          <View
            // onScroll={() => {
            //   if (scrollIndicatorVisible) {
            //     setScrollIndicatorVisible(false);
            //   }
            // }}
            // scrollEventThrottle={0}
            // persistentScrollbar={true}
            style={styles.modalScrollView}>
            {choices?.map((flavor, index) => {
              return (
                <View style={[styles.modalText, index === 0 ? { marginTop: 0 } : null]} key={index.toString()}>
                  <Text testID="flavorName" style={[appStyles.bodyLeftBold]}>
                    {flavor?.masterData?.current?.name}
                  </Text>
                  <Text testID="flavorDescription" style={appStyles.bodyLargeLeft}>
                    {flavor?.masterData?.current?.masterVariant.attributesRaw.find((a) => a.name === 'longDescription')?.value || ''}
                  </Text>
                </View>
              );
            })}
          </View>
          {/* {scrollIndicatorVisible ? (
            <View style={styles.scrollIndicator}>
              <Icon size={18} name="triangle-down" fill={colors.red} stroke={colors.red} />
            </View>
          ) : null} */}
        </View>
      </Modal>
      <View style={styles.container}>
        <ScrollView bounces={false} style={styles.body}>
          {!selectedSize.itemName.includes('Quarter') ? (
            <>
              <Text testID="selectFillingText" style={[styles.selectText, utilityStyles.px1]}>
                Select Filling <Text style={[appStyles.bodySmallLight]}>{`  Pick up to ${fillingApplication === 'Split' ? 2 : 1}`}</Text>
              </Text>
              <View style={[styles.secondaryBodySection]}>
                <Text testID="fillingQuestionText" style={[appStyles.h5L, styles.text]}>
                  How would you like the filling applied?
                </Text>
                <View style={styles.largeFillingChoiceWrapper}>
                  <TouchableOpacity onPress={() => switchApplicationType('Whole')} style={{ alignItems: 'center' }}>
                    <View style={[styles.wholeCake, fillingApplication === 'Whole' ? { backgroundColor: colors.red } : null]} />
                    <TouchableOpacity onPress={() => switchApplicationType('Whole')} style={styles.applicationAction}>
                      <RadioButton
                        toggle={() => switchApplicationType('Whole')}
                        containerStyle={{ width: 25, marginTop: -10 }}
                        buttonSize={25}
                        buttonStyle={[appStyles.mediumIcon]}
                        value={fillingApplication === 'Whole'}
                      />
                      <Text
                        testID="wholeCake"
                        style={[
                          appStyles.h5L,
                          utilityStyles.ml1,
                          styles.text,
                          fillingApplication === 'Whole' ? { fontFamily: FontFamily.LarsseitBold } : null,
                        ]}>
                        Whole Cake
                      </Text>
                    </TouchableOpacity>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => switchApplicationType('Split')} style={{ alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row' }}>
                      <View style={[styles.splitCake, fillingApplication === 'Split' ? { backgroundColor: colors.red } : null]} />
                      <View style={[styles.splitCake, fillingApplication === 'Split' ? { backgroundColor: colors.red } : null]} />
                    </View>
                    <View style={styles.applicationAction}>
                      <RadioButton
                        toggle={() => switchApplicationType('Split')}
                        containerStyle={{ width: 25, marginTop: -10 }}
                        buttonSize={25}
                        buttonStyle={[appStyles.mediumIcon]}
                        value={fillingApplication === 'Split'}
                      />
                      <Text
                        testID="splitTwoChoicesTxt"
                        style={[
                          appStyles.h5L,
                          utilityStyles.ml1,
                          styles.text,
                          fillingApplication === 'Split' ? { fontFamily: FontFamily.LarsseitBold } : null,
                        ]}>
                        Split (2 choices)
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <FlavorDescriptionLink />
                {fillingApplication ? (
                  <View>
                    <Text testID="fillingFlavor" style={[appStyles.h5L, styles.text, { marginBottom: 2, marginLeft: 16, marginTop: 15 }]}>
                      {fillingApplication === 'Whole' ? 'Single' : 'First'} Filling Flavor
                    </Text>
                    <Picker
                      initialValue={selectedItems[0]?.itemName}
                      placeholder={'Select'}
                      options={choices}
                      onChange={firstPickerFlavorChange}
                      headerTitle={fillingApplication === 'Whole' ? 'Single Filling Flavor' : 'First Filling Flavor'}
                      pickerStyle={{ width: screenWidth * 0.85, marginBottom: 30 }}
                      pickerTextStyle={{ fontSize: scale(18) }}
                    />
                  </View>
                ) : null}
                {fillingApplication === 'Split' ? (
                  <View style={{ marginBottom: 50 }}>
                    <Text testID="secondFillingFlavor" style={[appStyles.h5L, styles.text, { marginBottom: 2, marginLeft: 16 }]}>
                      Second Filling Flavor
                    </Text>
                    <Picker
                      initialValue={selectedItems[1]?.itemName}
                      placeholder={'Select'}
                      options={choices}
                      onChange={secondPickerFlavorChange}
                      headerTitle={fillingApplication === 'Split' ? 'Second Filling Flavor' : ''}
                      pickerStyle={{ width: screenWidth * 0.85, marginBottom: 30 }}
                      pickerTextStyle={{ fontSize: scale(18) }}
                    />
                  </View>
                ) : null}
              </View>
            </>
          ) : (
            <View style={styles.bodySection}>
              <Text testID="chooseYourSizeText" style={styles.chooseYourSizeText}>
                Choose Your Filling
              </Text>
              <View style={styles.listContainer}>
                <FlavorDescriptionLink />
                {choices?.map((choice) => {
                  return (
                    <FillingSelection
                      setSelectedItems={setSelectedItems}
                      selectedItems={selectedItems}
                      limit={limit ? +limit : 0}
                      itemCount={itemCount}
                      setItemCount={setItemCount}
                      key={choice?.masterData?.current?.name}
                      item={choice}
                    />
                  );
                })}
              </View>
            </View>
          )}
        </ScrollView>
        <View style={styles.footerWrapper}>
          <CustomizeFooter disabled={!selectedItems[0] || itemCount < (fillingApplication === 'Split' ? 2 : 1) ? true : false} />
        </View>
      </View>
    </>
  );
};
//disabled={!selectedItems[0] || fillingApplication === 'Whole' ? itemCount < min : itemCount < limit ? true : false}
const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    paddingVertical: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
  },
  scrollIndicator: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  body: {
    flex: 0.65,
    width: '100%',
    paddingHorizontal: 16,
    paddingTop:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.01 : screenHeight * 0.01) : screenHeight > 640 ? screenHeight * 0.01 : screenHeight * 0.01,
  },
  bodySection: {
    backgroundColor: colors.white,
    width: '95%',
    alignSelf: 'center',
    borderRadius: 20,
    paddingHorizontal: 25,
    paddingTop: 25,
    marginBottom: 50,
    // overflow: 'scroll',
  },
  secondaryBodySection: {
    width: '100%',
    paddingHorizontal: 15,
    paddingTop: 15,
    alignItems: 'center',
    // overflow: 'scroll',
  },
  applicationAction: {
    marginTop: 10,
    flexDirection: 'row',
  },
  sizeText: {
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 12,
  },
  chooseYourSizeText: {
    fontSize: scale(20),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 30,
  },
  listContainer: {
    width: '100%',
    paddingBottom: 0,
  },
  contentContainer: {
    justifyContent: 'space-between',
  },
  sizeItemContainer: {
    width: '100%',
    minHeight: screenHeight * 0.02,
    flexGrow: 1,
    // alignItems: 'center',
    flexDirection: 'row',
    marginVertical: screenHeight > 740 ? -3 : -5,
    // backgroundColor: 'red',
  },
  sizeInfoTextWrapper: {
    marginLeft: 5,
    marginTop: 18,
    width: '80%',
    height: 50,
  },
  itemSizeText: {
    fontSize: scale(18),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    lineHeight: lineHeight(18),
  },
  servesText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit',
    color: colors.darkText,
  },
  checkButton: {
    marginRight: 16,
    justifyContent: 'center',
    marginBottom: 12,
  },
  footerWrapper: {
    paddingTop: 0,
    alignItems: 'center',
    // justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -100,
  },
  flavorDescriptionLink: {
    alignSelf: 'center',
    marginBottom: 15,
    marginTop: -5,
  },
  modalContent: {
    height: '100%',
    width: screenWidth,
    alignItems: 'flex-start',
  },
  modalScrollView: {
    // minHeight: screenHeight * 0.5,
    flexGrow: 1,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 25,
  },
  modalText: {
    marginVertical: 10,
  },
  selectText: {
    fontSize: scale(20.5),
    fontFamily: FontFamily.AleoBold,
    color: colors.darkText,
    marginBottom: 20,
  },
  largeFillingChoiceWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 30,
  },
  text: {
    fontSize: scale(18),
    marginBottom: 10,
  },
  wholeCake: {
    width: 50,
    height: 35,
    marginBottom: 3,
    backgroundColor: colors.navBorder,
  },
  splitCake: {
    width: 25,
    height: 35,
    marginBottom: 3,
    marginHorizontal: 1,
    backgroundColor: colors.navBorder,
  },
});

export default FillingScreen;

import { appStyles, utilityStyles } from '@styles';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { LayoutAnimation, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon from '../Icon';
import Text from '../Text';

type AccordionListItemProps = {
  titleStyle?: StyleProp<TextStyle>;
  title: string | JSX.Element;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  itemContainerStyle?: StyleProp<ViewStyle>;
  collapsible?: boolean;
  expanded?: boolean;
};

const AccordionListItem = ({
  style,
  containerStyle,
  title,
  titleStyle,
  collapsible = true,
  expanded = false,
  children,
  itemContainerStyle,
}: PropsWithChildren<AccordionListItemProps>) => {
  const [isExpanded, setIsExpanded] = useState(!expanded);

  const toggleListItem = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.header, style]}>
        {typeof title !== 'string' ? title : <Text style={[appStyles.bodyLeftBold, titleStyle]}>{title}</Text>}
        {collapsible && (
          <TouchableOpacity style={utilityStyles.mr3} onPress={toggleListItem} hitSlop={{ bottom: 15, left: 15, right: 15, top: 15 }}>
            <Icon name={!isExpanded ? 'arrow-down' : 'arrow-up'} style={appStyles.icon} />
          </TouchableOpacity>
        )}
      </View>
      {isExpanded && <View style={[styles.body, itemContainerStyle]}>{children}</View>}
    </View>
  );
};
export default AccordionListItem;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    top: 0,
  },
  header: {
    paddingHorizontal: 16,
    height: 56,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  body: {
    paddingHorizontal: 12,
  },
});

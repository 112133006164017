import colors from '@config/colors';
import { appStyles } from '@styles';
import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, TouchableHighlight, View, ViewStyle } from 'react-native';
import Text from './../Text';

type ListItemSingleRowProps = {
  title: string;
  style?: StyleProp<ViewStyle>;
  onPress?: (event: any) => void;
  chidren?: JSX.Element;
  testID?: string;
};

const ListItemSingleRow = ({ title, style, onPress, children, testID = '' }: PropsWithChildren<ListItemSingleRowProps>) => {
  return (
    <View style={[styles.container, style]} testID={testID}>
      <TouchableHighlight underlayColor={colors.light} onPress={onPress} style={styles.titleContainer}>
        <Text style={appStyles.fontMobileSmallLeftRegular} numberOfLines={1}>
          {title}
        </Text>
      </TouchableHighlight>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#e2deda',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
  },
  titleContainer: {
    paddingVertical: 5,
    flex: 1,
  },
  title: {
    marginLeft: 10,
  },
});

export default ListItemSingleRow;

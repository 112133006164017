import { appStyles } from '@styles';
import { useField } from 'formik';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import RadioButton, { RadioButtonProps } from '../RadioButton';
import ErrorMessage from './ErrorMessage';

interface FormRadioButtonProps extends Omit<RadioButtonProps, 'toggle'> {
  name: string;
  reserveSpace?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
}

const FormRadioButton: React.FC<FormRadioButtonProps> = ({ name, reserveSpace = false, containerStyle, ...otherProps }) => {
  const [field, meta, helpers] = useField<any>(name);
  return (
    <View style={[appStyles.mt10, containerStyle]}>
      <View>
        <RadioButton toggle={helpers.setValue} value={field.value} {...otherProps} />
      </View>
      {meta.touched && meta.error ? <ErrorMessage reserveSpace={reserveSpace} error={meta.error} visible={meta.touched} /> : <></>}
    </View>
  );
};

export default FormRadioButton;

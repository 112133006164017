import { LoadingScreen } from '@components';
import useCartValidation from '@hooks/useCartValidation';
import { CheckoutStackRoutes, CheckoutStackScreenProps } from '@navigation/routes';
import React from 'react';
import CartScreen from './CartScreen';
import CartValidaionScreen from './CartValidationScreen';

type CartLandingScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.CartLanding>;

const CartLandingScreen = ({ route }: CartLandingScreenProps) => {
  const { storeNumber } = route.params;
  const { isValidating, result } = useCartValidation(storeNumber);

  return isValidating ? <LoadingScreen /> : result && !result.isValid ? <CartValidaionScreen {...route.params} validationResult={result} /> : <CartScreen />;
};

export default CartLandingScreen;

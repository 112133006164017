import { Formik, FormikConfig } from 'formik';
import React from 'react';

const AppForm: React.FC<FormikConfig<any>> = ({ initialValues, onSubmit, onReset, validationSchema, children, validateOnChange, validateOnBlur, innerRef }) => {
  return (
    <Formik
      innerRef={innerRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}>
      {() => {
        return <>{children}</>;
      }}
    </Formik>
  );
};

export default AppForm;

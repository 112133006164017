import { Configuration, initialize, Page } from '@bloomreach/spa-sdk';
import getBrxmConfiguration from '@config/bloomreach/configuration';
import { useRoute } from '@react-navigation/core';
import { useLinkBuilder } from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';

const useBrxmPage = () => {
  const [state, setState] = useState<{ brxmPage: Page; brxmConfiguration: Configuration }>();

  const route = useRoute();
  const buildLink = useLinkBuilder();
  const path = buildLink(route.name, route.params);

  const loadPageAsync = useCallback(async (): Promise<void> => {
    const config = getBrxmConfiguration(path);
    const page = await initialize(config);
    setState({ brxmPage: page, brxmConfiguration: config });
  }, [path]);

  const reloadPageAsync = async (): Promise<void> => {
    await loadPageAsync();
  };

  useEffect(() => {
    loadPageAsync();
  }, [loadPageAsync]);

  return { ...state, reloadPageAsync };
};

export default useBrxmPage;

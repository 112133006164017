import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { ShoppingList } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';
import ListOptions from './ListOptions';

type ListHeaderProps = {
  listName?: string;
  count?: number;
  listData?: ShoppingList | undefined;
};

const ListHeader: FC<ListHeaderProps> = ({ listName = '', count = 0, listData }) => {
  const navigation = useNavigation();
  const { hideModal, showModal } = useCustomModalContext();
  const openFilters = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: { screen: AccountStackRoutes.ListFilter },
    });
  };

  const customHandle = () => (
    <View style={styles.handle}>
      <TouchableOpacity
        testID="closeBtnClick"
        style={styles.closeButton}
        onPress={() => {
          hideModal();
        }}>
        <Icon name={'x-close'} size={20} testID="closeIcon" />
      </TouchableOpacity>
      <Text style={appStyles.h6} testID="listOption">
        List Options
      </Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]} testID="name">
        {listData?.name}
      </Text>
    </View>
  );

  const openList = () => {
    showModal({
      style: { borderTopLeftRadius: 25, borderTopRightRadius: 25, backgroundColor: colors.cream },
      header: customHandle(),
      children: (
        <>
          <ListOptions list={listData} navigation={navigation} />
        </>
      ),
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3, paddingBottom: 16, marginTop: -16 },
    });
  };

  return (
    <>
      <View style={styles.container} testID="ListHeader">
        <View style={styles.leftContainer}>
          <Text style={styles.headerText} numberOfLines={1} ellipsizeMode={'tail'} testID="CategoryName">
            {listName}
          </Text>
        </View>
        {listName && (
          <View style={styles.rightContainer}>
            <Text style={styles.countText} testID="totalItem">{`(${count} items total)`}</Text>
            <TouchableOpacity onPress={() => openFilters()} testID="sortIconClick">
              <Icon name="sort-icon" size={28} style={styles.icon} stroke={colors.darkText} testID="sortIcon" />
            </TouchableOpacity>
            <TouchableOpacity onPress={openList} testID="ellipsisIconClick">
              <Icon name={'ellipsis-icon'} size={28} style={[styles.icon, styles.iconShift]} fill={colors.darkText} stroke={'none'} testID="ellipsisIcon" />
            </TouchableOpacity>
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.cream,
    paddingBottom: 4,
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    // height: 50,
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  countText: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    marginRight: 20,
  },
  leftContainer: {
    paddingLeft: 10,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    width: '75%',
    overflow: 'hidden',
  },
  headerText: {
    ...appStyles.h6,
    ...utilityStyles.pr3,
    ...utilityStyles.mr3,
    fontSize: scale(18),
    left: 5,
    width: '100%',
  },
  rightContainer: {
    flexGrow: 0.1,
    // backgroundColor: 'red',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingRight: 15,
    alignItems: 'center',
    alignContent: 'center',
  },
  icon: {
    ...appStyles.icon,
    marginRight: 20,
  },
  iconShift: {
    transform: [{ translateY: -6 }],
    marginRight: 8,
  },
});

export default ListHeader;

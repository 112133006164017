import { BrProps } from '@bloomreach/react-sdk';
import { Document, ImageSet } from '@bloomreach/spa-sdk';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { appStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import { Image } from '../image';

const ProductDetailCustomContent = ({ page, component }: BrProps) => {
  const { ProductDetailCustomContent: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const { showAlertModal } = useCustomModalContext();
  if (!document) {
    return null;
  }
  // Get the document data
  const { title, image: imageRef, subTitle, description } = document.getData();
  const image = imageRef && page.getContent<ImageSet>(imageRef);
  const categoryData = document.getData().categoryCard;

  if (categoryData) {
    for (let i = 0; i < categoryData.length; i++) {
      categoryData[i].Image = page.getContent<ImageSet>(categoryData[i].categoryImage);
      categoryData[i].ImageUrl = categoryData[i].Image?.getOriginal()?.getUrl();
    }
  }
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.fontMobileH2Left}>{title}</Text>
        {image && <Image style={styles.image} source={{ cache: 'web', uri: image?.getOriginal()?.getUrl() }} resizeMode="contain" />}
        <View style={styles.contentContainer}>
          <Text style={styles.fontMobileEyebrowLeftDarkSm}>{subTitle}</Text>
          <Text style={styles.h5}>{description}</Text>
          <View style={styles.gridBox}>
            {categoryData &&
              categoryData.map((item: any) => {
                return (
                  <View style={styles.categoryBox} key={item.id}>
                    <TouchableOpacity onPress={() => showAlertModal()}>
                      {item.ImageUrl && (
                        <View style={styles.squareImage}>
                          <Image
                            source={{
                              uri: item.ImageUrl,
                            }}
                            style={styles.catagoryImage}
                          />
                        </View>
                      )}
                      <Text style={styles.video}>Video</Text>
                      <Text style={styles.h5}>{item.categoryName}</Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
          </View>
        </View>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    height: 'auto',
    paddingHorizontal: 10,
    backgroundColor: '#F0E5DF',
    flex: 1,
  },
  imageContainer: {
    height: 500,
    width: '100%',
    paddingHorizontal: 5,
  },
  gridBox: {
    marginTop: -10,
    paddingTop: 25,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  categoryBox: {
    width: '50%',
    paddingTop: 10,
    paddingHorizontal: 5,
  },
  squareImage: {
    width: screenWidth * 0.42,
    aspectRatio: 1,
  },
  catagoryImage: {
    width: '100%',
    height: '100%',
  },
  video: {
    marginTop: 5,
    fontSize: scale(19),
    fontWeight: 'bold',
  },
  image: {
    width: screenWidth,
    height: 500,
    top: -100,
    left: -10,
  },
  h5: {
    ...appStyles.h5,
    // lineHeight: 24,
    // paddingTop: 10,
  },
  contentContainer: { paddingHorizontal: 10, top: -210 },
  fontMobileH2Left: {
    marginTop: 10,
    color: colors.text,
    fontSize: 32,
    fontFamily: FontFamily.AleoBold,
    lineHeight: 39,
    textAlign: 'left',
  },
  fontMobileEyebrowLeftDarkSm: {
    fontSize: 14,
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    letterSpacing: 0.5,
    lineHeight: 18,
    textTransform: 'uppercase',
  },
});

export default ProductDetailCustomContent;

import colors from '@config/colors';
import { useNavigation } from '@react-navigation/native';
import { useListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../contexts';
import Icon from './Icon';
import Text from './Text';
import AddToListOptions from './listManagement/AddToListOptions';

type BookmarkButtonProps = {
  wrapperStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  size?: number;
  productSku: string;
  searchText?: string;
  alertContainerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
};

const CustomHeader = () => {
  const { hideModal } = useCustomModalContext();

  return (
    <View style={styles.handle}>
      <TouchableOpacity style={styles.closeButton} onPress={hideModal}>
        <Icon testID="xCloseIcon" name={'x-close'} size={20} />
      </TouchableOpacity>
      <Text testID="addToList" style={appStyles.h6}>
        Add to List
      </Text>
      <Text testID="whichListText" style={[appStyles.bodyLargeCenter, utilityStyles.my3]}>
        Which list would you like to use?
      </Text>
    </View>
  );
};

const BookmarkButton: FC<BookmarkButtonProps> = ({ wrapperStyle, size, productSku, searchText = '', alertContainerStyle, iconStyle, disabled = false }) => {
  const navigation = useNavigation();
  const { showModal } = useCustomModalContext();
  const { isInList } = useListsStore();
  var inList = isInList(productSku);

  const showShoppingListModal = () => {
    showModal({
      style: { backgroundColor: colors.cream },
      header: <CustomHeader />,
      children: <AddToListOptions productSku={productSku} searchText={searchText} containerStyle={[alertContainerStyle]} navigation={navigation} />,
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3 },
    });
  };

  return (
    <>
      <TouchableOpacity onPress={showShoppingListModal} style={[wrapperStyle, { opacity: disabled ? 0.4 : 1.0 }]} activeOpacity={0.7} disabled={disabled}>
        <Icon
          testID="bookmarkIcon"
          name="bookmark-icon"
          stroke={colors.red}
          fill={inList ? colors.red : 'none'}
          style={[appStyles.largeIcon, iconStyle]}
          size={size ? size : 24}
        />
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  viewStyle: {
    flexDirection: 'column',
    width: screenWidth,
    alignSelf: 'center',
    paddingBottom: 0,
    paddingHorizontal: 16,
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    // height: 50,
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  noBorder: {
    borderWidth: 0,
  },
  linkContainer: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    justifyContent: 'center',
  },
  link: {
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
});

export default BookmarkButton;

import { CloseButton, Icon, LinkButton, LoadingScreen, Modal, NavigationHeader, Screen } from '@components';
import Text from '@components/Text';
import StoreMapView from '@components/store/StoreMapView';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { fontSize } from '@config/typography';
import { Coordinates, ShopType, Store } from '@fieldera-raleys/client-common/types';
import { getLastKnownLocation } from '@hooks/useLocation.web';
import { AppStackRoutes, CheckoutStackRoutes, RootTabRoutes, ShopStackRoutes, StoreFinderStackRoutes, StoreFinderStackScreenProps } from '@navigation/routes';
import { StackActions } from '@react-navigation/native';
import { storeService } from '@services/brandywine';
import { useCartStore, useShopStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { formatPhone } from '@utils/helpers';
import React, { createRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Linking, Modal as NativeModal, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import MapView from 'react-native-maps';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useQuery } from 'react-query';
import MapScreen from '../../shoppingContext/MapScreen';

type StoreFinderDetailProps = StoreFinderStackScreenProps<StoreFinderStackRoutes.StoreFinderDetail>;

const StoreFinderDetailScreen = ({ route, navigation }: StoreFinderDetailProps) => {
  const { storeNumber } = route.params ?? '';
  const { t } = useTranslation('storeFinderScreen');
  const mapRef = React.useRef<MapView>(null);
  const [showMap, setShowMap] = useState<boolean>(true);
  const { data: store, isLoading } = useQuery<Store, Error>('store', async () => {
    var locationData = await getLastKnownLocation();
    var lat = locationData?.coords.latitude;
    var lon = locationData?.coords.longitude;

    return await storeService.getStore(storeNumber!, lat, lon);
  });
  let markerArr: Store[] = store ? [store] : [];

  const {
    setSelectedStore: setSelectedLocation,
    selectedStore: selectedLocation,
    setSelectedTimeSlot,
    favorites,
    setFavorite,
    setSearchText,
    setSearchResult,
    previousDeliveryAddress,
    setDeliveryAddress,
    previousTimeSlot,
    previousStore,
    setSelectedShopType,
    previousShopType,
    setPreviousShopType,
    setSelectedStore,
    deliveryAddress,
    selectedTimeSlot,
    tempShopType,
    setCurrPage,
  } = useShopStore();
  const cartChangeModalRef = createRef<NativeModal>();
  const { validateCart } = useCartStore();
  const handleError = useErrorHandler();
  const [cartChangeStore, setCartChangeStore] = useState<Store | undefined>(undefined);
  const [showCartChangeModal, setShowCartChangeModal] = useState(false);

  const renderDepartmentList = (departmentName: string, index: number) => {
    return (
      <View style={styles.departmentView} key={`service-${departmentName}${index}`}>
        <Icon testID="circleCheck" name={'circle-check'} fill={colors.white} stroke={colors.green} strokeSecondary={colors.green} size={20} />
        <Text testID="departmentName" style={[appStyles.fontMobileBodySmallLight, styles.deptNameText]}>
          {departmentName}
        </Text>
      </View>
    );
  };

  const openMap = (coordinates: Coordinates) => {
    const location = `${coordinates.latitude},${coordinates.longitude}`;

    const url = Platform.select({
      ios: `maps:daddr=${location}`,
      android: `google.navigation:q=${location}`,
    });
    Linking.openURL(url || '');
  };

  const handleBrandIcon = (name: string | undefined) => {
    switch (name) {
      case 'Bel-Air':
        return (
          <Icon testID="Bel-Air" fill={colors.belAirRed} fillSecondary={colors.belAirBrown} stroke={'none'} size={75} name="belair" style={styles.logoStyle} />
        );
      case "Raley's":
        return <Icon testID="Raley" fill={colors.raleysRed} stroke={'none'} size={75} name="raleys" style={styles.logoStyle} />;
      case 'Nob Hill Foods':
        return <Icon testID="NobHillFoods" fill={colors.nobHillRed} stroke={'none'} size={100} name="nobhillfoods" style={styles.logoStyle} />;
      case 'ONE':
        return (
          <Icon
            testID="raleysonemarket"
            fillSecondary={colors.raleysOneLightGreen}
            fill={colors.raleysOneDarkGreen}
            stroke={'none'}
            size={75}
            name="raleysonemarket"
            style={styles.logoStyle}
          />
        );
      default:
        return <Icon testID="raleys" fill={colors.raleysRed} stroke={'none'} size={75} name="raleys" style={styles.logoStyle} />;
    }
  };
  const onIconNext = () => {
    return (
      store && (
        <TouchableOpacity
          testID="favIconClick"
          onPress={() => {
            setFavorite(store);
          }}>
          {favorites?.some((favorite) => favorite.number === store?.number) ? (
            <Icon testID="favIcon" name="star-icon" size={26} fill={colors.darkText} stroke={colors.darkText} />
          ) : (
            <Icon testID="unFavIcon" name="star-icon" size={26} fill={'none'} stroke={colors.darkText} />
          )}
        </TouchableOpacity>
      )
    );
  };

  const handleOnMapPress = () => {
    markerArr && setSearchResult(markerArr);
    storeNumber && setSearchText(storeNumber);
    setShowMap(!showMap);
  };

  const handleCartChange = () => {
    setShowCartChangeModal(false);
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: { storeNumber: cartChangeStore?.number, shopType: ShopType.PICKUP, deliveryAddress: undefined },
      }),
    );
    setCartChangeStore(undefined);
  };

  const handleCancelValidation = () => {
    if (previousShopType === ShopType.PICKUP) {
      if (previousStore) {
        setSelectedShopType(ShopType.PICKUP);
        setSelectedStore(previousStore);
        setSelectedTimeSlot(previousTimeSlot);
      }
      setCartChangeStore(undefined);
      setShowCartChangeModal(false);
      return;
    } else if (previousShopType === ShopType.DELIVERY) {
      setSelectedShopType(ShopType.DELIVERY);
      setSelectedLocation(selectedLocation || previousStore);
      setDeliveryAddress(deliveryAddress || previousDeliveryAddress);
      setSelectedTimeSlot(selectedTimeSlot || previousTimeSlot);
      setCurrPage('Delivery');
      return;
    }
    setCartChangeStore(undefined);
    setShowCartChangeModal(false);
  };

  const saveLocation = async () => {
    const validationResult = await validateCart(store?.number).catch((err) => handleError(err));
    if (!validationResult?.isValid) {
      setCartChangeStore(store);
      setShowCartChangeModal(true);
      return;
    } else {
      setSelectedShopType(tempShopType);
      await setSelectedLocation(store);

      if (tempShopType && tempShopType === ShopType.IN_STORE) {
        setPreviousShopType(ShopType.IN_STORE);
        setSelectedShopType(ShopType.IN_STORE);
      } else {
        setPreviousShopType(ShopType.PICKUP);
        setSelectedShopType(ShopType.PICKUP);
      }
      setDeliveryAddress(undefined);
      setSelectedTimeSlot(undefined);
      if (tempShopType && tempShopType === ShopType.IN_STORE) {
        setCurrPage('In-Store');
      } else {
        setCurrPage('TimeSlot');
      }
      navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.WeeklyAds } });
    }
  };

  return !showMap ? (
    <View style={styles.mapView}>
      <MapScreen calledFrom="storeFinderDetailPage" />
    </View>
  ) : (
    <Screen style={styles.screenBackground}>
      <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container }}>
        <NavigationHeader
          style={styles.headerStyle}
          subStyle={styles.subHeaderStyle}
          titleStyle={[appStyles.fontMobileH6, styles.alignCenter]}
          icon={<CloseButton />}
          next={onIconNext()}
        />
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <ScrollView contentContainerStyle={styles.scrollContainer} showsVerticalScrollIndicator={false}>
            {store && (
              <View style={styles.containerView}>
                <View style={styles.middleViewConatiner}>
                  <StoreMapView ref={mapRef} data={markerArr} onPress={handleOnMapPress} testID="storeDetailMap" maxZoomLevel={15} />
                </View>
                <View style={styles.barndNameView}>
                  <View style={{ width: screenWidth * 0.5 }} testID="brandView">
                    {handleBrandIcon(store.brand.name)}
                  </View>
                  {store.brand.name && (
                    <TouchableOpacity onPress={() => openMap(store.coordinates)} style={styles.directionView} testID="mapBtnClick">
                      <Text testID="directionsText" style={[appStyles.bodyXsBoldMediumGrey, styles.directionText]}>
                        {t('directionsText')}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                {store.address.street !== null && (
                  <View style={styles.addressMargin}>
                    <Text style={styles.addressText} numberOfLines={2} testID="addressStreet">
                      {`${store.address.street}`}
                    </Text>
                    <Text
                      testID="addressState"
                      style={styles.addressText}
                      numberOfLines={2}>{`${store.address.city} ${store.address.state}, ${store.address.zip}`}</Text>
                  </View>
                )}
                <View style={styles.distanceView}>
                  {store.distance !== 0 && (
                    <Text testID="distanceText" style={[appStyles.fontMobileBodyXSBoldRed, styles.distanceText]}>
                      {store.distance.toFixed(1)} miles away
                    </Text>
                  )}
                </View>
                <View style={styles.bottomBorder} />

                <View style={styles.infoView}>
                  <Text style={[appStyles.fontMobileBodySmallLight, { marginLeft: scale(35) }]} testID="checkOutMsg">
                    {t('checkOutMsg')}
                  </Text>
                  <LinkButton testID="weeklyAdText" style={[appStyles.fontMobileBodyXSBoldRed, styles.weeklyAdsText]} onPress={saveLocation}>
                    {t('weeklyAdText')}
                  </LinkButton>
                </View>
                <View style={styles.bottomBorder} />
                <View style={styles.storeHrsView}>
                  {store.phone && (
                    <View style={styles.storePhoneView}>
                      <Text style={appStyles.bodySmallBold} testID="storeHours">
                        {t('storeHours')}
                      </Text>

                      <TouchableOpacity
                        testID="phoneClick"
                        onPress={() => {
                          Linking.openURL(`tel:${store.phone}`);
                        }}
                        style={[styles.storePhoneTxt]}>
                        <Text testID="phoneText" style={[appStyles.bodySmallLeftRegular, styles.phoneText]} numberOfLines={2}>
                          {formatPhone(store.phone)}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  <Text style={styles.timeText} testID="hrsText">
                    TODAY:{' '}
                    {store?.storeHours
                      ?.replace(/between/gi, '')
                      .replace(/and/gi, '-')
                      .replace(/:00/gi, '')
                      .replace(/\s +/g, '')}
                  </Text>
                  <View style={styles.bottomBorder} />
                  {store.departments && store.departments.length > 0 && store.departments.some((i) => i.isFilterable) && (
                    <>
                      <View style={styles.serviceMarginVertical}>
                        <Text style={appStyles.bodySmallBold} testID="servicesText">
                          {t('servicesText')}
                        </Text>
                      </View>
                      {store.departments.map((dwh, index) => {
                        return dwh.displayName && dwh.isFilterable && renderDepartmentList(dwh.displayName, index);
                      })}
                    </>
                  )}
                </View>
              </View>
            )}
          </ScrollView>
        )}
      </Animated.View>
      {showCartChangeModal && (
        <Modal
          style={{ minHeight: screenHeight / 3 }}
          buttonContainerStyle={styles.buttonContainerStyle}
          headerStyle={styles.modalHeaderStyle}
          contentStyle={styles.modalContentStyle}
          ref={cartChangeModalRef}
          visible={showCartChangeModal}
          title={t('cartChangeModalTitle')}
          location="top"
          cancelButtonOnPress={handleCancelValidation}
          okButtonOnPress={handleCartChange}
          cancelButtonText="Cancel"
          okButtonText="Continue">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} allowFontScaling={false} testID="showCartChangeModalText">
            {t('cartChangeModalText')}
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: colors.cream,
  },
  container: {
    backgroundColor: colors.cream,
    justifyContent: 'center',
    alignContent: 'center',
  },
  scrollContainer: {
    width: screenWidth,
    paddingBottom: screenHeight * 0.09,
  },
  containerView: {
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 13,
    marginHorizontal: 12,
    flex: 1,
  },
  headerStyle: {
    borderBottomColor: '#B4B4B4',
  },
  storeLogo: {
    borderWidth: 1,
    borderColor: 'pink',
  },
  serviceMarginVertical: {
    marginBottom: 20,
    marginTop: 10,
  },
  logoStyle: {
    width: '70%',
    height: 30,
    justifyContent: 'center',
  },
  storeHrsView: {
    width: containerWidth,
  },
  subHeaderStyle: {
    backgroundColor: colors.cream,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  bottomBorder: {
    borderBottomWidth: 0.5,
    borderColor: colors.lineSeparator,
    marginVertical: 20,
  },
  middleViewConatiner: {
    height: scale(150),
    width: screenWidth * 0.925,
    marginVertical: 20,
    borderRadius: 6,
  },
  storePhoneTxt: {
    height: 30,
    paddingTop: 1,
  },
  departmentView: {
    flexDirection: 'row',
    marginVertical: scale(10),
  },
  deptNameText: {
    marginHorizontal: 10,
    textDecorationLine: 'underline',
  },
  alignCenter: { alignSelf: 'center' },
  barndNameView: {
    width: screenWidth * 0.9,
    flexDirection: 'row',
    height: 32,
    marginVertical: 7,
  },
  directionView: {
    width: screenWidth * 0.43,
    justifyContent: 'flex-start',
  },
  directionText: {
    textDecorationLine: 'underline',
    textAlign: 'right',
  },
  addressMargin: { marginVertical: 10 },
  addressText: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(18),
    color: colors.darkText,
  },
  distanceView: {
    width: screenWidth * 0.925,
    flexDirection: 'row',
  },
  shopStoreText: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitLight,
    marginHorizontal: scale(50),
    marginLeft: 30,
  },
  infoView: {
    width: screenWidth * 0.94,
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    marginHorizontal: scale(50),
  },
  weeklyAdsText: {
    textAlign: 'right',
    marginHorizontal: scale(36),
  },
  storePhoneView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: screenWidth * 0.929,
  },
  phoneText: {
    fontSize: fontSize.TertiaryText,
    textDecorationLine: 'underline',
  },
  timeText: {
    fontSize: 12,
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
  },
  mapView: {
    marginTop: screenHeight > 844 ? scale(70) : screenHeight > 811 ? scale(80) : scale(60),
    width: screenWidth,
    height: screenHeight * 0.8,
  },
  distanceText: {
    color: colors.text,
    paddingTop: 2,
  },
  modalHeaderStyle: {
    justifyContent: 'center',
  },
  buttonContainerStyle: {
    bottom: screenHeight > 740 ? 30 : 15,
  },
  modalContentStyle: {
    width: screenWidth,
    paddingHorizontal: 20,
    marginTop: 20,
  },
});

export default StoreFinderDetailScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import appStyles from '@styles/appStyles';
import React from 'react';
import { ActivityIndicator, StyleProp, TextStyle, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from 'react-native';

export interface ButtonProps extends Omit<TouchableOpacityProps, 'style' | 'onPress'> {
  onPress: ((event: any) => void) | undefined;
  type?: 'primary' | 'secondary';
  title: string;
  isButtonLoading?: boolean;
  textStyle?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  size?: 'regular' | 'small';
  rDecore?: string | JSX.Element | null;
  lDecore?: string | JSX.Element | null;
  testID?: string;
}

const Button = ({
  title,
  onPress,
  textStyle,
  isButtonLoading = false,
  type = 'primary',
  disabled = false,
  buttonStyle = null,
  rDecore = null,
  size = 'regular',
  lDecore = null,
  testID,
}: ButtonProps) => {
  let btnStyle: StyleProp<ViewStyle>[] = [size === 'small' ? appStyles.secondaryButtonSmall : appStyles.secondaryButton];
  let txtStyle: StyleProp<TextStyle>[] = [size === 'small' ? appStyles.secondaryButtonSmallText : appStyles.secondaryButtonText];
  if (type === 'primary') {
    btnStyle = [size === 'small' ? appStyles.primaryButtonSmall : appStyles.primaryButton];
    txtStyle = [size === 'small' ? appStyles.primaryButtonSmallText : appStyles.primaryButtonText];
  }

  return (
    <TouchableOpacity
      style={disabled ? [...btnStyle, buttonStyle, { opacity: 0.4 }] : [...btnStyle, buttonStyle]}
      disabled={isButtonLoading || disabled}
      onPress={onPress}>
      {!isButtonLoading ? (
        <View style={{ flexDirection: lDecore ? 'row' : 'column' }}>
          {lDecore && (
            <Text style={[...txtStyle, textStyle]} testID={testID ?? title}>
              {lDecore}
            </Text>
          )}
          <Text style={[...txtStyle, textStyle]} testID={testID ?? title}>
            {title}
            {rDecore}
          </Text>
        </View>
      ) : (
        <ActivityIndicator style={[...txtStyle, textStyle]} color={type === 'primary' ? colors.primary : colors.secondary} />
      )}
    </TouchableOpacity>
  );
};

export default Button;

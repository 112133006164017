import { Cart, CustomerContact, LineItem, ShippingAddress } from '@fieldera-raleys/client-commercetools/schema';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { offerService } from '@services/brandywine';
import useShopStore from '@store/shopStore';
import useUserProfileStore from '@store/userProfileStore';
import { getProductAttributeValue } from './productHelper';

export const getCustomerContact = (): CustomerContact | undefined => {
  const user = useUserProfileStore.getState().userProfile;
  if (!user) {
    return;
  }
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    loyaltyId: user.loyaltyId,
    sepiNumber: user.sepiNumber,
    allowText: false,
  } as CustomerContact;
};

export const getDefaultShipping = (): ShippingAddress | undefined => {
  const location = useShopStore.getState().selectedStore;
  if (!location) {
    return;
  }
  return {
    zip: location.address.zip,
    state: location.address.state,
    city: location.address.city,
  } as ShippingAddress;
};

type Prod = {
  LineItemId: string;
  ExtProductId: string;
  Quantity: number;
  Price: number;
  RegularPrice: number;
  EstimatedTotalWeight?: number;
};

const estimateLineItemWeight = (lineItem: LineItem, quantity: number): number | undefined => {
  let sellType = getProductAttributeValue('unitSellType', lineItem.variant?.attributesRaw ?? []);
  if (!sellType) {
    sellType = { key: 'byEach' };
  }
  const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', lineItem.variant?.attributesRaw ?? []);
  const unitBuyIncrement = getProductAttributeValue('unitBuyIncrement', lineItem.variant?.attributesRaw ?? []);

  const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', lineItem.variant?.attributesRaw ?? []);

  let estimatedWeight;
  if (sellType && sellType.key === 'byWeight') {
    estimatedWeight = quantity > 0 ? unitBuyIncrement * --quantity! + unitBuyMinimum : 0;
  } else if (sellType && sellType.key === 'weightByEach') {
    estimatedWeight = (unitAverageBuyWeight ?? 1) * quantity;
  }

  return estimatedWeight;
};

export const getLineItemsToEvaluateCart = (lineItems: LineItem[]): Prod[] => {
  const products: Prod[] = [];

  lineItems.length > 0 &&
    lineItems
      .filter((x) => !x?.custom?.customFieldsRaw?.find((y) => y.name === 'parentLineItemId'))
      .forEach((li) => {
        const q = li.quantity;

        const lineItemVariant = li.variant;
        const sku = lineItemVariant?.sku ?? '';
        const priceCustomValues = lineItemVariant?.price?.custom?.customFieldsRaw ?? [];

        const p = lineItemVariant?.price?.value ?? { type: 'centPrecision', currencyCode: 'USD', centAmount: 0, fractionDigits: 2 };
        const rp = priceCustomValues.length > 0 ? priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value : p;
        const ew = (li.custom?.customFieldsRaw ?? []).find((pcv) => pcv.name === 'estimatedTotalWeight')?.value ?? estimateLineItemWeight(li, q);

        products.push({
          LineItemId: li.id,
          ExtProductId: sku,
          Quantity: q,
          Price: +(Math.ceil(p.centAmount / 1) / (10 ** p.fractionDigits || 1)),
          RegularPrice: +(Math.ceil(rp.centAmount / 1) / (10 ** rp.fractionDigits || 1)),
          EstimatedTotalWeight: ew,
        });
      });

  return products;
};

export const evaluateCart = async (cart: Cart, storeNumber: string, shippingMethod: 'pickup' | 'delivery'): Promise<Promotion[]> => {
  const products = getLineItemsToEvaluateCart(cart.lineItems ?? []);
  const res = await offerService.evaluateCart('app', storeNumber, shippingMethod, products);
  // console.log(
  //   '==EVALCART:',
  //   JSON.stringify({
  //     StoreNumber: storeNumber,
  //     ShippingMethod: shippingMethod,
  //     Cart: products,
  //   }),
  //   //JSON.stringify(res),
  // );
  return res;
};

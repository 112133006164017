import appConstants from '@config/appConstants';
import {
  BaseMoney,
  Category,
  CateringStatus,
  InventoryMode,
  LineItem,
  Maybe,
  Product,
  ProductCatalogData,
  ProductData,
  ProductSearch,
  ProductVariant,
  ProductVariantAvailability,
  RawCustomField,
  RawProductAttribute,
  ShoppingListLineItem,
} from '@fieldera-raleys/client-commercetools/schema';
import { ProductType } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { productService } from '@services/commerceTools';
import dayjs, { Dayjs } from 'dayjs';
import Config from 'react-native-config';
import { JsonObject } from 'urbanairship-react-native';
import { formatValue } from './helpers';
import logger from './logger';

const DEFAULT_QUANTITY: number = 999;

export type AvailablityStatus = 'OutOfStock' | 'Unavailable' | 'LowStock' | 'Available' | 'Discontinued';
export type AvailablityResult = {
  availability: AvailablityStatus;
  quantity?: number;
  availabilityDate?: dayjs.Dayjs;
  inventoryMode?: InventoryMode;
  productSku?: string;
};

export type ProductPromotion = {
  flagHeadline: string;
  badgeType: string;
  autoApply: boolean;
  isAccepted: boolean;
  sku: string;
  extFlagTypeCode: string;
};

export interface ProductList {
  sku: string;
  product: Product;
  promotion: ProductPromotion;
}

export const getRootCategory = (categories?: Category[]) => {
  if (!categories || !categories.length) {
    return undefined;
  }
  const root = categories[0].ancestors.find((a) => !a.parent);
  return root ? categories[0].ancestors.find((r) => r.parent?.key === root?.key) : root;
};

export const getProductAisleBayBinForListManagement = (product: ProductData | LineItem | ShoppingListLineItem) => {
  var pricingAttributes: RawCustomField[] | undefined;
  if ((<ProductData>(<unknown>product)).masterVariant) {
    pricingAttributes = (<ProductData>(<unknown>product)).masterVariant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  if ((<LineItem>(<unknown>product)).variant) {
    pricingAttributes = (<LineItem>(<unknown>product))?.variant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  return pricingAttributes?.find((f) => f.name === 'areaBayShelfBin')?.value ?? undefined;
};

export const getProductSellType = async (product?: Product): Promise<JsonObject | undefined> => {
  const defaultValue = { key: 'byEach', label: 'By Each' };
  return product?.masterData.current?.masterVariant.attributesRaw
    ? getProductAttributeValue('unitSellType', product?.masterData.current?.masterVariant.attributesRaw) ?? defaultValue
    : defaultValue;
};

export const getPriceBySellType = async (product: Product, sku?: string | null, qty?: number): Promise<number | undefined> => {
  let variant = product.masterData.current?.variants.find((x) => x.sku === sku);
  if (!variant) {
    variant = product.masterData.current?.masterVariant;
  }
  if (variant) {
    const price = variant.price?.value.centAmount / 10 ** (variant.price?.value.fractionDigits ?? 2);
    const unitsPerPackage = getProductAttributeValue('unitsPerPackage', variant.attributesRaw);
    const unitAvgBuyWeight = getProductAttributeValue('unitAverageBuyWeight', variant.attributesRaw);
    const sellType = getProductAttributeValue('unitSellType', variant.attributesRaw);
    // const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', variant.attributesRaw);
    // const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', variant.attributesRaw);
    if (sellType) {
      if (!qty) {
        throw 'Quantity Needed';
      }
      switch (sellType?.key) {
        case 'weightByEach':
          return price * unitAvgBuyWeight * (qty ?? 1);
        case 'byWeight':
          return price * (qty ?? +unitsPerPackage);
        default:
          return price * (qty ?? 1);
      }
    }
  }
  return product.masterData.current?.masterVariant.price?.value.centAmount / 10 ** (product.masterData.current?.masterVariant.price?.value.fractionDigits ?? 2);
};

export const getQuantityFromPriceMessage = (priceMessage: string): number => {
  let match = /(you\s)?save\s\$\d*\.\d{2}\s(on|for)\s\d+$/i.exec(priceMessage);

  if (match) {
    const messages = priceMessage.split(' ');
    const qty = Number(messages[messages.length - 1]);

    return qty;
  }

  return 1;
};

export const getDisplayPriceBySellTypeAndPriceMessage = (sellType: string, priceMessage: string, price: BaseMoney | undefined, avgBuyWeight?: number) => {
  if (!price) {
    return undefined;
  }

  const qty = getQuantityFromPriceMessage(priceMessage);

  let final = price.centAmount / 10 ** (price.fractionDigits ?? 2);
  final = Number((final * qty).toFixed(2));

  if (sellType === 'weightByEach') {
    if (!avgBuyWeight) {
      avgBuyWeight = 1;
      logger.error('weightByEach requires the unitAverageBuyWeight');
      //throw 'weightByEach requires the unitAverageBuyWeight';
    }
    return `${qty} for $${(final * avgBuyWeight).toFixed(2)}`;
  } else {
    return `${qty} for $${final.toFixed(2)}`;
  }
};

export const getDisplayPriceBySellType = (
  sellType: string,
  price: BaseMoney | undefined,
  avgBuyWeight?: number,
  qty: number = 1,
  prefixCurrencyCode: boolean = true,
) => {
  if (!price) {
    return undefined;
  }
  let final = price.centAmount / 10 ** (price.fractionDigits ?? 2);
  final = Number((final * qty).toFixed(2));

  if (sellType === 'weightByEach') {
    if (!avgBuyWeight) {
      avgBuyWeight = 1;
      logger.error('weightByEach requires the unitAverageBuyWeight');
      //throw 'weightByEach requires the unitAverageBuyWeight';
    }
    return prefixCurrencyCode ? `$${(final * avgBuyWeight).toFixed(2)}` : (final * avgBuyWeight).toFixed(2);
  } else {
    return prefixCurrencyCode ? `$${final.toFixed(2)}` : final.toFixed(2);
  }
};

const getAvailablityStatus = (availableQuantity: number, cateringStatus?: CateringStatus): AvailablityStatus => {
  if (!cateringStatus) {
    return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= parseFloat(Config.LOW_INVENTORY_THRESHOLD ?? '0') ? 'Available' : 'LowStock';
  }

  switch (cateringStatus) {
    case 'retired':
      return 'Unavailable';
    case 'closed':
    case 'pending':
      return 'OutOfStock';
    default:
      return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= parseFloat(Config.LOW_INVENTORY_THRESHOLD ?? '0') ? 'Available' : 'LowStock';
  }
};

const getCustomField = (name: string, attributes: RawCustomField[]) => {
  return attributes.find((x: RawCustomField) => x.name === name);
};
const getCustomFieldValue = (name: string, attributes: RawCustomField[]) => getCustomField(name, attributes)?.value;

export const isAvailablePastDate = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant, orderDate?: dayjs.Dayjs): boolean => {
  if (!product || !orderDate) {
    return false;
  }

  let endAvailableDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product?.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
      ? (product.variant as ProductVariant)
      : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'endAvailableOrderDate')?.value;
    if (!val) {
      // no end date
      return true;
    }
  }

  try {
    endAvailableDate = dayjs(val);
  } catch (e) {
    logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
  }

  if (endAvailableDate) {
    const diff = endAvailableDate.diff(orderDate, 'minutes');
    if (diff > 0) {
      return true;
    }
  }

  return false;
};

export const getEndAvailabilityDate = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant): Dayjs | undefined => {
  if (!product) {
    return undefined;
  }
  let endDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
      ? (product.variant as ProductVariant)
      : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'endAvailableOrderDate')?.value;
  }

  if (val) {
    try {
      endDate = dayjs(val);
    } catch (e) {
      logger.error(['Failed to parse startAvailableOrderDate attribute:', val].join(' '));
    }
  }

  return endDate;
};

export const getfulfillmentLeadTimeMinutes = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant): number => {
  if (!product) {
    return Number(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES);
  }

  const now = dayjs();
  let availableDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product?.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
      ? (product.variant as ProductVariant)
      : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'fulfillmentLeadTimeMinutes')?.value;
  }

  let leadTime: number =
    (('productType' in product && product?.productType?.name) ?? ProductType.STANDARD) === ProductType.CONFIGURABLE
      ? Number(Config.DEFAULT_FULFILLMENT_CUSTOMIZABLE_LEAD_TIME_MINUTES)
      : Number(Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES);

  if (val) {
    try {
      leadTime = Number(val);
    } catch (e) {}
  }

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'startAvailableOrderDate')?.value;
  }

  if (val) {
    try {
      availableDate = dayjs(val);
    } catch (e) {
      logger.error(['Failed to parse startAvailableOrderDate attribute:', val].join(' '));
    }
  }
  if (availableDate) {
    const diff = availableDate.diff(now, 'minutes');
    if (diff > 0) {
      leadTime = diff;
    }
  }

  return leadTime;
};

export const getProductAttribute = (name: string, attributes: RawProductAttribute[]) => {
  return attributes.find((x) => x.name === name);
};

export const getProductAttributeValue = (name: string, attributes: RawProductAttribute[]) => getProductAttribute(name, attributes)?.value;

export const getFormattedPrice = (price: BaseMoney, qty: number = 1): string => {
  // return formatValue(price.centAmount / (10 ** price.fractionDigits || 1) / qty);
  return price && formatValue(Math.round(price.centAmount / qty) / (10 ** price.fractionDigits || 1));
};

export const getFormattedUnitPerPackage = (upp: string, uom: string) => {
  return Number(upp).toFixed(2) + ' ' + uom;
};

export const getProductNameWithUPP = (productName: Maybe<string> | undefined, unitP: string, unitM: string) => {
  const maxlimit = 25;
  let pname = productName ?? '';
  if (pname.length > maxlimit) {
    return pname.substring(0, maxlimit - 3) + '...  ' + parseFloat(unitP) + ' ' + unitM;
  } else {
    return pname + ' ' + parseFloat(unitP) + ' ' + unitM;
  }
};

export const getProductsfromCommerceTools = async (skus: string[]): Promise<Product[]> => {
  skus = skus.filter((s) => s !== undefined);
  const search: ProductSearch = { skus: skus, limit: skus.length };
  return await productService.getProducts(search);
};

export const getProductCategoriesfromCommerceTools = async (skus: string[]): Promise<Product[]> => {
  const search: ProductSearch = { skus: skus, limit: skus.length };
  return await productService.getProductCategoriesBySkus(search);
};

export const getProductAisleBayBin = (product: ProductData | LineItem | ShoppingListLineItem) => {
  var pricingAttributes: RawCustomField[] | undefined;
  if ((<ProductData>(<unknown>product)).masterVariant) {
    pricingAttributes = (<ProductData>(<unknown>product)).masterVariant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  if ((<LineItem>(<unknown>product)).variant) {
    pricingAttributes = (<LineItem>(<unknown>product))?.variant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  return pricingAttributes?.find((f) => f.name === 'areaBayShelfBin')?.value ?? undefined;
};

export const getProductAvailablity = (product?: ProductCatalogData | null | ShoppingListLineItem): AvailablityResult => {
  var result: AvailablityResult = {
    availability: 'Unavailable',
  };

  if (!product) {
    return result;
  }
  if ('current' in product && !product.published) {
    return result;
  }

  var inventoryMode: InventoryMode = InventoryMode.None;
  var discontinued: boolean = false;
  var availability: ProductVariantAvailability | undefined;
  var isCateringManagerItem: boolean | undefined;
  var cateringStatus: CateringStatus = 'open';
  var hasPriceChannel: boolean = false;
  var priceCustomFields: RawCustomField[] = [];
  var priceChannelCustomFields: RawCustomField[] = [];
  var productAttributes: RawProductAttribute[] = [];

  if ('current' in product) {
    hasPriceChannel = !!product.current?.masterVariant?.price?.channel ?? false;
    priceCustomFields = product.current?.masterVariant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = product.current?.masterVariant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = product.current?.masterVariant.attributesRaw ?? [];
    availability =
      (product.current?.masterVariant?.availability?.channels?.results?.length ?? 0) > 0
        ? product.current?.masterVariant?.availability?.channels.results[0].availability ?? undefined
        : undefined;
  } else if ('variant' in product) {
    hasPriceChannel = !!product.variant?.price?.channel ?? false;
    priceCustomFields = product.variant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = product.variant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = product.variant?.attributesRaw ?? [];
    availability =
      (product.variant?.availability?.channels?.results?.length ?? 0) > 0
        ? product.variant?.availability?.channels.results[0].availability ?? undefined
        : undefined;
  }
  isCateringManagerItem = Boolean(getProductAttributeValue('isCateringManagerItem', productAttributes) ?? false);
  cateringStatus = getCustomFieldValue('cateringStatus', priceChannelCustomFields);
  inventoryMode = getCustomFieldValue('inventoryMode', priceCustomFields) ?? InventoryMode.None;
  discontinued = Boolean(getCustomFieldValue('discontinued', priceCustomFields) ?? false);

  result.inventoryMode = inventoryMode;

  if (!hasPriceChannel || discontinued || (inventoryMode === InventoryMode.ReserveOnOrder && !availability)) {
    result.availability =
      hasPriceChannel && discontinued
        ? 'Discontinued'
        : hasPriceChannel && inventoryMode === InventoryMode.ReserveOnOrder && !availability
        ? 'OutOfStock'
        : 'Unavailable';
    return result;
  }

  const now = dayjs();
  // check if there is a Pre Order restriction
  var val = getProductAttributeValue('preorderDate', productAttributes);
  if (val) {
    try {
      var preOrder = dayjs(val);
      if (preOrder.diff(now, 'minutes') >= 0) {
        return { ...result, availabilityDate: preOrder };
      }
    } catch (e) {
      logger.error(['Failed to parse preorderDate attribute:', val].join(' '));
      return result;
    }
  }

  // skip start available day restrictions, treating it as additional lead time of in future
  val = getProductAttributeValue('endAvailableOrderDate', productAttributes);
  if (val) {
    try {
      var endAvailability = dayjs(val);
      if (endAvailability.diff(now, 'minutes') <= 0) {
        return result;
      }
    } catch (e) {
      logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
      return result;
    }
  }

  var availabilityStatus: AvailablityStatus | undefined = inventoryMode !== InventoryMode.ReserveOnOrder ? 'Available' : undefined;
  if (isCateringManagerItem) {
    availabilityStatus = getAvailablityStatus(
      inventoryMode === InventoryMode.ReserveOnOrder ? availability?.availableQuantity ?? 0 : DEFAULT_QUANTITY,
      cateringStatus,
    );
  } else if (!availabilityStatus) {
    availabilityStatus = getAvailablityStatus(availability?.availableQuantity ?? 0);
  }
  // else just check the quantity
  return {
    availability: availabilityStatus,
    quantity: inventoryMode === InventoryMode.ReserveOnOrder ? availability?.availableQuantity : DEFAULT_QUANTITY,
    inventoryMode,
  };
};

export const getProductPromotions = (allProductPromotion: { AvailablePromotions: Promotion[]; AcceptedPromotions: Promotion[] }, productlist: Product[]) => {
  const upl: ProductList[] = [];

  productlist?.map((lp) => {
    let productPromotion: ProductPromotion = {
      flagHeadline: '',
      badgeType: '',
      autoApply: false,
      isAccepted: false,
      sku: '',
      extFlagTypeCode: '',
    };

    const sku = lp.masterData.current?.masterVariant.sku ?? '';

    const avp = allProductPromotion?.AvailablePromotions?.filter((p) => p?.ProductList?.includes(sku)) ?? [];
    const acp = allProductPromotion?.AcceptedPromotions?.filter((p) => p?.ProductList?.includes(sku)) ?? [];
    const allPromotions = { AvailablePromotions: avp, AcceptedPromotions: acp };

    const flagsPromotions = allPromotions?.AvailablePromotions.filter((p) => p.AutoApply && p.ExtFlagTypeCode).sort(function (p1, p2) {
      return (
        appConstants.PRODUCT_FLAG_TYPES.indexOf(p1.ExtFlagTypeCode?.toUpperCase() ?? '') -
        appConstants.PRODUCT_FLAG_TYPES.indexOf(p2.ExtFlagTypeCode?.toUpperCase() ?? '')
      );
    });

    const availableBadgePromotions = allPromotions?.AvailablePromotions.filter((p) => p.AutoApply !== true && p.ExtBadgeTypeCode).sort(function (p1, p2) {
      return (
        appConstants.PRODUCT_BADGE_TYPES.indexOf(p1?.ExtBadgeTypeCode?.toLowerCase() ?? '') -
        appConstants.PRODUCT_BADGE_TYPES.indexOf(p2?.ExtBadgeTypeCode?.toLowerCase() ?? '')
      );
    });

    if (flagsPromotions.length > 0) {
      productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
      productPromotion.autoApply = flagsPromotions[0].AutoApply ?? true;
      productPromotion.extFlagTypeCode = flagsPromotions[0].ExtFlagTypeCode ?? '';
      productPromotion.flagHeadline = flagsPromotions[0].Headline ?? '';
      productPromotion.isAccepted = false;
    }

    if (availableBadgePromotions.length > 0) {
      productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
      productPromotion.badgeType = availableBadgePromotions[0].ExtBadgeTypeCode ?? '';
      productPromotion.isAccepted = false;
    }

    if ((productPromotion.badgeType ?? '').trim().length === 0) {
      const acceptedBadgePromotions = allPromotions?.AcceptedPromotions.filter((p) => p.ExtBadgeTypeCode).sort(function (p1, p2) {
        return (
          appConstants.PRODUCT_BADGE_TYPES.indexOf(p1.ExtBadgeTypeCode?.toLowerCase() ?? '') -
          appConstants.PRODUCT_BADGE_TYPES.indexOf(p2.ExtBadgeTypeCode?.toLowerCase() ?? '')
        );
      });

      if (acceptedBadgePromotions.length > 0) {
        productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
        productPromotion.badgeType = acceptedBadgePromotions[0].ExtBadgeTypeCode ?? '';
        productPromotion.isAccepted = true;
      }
    }

    upl.push({ sku: sku, product: lp, promotion: productPromotion });
  });

  return upl;
};

import { Address, ShopType, Store, TimeSlot } from '@fieldera-raleys/client-common';
import { userService } from '@services/brandywine';
import logger from '@utils/logger';
import { CancelToken } from 'axios';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { ShopStoreType } from './storeTypes';

const useShopStore = create<ShopStoreType>()(
  persist(
    (set, get) => ({
      selectedStore: undefined,
      selectedTimeSlot: undefined,
      previousStore: undefined,
      previousTimeSlot: undefined,
      previousDeliveryAddress: undefined,
      previousShopType: undefined,
      selectedShopType: undefined,
      tempShopType: undefined,
      favorites: [],
      previous: [],
      searchResult: [],
      searchText: '',
      currentPageTab: '',
      currPage: '',
      editAddressInfo: undefined,
      deliveryAddress: undefined,
      clearShopSettings: () => {
        const emptyShop = {
          selectedStore: undefined,
          selectedTimeSlot: undefined,
          deliveryAddress: undefined,
          selectedShopType: '',
          locationData: undefined,
          searchResult: [],
          searchText: '',
          currentPageTab: '',
        };
        set((state) => ({ ...state, emptyShop }), true);
      },
      clearShopData: () => {
        const emptyShop = {
          selectedStore: undefined,
          selectedTimeSlot: undefined,
          previousStore: undefined,
          previousTimeSlot: undefined,
          previousDeliveryAddress: undefined,
          previousShopType: undefined,
          selectedShopType: undefined,
          favorites: [],
          locationData: undefined,
          previous: [],
          searchResult: [],
          searchText: '',
          currentPageTab: '',
          currPage: '',
          editAddressInfo: undefined,
          deliveryAddress: undefined,
        };
        set((state) => ({ ...state, ...emptyShop }), true);
      },
      setShopData: () => {
        //TODO: Fetch previous and favorite stores
        return;
      },
      storePrevious: (store: Store) => {
        const current = get().previous ?? [];
        const previous = current.find((fav) => fav.number === store.number);
        try {
          if (!previous) {
            userService.addPreviouslyShoppedStore(store.number);
            current.unshift(store);
            set((state) => ({ ...state, previous: current }), true);
          }
        } catch (err) {
          logger.error(err);
        }
      },
      setFavorite: async (store: Store) => {
        var current = get().favorites ?? [];
        const favorite = current.find((fav) => fav.number === store.number);
        try {
          if (favorite) {
            await userService.deleteFavoriteStore(favorite.number);
            current = current?.filter((x) => x.number !== favorite.number);
          } else {
            await userService.addFavoriteStore(store.number);
            current.unshift(store);
          }
          set((state) => ({ ...state, favorites: current }), true);
        } catch (err) {
          logger.error(err);
        }
      },
      loadFavoriteStores: async (latitude?: number, longitude?: number, cts?: CancelToken) => {
        try {
          const favStores = await userService.getFavoriteStores(latitude, longitude, cts);
          set((state) => ({ ...state, favorites: favStores ?? [] }), true);
        } catch (error) {
          logger.error(error);
        }
      },
      loadPreviouslyShoppedStores: async (latitude?: number, longitude?: number, cts?: CancelToken) => {
        try {
          const prevStores = await userService.getPreviouslyShoppedStores(latitude, longitude, cts);
          set((state) => ({ ...state, previous: prevStores ?? [] }), true);
        } catch (error) {
          logger.error(error);
        }
      },
      setSelectedShopType: (shopType?: ShopType) => {
        // const current = get().selectedShopType;
        // const previous = !shopType && current ? current : shopType !== current ? current : shopType;
        set((state) => ({ ...state, selectedShopType: shopType }), true);
      },
      setPreviousShopType: (prevShopType?: ShopType) => {
        set((state) => ({ ...state, previousShopType: prevShopType }), true);
      },
      setTempShopType: (shopType?: ShopType) => {
        set((state) => ({ ...state, tempShopType: shopType }), true);
      },
      setSelectedStore: async (store?: Store) => {
        const current = get().selectedStore;
        const previous = !store && current ? current : get().previousStore;
        set((state) => ({ ...state, selectedStore: store, previousStore: previous }), true);
      },
      setSelectedTimeSlot: (timeSlot?: TimeSlot) => {
        const current = get().selectedTimeSlot;
        const previous = !timeSlot && current ? current : get().previousTimeSlot;
        set((state) => ({ ...state, selectedTimeSlot: timeSlot, previousTimeSlot: previous }), true);
      },
      setSearchResult: (results: Store[]) => {
        set((state) => ({ ...state, searchResult: results }), true);
      },
      setSearchText: (text: string) => {
        set((state) => ({ ...state, searchText: text }), true);
      },
      setCurrentPageTab: (currTab: string) => {
        set((state) => ({ ...state, currentPageTab: currTab }), true);
      },
      setCurrPage: (currPage: string) => {
        set((state) => ({ ...state, currPage: currPage }), true);
      },
      setEditAddressInfo: (address: Address) => {
        set((state) => ({ ...state, editAddressInfo: address }), true);
      },
      setDeliveryAddress: (address?: Address) => {
        // set((state) => ({ ...state, deliveryAddress: address }), true);
        const current = get().deliveryAddress;
        const previous = !address && current ? current : get().previousDeliveryAddress;
        set((state) => ({ ...state, deliveryAddress: address, previousDeliveryAddress: previous }), true);
      },
    }),
    {
      name: 'uc-shopContext', // unique name
      storage: createJSONStorage<ShopStoreType>(() => asyncStorage), // (optional) by default the 'localStorage' is used
    },
  ),
);

export default useShopStore;

import colors from '@config/colors';
import { screenWidth } from '@styles/constants';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface BottomBarProps extends Omit<ViewProps, 'style'> {
  useInsets?: boolean;
}

const BottomBar = ({ children, useInsets = true, ...rest }: BottomBarProps): JSX.Element => {
  const insets = useSafeAreaInsets();
  return (
    <View style={[styles.container, { paddingBottom: useInsets ? insets.bottom : 0 }]} {...rest} testID="bottomBar">
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    minHeight: 60,
    width: screenWidth,
    justifyContent: 'space-around',
    paddingHorizontal: 16,
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: colors.navBorder,
    flexDirection: 'row',
    backgroundColor: colors.white,
    bottom: 0,
    elevation: 8,
    position: 'absolute',
  },
});

export default BottomBar;

import colors from '@config/colors';
import { screenHeight, screenWidth } from '@styles/constants';
import React from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Button, { ButtonProps } from './Button';

interface SaveBarProps extends Omit<ButtonProps, 'title'> {
  title?: string;
  containerStyle?: StyleProp<ViewStyle>;
  data?: String | number | Object;
  marginBottom?: number;
  showMapSave?: boolean;
}

const SaveBar = ({ marginBottom, containerStyle, showMapSave, title = 'Save', ...rest }: SaveBarProps): JSX.Element => {
  const defaultBottom = showMapSave ? 22 : 60;
  return (
    <View style={[styles.saveBar, { bottom: Platform.OS === 'web' ? 0 : marginBottom ?? defaultBottom }, containerStyle]}>
      <Button buttonStyle={styles.saveButton} title={title} {...rest} testID="saveBarButton" />
    </View>
  );
};

const styles = StyleSheet.create({
  saveBar: {
    width: screenWidth,
    height: screenHeight * 0.09,
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#707070',
    position: 'absolute',
    // bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveButton: {
    width: screenWidth * 0.4,
    height: '70%',
    backgroundColor: colors.darkCream,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    padding: 0,
  },
});

export default SaveBar;

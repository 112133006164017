import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document } from '@bloomreach/spa-sdk';
import Icon from '@components/Icon';
import Text from '@components/Text';
import colors from '@config/colors';
import { AccountStackRoutes, AppStackRoutes, HelpCenterStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@styles/appStyles';
import { screenWidth } from '@styles/constants';
import React from 'react';
import { FlatList, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

const HelpTopicComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);
  const navigation = useNavigation();

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.editContainer}>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { topic, faqs } = document.getData();

  return (
    <FlatList
      data={faqs}
      keyExtractor={(_, index) => String(index)}
      style={[appStyles.container, styles.paddingHorizantal, { paddingTop: 20 }]}
      ListHeaderComponent={
        <Text style={[appStyles.fontMobileBodyMedium]} numberOfLines={2} testID="headerTitle">
          {topic}
        </Text>
      }
      renderItem={({ item }) => {
        const source = { html: item.faqAnswer?.value };

        return (
          <TouchableOpacity
            testID="questionClick"
            style={[styles.listItem]}
            key={item?.id}
            onPress={() => {
              return navigation.navigate(AppStackRoutes.RootTabs, {
                screen: RootTabRoutes.Account,
                params: {
                  screen: AccountStackRoutes.HelpCenter,
                  params: {
                    screen: HelpCenterStackRoutes.HelpTopicsDetailScreen,
                    params: { question: item.faqQuestion, answer: source.html },
                  },
                },
              });
            }}>
            <View style={[styles.item]}>
              <Text style={[appStyles.fontMobileSmallLeftRegular]} numberOfLines={2} testID="questionText">
                {item.faqQuestion}
              </Text>
            </View>
            <View style={{ justifyContent: 'center' }}>
              <Icon name="arrow-right" size={20} testID="arrowIcon" />
            </View>
          </TouchableOpacity>
        );
      }}
      maxToRenderPerBatch={5}
      bounces={false}
      windowSize={2}
      decelerationRate="fast"
      showsVerticalScrollIndicator={false}
    />
  );
};

const styles = StyleSheet.create({
  editContainer: {
    minHeight: 100,
  },
  listItem: {
    borderBottomWidth: 0.5,
    borderColor: colors.sectionBorder,
    paddingVertical: 14,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  paddingHorizantal: {
    paddingHorizontal: 28,
  },
  item: {
    width: screenWidth * 0.7,
  },
});

export default HelpTopicComponent;

import colors from '@config/colors';
import { appStyles } from '@styles';
import { aspectRatio, containerWidth, lineHeight, screenHeight, screenWidth } from '@styles/constants';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  editContainer: {
    minHeight: 100,
  },

  brxmCarouselContainer: {
    flex: 1,
    height: 400,
    width: screenWidth,
    alignSelf: 'center',
    minHeight: 'auto',
    maxHeight: 'auto',
  },

  defaultCarouselContainer: {
    padding: 0,
  },
  defaultSlideContainer: {
    width: screenWidth,
    height: screenHeight * 0.6,
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  defaultSlideImage: {
    position: 'relative',
    width: '100%',
    height: '60%',
  },
  defaultSlideContent: {
    height: '30%',
    paddingTop: 10,
    paddingHorizontal: 10,
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },

  imageCarouselContainer: {
    paddingBottom: 0,
    backgroundColor: colors.white,
  },

  mainContainer: {
    alignSelf: 'center',
    width: screenWidth,
    backgroundColor: colors.white,
  },

  imageSlideContainer: {
    backgroundColor: colors.white,
    paddingTop: 60,
    textAlign: 'center',
    width: screenWidth * 0.75,
    paddingHorizontal: 25,
    marginBottom: 30,
  },
  imageContentSlideContainer: {
    backgroundColor: colors.white,
    textAlign: 'center',
    width: containerWidth,
    alignSelf: 'center',
  },
  imageSlideText: {
    ...appStyles.h5,
    marginTop: 5,
    fontSize: 20,
  },
  imageContentCarouselContainer: {
    backgroundColor: 'white',
    paddingBottom: 25,
  },
  imageContentSlideContent: {
    flexDirection: 'column',
    width: '100%',
    borderColor: '#C9974E',
    height: 'auto',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  squareImage: {
    width: screenWidth * 0.65,
    aspectRatio: aspectRatio.square,
  },
  imageSlideImage: {
    width: '100%',
    height: '95%',
  },

  pagination: {
    width: containerWidth,
    height: 50,
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },

  defaultPagination: {
    width: containerWidth,
    height: 50,
    justifyContent: 'center',
    flexDirection: 'row',
    bottom: screenHeight < 750 ? '50%' : '60%',
  },

  defaultPaginationDots: {
    backgroundColor: '#ddd',
    opacity: 0.75,
    width: 10,
    height: 10,
    borderRadius: 100,
    margin: 4,
  },

  defaultPaginationDotsActive: {
    backgroundColor: 'grey',
    opacity: 1,
    width: 10,
    height: 10,
    borderRadius: 100,
    margin: 4,
  },

  carouselDotsSmall: {
    backgroundColor: 'yellow',
    opacity: 0.2,
    width: 7,
    height: 7,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
    marginTop: 3,
    marginRight: 3,
    marginBottom: 3,
    marginLeft: 3,
  },

  paginationDot: {
    backgroundColor: colors.white,
    opacity: 0.75,
    width: 9,
    height: 9,
    borderRadius: 100,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 4,
    marginLeft: 4,
  },
  paginationDotActive: {
    backgroundColor: colors.white,
    opacity: 1,
    width: 9,
    height: 9,
    borderRadius: 100,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 4,
    marginLeft: 4,
  },
  paginationDotInactive: { backgroundColor: 'grey' },

  imageContainer: {
    width: containerWidth,
    borderColor: '#C9974E',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  imageContainerBorderBottom: {
    borderBottomWidth: 1,
  },

  overlapCarouselContainer: {
    marginTop: 25,
    width: containerWidth,
    backgroundColor: colors.cream,
    minHeight: 500,
    maxHeight: 750,
  },
  overlapSlideContainer: {
    backgroundColor: colors.cream,
    marginBottom: 100,
    minHeight: 550,
    maxHeight: 750,
    paddingHorizontal: 15,
    width: containerWidth,
  },
  overlapSlideImageContainer: {},
  overlapSlideFlyer: {
    position: 'absolute',
    left: -10,
    top: -5,
    width: 150,
    height: 100,
  },
  overlapSlideImage: {
    height: 350,
    top: 20,
    left: 25,
  },
  overlapTextContainer: {
    paddingHorizontal: 20,
    paddingTop: 50,
    paddingBottom: 30,
    left: 25,
    top: -20,
    width: '87%',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
  },
  overlapCarouselPaginationDot: {
    bottom: 40,
    backgroundColor: colors.primary,
    opacity: 1.0,
    width: 7,
    height: 7,
    borderRadius: 4,
    right: 10,
    marginHorizontal: 2,
  },
  overlapCarouselPaginationDotActive: {
    bottom: 40,
    backgroundColor: colors.primary,
    opacity: 0.2,
    width: 7,
    height: 7,
    borderRadius: 4,
    right: 10,
    marginHorizontal: 2,
  },
  imagePaginationDot: {
    backgroundColor: colors.primary,
    opacity: 1.0,
    width: 7,
    height: 7,
    borderRadius: 100,
    marginHorizontal: 2,
    left: -10,
  },
  imagePaginationDotActive: {
    backgroundColor: colors.primary,
    opacity: 0.2,
    width: 7,
    height: 7,
    borderRadius: 100,
    marginHorizontal: 2,
    left: -10,
  },
  defaultpaginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 2,
    top: 100,
    left: -10,
  },
  pagerStyle: {
    marginTop: 5,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  carouselDotsSmallActive: {
    backgroundColor: colors.primary,
    opacity: 1,
    width: 7,
    height: 7,
    borderRadius: 100,
  },
  link: {
    textDecorationLine: 'none',
  },
  linkTextStyle: {
    lineHeight: lineHeight(14),
    textTransform: 'capitalize',
  },
});

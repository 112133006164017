import { CustomizeService } from '@fieldera-raleys/client-commercetools/api';
import Config from 'react-native-config';
import { getJwtToken } from '../authHelper';
import { getSelectedStoreKey } from '../helper';

const customizeService = new CustomizeService({
  apiUrl: Config.CT_API_URL,
  authToken: getJwtToken,
  selectedStoreKey: getSelectedStoreKey,
});
export default customizeService;

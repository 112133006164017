import { useTheme } from '@react-navigation/native';
import { appStyles } from '@styles';
import React from 'react';
import { Text as NativeText, TextProps } from 'react-native';

const Text = ({ children, style, allowFontScaling = false, maxFontSizeMultiplier = 1, ...rest }: TextProps) => {
  const { colors } = useTheme();
  return (
    <NativeText
      style={[appStyles.text, { color: colors.text }, style]}
      allowFontScaling={allowFontScaling}
      maxFontSizeMultiplier={maxFontSizeMultiplier}
      minimumFontScale={1}
      {...rest}>
      {children}
    </NativeText>
  );
};

export default Text;

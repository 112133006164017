import UpgradeAppModal from '@components/UpgradeModal';
import { FulfillmentStore } from '@fieldera-raleys/client-commercetools/schema';
import { Address, ShopType, Store } from '@fieldera-raleys/client-common';
import { useEffectOnce } from '@hooks';
import { getLastKnownLocation } from '@hooks/useLocation';
import { updateNamedUser } from '@hooks/useNotifications';
import { LinkingOptions, NavigationContainer, Route, useNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { AccordionListScreen, LiveAgentChatScreen, PermissionsScreen, ProductDetailsScreen, SearchFilterScreen, WebBrowserScreen } from '@screens';
import ProductCarouselListScreen from '@screens/ProductCarouselListScreen';
import { ShelfGuideDefinitionsScreen } from '@screens/shop';
import { SEOfferDetailsScreen } from '@screens/somethingExtra';
import { storeService } from '@services/brandywine';
import {
  useAnalyticsStore,
  useAppConfigStore,
  useAuthStore,
  useCartStore,
  useCommerceToolsStore,
  useDeviceInfoStore,
  useListsStore,
  useOffersStore,
  useShopStore,
  useStoreFinderFilterStore,
  useUserProfileStore,
  useUserSettingsStore,
} from '@store';
import { formatPhone } from '@utils/helpers';
import { getFulfilmentStore, getShippingAddress, getShippingMethod } from '@utils/orderHelpers';
import { Mutex } from '@utils/semaphore';
import { CancelToken } from 'apisauce';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import Config from 'react-native-config';
import { AirshipPreferenceCenter } from 'urbanairship-preference-center-react-native';
import { UrbanAirship } from 'urbanairship-react-native';
import { CustomModalProvider } from '../contexts';
import CheckoutNavigator from './CheckoutNavigator';
import CustomizeNavigator from './CustomizeNavigator';
import RootTabNavigator from './RootTabNavigator';
import { NavigationProps } from './declarations';
import { AppStackParamList, AppStackRoutes, CheckoutStackRoutes } from './routes';

const getshopType = (shop_type: string): ShopType | undefined => {
  switch (shop_type.trim().toLowerCase()) {
    case 'pickup':
      return ShopType.PICKUP;
    case 'delivery':
      return ShopType.DELIVERY;
    case 'in-store':
      return ShopType.IN_STORE;
    default:
      return undefined;
  }
};

const AppNavigation = ({ queryParams, theme, prefix }: NavigationProps) => {
  const navigationContainerRef = useNavigationContainerRef();
  const previousRouteRef = useRef<Route<string, object | undefined>>();
  const Stack = createStackNavigator<AppStackParamList>();
  const { deviceInfo } = useDeviceInfoStore();
  const { setCartData, cartData, cart, initialize: initializeCart, setShippingAddress, setTimeSlot, initializeCartContact, validateCart } = useCartStore();
  const { initialize: initializeOffers } = useOffersStore();
  const { initialize: initializeDepartments } = useStoreFinderFilterStore();
  const {
    selectedShopType,
    setDeliveryAddress,
    deliveryAddress,
    selectedStore,
    selectedTimeSlot,
    setSelectedShopType,
    setSelectedStore,
    setSelectedTimeSlot,
    loadPreviouslyShoppedStores,
    setPreviousShopType,
    setCurrPage,
    loadFavoriteStores,
  } = useShopStore();
  const { initialize: initializeLists } = useListsStore();
  const [cartInitialized, setCartInitialized] = useState(false);
  const [storeInitialized, setStoreInitialized] = useState(false);
  const { loadUserProfile, userProfile } = useUserProfileStore();
  const { isAuthenticated } = useAuthStore();
  const { initialize: initializeCommerceToolsStore, loadStore: loadCommerceToolsStore } = useCommerceToolsStore();
  const { t } = useTranslation('cart');
  const handleError = useErrorHandler();
  const { setPreviousRoute } = useAnalyticsStore();
  const { appConfig, getConfigValue, upgradeMode } = useAppConfigStore();
  const [showAppUpgradeModal, setShowAppUpgradeModal] = useState(false);
  const { userSettings, updateSetting } = useUserSettingsStore();

  useEffectOnce(() => {
    if (Platform.OS !== 'web') {
      UrbanAirship.setAutoLaunchDefaultMessageCenter(false);
      Config.AIRSHIP_PREFERENCE_CENTER_ID && AirshipPreferenceCenter.setUseCustomPreferenceCenterUi(true, Config.AIRSHIP_PREFERENCE_CENTER_ID);
      UrbanAirship.getUnreadMessageCount().then((messageCount) => {
        updateSetting('unreadMessageCount', messageCount);
        UrbanAirship.setBadgeNumber(messageCount);
      });
    }
  });

  //TODO: for optimization do we really need to load store Favorites and Previous here? can this be delayed to on access?
  useEffectOnce(() => {
    const cts = CancelToken.source();
    const init = async () => {
      if (isAuthenticated()) {
        const locationData = await getLastKnownLocation();
        await loadUserProfile();
        if (Platform.OS !== 'web') {
          updateNamedUser();
        }
        loadPreviouslyShoppedStores(locationData?.coords.latitude, locationData?.coords.longitude, cts.token);
        loadFavoriteStores(locationData?.coords.latitude, locationData?.coords.longitude, cts.token);
        initializeOffers(cts.token);
        initializeDepartments();
      }
    };

    init();
    return () => {
      cts.cancel('unmount');
    };
  });

  useEffect(() => {
    if (!cart) {
      setCartInitialized(false);
    }
  }, [cart]);

  useEffectOnce(() => {
    // Load commercetools Store
    const initialize = async () => {
      await initializeCommerceToolsStore();
      await loadCommerceToolsStore(selectedStore?.number);
      setStoreInitialized(true);
    };

    try {
      initialize();
    } catch (ex) {
      setStoreInitialized(false);
      handleError(ex);
    }
  });

  useEffect(() => {
    //initialize cart
    const initialize = async () => {
      var serverCart = await initializeCart();
      // reset shopping context from cart
      const cartShippingMethod = getShippingMethod(serverCart);
      if (cartShippingMethod && (!selectedShopType || (cartData.cartReset ?? false))) {
        if (cartData.cartReset ?? false) {
          setCartData({ cartReset: false });
        }

        const fStore = getFulfilmentStore(serverCart);
        const sAddr = getShippingAddress(serverCart);
        if (fStore) {
          const store = await storeService.getStore(fStore.number);
          setSelectedShopType(cartShippingMethod);
          setSelectedStore(store);
          if (cartShippingMethod === ShopType.PICKUP) {
            setDeliveryAddress(undefined); // store address already implide from selected store
          } else if (cartShippingMethod === ShopType.DELIVERY) {
            setDeliveryAddress(sAddr);
          } else if (cartShippingMethod === ShopType.IN_STORE) {
            setDeliveryAddress(undefined);
          }
        }
      }
      setCartInitialized(true);
    };

    if (storeInitialized && !cartInitialized) {
      try {
        initialize();
      } catch (ex) {
        setCartInitialized(false);
        handleError(ex);
      }
    }
  }, [
    storeInitialized,
    cartInitialized,
    setSelectedStore,
    initializeCart,
    handleError,
    selectedShopType,
    setSelectedShopType,
    setDeliveryAddress,
    cartData.cartReset,
    setCartData,
  ]);

  useEffect(() => {
    // update selected Store and reload favorites
    const abortController = new AbortController(); // the dom one, not the npm one
    const updateStoreAndReloadFavorites = async () => {
      const store = await loadCommerceToolsStore(selectedStore?.number);
      const priceChannelId = store?.distributionChannels.map((x) => x.id)[0];
      const availablityChannelIds = store?.supplyChannels.map((x) => x.id);
      // await initializeFavorites(priceChannelId, availablityChannelIds, abortController.signal);
      await initializeLists(priceChannelId, availablityChannelIds, abortController.signal);
      initializeOffers();
    };

    if (cartInitialized) {
      try {
        Mutex.withMutex(updateStoreAndReloadFavorites);
      } catch (ex) {
        // TODO: add logic to retry??
        handleError(ex);
      }
    }
    () => {
      abortController.abort();
    };
  }, [cartInitialized, handleError, loadCommerceToolsStore, selectedStore, initializeLists, initializeOffers]);

  useEffect(() => {
    if (!cartInitialized || !appConfig) {
      return;
    }
    // update shipping based on selected shop type and selected store
    const getStorePhone = (): string | undefined => {
      const phone =
        (selectedStore?.departments ?? []).find((x) => x && x.departmentId === Number(Config.ONLINE_DEPARTMENT_ID))?.phoneNumber ?? selectedStore?.phone;
      return phone && formatPhone(phone);
    };

    const updateShipping = async () => {
      if (selectedStore) {
        const fulfillmentStore = {
          brand: selectedStore.brand.name,
          name: selectedStore.name,
          number: selectedStore.number,
          logo: (selectedStore.brand.name ?? 'raleys').replace(/[^A-Z0-9]/gi, '').toLowerCase(),
          address: {
            address1: selectedStore.address.street,
            city: selectedStore.address.city,
            state: selectedStore.address.state,
            postalCode: selectedStore.address.zip,
            country: 'US',
            companyName: selectedStore.name,
            phone: getStorePhone(),
            email: selectedStore.email,
            addressType: 'business',
            isValidated: true,
          } as Address,
        } as FulfillmentStore;

        if (selectedShopType === ShopType.PICKUP) {
          const address = {
            address1: selectedStore.address.street,
            city: selectedStore.address.city,
            state: selectedStore.address.state,
            postalCode: selectedStore.address.zip,
            businessType: true,
            company: selectedStore.name,
            defaultAddress: false,
            phone: getStorePhone(),
            country: 'US',
            email: selectedStore.email,
            addressType: 'business',
            isValidated: true,
          } as Address;

          await setShippingAddress(address, 'pickup', fulfillmentStore, getConfigValue<string>('PickupInstructions'));
        } else if (selectedShopType === ShopType.DELIVERY) {
          await setShippingAddress(deliveryAddress, 'delivery', fulfillmentStore);
        }
      } else {
        await setShippingAddress(undefined, selectedShopType === ShopType.PICKUP ? 'pickup' : 'delivery', undefined);
      }
    };

    try {
      updateShipping();
    } catch (ex) {
      // TODO: add logic to retry??
      handleError(ex);
    }
  }, [cartInitialized, selectedShopType, deliveryAddress, selectedStore, setShippingAddress, t, handleError, appConfig, getConfigValue]);

  useEffect(() => {
    // updated timeslot in cart to selected timeslot
    if (cartInitialized) {
      setTimeSlot(selectedTimeSlot);
    }
  }, [cartInitialized, selectedTimeSlot, setTimeSlot]);

  useEffect(() => {
    // initialize Customer Contact incase one is missing
    if (cartInitialized && userProfile) {
      initializeCartContact();
    }
  }, [cartInitialized, initializeCartContact, userProfile]);

  const updateShoppingContext = useCallback(
    (shopType: ShopType, store: Store, postalCode?: string) => {
      setPreviousShopType(shopType);
      setSelectedShopType(shopType);
      setSelectedTimeSlot(undefined);
      setSelectedStore(store);
      setDeliveryAddress(shopType === ShopType.PICKUP ? undefined : store ? ({ state: store.address.state, postalCode: postalCode } as Address) : undefined);
      setCurrPage(shopType === ShopType.PICKUP ? 'FindAStore' : 'Delivery');
    },
    [setPreviousShopType, setSelectedShopType, setSelectedStore, setSelectedTimeSlot, setDeliveryAddress, setCurrPage],
  );

  useEffect(() => {
    const setShoppingContext = async (shop_type?: string, store_id?: string, postal_code?: string) => {
      if (shop_type) {
        const incomingShopType = getshopType(shop_type);
        if (!incomingShopType) {
          return;
        }
        // no context set or cart is empty
        if (!selectedStore || (selectedStore && cart?.lineItems.length === 0)) {
          if (incomingShopType === ShopType.PICKUP && store_id) {
            const store = await storeService.getStore(store_id);
            if (store) {
              updateShoppingContext(incomingShopType, store);
            }
          } else if (incomingShopType === ShopType.DELIVERY && postal_code) {
            var locationData = await getLastKnownLocation();
            const searchResults = await storeService.searchStores(0, 1, {
              shippingMethod: 'delivery',
              postalCode: postal_code,
              latitude: locationData?.coords.latitude,
              longitude: locationData?.coords.longitude,
            });
            const store = searchResults.data[0];
            updateShoppingContext(incomingShopType, store, postal_code);
          }
        } else {
          var store: Store | undefined;
          if (incomingShopType === ShopType.PICKUP && store_id) {
            store = await storeService.getStore(store_id);
          } else if (incomingShopType === ShopType.DELIVERY && postal_code) {
            var locationData = await getLastKnownLocation();
            const searchResults = await storeService.searchStores(0, 1, {
              shippingMethod: 'delivery',
              postalCode: postal_code,
              latitude: locationData?.coords.latitude,
              longitude: locationData?.coords.longitude,
            });
            store = searchResults.data[0];
          }
          if (store?.number) {
            const validationResult = await validateCart(store.number);
            if (validationResult.isValid) {
              updateShoppingContext(incomingShopType, store);
            } else {
              navigationContainerRef.navigate(AppStackRoutes.CartAndCheckout, {
                screen: CheckoutStackRoutes.CartLanding,
                params: { shopType: incomingShopType, storeNumber: store.number },
              });
            }
          }
        }
      }
    };

    if (queryParams) {
      const { shop_type, store_id, postal_code } = queryParams as { shop_type?: string; store_id?: string; postal_code?: string };

      setShoppingContext(shop_type, store_id, postal_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps --- trigger only if there is a change in query params and cart is initialzed
  }, [cartInitialized, queryParams, updateShoppingContext]);

  useEffect(() => {
    if (
      !showAppUpgradeModal &&
      (upgradeMode ?? '') === 'SuggestUpgrade' &&
      (userSettings?.lastCkecked === undefined || dayjs().diff(userSettings.lastCkecked, 'days') > 0)
    ) {
      setShowAppUpgradeModal(true);
      updateSetting('lastCkecked', dayjs().toDate());
    } else if (showAppUpgradeModal && upgradeMode !== 'SuggestUpgrade') {
      setShowAppUpgradeModal(false);
    }
  }, [showAppUpgradeModal, updateSetting, upgradeMode, userSettings?.lastCkecked]);

  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [
      prefix,
      'https://www.raleys.com',
      'https://www-raleys.fieldera.com',
      'https://www-raleys.fieldera.com',
      'https://www-stage-raleys.fieldera.com',
      'https://www-test-raleys.fieldera.com',
      'https://www-test-raleys.fieldera.com',
      'https://consumerapp.raleys.com',
    ],
    config: {
      screens: {
        RootTabs: {
          screens: {
            Home: 'home',
            Shop: {
              // path: 'shop',
              screens: {
                ShopLanding: 'shop',
                SearchLanding: 'search',
                Categories: 'categories',
                Category: {
                  path: 'category/:categoryId',
                  parse: {
                    categoryId: String,
                  },
                },
                ProductList: {
                  path: 'products/:categoryId',
                  parse: {
                    categoryId: String,
                  },
                },
                ShelfGuide: 'shelf-guide-list',
                ShelfGuideCategoryDetails: {
                  path: 'shelf-guide/:shelfGuide',
                  parse: {
                    shelfGuide: String,
                  },
                },
                ShelfGuideHelp: 'shelf-guide-help',
                WeeklyAds: 'weekly-ads',
                WeeklyAdsCollection: 'weekly-ads-collection',
                CollectionsLanding: 'collections',
                CollectionListing: {
                  path: 'collection/:productCollectionId/:collectionName',
                  parse: {
                    productCollectionId: String,
                    collectionName: String,
                  },
                },
              },
            },
            Wallet: 'wallet',
            Rewards: {
              path: 'something-extra',
              screens: {
                SEOffers: 'offers-and-savings',
              },
            },
            Account: {
              screens: {
                AccountLanding: 'account/landing',
                Wallet: 'account/payment-methods',
                AddEditPaymentMethod: {
                  path: 'account/payment-method/:paymentProfileId?',
                  parse: {
                    paymentProfileId: String,
                  },
                },
                ManageShoppingList: 'account/my-list',
                CommunicationPreferences: 'account/communication-preferences',
                MessageCenter: 'account/inbox',
                AppSettings: 'account/app-settings',
                AddressBook: 'account/address-book',
                AddEditAddress: {
                  path: 'account/address/:addressId?',
                  parse: {
                    addressId: String,
                  },
                },
                EditProfile: 'account/profile',
                ChangePassword: 'account/change-password',
                OrderHistory: {
                  screens: {
                    RecentOrders: 'account/recent-orders',
                    OrderDetails: {
                      path: 'account/order/:orderId',
                      parse: {
                        orderId: String,
                      },
                    },
                  },
                },
                StoreFinder: 'stores',
                HelpCenter: {
                  path: 'help-center',
                  screens: {
                    HelpCenterScreen: 'landing',
                    ContactCustomerScreen: 'customer-service',
                    HelpTopicsListScreen: {
                      path: '/:topic',
                      parse: {
                        topic: String,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        Customize: {
          path: 'customize',
          screens: {
            Initial: 'initial',
            Size: 'size',
            Selection: 'selection',
            Container: 'container',
            Notes: 'notes',
            Review: 'review',
            BasicSelection: 'basic-selection',
            Theme: 'theme',
            Filling: 'filling',
            Message: 'message',
            Summary: 'summary',
          },
        },
        ProductDetails: {
          path: 'product/:productKey',
          parse: {
            productKey: String,
          },
        },
        ShelfGuideDefinitions: {
          path: 'shelf-guide-definition/:shelfGuide',
          parse: {
            shelfGuide: String,
          },
        },
        ProductCarouselList: 'carousel-product-list',
        CartAndCheckout: {
          screens: {
            CartLanding: 'cart',
            OrderComplete: {
              path: 'order/:orderId',
              parse: {
                orderId: String,
              },
            },
            AddPaymentMethod: {
              path: 'checkout/payment/:paymentId',
              parse: {
                paymentId: String,
              },
            },
            AddAddress: {
              path: 'checkout/address/:addressId',
              parse: {
                addressId: String,
              },
            },
            Checkout: 'checkout',
            SavingsReminder: 'checkout/savings-reminder',
            OrderSummary: 'order-Summary',
          },
        },
      },
    },
  };

  return (
    <NavigationContainer
      ref={navigationContainerRef}
      theme={theme}
      linking={linking}
      onReady={() => {
        previousRouteRef.current = navigationContainerRef?.getCurrentRoute();
      }}
      onStateChange={() => {
        const currentRoute = navigationContainerRef?.getCurrentRoute();
        if (previousRouteRef.current?.name !== currentRoute?.name) {
          setPreviousRoute(previousRouteRef.current);
        }
        // Save the current route name for later comparison
        previousRouteRef.current = currentRoute;
      }}>
      <CustomModalProvider>
        <Stack.Navigator screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
          {deviceInfo?.requestPermissions && <Stack.Screen name={AppStackRoutes.Permissions} component={PermissionsScreen} initialParams={queryParams ?? {}} />}
          <Stack.Screen name={AppStackRoutes.RootTabs} component={RootTabNavigator} initialParams={queryParams ?? {}} />
          <Stack.Screen name={AppStackRoutes.LiveAgent} component={LiveAgentChatScreen} initialParams={{}} />
          <Stack.Screen
            name={AppStackRoutes.Customize}
            component={CustomizeNavigator}
            options={{
              presentation: 'modal',
            }}
          />
          <Stack.Screen
            name={AppStackRoutes.ProductDetails}
            component={ProductDetailsScreen}
            options={{
              presentation: 'modal',
            }}
          />
          <Stack.Screen
            name={AppStackRoutes.ShelfGuideDefinitions}
            component={ShelfGuideDefinitionsScreen}
            options={{
              presentation: 'modal',
            }}
          />
          <Stack.Screen
            name={AppStackRoutes.ProductCarouselList}
            component={ProductCarouselListScreen}
            options={{
              presentation: 'modal',
            }}
          />
          <Stack.Screen name={AppStackRoutes.OfferDetails} component={SEOfferDetailsScreen} options={{ presentation: 'modal' }} />
          <Stack.Screen name={AppStackRoutes.CartAndCheckout} component={CheckoutNavigator} />
          <Stack.Screen name={AppStackRoutes.AccordionScreen} component={AccordionListScreen} />
          <Stack.Screen name={AppStackRoutes.SearchFilter} component={SearchFilterScreen} />
          <Stack.Screen name={AppStackRoutes.WebBrowser} component={WebBrowserScreen} options={{ presentation: 'modal' }} />
        </Stack.Navigator>
      </CustomModalProvider>
      <UpgradeAppModal shoModal={showAppUpgradeModal} onDismiss={() => setShowAppUpgradeModal(false)} />
    </NavigationContainer>
  );
};

export default AppNavigation;

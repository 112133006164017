import { useCallback, useRef } from 'react';
import useEffectOnce from './useEffectOnce';

function useIsMounted() {
  const isMounted = useRef(false);

  useEffectOnce(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return useCallback(() => isMounted.current, []);
}

export default useIsMounted;

import { LineItem, Order } from '@fieldera-raleys/client-commercetools/schema';
import { Category, EventData, Indexable, PageType } from '@fieldera-raleys/client-common';
import { AppStackRoutes, CheckoutStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useLinkBuilder, useRoute } from '@react-navigation/native';
import { analyticsService, brsmService } from '@services';
import { useAnalyticsStore, useShopStore } from '@store';
import { searchCategoryTree } from '@utils/categoryHelper';
import logger from '@utils/logger';
import { moneyValue } from '@utils/orderHelpers';
import { queryClient } from '@utils/reactQuery';
import { useCallback } from 'react';
import Config from 'react-native-config';

const useAnalytics = () => {
  const buildLink = useLinkBuilder();
  const currentRoute = useRoute();

  const getCategoryTree = useCallback((categoryId: string, data: Category[]) => {
    let arr: String[] | undefined = [];
    if (data) {
      const productCategory = searchCategoryTree(data, (cat) => cat.id === categoryId);
      arr = productCategory && productCategory.crumb.map((item) => item.categoryName);
    }
    return arr ? arr.join('|') : '';
  }, []);

  const buildParams = useCallback(
    async (initialValues: EventData): Promise<EventData | undefined> => {
      const { previousRoute } = useAnalyticsStore.getState();
      const routeParams = currentRoute.params as Indexable<object>;
      var pageUrl = currentRoute.name && buildLink(currentRoute.name, currentRoute.params);
      var refUrl = previousRoute?.name && buildLink(previousRoute.name, previousRoute.params);
      var customParams = {};
      var pageType: PageType;
      switch (currentRoute.name) {
        case RootTabRoutes.Home:
          pageType = 'homepage';
          customParams = {};
          break;
        case ShopStackRoutes.Category:
        case ShopStackRoutes.ProductList:
          const storeId = useShopStore.getState().selectedStore?.number ?? Config.BRSM_DEFAULT_STORE;
          const data = await queryClient.fetchQuery(['categories', storeId], async () => brsmService.getAllCategories(storeId));
          const catTree = getCategoryTree(String(routeParams?.categoryId), data ?? []);
          pageType = 'category';
          if (catTree.length) {
            customParams = {
              cat: catTree,
              cat_id: routeParams?.categoryId,
            };
          }
          break;
        case ShopStackRoutes.SearchLanding:
          pageType = 'search';
          customParams = {
            search_term: routeParams?.query,
          };
          break;
        case ShopStackRoutes.ShopLanding:
        case CheckoutStackRoutes.CartLanding:
          pageType = 'other';
          customParams = {};
          break;
        case AppStackRoutes.ProductDetails:
          pageType = 'product';
          customParams = {};
          break;
        case CheckoutStackRoutes.Checkout:
        case CheckoutStackRoutes.OrderComplete:
          pageType = 'conversion';
          customParams = {};
          break;
        default:
          return;
      }
      return {
        ...initialValues,
        ...customParams,
        title: currentRoute.name,
        ptype: pageType,
        url: encodeURIComponent(`${Config.BRSM_BASE_URL}${pageUrl}`),
        ref_url: refUrl ? encodeURIComponent(`${Config.BRSM_BASE_URL}${refUrl}`) : '',
        test_data: String(__DEV__),
      };
    },
    [buildLink, currentRoute.name, currentRoute.params, getCategoryTree],
  );

  const buildBasketString = useCallback((pageEventArgArr: Array<LineItem>) => {
    let basket = '';

    pageEventArgArr.forEach((item, i) => {
      const price = moneyValue(item.price.value)?.replace('$', '');
      const separator = i === pageEventArgArr.length - 1 ? '' : '.';
      basket += '!i' + encodeURI(`${item.productKey ?? item.variant?.sku}'s${item.variant?.sku}'n${item.name}'q${item.quantity}'p${price}${separator}`);
    });
    return basket;
  }, []);

  const trackAddToCartEvent = useCallback(
    async (info: EventData) => {
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({
          type: 'event',
          group: 'cart',
          etype: 'click-add',
          ...info,
        });
        if (pageViewObj) {
          analyticsService.trackEvent('AddToCart', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackAddToCartEvent', ...pageViewObj });
      }
    },
    [buildParams],
  );

  const trackSearchSuggestEvent = useCallback(
    async (info: EventData) => {
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({
          type: 'event',
          group: 'suggest',
          etype: 'click',
          ...info,
        });
        if (pageViewObj) {
          analyticsService.trackEvent('SearchSuggest', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackSearchSuggestEvent', ...pageViewObj });
      }
    },
    [buildParams],
  );

  const trackSearchSubmitEvent = useCallback(
    async (info: EventData) => {
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({
          type: 'event',
          group: 'suggest',
          etype: 'submit',
          ...info,
        });
        if (pageViewObj) {
          analyticsService.trackEvent('SearchSubmit', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackSearchSubmitEvent', ...pageViewObj });
      }
    },
    [buildParams],
  );

  const trackConversionEvent = useCallback(
    async (orderData: Order) => {
      let pageViewObj: EventData | undefined = {};
      try {
        const basket = buildBasketString(orderData.lineItems);
        pageViewObj = await buildParams({
          type: 'pageview',
          is_conversion: '1',
          basket_value: moneyValue(orderData?.totalPrice)?.replace('$', ''),
          order_id: String(orderData?.orderNumber),
          basket: basket,
        });
        if (pageViewObj) {
          analyticsService.trackEvent('PlaceOrder', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackConversionEvent', ...pageViewObj });
      }
    },
    [buildBasketString, buildParams],
  );

  const trackPageViewEvent = useCallback(
    async (info?: EventData) => {
      if (currentRoute.name === AppStackRoutes.ProductDetails && !info?.prod_id) {
        return;
      }
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({ type: 'pageview', ...info });

        if (pageViewObj) {
          analyticsService.trackEvent('PageView', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackPageViewEvent', ...pageViewObj });
      }
    },
    [buildParams, currentRoute.name],
  );

  const trackWidgetViewEvent = useCallback(
    async (info?: EventData) => {
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({
          group: 'widget',
          etype: 'widget-view',
          ...info,
        });

        if (pageViewObj) {
          analyticsService.trackEvent('WidgetView', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackWidgetViewEvent', ...pageViewObj });
      }
    },
    [buildParams],
  );

  const trackWidgetClickEvent = useCallback(
    async (info?: EventData) => {
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({
          group: 'widget',
          etype: 'widget-click',
          ...info,
        });
        if (pageViewObj) {
          analyticsService.trackEvent('WidgetClick', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackWidgetClickEvent', ...pageViewObj });
      }
    },
    [buildParams],
  );

  const trackWidgetAddToCartEvent = useCallback(
    async (info: EventData) => {
      let pageViewObj: EventData | undefined = {};
      try {
        pageViewObj = await buildParams({
          type: 'event',
          group: 'widget',
          etype: 'widget-add',
          ...info,
        });
        if (pageViewObj) {
          analyticsService.trackEvent('WidgetAddToCart', pageViewObj);
        }
      } catch (ex) {
        logger.error(ex, { method: 'trackWidgetAddToCartEvent', ...pageViewObj });
      }
    },
    [buildParams],
  );
  return {
    trackAddToCartEvent,
    trackSearchSuggestEvent,
    trackSearchSubmitEvent,
    trackConversionEvent,
    trackPageViewEvent,
    trackWidgetViewEvent,
    trackWidgetClickEvent,
    trackWidgetAddToCartEvent,
  };
};

export default useAnalytics;

import React, { useState } from 'react';
import Screen from '../Screen';
import Tombstone, { OpenTombstoneRequest } from '../Tombstone';

type WithTombstoneProps = {
  showTombstone?: boolean;
};

const withTombstone =
  <P extends {}>(Component: React.ComponentType<P>, { showTombstone = true }: WithTombstoneProps) =>
  ({ ...props }: P): JSX.Element => {
    const [tsRequest, setTsRequest] = useState<OpenTombstoneRequest | undefined>();
    return (
      <Screen>
        <Component {...({ ...props, setTsRequest } as P)} />
        {showTombstone && <Tombstone openRequest={tsRequest} />}
      </Screen>
    );
  };

export default withTombstone;

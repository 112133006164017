import { createStackNavigator } from '@react-navigation/stack';
import { SEBenefitsScreen, SEHomeScreen, SEOffersScreen, TopDealsScreen } from '@screens/somethingExtra';
import React from 'react';
import { RewardsStackParamList, RewardsStackRoutes } from './routes';

const RewardsNavigator = () => {
  const RewardsStack = createStackNavigator<RewardsStackParamList>();

  return (
    <RewardsStack.Navigator initialRouteName={RewardsStackRoutes.SEHome} screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <RewardsStack.Screen name={RewardsStackRoutes.SEHome} component={SEHomeScreen} initialParams={{ reload: false }} />
      <RewardsStack.Screen name={RewardsStackRoutes.SEOffers} component={SEOffersScreen} />
      <RewardsStack.Screen name={RewardsStackRoutes.SEBenefits} component={SEBenefitsScreen} options={{ presentation: 'modal' }} />
      <RewardsStack.Screen name={RewardsStackRoutes.TopDeals} component={TopDealsScreen} />
    </RewardsStack.Navigator>
  );
};

export default RewardsNavigator;

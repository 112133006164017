import { appStyles, utilityStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React from 'react';
import { ActivityIndicator, FlatList, FlatListProps, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Text from '../Text';

interface ListProp<T> extends Omit<FlatListProps<T>, 'horizontal' | 'keyExtractor' | 'showsHorizontalScrollIndicator'> {
  isLoading: boolean;
  showIndicator?: boolean;
  headerStyle?: StyleProp<ViewStyle>;
  heading?: string | JSX.Element;
  headingStyle?: StyleProp<TextStyle>;
  link?: string | JSX.Element;
  linkStyle?: StyleProp<TextStyle>;
  linkOnPress?: () => void;
  orientation?: 'horizontal' | 'vertical';
  listContainerStyle?: StyleProp<ViewStyle>;
}

const List = <T,>({
  data,
  isLoading = false,
  linkOnPress,
  headerStyle,
  heading,
  link,
  linkStyle,
  testID,
  style,
  headingStyle,
  orientation = 'vertical',
  showIndicator = false,
  renderItem,
  listContainerStyle,
  ...rest
}: ListProp<T>) => {
  return (
    <View style={[styles.container, style]} testID={testID}>
      {!!heading && (
        <View style={styles.header}>
          {typeof heading === 'string' ? (
            <>
              <View style={[utilityStyles.py2, headerStyle]}>
                <Text style={[appStyles.bodyLeftBold, headingStyle]} testID={heading}>
                  {heading}
                </Text>
              </View>
              {!!link && (
                <TouchableOpacity onPress={linkOnPress}>
                  <Text style={[utilityStyles.py2, linkStyle]} testID="links">
                    {link}
                  </Text>
                </TouchableOpacity>
              )}
            </>
          ) : (
            heading
          )}
        </View>
      )}
      {isLoading ? (
        <ActivityIndicator size={'small'} />
      ) : (
        <FlatList
          keyExtractor={(_, id) => String(id)}
          style={[utilityStyles.px2, listContainerStyle]}
          data={data}
          renderItem={renderItem}
          horizontal={orientation === 'horizontal'}
          showsHorizontalScrollIndicator={orientation === 'horizontal' && showIndicator ? true : false}
          showsVerticalScrollIndicator={orientation === 'vertical' && showIndicator ? true : false}
          indicatorStyle="black"
          {...rest}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    marginTop: 10,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
    alignItems: 'center',
  },
});

export default List;

import { createStackNavigator } from '@react-navigation/stack';
import {
  AddAddressScreen,
  AddPaymentMethodScreen,
  AgeRequirementScreen,
  CartOptionsScreen,
  CartScreen,
  CheckoutScreen,
  OrderCompleteScreen,
  OrderSummaryScreen,
  SavingsReminderScreen,
} from '@screens/cartAndCheckout';
import BackupScreen from '@screens/cartAndCheckout/BackupScreen';
import CartLandingScreen from '@screens/cartAndCheckout/CartLandingScreen';
import React from 'react';
import { CheckoutStackParamList, CheckoutStackRoutes } from './routes';

const CheckoutNavigator = () => {
  const CheckoutStack = createStackNavigator<CheckoutStackParamList>();

  return (
    <CheckoutStack.Navigator screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }} initialRouteName={CheckoutStackRoutes.Cart}>
      <CheckoutStack.Screen name={CheckoutStackRoutes.CartLanding} component={CartLandingScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.Cart} component={CartScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.OrderComplete} component={OrderCompleteScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.AddPaymentMethod} component={AddPaymentMethodScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.AddAddress} component={AddAddressScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.Checkout} component={CheckoutScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.OrderSummary} component={OrderSummaryScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.SavingsReminder} component={SavingsReminderScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.AgeRequirementScreen} component={AgeRequirementScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.CartOptionsScreen} component={CartOptionsScreen} />
      <CheckoutStack.Screen name={CheckoutStackRoutes.BackupScreen} component={BackupScreen} />
    </CheckoutStack.Navigator>
  );
};

export default CheckoutNavigator;

import Text from '@components/Text';
import { BottomSheetHandleProps } from '@gorhom/bottom-sheet';
import { appStyles } from '@styles';
import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import Animated, { Extrapolate, interpolate, useAnimatedStyle, useDerivedValue } from 'react-native-reanimated';
import { toRad } from 'react-native-redash';
import { transformOrigin } from '../../utils/transformOrigin';

export interface CustomHeaderHandleProps extends BottomSheetHandleProps {
  title: string;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subTitle?: string;
  subTitleStyle?: StyleProp<TextStyle>;
}

const CustomHeaderHandle: React.FC<CustomHeaderHandleProps> = ({ title, style, animatedIndex, titleStyle = {}, subTitle, subTitleStyle = {} }) => {
  //#region animations

  const indicatorTransformOriginY = useDerivedValue(() => interpolate(animatedIndex.value, [0, 1, 2], [-1, 0, 1], Extrapolate.CLAMP));
  //#endregion

  //#region styles
  const containerStyle = useMemo(() => [styles.container, style], [style]);
  const containerAnimatedStyle = useAnimatedStyle(() => {
    const borderTopRadius = interpolate(animatedIndex.value, [1, 2], [20, 0], Extrapolate.CLAMP);
    return {
      borderTopLeftRadius: borderTopRadius,
      borderTopRightRadius: borderTopRadius,
    };
  });
  const leftIndicatorStyle = useMemo(
    () => ({
      ...styles.indicator,
      ...styles.leftIndicator,
    }),
    [],
  );
  const leftIndicatorAnimatedStyle = useAnimatedStyle(() => {
    const leftIndicatorRotate = interpolate(animatedIndex.value, [0, 1, 2], [toRad(-30), 0, toRad(30)], Extrapolate.CLAMP);
    return {
      transform: transformOrigin(
        { x: 0, y: indicatorTransformOriginY.value },
        {
          rotate: `${leftIndicatorRotate}rad`,
        },
        {
          translateX: -5,
        },
      ),
    };
  });
  const rightIndicatorStyle = useMemo(
    () => ({
      ...styles.indicator,
      ...styles.rightIndicator,
    }),
    [],
  );
  const rightIndicatorAnimatedStyle = useAnimatedStyle(() => {
    const rightIndicatorRotate = interpolate(animatedIndex.value, [0, 1, 2], [toRad(30), 0, toRad(-30)], Extrapolate.CLAMP);
    return {
      transform: transformOrigin(
        { x: 0, y: indicatorTransformOriginY.value },
        {
          rotate: `${rightIndicatorRotate}rad`,
        },
        {
          translateX: 5,
        },
      ),
    };
  });
  //#endregion

  // render
  return (
    <Animated.View style={[containerStyle, containerAnimatedStyle]} renderToHardwareTextureAndroid={true}>
      <Animated.View style={[leftIndicatorStyle, leftIndicatorAnimatedStyle]} />
      <Animated.View style={[rightIndicatorStyle, rightIndicatorAnimatedStyle]} />
      <Text style={[styles.title, titleStyle]}>{title}</Text>
      {subTitle && <Text style={[appStyles.bodySmall, subTitleStyle]}>{subTitle}</Text>}
    </Animated.View>
  );
};

export default CustomHeaderHandle;

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    paddingBottom: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.125)',
    zIndex: 99999,
  },
  indicator: {
    marginTop: 10,
    position: 'absolute',
    width: 10,
    height: 4,
    backgroundColor: '#999',
  },
  leftIndicator: {
    borderTopStartRadius: 2,
    borderBottomStartRadius: 2,
  },
  rightIndicator: {
    borderTopEndRadius: 2,
    borderBottomEndRadius: 2,
  },
  title: {
    marginTop: 26,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

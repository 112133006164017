import { BottomBar, Button, CloseButton, NavigationHeader, Screen, Switch } from '@components';
import Text from '@components/Text';
import { ListItem } from '@components/lists';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { StoreFinderStackRoutes, StoreFinderStackScreenProps } from '@navigation/routes';
import { useIsFocused } from '@react-navigation/native';
import { useStoreFinderFilterStore } from '@store';
import { StoreFinderFilters } from '@store/storeFinderFilterStore';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight } from '@styles/constants';
import { Platform } from 'expo-modules-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

type StoreFinderFilterProps = StoreFinderStackScreenProps<StoreFinderStackRoutes.StoreFinderFilter>;
const StoreFinderFilterScreen = ({ navigation }: StoreFinderFilterProps) => {
  const { t } = useTranslation('storeFinderScreen');

  const { departments, selectFilter, resetFilters, getSelectedFilters } = useStoreFinderFilterStore();
  const [scrollViewBottomPadding, setScrollViewBottomPadding] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<StoreFinderFilters[]>([]);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setSelectedFilters(getSelectedFilters());
    }
  }, [isFocused, getSelectedFilters]);

  const onBottomBarLayout = (le: LayoutChangeEvent) => {
    setScrollViewBottomPadding(le.nativeEvent.layout.height);
  };

  const applyFilters = () => {
    navigation.goBack();
  };

  const clearFilter = () => {
    setSelectedFilters([]);
    resetFilters();
  };

  const closeOnClick = () => {
    resetFilters();

    selectedFilters.map((sf) => {
      selectFilter(sf.id);
    });

    setSelectedFilters([]);
    navigation.goBack();
  };

  return (
    <>
      <Screen style={styles.screenBackground}>
        <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container }}>
          <NavigationHeader icon={<CloseButton size={20} onPress={closeOnClick} />} title={t('filterNavigationTitle')} titleStyle={appStyles.h6} />
          <ScrollView contentContainerStyle={[styles.listContainer, { paddingBottom: scrollViewBottomPadding }]} showsVerticalScrollIndicator={false}>
            {departments &&
              departments.map((item) => {
                return (
                  <View style={[appStyles.container, styles.viewPadding]} key={item.id}>
                    <ListItem testID="filterItem" key={item.id} title={item.title} titleStyle={appStyles.fontMobileListSmallLeft} style={styles.listItem}>
                      <Switch
                        testID={`${item.title}-switch`}
                        value={item.selected}
                        onValueChange={(selected) => {
                          selectFilter(item.departmentId, selected);
                        }}
                      />
                    </ListItem>
                  </View>
                );
              })}
          </ScrollView>
        </Animated.View>
      </Screen>
      <BottomBar onLayout={onBottomBarLayout} useInsets={false} testID="bottomBar">
        <TouchableOpacity activeOpacity={0.7} onPress={clearFilter} testID="clearFilterButton">
          <Text style={[appStyles.smallLink, styles.resetLink]} testID="resetText">
            Reset
          </Text>
        </TouchableOpacity>
        <Button
          testID="applyBtn"
          size={'small'}
          buttonStyle={[appStyles.primaryButtonSmall, styles.button]}
          textStyle={[appStyles.primaryButtonSmallText, styles.applyText]}
          onPress={applyFilters}
          title={'Apply'}
        />
      </BottomBar>
    </>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: colors.cream,
  },
  listContainer: {
    width: containerWidth,
  },
  button: {
    minWidth: '65%',
    height: 42,
  },
  resetLink: {
    color: colors.text,
  },
  container: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    flex: Platform.select({ ios: screenHeight < 740 ? 1 : 0, default: 1 }),
  },
  applyText: {
    textAlign: 'center',
    marginLeft: 15,
  },
  viewPadding: {
    paddingHorizontal: 0,
  },
  listItem: {
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
});

export default StoreFinderFilterScreen;
